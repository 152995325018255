import Button from '@mui/material/Button'
import NoteModal from './note-modal'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

export function NoteButton({ studentId, refreshFeed }) {
  const [show, setShow] = useState(false)

  return (
    <div>
      <NoteModal
        show={show}
        refreshFeed={refreshFeed}
        studentId={studentId}
        setShow={setShow}
      />

      <Button variant="outlined" onClick={() => setShow(true)}>
        Create Student Note
      </Button>
    </div>
  )
}

NoteButton.propTypes = {
  studentId: PropTypes.number.isRequired,
  refreshFeed: PropTypes.func
}

export default NoteButton
