import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import { Button, FormControl, Grid, Stack, Typography } from '@mui/material'
import SimpleAutocomplete from 'components/shared/simple-autocomplete'
import { getSchools } from 'api/schools'
import { getHmhAssessments } from 'api/hmh-quiz-statistics'
import { useQuery } from '@tanstack/react-query'
import { range, sortBy } from 'lodash'
import PieChartFiveSlices from 'components/shared/charts/PieChartFiveSlices'
import PaperBox from 'components/shared/PaperBox'
import StandardsBreakdown from './standards-breakdown'
import StandardsModules from './standards-modules'
import { useTeachers } from 'hooks/useTeachers'
import { useTeacher } from 'hooks/useTeacher'

const gradeLevelOptions = [
  { label: 'K', value: 'k' },
  { label: '1st', value: '1st' },
  { label: '2nd', value: '2nd' },
  { label: '3rd', value: '3rd' },
  { label: '4th', value: '4th' },
  { label: '5th', value: '5th' }
]

const languageOptions = [
  { label: 'English', value: 'english' },
  { label: 'Spanish', value: 'spanish' }
]
function HmhAssessments({ schoolId, teacherId }) {
  const [network, setNetwork] = useState('')
  const [school, setSchool] = useState(schoolId || '')
  const [gradeLevel, setGradeLevel] = useState('')
  const [module, setModule] = useState('')
  const [language, setLanguage] = useState('english')
  const [moduleOptions, setModuleOptions] = useState([])
  const [teacher, setTeacher] = useState(teacherId || '')
  const [sectionId, setSectionId] = useState('')

  const schoolsParams = {
    active: true,
    skip_pagination: true,
    school_type: 'elementary'
  }
  const { data: schools } = useQuery(
    ['schools', schoolsParams],
    () => getSchools(schoolsParams).then(res => res.data),
    {
      enabled: !schoolId,
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  const { data: teachers } = useTeachers({
    params: { school_id: schoolId, as_options: true, skip_pagination: true },
    options: {
      enabled: !!schoolId,
      initialData: [],
      refetchOnWindowFocus: false
    }
  })

  const { data: teacherData } = useTeacher(teacher, {
    include_sections: true,
    powerschool_sections: true
  })

  const teacherOptions = sortBy(teachers?.data, 'label') || []

  const sectionOptions =
    sortBy(teacherData?.teacher?.sections, 'full_name').map(section => ({
      label: section.full_name,
      value: section.id
    })) || []

  const hmhAssessmentsParams = {
    language: language,
    module: module,
    grade_level: gradeLevel,
    network: network,
    school_id: school,
    teacher_id: teacher,
    section_id: sectionId
  }
  const { data: hmhAssessments, isLoading } = useQuery(
    ['hmh-assessments', hmhAssessmentsParams],
    () => getHmhAssessments(hmhAssessmentsParams),
    {
      refetchOnWindowFocus: false
    }
  )

  const networkOptions = [...new Set(schools.map(school => school.network))]
    .filter(
      network =>
        !['Guillory', 'Neves', 'No-ILD'].includes(network) && network !== null
    )
    .sort()
    .map(network => ({ label: network, value: network }))

  const schoolOptions = sortBy(schools, 'name')
    .filter(school => school.network == network || !network)
    .map(school => ({
      label: school.name,
      value: school.id
    }))

  const resetFilters = () => {
    setLanguage('english')
    setGradeLevel('')
    setModule('')

    if (teacherId) {
      setSectionId('')
    }

    if (schoolId) {
      setTeacher('')
      setSectionId('')
    }

    if (!schoolId) {
      setNetwork('')
      setSchool('')
    }
  }

  const population = hmhAssessments?.population
  const { proficient, participated } = hmhAssessments?.proficiency || {}
  const didNotParticipate = population - participated
  const notProficient = participated - proficient

  React.useEffect(() => {
    if (hmhAssessments?.delivered_modules) {
      setModuleOptions(
        range(1, hmhAssessments.delivered_modules[gradeLevel] + 1).map(x => ({
          label: `${x}`,
          value: x
        }))
      )
    }
  }, [gradeLevel, hmhAssessments?.delivered_modules])

  return (
    <Page
      name="HMH Assessment Data (Canvas)"
      title="HMH Assessment Data (Canvas)"
    >
      <Stack direction={'row'} mb={4} justifyContent={'space-between'}>
        <Typography variant="subtitle2" pt={2}>
          Select a language and grade level in the filters below to see each
          standard&apos;s proficiency data.
        </Typography>

        {(network ||
          school ||
          gradeLevel ||
          language ||
          teacher ||
          sectionId) && (
          <Button onClick={() => resetFilters()}>Reset Filters</Button>
        )}
      </Stack>

      <Stack
        useFlexGap
        spacing={2}
        my={4}
        direction={{ xs: 'column', md: 'row' }}
      >
        <FormControl fullWidth>
          <SimpleAutocomplete
            key={`language-${language}`}
            disableClearable
            label={'Language'}
            options={languageOptions}
            value={language}
            onChange={option => setLanguage(option?.value || '')}
          />
        </FormControl>

        <FormControl fullWidth>
          <SimpleAutocomplete
            key={`gradeLevel-${gradeLevel}`}
            label={'Grade Level'}
            options={gradeLevelOptions}
            value={gradeLevel}
            onChange={option => setGradeLevel(option?.value || '')}
          />
        </FormControl>

        <FormControl fullWidth>
          <SimpleAutocomplete
            key={`module-${module}`}
            label={'Module'}
            options={moduleOptions}
            value={module}
            onChange={option => setModule(option?.value || '')}
          />
        </FormControl>

        {!schoolId && !teacherId && (
          <React.Fragment>
            <FormControl fullWidth>
              <SimpleAutocomplete
                key={`network-${network}`}
                label={'Network'}
                options={networkOptions}
                value={network}
                onChange={option => setNetwork(option?.value || '')}
              />
            </FormControl>

            <FormControl fullWidth>
              <SimpleAutocomplete
                key={`school-${school}`}
                label={'School'}
                options={schoolOptions}
                value={school}
                onChange={option => setSchool(option?.value || '')}
              />
            </FormControl>
          </React.Fragment>
        )}

        {schoolId && !teacherId && (
          <FormControl fullWidth>
            <SimpleAutocomplete
              key={`teacher-${teacher}`}
              label={'Teacher'}
              options={teacherOptions}
              value={teacher}
              onChange={option => {
                setTeacher(option?.value || '')
                setSectionId('')
              }}
            />
          </FormControl>
        )}

        {(schoolId || teacherId) && (
          <FormControl fullWidth>
            <SimpleAutocomplete
              key={`sectionId-${sectionId}`}
              disabled={!teacher}
              label={'Section'}
              options={sectionOptions}
              value={sectionId}
              onChange={option => setSectionId(option?.value || '')}
            />
          </FormControl>
        )}
      </Stack>

      {!population || !gradeLevel ? (
        <Typography my={4}>
          {isLoading ? 'Loading…' : 'No students match the specified criteria.'}
        </Typography>
      ) : (
        <React.Fragment>
          {!module && (
            <React.Fragment>
              <Typography my={4}>
                The standard breakdown is only available for English HMH modules
                at this time.
              </Typography>
              {language == 'english' && hmhAssessments && (
                <StandardsModules
                  stats={hmhAssessments.stats}
                  hmh_oas={hmhAssessments.hmh_oas}
                />
              )}
            </React.Fragment>
          )}

          {Boolean(module && participated) && (
            <React.Fragment>
              <Grid container rowSpacing={2} columnSpacing={2} my={2}>
                <Grid item md={6}>
                  <PaperBox title="Participation">
                    <PieChartFiveSlices
                      one={{
                        title: 'Participated',
                        value: participated
                      }}
                      two={{
                        title: 'Did Not Participate',
                        value: didNotParticipate
                      }}
                    />
                  </PaperBox>
                </Grid>
                <Grid item md={6}>
                  <PaperBox title="Proficiency">
                    <PieChartFiveSlices
                      one={{
                        title: 'Proficient',
                        value: proficient
                      }}
                      two={{
                        title: 'Not Proficient',
                        value: notProficient
                      }}
                    />
                  </PaperBox>
                </Grid>
              </Grid>

              <Typography my={4}>
                The standard breakdown is only available for English HMH modules
                at this time.
              </Typography>

              {language == 'english' && hmhAssessments && (
                <React.Fragment>
                  <StandardsBreakdown
                    stats={hmhAssessments.stats}
                    hmh_oas={hmhAssessments.hmh_oas}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Page>
  )
}

HmhAssessments.propTypes = {
  schoolId: PropTypes.number,
  teacherId: PropTypes.number
}

export default HmhAssessments
