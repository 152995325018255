import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices'
import FormControl from '@mui/material/FormControl'
import InfiniteScroll from 'react-infinite-scroller'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import filterOptions from 'components/teachers/show/overview/filter-options'
import { AbsoluteSpinner } from 'components/shared/spinner'

export default function InfiniteActivityFeed({
  title,
  loading,
  loadMore,
  hasMore,
  children,
  showFilter,
  selectedFilter,
  handleFilterChange
}) {
  return (
    <Paper mb={2}>
      <Typography
        variant="h6"
        component="div"
        sx={{ backgroundColor: '#f5f5f5', p: 2 }}
      >
        {title}
      </Typography>
      {loading && <AbsoluteSpinner style={{ marginTop: '2em' }} />}
      {showFilter && (
        <Box
          sx={{
            m: 2
          }}
        >
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="filter-options-label">
              Filter by feed item type
            </InputLabel>
            <Select
              className="Select"
              labelId="filter-options-label"
              id="filter-options"
              value={selectedFilter}
              label="Filter by feed item type"
              onChange={event => handleFilterChange(event.target.value)}
            >
              {filterOptions.map(filterOption => (
                <MenuItem value={filterOption.value} key={filterOption.value}>
                  {filterOption.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {selectedFilter && (
            <Button
              startIcon={<CleaningServicesIcon />}
              variant="contained"
              onClick={() => handleFilterChange(null)}
              sx={{ mb: 2 }}
            >
              Reset Filter
            </Button>
          )}
        </Box>
      )}
      <InfiniteScroll
        className="ActivityFeed"
        pageStart={0}
        initialLoad={false}
        loadMore={loadMore}
        hasMore={hasMore}
      >
        {children}
      </InfiniteScroll>
    </Paper>
  )
}

InfiniteActivityFeed.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  showFilter: PropTypes.bool,
  selectedFilter: PropTypes.string,
  handleFilterChange: PropTypes.func
}
