import { alert } from 'utils/alert'
import { destroy, get, patch, post } from 'utils/ajax'

export const destroyIntervention = (id, callback) => {
  return destroy(`/api/web/concerns/${id}`, {})
    .catch(_ => {
      alert('Something went wrong!', 'danger')
    })
    .then(() => {
      alert('Concern was deleted!', 'success')
      callback()
    })
}

export const createConcern = params => {
  return post('/api/web/concerns', { concern: params }).catch(_error => {
    alert('There was an error creating the concern.', 'danger')
  })
}

export const updateConcern = (id, params) => {
  return patch(`/api/web/concerns/${id}`, { concern: params })
}

export const getConcern = id => get(`/api/web/concerns/${id}`)

export const getConcerns = params => {
  return get('/api/web/concerns', { params: params }).catch(_error => {
    alert('There was an error fetching the concerns.', 'danger')
  })
}

export const getInterventionTypes = () => {
  return get('/api/web/intervention_types').catch(_err => {
    alert('An error occurred while fetching intervention types', 'danger')
  })
}

export const getConcernTypes = () => {
  return get('/api/web/concern_types').catch(_err => {
    alert('An error occurred while fetching concern types', 'danger')
  })
}

export const getConcernType = id => {
  return get(`/api/web/concern_types/${id}`).catch(_err => {
    alert('An error occurred while fetching concern type', 'danger')
  })
}

export const getSchoolInterventions = params => {
  return get('/api/web/concerns/school_concerns', { params }).catch(_err => {
    alert('An error occurred while fetching interventions', 'danger')
  })
}

export const createSchoolConcernsCsv = params => {
  return post('/api/web/concerns/school_concerns_report.csv', {
    ...params
  }).catch(_err => {
    alert('An error occurred while creating the csv.')
  })
}

export const getOwners = schoolId => {
  return get(`/api/web/schools/${schoolId}`, {
    params: {
      users_with_access: true
    }
  }).catch(_err => {
    alert('An error occurred while fetching owners', 'danger')
  })
}

export const getInterventionTypesForConcern = params => {
  return get('/api/web/intervention_types', {
    params: params
  }).catch(_err => {
    alert('An error occurred while fetching intervention types', 'danger')
  })
}

export const createIntervention = params => {
  return post('/api/web/interventions', {
    intervention: params
  }).catch(_err => {
    alert('An error occurred while creating the intervention', 'danger')
  })
}

export const getIntervention = interventionId => {
  return get(`/api/web/interventions/${interventionId}`).catch(_error => {
    alert('There was an error fetching the concern.', 'danger')
  })
}

export const updateIntervention = (id, params) => {
  return patch(`/api/web/interventions/${id}`, { intervention: params }).catch(
    _error => {
      alert('There was an error updating the concern.', 'danger')
    }
  )
}

export const createManySupports = ({
  studentIds,
  concernTypeId,
  interventionTypeId,
  date,
  content
}) => {
  return post('/api/web/supports/create_many', {
    student_ids: studentIds,
    concern_type_id: concernTypeId,
    intervention_type_id: interventionTypeId,
    date,
    content
  })
}
