import React, { useState } from 'react'
import PropTypes from 'prop-types'
import InterventionModal from 'components/shared/interventions/modal'
import InterventionCards from './cards'
import { useConcernType } from 'hooks/useConcernType'
import { Box, Button, Stack, Typography } from '@mui/material'

function TargetedInterventions({ concern }) {
  const { data: concernType } = useConcernType({
    concernTypeId: concern.concern_type_id
  })

  const [conciseView, setConciseView] = useState(true)
  const [show, setShow] = useState(false)

  const onClose = () => {
    setShow(false)
  }

  if (!concernType) {
    return null
  }

  if (concernType.targeted_intervention_type_options?.length) {
    return (
      <Stack spacing={2}>
        <Stack spacing={2}>
          <Typography variant="h2">Targeted Interventions</Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShow(true)}
              disabled={concern.closed || concern.is_resolved}
            >
              New Targeted Intervention
            </Button>
            <InterventionModal
              concern={concern}
              onClose={onClose}
              show={show}
            />
          </Box>
        </Stack>

        {concern.interventions.length === 0 && (
          <Typography variant="body1">
            No targeted interventions created yet. Please create for a
            long-term, measurable, skill-focused, human-led effort to resolve
            this student deficit.
          </Typography>
        )}

        {concern.interventions.map(
          (intervention, i) =>
            (!conciseView || (conciseView && i < 3)) && (
              <Box key={`intervention-${intervention.id}-card`}>
                <InterventionCards intervention={intervention} />
              </Box>
            )
        )}

        {conciseView && concern.interventions.length > 3 && (
          <Typography
            variant="subtitle1"
            style={{ color: '#28abe2', cursor: 'pointer' }}
            onClick={() => setConciseView(false)}
          >
            See Targeted Interventions ({concern.interventions.length} Total)
          </Typography>
        )}
      </Stack>
    )
  } else {
    return (
      <Stack spacing={2}>
        <Typography variant="h2">Targeted Interventions</Typography>

        <Typography variant="body1">
          Targeted interventions are only available for academic or behavioral
          concerns at this time.
        </Typography>
      </Stack>
    )
  }
}

TargetedInterventions.propTypes = {
  concern: PropTypes.object.isRequired
}

export default TargetedInterventions
