import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'utils/ajax'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { grey } from '@mui/material/colors'

export default function RiskStatusPopover({ student }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [failedCourses, setFailedCourses] = useState([])
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const [loading, setLoading] = useState(false)

  const handleClose = () => {
    setAnchorEl(null)
    setFailedCourses([])
  }

  const fetchStudentData = studentIdFromRow => {
    get(`/api/web/students/${studentIdFromRow}`, {
      params: {
        include_failed_courses: true
      }
    }).then(res => {
      setFailedCourses(res.student.failed_core_courses)
      setLoading(false)
    })
  }

  const fetchDataAndOpenPopover = (e, studentIdFromRow) => {
    setLoading(true)
    fetchStudentData(studentIdFromRow)
    handleClick(e)
  }

  const popoverContent = (string, alignment = 'center') => {
    return (
      <Box
        sx={{
          p: 2,
          borderBottom: `1px solid ${grey[300]}`
        }}
      >
        <Grid container justifyContent={alignment}>
          <Typography variant="subtitle1">{string}</Typography>
        </Grid>
      </Box>
    )
  }

  return (
    <div>
      <a
        onClick={e => fetchDataAndOpenPopover(e, student.id)}
        style={{ cursor: 'pointer' }}
      >
        {student.failed_core_courses_count}
      </a>
      <Popover
        id="grad-progress"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          style: { minWidth: '350px' }
        }}
      >
        <Box
          sx={{
            bgcolor: grey[300],
            p: 2,
            flexDirection: 'column',
            display: 'flex'
          }}
        >
          <Typography variant="h6" align="center">
            {student.graduation_credits} of 23
          </Typography>
          <Typography variant="subtitle1" align="center">
            Credits
          </Typography>
        </Box>
        {popoverContent('Failed Core Courses')}

        {loading
          ? popoverContent('Loading...', 'flex-start')
          : Object.keys(failedCourses).length === 0
          ? popoverContent('This student has not failed any core courses.')
          : Object.keys(failedCourses).map(key => (
              <Box
                key={key}
                sx={{
                  p: 2,
                  borderBottom: `1px solid ${grey[300]}`
                }}
              >
                <Grid container item alignItems="center">
                  <Grid item container xs={8}>
                    <Typography variant="subtitle1">{key}</Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={4}>
                    {failedCourses[key].map(c => (
                      <Typography variant="subtitle2" key={c.id}>
                        {c.term}&nbsp;
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              </Box>
            ))}
      </Popover>
    </div>
  )
}

RiskStatusPopover.propTypes = {
  student: PropTypes.object.isRequired
}
