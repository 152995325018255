import { get, patch, post } from 'utils/ajax'
import { alert } from 'utils/alert'

export const createChildStudyReferral = childStudyReferral => {
  return post('/api/web/child_study_referrals', {
    child_study_referral: childStudyReferral
  }).catch(() => {
    alert('There was an error creating the child study referral.', 'danger')
  })
}

// Pseudo-show request actually hitting index within controller
export const getChildStudyReferral = (childStudyId, studentId) => {
  return get('/api/web/child_study_referrals', {
    params: {
      child_study_id: childStudyId,
      student_id: studentId
    }
  }).catch(() => {
    alert('There was an error fetching the child study referral.', 'danger')
  })
}

export const updateChildStudyReferral = (id, params) => {
  return patch(`/api/web/child_study_referrals/${id}`, {
    child_study_referral: params
  }).catch(() => {
    alert('There was an error updating the child study referral.', 'danger')
  })
}
