import { get } from 'utils/ajax'
import { alert } from 'utils/alert'

const getUserTableData = (params = {}) => {
  return get('/api/admin/users', { params }).catch(_err => {
    alert('An error occurred while fetching user table data', 'danger')
  })
}

const getUserSchools = userId => {
  return get(`/api/admin/users/${userId}`).catch(_err => {
    alert('An error occurred while fetching user school data', 'danger')
  })
}

export default {
  getUserTableData,
  getUserSchools
}
