import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import {
  Box,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography
} from '@mui/material'
import PageTitle from 'components/shared/page-title'
import { useTheme } from '@mui/styles'
import MapGrowth from 'components/shared/students/show/map'
import StateTesting from 'components/students/show/state-testing/index'
import { getAcademicAssessmentAmiras } from 'api/academic-assessments/amira'
import { useQuery } from '@tanstack/react-query'
import { handleRailsRequestJsResponse } from 'utils/handleRailsRequestJsResponse'

export function StudentAcademicAssessments({ student }) {
  const theme = useTheme()
  const { data: amira_academic_assessments } = useQuery(
    ['amira_academic_assessments', student.id],
    async () => {
      const params = {
        student_id: student.id
      }
      const response = await getAcademicAssessmentAmiras(params)
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: [],
      refetchOnWindowFocus: false,
      enabled: student?.id ? true : false
    }
  )

  return (
    <>
      <PageTitle title={'Academic Assessments'} />
      <Stack spacing={4}>
        <Typography variant="h2">Reading</Typography>
        <Box id="studentAcademicAssessmentAmira">
          <Typography variant="h3">Amira</Typography>
          <TableContainer
            component={Paper}
            sx={{ marginTop: theme.spacing(4) }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell>ARM PR</TableCell>
                  <TableCell>ARM</TableCell>
                  <TableCell>WCPM</TableCell>
                  <TableCell>Dyslexia Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {amira_academic_assessments.length > 0 &&
                  amira_academic_assessments.map(assessment => (
                    <TableRow key={assessment.id}>
                      <TableCell>{assessment.overall_status}</TableCell>
                      <TableCell>{assessment.arm_pr}</TableCell>
                      <TableCell>{assessment.arm}</TableCell>
                      <TableCell>
                        {Math.round(assessment?.wcpm_score || 0)}
                      </TableCell>
                      <TableCell>{assessment.dyslexia_status}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <p>
            For guidance understanding these results, please utilize{' '}
            <a
              href="https://sde.ok.gov/sites/default/files/23-24%20Screening%20Technical%20Guidance%20Amira.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              this state resource.
            </a>
          </p>
        </Box>

        <Box id="studentAcademicAssessmentStateReading">
          <StateTesting studentId={student.id} subject="ela" />
        </Box>

        <Box id="studentAcademicAssessmentMapGrowthReading">
          <MapGrowth studentId={student.id} subject={'reading'} />
        </Box>

        <Typography variant="h2">Math</Typography>
        <Box id="studentAcademicAssessmentStateMath">
          <StateTesting studentId={student.id} subject={'mathematics'} />
        </Box>

        <Box id="studentAcademicAssessmentStateMath">
          <MapGrowth studentId={student.id} subject={'mathematics'} />
        </Box>
      </Stack>
    </>
  )
}

StudentAcademicAssessments.propTypes = {
  student: PropTypes.object.isRequired
}

export default StudentAcademicAssessments
