import { destroyMeeting } from 'api/meetings'
import { RequirementPropType } from 'modules/requirements'
import { startCase } from 'lodash'
import AccessChecker from 'utils/child-study-team-user-access-checker'
import MeetingModal from '../action-modals/meeting'
import PropTypes from 'prop-types'
import React from 'react'
import ConfirmModalButton from 'components/shared/confirm-modal/button'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const MeetingRequirement = ({
  activeId,
  activeModal,
  fetchRequirements,
  requirement,
  setActiveId,
  setActiveModal,
  schoolId,
  student,
  user,
  handleCloseAndRefreshFeed
}) => {
  const attendeesList = () => {
    const attendees = requirement.attendees || []
    return (
      <List>
        {attendees.length > 0 ? (
          attendees.map(attendee => (
            <ListItem key={attendee.id}>
              <ListItemText
                primary={`${attendee.name} - ${startCase(attendee.role)}`}
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="body1">No attendees</Typography>
        )}
      </List>
    )
  }

  return (
    <Box>
      <Box>
        <Typography variant="h5">{`Attendees on ${requirement.date}`}</Typography>
        {attendeesList()}
      </Box>

      <Stack direction="row" justifyContent="center">
        {AccessChecker.checkUserEditAccess(requirement, user, student) && (
          <Tooltip title="Edit Meeting" placement="top" arrow>
            <Button
              id="edit-child-study-meeting"
              onClick={() =>
                setActiveModal('meeting') & setActiveId(requirement.data_id)
              }
              startIcon={<EditIcon />}
              color="secondary"
            >
              Edit
            </Button>
          </Tooltip>
        )}

        {AccessChecker.checkUserDestroyAccess(requirement, user, student) && (
          <ConfirmModalButton
            id="delete-child-study-meeting"
            modalMessage="Are you sure you want to delete this meeting?"
            showCancelButton={false}
            onConfirm={() =>
              destroyMeeting(requirement.data_id, fetchRequirements)
            }
            startIcon={<DeleteIcon color="error" />}
            variant="outlined"
            color="error"
          >
            Delete
          </ConfirmModalButton>
        )}
      </Stack>

      <MeetingModal
        show={activeModal == 'meeting' && activeId == requirement.data_id}
        onHide={handleCloseAndRefreshFeed}
        meetingId={requirement.data_id}
        meetingDate={requirement.date}
        childStudyId={requirement.child_study_id}
        attendees={requirement.attendees}
        schoolId={schoolId}
        editing
      />
    </Box>
  )
}

MeetingRequirement.propTypes = {
  activeId: PropTypes.number,
  activeModal: PropTypes.string.isRequired,
  fetchRequirements: PropTypes.func.isRequired,
  requirement: RequirementPropType.isRequired,
  setActiveId: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
  schoolId: PropTypes.number.isRequired,
  student: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  handleCloseAndRefreshFeed: PropTypes.func.isRequired
}

export default MeetingRequirement
