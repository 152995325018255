import React from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { connect } from 'react-redux'
import { titleize } from 'utils'
import { alert, Severity } from 'utils/alert'
import { createReport } from 'modules/exports'
import { fetchStudents, setSelectedStudents } from 'modules/students'
import { Button, TextField, DialogActions, Box, Stack } from '@mui/material'
import SimpleAutocomplete from '../../shared/simple-autocomplete'
import StudentSelectContainer from 'shared/concerns/StudentSelectContainer'

class StudentReportOptions extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    createReport: PropTypes.func.isRequired,
    fetchStudents: PropTypes.func.isRequired,
    setSelectedStudents: PropTypes.func,
    selectedStudents: PropTypes.array,
    students: PropTypes.array
  }

  state = {
    locale: null,
    reportName: null,
    reportType: null,
    color: 'black_and_white',
    term: null,
    reportCardType: null,
    isSubmitted: false
  }

  componentDidMount() {
    this.handleFetchStudents()
  }

  handleSubmit = e => {
    e.preventDefault()
    const studentIds = this.props.selectedStudents.map(({ value }) => value)
    const { closeModal, createReport } = this.props
    const { locale, reportName, reportType, color, term, reportCardType } =
      this.state
    const params = {
      locale,
      report_type: reportType,
      report_name: reportName,
      student_ids: studentIds,
      color: color,
      selected_term: term,
      report_card_type: reportCardType
    }
    createReport(params)
      .then(() => {
        closeModal()
        alert(
          'Reports processing - refresh page to check status.',
          Severity.SUCCESS
        )
      })
      .catch(() => {
        alert('There was an error generating these reports.', Severity.ERROR)
      })
  }

  handleSelectedStudents = students => {
    const normalizedStudents = students.map(student => ({
      value: student.value,
      label: student.label
    }))
    this.props.setSelectedStudents(normalizedStudents)
  }

  handleFetchStudents = debounce(
    input => {
      const { fetchStudents } = this.props
      const params = {
        as_options: true,
        search: input,
        per_page: 100
      }
      fetchStudents(params).catch(() => {
        alert('An error occurred fetching students.', Severity.ERROR)
      })
    },
    300,
    { leading: false, trailing: true }
  )

  reportTypeChanged = option => {
    const reportType = option ? option.value : null
    this.setState({
      reportType,
      reportName: reportType ? titleize(reportType) : ''
    })
  }

  showLocaleSelect = () => {
    const { reportType } = this.state
    return (
      !reportType ||
      ![
        'graduation_summaries',
        'graduation_letters',
        'oklahoma_promise_letters',
        'ncaa_division_one',
        'ncaa_division_two',
        'missing_assignments'
      ].includes(reportType)
    )
  }

  render() {
    const { locale, reportType, reportName, isSubmitted } = this.state
    const { selectedStudents, closeModal } = this.props

    return (
      <form onSubmit={this.handleSubmit}>
        <Stack spacing={2}>
          <Box>
            <StudentSelectContainer
              studentId={null}
              selectedStudentOption={selectedStudents}
              setSelectedStudentOption={this.handleSelectedStudents}
              isSubmitted={isSubmitted}
            />
          </Box>

          <Box>
            <SimpleAutocomplete
              label="Report Type"
              options={[
                { value: 'student_summaries', label: 'Student Summary' },
                { value: 'chronic_absenteeism', label: 'Chronic Absenteeism' },
                {
                  value: 'five_consecutive_absences',
                  label: 'Five Consecutive Absences'
                },
                {
                  value: 'graduation_summaries',
                  label: 'Grad Check - Summary'
                },
                { value: 'graduation_letters', label: 'Grad Check - Full' },
                { value: 'oklahoma_promise_letters', label: 'OK Promise GPA' },
                { value: 'ncaa_division_one', label: 'NCAA D1' },
                { value: 'ncaa_division_two', label: 'NCAA D2' },
                { value: 'missing_assignments', label: 'Missing Assignments' }
              ]}
              value={reportType}
              onChange={newValue => this.reportTypeChanged(newValue)}
            />
          </Box>

          <Box>
            <TextField
              required
              id="report-name"
              label="Report Name"
              variant="outlined"
              fullWidth
              value={reportName || ''}
              onChange={e => this.setState({ reportName: e.target.value })}
            />
          </Box>

          {this.showLocaleSelect() ? (
            <Box sx={{ mb: 2 }}>
              <SimpleAutocomplete
                label="Language"
                options={[
                  { value: 'en', label: 'English' },
                  { value: 'es', label: 'Spanish' },
                  { value: 'chk', label: 'Chuukese (Attendance Letters Only)' },
                  { value: 'prs', label: 'Dari (Attendance Letters Only)' },
                  { value: 'hmn', label: 'Hmong (Attendance Letters Only)' },
                  {
                    value: 'mh',
                    label: 'Marshallese (Attendance Letters Only)'
                  },
                  { value: 'ps', label: 'Pashto (Attendance Letters Only)' }
                ]}
                value={locale}
                onChange={newValue =>
                  this.setState({ locale: newValue ? newValue.value : null })
                }
              />
            </Box>
          ) : (
            <Box sx={{ mb: 2 }}>
              <p>This report is currently only available in English.</p>
            </Box>
          )}

          <DialogActions>
            <Button variant="text" onClick={closeModal}>
              Close
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={selectedStudents.length === 0}
            >
              Save
            </Button>
          </DialogActions>
        </Stack>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  students: state.exports.students,
  selectedStudents: state.exports.selectedStudents || []
})

const mapDispatchToProps = {
  createReport,
  fetchStudents,
  setSelectedStudents
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StudentReportOptions)
