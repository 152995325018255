import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

function SlipDyslexiaButton({
  slipId,
  dyslexiaAddendumId,
  dyslexiaAssessmentResults,
  studentId
}) {
  const needs_new_form =
    !dyslexiaAddendumId && dyslexiaAssessmentResults == 'at_risk'
  const color = dyslexiaAddendumId ? 'primary' : 'secondary'

  const display = dyslexiaAddendumId
    ? 'View Dyslexia Addendum'
    : 'Create Dyslexia Addendum'

  const new_or_view_path = dyslexiaAddendumId
    ? `/dyslexia_addendums/${dyslexiaAddendumId}/edit`
    : `/dyslexia_addendums/new?slip_form_id=${slipId}&student_id=${studentId}`

  return needs_new_form || dyslexiaAddendumId ? (
    <Button
      variant="contained"
      color={color}
      href={new_or_view_path}
      target="_blank"
      sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}
    >
      {display}

      <OpenInNewIcon fontSize={'inherit'} display={'block'} />
    </Button>
  ) : (
    <React.Fragment></React.Fragment>
  )
}

SlipDyslexiaButton.propTypes = {
  dyslexiaAddendumId: PropTypes.number,
  slipId: PropTypes.number,
  dyslexiaAssessmentResults: PropTypes.bool
}

export default SlipDyslexiaButton
