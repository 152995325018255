import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchRequirements } from 'modules/requirements'
import { createMeeting, updateMeeting } from 'api/meetings'
import { Box, Button, Stack } from '@mui/material'
import AttendeeList from './attendee-list'
import moment from 'moment'
import { some } from 'lodash'
import { Dialog } from 'shared/dialog'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

class MeetingModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    childStudyId: PropTypes.number.isRequired,
    schoolId: PropTypes.number.isRequired,
    fetchRequirements: PropTypes.func.isRequired,
    editing: PropTypes.bool,
    attendees: PropTypes.array,
    meetingId: PropTypes.number,
    meetingDate: PropTypes.string
  }

  state = {
    dateVal: moment().format(),
    potentialAttendeeList: [],
    attendeesForDeletion: []
  }

  handleSubmit = event => {
    const { childStudyId, fetchRequirements } = this.props
    const { dateVal, potentialAttendeeList, attendeesForDeletion } = this.state

    event.preventDefault()

    const params = {
      meeting: {
        child_study_id: childStudyId,
        date: dateVal,
        attendees_attributes: [
          ...potentialAttendeeList,
          ...attendeesForDeletion
        ]
      }
    }

    this.onSubmitAction(params).then(_ => {
      fetchRequirements(childStudyId)
      this.resetForm()
    })
  }

  onSubmitAction = params => {
    const { editing, meetingId } = this.props
    if (editing) {
      return updateMeeting(meetingId, params)
    } else {
      return createMeeting(params)
    }
  }

  handleSelect = date => this.setState({ dateVal: date })

  addAttendee = newAttendee => {
    this.setState(prevState => ({
      potentialAttendeeList: [...prevState.potentialAttendeeList, newAttendee]
    }))
  }

  attendeeExists(attendeeId) {
    const { attendees } = this.props
    return some(attendees, ['user_id', attendeeId])
  }

  markAttendeeForDeletion(attendees, userIdToRemove) {
    const attendee = attendees.find(
      attendee => attendee.user_id === userIdToRemove
    )

    this.setState(prevState => ({
      attendeesForDeletion: [
        ...prevState.attendeesForDeletion,
        { ...attendee, _destroy: 1 }
      ]
    }))
  }

  removeAttendee = userIdToRemove => {
    const { editing } = this.props
    const oldAttendees = this.state.potentialAttendeeList

    if (editing && this.attendeeExists(userIdToRemove)) {
      this.markAttendeeForDeletion(oldAttendees, userIdToRemove)
    }

    const newAttendees = oldAttendees.filter(
      attendee => attendee.user_id !== userIdToRemove
    )
    this.setState({ potentialAttendeeList: newAttendees })
  }

  resetForm = () => {
    this.setState({ potentialAttendeeList: [], attendeesForDeletion: [] })
    this.props.onHide()
  }

  actionType = () => (this.props.editing ? 'Update' : 'Create')

  setInitialStateForEdit = () => {
    const { editing, meetingDate, attendees } = this.props

    if (editing && attendees) {
      this.setState({
        potentialAttendeeList: attendees,
        dateVal: moment(meetingDate).format()
      })
    }
  }

  render() {
    const { show, schoolId, childStudyId } = this.props
    const { potentialAttendeeList } = this.state

    return (
      <Dialog
        open={show}
        onClose={this.resetForm}
        onEntering={this.setInitialStateForEdit}
        title={`${this.actionType()} Meeting`}
      >
        <Box sx={{ p: 4 }}>
          <form onSubmit={this.handleSubmit}>
            <Stack spacing={2}>
              <DatePicker
                label="Select a Meeting Date"
                value={moment(this.state.dateVal).toDate}
                type="date"
                onChange={this.handleSelect}
              />

              <AttendeeList
                potentialAttendeeList={potentialAttendeeList}
                childStudyId={childStudyId}
                schoolId={schoolId}
                addAttendee={this.addAttendee}
                removeAttendee={this.removeAttendee}
              />

              <Button type="submit" color="primary" variant="contained">
                {`${this.actionType()} Meeting`}
              </Button>
            </Stack>
          </form>
        </Box>
      </Dialog>
    )
  }
}

const mapDispatchToProps = {
  fetchRequirements
}

export default connect(null, mapDispatchToProps)(MeetingModal)
