import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { popAlert } from '../../redux/modules/alerts/actions'
import { Alert } from '@mui/material'

// The autoHideDuration prop for Snackbar does not appear to work properly. The
// actual duration seems inconsistent. The custom implmentation below will also
// potentially enable us to display more than one message at a time.
const autoHideDruation = 5 * 1000

// https://mui.com/material-ui/react-snackbar/#consecutive-snackbars
export default function ConsecutiveSnackbars() {
  const dispatch = useDispatch()
  const snackPack = useSelector(state => state.alerts)

  // status can be: closed, opening, open, closing
  const [status, setStatus] = React.useState('closed')
  const [messageInfo, setMessageInfo] = React.useState(null)
  const timeoutRef = React.useRef(null)

  const startOpen = snackPack => {
    if (snackPack.length) {
      setStatus('opening')
      setMessageInfo(snackPack[0])
      dispatch(popAlert())
    }
  }

  const finishOpen = _snackPack => {
    setStatus('open')
    timeoutRef.current = setTimeout(startClose, autoHideDruation)
  }

  const startClose = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
    setStatus('closing')
  }

  const finishClose = () => {
    setStatus('closed')
    setMessageInfo(null)
  }

  const handleClose = (_event, reason) => {
    if (reason !== 'clickaway') {
      startClose()
    }
  }

  const handleExited = () => {
    finishClose()
  }

  React.useEffect(() => {
    if (status == 'closed' && !messageInfo) {
      startOpen(snackPack)
    } else if (status == 'opening' && messageInfo) {
      finishOpen(snackPack)
    }
  }, [snackPack.length, messageInfo, status])

  const open = messageInfo && status === 'open'

  return (
    <Snackbar
      data-testid="snackbar"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      TransitionProps={{ onExited: handleExited }}
      action={
        <>
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.5 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </>
      }
    >
      {messageInfo && (
        <Alert
          variant="filled"
          severity={messageInfo.severity}
          onClose={handleClose}
        >
          {messageInfo.message}
        </Alert>
      )}
    </Snackbar>
  )
}
