import { get } from 'utils/ajax'
import { alert } from 'utils/alert'

export const fetchAttendeesForSchool = schoolId => {
  const params = { school_id: schoolId }
  return get('/api/web/attendees', { params }).catch(() => {
    alert('There was an error creating the meeting.', 'danger')
  })
}

export default {
  fetchAttendeesForSchool
}
