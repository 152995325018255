import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Dialog from 'components/shared/dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import ReferralForm from './form'
import Referral from './show'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Stack } from '@mui/material'

function ReferralDialog({
  childStudyReferral,
  onHide,
  open,
  schoolId,
  studentId
}) {
  const user = useSelector(state => state.currentUser.user)
  const [allowEdits, setAllowEdits] = useState(false)

  const handleHide = () => {
    if (allowEdits) {
      setAllowEdits(false)
    } else {
      onHide()
    }
  }

  return (
    <Dialog
      onClose={handleHide}
      open={open}
      title={'Child Study Referral Form'}
    >
      <DialogContent>
        {!allowEdits && <Referral childStudyReferral={childStudyReferral} />}
        {allowEdits && (
          <ReferralForm
            allowEdits={allowEdits}
            childStudyReferral={childStudyReferral}
            onClose={handleHide}
            schoolId={schoolId}
            studentId={studentId}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            Submitted On:{' '}
            {moment(childStudyReferral.created_at).format('MM/DD/YYYY')}
          </Grid>
          {allowEdits ? (
            <Grid item>
              <Stack direction={'row'} spacing={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="medium"
                  onClick={handleHide}
                >
                  Cancel
                </Button>

                {/* TODO: re-enable when we have forwardRef */}
                {/* <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  // onClick={onHide}
                >
                  Save
                </Button> */}
              </Stack>
            </Grid>
          ) : (
            <Grid item>
              <Stack direction={'row'} spacing={2}>
                {user.id === childStudyReferral.referrer_id && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setAllowEdits(true)}
                  >
                    Edit
                  </Button>
                )}
                <Button
                  id="submit-note"
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={onHide}
                >
                  Close
                </Button>
              </Stack>
            </Grid>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

ReferralDialog.propTypes = {
  childStudyReferral: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  schoolId: PropTypes.number.isRequired,
  studentId: PropTypes.number.isRequired
}

export default ReferralDialog
