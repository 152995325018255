import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'components/shared/table'
import { titleize } from 'utils'

function participationCell(type, position) {
  function ParticipationCell({ value }) {
    const [_term, data] = value[position]

    return data && <span>{`${data[type]} (${data['participation']})`}</span>
  }

  ParticipationCell.propTypes = {
    value: PropTypes.object
  }

  return ParticipationCell
}

export default class SummaryTable extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    type: PropTypes.oneOf(['proficiency', 'growth']).isRequired
  }

  createColumns() {
    const type = this.props.type

    let columns = [
      {
        Header: '',
        accessor: 'name',
        Cell: props => <strong>{props.value}</strong>,
        maxWidth: '120px'
      }
    ]

    if (type === 'proficiency') {
      columns.push(
        {
          id: 'fall-proficiency',
          Header: 'Fall',
          accessor: obj => obj.data,
          Cell: participationCell(type, 0)
        },
        {
          id: 'winter-proficiency',
          Header: 'Winter',
          accessor: obj => obj.data,
          Cell: participationCell(type, 1)
        },
        {
          id: 'spring-proficiency',
          Header: 'Spring',
          accessor: obj => obj.data,
          Cell: participationCell(type, 2)
        }
      )
    }

    if (type === 'growth') {
      columns.push(
        {
          id: 'fall-winter',
          Header: 'Fall-Winter',
          accessor: obj => obj.data,
          Cell: participationCell(type, 0)
        },
        {
          id: 'fall-spring',
          Header: 'Fall-Spring',
          accessor: obj => obj.data,
          Cell: participationCell(type, 1)
        }
      )
    }
    return columns
  }

  render() {
    const { data, type } = this.props

    return (
      <div style={styles.container}>
        <h3>{titleize(type)} Data</h3>
        <Table
          columns={this.createColumns()}
          data={data}
          resizable={false}
          showPagination={false}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    marginBottom: '2em'
  }
}
