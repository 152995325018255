import { get, post } from 'utils/ajax'
import { alert } from 'utils/alert'
import { debounce } from 'lodash'

const getStudents = params => {
  return get('/api/web/students', { params }).catch(_err => {
    alert('An error occurred fetching students', 'danger')
  })
}

// Fetch React-Select student options asynchronously
// supports async with promises or callback
// https://github.com/JedWatson/react-select/tree/v1.x#async-options-with-promises
// callback can work better with debounce
const createDebouncedGetStudentSelectOptions = () =>
  debounce(
    (params, callback) => {
      return getStudents({ as_options: true, ...params })
        .then(data => {
          if (callback) {
            callback(null, { options: data.data })
          } else {
            return { options: data.data }
          }
        })
        .catch(() => {
          alert('An error occurred fetching students.')
        })
    },
    300,
    { leading: false, trailing: true }
  )

export default {
  getStudents,
  createDebouncedGetStudentSelectOptions
}
