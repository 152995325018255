import { get } from 'utils/ajax'

export const getSlipForm = params => {
  return get('/slip_forms.json', { params })
}

export const getPastThirdSlipForm = params => {
  return get('/slip_past_third_grade_forms.json', { params })
}

export const fetchStudentSlipData = params => {
  return get('/api/web/student_slip_map_growths', { params })
}

export const fetchDistrictSlipData = params => {
  return get('/api/web/district_slips', { params })
}
