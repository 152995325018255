import React from 'react'
import PropTypes from 'prop-types'
import BalanceIcon from '@mui/icons-material/Balance'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import SupportIcon from '@mui/icons-material/Support'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import SuperscriptIcon from '@mui/icons-material/Superscript'
import LoginIcon from '@mui/icons-material/Login'
import SchoolIcon from '@mui/icons-material/School'
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import SearchIcon from '@mui/icons-material/Search'
import ModeStandbyIcon from '@mui/icons-material/ModeStandby'

const EventIcon = ({ category }) => {
  const categoryToIconMap = {
    attendance: <CalendarTodayIcon />,
    chronic_absenteeism: <CalendarTodayIcon />,
    suspension: <BalanceIcon />,
    referral: <BalanceIcon />,
    intervention: <SupportIcon />,
    reading: <AutoStoriesIcon />,
    mathematics: <SuperscriptIcon />,
    enrollment: <LoginIcon />,
    coursework: <MapsHomeWorkIcon />,
    graduation: <SchoolIcon />,
    testing: <ShowChartIcon />,
    note: <StickyNote2Icon />,
    child_study: <SearchIcon />
  }

  const categoryToIcon = () =>
    categoryToIconMap[category] || <ModeStandbyIcon />

  return categoryToIcon(category)
}

EventIcon.propTypes = {
  category: PropTypes.string.isRequired
}

export default EventIcon
