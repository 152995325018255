import React from 'react'
import PropTypes from 'prop-types'
import { RequirementPropType } from 'modules/requirements'
import { userPropTypes } from 'modules/current-user'
import { connect } from 'react-redux'

import NoteRequirement from './note'
import MeetingRequirement from './meeting'
import InterventionRequirement from './intervention'
import UploadFormRequirement from './upload-form'

const RequirementBody = ({
  activeId,
  activeModal,
  fetchRequirements,
  requirement,
  setActiveId,
  setActiveModal,
  schoolId,
  student,
  user
}) => {
  const handleCloseAndRefreshFeed = () => {
    setActiveModal('')
    setActiveId(null)
    fetchRequirements()
  }

  const getRequirementProps = () => {
    return {
      activeId,
      activeModal,
      fetchRequirements,
      handleCloseAndRefreshFeed,
      requirement,
      setActiveId,
      setActiveModal,
      schoolId,
      student,
      user
    }
  }

  switch (requirement.data_type) {
    case 'Meeting':
      return <MeetingRequirement {...getRequirementProps()} />
    case 'ChildStudyNote':
      return <NoteRequirement {...getRequirementProps()} />
    case 'ChildStudyForm':
      return <UploadFormRequirement {...getRequirementProps()} />
    case 'Concern':
      return <InterventionRequirement {...getRequirementProps()} />
    default:
      return null
  }
}

RequirementBody.propTypes = {
  activeId: PropTypes.number,
  activeModal: PropTypes.string.isRequired,
  fetchRequirements: PropTypes.func.isRequired,
  requirement: RequirementPropType.isRequired,
  setActiveId: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  ...userPropTypes
}

const mapStateToProps = state => ({
  schoolId: state.schools.schoolId,
  user: state.currentUser.user
})

export default connect(mapStateToProps)(RequirementBody)
