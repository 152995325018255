import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import linkify from 'utils/linkify'
import WarningIcon from '@mui/icons-material/Warning'
import ErrorIcon from '@mui/icons-material/Error'

function ConcernTypeHelperText({
  description,
  isHighSecurity,
  isEmergencyCrisis
}) {
  const displayLinkOrText = (linkifyObject, i) => {
    if (linkifyObject.type === 'text') {
      return linkifyObject.text
    } else if (linkifyObject.type === 'link') {
      return (
        <a
          key={`${linkifyObject.type}-${i}`}
          href={`https://${linkifyObject.text}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          link
        </a>
      )
    }
  }

  return (
    <Box>
      {(isHighSecurity || isEmergencyCrisis || description.length > 0) && (
        <Typography variant="subtitle2">Concern Description</Typography>
      )}
      <Box>
        {isHighSecurity && (
          <>
            <Typography variant="overline">High Sensitivity</Typography>
            <WarningIcon color="error" />
          </>
        )}

        {isEmergencyCrisis && (
          <>
            <Typography variant="overline">Emergency Crisis</Typography>
            <ErrorIcon color="error" />
          </>
        )}
      </Box>
      <Typography varaint="body2">
        {linkify(description).map((t, i) => displayLinkOrText(t, i))}
      </Typography>
    </Box>
  )
}

export default ConcernTypeHelperText

ConcernTypeHelperText.propTypes = {
  description: PropTypes.string.isRequired,
  isHighSecurity: PropTypes.bool.isRequired,
  isEmergencyCrisis: PropTypes.bool.isRequired
}
