import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectors } from 'modules/shared'
import {
  withHighcharts,
  HighchartsChart,
  XAxis,
  YAxis,
  LineSeries,
  Legend,
  Tooltip
} from 'react-jsx-highcharts'
import { colorStudentAttendance } from 'utils/color-series'

const AttendanceChart = ({ data, isParentPortal, selectedYear }) => {
  if (!data || !data.length) {
    return <div style={styles.container} />
  }

  const xAxisTitle = I18n.jsT('labels.time')
  const yAxisTitle = I18n.jsT('labels.percent_attendance')

  const plotBands = [
    {
      from: 0,
      to: 80,
      color: 'rgba(232, 79, 37, 0.2)',
      label: {
        style: {
          color: '#606060'
        }
      }
    },
    {
      from: 80.01,
      to: 90,
      color: 'rgba(255, 144, 0, 0.2)',
      label: {
        style: {
          color: '#606060'
        }
      }
    },
    {
      from: 90.01,
      to: 95,
      color: 'rgba(246, 198, 54, 0.2)',
      label: {
        style: {
          color: '#606060'
        }
      }
    },
    {
      from: 95.01,
      to: 100,
      color: 'rgba(108, 205, 64, 0.2)',
      label: {
        style: {
          color: '#606060'
        }
      }
    }
  ]

  return (
    <div id="js-attendance-line-chart" style={styles.container}>
      <HighchartsChart
        id="js-attendance-line-chart"
        exporting={{ enabled: !isParentPortal }}
      >
        <Tooltip
          padding={10}
          hideDelay={250}
          valueDecimals={1}
          valueSuffix="%"
        />
        <Legend layout="horizontal" align="center" verticalAlign="top" />
        <XAxis
          type="category"
          categories={[
            I18n.jsT('dates.months.august'),
            I18n.jsT('dates.months.september'),
            I18n.jsT('dates.months.october'),
            I18n.jsT('dates.months.november'),
            I18n.jsT('dates.months.december'),
            I18n.jsT('dates.months.january'),
            I18n.jsT('dates.months.february'),
            I18n.jsT('dates.months.march'),
            I18n.jsT('dates.months.april'),
            I18n.jsT('dates.months.may'),
            I18n.jsT('dates.months.june'),
            I18n.jsT('dates.months.july')
          ]}
        >
          <XAxis.Title>{xAxisTitle}</XAxis.Title>
        </XAxis>
        <YAxis
          id="number"
          floor={0}
          ceiling={100}
          minRange={20}
          plotBands={plotBands}
        >
          <YAxis.Title>{yAxisTitle}</YAxis.Title>
        </YAxis>

        {data.map((series, index) => (
          <LineSeries
            id={`series-${index}`}
            name={series.name}
            data={series.data}
            lineWidth={2}
            marker={{ enabled: true, radius: 5 }}
            color={colorStudentAttendance(series.name, selectedYear)}
            key={index}
          />
        ))}
      </HighchartsChart>
    </div>
  )
}

AttendanceChart.propTypes = {
  data: PropTypes.array,
  isParentPortal: PropTypes.bool,
  selectedYear: PropTypes.number
}

const styles = {
  container: {
    minHeight: '400px'
  }
}

const mapStateToProps = state => ({
  isParentPortal: selectors.isParentPortal(state)
})

export default connect(mapStateToProps)(
  withHighcharts(AttendanceChart, window.Highcharts)
)
