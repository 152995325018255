import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { isNull, sortBy, uniqBy } from 'lodash'
import InterventionTypeOptionCard from './intervention-type-option'
import SimpleAutocomplete from '../simple-autocomplete'
import { QuestionMark } from '@mui/icons-material'

const frequencyOptions = [
  { label: 'As Needed', value: 'as_needed' },
  { label: 'One Time', value: 'one_time' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Every Other Week', value: 'every_other_week' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Daily', value: 'daily' }
]

const groupSizeOptions = [
  { label: 'Individual', value: 'individual' },
  { label: 'Small Group', value: 'small' },
  { label: 'Large Group', value: 'large' }
]

function findByValue(items, value, defaultValue = '') {
  return items.find(item => item.value == value) || defaultValue
}

export function InterventionForm({
  formData,
  setFormData,
  interventionTypes,
  platforms,
  school,
  bulkCreate,
  ...props
}) {
  const level = ['high', 'middle'].includes(school.category)
    ? 'secondary'
    : 'elementary'

  const platformOptions = platforms
    .filter(platform => platform.level === level)
    .map(platform => ({ label: platform.name, value: platform.id }))

  const interventionTypeOptions = interventionTypes.map(
    ({
      title,
      targeted_intervention_type_category_id,
      targeted_intervention_type_category_name,
      id,
      description,
      links,
      subtitle
    }) => ({
      label: title,
      value: id,
      sort: `${targeted_intervention_type_category_name} - ${title}`,
      search: `${targeted_intervention_type_category_name} ${title}`,
      description,
      links,
      subtitle,
      targeted_intervention_type_category_name,
      targeted_intervention_type_category_id
    })
  )

  const typeCategoryOptions = uniqBy(
    sortBy(
      interventionTypeOptions,
      'targeted_intervention_type_category_name'
    ).map(o => ({
      label: o.targeted_intervention_type_category_name,
      value: o.targeted_intervention_type_category_id
    })),
    'label'
  )

  const [interventionTypeCategory, setInterventionTypeCategory] = useState(
    findByValue(
      typeCategoryOptions,
      formData.targeted_intervention_type_category_id
    )
  )
  const [interventionType, setInterventionType] = useState(
    formData.targeted_intervention_type_id
  )

  const categoryTargetedInterventionTypeOptions =
    interventionTypeOptions.filter(
      o => o.targeted_intervention_type_category_id === interventionTypeCategory
    )

  const changeIntervention = values => {
    setFormData(state => ({ ...state, ...values }))
  }

  return (
    <Box component="form" {...props}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={7}>
          <Stack direction="column" spacing={2}>
            <DatePicker
              renderInput={props => <TextField required {...props} />}
              disableToolbar
              fullWidth
              value={moment(formData.followed_up_at).toDate()}
              inputFormat="MM/DD/YYYY"
              onChange={val =>
                changeIntervention({
                  followed_up_at: val
                })
              }
              label="Start Date"
              autoOk={true}
            />
            {platformOptions.length > 0 && (
              <FormControl fullWidth>
                <SimpleAutocomplete
                  required
                  key={'platform'}
                  label={'What intervention are you implementing?'}
                  options={platformOptions}
                  value={formData.platform_id}
                  onChange={option => {
                    setInterventionType(null)
                    changeIntervention({
                      platform_name: option?.label || '',
                      platform_id: option?.value || '',
                      assessment_type: '',
                      targeted_intervention_type_id: null
                    })
                  }}
                />
              </FormControl>
            )}
            {(platformOptions.length === 0 ||
              formData.platform_name === 'Other') && (
              <TextField
                required={formData.platform_name === 'Other'}
                id="assessment_type"
                label="What platform/resource will you be using?"
                multiline
                fullWidth
                variant="outlined"
                rows={4}
                value={formData.assessment_type}
                onChange={e =>
                  changeIntervention({
                    assessment_type: e.target.value
                  })
                }
              />
            )}
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Stack direction="row" spacing={0} alignItems="center">
                  <TextField
                    required={!bulkCreate}
                    type="number"
                    id="baseline"
                    label="Baseline Value"
                    fullWidth
                    variant="outlined"
                    value={formData.baseline}
                    onChange={e =>
                      changeIntervention({
                        baseline: e.target.value
                      })
                    }
                  />
                  <Tooltip
                    title="Baseline Value is the current or most recent value before the intervention is conducted."
                    arrow
                    placement="top"
                  >
                    <IconButton>
                      <QuestionMark />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  renderInput={props => (
                    <TextField
                      required={!isNull(formData.baseline)}
                      {...props}
                      error={false}
                    />
                  )}
                  disableToolbar
                  fullWidth
                  value={moment(formData.baseline_date).toDate()}
                  inputFormat="MM/DD/YYYY"
                  onChange={val =>
                    changeIntervention({
                      baseline_date: val
                    })
                  }
                  label="Baseline Date"
                  autoOk={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <Stack direction="row" spacing={0} alignItems="center">
                  <TextField
                    error={
                      formData.baseline &&
                      formData.target &&
                      formData.baseline === formData.target
                    }
                    helperText={
                      formData.baseline &&
                      formData.target &&
                      formData.baseline === formData.target
                        ? 'Baseline and target cannot be the same.'
                        : ''
                    }
                    required={!bulkCreate}
                    type="number"
                    id="target"
                    label="Target Value"
                    fullWidth
                    variant="outlined"
                    value={formData.target}
                    onChange={e =>
                      changeIntervention({
                        target: e.target.value
                      })
                    }
                  />
                  <Tooltip
                    title="Target Value is the desired value to achieve on or before the target date."
                    arrow
                    placement="bottom"
                  >
                    <IconButton>
                      <QuestionMark />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  renderInput={props => (
                    <TextField
                      required={!bulkCreate}
                      {...props}
                      error={false}
                    />
                  )}
                  disableToolbar
                  fullWidth
                  value={moment(formData.target_date).toDate()}
                  inputFormat="MM/DD/YYYY"
                  onChange={val =>
                    changeIntervention({
                      target_date: val
                    })
                  }
                  label="Target Date"
                  autoOk={true}
                />
              </Grid>
            </Grid>
            <TextField
              id="goal"
              label="Goal Description (Optional)"
              multiline
              fullWidth
              variant="outlined"
              rows={4}
              value={formData.goal_description}
              onChange={e =>
                changeIntervention({
                  goal_description: e.target.value
                })
              }
              helperText="The purpose of this targeted intervention"
            />
            <FormControl fullWidth variant="outlined">
              <SimpleAutocomplete
                key="frequency"
                label="Frequency (Optional)"
                options={frequencyOptions}
                value={formData.frequency}
                onChange={option =>
                  changeIntervention({
                    frequency: option?.value || ''
                  })
                }
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <SimpleAutocomplete
                key="groupSize"
                label="Group Size (Optional)"
                options={groupSizeOptions}
                value={formData.group_size}
                onChange={option =>
                  changeIntervention({
                    group_size: option?.value || ''
                  })
                }
              />
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Stack spacing={2}>
            <FormControl fullWidth variant="outlined">
              <SimpleAutocomplete
                required
                key="Intervention Category"
                label="Intervention Category"
                options={typeCategoryOptions}
                value={interventionTypeCategory}
                onChange={option => {
                  setInterventionTypeCategory(option?.value || '')
                  setInterventionType(null)
                  changeIntervention({
                    targeted_intervention_type_category_id:
                      option?.value || null,
                    targeted_intervention_type_id: null
                  })
                }}
              />
            </FormControl>
            {categoryTargetedInterventionTypeOptions.length > 0 &&
            (platformOptions.length === 0 ||
              formData.platform_name === 'Other') ? (
              <FormControl fullWidth variant="outlined">
                <FormLabel sx={{ pointerEvents: 'none' }}>
                  {'Intervention Type (Optional)'}
                  {interventionType && (
                    <Button
                      sx={{ pointerEvents: 'auto', ml: 2 }}
                      onClick={() => {
                        setInterventionType(null)
                        changeIntervention({
                          targeted_intervention_type_id: null
                        })
                      }}
                    >
                      Clear
                    </Button>
                  )}
                </FormLabel>
                <FormGroup>
                  <Stack direction="column" spacing={2}>
                    {categoryTargetedInterventionTypeOptions.map(
                      (option, index) => (
                        <InterventionTypeOptionCard
                          key={index}
                          option={option}
                          checked={interventionType === option.value}
                          setInterventionType={setInterventionType}
                          changeIntervention={changeIntervention}
                        />
                      )
                    )}
                  </Stack>
                </FormGroup>
              </FormControl>
            ) : (
              <Typography>Please select an intervention category.</Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

InterventionForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  interventionTypes: PropTypes.array.isRequired,
  platforms: PropTypes.array.isRequired,
  school: PropTypes.object.isRequired,
  bulkCreate: PropTypes.bool
}

export default InterventionForm
