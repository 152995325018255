import { get, post, patch, destroy } from '@rails/request.js'

const destroyDyslexiaAddendum = async id =>
  await destroy(`/dyslexia_addendums/${id}`)

const getDyslexiaAddendums = async params =>
  await get('/dyslexia_addendums', {
    query: params,
    responseKind: 'json'
  })

const getDyslexiaAddendum = async params =>
  await get(`/dyslexia_addendums/${params.id}`)

const getDyslexiaAddendumDocuments = async id =>
  await get(`/dyslexia_addendums/${id}/documents`)

const getPrefilledDyslexiaAddendum = async params =>
  await get('/dyslexia_addendums/new', {
    query: params.student_id ? params : {}
  })

const postDyslexiaAddendum = async params =>
  await post('/dyslexia_addendums', {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

const patchDyslexiaAddendum = async (id, params) =>
  await patch(`/dyslexia_addendums/${id}`, {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

const getDyslexiaAddendumReports = async params =>
  await get('/dyslexia_addendums/reports', {
    query: params,
    responseKind: 'json'
  })

const getDyslexiaAddendumStats = async params =>
  await get('/dyslexia_addendums/stats', {
    query: params,
    responseKind: 'json'
  })

export {
  destroyDyslexiaAddendum,
  getDyslexiaAddendum,
  getDyslexiaAddendums,
  getPrefilledDyslexiaAddendum,
  getDyslexiaAddendumDocuments,
  postDyslexiaAddendum,
  patchDyslexiaAddendum,
  getDyslexiaAddendumReports,
  getDyslexiaAddendumStats
}
