import { destroy, get, patch, post } from 'utils/ajax'

export const createColumnSet = params => {
  return post('/api/web/column_sets', { column_set: params })
}

export const deleteColumnSet = id => {
  return destroy(`/api/web/column_sets/${id}`, {})
}

export const fetchColumnSets = params => {
  return get('/api/web/column_sets', { params })
}

export const updateColumnSet = (id, params) => {
  return patch(`/api/web/column_sets/${id}`, { column_set: params })
}
