import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isNull } from 'lodash'
import moment from 'moment'
import { Table } from 'components/shared/table'
import Chip from '@mui/material/Chip'
import {
  fetchHistoricalGrades,
  selectors as gradeSelectors
} from 'modules/grades'

const CreditTypeCell = ({ original }) => (
  <React.Fragment>
    {original.credit_types.map(creditType => (
      <Chip
        key={creditType.abbreviation}
        label={creditType.abbreviation}
        variant="outlined"
        color="info"
        size="small"
      />
    ))}
  </React.Fragment>
)
CreditTypeCell.propTypes = {
  original: PropTypes.object.isRequired
}

const HistoricalGrades = ({
  historicalGrades,
  fetchHistoricalGrades,
  studentId
}) => {
  const [loading, setLoading] = useState(false)

  const handleFetchHistoricalGrades = () => {
    setLoading(true)
    fetchHistoricalGrades(studentId).then(_ => setLoading(false))
  }

  useEffect(() => {
    handleFetchHistoricalGrades()
  }, [])

  const columns = () => {
    return [
      {
        sortable: false,
        Header: I18n.jsT('table_headers.grades.course'),
        accessor: 'course_name'
      },
      {
        id: 'date',
        sortable: false,
        Header: I18n.jsT('table_headers.grades.date'),
        accessor: grade => moment(grade.date).format('MM/DD/YY')
      },
      {
        sortable: false,
        Header: I18n.jsT('table_headers.grades.earned'),
        accessor: 'earned'
      },
      {
        sortable: false,
        Header: I18n.jsT('table_headers.grades.credit_types'),
        accessor: 'credit_types',
        minWidth: 200,
        Cell: CreditTypeCell
      },
      {
        sortable: false,
        Header: I18n.jsT('table_headers.grades.grade'),
        accessor: 'grade'
      },
      {
        id: 'percent',
        sortable: false,
        Header: I18n.jsT('table_headers.grades.percent'),
        accessor: grade => !isNull(grade.percent) && `${grade.percent}%`
      },
      {
        sortable: false,
        Header: I18n.jsT('table_headers.grades.term'),
        accessor: 'term'
      }
    ]
  }

  return (
    historicalGrades && (
      <Table
        style={{
          borderTop: 'none'
        }}
        columns={columns()}
        data={historicalGrades}
        loading={loading}
      />
    )
  )
}

const mapStateToProps = (state, { studentId }) => ({
  historicalGrades: gradeSelectors.historicalGrades(state, studentId)
})

const mapDispatchToProps = {
  fetchHistoricalGrades
}

HistoricalGrades.propTypes = {
  historicalGrades: PropTypes.array.isRequired,
  fetchHistoricalGrades: PropTypes.func.isRequired,
  studentId: PropTypes.number.isRequired
}

export default connect(mapStateToProps, mapDispatchToProps)(HistoricalGrades)
