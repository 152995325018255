import React from 'react'
import Page from 'shared/page'
import PageContainer from 'shared/page-container'
import LookForCheckBox from './components/look-for-checkbox'
import { useLearningWalk } from 'hooks/tulsa-way/useLearningWalk'
import { useObservationTypeLookFors } from '../learning-walk'
import { toInteger, uniq } from 'lodash'
import FormGroup from '@mui/material/FormGroup'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useSelector } from 'react-redux'
import LearningWalkHeader from './components/header'
import Note from '../note'

export function LearningWalkReview() {
  const observationId = toInteger(window.location.href.split('/').pop())
  const user = useSelector(state => state.currentUser.user)

  const { data: observation } = useLearningWalk(observationId)
  const { data: observationTypeLookFors } =
    useObservationTypeLookFors(observation)
  const developmentAreaNames = uniq(
    observationTypeLookFors.map(o => o.development_area_name)
  )

  const wasObserved = lookFor =>
    observation?.look_fors_observed?.find(
      o => o.tulsa_way_look_for_id == lookFor.id
    )?.was_observed

  const developmentAareaObservationLookFors = name =>
    observationTypeLookFors
      .filter(o => o.development_area_name == name)
      .map(observationTypeLookFor => ({
        observationTypeLookFor,
        checked: wasObserved(observationTypeLookFor)
      }))

  if (!observation) {
    return null
  }

  return (
    <Page name="Review Learning Walk" title="Review Learning Walk">
      <PageContainer>
        <LearningWalkHeader observation={observation} />

        {developmentAreaNames.map(developmentAreaName => (
          <Paper
            key={developmentAreaName}
            elevation={3}
            sx={theme => ({ padding: theme.spacing(3) })}
          >
            <Stack>
              <Typography variant={'h3'}>{developmentAreaName}</Typography>
              <FormGroup>
                {developmentAareaObservationLookFors(developmentAreaName)
                  .filter(({ checked }) => [true, false].includes(checked))
                  .map(({ observationTypeLookFor }, key) => (
                    <LookForCheckBox
                      key={key}
                      checked={wasObserved(observationTypeLookFor)}
                      observationTypeLookFor={observationTypeLookFor}
                    />
                  ))}
              </FormGroup>
            </Stack>
          </Paper>
        ))}
        <Stack>
          <Typography variant={'h4'}>Notes</Typography>
          {user.id === observation.observer.user.id && (
            <React.Fragment>
              <Typography variant={'subtitle1'}>
                Private Note (Visible to Me)
              </Typography>
              <Note value={observation.note_to_self} />
            </React.Fragment>
          )}
          {user.id === observation.observer.user.id && (
            <Typography variant={'subtitle1'}>
              Public Note (Visible to Teacher)
            </Typography>
          )}
          <Note value={observation.note_to_teacher} />
        </Stack>
      </PageContainer>
    </Page>
  )
}

export default LearningWalkReview
