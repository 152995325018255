import { destroy, get, patch, post } from 'utils/ajax'

export const getTouchpointObservations = observerId => {
  return get('/api/web/touchpoint_observations', {
    params: {
      observer_id: observerId
    }
  })
}

export const createTouchpointObservation = observation => {
  return post('/api/web/touchpoint_observations', {
    touchpoint_observation: observation
  })
}

export const updateTouchpointObservation = observation => {
  return patch(`/api/web/touchpoint_observations/${observation.id}`, {
    touchpoint_observation: observation
  })
}

export const deleteTouchpointObservation = observationId => {
  return destroy(`/api/web/touchpoint_observations/${observationId}`)
}

export const getTouchpointIndicatorSets = () => {
  return get('/api/web/touchpoint_indicatorsets')
}

export const getTouchpointIndicatorSet = id => {
  return get(`/api/web/touchpoint_indicatorsets/${id}`)
}

export const getTouchpointObservationIndicators = teacher_id => {
  return get('/api/web/touchpoint_observation_indicators', {
    params: {
      teacher_id: teacher_id
    }
  })
}
