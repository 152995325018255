import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import {
  fetchSchoolLocatorSchoolDetails,
  updateSchoolLocatorSchoolDetails
} from 'api/school-locator-schools'
import { useDebounce } from 'hooks/useDebounce'
import { Typography } from '@mui/material'

function FindASchool({ hasAdminAccess, school, schoolId }) {
  const [gradeLevels, setGradeLevels] = useState([])
  const [notes, setNotes] = useState('')
  const debouncedNotes = useDebounce(notes, 500)
  const [schoolLocatorSchool, setSchoolLocatorSchool] = useState(null)

  const handleGradeLevelChange = array => {
    const sortedArray = array.sort(function (a, b) {
      return a - b
    })
    setGradeLevels(sortedArray)
  }

  const resetGradeLevels = () => {
    const currentGradeLevels = school.grade_level_values
    setGradeLevels(currentGradeLevels)
  }

  useEffect(() => {
    fetchSchoolLocatorSchoolDetails(schoolId).then(res => {
      setSchoolLocatorSchool(res)
      setGradeLevels(res.grade_levels)
      setNotes(res.notes)
    })
  }, [])

  useEffect(() => {
    if (schoolLocatorSchool) {
      if (
        notes !== schoolLocatorSchool.notes ||
        gradeLevels !== schoolLocatorSchool.grade_levels
      ) {
        const params = {
          ...schoolLocatorSchool,
          grade_levels: gradeLevels,
          notes: debouncedNotes
        }
        updateSchoolLocatorSchoolDetails(schoolLocatorSchool.school_id, params)
      }
    }
  }, [gradeLevels, debouncedNotes])

  return (
    <Grid container component={Stack} id="find-a-school-settings">
      <Grid item xs={12}>
        <Typography variant="h4">Find A School</Typography>
      </Grid>
      <Grid item xs={12}>
        {/* SchoolLocatorSchool.notes */}
        <FormControl fullWidth>
          <TextField
            id={'notes'}
            name={'notes'}
            label={'Public Notes'}
            rows={4}
            value={notes}
            onChange={val => setNotes(val.target.value)}
            disabled={!hasAdminAccess}
            multiline
          />
        </FormControl>
      </Grid>
      <Grid item xs={10}>
        <FormControl fullWidth>
          <InputLabel id="grade-level-select">Grade Levels</InputLabel>
          <Select
            labelId="grade-level-select"
            id="grade-levels"
            value={gradeLevels}
            label="Grade Levels"
            onChange={val => handleGradeLevelChange(val.target.value)}
            disabled={!hasAdminAccess}
            multiple
          >
            <MenuItem value={-2}>Pre-K (3)</MenuItem>
            <MenuItem value={-1}>Pre-K</MenuItem>
            <MenuItem value={0}>K</MenuItem>
            <MenuItem value={1}>1st</MenuItem>
            <MenuItem value={2}>2nd</MenuItem>
            <MenuItem value={3}>3rd</MenuItem>
            <MenuItem value={4}>4th</MenuItem>
            <MenuItem value={5}>5th</MenuItem>
            <MenuItem value={6}>6th</MenuItem>
            <MenuItem value={7}>7th</MenuItem>
            <MenuItem value={8}>8th</MenuItem>
            <MenuItem value={9}>9th</MenuItem>
            <MenuItem value={10}>10th</MenuItem>
            <MenuItem value={11}>11th</MenuItem>
            <MenuItem value={12}>12th</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={2}
        sx={{ paddingLeft: 2, display: 'flex', alignItems: 'center' }}
      >
        <Button
          variant={'contained'}
          color={'warning'}
          onClick={() => resetGradeLevels()}
          disabled={!hasAdminAccess}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  )
}

FindASchool.propTypes = {
  hasAdminAccess: PropTypes.bool.isRequired,
  school: PropTypes.object.isRequired,
  schoolId: PropTypes.number.isRequired
}

export default FindASchool
