// NOTE: importing the app redux store directly from '../redux/store' does not
//       work but it is unclear why. re-exporting it from the app component
//       does however. this does not seem to be the case with the admin redux
//       store but doing the same for consistency.
import { store } from '../components/app'

import { types } from 'redux/modules/alerts'

// https://mui.com/material-ui/react-alert/#severity
export const Severity = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error'
}

// https://getbootstrap.com/docs/3.4/components/#alerts
const bootstrapSeverityMap = {
  notice: Severity.SUCCESS, // green
  success: Severity.SUCCESS, // green
  info: Severity.INFO, // blue
  warning: Severity.WARNING, // yellow
  danger: Severity.ERROR // red
}

const alert = (message, type = Severity.INFO) => {
  let severity = bootstrapSeverityMap[type] || type
  if (!Object.values(Severity).includes(severity)) {
    severity = Severity.INFO
  }

  const payload = { message, severity }
  store?.dispatch({ type: types.ADD_ALERT, payload })
}

export { alert }
