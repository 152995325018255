import React from 'react'
import PropTypes from 'prop-types'
import ConfirmModalButton from 'components/shared/confirm-modal/button'

export default function DestroyAction(props) {
  const { feedItem, destroy, objectType } = props

  return (
    <ConfirmModalButton
      modalMessage={`Are you sure you want to delete this ${objectType}?`}
      showCancelButton={false}
      id={`destroy-feed-item-${feedItem.id}`}
      onConfirm={() => destroy(feedItem.data_id)}
    >
      <i className="fa fa-trash" aria-hidden="true" />
    </ConfirmModalButton>
  )
}

DestroyAction.propTypes = {
  feedItem: PropTypes.object.isRequired,
  destroy: PropTypes.func.isRequired,
  objectType: PropTypes.string.isRequired
}
