import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { CSVDownload } from 'react-csv'
import { fetchDistrictSchoolMetrics } from 'api/district.js'
import { titleize } from 'utils'
import Page from 'components/shared/page'
import SchoolsTable from 'components/schools/table'
import DownloadIcon from '@mui/icons-material/Download'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  Stack
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

function DistrictSchools() {
  const [isLoading, setIsLoading] = useState(true)
  const [pages, setPages] = useState(null)
  const [search, setSearch] = useState('')
  const [schools, setSchools] = useState([])
  const [schoolType, setSchoolType] = useState('')
  const [schoolDesignation, setSchoolDesignation] = useState('')
  const [schoolsForCsv, setSchoolsForCsv] = useState(null)

  useEffect(() => {
    fetchSchoolTableData()
  }, [schoolType, search, schoolDesignation])

  const fetchSchoolTableData = debounce(
    params => {
      setIsLoading(true)
      const districtParams = {
        include_metrics: true,
        search,
        school_type: schoolType,
        designation: schoolDesignation,
        active: true,
        ...params
      }
      return fetchDistrictSchoolMetrics(districtParams)
        .then(res => {
          setSchools(res.data)
          setPages(res.meta.pages)
          setIsLoading(false)
          return res
        })
        .catch(() => {
          setIsLoading(false)
          alert('Failed to fetch schools :(')
        })
    },
    300,
    { leading: false, trailing: true }
  )
  const schoolTypeChanged = e => {
    setSchoolType(e.target.value)
  }
  const searchChanged = e => {
    setSearch(e.target.value)
  }
  const schoolDesignationChanged = e => {
    setSchoolDesignation(e.target.value)
  }

  const fetchSchoolsForCSV = () => {
    fetchDistrictSchoolMetrics({
      include_metrics: true,
      skip_pagination: true,
      active: true
    }).then(res => {
      const sanitizedData = res.data.map(row => {
        let rowOutput = {}
        Object.entries(row).map(([k, v]) => {
          if (k === 'current_school_metric') {
            if (v) {
              return Object.entries(v)
                .filter(([key, _value]) =>
                  [
                    'attendance',
                    'absenteeism',
                    'suspensions',
                    'proficiency',
                    'math_growth',
                    'reading_growth',
                    'third_grade_proficiency'
                  ].includes(key)
                )
                .map(([key, value]) => (rowOutput[key] = value || ''))
            }
          } else {
            return (rowOutput[k] = v || '')
          }
        })
        return rowOutput
      })
      setSchoolsForCsv(sanitizedData)
    })
  }

  const headers = () => {
    if (!schoolsForCsv || !schoolsForCsv.length) return []

    return Object.keys(schoolsForCsv[0]).map(key => ({
      label: titleize(key),
      key
    }))
  }

  return (
    <Page name="District School Metrics" title="District School Metrics">
      <Stack spacing={2}>
        <Box>
          <form>
            <Stack direction="row" spacing={2}>
              <FormControl fullWidth>
                <InputLabel id="school-type-label">School Type</InputLabel>
                <Select
                  id="school-type"
                  labelId="school-type-label"
                  label="School Type"
                  value={schoolType}
                  onChange={schoolTypeChanged}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="early_childhood">Early Childhood</MenuItem>
                  <MenuItem value="elementary">Elementary</MenuItem>
                  <MenuItem value="middle">Middle</MenuItem>
                  <MenuItem value="high">High</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="school-designation-label">
                  Designation
                </InputLabel>
                <Select
                  id="school-designation"
                  labelId="school-designation-label"
                  label="Designation"
                  value={schoolDesignation}
                  onChange={schoolDesignationChanged}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="mri">MRI</MenuItem>
                  <MenuItem value="csi">CSI</MenuItem>
                  <MenuItem value="csi_and_mri">CSI & MRI</MenuItem>
                </Select>
              </FormControl>

              <Box>
                {/* This sets schoolsForCsv */}
                <Button
                  variant="contained"
                  onClick={() => fetchSchoolsForCSV()}
                  startIcon={<DownloadIcon />}
                >
                  CSV
                </Button>

                {/* This provides the client side csv download functionality */}
                {schoolsForCsv && (
                  <CSVDownload
                    data={schoolsForCsv}
                    headers={headers()}
                    filename="schools.csv"
                    target="_blank"
                  />
                )}
              </Box>
            </Stack>
          </form>
        </Box>

        <Box>
          <FormControl fullWidth>
            <InputLabel id="search-label">Search</InputLabel>
            <OutlinedInput
              id="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              label="Search"
              value={search}
              onChange={searchChanged}
            />
          </FormControl>
        </Box>

        <SchoolsTable
          data={schools}
          loading={isLoading}
          totalPages={pages}
          fetchData={fetchSchoolTableData}
        />
      </Stack>
    </Page>
  )
}

export default DistrictSchools
