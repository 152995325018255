import { alert } from 'utils/alert'
import { get } from 'utils/ajax'

export const getPostsecondaryEligibilityTiers = params => {
  return get('/api/web/postsecondary_eligibility_tiers.json', {
    params: params
  }).catch(_error => {
    alert(
      'There was an error fetching the postsecondary eligibility tiers.',
      'danger'
    )
  })
}
