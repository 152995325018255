import { post, get, patch, destroy } from 'utils/ajax'
import { alert } from 'utils/alert'
import { titleize } from 'utils'

export const getActivities = category => {
  return get('/api/web/activities', {
    params: {
      category: category,
      for_management: true
    }
  }).catch(error => {
    alert('An error occurred while fetching activities', 'danger')
    return Promise.reject(error)
  })
}

export const getSchoolActivities = schoolId => {
  return get('/api/web/school_activities', {
    params: {
      school_id: schoolId
    }
  }).catch(error => {
    alert("An error occurred while fetching your school's activities", 'danger')
    return Promise.reject(error)
  })
}

export const createActivity = (name, category) => {
  return post('/api/web/activities', {
    activity: {
      name: { en: titleize(name) },
      category: category
    }
  }).catch(error => {
    alert('An error occurred while creating the new activity', 'danger')
    return Promise.reject(error)
  })
}

export const createSchoolActivity = params => {
  return post('/api/web/school_activities', {
    school_activity: params
  }).catch(error => {
    alert('An error occurred while creating the school activity', 'danger')
    return Promise.reject(error)
  })
}

export const deleteSchoolActivity = id => {
  return destroy(`/api/web/school_activities/${id}`).catch(error => {
    alert('An error occurred while deleting the school activity', 'danger')
    return Promise.reject(error)
  })
}

export const updateSchoolActivity = (id, category) => {
  return patch(`/api/web/school_activities/${id}`, {
    school_activity: {
      category: category
    }
  }).catch(error => {
    alert('An error occurred while updating the school activity', 'danger')
    return Promise.reject(error)
  })
}

export const saveActivityTranslations = activity => {
  let url = `/api/web/activities/${activity.id}`
  let payload = { activity }

  return patch(url, payload).catch(error => {
    alert('An error occurred while saving the activity translations', 'danger')
    return Promise.reject(error)
  })
}
