import { alert } from 'utils/alert'

export const logError = (error, info) => {
  console.error('An error occurred', error, info)
}

// used to display AJAX errors
// TODO: do something nicer than a browser alert
export const showError = (message, err) => {
  const fullMessage = constructErrorMessage(err, message)
  console.error(fullMessage)
  alert(fullMessage)
}

export const constructErrorMessage = (
  err,
  message,
  messageSuffixWhenBlank = ''
) => {
  const errorMessage = getMessageFromError(err)
  return errorMessage
    ? `${message}: ${errorMessage}`
    : `${message}. ${messageSuffixWhenBlank}`.trim()
}

const getMessageFromError = err => {
  let errorMessage
  if (err && err.response && err.response.data && err.response.data.message) {
    errorMessage = err.response.data.message
  } else if (err && err.data && err.data.message) {
    errorMessage = err.data.message
  } else if (err) {
    errorMessage = err.message
  }

  return errorMessage
}
