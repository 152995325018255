import React from 'react'
import axios from 'axios'
import AdminSchoolsTable from './table'
import { debounce } from 'lodash'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/admin-page-container'
import {
  Box,
  Typography,
  FormControl,
  Select,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  MenuItem,
  Stack
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export default class AdminSchools extends React.Component {
  state = {
    isLoading: true,
    schools: [],
    pages: null,
    search: '',
    schoolType: '',
    active: true
  }

  componentDidMount() {
    this.fetchSchoolTableData()
  }

  searchChanged = e => {
    const search = e.target.value
    this.setState({ search }, () => {
      this.fetchSchoolTableData()
    })
  }

  schoolTypeChanged = e => {
    const schoolType = e.target.value
    this.setState({ schoolType }, () => {
      this.fetchSchoolTableData()
    })
  }

  activeChanged = e => {
    const active = e.target.value === 'true' ? true : false
    this.setState({ active }, () => {
      this.fetchSchoolTableData()
    })
  }

  fetchSchoolTableData = debounce(
    attrs => {
      this.setState({ isLoading: true })

      const params = {
        search: this.state.search,
        school_type: this.state.schoolType,
        active: this.state.active,
        login_data: true,
        ...attrs
      }

      return axios
        .get('/api/admin/schools', {
          params: {
            ...params
          }
        })
        .then(res => {
          this.setState({
            schools: res.data.data,
            pages: res.data.meta.pages,
            isLoading: false
          })
          return res.data
        })
        .catch(() => {
          this.setState({ isLoading: false })
          alert('Failed to fetch schools :(')
        })
    },
    300,
    { leading: false, trailing: true }
  )

  render() {
    const { isLoading, pages, search, schools, schoolType, active } = this.state
    return (
      <Page name="Admin-Schools" title="All Schools">
        <PageContainer>
          <Typography variant="h1">Schools</Typography>

          <Stack spacing={2}>
            <Box>
              <FormControl fullWidth>
                <InputLabel id="search-label">Search</InputLabel>
                <OutlinedInput
                  id="search"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Search"
                  value={search}
                  onChange={e => {
                    this.searchChanged(e)
                  }}
                />
              </FormControl>
            </Box>

            <Box>
              <form>
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel id="school-type-label">School Type</InputLabel>
                    <Select
                      id="school-type"
                      labelId="school-type-label"
                      label="School Type"
                      className="form-control"
                      value={schoolType}
                      onChange={this.schoolTypeChanged}
                    >
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value="early_childhood">
                        Early Childhood
                      </MenuItem>
                      <MenuItem value="elementary">Elementary</MenuItem>
                      <MenuItem value="middle">Middle</MenuItem>
                      <MenuItem value="high">High</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="school-status-label">Status</InputLabel>
                    <Select
                      id="school-status"
                      labelId="school-status"
                      label="Status"
                      className="form-control"
                      value={active}
                      onChange={this.activeChanged}
                    >
                      <MenuItem value={true}>Active</MenuItem>
                      <MenuItem value={false}>Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </form>
            </Box>

            <AdminSchoolsTable
              data={schools}
              totalPages={pages}
              loading={isLoading}
              fetchData={this.fetchSchoolTableData}
            />
          </Stack>
        </PageContainer>
      </Page>
    )
  }
}
