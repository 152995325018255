import React, { useState } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import MapTab from './map'
import OverviewTab from './overview'
import GradesTab from './grades/index'
import AttendanceTab from './attendance'
import ReferralsTab from './referrals'
import SectionsTab from './sections'
import ObservationsTab from './observations'
import TeacherSlipComplianceTab from './slip/compliance'
import HmhAssessments from 'components/district/hmh_assessments'
import { routerPropTypes } from 'utils/prop-types'
import { getTeacherInfo } from 'api/teachers'
import PageContainer from 'components/shared/page-container'
import { uniqBy } from 'lodash'
import { Box, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import Tabs from 'components/shared/tabs'
import Page from 'shared/page'

const makeTabs = ({
  baseUrl,
  teacherHasSections,
  teachesSeniors: _teachesSeniors
}) => {
  return [
    { label: 'Overview', to: `${baseUrl}/overview` },
    {
      label: 'Sections',
      to: `${baseUrl}/sections`,
      access: () => teacherHasSections
    },
    { label: 'MAP', to: `${baseUrl}/map`, access: () => teacherHasSections },
    {
      label: 'Grade Distribution',
      to: `${baseUrl}/grades`,
      access: () => teacherHasSections
    },
    // { label: 'Observations', to: `${baseUrl}/observations`, access: () => teacherHasSections },
    {
      label: 'Attendance',
      to: `${baseUrl}/attendance`,
      access: () => teacherHasSections
    },
    {
      label: 'Referrals',
      to: `${baseUrl}/referrals`,
      access: () => teacherHasSections
    },
    { label: 'SLIP', to: `${baseUrl}/slip` }
    // { label: 'HMH Assessments', to: `${baseUrl}/hmh-assessments` },
  ].filter(tab => !tab.access || tab.access())
}

function Teacher({ match, location }) {
  const searchParams = new URLSearchParams(location.search)

  const teacherId = parseInt(match.params.id)
  const [sectionId, setSectionId] = useState(searchParams.section_id || '')
  const [schoolId, setSchoolId] = useState(searchParams.school_id || '')

  const params = {
    include_sections: true,
    include_schools: true,
    include_certifications: true,
    include_attendance_info: true,
    include_grade_levels: true,
    include_student_count: true,
    school_id: schoolId
  }

  const {
    data: { teacher, sections, schools }
  } = useQuery(
    ['teacher', teacherId, params],
    () => {
      return getTeacherInfo(teacherId, params).then(data => {
        return {
          teacher: data.teacher,
          sections: data.teacher.sections,
          schools: data.teacher.schools
        }
      })
    },
    {
      initialData: {
        teacher: null,
        sections: [],
        schools: []
      },
      refetchOnWindowFocus: false
    }
  )

  const sectionChanged = value => {
    setSectionId(value)
  }

  const schoolChanged = value => {
    setSectionId(0)
    setSchoolId(value)
  }

  const baseUrl = `/teachers/${teacherId}`
  const sectionOptions = [{ label: 'All Sections', value: '' }].concat(
    sections.map(s => ({
      label: s.full_name,
      value: s.id
    }))
  )
  const schoolOptions = uniqBy(
    [{ label: 'All Schools', value: '' }].concat(
      schools.map(s => ({
        label: s.name,
        value: s.id
      }))
    ),
    'label'
  )

  const teacherName = teacher && teacher.name
  const teachesSeniors = teacher && teacher.grade_levels.includes('12th')
  const teacherHasSections = sections?.length > 0

  const tabs = React.useMemo(
    () => teacher && makeTabs({ baseUrl, teacherHasSections, teachesSeniors }),
    [teacher, baseUrl, teacherHasSections, teachesSeniors]
  )

  return (
    teacher && (
      <Page
        title={`${teacher.name || 'Teacher'} -- TPS Data`}
        name="Teacher Show Page"
      >
        <PageContainer>
          <Box
            sx={{
              mb: 6
            }}
          >
            <Typography variant="h1" id="test_header_title">
              Teacher
            </Typography>
            <Typography variant="h2" id="test_subheader_title">
              {teacherName ? teacherName : '...'}
            </Typography>
          </Box>

          <Tabs tabs={tabs} />

          <Box
            sx={{
              mt: 6
            }}
          >
            <Switch>
              <Redirect
                from={baseUrl}
                exact={true}
                to={`${baseUrl}/overview`}
              />

              <Route path={`${baseUrl}/overview`}>
                <OverviewTab teacher={teacher} teacherId={teacherId} />
              </Route>

              {teacherHasSections && (
                <>
                  <Route path={`${baseUrl}/sections`}>
                    <SectionsTab
                      sectionId={sectionId}
                      sectionOptions={sectionOptions}
                      sections={sections}
                      sectionChanged={sectionChanged}
                      teacher={teacher}
                      schoolId={schoolId}
                      schools={schools}
                      schoolOptions={schoolOptions}
                      schoolChanged={schoolChanged}
                    />
                  </Route>

                  <Route path={`${baseUrl}/map`}>
                    <MapTab
                      sectionId={sectionId}
                      sectionOptions={sectionOptions}
                      sectionChanged={sectionChanged}
                      teacherId={teacherId}
                      schoolId={schoolId}
                      schools={schools}
                      schoolOptions={schoolOptions}
                      schoolChanged={schoolChanged}
                    />
                  </Route>

                  <Route path={`${baseUrl}/grades`}>
                    <GradesTab
                      sectionId={sectionId}
                      sectionOptions={sectionOptions}
                      sectionChanged={sectionChanged}
                      teacherId={teacherId}
                      schoolId={schoolId}
                      schools={schools}
                      schoolOptions={schoolOptions}
                      schoolChanged={schoolChanged}
                    />
                  </Route>
                  <Route path={`${baseUrl}/slip`}>
                    <TeacherSlipComplianceTab
                      sectionId={sectionId}
                      sectionOptions={sectionOptions}
                      sectionChanged={sectionChanged}
                      teacher={teacher}
                      schoolId={schoolId}
                      schools={schools}
                      schoolOptions={schoolOptions}
                      schoolChanged={schoolChanged}
                    />
                  </Route>
                  {/* TODO: The following routes within this fragment DO NOT WORK outside the scope of this fragment. */}
                  <Route path={`${baseUrl}/observations`}>
                    <ObservationsTab teacher={teacher} teacherId={teacherId} />
                  </Route>
                  <Route path={`${baseUrl}/attendance`}>
                    <AttendanceTab teacherId={teacherId} />
                  </Route>
                  <Route path={`${baseUrl}/referrals`}>
                    <ReferralsTab teacherId={teacherId} />
                  </Route>
                  <Route path={`${baseUrl}/hmh-assessments`}>
                    <HmhAssessments teacherId={teacherId} />
                  </Route>
                </>
              )}
            </Switch>
          </Box>
        </PageContainer>
      </Page>
    )
  )
}

Teacher.propTypes = {
  ...routerPropTypes
}

export default Teacher
