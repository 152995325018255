import React from 'react'
import PropTypes from 'prop-types'
import { currentSchoolYear } from 'utils'
import {
  Avatar,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Tooltip,
  Typography,
  colors
} from '@mui/material'
import PageTitle from 'components/shared/page-title'
import { useTheme } from '@mui/styles'
import { useQuery } from '@tanstack/react-query'
import { getAcademicInterventionUsageAndPlatformData } from 'api/academic-interventions'
import { handleRailsRequestJsResponse } from 'utils/handleRailsRequestJsResponse'
import { QuestionMark } from '@mui/icons-material'
import { groupBy, sortBy } from 'lodash'

const elementaryGradeLevels = ['k', '1st', '2nd', '3rd', '4th', '5th']
const secondaryGradeLevels = [
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th'
]

export function StudentAcademicInterventions({ student }) {
  const theme = useTheme()
  const isElementary = elementaryGradeLevels.includes(student.grade_level)
  const isSecondary = secondaryGradeLevels.includes(student.grade_level)

  const { data: academicInterventions } = useQuery(
    ['academic-interventions-student', student.id],
    () =>
      getAcademicInterventionUsageAndPlatformData({
        student_id: student.id,
        school_year: currentSchoolYear
      }).then(res => handleRailsRequestJsResponse(res)),
    {
      initialData: null,
      refetchOnWindowFocus: false
    }
  )

  const academicInterventionsByPlatform = groupBy(
    sortBy(academicInterventions, ['school_year', 'week_number']),
    'platform'
  )

  const determineThresholdMetColor = thresholdMet => {
    if (thresholdMet === null) {
      return colors.red[600]
    }
    return thresholdMet ? colors.green[600] : colors.amber[600]
  }

  const elementaryAcademicInterventionPlatforms = [
    { label: 'Amira', value: 'amira' },
    { label: 'Istation', value: 'istation' },
    { label: 'Imagine Learning', value: 'imagine_learning' }
  ]

  const secondaryAcademicInterventionPlatforms = [
    { label: 'Exact Path', value: 'exact_path' },
    { label: 'Achieve3000', value: 'achieve_3000' },
    { label: 'Summit K12', value: 'summit' }
  ]

  const determinePlatforms = () => {
    if (isElementary) {
      return elementaryAcademicInterventionPlatforms
    } else if (isSecondary) {
      return secondaryAcademicInterventionPlatforms
    } else {
      return []
    }
  }

  return (
    <>
      <PageTitle title={'Academic Interventions'} />
      <Stack sx={{ mt: 6 }} spacing={6}>
        <Typography variant="h2">Reading</Typography>
        {determinePlatforms().map(platform => (
          <Box
            key={platform.value}
            id={`student${platform.label}InterventionsSummary`}
          >
            <Typography variant="h3">{platform.label}</Typography>
            <TableContainer
              component={Paper}
              sx={{ marginTop: theme.spacing(4) }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell>Week</TableCell>
                    <TableCell>Primary</TableCell>
                    <TableCell>
                      Met Threshold{' '}
                      <Tooltip
                        title={
                          <Typography>
                            Green: Met threshold
                            <br />
                            Yellow: Did not meet threshold
                            <br />
                            Red: Did not use platform
                          </Typography>
                        }
                        arrow
                        placement="top"
                      >
                        <QuestionMark fontSize={'inherit'} />
                      </Tooltip>
                    </TableCell>
                    <TableCell>Weekly Activities</TableCell>
                    <TableCell>Weekly Minutes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {academicInterventionsByPlatform[platform.value]?.map(
                    intervention => (
                      <TableRow key={intervention.id}>
                        <TableCell>{intervention.school_year}</TableCell>
                        <TableCell>{intervention.week_number}</TableCell>
                        <TableCell>
                          {intervention.primary_platform ? 'Yes' : 'No'}
                        </TableCell>
                        <TableCell>
                          <Avatar
                            key={intervention.week_number}
                            title={`Week ${intervention.week_number}`}
                            alt={`Week ${intervention.week_number}`}
                            sx={{
                              bgcolor: determineThresholdMetColor(
                                intervention.threshold_met
                              ),
                              width: 24,
                              height: 24,
                              fontSize: '80%'
                            }}
                          >
                            {' '}
                          </Avatar>
                        </TableCell>
                        <TableCell>{intervention.weekly_activities}</TableCell>
                        <TableCell>{intervention.weekly_minutes}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
      </Stack>
    </>
  )
}

StudentAcademicInterventions.propTypes = {
  student: PropTypes.object.isRequired
}

export default StudentAcademicInterventions
