import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import NoteModal from 'components/students/show/note-modal'

function NoteActions({
  noteId,
  update,
  destroy,
  noteOwnerId,
  noteContent,
  userId,
  refreshFeed
}) {
  const [show, setShow] = useState(false)

  const handleUpdate = comment => {
    update({ noteId, comment })
    closeModal()
  }

  const handleDestroy = _ => {
    destroy(noteId)
  }

  const openModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  return (
    <div>
      {noteOwnerId && noteOwnerId === userId && (
        <React.Fragment>
          <IconButton
            id={`edit-note-${noteId}`}
            onClick={openModal}
            aria-label="edit"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            id={`delete-note-${noteId}`}
            onClick={handleDestroy}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
          <NoteModal
            show={show}
            setShow={setShow}
            refreshFeed={refreshFeed}
            handleUpdate={handleUpdate}
            noteContent={noteContent}
            action={true}
          />
        </React.Fragment>
      )}
    </div>
  )
}

NoteActions.propTypes = {
  noteOwnerId: PropTypes.number,
  noteId: PropTypes.number.isRequired,
  destroy: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  noteContent: PropTypes.string.isRequired,
  userId: PropTypes.number,
  refreshFeed: PropTypes.func,
  setShow: PropTypes.func
}

const mapStateToProps = state => ({
  userId: state.currentUser.user.id
})

export default connect(mapStateToProps)(NoteActions)
