//# vi: ft=javascript
import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemText,
  Link,
  Stack,
  Typography
} from '@mui/material'
import {
  FormSelect,
  FormHiddenInput,
  FormTextInput,
  FormTextAreaInput,
  FormDateTimePicker,
  setLocale
} from 'shared/forms/formik'
import initFormValues from 'shared/forms/initFormValues'
import { useQuery } from '@tanstack/react-query'
import { handleRailsRequestJsResponse } from '../../utils/handleRailsRequestJsResponse'
import { isArray } from 'lodash'
import { getMentalWellnessAgencies } from 'api/confidential-crisis-forms'

function validUrl(url) {
  try {
    return `${new URL(url)}`
  } catch {
    return null
  }
}

function AgencyInfo({ agency }) {
  const url = validUrl(agency.website)

  return !agency ? null : (
    <Card variant={'outlined'}>
      <CardContent>
        <Typography variant="h4">{agency.name}</Typography>
        <Typography variant="body1">{agency.phone}</Typography>
        <Typography variant="body1">{agency.email}</Typography>
        {url && (
          <Typography variant="body1">
            <Link target={'_blank'} href={url}>
              {url}
            </Link>
          </Typography>
        )}
      </CardContent>
    </Card>
  )
}

AgencyInfo.propTypes = {
  agency: PropTypes.shape({
    name: PropTypes.string.isRequired,
    phone: PropTypes.string.phone,
    email: PropTypes.string.email,
    website: PropTypes.string
  })
}

const stringAttributes = [
  'status',
  'primary_concern_id',
  'student_name',
  'student_number',
  'student_email',
  'parent_guardian_name',
  'parent_guardian_email',
  'parent_guardian_phone',
  'email_inquiry_sent_at',
  'agency_person_contacted_name',
  'agency_person_contacted_email',
  'agency_person_contacted_phone',
  'agency_assigned_case_id',
  'notes'
]

const dateAttributes = [
  'email_warm_welcome_sent_at',
  'agency_person_contacted_datetime'
]

const MentalWellnessReferralForm = ({
  onSubmitValidated,
  setFormLoaded = () => {},
  prefill = {},
  disabled = false,
  hideStudentAndGuardianInfo = false
}) => {
  setLocale()

  const initialValues = initFormValues(prefill, {
    stringAttributes,
    dateAttributes
  })

  const validationSchema = Yup.object({
    status: Yup.string().required('Required'),
    student_name: Yup.string().required('Required'),
    mental_wellness_agency_id: Yup.string().required('Required'),
    student_number: Yup.string().required('Required'),
    student_email: Yup.string().required('Required'),
    parent_guardian_name: Yup.string().required('Required'),
    parent_guardian_email: Yup.string().required('Required'),
    parent_guardian_phone: Yup.string().required('Required'),
    agency_person_contacted_name: Yup.string().required('Required'),
    agency_person_contacted_email: Yup.string().required('Required'),
    agency_person_contacted_phone: Yup.string().required('Required'),
    agency_person_contacted_datetime: Yup.string().required('Required')
  })

  const { data: mentalWellnessAgencies } = useQuery(
    ['mental-wellness-agencies'],
    async () => {
      const response = await getMentalWellnessAgencies()
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const optionsForAgencies = isArray(mentalWellnessAgencies)
    ? mentalWellnessAgencies.map(obj => ({ label: obj.name, value: obj.id }))
    : []

  const findAgency = React.useCallback(
    id => mentalWellnessAgencies.find(agency => agency.id == id),
    [mentalWellnessAgencies]
  )

  // This is used with the print feature. We want to make sure
  // we have the menu items fetched before the print dialog is triggered.
  React.useEffect(() => {
    mentalWellnessAgencies && setFormLoaded(true)
  }, [mentalWellnessAgencies])

  return !mentalWellnessAgencies ? null : (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitValidated(values)
        setSubmitting(false)
      }}
    >
      {({ values }) => (
        <Form>
          <Box
            component={'fieldset'}
            disabled={disabled}
            sx={{ border: 'none' }}
          >
            <Stack>
              <FormHiddenInput name="school_id" />
              <FormHiddenInput name="student_id" />
              <FormHiddenInput name="student_number" />

              {!hideStudentAndGuardianInfo && (
                <>
                  <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Grid item md={4}>
                      <FormControl fullWidth>
                        <FormSelect
                          id="formSelect-status"
                          disabled={disabled}
                          items={[
                            {
                              label: 'Declined Services',
                              value: 'Declined Services'
                            },
                            { label: 'In Progress', value: 'In Progress' },
                            {
                              label: 'Intake Completed',
                              value: 'Intake Completed'
                            },
                            { label: 'New Referral', value: 'New Referral' }
                          ]}
                          label={I18n.t('MentalWellnessReferral.status')}
                          name="status"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={8}>
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          Status Definitions
                        </Typography>
                        <List>
                          <ListItem>
                            <ListItemText
                              primary="Declined Services"
                              secondary="Parent/guardian or agency states the guardian declined services"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="In Progress"
                              secondary="All fields are completed on the form, default selection"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="Intake Completed"
                              secondary="Staff confirms with guardian or agency that the student has completed intake"
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemText
                              primary="New Referral"
                              secondary="Selected when the original agency did not work and a new referral is needed - restarts the process"
                            />
                          </ListItem>
                        </List>
                      </Box>
                    </Grid>
                  </Grid>

                  <FormTextInput
                    name="student_name"
                    label={I18n.t('MentalWellnessReferral.student_name')}
                    disabled={disabled}
                  />

                  <FormTextInput
                    name="student_number"
                    label={I18n.t('MentalWellnessReferral.student_number')}
                    disabled={disabled}
                  />

                  <FormTextInput
                    name="student_email"
                    label={I18n.t('MentalWellnessReferral.student_email')}
                    disabled={disabled}
                  />

                  <FormTextInput
                    name="parent_guardian_name"
                    label={I18n.t(
                      'MentalWellnessReferral.parent_guardian_name'
                    )}
                    disabled={disabled}
                  />

                  <FormTextInput
                    name="parent_guardian_email"
                    label={I18n.t(
                      'MentalWellnessReferral.parent_guardian_email'
                    )}
                    disabled={disabled}
                  />

                  <FormTextInput
                    name="parent_guardian_phone"
                    label={I18n.t(
                      'MentalWellnessReferral.parent_guardian_phone'
                    )}
                    disabled={disabled}
                  />

                  <FormSelect
                    id="formSelect-mental_wellness_agency_id"
                    disabled={disabled}
                    items={optionsForAgencies}
                    label={I18n.t(
                      'confidential_crisis.student_mh_immediate_referral_name'
                    )}
                    name="mental_wellness_agency_id"
                  />
                </>
              )}

              {values.mental_wellness_agency_id && (
                <AgencyInfo
                  agency={findAgency(values.mental_wellness_agency_id)}
                />
              )}

              <FormTextInput
                name="agency_person_contacted_name"
                label={I18n.t(
                  'MentalWellnessReferral.agency_person_contacted_name'
                )}
                disabled={disabled}
              />

              <FormTextInput
                name="agency_person_contacted_email"
                label={I18n.t(
                  'MentalWellnessReferral.agency_person_contacted_email'
                )}
                disabled={disabled}
              />

              <FormTextInput
                name="agency_person_contacted_phone"
                label={I18n.t(
                  'MentalWellnessReferral.agency_person_contacted_phone'
                )}
                disabled={disabled}
              />

              <FormTextInput
                name="agency_assigned_case_id"
                label={I18n.t('MentalWellnessReferral.agency_assigned_case_id')}
                disabled={disabled}
              />

              <FormDateTimePicker
                name="agency_person_contacted_datetime"
                label={I18n.t(
                  'MentalWellnessReferral.agency_person_contacted_datetime'
                )}
                disabled={disabled}
              />

              <FormTextAreaInput
                name="notes"
                label={I18n.t('MentalWellnessReferral.notes')}
                disabled={disabled}
              />

              {!disabled && (
                <Button variant="contained" type="submit">
                  Submit
                </Button>
              )}
            </Stack>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

MentalWellnessReferralForm.propTypes = {
  onSubmitValidated: PropTypes.func.isRequired,
  setFormLoaded: PropTypes.func,
  prefill: PropTypes.object,
  disabled: PropTypes.bool,
  hideStudentAndGuardianInfo: PropTypes.bool
}

export { MentalWellnessReferralForm }
