import { post, patch, destroy } from 'utils/ajax'
import { alert } from 'utils/alert'

export const createMeeting = params => {
  const mergedParams = {
    ...params
  }
  return post('/api/web/meetings', mergedParams).catch(_err => {
    alert('There was an error creating the meeting.', 'error')
  })
}

export const updateMeeting = (id, params) => {
  const mergedParams = {
    ...params
  }
  return patch(`/api/web/meetings/${id}`, mergedParams).catch(_err => {
    alert('There was an error updating the meeting.', 'error')
  })
}

export const destroyMeeting = (id, callback) => {
  return destroy(`/api/web/meetings/${id}`, {})
    .catch(_err => {
      alert('Something went wrong!', 'error')
    })
    .then(() => {
      alert('Meeting was successfully deleted.', 'success')
      callback()
    })
}

export default {
  createMeeting,
  updateMeeting,
  destroyMeeting
}
