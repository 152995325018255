import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'

export const RouterLinkButton = props => {
  return (
    <Button component={Link} {...props}>
      {props.children}
    </Button>
  )
}
RouterLinkButton.propTypes = {
  children: PropTypes.any
}

export default RouterLinkButton
