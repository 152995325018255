import { get } from 'utils/ajax'

export const fetchDistrictSchoolMetrics = params => {
  return get('/api/web/schools', {
    params
  })
}

export const fetchEnrollmentMetrics = () => {
  return get('/api/web/district_enrollments')
}
