import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useLearningWalk } from 'hooks/tulsa-way/useLearningWalk'
import { useObservationTypeLookFors } from '../learning-walk'
import { toInteger } from 'lodash'
import LearningWalkHeader from '../learning-walk/components/header'
import { useSelector } from 'react-redux'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import FormGroup from '@mui/material/FormGroup'
import Paper from '@mui/material/Paper'
import LookForCheckBox from '../learning-walk/components/look-for-checkbox'
import Note from '../note'

export function WalkToReadReview() {
  const observationId = toInteger(window.location.href.split('/').pop())
  const user = useSelector(state => state.currentUser.user)

  const { data: observation } = useLearningWalk(observationId)
  const { data: observationTypeLookFors } =
    useObservationTypeLookFors(observation)
  const developmentAreaNames = ['The teacher:']

  const wasObserved = lookFor =>
    observation?.look_fors_observed?.find(
      o => o.tulsa_way_look_for_id == lookFor.id
    )?.was_observed

  const developmentAareaObservationLookFors = () =>
    observationTypeLookFors.map(observationTypeLookFor => ({
      observationTypeLookFor,
      checked: wasObserved(observationTypeLookFor)
    }))

  if (!observation) {
    return null
  }

  return (
    <Page
      name={`Tulsa Way - ${observation.observation_type} Review`}
      title={`Tulsa Way - ${observation.observation_type} Review`}
    >
      <PageContainer>
        <Stack spacing={5}>
          <LearningWalkHeader observation={observation} />

          {developmentAreaNames.map(developmentAreaName => (
            <Paper
              key={developmentAreaName}
              elevation={3}
              sx={theme => ({ padding: theme.spacing(3) })}
            >
              <Stack>
                <Typography variant={'h3'}>{developmentAreaName}</Typography>
                <FormGroup>
                  {developmentAareaObservationLookFors(developmentAreaName)
                    .filter(({ checked }) => [true, false].includes(checked))
                    .map(({ observationTypeLookFor }, key) => (
                      <LookForCheckBox
                        key={key}
                        checked={wasObserved(observationTypeLookFor)}
                        observationTypeLookFor={observationTypeLookFor}
                      />
                    ))}
                </FormGroup>
              </Stack>
            </Paper>
          ))}

          <Stack>
            <Typography variant={'h4'}>Notes</Typography>
            {user.id === observation.observer.user.id && (
              <React.Fragment>
                <Typography variant={'subtitle1'}>
                  Private Note (Visible to Me)
                </Typography>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Note value={observation.note_to_self} />
                </Paper>
              </React.Fragment>
            )}
            {user.id === observation.observer.user.id && (
              <Typography variant={'subtitle1'}>
                Public Note (Visible to Teacher)
              </Typography>
            )}
            <Paper elevation={3} sx={{ p: 3 }}>
              <Note value={observation.note_to_teacher} />
            </Paper>
          </Stack>
        </Stack>
      </PageContainer>
    </Page>
  )
}

export default WalkToReadReview
