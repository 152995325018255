import GradesApi from './api'
import { types } from './types'

export const fetchHistoricalGrades = (studentId, params) => {
  return dispatch => {
    return GradesApi.getHistoricalGrades(studentId, params).then(data => {
      dispatch({
        type: types.SET_HISTORICAL_GRADES,
        payload: {
          ...data,
          studentId
        }
      })
    })
  }
}
