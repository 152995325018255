import React from 'react'
import PropTypes from 'prop-types'
import { createNote } from 'api/notes'
import Button from '@mui/material/Button'
import Dialog from 'components/shared/dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import { useMutation } from '@tanstack/react-query'

export function NoteModal({
  show,
  setShow,
  refreshFeed,
  studentId,
  successMessage,
  handleUpdate,
  noteContent,
  action = 'create'
}) {
  const [value, setValue] = React.useState(noteContent || '')

  const mutation = useMutation(value => createNote(value, studentId), {
    onSuccess: (_data, _variables, _context) => {
      setValue('')
      successMessage && successMessage()
    },
    onSettled: () => {
      onModalClose()
    }
  })

  const handleSubmit = () => {
    if (action === 'create') {
      mutation.mutate(value)
    } else if (handleUpdate) {
      handleUpdate(value)
    }
  }

  const onModalClose = () => {
    refreshFeed && refreshFeed()
    setShow(false)
  }

  return (
    <Dialog
      onClose={() => onModalClose()}
      open={show}
      title={`${action === 'create' ? 'New' : 'Edit'} Student Note`}
    >
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            id="note_comment"
            label={'Note'}
            placeholder={
              'Add a note about this student (250 character max). This a public note that all users with access to this student can see.'
            }
            multiline
            maxLength={250}
            rows={4}
            value={value}
            onChange={event => setValue(event.target.value || '')}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          id="submit-note"
          variant="contained"
          color="primary"
          size="medium"
          onClick={handleSubmit}
        >
          {action === 'create' ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NoteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  studentId: PropTypes.number,
  successMessage: PropTypes.func,
  handleUpdate: PropTypes.func,
  noteContent: PropTypes.string,
  action: PropTypes.bool,
  refreshFeed: PropTypes.func,
  setShow: PropTypes.func
}

export default NoteModal
