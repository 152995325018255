import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
// import Button from '@mui/material/Button'
import {
  createChildStudy,
  fetchEligibleChildStudyStudents
} from 'modules/child-studies'
import ChildStudyReferralModal from '../shared/child-study-referral-modal'

export function NewChildStudyButton({
  createChildStudy,
  schoolId,
  fetchEligibleChildStudyStudents,
  fetchChildStudies
}) {
  const [childStudyReferralModalOpen, setChildStudyReferralModalOpen] =
    useState(false)

  const handleCreateChildStudy = (studentId, userId, concernIds, note) => {
    createChildStudy(studentId, userId, concernIds, note).then(_ => {
      fetchEligibleChildStudyStudents(schoolId)
      fetchChildStudies()
    })
  }

  const handleChildStudyModalHide = () => {
    setChildStudyReferralModalOpen(false)
  }

  // const handleChildStudyReferralModalStatus = () => {
  //   setChildStudyReferralModalOpen(!childStudyReferralModalOpen)
  // }

  useEffect(() => {
    fetchEligibleChildStudyStudents(schoolId)
  }, [schoolId])

  return (
    <div>
      {/* TODO: Re-enable after updating child study referral modal to reference new form
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={handleChildStudyReferralModalStatus}
        sx={{ whiteSpace: 'nowrap' }}
      >
        New Child Study
      </Button>
      */}
      <ChildStudyReferralModal
        show={childStudyReferralModalOpen}
        onHide={handleChildStudyModalHide}
        handleCreateChildStudy={handleCreateChildStudy}
      />
    </div>
  )
}

NewChildStudyButton.propTypes = {
  createChildStudy: PropTypes.func.isRequired,
  schoolId: PropTypes.number,
  fetchEligibleChildStudyStudents: PropTypes.func.isRequired,
  fetchChildStudies: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  schoolId: state.schools.schoolId
})

const mapDispatchToProps = {
  createChildStudy,
  fetchEligibleChildStudyStudents
}

export default connect(mapStateToProps, mapDispatchToProps)(NewChildStudyButton)
