import React from 'react'
import PropTypes from 'prop-types'
import {
  studentShape,
  gradPlanNodeShape
} from 'utils/grad-plan-progress-prop-types'

import { TestGroupTableRow } from './grad-planner'
import {
  CreditGroupTableRow,
  CreditRequirementTableRow
} from './credit-group-table-row'

function nodeIdWalk({ id, children }, ids = []) {
  return [id, ...children.flatMap(node => nodeIdWalk(node, ids))]
}

export function activeRequirementsCounter({
  node,
  gradPlanRequirementActions
}) {
  const nodeIds = nodeIdWalk(node)
  return gradPlanRequirementActions
    .filter(x => x.status == 'active')
    .map(x => x.ps_gp_node?.id)
    .filter(id => nodeIds.includes(id)).length
}

export function RequirementRows({
  student,
  node,
  gradPlanRequirementActions,
  requirementsEarnedAndEnrolled,
  level = -1
}) {
  return (
    <>
      {node.children.map((child, index) =>
        child.type === 3 ? (
          <TestGroupTableRow key={index} node={child} level={level + 1} />
        ) : child.type === 2 ? (
          <CreditGroupTableRow
            key={index}
            student={student}
            node={child}
            gradPlanRequirementActions={gradPlanRequirementActions}
            requirementsEarnedAndEnrolled={requirementsEarnedAndEnrolled}
            level={level + 1}
          />
        ) : child.type === 0 ? (
          <CreditRequirementTableRow
            key={index}
            student={student}
            node={child}
            gradPlanRequirementActions={gradPlanRequirementActions}
            requirementsEarnedAndEnrolled={requirementsEarnedAndEnrolled}
            level={level + 1}
          />
        ) : null
      )}
    </>
  )
}
RequirementRows.propTypes = {
  student: studentShape,
  node: gradPlanNodeShape.isRequired,
  gradPlanRequirementActions: PropTypes.array,
  level: PropTypes.number,
  requirementsEarnedAndEnrolled: PropTypes.array
}

export default RequirementRows
