import React from 'react'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import { Typography } from '@mui/material'

const lorem = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus a tortor non lorem vulputate imperdiet. Mauris tellus quam, faucibus efficitur felis tristique, feugiat volutpat mauris. Praesent non finibus est, non aliquam dui. Etiam elementum ullamcorper lectus vel euismod. Morbi a leo eget elit convallis congue. Nullam sit amet sem velit. Phasellus in sagittis enim, at varius lacus.',
  'Integer venenatis consectetur nibh, ac semper dui ornare quis. Suspendisse tempor consectetur blandit. Vestibulum rutrum lobortis sem, nec euismod ipsum ultricies nec. Sed ex massa, tristique vel felis vitae, gravida laoreet sem. In tincidunt magna lacus, et suscipit lectus tincidunt vitae. Ut sed ante placerat, egestas dui eget, tempus nulla. Phasellus porta justo risus.',
  'Quisque nisi risus, tempor ut odio in, euismod varius leo. Pellentesque vulputate augue eu velit dignissim, ac semper metus condimentum. Nullam cursus ac ex eget scelerisque. Mauris in lorem ut lacus gravida gravida. Donec in luctus dui. Duis mauris mauris, porttitor sit amet purus non, porttitor ornare dolor. Donec in volutpat risus, in mollis magna. Nullam dictum nisl mi, ut mattis justo aliquet nec. Maecenas varius turpis dignissim imperdiet scelerisque. Nam accumsan ipsum fermentum nisl maximus ultrices. Suspendisse suscipit quam nec orci auctor, quis condimentum justo porttitor. Quisque non nunc arcu. Quisque eu lectus tortor.',
  'Suspendisse eleifend volutpat lacinia. Aenean lobortis elit elit, sed consequat massa finibus vitae. Donec consectetur elementum tellus, id condimentum orci aliquam sit amet. Fusce consequat, sem vitae vehicula bibendum, neque sem faucibus mi, non pretium turpis arcu sed tortor. Cras porta vulputate odio faucibus tincidunt. Aliquam felis sapien, ullamcorper ac quam nec, rutrum viverra mauris. Morbi congue eu leo quis dapibus. Pellentesque tristique sollicitudin lectus eget egestas. Ut ut mauris eget lorem laoreet fermentum. Quisque et placerat ex, ut aliquet mauris.',
  'Aenean non ultrices ante. Vestibulum lobortis lorem urna, vitae dapibus quam efficitur vel. Sed eget ultricies libero, sed tempus urna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi non viverra justo. Morbi vel mattis mauris. Phasellus pulvinar auctor tellus, eget mattis lorem venenatis suscipit. Morbi viverra tincidunt volutpat. Sed eu sem nunc. Praesent sed purus vitae leo vulputate tincidunt. Proin nec nulla euismod, vestibulum erat et, aliquet ligula.'
]

export function Theme() {
  return (
    <Page name={'Tulsa Way'} title={'Tulsa Way'}>
      <PageContainer>
        <Typography variant="h1">Theme</Typography>
        <Typography variant="body1">
          The page provides sample of different usage of MUI components.
        </Typography>

        <Typography variant="h1">Heading 1</Typography>
        <Typography variant="h2">Heading 2</Typography>
        <Typography variant="h3">Heading 3</Typography>
        <Typography variant="h4">Heading 4</Typography>
        <Typography variant="h5">Heading 5</Typography>
        <Typography variant="h6">Heading 6</Typography>

        <Typography variant="subtitle1">subtitle1: {lorem[0]}</Typography>
        <Typography variant="subtitle2">subtitle2: {lorem[0]}</Typography>

        <Typography variant="body1">body1: {lorem[0]}</Typography>
        <Typography variant="body2">body2: {lorem[0]}</Typography>

        <Typography variant="button">button text</Typography>

        <Typography variant="caption">caption text</Typography>

        <Typography variant="overline">overline text</Typography>

        {lorem.map((x, index) => (
          <Typography key={index} variant="body">
            {x}
          </Typography>
        ))}
      </PageContainer>
    </Page>
  )
}
