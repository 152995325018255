import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import StudentReportOptions from './student-report-options'
import SectionReportOptions from './section-report-options'
import Dialog from 'components/shared/dialog'
import {
  Box,
  DialogContent,
  Autocomplete,
  TextField,
  Typography
} from '@mui/material'

function GenerateReportModal({ onHide, show }) {
  const [generateGroupBy, setGenerateGroupBy] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (show) {
      setGenerateGroupBy(null)
      setError(null)
    }
  }, [show])

  const handleOnHide = () => {
    setGenerateGroupBy(null)
    setError(null)
    onHide()
  }

  const handleGroupByChange = (event, newValue) => {
    setGenerateGroupBy(newValue ? newValue.value : null)
    setError(null)
  }

  const groupByOptions = [
    { value: 'student', label: 'Student' },
    { value: 'section', label: 'Section' },
    { value: 'gradeLevel', label: 'Grade Level' }
  ]

  const renderReportOptions = () => {
    switch (generateGroupBy) {
      case 'student':
        return <StudentReportOptions closeModal={handleOnHide} />
      case 'section':
        return <SectionReportOptions closeModal={handleOnHide} />
      case 'gradeLevel':
        return (
          <SectionReportOptions closeModal={handleOnHide} gradeLevel={true} />
        )
      default:
        return null
    }
  }

  return (
    <Dialog
      title={'Generate Reports'}
      open={show}
      onClose={handleOnHide}
      PaperProps={{
        sx: {
          minHeight: 300,
          width: '100%',
          maxWidth: 600
        }
      }}
    >
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Autocomplete
            id="generate-group-by-select"
            options={groupByOptions}
            value={
              groupByOptions.find(option => option.value === generateGroupBy) ||
              null
            }
            onChange={handleGroupByChange}
            renderInput={params => (
              <TextField
                {...params}
                label="Generate Report By"
                variant="outlined"
                error={!!error}
                helperText={error}
              />
            )}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </Box>

        {generateGroupBy ? (
          renderReportOptions()
        ) : (
          <Typography variant="body2" color="text.secondary">
            Please select a report type to continue.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  )
}

GenerateReportModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

export default GenerateReportModal
