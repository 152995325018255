import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { updateConcern, updateIntervention } from 'api/interventions'
import { createNote } from 'api/wellness'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { theme } from 'components/app/theme'
import { alert } from 'utils/alert'

function StatusForm({
  isClosed,
  isResolved,
  parent,
  queryName,
  setShow,
  show,
  queryKey
}) {
  const queryClient = useQueryClient()
  const [closedAt, setClosedAt] = useState(moment())
  const [note, setNote] = useState('')

  const determineInitialStatus = () => {
    if (isResolved) {
      return 'resolved'
    } else if (isClosed) {
      return 'closed'
    } else {
      return 'open'
    }
  }

  const initialStatus = determineInitialStatus()
  const [status, setStatus] = useState(initialStatus)

  const handleClose = () => {
    setShow(false)
  }

  const determineParams = () => {
    if (status === 'resolved') {
      return {
        closed_at: closedAt._d,
        is_resolved: true
      }
    } else if (status === 'closed') {
      return {
        closed_at: closedAt._d,
        is_resolved: false
      }
    } else {
      return {
        closed_at: null,
        is_resolved: false
      }
    }
  }

  const updateConcernMutation = useMutation(
    async ({ concernId, params }) => {
      await updateConcern(concernId, params)
        // only create note on concern if successfully updated
        .then(async () => {
          if (note) {
            const params = {
              data_type: 'Concern',
              data_id: parent.id,
              content: note,
              date: closedAt._d
            }
            await createNote(params)
          }
        })
        .catch(_err => {
          alert('There was an error updating the concern.', 'danger')
        })
    },
    {
      onSuccess: () => {
        handleClose()

        if (queryKey) {
          queryClient.invalidateQueries(queryKey)
        } else {
          setNote('')
          queryClient.invalidateQueries(['concern', parent.id])
        }
      }
    }
  )

  const updateInterventionMutation = useMutation(
    async ({ interventionId, params }) => {
      if (note) {
        const params = {
          data_type: 'Intervention',
          data_id: parent.id,
          content: note,
          date: closedAt._d
        }
        await createNote(params)
      }
      await updateIntervention(interventionId, params)
    },
    {
      onSuccess: () => {
        handleClose()
        setNote('')

        queryClient.invalidateQueries(['intervention', parent.id])
      }
    }
  )

  const handleSubmit = () => {
    const params = determineParams()
    if (queryName === 'concern') {
      updateConcernMutation.mutate({
        concernId: parent.id,
        params: params
      })
    } else if (queryName === 'intervention') {
      updateInterventionMutation.mutate({
        interventionId: parent.id,
        params: params
      })
    }
  }

  const determineName = () => {
    if (queryName === 'concern') {
      return parent.concern_name
    } else if (queryName === 'intervention') {
      return parent.targeted_intervention_name
    }
  }

  const determineDisabled = status => {
    return initialStatus === status
  }

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={show} onClose={handleClose}>
      <DialogTitle>
        <Grid item container justifyContent="space-between" xs={12}>
          <span>{determineName()}</span>
          <DatePicker
            renderInput={props => (
              <TextField
                {...props}
                sx={{ fontSize: theme.typography.fontSize }}
              />
            )}
            disableToolbar
            required
            fullWidth
            value={moment(closedAt).toDate()}
            inputFormat="MM/DD/YYYY"
            inputVariant="outlined"
            margin="normal"
            id="date-event-on"
            onChange={val => setClosedAt(val)}
            label="Date"
            autoOk={true}
          />
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            m: 4,
            mb: 6
          }}
        >
          <Box>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                label="Status"
                value={status}
                onChange={val => setStatus(val.target.value)}
              >
                <MenuItem
                  key="status-option-open"
                  value="open"
                  disabled={determineDisabled('open')}
                >
                  Open
                </MenuItem>
                <MenuItem
                  key="status-option-resolved"
                  value="resolved"
                  disabled={determineDisabled('resolved')}
                >
                  Resolved
                </MenuItem>
                <MenuItem
                  key="status-option-closed"
                  value="closed"
                  disabled={determineDisabled('closed')}
                >
                  Closed
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <TextField
              id="status-note"
              label="Note (Optional)"
              value={note}
              onChange={e => setNote(e.target.value)}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
            />
            <Typography variant="caption">
              Any comments to document why the concern is being
              resolved/closed/opened
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={determineDisabled(status)}
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

StatusForm.propTypes = {
  isClosed: PropTypes.bool,
  isResolved: PropTypes.bool,
  isArchived: PropTypes.bool,
  parent: PropTypes.object.isRequired,
  queryName: PropTypes.string.isRequired,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  queryKey: PropTypes.array
}

export default StatusForm
