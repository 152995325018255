import React from 'react'
import { withRouterPropTypes } from 'utils/react-router-prop-types'

import { Route, Switch } from 'react-router-dom'

import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

import TulsaWayBadge from '../../../assets/images/tulsa-way/tulsa-way-badge.png'

import TeacherSectionSelectors from './teacher-section-selectors'
import WalkToReadObserve from './walk-to-read/observe'
import ObservationHeader from './observation-header'
import {
  useWalkToRead,
  useWalkToReadMutation
} from 'hooks/tulsa-way/useWalkToRead'
import DisableFreshWidget from 'components/shared/disable-fresh-widget'
import { isNull } from 'lodash'

import ConfirmModalButton from 'components/shared/confirm-modal/button'
import { get } from 'utils/ajax'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const initForm = {
  id: null,
  observation_type: null,
  teacher_id: null,
  section_id: null,
  started_at: null,
  ended_at: null,
  note_to_teacher: '',
  note_to_self: '',
  lookForsObserved: {}
}

const observationToForm = observation => {
  const {
    id,
    observation_type,
    observee_id: teacher_id,
    section_id,
    note_to_teacher,
    note_to_self,
    started_at,
    ended_at
  } = {
    observee_id: null,
    ...initForm,
    ...observation
  }

  const lookForsObserved = (observation?.look_fors_observed || []).reduce(
    (prev, { tulsa_way_look_for_id, was_observed }) => {
      prev[tulsa_way_look_for_id] = was_observed
      return prev
    },
    {}
  )

  return {
    id,
    observation_type,
    teacher_id,
    section_id,
    note_to_teacher,
    note_to_self,
    started_at: started_at && new Date(started_at),
    ended_at: ended_at && new Date(ended_at),
    lookForsObserved
  }
}

const formToObservation = form => {
  const { lookForsObserved, ...data } = form

  data.look_fors_observed = lookForsObserved

  return data
}

export const useObservationTypeLookFors = (observation, options) => {
  const { observation_type, tulsa_way_rubric_id } = { ...observation }
  const params = { observation_type, tulsa_way_rubric_id }
  const enabled = Boolean(observation_type && tulsa_way_rubric_id)
  const queryKey = [
    'tulsa-way:observation-type-look-fors',
    observation_type,
    tulsa_way_rubric_id
  ]
  const queryFn = () =>
    get('/api/web/tulsa_way/walk_to_read/observation_type_look_fors', {
      params
    })
  const query = useQuery(queryKey, queryFn, {
    initialData: [],
    enabled,
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}

export function TulsaWayWalkToRead({ match, history }) {
  const { params } = match
  const [form, setForm] = React.useState(params.id ? null : initForm)

  const { data: observation } = useWalkToRead(params.id, {
    onSuccess: data => {
      if (!form) {
        setForm(observationToForm(data))
      }
    }
  })
  const observationMutation = useWalkToReadMutation()
  const observationType = observation?.observation_type || 'Walk to Read'
  const { data: observationTypeLookFors } =
    useObservationTypeLookFors(observation)

  const handleSelectorChange = ({ teacher_id, section_id }) => {
    setForm(state => ({ ...state, teacher_id, section_id }))
  }

  const handleCancel = () => {
    history.push('/tulsa-way')
  }

  const handleObserve = () => {
    const started_at = form.started_at || new Date()
    const observation_type = observationType
    const variables = formToObservation({
      ...form,
      observation_type,
      started_at
    })

    observationMutation.mutate(variables, {
      onSuccess: (data, _variables, _context) => {
        setForm(observationToForm(data))
        history.replace(`${match.url}/${data.id}/observe`)
      }
    })
  }

  const handleDraft = () => {
    const updated_at = new Date()
    const variables = formToObservation({ ...form, updated_at })

    observationMutation.mutate(variables, {
      onSuccess: () => {
        history.push('/tulsa-way')
      }
    })
  }

  const handleComplete = () => {
    const started_at = form.started_at || new Date()
    const ended_at = new Date()
    const variables = formToObservation({ ...form, started_at, ended_at })
    observationMutation.mutate(variables, {
      onSuccess: () => {
        history.push('/tulsa-way')
      }
    })
  }

  const lookForObserved = (id, checked) => {
    setForm(state => {
      const lookForsObserved = { ...state.lookForsObserved, [id]: checked }
      const form = { ...state, lookForsObserved }
      const variables = formToObservation(form)

      observationMutation.mutate(variables)

      return form
    })
  }

  return (
    <Container sx={theme => ({ paddingY: theme.spacing(3) })}>
      <DisableFreshWidget />

      <Stack spacing={5}>
        <Stack
          direction={'row'}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography variant={'h2'}>{observationType}</Typography>

          <img src={TulsaWayBadge} style={{ height: '64px' }} />
        </Stack>

        <Switch>
          <Route path={match.url} exact>
            <TeacherSectionSelectors
              onChange={handleSelectorChange}
              observationType={'Walk to Read'}
            />
            <Stack direction={'row-reverse'}>
              <Button
                onClick={handleObserve}
                variant={'contained'}
                disabled={observationMutation.isLoading || !form?.section_id}
              >
                Observe
              </Button>
              <Button
                onClick={handleCancel}
                disabled={observationMutation.isLoading}
              >
                Cancel
              </Button>
            </Stack>
          </Route>

          <Route path={`${match.url}/observe`}>
            {observation && form && (
              <React.Fragment>
                <ObservationHeader {...{ observation }} />
                <WalkToReadObserve
                  {...{
                    form,
                    setForm,
                    lookForObserved,
                    observationTypeLookFors
                  }}
                />
                <Stack direction={'row-reverse'}>
                  {isNull(observation.ended_at) ? (
                    <React.Fragment>
                      <ConfirmModalButton
                        modalMessage={
                          'Once submitted, an email will be sent to the observee with a link to the observation. The observee will only be able to see the "Note to Teacher" section. All notes entered in the "Note to Self" section will remain private.'
                        }
                        cancelButtonText="No"
                        id={'submit-informal-observation'}
                        onConfirm={handleComplete}
                        disabled={observationMutation.isLoading}
                        confirmTitle="Are you sure you would like to submit this observation?"
                      >
                        <Typography variant={'button'}>Submit</Typography>
                      </ConfirmModalButton>
                      <Button
                        onClick={handleDraft}
                        disabled={observationMutation.isLoading}
                      >
                        Save as Draft
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      onClick={handleComplete}
                      variant={'contained'}
                      disabled={observationMutation.isLoading}
                    >
                      Done
                    </Button>
                  )}
                </Stack>
              </React.Fragment>
            )}
          </Route>
        </Switch>
      </Stack>
    </Container>
  )
}

TulsaWayWalkToRead.propTypes = {
  ...withRouterPropTypes
}

export default TulsaWayWalkToRead
