import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {
  studentShape,
  gradPlanNodeShape
} from 'utils/grad-plan-progress-prop-types'
import Dialog from 'components/shared/dialog'
import { currentSchoolYear } from 'utils'
import {
  createGradPlanRequirementAction,
  updateGradPlanRequirementAction
} from 'api/grad-plan-requirement-actions'
import { useQueryClient } from '@tanstack/react-query'

export function RequirementActionDialog({
  student,
  node,
  action,
  children,
  ...props
}) {
  const queryClient = useQueryClient()

  const [plannedSchoolYear, setPlannedSchoolYear] = useState('')
  const [plannedTerm, setPlannedTerm] = useState('')
  const [methodOfCompletion, setMethodOfCompletion] = useState('')
  const [notes, setNotes] = useState('')

  const [dialogOpen, setDialogOpen] = useState(false)

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleDialogClose = () => {
    setDialogOpen(false)

    if (props.onClose) {
      props.onClose()
    }
  }

  const handleSubmit = async () => {
    const params = {
      student_id: student.id,
      ps_gp_node_id: node.id,
      status: 'active',
      planned_school_year: plannedSchoolYear,
      planned_term: plannedTerm,
      method_of_completion: methodOfCompletion,
      notes: notes
    }

    if (action) {
      await updateGradPlanRequirementAction(action.id, params)
    } else {
      await createGradPlanRequirementAction(params)
    }

    handleDialogClose()
    queryClient.invalidateQueries({
      queryKey: ['grad-plan-requirement-actions', student.id]
    })
  }

  const childFunction = typeof children === 'function' ? children : () => {}
  const childContent = typeof children !== 'function' ? children : null

  React.useEffect(() => {
    if (dialogOpen && action) {
      setPlannedSchoolYear(action.planned_school_year)
      setPlannedTerm(action.planned_term)
      setMethodOfCompletion(action.method_of_completion)
      setNotes(action.notes)
    }
  }, [dialogOpen, action])

  return (
    <>
      {childFunction({ openDialog: handleDialogOpen })}

      <Dialog
        onClick={event => {
          event.stopPropagation()
        }}
        open={dialogOpen}
        onClose={handleDialogClose}
        title={`${action ? 'Edit' : 'Create'} Completion Plan for ${node.name}`}
        {...props}
      >
        <DialogContent>
          <Stack>
            <Typography>
              When/how will the student complete this credit type?
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="planned-school-year-label">
                Planned School Year
              </InputLabel>
              <Select
                id="planned-school-year-selector"
                labelId="planned-school-year-label"
                label="Planned School Year"
                onChange={val => setPlannedSchoolYear(val.target.value)}
                value={plannedSchoolYear}
              >
                <MenuItem value={currentSchoolYear}>{`${
                  currentSchoolYear - 1
                }-${currentSchoolYear}`}</MenuItem>
                <MenuItem
                  value={currentSchoolYear + 1}
                >{`${currentSchoolYear}-${currentSchoolYear + 1}`}</MenuItem>
                <MenuItem value={currentSchoolYear + 2}>{`${
                  currentSchoolYear + 1
                }-${currentSchoolYear + 2}`}</MenuItem>
                <MenuItem value={currentSchoolYear + 3}>{`${
                  currentSchoolYear + 2
                }-${currentSchoolYear + 3}`}</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="planned-term-label">Planned Term</InputLabel>
              <Select
                id="planned-term-selector"
                labelId="planned-term-label"
                label="Planned Term"
                value={plannedTerm}
                onChange={val => setPlannedTerm(val.target.value)}
              >
                <MenuItem value={'S1'}>S1</MenuItem>
                <MenuItem value={'S2'}>S2</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="method-of-completion-label">
                Method of Completion
              </InputLabel>
              <Select
                id="method-of-completion-selector"
                labelId="method-of-completion-label"
                label="Method of Completion"
                value={methodOfCompletion}
                onChange={val => setMethodOfCompletion(val.target.value)}
              >
                <MenuItem
                  value={
                    'Student is/will be enrolled in credit recovery at Twilight'
                  }
                >
                  Student is/will be enrolled in credit recovery at Twilight
                </MenuItem>
                <MenuItem
                  value={
                    'Student is/will be enrolled in Tulsa Tech Success Center (non-TPS)'
                  }
                >
                  Student is/will be enrolled in Tulsa Tech Success Center
                  (non-TPS)
                </MenuItem>
                <MenuItem
                  value={
                    'Student is/will be enrolled in on-site credit recovery '
                  }
                >
                  Student is/will be enrolled in on-site credit recovery{' '}
                </MenuItem>
                <MenuItem
                  value={
                    'Student is/will be currently enrolled in course on-site'
                  }
                >
                  Student is/will be currently enrolled in course on-site
                </MenuItem>
                <MenuItem
                  value={
                    'Student is/will be enrolled in Tulsa Community College'
                  }
                >
                  Student is/will be enrolled in Tulsa Community College
                </MenuItem>
                <MenuItem
                  value={'Student is/will be enrolling in New Vision School'}
                >
                  Student is/will be enrolling in New Vision School
                </MenuItem>
                <MenuItem
                  value={'Student is/will be enrolling in Summer School'}
                >
                  Student is/will be enrolling in Summer School
                </MenuItem>
                <MenuItem
                  value={
                    'Student is/will be completing non-course graduation requirements: ICAP, WBL, CPR, PFL, and FAFSA.'
                  }
                >
                  Student is/will be completing non-course graduation
                  requirements: ICAP, WBL, CPR, PFL, and FAFSA.
                </MenuItem>
                <MenuItem value={'Current school outside of the school day'}>
                  Current school outside of the school day
                </MenuItem>
                <MenuItem value={'Current school outside of the school year'}>
                  Current school outside of the school year
                </MenuItem>
                <MenuItem value={'Other (see grad check notes)'}>
                  Other (see grad check notes)
                </MenuItem>
              </Select>
            </FormControl>
            <Typography>Any other notes you wish to provide?</Typography>
            <TextField
              onChange={val => setNotes(val.target.value)}
              value={notes}
              label="Notes"
              id="notes-field"
              multiline
              rows={3}
            />
            {childContent}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            size={'small'}
            sx={{ m: 1 }}
            variant={'contained'}
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
          <Button
            size={'small'}
            sx={{ m: 1 }}
            variant={'outlined'}
            onClick={() => handleDialogClose()}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
RequirementActionDialog.propTypes = {
  student: studentShape,
  node: gradPlanNodeShape.isRequired,
  action: PropTypes.object,
  gradPlanRequirementActions: PropTypes.array,
  onClose: PropTypes.func,
  children: PropTypes.any
}

export default RequirementActionDialog
