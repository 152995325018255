import React from 'react'
import PropTypes from 'prop-types'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Title,
  Legend,
  XAxis,
  YAxis,
  ScatterSeries,
  Tooltip
} from 'react-jsx-highcharts'

function MapChart({ chartData }) {
  if (chartData.length < 1) {
    return null
  }

  const chartDataValues = Object.values(chartData[0]).flat()
  if (chartDataValues.length < 1) {
    return null
  }

  const pointFormat = `
      <h5 class="text-center" style="color:{series.color};padding:0;font-size:14px;">{point.name}</h5>
      <table>
        <tr>
          <th></th>
          <th>Reading</th>
          <th>Math<th>
        <tr>
        <tr>
          <td>Score</td>
          <td class="text-center">{point.reading}</td>
          <td class="text-center">{point.math}<td>
        <tr>
        <tr>
          <td>Percentile</td>
          <td class="text-center">{point.x}</td>
          <td class="text-center">{point.y}<td>
        <tr>
        <tr>
          <td>Projected Growth</td>
          <td class="text-center">{point.reading_projected}</td>
          <td class="text-center">{point.math_projected}</td>
        </tr>
        <tr>
          <td>Actual Growth</td>
          <td class="text-center">{point.reading_actual}</td>
          <td class="text-center">{point.math_actual}</td>
        </tr>
        <tr>
          <td>Met Growth Goal</td>
          <td class="text-center">{point.reading_growth}</td>
          <td class="text-center">{point.math_growth}</td>
        </tr>
      </table>
    `

  return (
    <HighchartsChart>
      <Chart />
      <Title>MAP Proficiency and Growth</Title>
      <Legend layout="horizontal" align="center" verticalAlign="bottom" />
      <Tooltip
        headerFormat=""
        pointFormat={pointFormat}
        shared={true}
        crosshairs={true}
        useHTML={true}
      />
      <XAxis id="reading-percentiles" max={100} endOnTick={false}>
        <XAxis.Title>Reading Proficiency Percentile</XAxis.Title>
      </XAxis>

      <YAxis id="math-percentiles" max={100} endOnTick={false}>
        <YAxis.Title>Math Proficiency Percentile</YAxis.Title>
      </YAxis>

      <ScatterSeries
        id="met_both"
        name="Met Both"
        data={chartData[0]['met_both']}
        key="met_both"
        color="#6CCD40"
        marker={{ symbol: 'circle', radius: 5 }}
        animation={false}
      />

      <ScatterSeries
        id="met_reading"
        name="Met Reading Growth Goal"
        data={chartData[0]['met_reading']}
        key="met_reading"
        color="#ebac13"
        marker={{ symbol: 'circle', radius: 5 }}
        animation={false}
      />

      <ScatterSeries
        id="met_math"
        name="Met Math Growth Goal"
        data={chartData[0]['met_math']}
        key="met_math"
        color="#5bc0de"
        marker={{ symbol: 'circle', radius: 5 }}
        animation={false}
      />

      <ScatterSeries
        id="met_neither"
        name="Met Neither"
        data={chartData[0]['met_neither']}
        key="met_neither"
        color="#E84F25"
        marker={{ symbol: 'circle', radius: 5 }}
        animation={false}
      />
    </HighchartsChart>
  )
}

MapChart.propTypes = {
  chartData: PropTypes.array.isRequired
}

export default withHighcharts(MapChart, window.Highcharts)
