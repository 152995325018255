import React from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@mui/material'

const Spinner = ({ size, style, centered, ...props }) => {
  const styles = computeStyles({ centered })

  return (
    <Box className="Spinner" sx={{ ...styles.container, style }}>
      <CircularProgress color="primary" size={size} {...props} />
    </Box>
  )
}

const computeStyles = ({ centered }) => ({
  container: {
    display: 'flex',
    justifyContent: centered ? 'center' : 'flex-start',
    width: '100%'
  }
})

Spinner.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object,
  centered: PropTypes.bool
}

Spinner.defaultProps = {
  size: 20,
  style: {}
}

export default Spinner
