import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PieChart } from 'react-minimal-pie-chart'
import { useTheme } from '@mui/material/styles'
import { isNumber, round } from 'lodash'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useHistory } from 'react-router-dom'
import { alert } from 'utils/alert'

const PieChartFiveSlices = ({
  one,
  two,
  three,
  four,
  five,
  height,
  includeSumOfValues = true,
  meta = {}
}) => {
  const theme = useTheme()
  const history = useHistory()
  const radius = 43
  const customHeight = isNumber(height) ? true : false
  const heightProps = { height: customHeight ? `${height}rem` : '24rem' }
  const [displayAs, setDisplayAs] = useState('percentage')
  const [hovered, setHovered] = useState(null)

  const chartData = []
  const legend = []

  if (one && one.value > 0) {
    chartData.push({
      color: theme.palette.success.main,
      ...one
    })
    legend.push(one.title)
  }
  if (two && two.value > 0) {
    chartData.push({
      color: theme.palette.warning.light,
      ...two
    })
    legend.push(two.title)
  }
  if (three && three.value > 0) {
    chartData.push({
      color: theme.palette.error.light,
      ...three
    })
    legend.push(three.title)
  }
  if (four && four.value > 0) {
    chartData.push({
      color: theme.palette.warning.dark,
      ...four
    })
    legend.push(four.title)
  }
  if (five && five.value > 0) {
    chartData.push({
      color: theme.palette.error.dark,
      ...five
    })
    legend.push(five.title)
  }

  const indices = [...Array(chartData.length).keys()].map(i => i)
  const sumOfValues = chartData.reduce((n, { value }) => n + value, 0)

  const navigateToChartDataTable = index => {
    const { pathname, query_params, alert_text } =
      chartData[index]?.options || {}
    const queryString =
      query_params && new URLSearchParams(query_params).toString()

    if (pathname) {
      const params = query_params ? { search: queryString } : {}
      history.push({
        pathname,
        ...params
      })

      alert(
        alert_text ||
          `We've filtered the page to show ${legend[index]} students`
      )
    }
  }

  const legendItem = (color, label, index) => {
    return (
      <Button
        key={`legend-item-${index}`}
        variant="contained"
        disableFocusRipple={true}
        disableRipple={true}
        disableElevation={true}
        sx={{
          'width': '100%',
          'color': 'black',
          'backgroundColor': color,
          '&.MuiButtonBase-root:hover': {
            bgcolor: color,
            cursor: 'pointer'
          }
        }}
        onMouseOver={() => setHovered(index)}
        onMouseOut={() => setHovered(null)}
        onClick={() => navigateToChartDataTable(index)}
      >
        {label}
      </Button>
    )
  }

  return (
    <>
      {/* DataAttributeBox is used by specs */}
      <DataAttributeBox data={meta} />
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack justifyContent="center" spacing={1} sx={{ m: 4 }}>
            {legend &&
              indices.map(index =>
                legendItem(chartData[index]?.color, legend[index], index)
              )}

            <Typography variant="body1">Display As:</Typography>
            <ToggleButtonGroup
              color="primary"
              value={displayAs}
              exclusive
              onChange={val => setDisplayAs(val.target.value)}
              aria-label="Display as"
            >
              <ToggleButton value="percentage">%</ToggleButton>
              <ToggleButton value="number">#</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>

        <Grid item xs={legend ? 8 : 12}>
          <Stack direction="column" alignItems="center">
            <PieChart
              style={{ ...heightProps }}
              radius={radius}
              label={({ dataEntry }) =>
                displayAs === 'percentage'
                  ? `${round(dataEntry.percentage, 0)}%`
                  : dataEntry.value
              }
              labelStyle={{
                fontSize: '0.33rem',
                fontFamily: theme.typography.body1.fontFamily
              }}
              segmentsStyle={{ cursor: 'pointer' }}
              segmentsShift={index => (index === hovered ? 6 : 1)}
              data={chartData}
              onClick={(_, index) => navigateToChartDataTable(index)}
              onMouseOver={(_, index) => setHovered(index)}
              onMouseOut={() => setHovered(null)}
            />
            {!!includeSumOfValues && (
              <Typography variant="body1">n = {sumOfValues}</Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

PieChartFiveSlices.propTypes = {
  one: PropTypes.object,
  two: PropTypes.object,
  three: PropTypes.object,
  four: PropTypes.object,
  five: PropTypes.object,
  height: PropTypes.number,
  includeSumOfValues: PropTypes.bool
}

const DataAttributeBox = ({ data }) => {
  const dataAttributes = Object.entries(data).reduce((obj, [key, value]) => {
    obj[`data-${key}`] = value
    return obj
  }, {})

  return <Box {...dataAttributes} id="pie-chart-meta" />
}

export default PieChartFiveSlices
