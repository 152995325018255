import { get, patch } from 'utils/ajax'
import { alert } from 'utils/alert'

const getGoals = params => {
  return get(`/api/web/goals/${params.id}`)
    .then(goals => {
      return goals
    })
    .catch(_err => {
      alert('An error occurred while fetching goals', 'danger')
    })
}

const updateGoal = params => {
  return patch(`/api/web/goals/${params.goal_id}`, {
    goal: params
  })
}

export default {
  getGoals,
  updateGoal
}
