import React from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Chip
} from '@mui/material'

export default function BehaviorRiskTable() {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Risk</TableCell>
          <TableCell>Threshold</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Chip color="success" label="Low Risk" />
          </TableCell>
          <TableCell>Behavior Score = 0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Chip color="default" label="At Risk" />
          </TableCell>
          <TableCell>1.0 &gt; Behavior Score &gt; 0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Chip color="warning" label="High Risk" />
          </TableCell>
          <TableCell>5.0 &gt; Behavior Score &ge; 1.0</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Chip color="error" label="Critical" />
          </TableCell>
          <TableCell>Behavior Score &ge; 5</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
