import React from 'react'
import PropTypes from 'prop-types'
import CompareArrow from 'shared/table/compare_arrow'

const Comparison = ({ current, previous, inverse = false, percentage = true }) => {
  if (current === null || previous === null) {
    return null
  }

  const diff = current - previous

  return <CompareArrow inverse={inverse} value={diff} colorText={true} percentage={percentage} />
}

Comparison.propTypes = {
  current: PropTypes.number,
  previous: PropTypes.number,
  inverse: PropTypes.bool,
  percentage: PropTypes.bool
}

export default Comparison
