import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { default as SharedPage } from 'components/shared/page'
import PageContainer from 'shared/page-container'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  Link as MuiLink
} from '@mui/material'
import { Link, Route, Switch } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getAcademicAssessmentAmirasDyslexiaFlagged } from 'api/academic-assessments/amira'
import moment from 'moment'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { handleRailsRequestJsResponse } from 'utils/handleRailsRequestJsResponse'
import { isArray, isString } from 'lodash'
import { useDebounce } from 'hooks/useDebounce'
import { styled } from '@mui/system'
import { withRouterPropTypes } from 'utils/react-router-prop-types'
import SlipDyslexiaButton from 'shared/slip-dyslexia-button'

export const basePath = '/academic_assessment_amiras'

export function Routes() {
  return (
    <Switch>
      <Route
        exact
        path={`${basePath}`}
        component={AcademicAssessmentAmiraIndex}
      />
    </Switch>
  )
}
Routes.basePath = basePath

function Page({ children, breadcrumbs = [], breadcrumb, ...props }) {
  return (
    <SharedPage {...props}>
      <PageContainer>
        <Breadcrumbs aria-label="breadcrumb" variant={'overline'}>
          <MuiLink component={Link} to="/dyslexia_addendum_slips">
            Dyslexia Addendums
          </MuiLink>

          {breadcrumbs.map((crumb, index) =>
            isString(crumb) ? (
              <Typography key={index} variant="overline" color={'text.primary'}>
                {crumb}
              </Typography>
            ) : (
              <MuiLink key={index} component={Link} {...crumb} />
            )
          )}

          <Typography variant="overline" color={'text.primary'}>
            {breadcrumb || props.title}
          </Typography>
        </Breadcrumbs>

        <Typography variant={'h1'}>{props.title}</Typography>

        {children}
      </PageContainer>
    </SharedPage>
  )
}

Page.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.string, MuiLink.propTypes])
  ),
  breadcrumb: PropTypes.string,
  ...SharedPage.propTypes
}

const StripedTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  }
}))

const AcademicAssessmentAmiraIndex = () => {
  const [tableMutateEvents, _setTableMutateEvents] = useState(0)
  const [search, _setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)
  const [page, setPage] = useState(1)
  const handleChangePage = (_, value) => {
    setPage(value)
  }

  const { data: academic_assessment_amira } = useQuery(
    [
      'AcademicAssessmentAmiraDyslexiaFlagged-index',
      tableMutateEvents,
      debouncedSearch,
      page
    ],
    async () => {
      const params = { search: search, page: page }
      const response = await getAcademicAssessmentAmirasDyslexiaFlagged(params)
      const json = await handleRailsRequestJsResponse(response)
      return json
    },
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  const assessments = academic_assessment_amira?.data
  const pagination = academic_assessment_amira?.pagination

  return (
    <Page
      name="All Amira Assessments"
      title="Dyslexia Flagged by Amira Assessment"
      breadcrumb={'Dyslexia Flagged'}
    >
      <TableContainer component={Paper}>
        <Table id="academic_assessment_amira-index-table">
          <TableHead>
            <TableRow>
              <TableCell>Student Number</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>School Name</TableCell>
              <TableCell>Assessment Date</TableCell>
              <TableCell>Risk Indicator</TableCell>
              <TableCell>Risk Category</TableCell>
              <TableCell>Create/View Addendum</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isArray(assessments) &&
              assessments.map(assessment => (
                <StripedTableRow key={assessment.id}>
                  <TableCell>{assessment.student?.number}</TableCell>
                  <TableCell>{`${assessment.student?.first_name} ${assessment.student?.last_name}`}</TableCell>
                  <TableCell>{assessment.school?.name}</TableCell>
                  <TableCell>
                    {moment(assessment.assessment_date).fromNow()}
                  </TableCell>
                  <TableCell>{assessment.dyslexia_risk_indicator}</TableCell>
                  <TableCell>{assessment.dyslexia_risk_category}</TableCell>
                  <TableCell>
                    <SlipDyslexiaButton
                      studentId={assessment.student?.id}
                      slipId={null}
                      dyslexiaAddendumId={
                        assessment.dyslexia_addendums?.[0]?.id
                      }
                      dyslexiaAssessmentResults={'at_risk'}
                    />
                  </TableCell>
                </StripedTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        component="div"
        count={pagination?.total_pages || 100}
        page={page}
        onChange={handleChangePage}
      />
    </Page>
  )
}

AcademicAssessmentAmiraIndex.propTypes = {
  ...withRouterPropTypes
}

export default AcademicAssessmentAmiraIndex

export { AcademicAssessmentAmiraIndex }
