import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  DialogContent,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  styled,
  TextField
} from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'
import SharedDialog from 'components/shared/dialog'
import StudentListItem from './list-items/student'
import TeacherListItem from './list-items/teacher'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'hooks/useDebounce'
import { useSearch } from 'hooks/useSearch'

const Dialog = styled(SharedDialog)(() => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start'
  }
}))

const ListItemComponentMap = {
  Teacher: TeacherListItem,
  Student: StudentListItem
}

StudentSelector.propTypes = {
  redirectTo: PropTypes.string.isRequired,
  buttonVariant: PropTypes.string,
  buttonSize: PropTypes.string
}

export default function StudentSelector({
  redirectTo,
  buttonVariant = 'contained',
  buttonSize = 'large'
}) {
  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [results, setResults] = useState(null)
  const debouncedSearch = useDebounce(search, 500)

  const options = { enabled: open && debouncedSearch.length > 0 }
  const params = { search: debouncedSearch }
  const { isLoading, data } = useSearch({ params, options })

  const searching = search && isLoading
  const noResults = (results || []).length === 0
  const resultsAndComponent = (results || [])
    .map(result => [result, ListItemComponentMap[result.source_record_type]])
    .filter(([_result, ListItemComponent]) => Boolean(ListItemComponent))

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSearch('')
    setResults(null)
  }

  const handleClick =
    ({ source_record_type, source_record_id }) =>
    _event => {
      if (source_record_type == 'Student') {
        history.push(`${redirectTo}${source_record_id}`)
      }

      handleClose()
    }

  React.useEffect(() => {
    if (!search.length) {
      setResults(null)
    } else if (data) {
      const filteredData = data.filter(
        result => result.source_record_type === 'Student'
      )
      setResults(filteredData)
    }
  }, [search, data])

  return (
    <React.Fragment>
      <Button
        variant={buttonVariant}
        color="secondary"
        size={buttonSize}
        onClick={handleOpen}
        startIcon={<SearchIcon />}
      >
        Select Student
      </Button>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        title={
          <Box>
            <TextField
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              autoFocus
              margin="dense"
              id="name"
              name="search"
              hiddenLabel
              size={'small'}
              placeholder={'Search…'}
              value={search}
              onChange={e => setSearch(e.target.value)}
              fullWidth
              variant="filled"
              sx={{ my: 0 }}
            />
            {searching ? (
              <LinearProgress />
            ) : (
              <LinearProgress variant={'determinate'} value={100} />
            )}
          </Box>
        }
      >
        <DialogContent>
          <List dense={true} className="searchResults">
            {noResults ? (
              <ListItem>
                <ListItemText
                  primary={
                    results
                      ? 'No results found'
                      : search
                      ? 'Searching…'
                      : 'Type to search…'
                  }
                />
              </ListItem>
            ) : (
              resultsAndComponent.map(([r, ListItemComponent], i) => (
                <ListItemComponent
                  className={`searchResult-${i}`}
                  key={`searchResult-${i}`}
                  searchResult={r}
                  onClick={handleClick(r)}
                />
              ))
            )}
          </List>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
