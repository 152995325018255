import PropTypes from 'prop-types'

export const masteryLevelShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired
})

export const indicatorShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  tulsa_way_development_area_id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  mastery_level: masteryLevelShape.isRequired
})

export const developmentAreaShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired,
  indicators: PropTypes.arrayOf(indicatorShape).isRequired
})
