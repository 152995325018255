import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { isEmpty } from 'lodash'

function PreviewHeader({ to, from, cc = '', bcc = '', subject }) {
  const Th = props => (
    <Box component={'th'} sx={{ width: '1px', paddingRight: 2 }} {...props} />
  )

  return (
    <table>
      <tbody>
        <tr>
          <Th>From:</Th>
          <td>{from}</td>
        </tr>
        <tr>
          <Th>To:</Th>
          <td>{to}</td>
        </tr>
        {!isEmpty(cc) ? (
          <tr>
            <Th>Cc:</Th>
            <td>{cc}</td>
          </tr>
        ) : null}
        {!isEmpty(bcc) ? (
          <tr>
            <Th>Bcc:</Th>
            <td>{bcc}</td>
          </tr>
        ) : null}
        <tr>
          <Th>Subject:</Th>
          <td>{subject}</td>
        </tr>
      </tbody>
    </table>
  )
}

PreviewHeader.propTypes = {
  to: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  cc: PropTypes.string,
  bcc: PropTypes.string,
  subject: PropTypes.string.isRequired
}

export default PreviewHeader
