import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { round, last } from 'lodash'
import Table from './table'
import RiskScoreHeader from './risk-score-header'
import {
  fetchDisciplines,
  fetchRiskScores,
  selectors as disciplineSelectors
} from 'modules/disciplines'
import { selectors } from 'modules/shared'
import Page from 'shared/page'
import { Stack, Typography } from '@mui/material'

class Discipline extends React.Component {
  static propTypes = {
    disciplines: PropTypes.array.isRequired,
    isParentPortal: PropTypes.bool,
    riskScores: PropTypes.array.isRequired,
    fetchDisciplines: PropTypes.func.isRequired,
    fetchRiskScores: PropTypes.func.isRequired,
    studentId: PropTypes.number.isRequired
  }

  state = {
    isLoading: false
  }

  handleFetch = callback => {
    const { studentId } = this.props
    this.setState({ loading: true })
    callback(studentId).then(_ => this.setState({ isLoading: false }))
  }

  componentDidMount() {
    this.handleFetch(this.props.fetchDisciplines)
    this.handleFetch(this.props.fetchRiskScores)
  }

  render() {
    const { disciplines, isParentPortal, riskScores } = this.props
    const { isLoading } = this.state
    const latestRiskScore =
      riskScores.length > 0 ? round(last(riskScores).score, 2) : null

    return (
      <Page name="Student Discipline" title="Discipline">
        <Stack spacing={2}>
          <Typography variant="h3">Discipline</Typography>
          <Table data={disciplines} loading={isLoading} />
          <RiskScoreHeader
            isParentPortal={isParentPortal}
            riskScore={latestRiskScore}
          />
        </Stack>
      </Page>
    )
  }
}

const mapStateToProps = (state, { studentId }) => ({
  disciplines: disciplineSelectors.disciplines(state, studentId),
  isParentPortal: selectors.isParentPortal(state),
  riskScores: disciplineSelectors.riskScores(state, studentId)
})

const mapDispatchToProps = {
  fetchDisciplines,
  fetchRiskScores
}

export default connect(mapStateToProps, mapDispatchToProps)(Discipline)
