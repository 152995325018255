import { destroy, post } from 'utils/ajax'

export const anonymousMode = async () => {
  await post('/anonymous_mode')
  location.reload()
}

export const destroyAnonymousMode = async () => {
  await destroy('/anonymous_mode')
  location.reload()
}

export const destroyImpersonation = async user_id => {
  await destroy(`/admins/users/${user_id}/impersonations`)
  location.assign('/admins')
}
