import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTeacherSections } from 'hooks/useTeacherSections'
import SchoolAutocomplete from 'shared/school-autocomplete'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { sortBy, uniqBy } from 'lodash'

const defaultState = {
  teacherSections: [], // API result
  teacherOptions: [], // Autocomplete options
  teacher: null, // Autocomplete selected option
  sectionOptions: [], // Autocomplete options
  section: null // Autocomplete selected option
}

const teacherOptionsFromTeacherSections = teacherSections => {
  return sortBy(
    uniqBy(teacherSections, teacherSection => teacherSection.teacher_id),
    ['last_name', 'first_name']
  ).map(({ teacher_id, employee_id, first_name, last_name }) => ({
    teacher_id,
    employee_id,
    first_name,
    last_name
  }))
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'set-teacher-sections': {
      const teacherSections = action.payload
      const teacherOptions = teacherOptionsFromTeacherSections(teacherSections)
      const teacher =
        teacherOptions.find(
          ({ teacher_id }) => teacher_id == state.teacher?.teacher_id
        ) || null
      const sectionOptions = teacherSections.filter(
        ({ teacher_id }) => teacher_id == teacher?.teacher_id
      )
      const section =
        sectionOptions.find(
          ({ section_id }) => section_id == state.section?.section_id
        ) || null
      return {
        teacherSections,
        teacherOptions,
        teacher,
        sectionOptions,
        section
      }
    }

    case 'change-teacher': {
      const teacher = action.payload
      const sectionOptions = state.teacherSections.filter(
        ({ teacher_id }) => teacher_id == teacher?.teacher_id
      )
      const section =
        sectionOptions.find(
          ({ section_id }) => section_id == state.section?.section_id
        ) || null
      return { ...state, teacher, sectionOptions, section }
    }

    case 'change-section': {
      const section = action.payload
      return { ...state, section }
    }

    default:
      throw new Error(`Invalid action.type: ${action.type}`)
  }
}

export function TeacherSectionSelectors({ onChange, observationType }) {
  const school = useSelector(
    state => state.schools.byId[state.schools.schoolId]
  )

  const [state, dispatch] = React.useReducer(reducer, defaultState)

  const params = { school_id: school.id, active_powerschool_sections: true }
  const options = {
    onSuccess: payload => dispatch({ type: 'set-teacher-sections', payload })
  }
  const { isLoading } = useTeacherSections({ params, options })

  const handleTeacherChange = payload =>
    dispatch({ type: 'change-teacher', payload })
  const handleSectionChange = payload =>
    dispatch({ type: 'change-section', payload })

  React.useEffect(() => {
    const { teacher, section } = state
    const teacher_id = teacher?.teacher_id
    const section_id = section?.section_id

    onChange({ teacher_id, section_id })
  }, [state.teacher, state.section])

  return (
    <React.Fragment>
      <SchoolAutocomplete
        disableClearable
        disablePortal
        {...(observationType === 'Walk to Read'
          ? { categories: ['elementary'] }
          : {})}
      />

      <Autocomplete
        openOnFocus
        value={state.teacher}
        onChange={(_event, option) => handleTeacherChange(option)}
        options={state.teacherOptions}
        disabled={isLoading || !state.teacherOptions.length}
        getOptionLabel={option =>
          `${option.last_name}, ${option.first_name} (${option.employee_id})`
        }
        renderOption={(props, option) => (
          <Stack direction={'row'} justifyContent={'flex-start'} {...props}>
            <Box>{`${option.last_name}, ${option.first_name}`}</Box>
            <Box>
              <small style={{ color: '#8a8a8a' }}>{option.employee_id}</small>
            </Box>
          </Stack>
        )}
        renderInput={params => (
          <TextField {...params} label={'Teacher'} required />
        )}
      />

      <Autocomplete
        openOnFocus
        value={state.section}
        onChange={(_event, option) => handleSectionChange(option)}
        options={state.sectionOptions}
        disabled={isLoading || !state.sectionOptions.length}
        getOptionLabel={option =>
          `${option.section_hour} - ${option.section_name} - ${option.role} - (${option.section_number})`
        }
        renderOption={(props, option) => (
          <Stack
            component={'li'}
            direction={'row'}
            justifyContent={'flex-start'}
            {...props}
            key={`section-option-${option.id}`}
          >
            <Box>{`${option.section_hour} - ${option.section_name} - ${option.role}`}</Box>
            <Box>
              <small style={{ color: '#8a8a8a' }}>
                {option.section_number}
              </small>
            </Box>
          </Stack>
        )}
        renderInput={params => (
          <TextField {...params} label={'Section'} required />
        )}
      />
    </React.Fragment>
  )
}

TeacherSectionSelectors.propTypes = {
  onChange: PropTypes.func.isRequired,
  observationType: PropTypes.string.isRequired
}

export default TeacherSectionSelectors
