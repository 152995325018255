import React from 'react'
import moment from 'moment'
import { alert } from 'utils/alert'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dialog } from 'shared/dialog'
import { getMuiAutocompleteJobRoles, updateUserJobRoles } from 'api/jobs'
import ConfirmModalButton from 'components/shared/confirm-modal/button'
import { Box, Stack } from '@mui/material'
import { upperCase } from 'lodash'
import SimpleAutocomplete from 'shared/simple-autocomplete'

class JobModal extends React.Component {
  static propTypes = {
    job: PropTypes.object,
    jobRoles: PropTypes.array,
    fetchData: PropTypes.func,
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    showAllFields: PropTypes.bool.isRequired
  }

  static defaultProps = {
    job: {
      role: 'unassigned',
      employeeId: undefined,
      email: undefined,
      name: undefined,
      active: false,
      id: undefined,
      expiration_date: moment().format()
    },
    showAllFields: true
  }

  initialState = {
    activeJob: {
      ...this.props.job,
      role:
        this.props.jobRoles.find(role => role.value === this.props.job.role)
          ?.value || null
    },
    showButton: false,
    show: this.props.show
  }

  state = this.initialState

  resetModal = () => {
    this.setState({
      job: this.defaultProps,
      activeJob: this.initialState,
      showButton: false
    })
  }

  handleRoleSelect = val => {
    const show = val.value !== null
    this.setState(prevState => ({
      activeJob: {
        ...prevState.activeJob,
        role: val.value
      },
      showButton: show
    }))
  }

  handleRoleSubmit = (id, code, role) => {
    if (!role) {
      alert('Job role cannot be blank', 'error')
      return
    }
    const params = {
      ids: id,
      role: role,
      code: code
    }
    updateUserJobRoles(params)
    this.refreshTable()
  }

  refreshTable = () => {
    const { onHide, fetchData } = this.props
    onHide()
    fetchData()
  }

  render() {
    const { onHide, job, jobRoles, show } = this.props
    const { activeJob, showButton } = this.state

    return (
      <Dialog
        title={`Update ALL Job Roles for ${activeJob.title}`}
        open={show}
        onClose={onHide}
      >
        <Box
          sx={{
            p: 4,
            minHeight: '50vh'
          }}
        >
          <Stack direction="column" spacing={2}>
            <Box>
              <SimpleAutocomplete
                id="job-role-select"
                value={activeJob.role}
                onChange={val => this.handleRoleSelect(val)}
                options={jobRoles}
                label="Job Role"
              />
            </Box>

            <Box
              sx={{
                mt: 2
              }}
            >
              {showButton && (
                <Box
                  spacing={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <ConfirmModalButton
                      id="confirm-modal-button"
                      modalMessage={`Are you sure you want to update ALL USERS with the job title of ${
                        job.title
                      }
                        from ${upperCase(job.role)} to ${upperCase(
                        activeJob.role
                      )}?`}
                      size="small"
                      color="primary"
                      showCancelButton={true}
                      cancelButtonText="No"
                      onConfirm={() =>
                        this.handleRoleSubmit(
                          [job.id],
                          job.code,
                          activeJob.role
                        )
                      }
                    >
                      Update
                    </ConfirmModalButton>
                  </Stack>
                </Box>
              )}
            </Box>
          </Stack>
        </Box>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  jobRoles: getMuiAutocompleteJobRoles(state.jobs.byRole)
})

export default connect(mapStateToProps)(JobModal)
