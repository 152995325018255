import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isNull } from 'lodash'
import { connect, useSelector } from 'react-redux'
import { optionsPropTypes, withOptions } from 'utils/contexts/options'
import { selectors } from 'modules/schools'
import {
  studentFiltersChanged,
  clearStudentFilters
} from 'modules/student-filters/actions'
import MuiSlider from '@mui/material/Slider'
import FormLabel from '@mui/material/FormLabel'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import { Divider, IconButton, InputAdornment, Stack } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createFilterSet,
  deleteFilterSet,
  fetchFilterSets
} from 'api/filter-sets'
import Box from '@mui/material/Box'
import {
  Close as CloseIcon,
  DeleteForever as DeleteForeverIcon,
  Save as SaveIcon
} from '@mui/icons-material'
import SimpleAutocomplete from '../simple-autocomplete'
import MultiselectAutocomplete from 'components/shared/multiselect-autocomplete'
import { getPrimaryGradPlanOptions } from 'api/grad-planner'

const maxCredits = 32

const determineValue = val => {
  if (val === null) {
    return ''
  } else if (typeof val === 'string') {
    return val
  } else if (typeof val === 'object') {
    return val.value
  }
}
const determineMultiselectValues = values => {
  if (!Array.isArray(values) || values.length === 0) {
    return []
  }

  return values
}

function useFilterSets(options) {
  const user_id = useSelector(state => state.currentUser.user.id)

  const queryKey = ['filterSets', user_id]
  const queryFn = async () => {
    const response = await fetchFilterSets({ user_id })
    return response.data
  }
  const query = useQuery(queryKey, queryFn, options)

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}

function Slider(props) {
  return (
    <Box sx={{ px: 2 }}>
      <MuiSlider {...props} />
    </Box>
  )
}

function FilterStack({ children }) {
  return (
    <Stack spacing={2} divider={<Divider />}>
      {children}
    </Stack>
  )
}
FilterStack.propTypes = {
  children: PropTypes.any
}

function FilterSection({ title, children }) {
  return (
    <FilterStack>
      <Typography variant="h6">{title}</Typography>

      <FilterStack>{children}</FilterStack>
    </FilterStack>
  )
}
FilterSection.propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.any
}

function FilterItem({ title, children }) {
  return (
    <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }}>
      <Typography variant="subtitle1" flex={1}>
        {title}
      </Typography>
      <Box flex={1}>
        <FormControl fullWidth>{children}</FormControl>
      </Box>
    </Stack>
  )
}
FilterItem.propTypes = {
  title: PropTypes.any.isRequired,
  children: PropTypes.any
}

function FilterToggleButtonGroup({ value, options = {}, onChange = () => {} }) {
  return (
    <ToggleButtonGroup value={value || ''} color={'primary'} size={'small'}>
      {value && (
        <ToggleButton
          value={''}
          title={'Clear'}
          color={'primary'}
          onClick={() => onChange(null)}
        >
          <CloseIcon fontSize={'small'} />
        </ToggleButton>
      )}

      {Object.entries(options).map(
        ([value, label]) =>
          value && (
            <ToggleButton
              key={value}
              color={'primary'}
              value={value}
              onClick={() => onChange(value)}
            >
              {label}
            </ToggleButton>
          )
      )}
    </ToggleButtonGroup>
  )
}
FilterToggleButtonGroup.propTypes = {
  value: PropTypes.string,
  options: PropTypes.object,
  onChange: PropTypes.func
}

function LetterSlider({ value, onChange }) {
  const sliderMarks = [...Array(26).keys()]
    .map(i => String.fromCharCode(i + 65))
    .map((letter, i) => ({
      value: i + 1,
      label: letter
    }))

  const handleSliderFormat = val => {
    return sliderMarks.find(mark => mark.value === val)?.label
  }

  const coerceInt = arrayOfLetters => {
    return sliderMarks
      .filter(mark => arrayOfLetters.includes(mark.label))
      .map(mark => mark.value)
  }

  return (
    <Slider
      valueLabelDisplay="auto"
      valueLabelFormat={val => handleSliderFormat(val)}
      value={coerceInt(value || ['A', 'Z'])}
      marks={sliderMarks}
      min={1}
      max={26}
      onChange={(_e, arrayOfValues) => {
        const newValue = sliderMarks
          .filter(mark => arrayOfValues.includes(mark.value))
          .map(mark => mark.label)

        onChange(newValue)
      }}
    />
  )
}
LetterSlider.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

export function FilterSet({
  teacherPage,
  teacher,

  // withOptions
  availableYears,

  // map state
  userId,
  gradeLevels,
  cohorts,
  filters,
  school,

  // map dispatch
  studentFiltersChanged,
  clearStudentFilters
}) {
  const filterCount = Object.keys(filters).length
  const recentOSTPYear = availableYears.ostp[availableYears.ostp.length - 1]

  // state
  const [activeFilterSet, setActiveFilterSet] = useState(null)
  const [newFilterSetName, setNewFilterSetName] = useState(null)
  const { data: filterSets, invalidate: invalidateFilterSets } = useFilterSets({
    initialData: [],
    refetchOnWindowFocus: false
  })
  const { data: gradPlanOptions } = useQuery(
    ['grad-plan-options'],
    () => {
      return getPrimaryGradPlanOptions()
    },
    {
      initialData: []
    }
  )

  const findFilterSetByName = name =>
    filterSets.find(filterSet => filterSet.name === name)

  // handlers
  const filterChanged = (
    filterName,
    val,
    secondaryFilterName,
    secondaryVal
  ) => {
    const processValue = inputVal => {
      return Array.isArray(inputVal) && inputVal.length === 1
        ? inputVal[0]
        : inputVal
    }

    const value = Array.isArray(val)
      ? determineMultiselectValues(val).map(processValue)
      : determineValue(val)

    const secondaryValue = Array.isArray(secondaryVal)
      ? determineMultiselectValues(secondaryVal).map(processValue)
      : determineValue(secondaryVal)

    let newFilters = { [filterName]: value }

    if (secondaryFilterName) {
      newFilters = { ...newFilters, [secondaryFilterName]: secondaryValue }
    }

    studentFiltersChanged(filters, newFilters)

    setActiveFilterSet(null)
    setNewFilterSetName('')
  }

  const rangeFilterChanged = (filterName, val) => {
    studentFiltersChanged(filters, { [filterName]: val })
  }

  const clearFilter = (filterName, secondaryFilterName = null) =>
    filterChanged(filterName, '', secondaryFilterName, '')

  const clearAll = () => {
    clearStudentFilters()
    setActiveFilterSet(null)
  }

  const saveFilters = () => {
    const params = {
      name: newFilterSetName,
      filters: filters,
      user_id: userId
    }

    createFilterSet(params).then(res => {
      setActiveFilterSet(res)

      invalidateFilterSets()
    })
  }

  const clearFiltersAndSetActiveFilterSet = name => {
    const filterSet = findFilterSetByName(name)
    setActiveFilterSet(filterSet)
  }

  const deleteFilterSetAndRefreshQuery = () => {
    // NOTE: if deleting the last filter set, enable the input if there are active filters
    if (filterCount && filterSets.length === 1) {
      setNewFilterSetName('')
    }

    setActiveFilterSet(null)

    deleteFilterSet(activeFilterSet.id).then(() => {
      invalidateFilterSets()
    })
  }

  // helper components
  const resetFilterButton = (filterName, secondaryFilterName = null) => (
    <Button
      sx={{ mr: 0.5 }}
      onClick={() => clearFilter(filterName, secondaryFilterName)}
    >
      Reset
    </Button>
  )

  // render
  const gradeLevelOptions = teacherPage
    ? teacher.grade_levels.map(gradeLevel => ({
        label: gradeLevel,
        value: gradeLevel
      }))
    : gradeLevels

  // side affects
  useEffect(() => {
    if (activeFilterSet) {
      setNewFilterSetName(null)
      studentFiltersChanged({}, activeFilterSet.filters)
    }
  }, [activeFilterSet])

  return (
    <Stack>
      <Stack direction={'row'}>
        <Box flexGrow={1}>
          {(!activeFilterSet &&
            Boolean(filterCount) &&
            !isNull(newFilterSetName) && (
              <TextField
                fullWidth
                size={'small'}
                label={'Create a New Filter Set…'}
                value={newFilterSetName}
                onChange={event => setNewFilterSetName(event.target.value)}
                placeholder={'Name'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position={'end'}>
                      <IconButton
                        title={'Save'}
                        edge={'end'}
                        color={'primary'}
                        onClick={saveFilters}
                        disabled={!newFilterSetName}
                      >
                        <SaveIcon />
                      </IconButton>
                      {Boolean(filterSets.length) && (
                        <IconButton
                          title={'Cancel'}
                          edge={'end'}
                          onClick={() => setNewFilterSetName(null)}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }}
              />
            )) ||
            (filterSets.length > 0 && (
              <TextField
                fullWidth
                select
                label={'Filter Sets'}
                value={activeFilterSet?.name || ''}
                size={'small'}
                onChange={event =>
                  clearFiltersAndSetActiveFilterSet(event.target.value)
                }
                InputProps={
                  !activeFilterSet
                    ? undefined
                    : {
                        endAdornment: (
                          <InputAdornment position={'end'} sx={{ mr: 3 }}>
                            <IconButton
                              edge={'end'}
                              color={'error'}
                              onClick={deleteFilterSetAndRefreshQuery}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                }
              >
                {filterSets.map(filterSet => (
                  <MenuItem key={filterSet.id} value={filterSet.name}>
                    {filterSet.name}
                  </MenuItem>
                ))}
              </TextField>
            ))}
        </Box>
        <Box>
          <Button variant={'outlined'} onClick={clearAll}>
            Clear&nbsp;All
          </Button>
        </Box>
      </Stack>

      <FilterSection title={'Demographic Filters'}>
        <FilterItem title={'Ethnicity'}>
          <MultiselectAutocomplete
            label={'Ethnicities'}
            value={filters.ethnicities}
            onChange={val => filterChanged('ethnicities', val)}
            options={[
              { value: 'Hispanic', label: 'Hispanic' },
              { value: 'African American', label: 'African American' },
              {
                value: 'Asian/P. Islander',
                label: 'Asian/P. Islander'
              },
              { value: 'White', label: 'White' },
              { value: 'Multiracial', label: 'Multiracial' },
              { value: 'Native American', label: 'Native American' }
            ]}
          />
        </FilterItem>

        <FilterItem title={'ML'}>
          <FilterToggleButtonGroup
            value={filters.ells}
            options={{
              Yes: 'Yes',
              Former: 'Monitored/Exited',
              No: 'No'
            }}
            onChange={value => filterChanged('ells', value)}
          />
        </FilterItem>

        <FilterItem title={'Gender'}>
          <FilterToggleButtonGroup
            value={filters.gender}
            options={{
              F: 'Female',
              M: 'Male'
            }}
            onChange={value => filterChanged('gender', value)}
          />
        </FilterItem>

        <FilterItem title={'SPED'}>
          <FilterToggleButtonGroup
            value={filters.sped}
            options={{
              true: 'Yes',
              false: 'No'
            }}
            onChange={value => filterChanged('sped', value)}
          />
        </FilterItem>

        <FilterItem title={'Gifted'}>
          <FilterToggleButtonGroup
            value={filters.gifted}
            options={{
              true: 'Yes',
              false: 'No'
            }}
            onChange={value => filterChanged('gifted', value)}
          />
        </FilterItem>

        <FilterItem title={'Socioeconomically Disadvantaged'}>
          <FilterToggleButtonGroup
            value={filters.socioeconomically_disadvantaged}
            options={{
              true: 'Yes',
              false: 'No'
            }}
            onChange={value =>
              filterChanged('socioeconomically_disadvantaged', value)
            }
          />
        </FilterItem>
      </FilterSection>

      <FilterSection title={'More Filters'}>
        <FilterItem title={'Grade Level(s)'}>
          <MultiselectAutocomplete
            label={'Grade Levels'}
            value={filters.grade_level}
            onChange={val => filterChanged('grade_level', val)}
            options={gradeLevelOptions}
          />
        </FilterItem>

        <FilterItem title={'Attendance'}>
          <MultiselectAutocomplete
            label={'Attendance'}
            value={filters.chronic}
            onChange={val => filterChanged('chronic', val)}
            options={[
              { value: 'perfect', label: 'Perfect (ADA = 100%)' },
              {
                value: 'not_at_risk',
                label: 'Not at Risk (95% < ADA < 100%)'
              },
              { value: 'at_risk', label: 'At Risk (90% < ADA <= 95%)' },
              { value: 'chronic', label: 'Chronic (80% < ADA <= 90%)' },
              {
                value: 'critically_chronic',
                label: 'Critically Chronic (ADA <= 80%)'
              },
              {
                value: 'attend_to_win',
                label: 'Attend to Win (85% < ADA <= 90%)'
              }
            ]}
          />
        </FilterItem>

        <FilterItem title={'Has Student Success Plan'}>
          <FilterToggleButtonGroup
            label={'Student Success Plan'}
            value={filters.has_student_success_plan}
            options={{
              true: 'Yes',
              false: 'No'
            }}
            onChange={val => {
              filterChanged('has_student_success_plan', val)
            }}
          />
        </FilterItem>

        <FilterItem title={'Approximate FAY Status'}>
          <FilterToggleButtonGroup
            label={'Status'}
            value={filters.approximate_fay_status}
            options={{
              FAY: 'FAY',
              NFAY: 'NFAY'
            }}
            onChange={val => {
              filterChanged('approximate_fay_status', val)
            }}
          />
        </FilterItem>

        <FilterItem title="Behavior Tier">
          <MultiselectAutocomplete
            label="Behavior Tier"
            value={filters.behavior_tier}
            onChange={newValue => filterChanged('behavior_tier', newValue)}
            options={[
              { value: '1', label: 'Tier 1' },
              { value: '2', label: 'Tier 2' },
              { value: '3', label: 'Tier 3' }
            ]}
          />
        </FilterItem>

        <FilterItem title={'Academic Intervention Platform'}>
          <SimpleAutocomplete
            label={'Academic Intervention Platform'}
            value={filters.current_platform}
            onChange={val =>
              filterChanged('current_platform', val?.value || '')
            }
            options={[
              { label: 'Amira', value: 'amira' },
              { label: 'Istation', value: 'istation' },
              { label: 'Imagine Learning', value: 'imagine_learning' },
              { label: 'Exact Path', value: 'exact_path' },
              { label: 'Achieve3000', value: 'achieve_3000' },
              { label: 'Lalilo', value: 'lalilo' }
            ]}
          />
        </FilterItem>
        <FilterItem title={'MAP Read'}>
          <Slider
            className="select-filter"
            min={1}
            max={99}
            value={filters.latest_map_reading_percentile_range || [1, 99]}
            valueLabelDisplay="auto"
            onChange={(_e, val) =>
              rangeFilterChanged('latest_map_reading_percentile_range', val)
            }
          />

          {filters.latest_map_reading_percentile_range &&
            resetFilterButton('latest_map_reading_percentile_range')}

          {['elementary', 'middle', 'high'].includes(school?.category) && (
            <FormControl
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <FormLabel sx={{ mb: 0, mr: 2 }}>Tier</FormLabel>
              {['elementary'].includes(school?.category) && (
                <ToggleButtonGroup
                  value={filters.latest_map_reading_percentile_range || [1, 99]}
                  color="primary"
                  aria-label="toggle buttons to select MAP tiers"
                >
                  <ToggleButton
                    onClick={() =>
                      rangeFilterChanged(
                        'latest_map_reading_percentile_range',
                        [80, 99]
                      )
                    }
                    color="success"
                    value={[80, 99]}
                  >
                    Advanced
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      rangeFilterChanged(
                        'latest_map_reading_percentile_range',
                        [40, 79]
                      )
                    }
                    color="success"
                    value={[40, 79]}
                  >
                    1
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      rangeFilterChanged(
                        'latest_map_reading_percentile_range',
                        [26, 39]
                      )
                    }
                    color="success"
                    value={[26, 39]}
                  >
                    1+
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      rangeFilterChanged(
                        'latest_map_reading_percentile_range',
                        [11, 25]
                      )
                    }
                    color="warning"
                    value={[11, 25]}
                  >
                    2
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      rangeFilterChanged(
                        'latest_map_reading_percentile_range',
                        [1, 10]
                      )
                    }
                    color="error"
                    value={[1, 10]}
                  >
                    3
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
              {['middle', 'high'].includes(school?.category) && (
                <ToggleButtonGroup
                  value={filters.latest_map_reading_percentile_range || [1, 99]}
                  color="primary"
                  aria-label="toggle buttons to select MAP tiers"
                >
                  <ToggleButton
                    onClick={() =>
                      rangeFilterChanged(
                        'latest_map_reading_percentile_range',
                        [50, 99]
                      )
                    }
                    color="success"
                    value={[50, 99]}
                  >
                    1
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      rangeFilterChanged(
                        'latest_map_reading_percentile_range',
                        [26, 49]
                      )
                    }
                    color="warning"
                    value={[26, 49]}
                  >
                    2
                  </ToggleButton>
                  <ToggleButton
                    onClick={() =>
                      rangeFilterChanged(
                        'latest_map_reading_percentile_range',
                        [1, 25]
                      )
                    }
                    color="error"
                    value={[1, 25]}
                  >
                    3
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </FormControl>
          )}
        </FilterItem>

        <FilterItem title={'MAP Math'}>
          <Slider
            className="select-filter"
            min={1}
            max={99}
            value={filters.latest_map_math_percentile_range || [1, 99]}
            valueLabelDisplay="auto"
            onChange={(_e, val) =>
              rangeFilterChanged('latest_map_math_percentile_range', val)
            }
          />
          {filters.latest_map_math_percentile_range &&
            resetFilterButton('latest_map_math_percentile_range')}
        </FilterItem>

        <FilterItem
          title={
            <>
              <Typography variant={'body1'}>Projected OSTP Level</Typography>
              <Typography variant={'caption'}>
                Based on Latest MAP Reading
              </Typography>
            </>
          }
        >
          <SimpleAutocomplete
            label={'Projected OSTP Level (Reading)'}
            value={filters.latest_projected_reading_ostp_level}
            options={[
              { label: 'Below Basic', value: 'Below Basic' },
              { label: 'Basic', value: 'Basic' },
              { label: 'Proficient', value: 'Proficient' },
              { label: 'Advanced', value: 'Advanced' }
            ]}
            placeholder="Projected OSTP Level (Reading)"
            onChange={val =>
              filterChanged('latest_projected_reading_ostp_level', val)
            }
          />
        </FilterItem>

        <FilterItem
          title={
            <>
              <Typography variant={'body1'}>Projected OSTP Level</Typography>
              <Typography variant={'caption'}>
                Based on Latest MAP Math
              </Typography>
            </>
          }
        >
          <SimpleAutocomplete
            label={'Projected OSTP Level (Math)'}
            value={filters.latest_projected_math_ostp_level}
            options={[
              { label: 'Below Basic', value: 'Below Basic' },
              { label: 'Basic', value: 'Basic' },
              { label: 'Proficient', value: 'Proficient' },
              { label: 'Advanced', value: 'Advanced' }
            ]}
            placeholder="Projected OSTP Level (Math)"
            onChange={val =>
              filterChanged('latest_projected_math_ostp_level', val)
            }
          />
        </FilterItem>

        <FilterItem title={`OSTP ${recentOSTPYear} (ELA)`}>
          <SimpleAutocomplete
            label={'Proficiency Band'}
            value={filters.ostp_ela_band}
            options={[
              { label: 'Below Basic', value: 'below basic' },
              { label: 'Basic', value: 'basic' },
              { label: 'Proficient', value: 'proficient' },
              { label: 'Advanced', value: 'advanced' }
            ]}
            onChange={val => filterChanged('ostp_ela_band', val)}
          />
        </FilterItem>

        <FilterItem title={`OSTP ${recentOSTPYear} (Math)`}>
          <SimpleAutocomplete
            label={'Proficiency Band'}
            value={filters.ostp_mathematics_band}
            options={[
              { label: 'Below Basic', value: 'below basic' },
              { label: 'Basic', value: 'basic' },
              { label: 'Proficient', value: 'proficient' },
              { label: 'Advanced', value: 'advanced' }
            ]}
            onChange={val => filterChanged('ostp_mathematics_band', val)}
          />
        </FilterItem>

        <FilterItem title={`OSTP ${recentOSTPYear} (Science)`}>
          <SimpleAutocomplete
            label={'Proficiency Band'}
            value={filters.ostp_science_band}
            options={[
              { label: 'Below Basic', value: 'below basic' },
              { label: 'Basic', value: 'basic' },
              { label: 'Proficient', value: 'proficient' },
              { label: 'Advanced', value: 'advanced' }
            ]}
            onChange={val => filterChanged('ostp_science_band', val)}
          />
        </FilterItem>

        <FilterItem title={'Child Study Status'}>
          <SimpleAutocomplete
            label={'Child Study Status'}
            value={filters.child_study_status}
            options={[
              { label: 'Active', value: 'active' },
              { label: 'On-Hold Teacher', value: 'on_hold_teacher' },
              { label: 'Referred', value: 'referred' },
              { label: 'Closed', value: 'closed' },
              { label: 'Not Yet Referred', value: 'not_yet_referred' }
            ]}
            placeholder="Child Study Status"
            onChange={val => filterChanged('child_study_status', val)}
          />
        </FilterItem>

        <FilterItem title={'Credits'}>
          <Slider
            spacing={0}
            className="select-filter"
            min={0}
            max={maxCredits}
            value={filters.credit_range || [0, maxCredits]}
            valueLabelDisplay="auto"
            onChange={(_e, val) => rangeFilterChanged('credit_range', val)}
          />
          {filters.credit_range && resetFilterButton('credit_range')}
        </FilterItem>

        <FilterItem title="Number of 'F' or 'U' Grades">
          <Box display="flex" gap="1rem" alignItems="center">
            <SimpleAutocomplete
              label="Comparison"
              options={[
                { value: 'less_than', label: '<' },
                { value: 'less_than_or_equal_to', label: '<=' },
                { value: 'equals', label: '=' },
                { value: 'greater_than_or_equal_to', label: '>=' },
                { value: 'greater_than', label: '>' }
              ]}
              value={filters.failing_multiple_symbol || 'equals'}
              onChange={val => {
                filterChanged('failing_multiple_symbol', val)
              }}
            />
            <SimpleAutocomplete
              label="'F' or 'U' Grades"
              fullWidth
              options={Array.from({ length: 10 }, (_, i) => ({
                value: `${i}`,
                label: `${i}`
              }))}
              value={filters.failing_multiple || ''}
              onChange={val => {
                if (val == null) {
                  filterChanged(
                    'failing_multiple',
                    val,
                    'failing_multiple_symbol',
                    val
                  )
                } else if (!filters.failing_multiple_symbol) {
                  filterChanged(
                    'failing_multiple',
                    val,
                    'failing_multiple_symbol',
                    'equals'
                  )
                } else {
                  filterChanged('failing_multiple', val)
                }
              }}
            />
          </Box>
        </FilterItem>

        <FilterItem title="Number of Blank Grades">
          <Box display="flex" gap="1rem" alignItems="center">
            <SimpleAutocomplete
              label="Comparison"
              options={[
                { value: 'less_than', label: '<' },
                { value: 'less_than_or_equal_to', label: '<=' },
                { value: 'equals', label: '=' },
                { value: 'greater_than_or_equal_to', label: '>=' },
                { value: 'greater_than', label: '>' }
              ]}
              value={filters.blank_grades_symbol || 'equals'}
              onChange={val => {
                filterChanged('blank_grades_symbol', val)
              }}
            />
            <SimpleAutocomplete
              label="Blank Grades"
              fullWidth
              options={Array.from({ length: 10 }, (_, i) => ({
                value: `${i}`,
                label: `${i}`
              }))}
              value={filters.blank_grades_count || ''}
              onChange={val => {
                if (val == null) {
                  filterChanged(
                    'blank_grades_count',
                    val,
                    'blank_grades_symbol',
                    val
                  )
                } else if (!filters.blank_grades_symbol) {
                  filterChanged(
                    'blank_grades_count',
                    val,
                    'blank_grades_symbol',
                    'equals'
                  )
                } else {
                  filterChanged('blank_grades_count', val)
                }
              }}
            />
          </Box>
        </FilterItem>

        <FilterItem title={'Failed Core Courses'}>
          <FilterToggleButtonGroup
            label={'Failed Core Courses'}
            value={filters.failed_core}
            options={{
              true: 'Yes',
              false: 'No'
            }}
            onChange={val => filterChanged('failed_core', val)}
          />
        </FilterItem>

        {['middle', 'high'].includes(school?.category) && (
          <FilterItem title={'Enrolled in Literacy Intervention'}>
            <FilterToggleButtonGroup
              label={'Enrolled in Literacy Intervention'}
              value={filters.enrolled_in_literacy_intervention}
              options={{
                true: 'Yes',
                false: 'No'
              }}
              onChange={val =>
                filterChanged('enrolled_in_literacy_intervention', val)
              }
            />
          </FilterItem>
        )}

        <FilterItem title={'Met Access Goal'}>
          <FilterToggleButtonGroup
            label={'Met Access Goal'}
            value={filters.met_access_goal}
            placeholder="Met Access Goal"
            options={{
              true: 'Yes',
              false: 'No'
            }}
            onChange={val => filterChanged('met_access_goal', val)}
          />
        </FilterItem>

        <FilterItem title={'Grad Plan'}>
          <MultiselectAutocomplete
            label={'Grad Plan'}
            value={filters.graduation_plan}
            placeholder="Grad Plan"
            onChange={val => filterChanged('graduation_plan', val)}
            options={gradPlanOptions.concat({
              label: 'Missing Grad Plan',
              value: 'Missing'
            })}
          />
        </FilterItem>

        <FilterItem title={'Grad Status'}>
          <SimpleAutocomplete
            label={'Grad Status'}
            value={filters.grad_status || null}
            placeholder="Grad Status"
            onChange={val => filterChanged('grad_status', val)}
            options={[
              { value: 'on_track', label: 'On Track' },
              { value: 'off_track', label: 'Off Track' },
              { value: 'at_risk', label: 'At Risk' },
              { value: 'high_risk', label: 'High Risk' }
            ]}
          />
        </FilterItem>

        <FilterItem title={'Cohort'}>
          <SimpleAutocomplete
            label={'Cohort'}
            value={filters.cohorts}
            placeholder="Cohort"
            options={cohorts}
            onChange={val => filterChanged('cohorts', val)}
            getOptionLabel={option => option.label || option.value}
          />
        </FilterItem>

        <FilterItem title={'Grad Check - Was Emailed'}>
          <FilterToggleButtonGroup
            label={'Was Emailed'}
            value={filters.was_emailed}
            placeholder="Was Emailed"
            options={{
              true: 'Yes',
              false: 'No'
            }}
            onChange={val => filterChanged('was_emailed', val)}
          />
        </FilterItem>

        <FilterItem title={'Grad Check - Has Notes'}>
          <FilterToggleButtonGroup
            label={'Has Notes'}
            value={filters.has_notes}
            placeholder="Has Notes"
            options={{
              true: 'Yes',
              false: 'No'
            }}
            onChange={val => filterChanged('has_notes', val)}
          />
        </FilterItem>

        <FilterItem title={'Last Name'}>
          <LetterSlider
            value={filters.last_name_range}
            onChange={value => rangeFilterChanged('last_name_range', value)}
          />
          {filters.last_name_range && resetFilterButton('last_name_range')}
        </FilterItem>

        <FilterItem title={'U.S. Civics Knowledge Assessment'}>
          <SimpleAutocomplete
            label={'U.S. Civics Knowledge Assessment'}
            value={filters.us_civics_status}
            options={[
              { value: 'Passed', label: 'Passed' },
              { value: 'Failed', label: 'Failed' },
              { value: 'Exempt', label: 'Exempt' },
              { value: 'Not Taken', label: 'Not Taken' }
            ]}
            onChange={val => filterChanged('us_civics_status', val.value)}
            placeholder="Status"
          />
        </FilterItem>
      </FilterSection>

      <FilterSection title={'Postsecondary Readiness Filters'}>
        <FilterItem title={'Advanced Placement'}>
          <SimpleAutocomplete
            label={'Advanced Placement'}
            value={filters.advanced_placement}
            options={[
              { value: 'enrolled', label: 'Enrolled' },
              { value: 'qualified', label: 'Qualified (PSAT)' },
              { value: 'not_qualified', label: 'Not Qualified (PSAT)' }
            ]}
            onChange={val => filterChanged('advanced_placement', val)}
            placeholder="Advanced Placement"
          />
        </FilterItem>
        <FilterItem title={'College'}>
          <SimpleAutocomplete
            label={'College'}
            value={filters.college}
            options={[
              { value: 'enrolled', label: 'Enrolled' },
              { value: 'qualified', label: 'Qualified (MAP)' },
              { value: 'not_qualified', label: 'Not Qualified (MAP)' }
            ]}
            onChange={val => filterChanged('college', val)}
            placeholder="College"
          />
        </FilterItem>
        <FilterItem title={'International Baccalaureate Program'}>
          <FilterToggleButtonGroup
            options={{
              enrolled: 'Enrolled',
              not_enrolled: 'Not Enrolled'
            }}
            value={filters.ib}
            onChange={val => filterChanged('ib', val)}
          />
        </FilterItem>
        <FilterItem title={'Assessment Requirements'}>
          <FilterToggleButtonGroup
            value={filters.assessment_requirements}
            options={{ met: 'Met', not_met: 'Not Met' }}
            onChange={val => filterChanged('assessment_requirements', val)}
          />
        </FilterItem>
        <FilterItem title={'Stepmojo'}>
          <FilterToggleButtonGroup
            value={filters.stepmojo}
            options={{
              enrolled: 'Enrolled',
              not_enrolled: 'Not Enrolled'
            }}
            onChange={val => filterChanged('stepmojo', val)}
          />
        </FilterItem>
        <FilterItem title={'Career Tech'}>
          <FilterToggleButtonGroup
            value={filters.career_tech}
            options={{
              enrolled: 'Enrolled',
              not_enrolled: 'Not Enrolled'
            }}
            onChange={val => filterChanged('career_tech', val)}
          />
        </FilterItem>{' '}
      </FilterSection>
    </Stack>
  )
}

FilterSet.propTypes = {
  activeColumnSet: PropTypes.string,
  setActiveColumnSet: PropTypes.func,
  teacherPage: PropTypes.bool,
  teacher: PropTypes.object,

  // withOptions
  availableYears: optionsPropTypes.availableYears,

  // map state
  userId: PropTypes.number.isRequired,
  gradeLevels: PropTypes.array,
  cohorts: PropTypes.array,
  filters: PropTypes.object,
  school: PropTypes.object.isRequired,

  // map dispatch
  studentFiltersChanged: PropTypes.func.isRequired,
  clearStudentFilters: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  userId: state.currentUser.user.id,
  gradeLevels: selectors.gradeLevelOptions(state),
  cohorts: selectors.cohortOptions(state),
  filters: state.studentFilters.studentFilters,
  schoolId: state.schools.schoolId,
  school: selectors.currentSchool(state)
})

const mapDispatchToProps = {
  studentFiltersChanged,
  clearStudentFilters
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOptions(FilterSet))
