import React from 'react'
import PropTypes from 'prop-types'
import StudentNameDisplay from './StudentNameDisplay'
import StudentMultiSelect from './StudentMultiSelect'

function StudentSelectContainer({
  studentId,
  selectedStudentOption,
  setSelectedStudentOption,
  isSubmitted
}) {
  return (
    <>
      {studentId ? (
        <StudentNameDisplay studentId={studentId} />
      ) : (
        <StudentMultiSelect
          setSelectedOption={setSelectedStudentOption}
          selectedOption={selectedStudentOption}
          isSubmitted={isSubmitted}
        />
      )}
    </>
  )
}

StudentSelectContainer.propTypes = {
  studentId: PropTypes.number,
  selectedStudentOption: PropTypes.array,
  setSelectedStudentOption: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired
}

export default StudentSelectContainer
