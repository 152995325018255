import React from 'react'
import PropTypes from 'prop-types'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'

export function CloseAction({ onClose }) {
  return (
    <IconButton aria-label="close" onClick={onClose} color="inherit">
      <CloseIcon />
    </IconButton>
  )
}

CloseAction.propTypes = {
  onClose: PropTypes.func
}

export function DialogTitle({ children, actions, onClose, ...other }) {
  return (
    <MuiDialogTitle {...other}>
      <Stack direction={'row'} sx={{ alignItems: 'flex-start' }}>
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
        {(actions || onClose) && (
          <Stack direction={'row'}>
            {actions}
            {onClose && <CloseAction onClose={onClose} />}
          </Stack>
        )}
      </Stack>
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  children: PropTypes.any,
  actions: PropTypes.any,
  onClose: PropTypes.func
}

export default DialogTitle
