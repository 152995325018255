import { useSelector, connect } from 'react-redux'
import FeedItemContent from 'components/shared/activity-feed/feed-item-content'
import FeedItemHeader from 'components/shared/activity-feed/feed-item-header'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import TeacherFeedActions from './feed-actions'
import EssentialContentRating from './essential-content-rating'
import InfiniteActivityFeed from 'components/infinite_activity_feed'
import { get, showError } from 'utils/ajax'
import TeacherActionButtons from '../teacher-action-buttons'
import { Box, Paper } from '@mui/material'

const Feed = ({ teacherId, user, teacher }) => {
  const [loading, setLoading] = useState(true)
  const [feedItems, setFeedItems] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [renderCount, setRenderCount] = useState(0)

  const fetchNextPage = () => {
    if (!loading) {
      setLoading(true)
      setPage(page + 1)
    }
  }

  useEffect(() => {
    get(`/api/web/teachers/${teacherId}/feed_items`, {
      params: { page: page, per_page: 10 }
    })
      .then(({ data, meta }) => {
        setFeedItems([...feedItems, ...data])
        setLoading(false)
        const isLastPage = meta.page + 1 >= meta.pages
        setHasMore(!isLastPage)
      })
      .catch(err => {
        setLoading(false)
        showError(
          'Something went wrong when we tried to load the activity feed',
          err
        )
      })
  }, [page, renderCount])

  const refreshFeed = () => {
    if (!loading) {
      setLoading(true)
      setFeedItems([])
      setRenderCount(renderCount + 1)
    }
  }

  const userId = useSelector(state => state.currentUser.user.id)

  const hasEssentialContentRating = feedItem =>
    feedItem.event_type == 'observation' &&
    feedItem.data.essential_content_summary &&
    userId === feedItem.data.observer.id

  const handleFilterChange = val => {
    setSelectedFilter(val)
    refreshFeed()
  }

  const filteredFeedItems = selectedFilter
    ? feedItems.filter(feedItem => selectedFilter === feedItem.event_type)
    : feedItems

  const teacherFeedItems = () => {
    return filteredFeedItems.map(feedItem => (
      <Paper key={feedItem.id} sx={{ m: 4 }}>
        <Box>
          <FeedItemHeader
            status={feedItem.status}
            title={feedItem.title}
            date={moment(feedItem.date)}
          />
          {hasEssentialContentRating(feedItem) && (
            <EssentialContentRating
              essentialContent={feedItem.data.essential_content_summary}
            />
          )}
          <FeedItemContent content={feedItem.description} />

          <Box
            sx={{
              pl: 4
            }}
          >
            <TeacherFeedActions
              feedItem={feedItem}
              refreshFeed={refreshFeed}
              teacher={teacher}
              user={user}
            />
          </Box>
        </Box>
      </Paper>
    ))
  }

  return (
    <React.Fragment>
      {['principal', 'teacher'].includes(user.role) && (
        <TeacherActionButtons
          refreshFeed={refreshFeed}
          teacher={teacher}
          user={user}
        />
      )}
      <InfiniteActivityFeed
        title="Feed"
        loading={loading}
        loadMore={fetchNextPage}
        hasMore={hasMore}
        showFilter={true}
        handleFilterChange={handleFilterChange}
        selectedFilter={selectedFilter}
      >
        {teacherFeedItems()}
      </InfiniteActivityFeed>
    </React.Fragment>
  )
}

Feed.propTypes = {
  teacherId: PropTypes.number.isRequired,
  teacher: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.currentUser.user
})

export default connect(mapStateToProps)(Feed)
