import moment from 'moment'

const normalizeMomentValues = (object, dateAttributes) => {
  const entries = Object.entries(object).map(([k, v]) => [
    k,
    dateAttributes.includes(k) ? v && moment(v).toDate() : v
  ])
  return Object.fromEntries(entries)
}

const removeNullAttrs = obj => {
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop) && obj[prop] === null) {
      delete obj[prop]
    }
  }
  return obj
}

const initFormValues = (prefill, { stringAttributes, dateAttributes }) =>
  normalizeMomentValues(
    {
      ...Object.fromEntries(stringAttributes.map(x => [x, ''])),
      ...Object.fromEntries(dateAttributes.map(x => [x, undefined])),
      ...removeNullAttrs(prefill)
    },
    dateAttributes
  )

export default initFormValues
