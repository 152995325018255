import React from 'react'
import PropTypes from 'prop-types'

const FilterContainer = ({ children, topBorder = true, ...props }) => (
  <div
    className={`subnav-filters pl1 ${topBorder ? 'top-border' : ''}`}
    {...props}
  >
    <div className="row">
      <div className="col-sm-12">{children}</div>
    </div>
  </div>
)

FilterContainer.propTypes = {
  children: PropTypes.node.isRequired,
  topBorder: PropTypes.bool
}

export default FilterContainer
