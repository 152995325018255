import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from 'components/shared/dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { createNotes } from 'api/notes'

function BulkNoteGeneratorDialog({
  onClose,
  open,
  studentIds,
  filters,
  columnApiIncludes,
  totalResults
}) {
  const [note, setNote] = useState('')

  const handleClose = () => {
    setNote('')
    onClose()
  }

  const reduceApiIncludes = columnApiIncludes =>
    columnApiIncludes.reduce((hash, include) => {
      hash[`include_${include}`] = true
      return hash
    }, {})

  const submitForm = async e => {
    e.preventDefault()

    await createNotes({
      note: note,
      student_ids: studentIds,
      ...filters,
      ...reduceApiIncludes(columnApiIncludes)
    })
    handleClose()
  }

  return (
    <Dialog
      title={` Add Note for ${
        studentIds.includes('all') ? totalResults : studentIds.length
      } students`}
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            required
            id="new-note"
            onChange={e => setNote(e.target.value)}
            label="Your Note"
            value={note}
            multiline
            rows={4}
            variant="outlined"
            maxLength={250}
            helperText="Will be saved to each student's page."
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={e => submitForm(e)}
          disabled={note.length == 0}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

BulkNoteGeneratorDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  studentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  filters: PropTypes.array,
  columnApiIncludes: PropTypes.array,
  totalResults: PropTypes.array
}

export default BulkNoteGeneratorDialog
