import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectors } from 'modules/schools'
import { get } from 'utils/ajax'
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import PageTitle from 'components/shared/page-title'
import { titleize, calculatePercentage } from 'utils'
import EnrollmentChart from 'components/shared/enrollment/chart'

class Enrollment extends React.Component {
  static propTypes = {
    schoolId: PropTypes.number.isRequired,
    school: PropTypes.object.isRequired
  }

  // categories are linked to the array's objects in Object.entries(schoolProfile)
  state = {
    schoolProfile: null,
    categories: [
      'current_enrollment',
      'sped',
      'gt',
      'ml',
      'grade_level',
      'ethnicity',
      'gender',
      'socioeconomically_disadvantaged'
    ]
  }

  fetchSchoolData = schoolId => {
    get(`/api/web/school_profiles/${schoolId}`, {
      params: { include_daily_enrollment: true }
    }).then(res => {
      this.setState({ schoolProfile: res.school_profile })
    })
  }

  humanizeBooleanAttributes = val => {
    if (val == 'true') {
      return 'yes'
    } else if (val == 'false') {
      return 'no'
    } else {
      return val
    }
  }

  componentDidMount() {
    const { schoolId } = this.props
    this.fetchSchoolData(schoolId)
  }

  render() {
    const { schoolProfile, categories } = this.state

    return (
      <React.Fragment>
        <PageTitle title={'Enrollment Data'} />
        <Box
          sx={{
            mt: 2
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              minHeight: '400px'
            }}
          >
            <Card>
              <CardContent>
                {schoolProfile && (
                  <EnrollmentChart data={schoolProfile.daily_enrollments} />
                )}
              </CardContent>
            </Card>
          </Box>
          {schoolProfile && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2
              }}
            >
              <Box
                sx={{
                  width: '48%'
                }}
              >
                <Card>
                  <CardHeader
                    title="Current Enrollment"
                    titleTypographyProps={{ align: 'center' }}
                  />
                  <CardContent>
                    <Typography variant="h4" align="center">
                      {schoolProfile.current_enrollment}
                    </Typography>
                  </CardContent>
                </Card>

                {categories.slice(1, 4).map(category => (
                  <Box
                    key={category}
                    sx={{
                      mt: 2
                    }}
                  >
                    <Card>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="33%">
                                {category.toUpperCase()}
                              </TableCell>
                              <TableCell width="33%" align="center">
                                Count Count
                              </TableCell>
                              <TableCell width="33%" align="center">
                                %
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(schoolProfile[category]).map(
                              data => (
                                <TableRow key={data[0]}>
                                  <TableCell>
                                    {data[0] === 'Former'
                                      ? 'Monitored/Exited'
                                      : titleize(
                                          this.humanizeBooleanAttributes(
                                            data[0]
                                          )
                                        )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data[1]}
                                  </TableCell>
                                  <TableCell align="center">
                                    {calculatePercentage(
                                      data[1],
                                      schoolProfile.current_enrollment
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  width: '48%'
                }}
              >
                {categories.slice(4, 8).map(category => (
                  <Box
                    key={category}
                    sx={{
                      mt: 2
                    }}
                  >
                    <Card>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell width="33%">
                                {titleize(category)}
                              </TableCell>
                              <TableCell width="33%" align="center">
                                Count
                              </TableCell>
                              <TableCell width="33%" align="center">
                                %
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(schoolProfile[category]).map(
                              data => (
                                <TableRow key={data[0]}>
                                  <TableCell>
                                    {titleize(
                                      this.humanizeBooleanAttributes(data[0])
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {data[1]}
                                  </TableCell>
                                  <TableCell align="center">
                                    {calculatePercentage(
                                      data[1],
                                      schoolProfile.current_enrollment
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  schoolId: state.schools.schoolId,
  school: selectors.currentSchool(state)
})

export default connect(mapStateToProps)(Enrollment)
