import * as React from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { Box, Stack } from '@mui/material'

function ReferralList({ referrals }) {
  return (
    <React.Fragment>
      <Typography variant="h3">This School Year: {referrals.length}</Typography>
      <List
        dense
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      >
        {referrals.map(referral => (
          <ListItem key={referral.id}>
            <ListItemText
              primary={referral.incident}
              secondary={
                <Stack direction="row" spacing={2}>
                  {moment(referral.date).format('MM/DD/YYYY')}
                  <Box>CONSEQUENCE: {referral.consequence}</Box>
                </Stack>
              }
            />
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  )
}

ReferralList.propTypes = {
  referrals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      incident: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      consequence: PropTypes.string
    })
  )
}

export default ReferralList
