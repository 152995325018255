import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Stack,
  Typography
} from '@mui/material'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

const AccordionInstructions = ({ children }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
        <Typography>Instructions, Guidance, Resources</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

const Instructions = () => {
  return (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h4">
          Steps to Complete a Confidential Crisis Intervention Report/Referral
        </Typography>
        <ol>
          <li>
            Report the situation immediately to the school leader, supervisor,
            or designee.
          </li>
          <li>
            Contact the guardian and provide a referral for an IMMEDIATE
            behavioral health assessment. This means call COPES at 918-744-4800
            (and/or existing behavioral health provider with COPES follow up)
            and provide all relevant information to COPES/existing therapist as
            outlined in{' '}
            <a
              href="https://drive.google.com/drive/u/0/folders/1GI1J2HJdTZG6z3ZrvH6--GuiQjA0sfzF"
              target="_blank"
              rel="noreferrer"
            >
              this link
            </a>
            .
          </li>
          <li>
            Answer all questions within the form. If the question is not
            applicable, enter “NA” in the blank.
          </li>
          <li>
            Complete all corresponding documentation referenced in the guidance.
          </li>
          <li>
            If a threat was made at any time, the following (link below) should
            also be accessed and completed immediately. (Instructions included
            in form.){' '}
            <a
              href="https://drive.google.com/drive/folders/1Bffa8_MpIsk757Mw_86tZTNoRWI5iUrQ"
              target="_blank"
              rel="noreferrer"
            >
              Threat Assessment and Success Plan Form Links
            </a>
          </li>
          <li>
            Completing this form will create a student concern - add notes and
            support entries to indicate any follow-up action that has been
            taken.
          </li>
          <li>
            Do NOT include confidential information and/or narrative
            descriptions within your follow-up documentation in the dashboard
          </li>
          <li>
            Completing this form will create a notification to the site crisis
            response team.
          </li>
          <li>
            For students that do NOT qualify for Exceptional Student Services
            (ESS), create a{' '}
            <a
              href="https://docs.google.com/document/d/1IfvJNqazz8_PuZ6NF1XWfkDEg2Uu06ML5rzMt6IKzY0/edit"
              target="_blank"
              rel="noreferrer"
            >
              Student Success Plan
            </a>{' '}
            with a safety focus OR For students receiving services through ESS
            (have an IEP), contact the caseload teacher regarding inclusion of
            success plan measures in the existing IEP.
          </li>
          <li>
            Ensure any{' '}
            <a
              href="https://docs.google.com/document/d/1ih193OUjjdfI0V8WyhNo4fn-Ty5psLF3BsbpgitmAaQ/edit"
              target="_blank"
              rel="noreferrer"
            >
              protected personal health information (PHI)
            </a>{' '}
            in need of sharing has an approved OKLAHOMA STANDARD AUTHORIZATION
            TO USE OR SHARE of PROTECTED HEALTH INFORMATION (PHI) (link to
            English & Spanish form) complete and on file prior to sharing.
          </li>
          <li>
            See guidance for additional information and requirements for
            incidents involving social media/internet access, Internet search
            related concerns, after hours procedures, incidents involving
            Injury, bullying, follow-up and re-entry
          </li>
          <li>
            Follow the post crisis support steps referenced in the guidance
          </li>
          <li>
            Ensure the form is completed in its entirety for any safety concern
            (e..g. thought/wish/plan/intent/report of harm self/others or active
            psychotic features)
          </li>
          <li>
            Save the form - Saving will send to school leader for signature.
            (electronic signatures acceptable).
          </li>
          <li>
            Upload any additional forms that were completed through this process
            to the data dashboard using the form uploader.
          </li>
        </ol>
      </Box>

      <Box>
        <Typography variant="h4">
          Social Media, Electronic Communication, Internet Access
        </Typography>

        <ol>
          <li>
            Report the situation immediately to the school leader, supervisor,
            or designee.
          </li>
          <li>
            Contact the guardian and provide a referral for an IMMEDIATE
            behavioral health assessment to COPES (918-744-4800) and/or existing
            behavioral health provider with COPES follow up.
          </li>
          <li>
            <a
              href="https://drive.google.com/drive/u/0/folders/1GI1J2HJdTZG6z3ZrvH6--GuiQjA0sfzF"
              target="_blank"
              rel="noreferrer"
            >
              Crisis Response & COPES Info - Brochure/Guidelines/Referral
              Questions
            </a>
          </li>
          <li>
            Complete all corresponding documentation referenced in the form
            below.
          </li>
          <li>
            If a threat was made at any time, the following (link below) should
            also be accessed and a threat assessment should be completed
            immediately. (Instructions included in form.)
          </li>
          <li>
            <a
              href="https://drive.google.com/drive/folders/1Bffa8_MpIsk757Mw_86tZTNoRWI5iUrQ"
              target="_blank"
              rel="noreferrer"
            >
              Threat Assessment, Sexual Threat Assessment, and Success Plan Form
              Links
            </a>
          </li>
          <li>
            Document the intervention/support under the Mental Health Concern
            created upon submitting this form.
          </li>
          <li>
            The Mental Health Concern created when completing this form will
            automatically create a notification to the site leadership team.
          </li>
          <li>
            For students that do NOT qualify for Exceptional Student Services
            (ESS), create a Student Success Plan with a safety focus OR
          </li>
          <li>
            For students receiving services through ESS (have an IEP), contact
            the caseload teacher regarding inclusion of success plan measures in
            the existing IEP.
          </li>
          <li>
            Follow the post crisis support steps referenced in Section 10 of
            this document.
          </li>
        </ol>
      </Box>

      <Box>
        <Typography variant="h4">
          Internet Searches Related to Topics of Concern
        </Typography>
        <ol>
          <li>
            Report the situation immediately to the school leader, supervisor,
            or designee.
          </li>
          <li>Contact the student&apos;s guardian.</li>
          <li>
            Offer the student and family a referral for assessment for
            behavioral health services.
          </li>
          <li>
            If the family accepts the referral, provide a warm handoff to the
            agency.
          </li>
          <li>
            Provide the student and family information to a local crisis
            resource in the event a crisis occurs.
          </li>
          <li>
            Document the family contacts, support and referrals under the Mental
            Health Concern created upon submitting this form.
          </li>
          <li>
            The Mental Health Concern created when completing this form will
            automatically create a notification to the site leadership team.
          </li>
        </ol>
      </Box>

      <Box>
        <Typography variant="h4">
          Before and/or After School Hours and/or Holidays
        </Typography>

        <ol>
          <li>Call COPES immediately (918-744-4800).</li>
          <li>Contact campus police (918-749-9966).</li>
          <li>
            If a threat was made at any time, the following (link below) should
            also be accessed and a threat assessment should be completed
            immediately. (Instructions included in form.)
          </li>
          <li>
            <a
              href="https://drive.google.com/drive/folders/1Bffa8_MpIsk757Mw_86tZTNoRWI5iUrQ"
              target="_blank"
              rel="noreferrer"
            >
              Threat Assessment & Success Plan Form Links
            </a>
          </li>
          <li>Contact the school leader.</li>
          <li>
            Upon return to school, complete the report Box of this form (Section
            8 - scroll below). Answer all questions. If the question is not
            applicable, enter “NA” in the blank.
          </li>
          <li>
            Complete all corresponding documentation referenced in the form
            below.
          </li>
          <li>
            Completing this form will create a notification to the site crisis
            response team.
          </li>
          <li>
            Upon return to school, if the student is NOT participating in
            behavioral health services, offer the student and family a referral
            for assessment for behavioral health services.
          </li>
          <li>
            Document the intervention/support under the Mental Health Concern
            created upon submitting this form.
          </li>
          <li>
            For students that do NOT qualify for Exceptional Student Services
            (ESS), upon return to school create a Student Success Plan with a
            safety focus OR
          </li>
          <li>
            For students receiving services through ESS (have an IEP), upon
            return to school contact the caseload teacher regarding inclusion of
            success plan measures in the existing IEP.
          </li>
          <li>
            Follow the post crisis support steps referenced in Section 10 of
            this document.
          </li>
        </ol>
      </Box>

      <Box>
        <Typography variant="h4">
          Medical Clearance for Reported or Observable Injury
        </Typography>
        <ol>
          <li>
            If a student’s crisis includes reported recent self-injury (self
            harm, reported overdose, etc.) and/or has observable evidence of
            self-injury (e.g. cuts, scratches, etc.), the student should be
            referred to the health services representative for medical clearance
            immediately. All other steps noted in instructions for completing
            this form should be followed, as well as additional steps related to
            any special circumstance referenced in the guidance. (NOTE: If no
            nurse is present, the site should contact the health services
            department at 918-746-6364 to request assistance.)
          </li>
        </ol>
      </Box>

      <Box>
        <Typography variant="h4">
          Allegations of Bullying Related to Current/Recently Reported Crisis
        </Typography>
        <ol>
          <li>
            Reference links that lead directly to Team Tulsa’s school board
            policy and district supports/forms.
          </li>
          <li>
            <a
              href="https://drive.google.com/drive/u/0/folders/1n9tw1gY1n2WJbh4WvPLuOwBPONLM9ITn"
              target="_blank"
              rel="noreferrer"
            >
              Bullying Investigation Information, Forms, & Board Policy
            </a>
          </li>
          <li>
            <a
              href="https://drive.google.com/drive/u/0/folders/0Bway_bGCtmiOOWxkZXV0ZUFxNVk"
              target="_blank"
              rel="noreferrer"
            >
              Bullying & Cyberbullying Prevention/Intervention Resources
            </a>
          </li>
          <li>
            Document and refer allegations to the site primary and/or secondary
            bullying investigator. (The site investigators’ responsibilities
            include initiating, conducting and documenting a site bullying
            investigation, including findings.)
          </li>
          <li>
            Upon conclusion of the investigation, the site investigators will
            upload the completed bullying investigation form(s) and supporting
            documentation into the TIPS system.
          </li>
        </ol>
      </Box>

      <Box>
        <Typography variant="h4">Follow Up & Re-Entry</Typography>
        <ol>
          <li>
            Obtain PHI (
            <a
              href="https://docs.google.com/document/d/1ih193OUjjdfI0V8WyhNo4fn-Ty5psLF3BsbpgitmAaQ/edit"
              target="_blank"
              rel="noreferrer"
            >
              OKLAHOMA STANDARD AUTHORIZATION TO USE OR SHARE of PROTECTED
              HEALTH INFORMATION (PHI)
            </a>
            ) release to improve continuity of support for the student.
          </li>
          <li>
            Create and implement a{' '}
            <a
              href="https://docs.google.com/document/d/1IfvJNqazz8_PuZ6NF1XWfkDEg2Uu06ML5rzMt6IKzY0/edit"
              target="_blank"
              rel="noreferrer"
            >
              Student Success Plan
            </a>{' '}
            with a safety focus. (*General education students only.
            Success/safety measures for students currently receiving ESS
            modifications should be incorporated into the student&apos;s
            existing individualized education plan e.g. IEP.)
          </li>
          <li>
            Access & complete the{' '}
            <a
              href="https://sde.ok.gov/sde/sites/ok.gov.sde/files/STUDNET%20RE-ENTRY%20PLAN%20OSDE.pdf"
              target="_blank"
              rel="noreferrer"
            >
              OSDE Student Re-Entry/Success Plan
            </a>{' '}
            to ensure continuity of school-based support and access the{' '}
            <a
              href="https://docs.google.com/document/d/1BwN33SvjJIpa24K6LNJXyelDwT4ELPrckqa4LxEWSXE/edit"
              target="_blank"
              rel="noreferrer"
            >
              OSDE Crisis Kit for Counselors & Social Workers
            </a>{' '}
            for additional resources, as appropriate.
          </li>
          <li>
            Refer to the{' '}
            <a
              href="https://docs.google.com/document/d/1sUkERLaAMDGdk5QYIFZwzA1fKQs4NuSlUy9RmYqcYxk/edit"
              target="_blank"
              rel="noreferrer"
            >
              School-Community Mental Health Partnership Guidance
            </a>{' '}
            if site-based mental health-related services are recommended.
          </li>
          <li>
            Utilize the{' '}
            <a
              href="https://drive.google.com/drive/folders/0Bway_bGCtmiOZ3oyNDJPMnlHdDA"
              target="_blank"
              rel="noreferrer"
            >
              Suicide: Awareness, Prevention, Intervention, & Post Supports
            </a>{' '}
            resource suite for resources (district/local/state/etc.) to support
            students and families, if the student is returning to school
            following an incident involving suicidal ideation/gestures/attempt,
            self-injurious behavior, etc.
          </li>
          <li>
            Continue to access the{' '}
            <a
              href="https://drive.google.com/drive/folders/0Bway_bGCtmiON1BNZjN6R18zSFU"
              target="_blank"
              rel="noreferrer"
            >
              Student & Family Support Services Shared Wellness Resources
            </a>{' '}
            live binder for additional resources, as needed, applicable to the
            individual student&apos;s/family&apos;s needs.
          </li>
          <li>
            Ensure all follow-up notes, supports, and referrals are documented
            under the Mental Health Concern created upon submitting this form.
          </li>
        </ol>
      </Box>

      <Box>
        <Typography variant="h4">
          Crisis Response Protocols & Best Practices for Social Services
          Delivery
        </Typography>

        <ol>
          <li>
            <a
              href="https://docs.google.com/document/d/1zPlZHepDU6ahBAWXqqV2dqFmPMvVpcfIbblSMNBurYw/edit"
              target="_blank"
              rel="noreferrer"
            >
              Best Practice Guide for Social Services Delivery (ALL Platforms)
            </a>
          </li>
          <li>
            <a
              href="https://tulsaschools-org.zoom.us/j/82428038489?pwd=QmMrOWU2VEphbUZkd2pabmdmbVprdz09"
              target="_blank"
              rel="noreferrer"
            >
              Bridges &quot;FOR US&quot; (*Weekly Optional Mental Health Team
              Consult via Zoom) Tuesdays from 10-11 AM{' '}
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/document/d/1hqBDvY_BPlPy9hAYF7zg_Rg51conhu3VxPic5snFJTE/edit"
              target="_blank"
              rel="noreferrer"
            >
              Mental Health Supports for Team Tulsa
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/document/d/1Yedcx0PMJy6bjoG7zsqBXHJ1fbILy3C-D7Ho2g9-3DY/edit"
              target="_blank"
              rel="noreferrer"
            >
              School-Community Mental Health Partnership Guidance
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/document/d/1wDHxWY_D0U0sMnfw_viw9_DujBVwRtPipLT-WOJuncE/edit"
              target="_blank"
              rel="noreferrer"
            >
              Confidential Crisis Intervention Report/Referral
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/spreadsheets/d/1kV8eQak3seHgz0eicfkEciQZNibHGfcrlcqJQd8tcKQ/edit#gid=727016459"
              target="_blank"
              rel="noreferrer"
            >
              Approved Mental Health Related Services Partners
            </a>
          </li>
          <li>
            <a
              href="https://drive.google.com/drive/folders/1aURF4MbgygFW6t_9tDQuxRpmA0VboUyw"
              target="_blank"
              rel="noreferrer"
            >
              Equity Resources for Student & Stakeholder Support
            </a>
          </li>
          <li>
            <a
              href="https://drive.google.com/drive/search?q=student%20and%20family%20shared%20wellness%20resources"
              target="_blank"
              rel="noreferrer"
            >
              Student & Family Support Services Shared Wellness Resources
            </a>
          </li>
          <li>
            <a
              href="https://drive.google.com/drive/folders/0Bway_bGCtmiOR0QtdHd1Q29vY0U"
              target="_blank"
              rel="noreferrer"
            >
              Section 504 - Training Information & Resources
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/document/d/1AToZAZnzeWoY6no411Ubbi3hdCmeHhsPdbJgF9bu8_4/edit"
              target="_blank"
              rel="noreferrer"
            >
              Section 504 Compliance Instructions
            </a>
          </li>
          <li>
            <a
              href="https://docs.google.com/document/d/1XbppA2V1qtwpQKnYLbD0Tx0ul1n-d0i97Yi9EY4SIbs/edit"
              target="_blank"
              rel="noreferrer"
            >
              Site Compliance Checklist Template - (Due April 15)
            </a>
          </li>
        </ol>
      </Box>
    </Stack>
  )
}

const CrisisFormsInstructions = () => (
  <AccordionInstructions>
    <Instructions />
  </AccordionInstructions>
)

AccordionInstructions.propTypes = {
  children: PropTypes.node
}

export default CrisisFormsInstructions
