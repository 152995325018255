import React from 'react'
import AdminSchoolUsersTable from './users/table'
import { debounce } from 'lodash'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/admin-page-container'
import { routerPropTypes } from 'utils/prop-types'
import { fetchSchool } from 'api/admin/schools'
import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Typography
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export default class AdminSchool extends React.Component {
  static propTypes = {
    ...routerPropTypes
  }

  state = {
    isLoading: true,
    users: [],
    schoolId: this.schoolIdFromUrl(),
    schoolName: '',
    pages: null,
    search: ''
  }

  componentDidMount() {
    this.fetchSchoolUsers()
  }

  schoolIdFromUrl() {
    return parseInt(window.location.href.split('schools/')[1])
  }

  searchChanged = e => {
    const search = e.target.value
    this.setState({ search }, () => {
      this.fetchSchoolUsers()
    })
  }

  fetchSchoolUsers = debounce(
    attrs => {
      this.setState({ isLoading: true })
      const params = {
        search: this.state.search,
        ...attrs
      }
      fetchSchool(this.state.schoolId, params)
        .then(res => {
          this.setState({
            users: res.data.users,
            isLoading: false,
            schoolName: res.data.school.name,
            pages: res.meta.pages
          })
          return res.data
        })
        .catch(() => {
          this.setState({ isLoading: false })
          alert('Failed to fetch school users :(')
        })
    },
    300,
    { leading: false, trailing: true }
  )

  render() {
    const { isLoading, users, schoolName, pages, search } = this.state

    return (
      <Page name="School" title="School">
        <PageContainer>
          <Stack spacing={2}>
            <Typography variant="h1">Employees</Typography>
            <Typography variant="h2">{schoolName}</Typography>

            <Box>
              <FormControl fullWidth>
                <InputLabel id="search-label">Search</InputLabel>
                <OutlinedInput
                  id="search"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Search"
                  value={search}
                  onChange={e => {
                    this.searchChanged(e)
                  }}
                />
              </FormControl>
            </Box>

            <AdminSchoolUsersTable
              data={users}
              totalPages={pages}
              loading={isLoading}
              fetchData={this.fetchSchoolUsers}
            />
          </Stack>
        </PageContainer>
      </Page>
    )
  }
}
