import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Tooltip } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email'

export default function EmailUserButton({ user }) {
  if (!user.email) {
    return null
  }

  const handleClick = () => {
    window.open(`mailto:${user.email}`, '_blank')
  }

  return (
    <Tooltip title="Email User" arrow placement="top">
      <IconButton
        aria-label="email user"
        variant="contained"
        color="primary"
        id={`js-admin-email-user-${user.id}`}
        onClick={handleClick}
      >
        <EmailIcon />
      </IconButton>
    </Tooltip>
  )
}

EmailUserButton.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string
  }).isRequired
}
