import { get, patch } from 'utils/ajax'

export const fetchGoalSettings = params => {
  return get('/api/admin/goal_settings', { params })
}

export const updateGoalSetting = params => {
  return patch(`/api/admin/goal_settings/${params.id}`, {
    goal_setting: params
  })
}
