import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { alert } from 'utils/alert'
import { createSchoolConcernsCsv } from 'api/interventions'
import { Box, Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import DownloadingIcon from '@mui/icons-material/Downloading'

class WellnessConcernsCsvLink extends React.Component {
  state = { concerns: [], loading: false }
  csvLink = React.createRef()

  fetchConcernsForCSV = () => {
    const { schoolId } = this.props

    this.setState({ loading: true })

    createSchoolConcernsCsv({ school_id: schoolId })
      .catch(_err => {
        this.setState({ loading: false })
      })
      .then(() => {
        this.setState({ loading: false })
        alert(
          'The CSV is being generated - check the Exports page for its status.'
        )
      })
  }

  render() {
    const { loading } = this.state

    return (
      <Box>
        <Button
          variant="contained"
          onClick={this.fetchConcernsForCSV}
          disabled={loading}
          startIcon={loading ? <DownloadingIcon /> : <DownloadIcon />}
        >
          CSV
        </Button>
      </Box>
    )
  }
}

WellnessConcernsCsvLink.propTypes = {
  schoolId: PropTypes.number
}

const mapStateToProps = state => ({
  schoolId: state.schools.schoolId
})

export default connect(mapStateToProps)(WellnessConcernsCsvLink)
