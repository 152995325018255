import React from 'react'
import { withRouter } from 'react-router-dom'
import { withRouterPropTypes } from 'utils/react-router-prop-types'

// https://stackoverflow.com/a/54343182/1596013

function ScrollToTop({ history, children }) {
  React.useEffect(() => {
    const listener = () => window.scrollTo(0, 0)
    const unlisten = history.listen(listener)

    return () => unlisten()
  }, [])

  return <React.Fragment>{children}</React.Fragment>
}

ScrollToTop.propTypes = {
  ...withRouterPropTypes
}

export default withRouter(ScrollToTop)
