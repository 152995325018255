import React from 'react'
import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'

function DownloadLink({ href, children }) {
  return (
    <a target="_blank" rel="noopener noreferrer" href={href}>
      {children}
    </a>
  )
}
DownloadLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any
}

function Referral({ childStudyReferral }) {
  const selectedAcademicConcerns = childStudyReferral.referral_concerns.filter(
    referralConcern => referralConcern.category === 'academic'
  )
  const selectedAttendanceConcerns =
    childStudyReferral.referral_concerns.filter(
      referralConcern => referralConcern.category === 'attendance'
    )
  const selectedBehaviorConcerns = childStudyReferral.referral_concerns.filter(
    referralConcern => referralConcern.category === 'behavior'
  )
  const selectedSocialHealthConcerns =
    childStudyReferral.referral_concerns.filter(
      referralConcern => referralConcern.category === 'social_health'
    )

  return (
    <Grid container rowSpacing={{ xs: 2 }}>
      <Grid xs={12} item>
        <h4>Referrer Information</h4>
        <div id="referrer-info">
          <h6>
            <b>Name:</b> {childStudyReferral.referrer_name}
          </h6>
          <h6>
            <b>Title:</b> {childStudyReferral.referrer_title}
          </h6>
        </div>
      </Grid>
      <Grid xs={12} item>
        <h4>Problem(s)/Concern(s)</h4>
        {childStudyReferral.has_behavior_concern && (
          <Grid container id="behavior" columnSpacing={{ xs: 2 }}>
            <Grid item xs={2}>
              <b>Behavior</b>
            </Grid>
            <Grid item xs={5}>
              {childStudyReferral.behavior_concern_summary}
            </Grid>
            <Grid item xs={5}>
              <span>
                {selectedBehaviorConcerns.length > 0 ? 'Tagged Concerns: ' : ''}
              </span>
              {selectedBehaviorConcerns.map((concern, index) => (
                <Link
                  key={`behavior-concern-${index}`}
                  to={`/concerns/${concern.id}`}
                  target="_blank"
                  style={{ marginRight: '0.5em' }}
                >
                  {concern.concern_name}
                </Link>
              ))}
            </Grid>
          </Grid>
        )}
        {childStudyReferral.has_academic_concern && (
          <Grid container id="academic" columnSpacing={{ xs: 2 }}>
            <Grid item xs={2}>
              <b>Academic</b>
            </Grid>
            <Grid item xs={5}>
              {childStudyReferral.academic_concern_summary}
            </Grid>
            <Grid item xs={5}>
              <span>
                {selectedAcademicConcerns.length > 0 ? 'Tagged Concerns: ' : ''}
              </span>
              {selectedAcademicConcerns.map((concern, index) => (
                <Link
                  key={`academic-concern-${index}`}
                  to={`/concerns/${concern.id}`}
                  target="_blank"
                  style={{ marginRight: '0.5em' }}
                >
                  {concern.concern_name}
                </Link>
              ))}
            </Grid>
          </Grid>
        )}
        {childStudyReferral.has_attendance_concern && (
          <Grid container id="attendance" columnSpacing={{ xs: 2 }}>
            <Grid item xs={2}>
              <b>Attendance</b>
            </Grid>
            <Grid item xs={5}>
              {childStudyReferral.attendance_concern_summary}
            </Grid>
            <Grid item xs={5}>
              <span>
                {selectedAttendanceConcerns.length > 0
                  ? 'Tagged Concerns: '
                  : ''}
              </span>
              {selectedAttendanceConcerns.map((concern, index) => (
                <Link
                  key={`attendance-concern-${index}`}
                  to={`/concerns/${concern.id}`}
                  target="_blank"
                  style={{ marginRight: '0.5em' }}
                >
                  {concern.concern_name}
                </Link>
              ))}
            </Grid>
          </Grid>
        )}
        {childStudyReferral.has_social_health_concern && (
          <Grid container id="social_health" columnSpacing={{ xs: 2 }}>
            <Grid item xs={2}>
              <b>Social Health</b>
            </Grid>
            <Grid item xs={5}>
              {childStudyReferral.social_health_concern_summary}
            </Grid>
            <Grid item xs={5}>
              <span>
                {selectedSocialHealthConcerns.length > 0
                  ? 'Tagged Concerns: '
                  : ''}
              </span>
              {selectedSocialHealthConcerns.map((concern, index) => (
                <Link
                  key={`social-health-concern-${index}`}
                  to={`/concerns/${concern.id}`}
                  target="_blank"
                  style={{ marginRight: '0.5em' }}
                >
                  {concern.concern_name}
                </Link>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid xs={12} item>
        <h4>Summary of the Problem/Concern</h4>
        {childStudyReferral.summary}
      </Grid>
      <Grid xs={12} item>
        <h4>Strategies Tried Prior to Referral - Mandatory</h4>
        <Grid container id="conference-with-student" columnSpacing={{ xs: 2 }}>
          <Grid item xs={3}>
            <b>Conference with student</b>
          </Grid>
          <Grid item xs={6}>
            {childStudyReferral.conference_with_student_summary}
          </Grid>
          <Grid item xs={3}>
            Date:{' '}
            {moment(childStudyReferral.conference_with_student_date).format(
              'MM/DD/YYYY'
            )}
          </Grid>
        </Grid>
        <Grid container id="conference-with-parent" columnSpacing={{ xs: 2 }}>
          <Grid item xs={3}>
            <b>Conference with Parents/Guardian</b>
          </Grid>
          <Grid item xs={6}>
            {childStudyReferral.conference_with_parent_summary}
          </Grid>
          <Grid item xs={3}>
            Date:{' '}
            {moment(childStudyReferral.conference_with_parent_date).format(
              'MM/DD/YYYY'
            )}
          </Grid>
        </Grid>
        <Grid container columnSpacing={2}>
          <Grid item xs={3}>
            <b>Strategy Summary</b>
          </Grid>
          <Grid item xs={9}>
            {childStudyReferral.strategies_summary}
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item>
        <h4>Documentation</h4>
        <Grid container id="classwork" columnSpacing={{ xs: 2 }}>
          <Grid item xs={2}>
            <h6>Classwork</h6>
          </Grid>
          <Grid item xs={6}>
            {childStudyReferral.classwork_summary}
          </Grid>
          <Grid item xs={4}>
            {childStudyReferral.forms
              .filter(form => form.category === 'classwork')
              .map((form, index) => (
                <DownloadLink key={index} href={form.file_url}>
                  Link {index + 1}
                </DownloadLink>
              ))}
          </Grid>
        </Grid>

        <Grid container id="student-observation" columnSpacing={{ xs: 2 }}>
          <Grid item xs={2}>
            <h6>Student Observation</h6>
          </Grid>
          <Grid item xs={6}>
            {childStudyReferral.student_observation_summary}
          </Grid>
          <Grid item xs={4}>
            {childStudyReferral.forms
              .filter(form => form.category === 'student_observation')
              .map((form, index) => (
                <DownloadLink key={index} href={form.file_url}>
                  Link {index + 1}
                </DownloadLink>
              ))}
          </Grid>
        </Grid>

        <Grid container id="class-assessments" columnSpacing={{ xs: 2 }}>
          <Grid item xs={2}>
            <h6>Class Assessments</h6>
          </Grid>
          <Grid item xs={6}>
            {childStudyReferral.class_assessments_summary}
          </Grid>
          <Grid item xs={4}>
            {childStudyReferral.forms
              .filter(form => form.category === 'class_assessments')
              .map((form, index) => (
                <DownloadLink key={index} href={form.file_url}>
                  Link {index + 1}
                </DownloadLink>
              ))}
          </Grid>
        </Grid>

        <Grid container id="district-assessments" columnSpacing={{ xs: 2 }}>
          <Grid item xs={2}>
            <h6>District Assessments</h6>
          </Grid>
          <Grid item xs={6}>
            {childStudyReferral.district_assessments_summary}
          </Grid>
          <Grid item xs={4}>
            {childStudyReferral.forms
              .filter(form => form.category === 'district_assessments')
              .map((form, index) => (
                <DownloadLink key={index} href={form.file_url}>
                  Link {index + 1}
                </DownloadLink>
              ))}
          </Grid>
        </Grid>

        <Grid container id="behavior-information" columnSpacing={{ xs: 2 }}>
          <Grid item xs={2}>
            <h6>Behavior Information</h6>
          </Grid>
          <Grid item xs={6}>
            {childStudyReferral.behavior_information_summary}
          </Grid>
          <Grid item xs={4}>
            {childStudyReferral.forms
              .filter(form => form.category === 'behavior_information')
              .map((form, index) => (
                <DownloadLink key={index} href={form.file_url}>
                  Link {index + 1}
                </DownloadLink>
              ))}
          </Grid>
        </Grid>

        <Grid container id="health-information" columnSpacing={{ xs: 2 }}>
          <Grid item xs={2}>
            <h6>Health Information</h6>
          </Grid>
          <Grid item xs={6}>
            {childStudyReferral.health_information_summary}
          </Grid>
          <Grid item xs={4}>
            {childStudyReferral.forms
              .filter(form => form.category === 'health_information')
              .map((form, index) => (
                <DownloadLink key={index} href={form.file_url}>
                  Link {index + 1}
                </DownloadLink>
              ))}
          </Grid>
        </Grid>

        <Grid container id="outside-agency-reports" columnSpacing={{ xs: 2 }}>
          <Grid item xs={2}>
            <h6>Outside Agency Reports</h6>
          </Grid>
          <Grid item xs={6}>
            {childStudyReferral.outside_agency_reports_summary}
          </Grid>
          <Grid item xs={4}>
            {childStudyReferral.forms
              .filter(form => form.category === 'outside_agency_reports')
              .map((form, index) => (
                <DownloadLink key={index} href={form.file_url}>
                  Link {index + 1}
                </DownloadLink>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

Referral.propTypes = {
  childStudyReferral: PropTypes.object
}

export default Referral
