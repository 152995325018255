import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { destroyInterventionScore } from 'api/intervention-scores'
import Spinner from 'shared/spinner/spinner'
import ScoreDialog from './dialog'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'

function ScoreTable({
  interventionId,
  isLoading,
  isSuccess,
  isDisabled,
  scores
}) {
  const queryClient = useQueryClient()
  const [currentScore, setCurrentScore] = useState(null)
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }
  const handleShow = s => {
    setCurrentScore(s)
    setShow(true)
  }

  const handleDelete = () => {
    deleteScore.mutate(currentScore.id)
  }

  const deleteScore = useMutation(id => destroyInterventionScore({ id: id }), {
    onSuccess: () =>
      queryClient.invalidateQueries(`interventions/${interventionId}/scores`)
  })

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={4}>
                <Spinner mt={2} mb={2} centered={true} />
              </TableCell>
            </TableRow>
          )}
          {isSuccess && scores.length == 0 && (
            <TableRow>
              <TableCell align={'center'} colSpan={4}>
                No scores recorded.
              </TableCell>
            </TableRow>
          )}
          {isSuccess &&
            scores.length > 0 &&
            scores.map((s, index) => (
              <TableRow key={`score-row-${s.id}`}>
                <TableCell>{s.event_on}</TableCell>
                <TableCell>{s.score}</TableCell>
                <TableCell>{s.notes}</TableCell>
                <TableCell>
                  <IconButton
                    disabled={isDisabled}
                    onClick={() => handleShow(s)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    disabled={scores.length < 2 || index === 0 || isDisabled}
                    onClick={() => handleDelete()}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {show && !!currentScore && (
        <ScoreDialog
          interventionId={interventionId}
          previousScore={currentScore}
          show={show}
          handleClose={() => handleClose()}
        />
      )}
    </TableContainer>
  )
}

ScoreTable.propTypes = {
  interventionId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  scores: PropTypes.array
}

export default ScoreTable
