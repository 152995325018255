import React from 'react'
import PropTypes from 'prop-types'
import { post } from 'utils/ajax'
import {
  Alert,
  Button,
  Box,
  Select,
  Stack,
  InputLabel,
  MenuItem,
  FormControl,
  TextField,
  Link as MuiLink
} from '@mui/material'
import { alert } from 'utils/alert'
import { titleize } from 'utils'
import { Dialog } from 'shared/dialog'
import { determineTermOptions } from 'utils/report-cards'
import { Link } from 'react-router-dom'

export default class StudentsLetterGeneratorModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    studentIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    filters: PropTypes.array,
    columnApiIncludes: PropTypes.array,
    totalResults: PropTypes.number
  }

  state = {}

  reduceApiIncludes = columnApiIncludes =>
    columnApiIncludes?.reduce((hash, include) => {
      hash[`include_${include}`] = true
      return hash
    }, {})

  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show) {
      this.setState({
        locale: null,
        reportName: '',
        reportType: null,
        color: 'black_and_white',
        term: null,
        reportCardType: null
      })
    }
  }

  generateLetters = e => {
    e.preventDefault()
    const { onHide, studentIds, filters, columnApiIncludes } = this.props
    const { locale, reportName, reportType, color, term, reportCardType } =
      this.state

    post('/api/web/reports', {
      locale,
      report_type: reportType,
      report_name: reportName,
      student_ids: studentIds,
      color: color,
      selected_term: term,
      report_card_type: reportCardType,
      ...filters,
      ...this.reduceApiIncludes(columnApiIncludes)
    })
      .then(_res => {
        alert(
          <>
            Letter generation processing - check{' '}
            <MuiLink
              component={Link}
              to={'/exports'}
              sx={{ color: 'white', textDecoration: 'underline' }}
            >
              Exports page
            </MuiLink>{' '}
            for status.
          </>
        )
      })
      .catch(() => {
        alert('There was an error generating these letters.', 'danger')
      })
      .then(() => onHide())
  }

  reportTypeChanged = event => {
    const reportType = event.target.value

    this.setState({
      reportType,
      reportName: titleize(reportType)
    })
  }

  showLocaleSelect = () => {
    const { reportType } = this.state
    return (
      !reportType ||
      ![
        'graduation_summaries',
        'graduation_letters',
        'oklahoma_promise_letters',
        'ncaa_division_one',
        'ncaa_division_two',
        'missing_assignments'
      ].includes(reportType)
    )
  }

  render() {
    const { onHide, show, studentIds, totalResults } = this.props
    const { locale, reportType, reportName, term, reportCardType } = this.state

    return (
      <Dialog
        open={show}
        onClose={onHide}
        title={`Generate Letter(s) For ${
          studentIds.includes('all') ? totalResults : studentIds.length
        } Student(s)`}
      >
        <Box sx={{ p: 4 }}>
          <form onSubmit={this.generateLetters}>
            <Stack>
              <FormControl fullWidth>
                <InputLabel id="report-type-label">Report Type</InputLabel>
                <Select
                  label="Report Type"
                  labelId="report-type-label"
                  id="report-type"
                  value={reportType}
                  placeholder="Select Report Type"
                  onChange={this.reportTypeChanged}
                >
                  <MenuItem value="student_summaries">Student Summary</MenuItem>
                  <MenuItem value="chronic_absenteeism">
                    Chronic Absenteeism
                  </MenuItem>
                  <MenuItem value="five_consecutive_absences">
                    Five Consecutive Absences
                  </MenuItem>
                  <MenuItem value="graduation_summaries">
                    Grad Check - Summary
                  </MenuItem>
                  <MenuItem value="graduation_letters">
                    Grad Check - Full
                  </MenuItem>
                  <MenuItem value="oklahoma_promise_letters">
                    OK Promise GPA
                  </MenuItem>
                  <MenuItem value="ncaa_division_one">NCAA D1</MenuItem>
                  <MenuItem value="ncaa_division_two">NCAA D2</MenuItem>
                  <MenuItem value="missing_assignments">
                    Missing Assignments
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                required
                label="Report Name"
                id="report-name"
                placeholder="Specify a name for the report file"
                value={reportName}
                onChange={event =>
                  this.setState({ reportName: event.target.value })
                }
              />

              {this.showLocaleSelect() ? (
                <FormControl fullWidth>
                  <InputLabel id="locale">Language</InputLabel>
                  <Select
                    label="Language"
                    labelId="locale-label"
                    id="locale"
                    value={locale}
                    placeholder="Select a language"
                    onChange={event =>
                      this.setState({ locale: event.target.value })
                    }
                  >
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="es">Spanish</MenuItem>
                    <MenuItem value="chk">
                      Chuukese (Attendance Letters Only)
                    </MenuItem>
                    <MenuItem value="prs">
                      Dari (Attendance Letters Only)
                    </MenuItem>
                    <MenuItem value="hmn">
                      Hmong (Attendance Letters Only)
                    </MenuItem>
                    <MenuItem value="mh">
                      Marshallese (Attendance Letters Only)
                    </MenuItem>
                    <MenuItem value="ps">
                      Pashto (Attendance Letters Only)
                    </MenuItem>
                  </Select>
                </FormControl>
              ) : (
                <Alert severity="info" sx={{ my: 2 }}>
                  This report is currently only available in English.
                </Alert>
              )}

              {reportType === 'report_cards' && (
                <FormControl fullWidth>
                  <InputLabel id="reportCardType">Report Card Type</InputLabel>
                  <Select
                    label="Report Card Type"
                    labelId="reportCardType-label"
                    id="reportCardType"
                    value={reportCardType}
                    placeholder="Select a type"
                    onChange={event =>
                      this.setState({
                        reportCardType: event.target.value,
                        term: null
                      })
                    }
                  >
                    <MenuItem value="pre_k">Pre-K</MenuItem>
                    <MenuItem value="montessori_pre_k">
                      Pre-K (Montessori)
                    </MenuItem>
                    <MenuItem value="kindergarten">Kindergarten</MenuItem>
                    <MenuItem value="montessori_kindergarten">
                      Kindergarten (Montessori)
                    </MenuItem>
                    <MenuItem value="elementary">Elementary</MenuItem>
                    <MenuItem value="montessori_elementary">
                      Elementary (Montessori)
                    </MenuItem>
                    <MenuItem value="middle">Middle</MenuItem>
                    <MenuItem value="grading_for_understanding_middle">
                      Middle (G4U Pilot)
                    </MenuItem>
                    <MenuItem value="high">High</MenuItem>
                  </Select>
                </FormControl>
              )}

              {reportType === 'report_cards' && (
                <FormControl fullWidth>
                  <InputLabel id="term-label">Term*</InputLabel>
                  <Select
                    label="Term*"
                    id="term"
                    labelId="term-label"
                    value={term}
                    placeholder="Select a term"
                    onChange={event =>
                      this.setState({ term: event.target.value })
                    }
                  >
                    {determineTermOptions(reportCardType).map(option => (
                      <MenuItem
                        key={`reportCardType-${option.value}`}
                        value={option.value}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <Button type="submit" variant="contained">
                Submit
              </Button>
            </Stack>
          </form>
        </Box>
      </Dialog>
    )
  }
}
