import React from 'react'
import PropTypes from 'prop-types'
import AttendanceRiskTable from 'components/students/attendance-risk-table'
import Tooltip from 'components/shared/tooltip'
import { Box, Chip } from '@mui/material'

const AttendanceBadge = ({ isParentPortal, score }) => {
  const renderScoreBadge = score => {
    if (score <= 80) {
      return <Chip color="error" label={I18n.jsT('badges.critical')} />
    } else if (score > 80 && score <= 90) {
      return <Chip color="warning" label={I18n.jsT('badges.high_risk')} />
    } else if (score > 90 && score <= 95) {
      return <Chip color="info" label={I18n.jsT('badges.at_risk')} />
    } else if (score > 95) {
      return <Chip color="success" label={I18n.jsT('badges.low_risk')} />
    } else null
  }

  return (
    <Box>
      {isParentPortal ? (
        renderScoreBadge(score)
      ) : (
        <Tooltip
          id="attendance-score"
          title="Attendance Risk Calculation"
          content={<AttendanceRiskTable />}
          placement="right"
        >
          {renderScoreBadge(score)}
        </Tooltip>
      )}
    </Box>
  )
}

AttendanceBadge.propTypes = {
  isParentPortal: PropTypes.bool,
  score: PropTypes.number
}

export default AttendanceBadge
