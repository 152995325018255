import { get, post, patch, destroy } from '@rails/request.js'

const destroyConfidentialCrisisForm = async id =>
  await destroy(`/api/web/confidential_crisis_forms/${id}`)

const getConfidentialCrisisForms = async params =>
  await get('/api/web/confidential_crisis_forms', { query: params })

const getConfidentialCrisisForm = async params =>
  await get(`/api/web/confidential_crisis_forms/${params.id}`)

const getConfidentialCrisisFormDocuments = async crisis_form_id =>
  await get(`/api/web/confidential_crisis_forms/${crisis_form_id}/documents`)

const getPrefilledConfidentialCrisisForm = async params =>
  await get('/api/web/confidential_crisis_forms/new', {
    query: params.student_id ? params : {}
  })

const postConfidentialCrisisForm = async params =>
  await post('/api/web/confidential_crisis_forms', {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

const patchConfidentialCrisisForm = async (id, params) =>
  await patch(`/api/web/confidential_crisis_forms/${id}`, {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

const getMentalWellnessAgencies = async () =>
  await get('/api/web/mental_wellness_agencies')

export {
  destroyConfidentialCrisisForm,
  getConfidentialCrisisForm,
  getConfidentialCrisisForms,
  getPrefilledConfidentialCrisisForm,
  getConfidentialCrisisFormDocuments,
  postConfidentialCrisisForm,
  patchConfidentialCrisisForm,
  getMentalWellnessAgencies
}
