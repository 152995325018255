import React from 'react'
import Page from 'shared/page'
import PropTypes from 'prop-types'
import PieChartFiveSlices from 'shared/charts/PieChartFiveSlices'
import PaperBox from 'shared/PaperBox'
import { fetchDistrictSlipData } from 'api/slip_forms'
import { Stack, Box } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

function DistrictSlipCompliance({ schoolId }) {
  const { data: districtSlipData } = useQuery(
    ['district-slip-data', schoolId],
    () => fetchDistrictSlipData({ school_id: schoolId }),
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  return (
    <Page name="District SLIP Compliance" title="District SLIP Compliance">
      <Stack>
        <PaperBox
          title="K-3 SLIP Form Completion Status"
          helperText={{
            'What is the SLIP Form?':
              'The purpose of a Student Literacy Intervention Plan (SLIP) is to define a plan that will enable the student to acquire the appropriate grade level reading skills and communicate this plan and the student’s progress to the student’s family.',
            'Which students are eligible?':
              'Students enrolled in kindergarten, first, second, and third grade in the public schools of Oklahoma shall be assessed at the beginning, middle, and end of each school year using a screening instrument approved by the State Board of Education. Any student found not to be reading at grade level shall be provided a Student Literacy Intervention Plan designed to enable the student to acquire the appropriate grade level reading skills. Diagnostic assessment shall be provided if determined appropriate, and progress monitoring shall continue throughout the year.',
            'How do I view these forms for each school?':
              'Navigate to a schools page. Within the navigation header click the SLIP tab.'
          }}
        >
          <Box id="test-k3-slip-completion">
            <PieChartFiveSlices
              meta={districtSlipData?.slip_form}
              one={{
                title: 'Signed',
                value: districtSlipData?.slip_form?.completed
              }}
              two={{
                title: 'Awaiting Signature',
                value: districtSlipData?.slip_form?.no_signature
              }}
              three={{
                title: 'Not Started',
                value: districtSlipData?.slip_form?.not_started
              }}
            />
          </Box>
        </PaperBox>

        <PaperBox
          title="4th & 5th Grade SLIP Form Completion Status"
          helperText={{
            'What is the SLIP?':
              'The purpose of a Student Literacy Intervention Plan (SLIP) is to define a plan that will enable the student to acquire the appropriate grade level reading skills and communicate this plan and the student’s progress to the student’s family.',
            'Which students are eligible?':
              'Students enrolled in kindergarten, first, second, and third grade in the public schools of Oklahoma shall be assessed at the beginning, middle, and end of each school year using a screening instrument approved by the State Board of Education. Any student found not to be reading at grade level shall be provided a Student Literacy Intervention Plan designed to enable the student to acquire the appropriate grade level reading skills. Diagnostic assessment shall be provided if determined appropriate, and progress monitoring shall continue throughout the year.',
            'How do I view these forms for each school?':
              'Navigate to a schools page. Within the navigation header click the SLIP tab.'
          }}
        >
          <Box id="test-4th-5th-slip-completion">
            <PieChartFiveSlices
              one={{
                title: 'Signed',
                value: districtSlipData?.slip_past_third_grade_form?.completed
              }}
              two={{
                title: 'Awaiting Signature',
                value:
                  districtSlipData?.slip_past_third_grade_form?.no_signature
              }}
              three={{
                title: 'Not Started',
                value: districtSlipData?.slip_past_third_grade_form?.not_started
              }}
            />
          </Box>
        </PaperBox>
      </Stack>
    </Page>
  )
}

DistrictSlipCompliance.propTypes = {
  schoolId: PropTypes.number
}

export default DistrictSlipCompliance
