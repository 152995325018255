import { keyBy } from 'lodash'

export const types = {
  FETCH_USER_TABLE_DATA: 'users/fetch_user_table_data',
  FETCH_USER_SCHOOLS: 'users/fetch_user_schools'
}

const initialState = {
  byId: {},
  meta: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USER_TABLE_DATA:
      return {
        ...state,
        byId: keyBy(action.payload.data, 'id'),
        meta: action.payload.meta
      }
    case types.FETCH_USER_SCHOOLS:
      return {
        ...state,
        userSchools: action.payload.schools
      }
    default:
      return state
  }
}
