import { get, post, patch, destroy } from '@rails/request.js'

const destroyMentalWellnessReferral = async id =>
  await destroy(`/api/web/mental_wellness_referrals/${id}`)

const getMentalWellnessReferrals = async params =>
  await get('/api/web/mental_wellness_referrals', {
    query: params,
    responseKind: 'json'
  })

const getMentalWellnessReferral = async params =>
  await get(`/api/web/mental_wellness_referrals/${params.id}`)

const getMentalWellnessReferralDocuments = async id =>
  await get(`/api/web/mental_wellness_referrals/${id}/documents`)

const getPrefilledMentalWellnessReferral = async params =>
  await get('/api/web/mental_wellness_referrals/new', {
    query: params.student_id ? params : {}
  })

const postMentalWellnessReferral = async params =>
  await post('/api/web/mental_wellness_referrals', {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

const patchMentalWellnessReferral = async (id, params) =>
  await patch(`/api/web/mental_wellness_referrals/${id}`, {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

const getMentalWellnessReferralReports = async params =>
  await get('/api/web/mental_wellness_referrals/reports', {
    query: params,
    responseKind: 'json'
  })

export {
  destroyMentalWellnessReferral,
  getMentalWellnessReferral,
  getMentalWellnessReferrals,
  getPrefilledMentalWellnessReferral,
  getMentalWellnessReferralDocuments,
  postMentalWellnessReferral,
  patchMentalWellnessReferral,
  getMentalWellnessReferralReports
}
