import { types } from './reducer'

export const addAlert = payload => {
  return dispatch => {
    dispatch({ type: types.ADD_ALERT, payload })
    return Promise.resolve()
  }
}

export const popAlert = () => {
  return dispatch => {
    dispatch({ type: types.POP_ALERT })
    return Promise.resolve()
  }
}
