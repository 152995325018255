import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  FormControl,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { fetchStudentSlipData } from 'api/slip_forms'
import { useQuery } from '@tanstack/react-query'
import SimpleAutocomplete from 'shared/simple-autocomplete'
import SlipButton from 'shared/slip-button'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import WarningIcon from '@mui/icons-material/Warning'
import { FiberManualRecord } from '@mui/icons-material'
import { isEmpty } from 'lodash'

function SlipCompliance({ schoolId, sectionId, teacherId }) {
  const [gradeLevel, setGradeLevel] = useState('')
  const [completionStatus, setCompletionStatus] = useState('')

  const params = {
    ...(sectionId && { section_id: sectionId }),
    ...(teacherId && { teacher_id: teacherId }),
    ...{ school_id: schoolId }
  }

  const { data: students } = useQuery(
    ['student-slip-data', schoolId, sectionId, teacherId],
    () =>
      fetchStudentSlipData(params).then(res => {
        return res.students
      }),
    {
      initialData: [],
      refetchOnWindowFocus: true
    }
  )

  const gradeLevelOptions = [
    { label: '5th', value: '5th' },
    { label: '4th', value: '4th' },
    { label: '3rd', value: '3rd' },
    { label: '2nd', value: '2nd' },
    { label: '1st', value: '1st' },
    { label: 'k', value: 'k' }
  ]

  const completionStatusOptions = [
    { label: 'Signed', value: 'signed' },
    { label: 'Awaiting Signature', value: 'awaiting_signature' },
    { label: 'Not Started', value: 'not_started' }
  ]

  const filterSelected = gradeLevel || completionStatus

  const clearAllFilters = () => {
    setGradeLevel('')
    setCompletionStatus('')
  }

  const has_signature_pad_or_scanned_document = (student = {}) => {
    const has_signature_pad_signatures =
      student?.slip_form_has_signature_pad_signatures ||
      student?.slip_past_third_grade_form_has_signature_pad_signatures

    const has_scanned_document =
      student?.slip_form_scanned_document_exists ||
      student?.slip_past_third_grade_form_scanned_document_exists

    return Boolean(has_signature_pad_signatures || has_scanned_document)
  }

  const sanitizedStudents = () => {
    let data = students

    if (gradeLevel) {
      data = data.filter(student => student.grade_level == gradeLevel)
    }

    if (completionStatus) {
      if (completionStatus == 'signed') {
        data = data.filter(student => {
          return has_signature_pad_or_scanned_document(student)
        })
      } else if (completionStatus == 'awaiting_signature') {
        data = data.filter(
          student =>
            (student.slip_form_id || student.slip_past_third_grade_form_id) &&
            !has_signature_pad_or_scanned_document(student)
        )
      } else {
        data = data.filter(
          student =>
            !student?.slip_form_id && !student?.slip_past_third_grade_form_id
        )
      }
    }

    return data
  }

  return (
    <Page name="School's SLIP Compliance" title="School's SLIP Compliance">
      <Stack>
        <Typography variant="h2">SLIP Management</Typography>
        <Box>
          <Typography variant="body1">
            Students required to have a Student Literacy Intervention Plan:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Students in K-3rd grades scoring lower than the 40th percentile on the most recent MAP Reading assessment will need a K-3 SLIP." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Students in 4th/5th grades who were probationary promoted or rsa retained." />
            </ListItem>
          </List>
        </Box>
        <Box>
          <Typography variant="body1">
            A Student Literacy Intervention Plan is considered signed when{' '}
            <b>either</b> of these bullet points are true:{' '}
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="has the parent/guardian's digital signature pad signature AND the current teacher's digital signature pad signature AND the next grade teacher's digital signature pad signature." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    has a scanned document attached with signatures for the
                    parent/guardian, current teacher, and next grade teacher*.{' '}
                    <b>
                      *Note: next grade teacher signature is only required for
                      the k-3 form.
                    </b>{' '}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>

        <Box>
          <Typography variant="body1">
            Below is a table listing the MAP Reading Percentile for each
            student. If a student tested below the 40th percentile, they will
            need to have a Student Literacy Intervention Plan created and signed
            by the relevant parties.
          </Typography>
        </Box>

        <Box>
          <Stack direction="row" spacing={2}>
            <FormControl fullWidth>
              <SimpleAutocomplete
                key={`gradeLevel-${gradeLevel}`}
                label={'Grade Level'}
                options={gradeLevelOptions}
                value={gradeLevel}
                onChange={option => setGradeLevel(option?.value || '')}
              />
            </FormControl>
            <FormControl fullWidth>
              <SimpleAutocomplete
                key={`hasSlip-${completionStatus}`}
                label={'Completion Status'}
                options={completionStatusOptions}
                value={completionStatus}
                onChange={option => setCompletionStatus(option?.value || '')}
              />
            </FormControl>

            {filterSelected && (
              <Button onClick={() => clearAllFilters()}>Clear All</Button>
            )}
          </Stack>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Student Name</TableCell>
              <TableCell>
                Grade <br />
                Level
              </TableCell>
              <TableCell>
                MAP <br />
                Reading <br />
                Percentile
              </TableCell>
              <TableCell>SLIP Form</TableCell>
              <TableCell>
                SLIP Form <br />
                Scanned <br />
                Document
              </TableCell>
              <TableCell>
                SLIP Form <br />
                Signature Pad
              </TableCell>
              <TableCell>
                Signature <br />
                Attempts
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sanitizedStudents().map(student => (
              <TableRow key={student.id}>
                <TableCell>
                  {student.last_name}, {student.first_name}
                </TableCell>
                <TableCell>{student.grade_level}</TableCell>
                <TableCell>
                  {`${student.percentile} (${student.term})`}{' '}
                  <small>
                    {student.map_test_type == 'Spanish MAP'
                      ? student.map_test_type
                      : ''}
                  </small>
                </TableCell>
                <TableCell>
                  {['k', '1st', '2nd', '3rd'].includes(student.grade_level) &&
                  student.percentile < 40 ? (
                    <SlipButton
                      slipId={student?.slip_form_id}
                      studentId={student?.id}
                      gradeLevel={student?.grade_level}
                    />
                  ) : (
                    ['k', '1st', '2nd', '3rd'].includes(student.grade_level) &&
                    'Not Required'
                  )}

                  {['4th', '5th'].includes(student.grade_level) &&
                  student.percentile < 40 ? (
                    <SlipButton
                      slipId={student?.slip_past_third_grade_form_id}
                      studentId={student?.id}
                      gradeLevel={student?.grade_level}
                    />
                  ) : (
                    ['4th', '5th'].includes(student.grade_level) &&
                    'Not Required'
                  )}
                </TableCell>
                <TableCell>
                  {student.percentile < 40 ? (
                    student?.slip_form_scanned_document_exists ||
                    student?.slip_past_third_grade_form_scanned_document_exists ? (
                      <CheckBoxIcon color="success" fontSize="large" />
                    ) : (
                      <WarningIcon color="warning" fontSize="large" />
                    )
                  ) : (
                    'Not Required'
                  )}
                </TableCell>
                <TableCell>
                  {student.percentile < 40 ? (
                    Boolean(student?.slip_form_has_signature_pad_signatures) ||
                    Boolean(
                      student?.slip_past_third_grade_form_has_signature_pad_signatures
                    ) ? (
                      <CheckBoxIcon color="success" fontSize="large" />
                    ) : (
                      <WarningIcon color="warning" fontSize="large" />
                    )
                  ) : (
                    'Not Required'
                  )}
                </TableCell>
                <TableCell>
                  {!isEmpty(
                    student?.slip_form_signature_virtual_conference ||
                      student?.slip_past_third_grade_form_signature_virtual_conference
                  ) && <Box>Virtual Conf</Box>}
                  {!isEmpty(
                    student?.slip_form_signature_parent_guardian_not_present ||
                      student?.slip_past_third_grade_form_signature_parent_guardian_not_present
                  ) && <Box>Unable To Attend</Box>}
                  <Box>{student?.schedule_guardian_conference_attempt_1}</Box>
                  <Box>{student?.schedule_guardian_conference_attempt_2}</Box>
                  <Box>{student?.slip_past_third_conference_attempt_1}</Box>
                  <Box>{student?.slip_past_third_conference_attempt_2}</Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
    </Page>
  )
}

SlipCompliance.propTypes = {
  schoolId: PropTypes.number.isRequired,
  sectionId: PropTypes.number,
  teacherId: PropTypes.number
}

export default SlipCompliance
