import React, { useState } from 'react'
import Dialog from 'shared/dialog'
import BehaviorRiskTable from './behavior-risk-table'
import {
  Box,
  Button,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

export default function BehaviorScoreLegend() {
  const [dialogOpen, setDialogOpen] = useState(false)
  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Behavior Key</Typography>

      <Stack direction={'row'} spacing={1}>
        <Chip color="success" label="No Risk" />
        <Chip color="secondary" label="At Risk" />
        <Chip color="warning" label="High Risk" />
        <Chip color="error" label="Critical" />
      </Stack>

      <Typography variant="body2">
        <Button onClick={openDialog}>How is this calculated?</Button>
      </Typography>

      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        title="How is the behavior risk score calculated?"
      >
        <Box>
          <Stack>
            <Typography variant="h4">Behavior Risk Calculation</Typography>

            <Typography variant="body1">
              Behavior Risk Calculation The behavior risk calculation is a
              function of weighted discipline incidents (referrals and
              suspensions) and the time since the incident occurred. The
              calculation weights the referrals and suspensions as follows:
            </Typography>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Discipline</TableCell>
                  <TableCell>Initial Score</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Tier IV Suspension</TableCell>
                  <TableCell>16</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tier III Suspension</TableCell>
                  <TableCell>8</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tier II Suspension</TableCell>
                  <TableCell>4</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tier I Suspension</TableCell>
                  <TableCell>2</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tier IV Referral</TableCell>
                  <TableCell>8</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tier III Referral</TableCell>
                  <TableCell>4</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tier II Referral</TableCell>
                  <TableCell>2</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tier I Referral</TableCell>
                  <TableCell>1</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Typography variant="body1">
              The score of the incident &apos;decays&apos; so that as the days
              since the incident increase, the score of that incident decreases.
              Referrals alse decay at a faster rate than suspensions, so
              suspensions will show up in the student&apos;s behavior score for
              a longer period of time.
            </Typography>
            <Typography variant="body1">
              For example, a Tier III Suspension and Tier IV Referral have the
              same initial weight. However after 120 days (one semester) the
              values will be:
            </Typography>
            <Typography variant="body1">Tier III Suspension: 1</Typography>
            <Typography variant="body1">Tier IV Referral: 0.25</Typography>
            <Typography variant="body1">
              In order to get the student behavior, we simply sum up all of the
              individual incident scores, and then we compare them to the
              thresholds
            </Typography>

            <BehaviorRiskTable />

            <Typography variant="body1">
              So if a student has a referral and suspension, it could look
              something like this.
            </Typography>
            <Typography variant="body1">
              Tier IV Referral 120 days ago + Tier II Suspension 60 days ago
            </Typography>
            <Typography variant="body1">
              0.25 + 1 = 1.25 = <Chip color="warning" label="High Risk" />
            </Typography>
          </Stack>
        </Box>
      </Dialog>
    </Stack>
  )
}
