import Page from 'components/shared/page'
import PropTypes from 'prop-types'
import React from 'react'
import { Stack, Typography } from '@mui/material'
import { getSlipForm, getPastThirdSlipForm } from 'api/slip_forms'
import SlipButton from 'shared/slip-button'
import SlipDyslexiaButton from 'shared/slip-dyslexia-button'
import { useQueries } from '@tanstack/react-query'

async function fetchSlipForm(student_id) {
  const forms = await getSlipForm({ student_id })
  return forms?.data?.[0]
}

async function fetchPastThirdSlipForm(student_id) {
  const forms = await getPastThirdSlipForm({ student_id })
  return forms?.data?.[0]
}

function useForms({ id, grade_level }) {
  const [slipQuery, pastThirdSlipQuery] = useQueries({
    queries: [
      {
        queryKey: ['slip-compliance-forms', id],
        queryFn: () => fetchSlipForm(id)
      },
      {
        queryKey: ['slip-compliance-forms-past-third', id],
        queryFn: () => fetchPastThirdSlipForm(id),
        enabled: ['4th', '5th'].includes(grade_level)
      }
    ]
  })
  const { data: slip } = slipQuery
  const { data: pastThirdSlip } = pastThirdSlipQuery

  return { slip, pastThirdSlip, slipQuery, pastThirdSlipQuery }
}

export function StudentSlipPage({ student }) {
  const { slip, pastThirdSlip } = useForms(student)
  const dyslexiaAddendum = slip?.dyslexia_addendum

  return (
    <Page name="Student's SLIP" title="Student Literacy Intervention Plan">
      {['4th', '5th'].includes(student.grade_level) &&
        !['Probation Promoted', 'RSA Retained'].includes(
          student.rsa_status
        ) && (
          <Typography>
            This student does not need a SLIP based on their RSA status in
            PowerSchool. Only students who were &quot;Probation Promoted&quot;
            or &quot;RSA Retained&quot; need a SLIP in the 4th/5th grades.
          </Typography>
        )}

      {['k', '1st', '2nd', '3rd'].includes(student.grade_level) && (
        <Stack direction="row">
          <SlipButton
            slipId={slip?.id}
            studentId={student.id}
            gradeLevel={student.grade_level}
          />

          <SlipDyslexiaButton
            slipId={slip?.id}
            dyslexiaAddendumId={dyslexiaAddendum?.id}
            dyslexiaAssessmentResults={slip?.dyslexia_assessment_results_str}
          />
        </Stack>
      )}

      {['4th', '5th'].includes(student.grade_level) &&
        student.rsa_status == 'Probation Promoted' && (
          <SlipButton
            slipId={pastThirdSlip?.id}
            studentId={student.id}
            gradeLevel={student.grade_level}
          />
        )}
    </Page>
  )
}

StudentSlipPage.propTypes = {
  student: PropTypes.object.isRequired
}

export default StudentSlipPage
