import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import FilterContainer from 'components/shared/filter-container'
import {
  actions as schoolActions,
  selectors as schoolSelectors
} from 'modules/schools'
import { fetchSections, selectors as sectionSelectors } from 'modules/sections'
import SectionsTable from './table'
import { debounce, isEqual } from 'lodash'
import {
  Box,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SchoolAutocomplete from '../shared/school-autocomplete'

class Sections extends React.Component {
  static propTypes = {
    fetchSections: PropTypes.func.isRequired,
    schoolId: PropTypes.number,
    schools: PropTypes.object,
    schoolName: PropTypes.string,
    schoolOptions: PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.number })
    ),
    schoolChanged: PropTypes.func,
    sections: PropTypes.array
  }

  state = {
    search: '',
    isLoading: false
  }

  componentDidMount() {
    this.handleFetchSections()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.schoolId, this.props.schoolId)) {
      this.handleFetchSections()
    }
  }

  handleFetchSections = debounce(
    attrs => {
      const { fetchSections, schoolId } = this.props
      this.setState({ isLoading: true })
      const params = {
        include_school_assignment_scores: true,
        include_percent_passing: true,
        include_students: true,
        school_id: schoolId,
        search: this.state.search,
        ...attrs
      }
      fetchSections(schoolId, params).then(_ =>
        this.setState({ isLoading: false })
      )
    },
    300,
    { leading: false, trailing: true }
  )

  searchChanged = value => {
    this.setState({ search: value }, () => {
      this.handleFetchSections({ page: 1 })
    })
  }

  render() {
    const { isLoading } = this.state
    const { schoolId, schools, schoolOptions, sections } = this.props
    const school = schools[schoolId]

    return (
      <Page name="Sections" title="Sections">
        <PageContainer>
          <Box>
            {schoolOptions.length > 1 && (
              <Stack direction="row" justifyContent="flex-end">
                <Box
                  id="test-school-selector"
                  sx={{
                    minWidth: '20rem'
                  }}
                >
                  <SchoolAutocomplete
                    disableClearable
                    includeAllSchoolsOption
                  />
                </Box>
              </Stack>
            )}
          </Box>

          <Box
            sx={{
              mb: 6
            }}
          >
            <Typography variant="h1">Sections</Typography>
            <Typography variant="h2">
              {school?.name || 'All Schools'}
            </Typography>
          </Box>

          <div>
            <FilterContainer>
              <form>
                <FormControl fullWidth sx={{ my: 2 }}>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    Search
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    label="Search"
                    onChange={e => this.searchChanged(e.target.value)}
                  />
                </FormControl>
              </form>
            </FilterContainer>

            <SectionsTable
              loading={isLoading}
              fetchData={this.handleFetchSections}
              data={sections}
            />
          </div>
        </PageContainer>
      </Page>
    )
  }
}

const mapStateToProps = state => {
  return {
    schoolId: state.schools.schoolId,
    schools: state.schools.byId,
    schoolOptions: schoolSelectors.schoolOptions(state, {
      includeAllSchoolsOption: true
    }),
    sections: sectionSelectors.sectionsForCurrentSchool(state),
    pages: state.sections.pages
  }
}

const mapDispatchToProps = {
  fetchSections,
  schoolChanged: schoolActions.selectSchool
}

export default connect(mapStateToProps, mapDispatchToProps)(Sections)
