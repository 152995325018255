import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem, Typography } from '@mui/material'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLifeRing, faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { destroySupport } from 'api/wellness'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import SupportModal from './modal'
import { theme } from 'components/app/theme'

function TimelineRow({ support, concern }) {
  const queryClient = useQueryClient()
  const user = useSelector(state => state.currentUser.user)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [editedSupport, setEditedSupport] = useState(null)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (editedSupport) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [editedSupport])

  const handleClose = () => {
    setEditedSupport(null)
    setAnchorEl(null)
  }

  const deleteSupportMutation = useMutation(
    ({ supportId }) => destroySupport(supportId),
    {
      onSuccess: () => {
        handleClose()
        queryClient.invalidateQueries(['concern', concern.id])
      }
    }
  )

  const disabled = concern.closed || concern.is_resolved || concern.is_archived

  const determineBackgroundColor = () => {
    if (disabled) {
      return theme.palette.primary.disabledLight
    } else {
      return theme.palette.primary.contrastText
    }
  }

  const determineColor = () => {
    if (disabled) {
      return theme.palette.primary.disabledDark
    } else {
      return theme.palette.primary.main
    }
  }

  return (
    <React.Fragment>
      <TimelineItem style={{ paddingRight: 0 }}>
        <TimelineOppositeContent
          style={{ flex: 0, padding: 0 }}
        ></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
            <FontAwesomeIcon icon={faLifeRing} style={{ color: '#fff7eb' }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{ paddingRight: 0 }}>
          <Typography variant="h4">{support.intervention_type_name}</Typography>
          <Typography style={{ maxWidth: '35em' }}>
            {support.content}
          </Typography>
          <Typography>
            {user.id === support.user_id && (
              <React.Fragment>
                <Button
                  id={`menu-${support.id}`}
                  aria-controls={anchorEl ? `menu-${support.id}` : false}
                  aria-haspopup="true"
                  aria-expanded={anchorEl ? 'true' : false}
                  onClick={e => setAnchorEl(e.currentTarget)}
                  style={{
                    backgroundColor: determineBackgroundColor(),
                    color: determineColor()
                  }}
                  disabled={disabled}
                >
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Button>
                <Menu
                  color="primary"
                  aria-labelledby={`menu-${support.id}`}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null)
                      setEditedSupport(support)
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      deleteSupportMutation.mutate({
                        supportId: support.id
                      })
                    }
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {moment(support.date).format('MM/DD/YYYY')} by {support.author_name}
          </Typography>
        </TimelineContent>
      </TimelineItem>
      {editedSupport && (
        <SupportModal
          concern={concern}
          setShow={setShow}
          show={show}
          support={editedSupport}
        />
      )}
    </React.Fragment>
  )
}

TimelineRow.propTypes = {
  support: PropTypes.object.isRequired,
  concern: PropTypes.object.isRequired
}

export default TimelineRow
