import React, { useState } from 'react'
import { alpha } from '@mui/material/styles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import { useSearch } from 'hooks/useSearch'
import DialogContent from '@mui/material/DialogContent'
import InputAdornment from '@mui/material/InputAdornment'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material'
import TeacherListItem from './list-items/teacher'
import StudentListItem from './list-items/student'
import { useHistory } from 'react-router-dom'
import SharedDialog from 'components/shared/dialog'
import { useDebounce } from 'hooks/useDebounce'
import { grey } from '@mui/material/colors'

const Dialog = styled(SharedDialog)(() => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start'
  }
}))

const SearchButton = styled(Button)(({ theme }) => ({
  'color': grey[300],
  'backgroundColor': alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    borderColor: alpha(theme.palette.common.white, 0.15)
  },
  'justifyContent': 'left',
  'border': `1px solid ${alpha(grey[300], 0.15)}`,
  'maxWidth': '20rem',
  'width': '100%'
}))

const ListItemComponentMap = {
  Teacher: TeacherListItem,
  Student: StudentListItem
}

export default function UniversalSearch() {
  const history = useHistory()

  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [results, setResults] = useState(null)
  const debouncedSearch = useDebounce(search, 500)

  const options = { enabled: open && debouncedSearch.length > 0 }
  const params = { search: debouncedSearch }
  const { isLoading, data } = useSearch({ params, options })

  const searching = search && isLoading
  const noResults = (results || []).length === 0
  const resultsAndComponent = (results || [])
    .map(result => [result, ListItemComponentMap[result.source_record_type]])
    .filter(([_result, ListItemComponent]) => Boolean(ListItemComponent))

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setSearch('')
    setResults(null)
  }

  const handleClick =
    ({ source_record_type, source_record_id }) =>
    _event => {
      switch (source_record_type) {
        case 'Teacher':
          history.push(`/teachers/${source_record_id}`)
          break
        case 'Student':
          history.push(`/student/${source_record_id}/overview`)
          break
      }

      handleClose()
    }

  React.useEffect(() => {
    if (!search.length) {
      setResults(null)
    } else if (data) {
      setResults(data)
    }
  }, [search, data])

  return (
    <React.Fragment>
      <SearchButton
        variant="outlined"
        size="small"
        onClick={handleOpen}
        startIcon={<SearchIcon />}
      >
        Search…
      </SearchButton>
      <Dialog
        open={open}
        fullWidth
        onClose={handleClose}
        title={
          <Box>
            <TextField
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              autoFocus
              margin="dense"
              id="name"
              hiddenLabel
              size={'small'}
              placeholder={'Search…'}
              value={search}
              onChange={e => setSearch(e.target.value)}
              fullWidth
              variant="filled"
              sx={{ my: 0 }}
            />
            {searching ? (
              <LinearProgress />
            ) : (
              <LinearProgress variant={'determinate'} value={100} />
            )}
          </Box>
        }
      >
        <DialogContent>
          <List dense={true}>
            {noResults ? (
              <ListItem>
                <ListItemText
                  primary={
                    results
                      ? 'No results found'
                      : search
                      ? 'Searching…'
                      : 'Type to search…'
                  }
                />
              </ListItem>
            ) : (
              resultsAndComponent.map(([r, ListItemComponent], i) => (
                <ListItemComponent
                  key={i}
                  searchResult={r}
                  onClick={handleClick(r)}
                />
              ))
            )}
          </List>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
