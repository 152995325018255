import React from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from '@mui/material'
import ConfirmModal from './modal'

export default class ConfirmModalButton extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    confirmWithText: PropTypes.string,
    confirmTitle: PropTypes.string,
    modalMessage: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    showCancelButton: PropTypes.bool,
    buttonVariant: PropTypes.string,
    tooltipText: PropTypes.string
  }

  state = {
    modalOpen: false
  }

  handleModalHide = () => {
    this.setState({ modalOpen: false })
  }

  openModal = () => this.setState({ modalOpen: true })

  handleOnConfirm = () => {
    const { onConfirm } = this.props
    this.handleModalHide()
    onConfirm()
  }

  render() {
    const {
      children,
      cancelButtonText,
      confirmButtonText,
      confirmWithText,
      confirmTitle,
      modalMessage,
      showCancelButton,
      onConfirm: _onConfirm,
      buttonVariant,
      tooltipText,
      ...buttonProps
    } = this.props

    const { modalOpen } = this.state

    return (
      <React.Fragment>
        <ConfirmModal
          cancelButtonText={cancelButtonText}
          confirmButtonText={confirmButtonText}
          confirmWithText={confirmWithText}
          message={modalMessage}
          onHide={this.handleModalHide}
          onConfirm={this.handleOnConfirm}
          show={modalOpen}
          showCancelButton={showCancelButton}
          title={confirmTitle}
        />
        <Tooltip title={tooltipText || ''} arrow placement="top">
          <Button
            onClick={this.openModal}
            variant={buttonVariant || 'contained'}
            {...buttonProps}
          >
            {children}
          </Button>
        </Tooltip>
      </React.Fragment>
    )
  }
}
