import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'lodash'
import { Redirect, Switch, Route } from 'react-router-dom'
import { actions as schoolActions, selectors } from 'modules/schools'
import { routerPropTypes } from 'utils/prop-types'
import { get, post, patch, destroy } from 'utils/ajax'
import { gradeLevelSort } from 'utils/sort'
import { alert } from 'utils/alert'
import Page from 'components/shared/page'
import Activities from './activities'
import FindASchool from './find-a-school'
import SectionTable from './section-table'
import SeatsTable from './seats-table'
import Form from './form'
import InitialVisit from './initial-visit'
import {
  Box,
  Stack,
  Switch as MuiSwitch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { Tabs as SharedTabs } from 'components/shared/tabs'

const makeTabs = ({ baseUrl, schoolId }) => {
  return [
    { label: 'Grade Level Sections', to: `${baseUrl}/grade-level-sections` },
    { label: 'Find A School', to: `${baseUrl}/find-a-school` },
    { label: 'Activities', to: `${baseUrl}/activites` },
    { label: 'Emails', to: `${baseUrl}/emails`, access: () => schoolId == 720 }
  ].filter(tab => !tab.access || tab.access())
}

function Tabs({ baseUrl, schoolId }) {
  const tabs = React.useMemo(
    () => makeTabs({ baseUrl, schoolId }),
    [baseUrl, schoolId]
  )

  return <SharedTabs tabs={tabs} />
}

Tabs.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  schoolId: PropTypes.number.isRequired
}

class SchoolSettings extends React.Component {
  static propTypes = {
    ...routerPropTypes
  }

  state = {
    isLoading: false,
    pages: null,
    sections: [],
    teachers: [],
    teacherSections: []
  }

  componentDidMount() {
    this.fetchSections()
    this.fetchTeachers()
    this.fetchTeacherSections()
  }

  schoolChanged = val => {
    if (val && val.value) {
      this.props.selectSchool(val.value)
    }
  }

  fetchSections = debounce(
    attrs => {
      this.setState({ isLoading: true })

      const params = {
        school_id: this.props.schoolId,
        grade_level: true,
        ...attrs
      }

      return get('/api/web/sections', { params })
        .then(data => {
          this.setState({
            sections: gradeLevelSort(data.data),
            pages: data.meta.pages,
            isLoading: false
          })
          return data
        })
        .catch(_err => {
          alert('An error occurred fetching sections data', 'warning')
          this.setState({ isLoading: false })
        })
    },
    300,
    { leading: false, trailing: true }
  )

  fetchTeachers = debounce(
    attrs => {
      this.setState({ isLoading: true })

      const params = {
        school_id: this.props.schoolId,
        search: this.state.search,
        per_page: 1000,
        ...attrs
      }

      return get('/api/web/teachers', { params })
        .then(data => {
          this.setState({
            teachers: data.data,
            isLoading: false
          })
          return data
        })
        .catch(_err => {
          alert('An error occurred fetching teacher data', 'warning')
          this.setState({ isLoading: false })
        })
    },
    300,
    { leading: false, trailing: true }
  )

  fetchTeacherSections = debounce(
    attrs => {
      this.setState({ isLoading: true })

      const params = {
        school_id: this.props.schoolId,
        grade_level: true,
        ...attrs
      }

      return get('/api/web/teacher_sections', { params })
        .then(data => {
          this.setState({
            teacherSections: data,
            isLoading: false
          })
          return data
        })
        .catch(_err => {
          alert('An error occurred fetching data', 'warning')
          this.setState({ isLoading: false })
        })
    },
    300,
    { leading: false, trailing: true }
  )

  createTeacherSection = (selectedTeacherId, selectedSectionId) => {
    post('/api/web/teacher_sections', {
      teacher_section: {
        teacher_id: selectedTeacherId,
        section_id: selectedSectionId
      }
    })
      .catch(resp => {
        let alertMessage = 'There was an error granting access. '
        if (resp.data.message) {
          alertMessage += resp.data.message
        }
        alert(alertMessage, 'danger')
      })
      .then(() => {
        this.fetchTeacherSections()
        this.fetchSections()
      })
  }

  handleDestroy = id => {
    destroy(`/api/web/teacher_sections/${id}`, {})
      .catch(() => {
        alert('There was an error removing access.', 'danger')
      })
      .then(() => {
        this.fetchTeacherSections()
        this.fetchSections()
      })
  }

  toggleAttendanceEmails = toggleValue => {
    const { school } = this.props
    patch(`/api/web/schools/${school.id}`, {
      school: {
        is_receiving_attendance_email: toggleValue
      }
    })
  }

  render() {
    const { school, schoolId, hasAdminAccess } = this.props
    const { isLoading, sections, teachers, teacherSections } = this.state
    const baseUrl = `/schools/${schoolId}/settings`

    return (
      <Page name="School Settings" title={`${school.name} - Settings`}>
        <Stack spacing={2}>
          <InitialVisit />

          <Typography variant="h3">School Settings</Typography>

          <Tabs {...{ baseUrl, schoolId }} />

          <Switch>
            <Redirect
              from={baseUrl}
              exact={true}
              to={`${baseUrl}/grade-level-sections`}
            />

            <Route path={`${baseUrl}/grade-level-sections`}>
              <Box>
                <Box>
                  <Form
                    onSubmit={this.createTeacherSection}
                    teachers={teachers}
                    sections={sections}
                  />
                </Box>
                <Box>
                  <SeatsTable sections={sections} />
                </Box>
                <Box>
                  <SectionTable
                    loading={isLoading}
                    data={teacherSections}
                    removeAccess={this.handleDestroy}
                  />
                </Box>
              </Box>
            </Route>

            <Route path={`${baseUrl}/find-a-school`}>
              <Box>
                <Box>
                  <FindASchool
                    school={school}
                    schoolId={schoolId}
                    hasAdminAccess={hasAdminAccess}
                  />
                </Box>
              </Box>
            </Route>

            <Route path={`${baseUrl}/activites`}>
              <Box>
                <Box>
                  <Activities schoolId={schoolId} />
                </Box>
              </Box>
            </Route>

            {school.id == 720 && (
              <Route path={`${baseUrl}/emails`}>
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>3-6-9 Attendance Initiative</TableCell>
                        <TableCell>
                          Once enabled, teachers with 1st hour classes will
                          receive an email containing student names and
                          parent/guardian contact information for all students
                          that have reached 3, 6, or 9 absences for the school
                          year. Teachers will have the ability to document all
                          phone calls made to parents by clicking the Follow Up
                          button next to each student name in the email. This
                          will allow us to gauge the impact of consistent
                          communication with parents at various points of the
                          year as we receive assessment results and grades.
                        </TableCell>
                        <TableCell>
                          <MuiSwitch
                            name="is_receiving_attendance_email"
                            id="is_receiving_attendance_email"
                            onChange={event =>
                              this.toggleAttendanceEmails(event.target.checked)
                            }
                            defaultChecked={
                              school.is_receiving_attendance_email
                            }
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Route>
            )}
          </Switch>
        </Stack>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  schoolId: state.schools.schoolId,
  school: selectors.currentSchool(state),
  schools: selectors.schoolOptions(state),
  hasAdminAccess: state.currentUser.user.rights.has_admin_access
})

const mapDispatchToProps = {
  selectSchool: schoolActions.selectSchool
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolSettings)
