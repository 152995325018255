import React from 'react'
import PropTypes from 'prop-types'
import { some, startCase } from 'lodash'
import { fetchAttendeesForSchool } from 'api/attendees'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import AttendeeForm from './attendee-form'
import {
  Alert,
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Typography
} from '@mui/material'

class AttendeeList extends React.Component {
  static propTypes = {
    potentialAttendeeList: PropTypes.array.isRequired,
    childStudyId: PropTypes.number.isRequired,
    schoolId: PropTypes.number.isRequired,
    addAttendee: PropTypes.func.isRequired,
    removeAttendee: PropTypes.func.isRequired
  }

  state = {
    attendeeOptions: [],
    suggestedAttendees: []
  }

  componentDidMount() {
    this.fetchAttendees(this.props.schoolId)
  }

  fetchAttendees = schoolId => {
    fetchAttendeesForSchool(schoolId).then(resp => {
      this.setState({
        attendeeOptions: resp.attendee_users,
        suggestedAttendees: resp.suggested_attendees
      })
    })
  }

  addSuggestedAttendee = userIdToAdd => {
    const suggested = this.state.suggestedAttendees
    const newAttendee = suggested.find(
      attendee => attendee.user_id === userIdToAdd
    )
    this.props.addAttendee(newAttendee)
  }

  removeAttendee = userIdToRemove => {
    this.props.removeAttendee(userIdToRemove)
  }

  removeAttendeeIcon = attendee => {
    return (
      <RemoveCircleIcon
        color="warning"
        onClick={() => this.removeAttendee(attendee.user_id)}
      />
    )
  }

  addAttendeeIcon = attendee => {
    if (this.isAttending(attendee.user_id)) {
      return <CheckCircleIcon color="success" />
    } else {
      return (
        <AddCircleIcon
          color="info"
          onClick={() => this.addSuggestedAttendee(attendee.user_id)}
        />
      )
    }
  }

  attendeesList(attendees, actionIcon) {
    return (
      <List>
        {attendees.map((attendee, i) => (
          <ListItem key={i}>
            <ListItemText
              primary={`${attendee.name} - ${startCase(attendee.role)}`}
            />
            <ListItemSecondaryAction>
              {actionIcon(attendee)}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    )
  }

  isAttending(attendeeId) {
    const { potentialAttendeeList } = this.props
    return some(potentialAttendeeList, ['user_id', attendeeId])
  }

  filteredAttendeeOptions() {
    const { attendeeOptions } = this.state
    return attendeeOptions.filter(attendee => !this.isAttending(attendee.id))
  }

  render() {
    const { potentialAttendeeList, addAttendee } = this.props
    const { suggestedAttendees } = this.state

    return (
      <Stack spacing={2}>
        <Box>
          <Typography variant="h4">Suggested Attendees</Typography>
          <Box>
            {suggestedAttendees.length > 0
              ? this.attendeesList(suggestedAttendees, this.addAttendeeIcon)
              : null}
          </Box>
        </Box>

        <Box>
          <AttendeeForm
            filteredAttendeeOptions={this.filteredAttendeeOptions()}
            addAttendeeFromForm={addAttendee}
          />
        </Box>

        <Box>
          <Typography variant="h4">Current Attendees</Typography>
          <Box>
            {potentialAttendeeList.length > 0 ? (
              this.attendeesList(potentialAttendeeList, this.removeAttendeeIcon)
            ) : (
              <Alert severity="error">
                No one is attending! Add attendees above.
              </Alert>
            )}
          </Box>
        </Box>
      </Stack>
    )
  }
}

export default AttendeeList
