import { get, post, patch, destroy } from '@rails/request.js'

const destroySlipForm = async id => await destroy(`/slip_forms/${id}`)

const getSlipForms = async params =>
  await get('/slip_forms', {
    query: params,
    responseKind: 'json'
  })

const getSlipForm = async params => await get(`/slip_forms/${params.id}`)

const getSlipFormDocuments = async id =>
  await get(`/slip_forms/${id}/documents`)

const getPrefilledSlipForm = async params =>
  await get('/slip_forms/new', {
    query: params.student_id ? params : {}
  })

const postSlipForm = async params =>
  await post('/slip_forms', {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

const patchSlipForm = async (id, params) =>
  await patch(`/slip_forms/${id}`, {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

const getSlipFormReports = async params =>
  await get('/slip_forms/reports', {
    query: params,
    responseKind: 'json'
  })

export {
  destroySlipForm,
  getSlipForm,
  getSlipForms,
  getPrefilledSlipForm,
  getSlipFormDocuments,
  postSlipForm,
  patchSlipForm,
  getSlipFormReports
}
