import Page from 'components/shared/page'
import React from 'react'
import DocumentsManager from 'components/shared/documents-manager'
import { useParams } from 'react-router-dom'
import PageContainer from 'shared/page-container'

function StudentDocuments() {
  const { id } = useParams()
  return (
    <Page name="Student's Documents" title="Student's Documents">
      <PageContainer>
        <DocumentsManager
          associations={{
            documentable_type: 'Student',
            documentable_id: id
          }}
        />
      </PageContainer>
    </Page>
  )
}

export default StudentDocuments
