import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import SeverityForm from './form'
import { titleize } from 'utils'

function Severity({ disabled, concern }) {
  const [showSeverityForm, setShowSeverityForm] = useState(false)
  const determineColor = () => {
    switch (concern.severity) {
      case 'low':
        return 'default'
      case 'medium':
        return 'warning'
      case 'high':
        return 'error'
    }
  }

  return (
    <React.Fragment>
      <Chip
        clickable={!disabled}
        disabled={disabled}
        color={determineColor()}
        label={titleize(concern.severity)}
        onClick={() => setShowSeverityForm(true)}
        style={{ fontSize: '1rem' }}
      />
      <SeverityForm
        concern={concern}
        setShow={setShowSeverityForm}
        show={showSeverityForm}
      />
    </React.Fragment>
  )
}

Severity.propTypes = {
  disabled: PropTypes.bool.isRequired,
  concern: PropTypes.object.isRequired
}

export default Severity
