import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'
import StatusForm from './form'
import { useTheme } from '@mui/styles'

function Status({ clickable, parent, queryName, greyScale = false, queryKey }) {
  const [showStatusForm, setShowStatusForm] = useState(false)
  const theme = useTheme()
  const isResolved = parent.is_resolved
  const isClosed = parent.closed

  const determineLabel = () => {
    if (isResolved) {
      return 'Resolved'
    } else if (isClosed) {
      return 'Closed'
    } else {
      return 'Open'
    }
  }

  const determineColor = () => {
    if (greyScale) {
      return 'default'
    } else {
      if (isResolved) {
        return 'success'
      } else if (isClosed) {
        return 'default'
      } else {
        return 'secondary'
      }
    }
  }

  const determineTextColor = () => {
    if ((isClosed && !isResolved) || greyScale) {
      return theme.palette.primary.black
    } else {
      return theme.palette.primary.contrastText
    }
  }

  const determineBorder = () => {
    if (greyScale && (isResolved || isClosed)) {
      return 'none'
    } else {
      return 'solid'
    }
  }

  const determineBorderWidth = () => {
    if (greyScale && !isResolved && !isClosed) {
      return 2
    } else {
      return 0
    }
  }

  return (
    <React.Fragment>
      <Chip
        clickable={clickable}
        color={determineColor()}
        label={determineLabel()}
        onClick={() => setShowStatusForm(true)}
        style={{
          fontSize: '1rem',
          color: determineTextColor(),
          borderWidth: determineBorderWidth(),
          borderColor: theme.palette.primary.disabledDark,
          borderStyle: determineBorder(),
          cursor: 'pointer'
        }}
      />
      <StatusForm
        isClosed={isClosed}
        isResolved={isResolved}
        parent={parent}
        queryName={queryName}
        setShow={setShowStatusForm}
        show={showStatusForm}
        queryKey={queryKey}
      />
    </React.Fragment>
  )
}

Status.propTypes = {
  clickable: PropTypes.bool.isRequired,
  parent: PropTypes.object.isRequired,
  queryName: PropTypes.string.isRequired,
  greyScale: PropTypes.bool,
  queryKey: PropTypes.array
}

export default Status
