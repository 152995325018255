import { getReports, destroyReport } from 'api/reports'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export function useReports({ params, options }) {
  const queryKey = ['reports', params]
  const queryFn = () => getReports({ params })
  const query = useQuery(queryKey, queryFn, {
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}

export function useDestroyReport(id) {
  return useMutation(() => {
    return destroyReport(id)
  })
}
