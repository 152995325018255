import React from 'react'
import { isNull } from 'lodash'
import TpsLogo from '../../../assets/images/TPS_RGB_SM.png'
import PsLogo from '../../../assets/images/PS_logo.png'
import TulsaWay from '../../../assets/images/tulsa_way.png'
import OfficeLogo from '../../../assets/images/office_365.png'
import XelloLogo from '../../../assets/images/xello.png'
import CleverLogo from '../../../assets/images/clever.png'
import CanvasLogo from '../../../assets/images/canvas.png'
import EdPlanLogo from '../../../assets/images/edplan.png'
import LaliloLogo from '../../../assets/images/lalilo.png'
import PanoramaLogo from '../../../assets/images/panorama.png'
import ApplicationLink from './application-link'
import { Menu, IconButton } from '@mui/material'
import Apps from '@mui/icons-material/Apps'
import { useSelector } from 'react-redux'

function OtherApplications() {
  const user = useSelector(state => state.currentUser.user)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    if (isNull(anchorEl)) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const powerSchoolUrl = `https://powerschool.tulsaschools.org/${
    user?.role === 'teacher' ? 'teachers' : 'admin'
  }/`

  return (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="show 4 new mails"
        color="inherit"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Apps />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <ApplicationLink
          icon={TpsLogo}
          title={'TPS Homepage'}
          href={'https://www.tulsaschools.org'}
        />

        <ApplicationLink
          icon={PsLogo}
          title={'PowerSchool'}
          href={powerSchoolUrl}
        />

        <ApplicationLink
          icon={TulsaWay}
          title={'TulsaWay'}
          href={
            'https://web.powerapps.com/webplayer/app?appId=%2fproviders%2fMicrosoft.PowerApps%2fapps%2f28d6564a-47e8-475d-b6b0-c36e4f67e997'
          }
        />

        <ApplicationLink
          icon={OfficeLogo}
          title={'Office 365'}
          href={'https://www.office.com'}
        />

        <ApplicationLink
          icon={CleverLogo}
          title={'Clever'}
          href={'https://clever.com/oauth/district-picker'}
        />

        <ApplicationLink
          icon={CanvasLogo}
          title={'Canvas'}
          href={'https://tulsaps.instructure.com/login/saml/8'}
        />

        <ApplicationLink
          icon={EdPlanLogo}
          title={'EdPlan'}
          href={'https://ok.pcgeducation.com/oktulsa'}
        />

        <ApplicationLink
          icon={LaliloLogo}
          title={'Lalilo'}
          href={'https://app.lalilo.com/#/login?type=TEACHER'}
        />

        <ApplicationLink
          icon={PanoramaLogo}
          title={'Panorama'}
          href={'https://secure.panoramaed.com/login'}
        />

        <ApplicationLink
          icon={XelloLogo}
          title={'Xello'}
          href={'https://login.xello.world/'}
        />
      </Menu>
    </React.Fragment>
  )
}

export default OtherApplications
