import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tab, Tabs as MuiTabs } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'

export const tabShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  access: PropTypes.func
})

export const indexOfTab = (tabs, to) => {
  const pathnames = tabs.map(tab => tab.to.replace(/[?#].*$/, ''))
  let tabIndex = pathnames.indexOf(to)

  // cheap hack to match parent tabs
  if (tabIndex === -1) {
    tabIndex = pathnames
      .map(pathname => (to.slice(0, pathname.length) == pathname ? to : ''))
      .indexOf(to)
  }

  // fallback to first tab
  return Math.max(tabIndex, 0)
}

export function Tabs({ tabs }) {
  const location = useLocation()
  const [tabIndex, setTabIndex] = React.useState(() => {
    const index = indexOfTab(tabs, location.pathname)
    return Math.max(index, 0) || 0
  })
  const accessibleTabs = React.useMemo(
    () => tabs.filter(tab => !tab.access || tab.access()),
    [tabs]
  )

  const handleChange = (_, newTabIndex) => {
    setTabIndex(newTabIndex)
  }

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <MuiTabs
        value={tabIndex}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {accessibleTabs.map(tab => (
          <Tab key={tab.label} component={Link} label={tab.label} to={tab.to} />
        ))}
      </MuiTabs>
    </Box>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(tabShape)
}

export default Tabs
