import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import RootCauseDialog from './dialog'

function RootCause({ concern }) {
  const [showRootCause, setShowRootCause] = useState(false)

  const disabled = concern.closed || concern.is_resolved || concern.is_archived

  return (
    <React.Fragment>
      {concern.parent_concern_type_name === 'Attendance' && (
        <Button
          onClick={() => setShowRootCause(true)}
          variant="contained"
          color="primary"
          size="medium"
          disabled={disabled}
          style={{ marginBottom: '10px' }}
        >
          Add Root Cause
        </Button>
      )}
      <RootCauseDialog
        concern={concern}
        setShow={setShowRootCause}
        show={showRootCause}
      />
    </React.Fragment>
  )
}

RootCause.propTypes = {
  concern: PropTypes.object
}

export default RootCause
