import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Autocomplete,
  Button,
  DialogContent,
  IconButton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Stack,
  Switch,
  Typography,
  Box,
  Table
} from '@mui/material'
import Dialog from 'components/shared/dialog'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createConfidentialCrisisTeamMembership,
  destroyConfidentialCrisisTeamMembership,
  getConfidentialCrisisTeamMemberships,
  updateConfidentialCrisisTeamMembership,
  userSearchConfidentialCrisisTeamMembership
} from 'api/confidential-crisis-team-memberships'
import { debounce } from 'lodash'

export function ManageTeam({ userId }) {
  const queryClient = useQueryClient()

  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])

  const [userSearch, setUserSearch] = useState('')
  const handleChangeUserSearch = value => setUserSearch(value)
  const debouncedChangeUserSearch = useCallback(
    debounce(handleChangeUserSearch, 300),
    []
  )

  const { data: users } = useQuery(
    ['confidential-crisis-team-memberships', 'user-search', userSearch],
    () => userSearchConfidentialCrisisTeamMembership({ search: userSearch }),
    {
      enabled: userSearch.length > 3,
      initialData: []
    }
  )

  const { data: memberships } = useQuery(
    ['confidential-crisis-team-memberships'],
    () => getConfidentialCrisisTeamMemberships(),
    { initialData: [] }
  )
  const invalidateConfidentialCrisisTeamMemberships = () => {
    queryClient.invalidateQueries(['confidential-crisis-team-memberships'])
  }

  const handleChange = (id, params) => {
    updateConfidentialCrisisTeamMembership(id, params).then(() => {
      invalidateConfidentialCrisisTeamMemberships()
    })
  }

  const handleCreate = () => {
    const promises = selectedOptions.map(({ id }) =>
      createConfidentialCrisisTeamMembership({ user_id: id })
    )

    Promise.all(promises).then(() => {
      setSelectedOptions([])
      invalidateConfidentialCrisisTeamMemberships()
    })
  }

  const handleDelete = id => {
    destroyConfidentialCrisisTeamMembership(id).then(() => {
      invalidateConfidentialCrisisTeamMemberships()
    })
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setDialogOpen(true)}
        sx={{ mr: 2 }}
      >
        Manage Team
      </Button>

      <Dialog
        title={'Manage Confidential Crisis Team Memberships'}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="manage-confidential-crisis-team-memberships"
      >
        <DialogContent>
          <Stack>
            <Typography>
              District personnel who need access to ALL confidential crisis
              forms.
            </Typography>
            <TableContainer component={Paper}>
              <Table id={'confidential-crisis-team-table'}>
                <TableHead>
                  <TableRow>
                    <TableCell width={'100%'}>Name</TableCell>
                    <TableCell>Admin</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {memberships.map(membership => (
                    <TableRow key={membership.id}>
                      <TableCell scope="row">{membership.user.name}</TableCell>
                      <TableCell align={'center'}>
                        <Switch
                          disabled={membership.user.id === userId}
                          size={'small'}
                          checked={membership.is_admin}
                          onChange={() =>
                            handleChange(membership.id, {
                              is_admin: !membership.is_admin
                            })
                          }
                          inputProps={{ 'aria-label': 'admin checkbox' }}
                        />
                      </TableCell>
                      <TableCell align={'right'} sx={{ whiteSpace: 'nowrap' }}>
                        {/* only allow manually created users to be deleted */}
                        {membership.created_by_user_id && (
                          <IconButton
                            disabled={membership.user.id === userId} // cannot delete oneself
                            onClick={() => handleDelete(membership.id)}
                            aria-label="delete"
                            title={'delete'}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Stack direction={'row'}>
              <Autocomplete
                multiple
                fullWidth
                filterOptions={x => x}
                options={users}
                getOptionLabel={option => `${option.name} <${option.email}>`}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps
                    }}
                    label="Enter employee’s name or email address"
                    onChange={event =>
                      debouncedChangeUserSearch(event.target.value)
                    }
                  />
                )}
                renderOption={(props, option, _state, _ownerState) => (
                  <Box component="li" {...props}>
                    <Stack
                      direction={'row'}
                      justifyContent={'space-between'}
                      sx={{ width: '100%' }}
                    >
                      <Box>{`${option.name} <${option.email}>`}</Box>
                      <Box>{`${option.role}`}</Box>
                    </Stack>
                  </Box>
                )}
                value={selectedOptions}
                onChange={(_event, value, _reason) => {
                  setUserSearch('')
                  setSelectedOptions(value)
                }}
              />
              <Button
                variant="contained"
                onClick={handleCreate}
                disabled={!selectedOptions.length}
                sx={{ whiteSpace: 'nowrap' }}
              >
                Add to Team
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}

ManageTeam.propTypes = {
  userId: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  userId: state.currentUser.user.id
})

export default connect(mapStateToProps)(ManageTeam)
