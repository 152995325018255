import React, { useState } from 'react'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import ConcernDialog from 'components/shared/concerns/dialog'
import { getConcern } from 'api/interventions'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { toInteger } from 'lodash'
import TargetedInterventions from './interventions/index'
import TimelineActions from './timeline/actions'
import ConcernLogTimeline from './timeline/index'
import ConcernHeader from './header'
import { useParams } from 'react-router'
import DocumentsManager from 'components/shared/documents-manager'
import { Stack, Typography } from '@mui/material'

function Concern() {
  const queryClient = useQueryClient()
  const params = useParams()
  const concernId = toInteger(params.id)
  const [showEditModal, setShowEditModal] = useState(false)

  const { data: concern } = useQuery(
    ['concern', concernId],
    () => getConcern(concernId),
    {
      onSuccess: data => {
        document.title = `${data.student_name} - Concern`
      },
      enabled: Boolean(concernId),
      refetchOnWindowFocus: false
    }
  )

  const hasInterventions = concern?.interventions?.length ? true : false

  return (
    <Page name="Show Concern" title="Wellness Concern">
      <PageContainer>
        {concern && (
          <Stack spacing={4}>
            <Typography variant="h1">Concern</Typography>

            <TimelineActions concern={concern} />

            <ConcernHeader
              concern={concern}
              setShowEditModal={setShowEditModal}
            />

            <TargetedInterventions concern={concern} />

            <Typography variant="h2">Concern Logs</Typography>
            <ConcernLogTimeline concern={concern} />

            <DocumentsManager
              associations={{
                documentable_id: concern.id,
                documentable_type: 'Concern'
              }}
              disabled={concern.closed}
            />
          </Stack>
        )}

        {concern && !concern.closed && (
          <ConcernDialog
            show={showEditModal}
            onHide={({ action }) => {
              setShowEditModal(false)
              if (action === 'update') {
                queryClient.invalidateQueries(['concern', concern.id])
              }
            }}
            studentId={concern.student_id}
            concern={concern}
            hasInterventions={hasInterventions}
          />
        )}
      </PageContainer>
    </Page>
  )
}

export default Concern
