import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import { Stack } from '@mui/material'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { TableViewDropdown, useColumnSets } from './column-sets'
import FilterSet from './filter-set'
import ColumnSet from './column-set'
import {
  TopDialog as Dialog,
  DialogContent,
  DialogActions
} from 'shared/dialog'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    fontSize: '1rem'
  }
}))

function StudentsTableFilters({
  userId,
  search,
  searchChanged,
  filters,
  teacherPage,
  teacher,
  activeColumnSet,
  setActiveColumnSet
}) {
  const filterCount = Object.keys(filters).length

  // state
  const { data: columnSets } = useColumnSets({ userId })
  const [drawerName, setDrawerName] = useState(null)

  return (
    <>
      <Stack spacing={1}>
        <Stack direction={'row'} id="StudentsTableActionBar" spacing={2}>
          {Boolean(columnSets?.length) && setActiveColumnSet && (
            <TableViewDropdown
              {...{
                userId,
                activeColumnSet,
                setActiveColumnSet,
                customizeTable: () => setDrawerName('Customize Table')
              }}
            />
          )}

          <Box>
            <StyledBadge color="secondary" badgeContent={filterCount}>
              <Button
                startIcon={<FilterAltIcon />}
                variant={'contained'}
                onClick={() => {
                  setDrawerName('Student Filters')
                }}
              >
                Filters
              </Button>
            </StyledBadge>
          </Box>
        </Stack>

        <Box width="100%">
          <FormControl fullWidth>
            <TextField label="Search" value={search} onChange={searchChanged} />
          </FormControl>
        </Box>
      </Stack>

      <Dialog
        title={drawerName}
        open={Boolean(drawerName)}
        onClose={() => setDrawerName(null)}
        fullWidth={true}
        maxWidth={'lg'}
      >
        {drawerName == 'Student Filters' && (
          <DialogContent>
            <FilterSet {...{ teacherPage, teacher }} />
          </DialogContent>
        )}

        {['Customize Table', 'Create Table View', 'Update Table View'].includes(
          drawerName
        ) && (
          <ColumnSet
            {...{
              setDrawerName,
              activeColumnSet,
              setActiveColumnSet
            }}
          >
            {({ actions, content }) => (
              <>
                <DialogContent>{content}</DialogContent>
                {actions && <DialogActions>{actions}</DialogActions>}
              </>
            )}
          </ColumnSet>
        )}
      </Dialog>
    </>
  )
}

StudentsTableFilters.propTypes = {
  userId: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  search: PropTypes.string,
  searchChanged: PropTypes.func.isRequired,
  selectedStudentIds: PropTypes.array,
  filters: PropTypes.object,
  teacherPage: PropTypes.bool,
  teacher: PropTypes.object,
  cohorts: PropTypes.array,
  schoolId: PropTypes.number,
  columnSets: PropTypes.array,
  activeColumnSet: PropTypes.string,
  setActiveColumnSet: PropTypes.func,
  openCustomization: PropTypes.object
}

const mapStateToProps = state => ({
  userId: state.currentUser.user.id,
  filters: state.studentFilters.studentFilters,
  schoolId: state.schools.schoolId
})

export default connect(mapStateToProps)(StudentsTableFilters)
