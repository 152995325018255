import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'utils/ajax'
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip
} from '@mui/material'
import PageContainer from 'components/shared/page-container'
import InfoIcon from '@mui/icons-material/Info'
import CheckIcon from '@mui/icons-material/Check'
import RemoveIcon from '@mui/icons-material/Remove'

export default class Sat extends React.Component {
  static propTypes = {
    studentId: PropTypes.number.isRequired
  }

  state = {
    sat: null,
    testScoreCategories: [
      { label: 'Reading', value: 'reading' },
      { label: 'Writing and Language', value: 'writing' },
      { label: 'Math', value: 'math' },
      { label: 'Analysis in History/Social Studies', value: 'history' },
      { label: 'Analysis in Science', value: 'science' }
    ],
    readingSubscoreCategories: [
      { label: 'Words in Context', value: 'words_context' },
      { label: 'Command of Evidence', value: 'command_evidence' },
      { label: 'Expression of Ideas', value: 'expression_ideas' },
      { label: 'English Conventions', value: 'english_conventions' }
    ],
    mathSubscoreCategories: [
      { label: 'Heart of Algebra', value: 'heart_algebra' },
      { label: 'Advanced Math', value: 'advanced_math' },
      { label: 'Problem Solving', value: 'problem_solving' }
    ],
    subscoreGreenBands: {
      command_evidence: 11,
      words_context: 11,
      expression_ideas: 11,
      english_conventions: 11,
      heart_algebra: 12,
      problem_solving: 12,
      advanced_math: 12
    },
    subscoreYellowBands: {
      command_evidence: 7,
      words_context: 6,
      expression_ideas: 7,
      english_conventions: 7,
      heart_algebra: 7,
      problem_solving: 8,
      advanced_math: 8
    }
  }

  fetchSatData = () => {
    const { studentId } = this.props
    get(`/api/web/students/${studentId}`, {
      params: {
        include_sat: true
      }
    }).then(res => {
      this.setState({ sat: res.student.recent_sat })
    })
  }

  determineStatus = category => {
    const { sat, subscoreGreenBands, subscoreYellowBands } = this.state
    let attribute = sat[`${category}`]
    if (attribute >= subscoreGreenBands[category]) {
      return 'success'
    } else if (attribute >= subscoreYellowBands[category]) {
      return 'warning'
    } else {
      return 'error'
    }
  }

  componentDidMount() {
    this.fetchSatData()
  }

  render() {
    const {
      sat,
      testScoreCategories,
      readingSubscoreCategories,
      mathSubscoreCategories
    } = this.state

    return (
      <PageContainer>
        {sat && (
          <div className="row">
            <div className="col-sm-6">
              <div className="col-sm-12">
                <Box>
                  <h4>Composite Scaled Scores</h4>
                  <div className="row">
                    <div className="col-sm-4">
                      <Box>
                        <h4>
                          <Tooltip
                            id="total-sat"
                            label="
                              The student’s total score on the SAT® is the
                              sum of the Evidence-Based Reading and
                              Writing section score and the Math section
                              score. The total score is reported on a
                              400–1600 scale."
                          >
                            {'Total '}
                            <InfoIcon />
                          </Tooltip>
                        </h4>
                        <h3>
                          <span>{sat.composite}</span>
                          <br />
                          <small>
                            Percentile: <span>{sat.composite_percentile}</span>
                          </small>
                        </h3>
                      </Box>
                    </div>
                    <div className="col-sm-4">
                      <Box>
                        <h4>
                          <Tooltip
                            id="math-benchmark"
                            label="A green checkmark indicates the student met or exceeded the benchmark. A red X indicates the student failed to meet the benchmark."
                          >
                            {'Math '}
                            <InfoIcon />
                          </Tooltip>
                        </h4>
                        {sat.math_benchmark ? (
                          <CheckIcon color="success" />
                        ) : (
                          <RemoveIcon color="error" />
                        )}
                        <h3>
                          <span>{sat.math_composite}</span>
                          <br />
                          <small>
                            Percentile:{' '}
                            <span>{sat.math_composite_percentile}</span>
                          </small>
                        </h3>
                      </Box>
                    </div>
                    <div className="col-sm-4">
                      <Box>
                        <h4>
                          <Tooltip
                            id="english-benchmark"
                            label=" A green checkmark indicates the student met or exceeded the benchmark. A red X indicates the student failed to meet the benchmark."
                          >
                            {'Reading & Writing '}
                            <InfoIcon />
                          </Tooltip>
                        </h4>
                        {sat.english_benchmark ? (
                          <CheckIcon color="success" />
                        ) : (
                          <RemoveIcon color="error" />
                        )}
                        <h3>
                          <span>{sat.english_composite}</span>
                          <br />
                          <small>
                            Percentile:{' '}
                            <span>{sat.english_composite_percentile}</span>
                          </small>
                        </h3>
                      </Box>
                    </div>
                  </div>
                </Box>
              </div>
              <div className="col-sm-12">
                <Box>
                  <h4>Resources & Information</h4>
                  <div className="col-sm-6">
                    <h5>
                      <a
                        href="https://www.khanacademy.org/test-prep/sat/new-sat-tips-planning"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Khan Academy: Tips & Strategies
                      </a>
                    </h5>
                    <h5>
                      <a
                        href="https://www.khanacademy.org/test-prep/sat/full-length-sat-1/paper-sat-tests/a/full-length-sats-to-take-on-paper"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Khan Academy: Paper Practice Tests
                      </a>
                    </h5>
                  </div>
                  <div className="col-sm-6">
                    <h5>
                      <a
                        href="https://www.khanacademy.org/test-prep/sat/full-length-sat-1/online-sat-tests/a/full-length-sats-to-take-online"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Khan Academy: Online Practice Tests
                      </a>
                    </h5>
                    <h5>
                      <a
                        href="https://collegereadiness.collegeboard.org/pdf/educator-benchmark-brief.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        SAT: The College and Career Readiness Benchmarks Study
                      </a>
                    </h5>
                  </div>
                </Box>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="col-sm-12">
                <Box>
                  <h4>
                    <Tooltip
                      id="test-scores"
                      label="The student receives three test scores for Reading, Writing and Language, and Math and two cross-test scores for Analysis in History/Social Studies and Analysis in Science (each reported on a scale of 10–40)."
                    >
                      {'Test and Cross-Test Scores '}
                      <InfoIcon />
                    </Tooltip>
                  </h4>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Subject</TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell>Percentile</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {testScoreCategories.map(category => (
                        <TableRow key={category.value}>
                          <TableCell>{category.label}</TableCell>
                          <TableCell>{sat[`${category.value}`]}</TableCell>
                          <TableCell>
                            {sat[`${category.value}_percentile`]}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </div>
              <div className="col-sm-12">
                <Box>
                  <h4>
                    <Tooltip
                      id="test-scores"
                      label=" Subscores (each reported on a 1–15 scale) give more information about the student’s strengths as well as areas to focus on for improvement. Subscores fall within one of three score ranges:"
                    >
                      <ul>
                        <li>Green: On track for college readiness</li>
                        <li>
                          Yellow: Within 1 yr. of academic growth to being on
                          track for college readiness
                        </li>
                        <li>Red: Need to strengthen skills</li>
                      </ul>
                      {'Subscores '}
                      <InfoIcon />
                    </Tooltip>
                  </h4>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <a
                            href="https://www.khanacademy.org/test-prep/sat/sat-math-practice#new-sat-heart-of-algebra"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Math
                          </a>
                        </TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell>Percentile</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mathSubscoreCategories.map(category => (
                        <TableRow
                          key={category.value}
                          className={this.determineStatus(category.value)}
                        >
                          <td>{category.label}</td>
                          <td>{sat[`${category.value}`]}</td>
                          <td>{sat[`${category.value}_percentile`]}</td>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Table className="table-condensed">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <a
                            href="https://www.khanacademy.org/test-prep/sat/sat-reading-writing-practice#new-sat-reading"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Reading & Writing
                          </a>
                        </TableCell>
                        <TableCell>Score</TableCell>
                        <TableCell>Percentile</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {readingSubscoreCategories.map(category => (
                        <TableRow
                          key={category.value}
                          className={this.determineStatus(category.value)}
                        >
                          <td>{category.label}</td>
                          <td>{sat[`${category.value}`]}</td>
                          <td>{sat[`${category.value}_percentile`]}</td>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </div>
            </div>
          </div>
        )}
      </PageContainer>
    )
  }
}
