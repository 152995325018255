import { get } from 'utils/ajax'
import { alert } from 'utils/alert'

export const getGradeDistribution = (teacherId, params = {}) => {
  return get(`/api/web/teachers/${teacherId}/grade_distributions`, {
    params
  }).catch(_err => {
    alert('An error occurred while fetching the grade distribution', 'danger')
  })
}

export const getGradeDistributionDetails = (teacherId, params = {}) => {
  return get(`/api/web/grade_distributions_details?teacher_id=${teacherId}`, {
    params
  }).catch(_err => {
    alert('An error occurred while fetching the grade details', 'danger')
  })
}
