import React from 'react'
import PropTypes from 'prop-types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import { titleize } from 'utils'
import { useStudent } from 'hooks/useStudents'
import { useTheme } from '@mui/styles'

function StateTesting({ studentId, subject }) {
  const theme = useTheme()
  const params = {
    include_ostps: true
  }
  const { isSuccess, data } = useStudent({ id: studentId, params })
  const recent_ostps = data?.student?.recent_ostps
    ? data.student.recent_ostps.filter(
        ostp => ostp.test_name.toLowerCase() == subject.toLowerCase()
      )
    : []

  return (
    <React.Fragment>
      <Typography variant="h3">Oklahoma State Testing Program</Typography>
      <TableContainer component={Paper} sx={{ marginTop: theme.spacing(4) }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell component="th">Test</TableCell>
              <TableCell component="th">Year</TableCell>
              <TableCell component="th">Grade Level</TableCell>
              <TableCell component="th">Performance</TableCell>
              <TableCell component="th">Score</TableCell>
              <TableCell component="th">Quantile</TableCell>
              <TableCell component="th">Lexile</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isSuccess &&
              recent_ostps.map(ostp => (
                <TableRow key={ostp.id}>
                  <TableCell>{titleize(ostp.test_name)}</TableCell>
                  <TableCell>{ostp.year}</TableCell>
                  <TableCell>{ostp.grade_level}</TableCell>
                  <TableCell>{titleize(ostp.performance)}</TableCell>
                  <TableCell>{ostp.score}</TableCell>
                  <TableCell>{ostp.quantile}</TableCell>
                  <TableCell>{ostp.lexile}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

StateTesting.propTypes = {
  studentId: PropTypes.number.isRequired,
  subject: PropTypes.string.isRequired
}

export default StateTesting
