import React from 'react'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import { Typography } from '@mui/material'

const ForbiddenPage = () => (
  <Page name="ForbiddenPage" title="Forbidden">
    <PageContainer noSidebar>
      <Typography variant="h1">Access Denied</Typography>

      <Typography>
        If you believe you should have access to this page, please submit a
        ticket.
      </Typography>
    </PageContainer>
  </Page>
)

export default ForbiddenPage
