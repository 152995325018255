import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DatasetFilters from './dataset'
import { optionsPropTypes, withOptions } from 'utils/contexts/options'
import { selectors } from 'modules/schools'
import { actions as filterActions, emptyDataset } from 'modules/student-filters'
import { Button, Stack } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

class AdvancedFilters extends React.Component {
  static propTypes = {
    school: PropTypes.object.isRequired,
    filters: PropTypes.array.isRequired,
    filtersChanged: PropTypes.func.isRequired,
    toggleFiltersExpanded: PropTypes.func.isRequired,
    defaultSchoolId: PropTypes.number,
    submit: PropTypes.func,
    ...optionsPropTypes
  }

  addDataset = e => {
    const { filters, defaultSchoolId, filtersChanged } = this.props
    e && e.preventDefault()
    const newFilters = [...filters, this.emptyDataset(defaultSchoolId)]
    filtersChanged(newFilters)
  }

  resetDatasets = () => {
    const filters = [emptyDataset(this.props.defaultSchoolId)]
    this.props.filtersChanged(filters).then(this.props.submit)
  }

  removeDataset = dataset => {
    const filters = this.props.filters.filter(d => d !== dataset)
    this.props.filtersChanged([...filters])
  }

  filterChanged = (dataset, property, value) => {
    dataset[property] = value ? value.value : null
    this.props.filtersChanged([...this.props.filters])
  }

  duplicateDataset = dataset => {
    const filters = [...this.props.filters, { ...dataset }]
    this.props.filtersChanged(filters)
  }

  duplicateLastDataset = () => {
    const { filters } = this.props
    this.duplicateDataset(filters[filters.length - 1])
  }

  submit = e => {
    e.preventDefault()
    this.props.submit()
  }

  render() {
    const {
      school,
      filters,
      schoolYears,
      ethnicities,
      cohorts,
      gradeLevels,
      toggleFiltersExpanded,
      ells
    } = this.props

    return (
      <form onSubmit={this.submit}>
        <div className="filter-form__subsets">
          <Stack spacing={2}>
            {filters.map((dataset, index) => (
              <DatasetFilters
                key={index}
                index={index}
                school={school}
                schoolYearOptions={schoolYears}
                ethnicityOptions={ethnicities}
                cohortOptions={cohorts}
                gradeLevelOptions={gradeLevels}
                ellOptions={ells}
                removable={filters.length > 1}
                filterChanged={(property, value) =>
                  this.filterChanged(dataset, property, value)
                }
                duplicateDataset={() => this.duplicateDataset(dataset)}
                removeDataset={() => this.removeDataset(dataset)}
                {...dataset}
              />
            ))}
          </Stack>
        </div>

        <Stack direction="row" mt={6}>
          <Button
            className="js-submit"
            type="submit"
            variant="contained"
            color="success"
            onClick={toggleFiltersExpanded}
          >
            Apply Filters
          </Button>

          <Button
            variant="contained"
            color="secondary"
            className="js-duplicate-subset"
            onClick={this.duplicateLastDataset}
            startIcon={<AddIcon />}
          >
            Add comparison
          </Button>

          <Button
            variant="contained"
            color="error"
            className="js-reset-subsets"
            onClick={this.resetDatasets}
          >
            Reset Filters
          </Button>
        </Stack>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.studentFilters.filters,
  school: selectors.currentSchool(state),
  cohorts: selectors.cohortOptions(state),
  gradeLevels: selectors.gradeLevelOptions(state),
  defaultSchoolId: state.schools.schoolId
})

const mapDispatchToProps = {
  filtersChanged: filters => filterActions.filtersChanged(filters, true)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOptions(AdvancedFilters))
