import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import ChartContainer from 'components/shared/chart-container'
import { post } from 'utils/ajax'
import { connect } from 'react-redux'
import { userPropTypes } from 'modules/current-user'
import ChartOptions from './chart-options'
import RouterLinkButton from '../../shared/router-link-button'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { BarChart } from '@mui/x-charts/BarChart'
import { calculatePercentage } from 'utils'
import { fetchSchoolDetails } from 'api/schools'
import { inRange } from 'lodash'

const subjectViewMap = {
  ela: 'OSTP (ELA)',
  mathematics: 'OSTP (Math)',
  science: 'OSTP (Science)'
}

const makeStudentsTableUri = ({ school, subject, gradeLevelOptions }) => {
  const isElementary = school.category === 'elementary'

  if (isElementary && subject === 'science') {
    return null
  }

  const studentDataSearchParams = new URLSearchParams()
  studentDataSearchParams.set('school_id', school.id)
  studentDataSearchParams.set('view', subjectViewMap[subject])

  if (isElementary) {
    const gradeLevelsWithOstpData = gradeLevelOptions
      .map(o => o.label)
      .filter(g => g !== '3rd')
    studentDataSearchParams.set('grade_level', gradeLevelsWithOstpData)
  }

  return '/students?' + studentDataSearchParams
}

async function fetchGradeLevels(schoolId) {
  const schoolDetails = await fetchSchoolDetails(schoolId)

  return schoolDetails.grade_levels
    .map((g, index) => {
      const gradeLevelValue = schoolDetails.grade_level_values[index]

      return (
        // inRange = 3 <= gradeLevelValue < 9
        inRange(gradeLevelValue, 3, 9) && {
          label: g,
          value: gradeLevelValue
        }
      )
    })
    .filter(o => typeof o === 'object')
}

function Ostp({ school, user }) {
  const [subject, setSubject] = useState('ela')
  const [gradeLevel, setGradeLevel] = useState('')

  const { data: chartData } = useQuery(
    ['school-ostps', school.id, subject, gradeLevel],
    () =>
      post('/api/web/ostps/chart_data', {
        chart_subject: subject,
        grade_level: gradeLevel,
        school_id: school.id
      }).then(res => res.data),
    {
      initialData: [],
      refetchOnWindowFocus: false,
      enabled: Boolean(school)
    }
  )

  const { data: gradeLevelOptions } = useQuery(
    ['schoolUsers', school.id],
    () => fetchGradeLevels(school.id),
    {
      refetchOnWindowFocus: false,
      initialData: [
        { label: '3rd', value: '3rd' },
        { label: '4th', value: '4th' },
        { label: '5th', value: '5th' },
        { label: '6th', value: '6th' },
        { label: '7th', value: '7th' },
        { label: '8th', value: '8th' }
      ],
      enabled: Boolean(school.id)
    }
  )

  const studentsTableUri = React.useMemo(
    () => makeStudentsTableUri({ school, subject, gradeLevelOptions }),
    [school, subject, gradeLevelOptions]
  )

  return (
    <Page name="OSTP" title={`OSTP - ${school && school.name}`}>
      <Typography variant="h3" id="test_h3_title">
        OSTP Scores
      </Typography>

      {user.role !== 'partner' && (
        <RouterLinkButton
          to={studentsTableUri}
          variant={'contained'}
          disabled={!studentsTableUri}
        >
          See Student Data
        </RouterLinkButton>
      )}

      <ChartOptions
        subject={subject}
        setSubject={setSubject}
        gradeLevel={gradeLevel}
        setGradeLevel={setGradeLevel}
        gradeLevelOptions={gradeLevelOptions}
      />

      <ChartContainer>
        <BarChart
          height={300}
          series={[
            {
              data: chartData.map(year => year.data.below_basic),
              label: 'Below Basic',
              stack: 'total'
            },
            {
              data: chartData.map(year => year.data.basic),
              label: 'Basic',
              stack: 'total'
            },
            {
              data: chartData.map(year => year.data.proficient),
              label: 'Proficient',
              stack: 'total'
            },
            {
              data: chartData.map(year => year.data.advanced),
              label: 'Advanced',
              stack: 'total'
            }
          ]}
          xAxis={[
            {
              id: 'years',
              data: chartData.map(data => `${data.year}`),
              scaleType: 'band',
              label: 'Years'
            }
          ]}
        />
      </ChartContainer>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Year</TableCell>
            <TableCell>Below Basic</TableCell>
            <TableCell>Basic</TableCell>
            <TableCell>Proficient</TableCell>
            <TableCell>Advanced</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chartData.map(year => (
            <TableRow key={year}>
              <TableCell>{year.year}</TableCell>
              <TableCell>
                {year.data.below_basic} (
                {calculatePercentage(year.data.below_basic, year.data.total)}%)
              </TableCell>
              <TableCell>
                {year.data.basic} (
                {calculatePercentage(year.data.basic, year.data.total)}%)
              </TableCell>
              <TableCell>
                {year.data.proficient} (
                {calculatePercentage(year.data.proficient, year.data.total)}%)
              </TableCell>
              <TableCell>
                {year.data.advanced} (
                {calculatePercentage(year.data.advanced, year.data.total)}%)
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Page>
  )
}

Ostp.propTypes = {
  school: PropTypes.object.isRequired,
  ...userPropTypes
}

const mapStateToProps = state => ({
  user: state.currentUser.user
})

export default connect(mapStateToProps)(Ostp)
