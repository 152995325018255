import React from 'react'
import PropTypes from 'prop-types'
import Note from 'components/wellness/note/index'
import ExternalReferral from './external-referral/index'
import Support from './support/new'
import RootCause from './root-cause/index'
import { Box, Stack } from '@mui/material'

function TimelineActions({ concern }) {
  return (
    <Stack>
      <Stack direction="row" spacing={2}>
        <Box>
          <Note parent={concern} queryName="concern" />
        </Box>
        <Box>
          <ExternalReferral concern={concern} />
        </Box>
        <Box>
          <Support concern={concern} />
        </Box>
        <Box>
          <RootCause concern={concern} />
        </Box>
      </Stack>
    </Stack>
  )
}

TimelineActions.propTypes = {
  concern: PropTypes.object.isRequired
}

export default TimelineActions
