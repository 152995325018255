import { get } from 'utils/ajax'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export function useCurrentGrades({ params, options }) {
  const queryKey = ['grades', params]
  const queryFn = () =>
    get(`/api/web/students/${params.studentId}/current_grades`, {
      params: {
        ...params,
        school_category: params.schoolCategory,
        include_student_current_grades: true,
        include_student_assignment_scores: true
      }
    }).catch(_err =>
      alert('An error occurred while getting current grades.', 'danger')
    )
  const query = useQuery(queryKey, queryFn, {
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}
