import React from 'react'
import Page from 'components/shared/page'
import Feed from './feed'
import TeacherInfo from './info'
import PropTypes from 'prop-types'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { connect } from 'react-redux'

const Overview = ({ user, teacher, teacherId }) => {
  return teacher ? (
    <Page name="Overview" title={teacher.name}>
      <Box
        sx={{
          p: 2
        }}
      >
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <TeacherInfo teacher={teacher} />
          <Box
            sx={{
              flex: 2
            }}
          >
            <Feed teacherId={teacherId} user={user} teacher={teacher} />
          </Box>
        </Stack>
      </Box>
    </Page>
  ) : null
}

Overview.propTypes = {
  teacherId: PropTypes.number,
  teacher: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = state => ({
  user: state.currentUser.user
})

export default connect(mapStateToProps)(Overview)
