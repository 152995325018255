import React from 'react'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import { getStudentGradPlanner } from 'api/students'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GraduationStatusChip from 'components/shared/students/show/graduation_check/graduation-status-chip'
import ProgressBar from './progress-bar'
import {
  studentShape,
  gradPlanNodeShape,
  gradPlanShapeData
} from 'utils/grad-plan-progress-prop-types'
import { fetchGradPlanRequirementActions } from 'api/grad-plan-requirement-actions'
import { connect } from 'react-redux'
import Notes from './notes'
import RequirementRows from './requirement-rows'
import { GraduationStatusLegendDialog } from '../../../../students/graduation-status-legend'
import { InfoOutlined } from '@mui/icons-material'

// NOTE: reference from PowerSchool
// const PROGRESS_COLOR_MAP = {
//   'stored': '#149500',
//   'enrolled': '#66ff62',
//   'waived': '#25ccca',
//   'requested': '#f9ff65',
//   'default': '#fff'
// }

const progressTests = child => {
  const { name, type } = child

  const stored = child.children.filter(({ passed }) => passed).length
  const enrolled = 0
  const required = child.children.length
  const status = `${stored} of ${child.children.length} Required Tests Taken`

  return { name, type, stored, enrolled, required, status }
}

const progressCredits = child => {
  const { name, type } = child

  const stored = child.groupDetail.creditsStored
  const enrolled = child.groupDetail.creditsEnrolled
  const required = child.groupDetail.effectiveCreditCapacity
  const status = `${stored} of ${required} Credits`

  return { name, type, stored, enrolled, required, status }
}

const progressRequirements = child => {
  const { name, type } = child
  const stored = child.groupDetail.creditsStored
  const enrolled = child.groupDetail.creditsEnrolled
  const required = child.groupDetail.effectiveCreditCapacity
  const status = `${stored} of ${required} Requirements`

  return { name, type, stored, enrolled, required, status }
}

const progress = child => {
  const { type } = child
  switch (type) {
    case 0: {
      return progressCredits(child)
    }
    case 2: {
      return progressRequirements(child)
    }
    case 3: {
      return progressTests(child)
    }
    default: {
      console.error(`unexpected type: ${type}`)
    }
  }
}

const sumProgress = children => {
  const reduced = children.map(progress).reduce(
    (prev, curr) => {
      prev.stored += curr.stored || 0
      prev.enrolled += curr.enrolled || 0
      prev.required += curr.required || 0
      return prev
    },
    { stored: 0, enrolled: 0, required: 0 }
  )

  return {
    stored: reduced.stored / reduced.required,
    enrolled: reduced.enrolled / reduced.required
  }
}

export function TestGroupTableRow({ node }) {
  const passed = node.children.filter(({ passed }) => passed)
  const stored = passed.length / node.children.length
  const status = `${passed.length} of ${node.children.length} Required Tests Taken`

  return (
    <TableRow>
      <TableCell>{node.name}</TableCell>
      <TableCell>{status}</TableCell>
      <TableCell>
        <ProgressBar stored={stored} />
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}
TestGroupTableRow.propTypes = {
  node: gradPlanNodeShape.isRequired
}

function GradPlannerAccordion({
  student,
  gradPlan,
  defaultExpanded = false,
  gradPlanRequirementActions,
  requirementsEarnedAndEnrolled
}) {
  const node = gradPlan.root
  const { stored, enrolled } = sumProgress(node.children)

  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box
          sx={{
            width: '100%',
            pr: '1rem'
          }}
        >
          <Typography variant={'h6'}>{gradPlan.gradPlan.name}</Typography>
          <ProgressBar stored={stored} enrolled={enrolled} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Requirement</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Completion</TableCell>
                <TableCell width={1}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <RequirementRows
                {...{
                  student,
                  node,
                  gradPlanRequirementActions,
                  requirementsEarnedAndEnrolled
                }}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  )
}
GradPlannerAccordion.propTypes = {
  student: studentShape,
  gradPlan: gradPlanShapeData.isRequired,
  defaultExpanded: PropTypes.bool,
  gradPlanRequirementActions: PropTypes.array,
  requirementsEarnedAndEnrolled: PropTypes.array
}

function NonAcademicRequirements({ student, studentUserFields }) {
  return (
    <>
      <Typography variant={'h4'}>Non-Academic Requirements</Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Requirement</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>FAFSA</TableCell>
              <TableCell>{studentUserFields?.tps_grad_fafsa}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>PFL</TableCell>
              <TableCell>{studentUserFields?.tps_grad_flp}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CPR</TableCell>
              <TableCell>{studentUserFields?.tps_grad_cpr}</TableCell>
            </TableRow>
            {student.cohort >= 2025 && (
              <TableRow>
                <TableCell>US Civics Knowledge Assessment</TableCell>
                <TableCell>{studentUserFields?.tps_grad_uscivics}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>ICAP 9</TableCell>
              <TableCell>{studentUserFields?.tps_grad_icap9}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ICAP 10</TableCell>
              <TableCell>{studentUserFields?.tps_grad_icap10}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ICAP 11</TableCell>
              <TableCell>{studentUserFields?.tps_grad_icap11}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ICAP 12</TableCell>
              <TableCell>{studentUserFields?.tps_grad_icap12}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>ICAP WBL</TableCell>
              <TableCell>{studentUserFields?.tps_grad_icap_wbl}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
NonAcademicRequirements.propTypes = {
  student: studentShape,
  studentUserFields: PropTypes.shape({
    tps_grad_fafsa: PropTypes.string,
    tps_grad_flp: PropTypes.string,
    tps_grad_cpr: PropTypes.string,
    tps_grad_uscivics: PropTypes.string,
    tps_grad_icap9: PropTypes.string,
    tps_grad_icap10: PropTypes.string,
    tps_grad_icap11: PropTypes.string,
    tps_grad_icap12: PropTypes.string,
    tps_grad_icap_wbl: PropTypes.string
  })
}

function GraduationProgressSummary({ student }) {
  return (
    <Stack>
      <Typography variant={'h4'}>Details</Typography>
      <Box>
        <Link
          target={'_blank'}
          href={`https://powerschool.tulsaschools.org/admin/students/GraduationPlanner/GradPlanProgress.html?frn=001${student.powerschool_dcid}`}
        >
          PowerSchool Graduation Progress
        </Link>
      </Box>

      <Box>
        <Typography variant={'h6'}>Primary Graduation Plan</Typography>
        <Typography variant={'subtitle2'}>{student.graduation_plan}</Typography>
      </Box>

      <GraduationStatusLegendDialog>
        {({ openModal }) => (
          <Box onClick={openModal} sx={{ cursor: 'pointer' }}>
            <Typography variant={'h6'}>
              Status <InfoOutlined fontSize={'inherit'} />
            </Typography>
            <Typography variant={'subtitle2'}>
              {student.graduation_status && (
                <GraduationStatusChip label={student.graduation_status} />
              )}
            </Typography>
          </Box>
        )}
      </GraduationStatusLegendDialog>

      <Box>
        <Typography variant={'h6'}>Cohort</Typography>
        <Typography variant={'subtitle2'}>
          {student.cohort || 'Not Set'}
        </Typography>
      </Box>
    </Stack>
  )
}
GraduationProgressSummary.propTypes = {
  student: studentShape
}

function GradPlanner({ student, userId }) {
  const studentId = student.id

  const { data: gradPlanRequirementActions } = useQuery(
    ['grad-plan-requirement-actions', studentId],
    () => {
      const params = { student_id: studentId }

      return fetchGradPlanRequirementActions(params).then(res => {
        return res.data
      })
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const { data } = useQuery(
    ['student-grad-planner', studentId],
    () => {
      return getStudentGradPlanner(studentId)
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const {
    grad_plan_progress,
    ps_u_students_user_fields: studentUserFields,
    sent_emails: sentEmails,
    requirements_earned_and_enrolled: requirementsEarnedAndEnrolled
  } = data || {}

  const gradPlans = grad_plan_progress?.data || []

  const nodeIdsInPrimaryGradPlan =
    gradPlans && gradPlans[0]
      ? gradPlans[0].root.children
          .map(c => [c.children.map(ch => ch.id), c.id])
          .flat(2)
      : []

  return !data ? null : (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <GraduationProgressSummary student={student} />
      </Grid>
      <Grid item xs={6}>
        <NonAcademicRequirements
          student={student}
          studentUserFields={studentUserFields}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'h4'}>Graduation Plans</Typography>
        {gradPlans.map((gradPlan, index) => (
          <GradPlannerAccordion
            key={index}
            student={student}
            gradPlan={gradPlan}
            defaultExpanded={index == 0}
            gradPlanRequirementActions={gradPlanRequirementActions}
            requirementsEarnedAndEnrolled={requirementsEarnedAndEnrolled}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Notes
          {...{
            student,
            userId,
            sentEmails,
            gradPlanRequirementActions,
            nodeIdsInPrimaryGradPlan
          }}
        />

        {/* <PreviousEmail /> */}
      </Grid>
    </Grid>
  )
}
GradPlanner.propTypes = {
  student: studentShape,
  userId: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  userId: state.currentUser.user.id
})

export default connect(mapStateToProps)(GradPlanner)
