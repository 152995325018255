import React from 'react'
import Page from 'components/shared/page'
import { Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { fetchDistrictMetricsBySchool } from 'api/district-metrics-by-school'
import DistrictMetricsTable from 'components/shared/district-metrics-table'

function DistrictMetricsBySchool() {
  const {
    data: { school_metrics, schools, academic_interventions, team_meetings }
  } = useQuery(
    ['district-metrics-by-school'],
    async () => await fetchDistrictMetricsBySchool(),
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  return (
    <Page
      name="District Overall Metrics By School"
      title="District Overall Metrics By School"
    >
      <Stack>
        <DistrictMetricsTable
          data={school_metrics || []}
          schools={schools}
          academic_interventions={academic_interventions}
          team_meetings={team_meetings}
        />
      </Stack>
    </Page>
  )
}

export default DistrictMetricsBySchool
