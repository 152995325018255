import { get } from 'utils/ajax'
import { alert } from 'utils/alert'

const getSections = (schoolId, params = {}) => {
  const mergedParams = { school_id: schoolId, ...params }
  return get('/api/web/sections', { params: mergedParams }).catch(_err => {
    alert('An error occurred while fetching sections', 'danger')
  })
}

export default {
  getSections
}
