import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'

export default function MultiselectAutocomplete({
  id,
  label,
  value,
  onChange,
  options
}) {
  const normalizedValue = [value || []].flat().map(val => {
    const matchingOption = options.find(opt => opt.value === val)
    return matchingOption || { value: val, label: val }
  })

  return (
    <Autocomplete
      value={normalizedValue}
      onChange={(_event, newValue) => {
        const simpleValues = newValue.map(item => item.value)
        onChange(simpleValues)
      }}
      multiple
      getOptionLabel={option => option.label}
      filterSelectedOptions
      renderInput={params => (
        <TextField
          label={label}
          {...params}
          inputProps={{
            ...params.inputProps,
            'data-testid': `${label}-select-input`
          }}
        />
      )}
      fullWidth
      id={id}
      options={options}
      autoHighlight={true}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  )
}

MultiselectAutocomplete.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}
