import axios from 'axios'
import React from 'react'
import moment from 'moment'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { titleize } from 'utils'
import UserModal from './../../users/user-modal'
import { RemoteTable } from 'components/shared/table'
import { Link } from 'react-router-dom'
import { Box, Button, Tooltip } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import KeyIcon from '@mui/icons-material/Key'
import CreateIcon from '@mui/icons-material/Create'

export default class AdminSchoolUsersTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    fetchData: PropTypes.func,
    loading: PropTypes.bool,
    totalPages: PropTypes.number
  }

  state = {
    openModal: false,
    activeUser: {}
  }

  handleModalHide = () => {
    this.setState({ openModal: false })
  }

  openModalWithUser = user => {
    this.setState({
      activeUser: user,
      openModal: true
    })
  }

  columns = () => {
    return [
      {
        id: 'name',
        Header: 'Name',
        accessor: obj => obj.name
      },
      {
        id: 'role',
        Header: 'Role',
        accessor: obj => titleize(obj.role)
      },
      {
        id: 'job_title',
        Header: 'Job Title',
        accessor: obj => obj,
        Cell: props => (
          <Link to={{ pathname: `/admins/jobs/id/${props.value.job_id}` }}>
            {props.value.job_title}
          </Link>
        )
      },
      {
        id: 'login_events_count',
        Header: 'Login Count',
        accessor: obj => obj.login_events_count
      },
      {
        id: 'last_sign_in_at',
        Header: 'Last Login',
        accessor: obj => obj.last_sign_in_at,
        Cell: props => moment(props.value).format('MM/DD/YYYY')
      },
      {
        id: 'actions',
        Header: 'Actions',
        accessor: obj => obj,
        Cell: props => (
          <Box>
            <Tooltip id="edit-user" placement="top" arrow title="Edit User">
              <Button
                id={`js-admin-view-user-${props.value.id}`}
                variant="contained"
                color="secondary"
                onClick={() => this.openModalWithUser(props.value)}
              >
                <CreateIcon />
              </Button>
            </Tooltip>

            {props.value.active && props.value.role != 'unassigned' ? (
              <Tooltip
                id="impersonate-user"
                placement="top"
                arrow
                title="Login as User"
              >
                <Button
                  variant="contained"
                  id={`impersonate-${props.value.id}`}
                  href={`/admins/users/${props.value.id}/impersonations`}
                  sx={{ ml: 2 }}
                >
                  <PersonIcon />
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                id="impersonate-user"
                placement="top"
                arrow
                label="Cannot impersonate inactive/unassigned Users"
              >
                <Button
                  id={`impersonate-${props.value.id}-disabled`}
                  variant="contained"
                  disabled
                  sx={{ ml: 2 }}
                >
                  <KeyIcon />
                </Button>
              </Tooltip>
            )}
          </Box>
        )
      }
    ]
  }

  fetchUserTableData = debounce(
    attrs => {
      this.setState({ isLoading: true })

      const params = {
        search: this.state.search,
        ...attrs
      }

      return axios
        .get('/api/admin/users', {
          params: {
            ...params
          }
        })
        .then(res => {
          this.setState({
            users: res.data.data,
            pages: res.data.meta.pages,
            isLoading: false
          })
          return res.data
        })
        .catch(() => {
          this.setState({ isLoading: false })
          alert('Failed to fetch users :(')
        })
    },
    300,
    { leading: false, trailing: true }
  )

  render() {
    const { data, loading, fetchData, totalPages, ...props } = this.props
    const { activeUser, openModal } = this.state
    const fetchUserData = this.fetchUserData
    return (
      <Box id="user-table">
        <UserModal
          key={activeUser.id}
          show={openModal}
          user={activeUser}
          onHide={this.handleModalHide}
          fetchData={fetchUserData}
        />
        <RemoteTable
          columns={this.columns()}
          data={data}
          loading={loading}
          onFetchData={params => fetchData(params)}
          pages={totalPages}
          {...props}
        />
      </Box>
    )
  }
}
