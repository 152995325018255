import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { observationTypelookForShape } from './components/prop-types'
import StyledTextField from './components/styled-text-field'
import { uniq, uniqBy } from 'lodash'
import DevelopmentArea from './components/development-area'

export function LearningWalkObserve({
  form,
  setForm,
  lookForObserved,
  observationTypeLookFors
}) {
  const developmentAreaNames = uniq(
    observationTypeLookFors.map(o => o.development_area_name)
  )
  const observationTypeCategories = uniqBy(
    observationTypeLookFors.map(o => ({
      name: o.look_for_category_name,
      color: o.look_for_category_color.split(':')[1],
      color_name: o.look_for_category_color.split(':')[0]
    })),
    category => category.name
  )

  const handleChangeNoteToTeacher = note_to_teacher => {
    setForm(state => ({ ...state, note_to_teacher }))
  }

  const handleChangeNoteToSelf = note_to_self => {
    setForm(state => ({ ...state, note_to_self }))
  }

  const developmentAareaObservationLookFors = name => {
    return observationTypeLookFors.filter(o => o.development_area_name == name)
  }

  return (
    <React.Fragment>
      {developmentAreaNames
        .map(name => ({
          name,
          observationTypeLookFors: developmentAareaObservationLookFors(name)
        }))
        .map(({ name, observationTypeLookFors }, i) => (
          <DevelopmentArea
            key={i}
            {...{
              name,
              form,
              lookForObserved,
              observationTypeLookFors,
              nullable: true
            }}
          />
        ))}
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-evenly',
          flexWrap: 'wrap'
        }}
      >
        {observationTypeCategories.map(category => (
          <span
            key={category.name}
            style={{
              whiteSpace: 'nowrap',
              color: category.color
            }}
          >
            {category.color_name}=
            {category.name.replace(/(Elementary|Secondary) /, '')} Look For
          </span>
        ))}
      </Stack>
      <Typography variant={'h4'}>Notes</Typography>
      <FormControl fullWidth>
        <TextField
          id={'noteToSelf'}
          name={'noteToSelf'}
          multiline
          label={'Note to Self'}
          helperText={'Visible only to Me'}
          rows={4}
          value={form.note_to_self || ''}
          onChange={event => handleChangeNoteToSelf(event.target.value)}
        />
      </FormControl>
      <FormControl fullWidth>
        <StyledTextField
          id={'teacherNote'}
          name={'teacherNote'}
          multiline
          label={'Note to Teacher'}
          helperText={'Visible to Teacher'}
          rows={4}
          value={form.note_to_teacher || ''}
          onChange={event => handleChangeNoteToTeacher(event.target.value)}
        />
      </FormControl>
    </React.Fragment>
  )
}

LearningWalkObserve.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  lookForObserved: PropTypes.func.isRequired,
  observationTypeLookFors: PropTypes.arrayOf(observationTypelookForShape)
    .isRequired
}

export default LearningWalkObserve
