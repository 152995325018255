import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PieChartFiveSlices from 'components/shared/charts/PieChartFiveSlices'
import PaperBox from 'components/shared/PaperBox'
import { Stack, Typography } from '@mui/material'
import { get } from 'utils/ajax'
import { getMapReadingTiers } from 'api/map-reading-tiers'
import urls from 'utils/urls'
import { currentSchoolYear } from 'utils'

function AcademicAssessments({ school }) {
  const [mapReadingTiers, setMapReadingTiers] = useState(null)
  const [metAccessGoals, setMetAccessGoals] = useState(null)

  useEffect(() => {
    const params = { school_year: currentSchoolYear }

    getMapReadingTiers({ school_id: school.id }).then(res => {
      setMapReadingTiers(res)
    })

    get(urls.api.schoolMetrics(school.id), { params }).then(data => {
      setMetAccessGoals(data.school_metric?.access_goals)
    })
  }, [school.id])

  return (
    <Stack spacing={2}>
      <Typography variant="h3">Reading Assessments</Typography>
      {mapReadingTiers && ['elementary'].includes(school?.category) && (
        <PaperBox
          title="MAP Literacy Tiers by Student Count"
          helperText={{
            'Who Is Included?':
              'Enrolled students with a MAP score in the most recent testing term',
            'Update Frequency': 'Three times per year (Fall/Winter/Spring)',
            'Advanced': '80th to 99th percentiles',
            'Tier 1': '40th to 79th percentiles',
            'Tier 1+': '26th to 39th percentiles',
            'Tier 2': '11th to 25th percentiles',
            'Tier 3': '10th percentile and under'
          }}
        >
          <PieChartFiveSlices
            one={{
              title: 'Advanced',
              value: mapReadingTiers.advanced,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'MAP',
                  latest_map_reading_percentile_range: [80, 99]
                }
              }
            }}
            two={{
              title: 'Tier 1',
              value: mapReadingTiers.one,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'MAP',
                  latest_map_reading_percentile_range: [40, 79]
                }
              }
            }}
            three={{
              title: 'Tier 1+',
              value: mapReadingTiers.one_plus,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'MAP',
                  latest_map_reading_percentile_range: [26, 39]
                }
              }
            }}
            four={{
              title: 'Tier 2',
              value: mapReadingTiers.two,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'MAP',
                  latest_map_reading_percentile_range: [11, 25]
                }
              }
            }}
            five={{
              title: 'Tier 3',
              value: mapReadingTiers.three,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'MAP',
                  latest_map_reading_percentile_range: [1, 10]
                }
              }
            }}
          />
        </PaperBox>
      )}
      {mapReadingTiers && ['middle', 'high'].includes(school?.category) && (
        <PaperBox
          title="MAP Literacy Tiers by Student Count"
          helperText={{
            'Update Frequency': 'Three times per year (Fall/Winter/Spring)',
            'Tier 1': '50th to 99th percentiles',
            'Tier 2': '26th to 49th percentiles',
            'Tier 3': '25th percentile and under'
          }}
        >
          <PieChartFiveSlices
            one={{
              title: 'Tier 1',
              value: mapReadingTiers.one,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'MAP',
                  latest_map_reading_percentile_range: [50, 99]
                }
              }
            }}
            two={{
              title: 'Tier 2',
              value: mapReadingTiers.two,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'MAP',
                  latest_map_reading_percentile_range: [26, 49]
                }
              }
            }}
            three={{
              title: 'Tier 3',
              value: mapReadingTiers.three,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'MAP',
                  latest_map_reading_percentile_range: [1, 25]
                }
              }
            }}
          />
        </PaperBox>
      )}

      {metAccessGoals && (
        <PaperBox
          title="Multilingual Learners (MLs) Meeting Growth Targets on ACCESS"
          helperText={{
            'Update Frequency': 'Annually',
            'Resources': 'link_to_ml_pdf'
          }}
        >
          <PieChartFiveSlices
            one={{
              title: 'Met',
              value: metAccessGoals.true,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school.id,
                  view: 'Default',
                  ells: 'Yes',
                  met_access_goal: true
                }
              }
            }}
            two={{
              title: 'Not Met',
              value: metAccessGoals.false,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school.id,
                  view: 'Default',
                  ells: 'Yes',
                  met_access_goal: false
                }
              }
            }}
          />
        </PaperBox>
      )}
    </Stack>
  )
}

AcademicAssessments.propTypes = {
  school: PropTypes.object.isRequired
}

export default AcademicAssessments
