import React from 'react'
import PropTypes from 'prop-types'
import { Button, Stack, Typography, Box, TextField } from '@mui/material'
import Dialog from 'shared/dialog'
import { connect } from 'react-redux'
import moment from 'moment'
import { createSystemStatus } from 'api/admin/system_statuses'

class SystemStatusModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired
  }

  state = {
    title: '',
    description: '',
    expirationDate: null
  }

  handleSubmit = e => {
    e.preventDefault()
    this.handleCreate()
  }

  handleCreate = () => {
    const { onHide, fetchData } = this.props
    const { title, description, expirationDate } = this.state
    let params = {
      title: title,
      description: description,
      expiration_date: expirationDate
    }
    createSystemStatus(params).then(() => {
      onHide()
      fetchData()
      this.setState({
        title: '',
        description: '',
        expirationDate: null
      })
    })
  }

  handleChange = (identifier, _value) => {
    this.setState({
      [identifier]: _value.target.value
    })
  }

  render() {
    const { show, onHide } = this.props
    const { title, description, expirationDate } = this.state

    return (
      <Dialog open={show} onClose={onHide} title="Create a System Status">
        <Box
          sx={{
            p: 4,
            pt: 0
          }}
        >
          <form onSubmit={e => this.handleSubmit(e)}>
            <Stack spacing={2}>
              <Box>
                <Typography>Title</Typography>
                <TextField
                  id="title"
                  value={title}
                  onChange={val => this.handleChange('title', val)}
                  fullWidth
                  required
                />
              </Box>
              <Box>
                <Typography>Description</Typography>
                <TextField
                  multiline
                  id="description"
                  value={description}
                  onChange={val => this.handleChange('description', val)}
                  fullWidth
                  rows={4}
                />
              </Box>
              <Box>
                <Typography>Expiration Date</Typography>
                <input
                  type="date"
                  value={moment(expirationDate).format('YYYY-MM-DD')}
                  onChange={val => this.handleChange('expirationDate', val)}
                  name="expirationDate"
                  required
                />
              </Box>
              <Box>
                <Button id="submit-button" type="submit" variant="contained">
                  Create
                </Button>
              </Box>
            </Stack>
          </form>
        </Box>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  schoolId: state.schools.schoolId
})

export default connect(mapStateToProps)(SystemStatusModal)
