import List from '@mui/material/List'
import PropTypes from 'prop-types'
import React from 'react'
import DocumentListItem from './document-list-item'
import Upload from './upload'

export function DocumentsList({
  associations = {},
  documents = [],
  uploaded = [],
  uploading = {},
  onEdited = _id => {},
  onDestroyed = _id => {},
  onUpload = () => {}
}) {
  return (
    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      {Object.values(uploading).map(upload => (
        <Upload
          key={`uploading:${upload.id}`}
          file={upload.file}
          associations={associations}
          onSuccess={doc => onUpload({ upload, doc })}
        >
          {({ doc, loaded }) => (
            <DocumentListItem doc={doc} loadedPercentage={loaded} />
          )}
        </Upload>
      ))}

      {uploaded.map(doc => (
        <DocumentListItem key={`uploaded:${doc.id}`} doc={doc} />
      ))}

      {documents.map(doc => (
        <DocumentListItem
          key={`document:${doc.id}`}
          doc={doc}
          onEdited={onEdited}
          onDestroyed={onDestroyed}
        />
      ))}
    </List>
  )
}

DocumentsList.propTypes = {
  associations: PropTypes.object,
  uploading: PropTypes.object,
  uploaded: PropTypes.array,
  documents: PropTypes.array,
  onEdited: PropTypes.func,
  onDestroyed: PropTypes.func,
  onUpload: PropTypes.func
}

export default DocumentsList
