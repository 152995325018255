import { get } from 'utils/ajax'
import { alert } from 'utils/alert'

export const getStudent = params => {
  return get(`/api/web/students/${params.studentId}`, { params }).catch(
    _err => {
      alert('Something went wrong when loading the student', 'danger')
    }
  )
}

export const getStudentFeed = (
  studentId,
  params = { page: 1, per_page: 10 }
) => {
  return get(`/api/web/students/${studentId}/feed_items`, { params }).catch(
    _err => {
      alert('Something went wrong when loading the student feed', 'danger')
    }
  )
}

export const getStudentNotes = studentId => {
  return get(`/api/web/students/${studentId}/notes`).catch(_err => {
    alert('Something went wrong when loading student notes', 'danger')
  })
}

export const getStudentGradPlanner = studentId => {
  return get(`/api/web/students/${studentId}/grad_planner`)
}

//TODO: finish all api calls
