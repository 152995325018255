export const types = {
  FETCH_REQUIREMENTS: 'child_studies/fetch_requirements'
}

const initialState = []

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_REQUIREMENTS:
      return action.payload
    default:
      return state
  }
}
