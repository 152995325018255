import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Popover from '@mui/material/Popover'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { IconButton } from '@mui/material'
import { QuestionMark } from '@mui/icons-material'

const PaperBox = ({ id, title, helperText, children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const popOverId = open ? 'helper-text-popover' : undefined

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const displayHelperText = text => {
    if (text == 'link_to_ml_pdf') {
      return (
        <a
          href={'/assets/multilingual-learner-one-pager.pdf'}
          rel="noopener noreferrer"
          target="_blank"
        >
          1-Pager
        </a>
      )
    } else {
      return text
    }
  }

  const sanitizeHelperText = () => {
    if (typeof helperText === 'object') {
      return (
        <Table>
          <TableBody>
            {Object.entries(helperText).map((row, i) => (
              <TableRow key={i}>
                <TableCell>
                  <strong>{row[0]}</strong>
                </TableCell>
                <TableCell>{displayHelperText(row[1])}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )
    } else if (typeof helperText === 'string') {
      return <Typography variant="subtitle1">{helperText}</Typography>
    }
  }

  return (
    <Paper id={id}>
      <Box
        component="div"
        sx={{
          backgroundColor: '#f5f5f5',
          p: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        <Typography variant="h6">{title}</Typography>
        {helperText && (
          <>
            <IconButton onClick={handleClick}>
              <QuestionMark />
            </IconButton>

            <Popover
              id={popOverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
            >
              <Typography sx={{ p: 2 }}>{sanitizeHelperText()}</Typography>
            </Popover>
          </>
        )}
      </Box>
      <Box sx={{ p: 2 }}>{children}</Box>
    </Paper>
  )
}

PaperBox.propTypes = {
  title: PropTypes.any,
  helperText: PropTypes.any,
  children: PropTypes.node
}

export default PaperBox
