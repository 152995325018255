import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from 'components/shared/dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'

function MapDialog({ show, onHide, map }) {
  const {
    name,
    subject,
    date,
    location,
    grade_level,
    term,
    lexile,
    fall_projected,
    fall_actual,
    spring_projected,
    spring_actual,
    winter_projected,
    winter_actual
  } = map || {}

  const ActiveTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover
  }))

  const BoldTableCell = styled(TableCell)(() => ({
    fontWeight: 'bold'
  }))

  return (
    <Dialog
      fullWidth
      open={show}
      onClose={onHide}
      title={`${name}: ${I18n.jsT('titles.map_test_from', { subject, date })}`}
    >
      <DialogContent>
        <Table aria-label="testing details">
          <TableHead>
            <TableRow>
              <BoldTableCell
                sx={{ color: 'white', backgroundColor: 'black' }}
                colSpan={'4'}
              >
                {I18n.jsT('titles.testing_details')}
              </BoldTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <ActiveTableRow>
              <BoldTableCell>School</BoldTableCell>
              <BoldTableCell>Grade</BoldTableCell>
              <BoldTableCell>Term</BoldTableCell>
              <BoldTableCell>Lexile</BoldTableCell>
            </ActiveTableRow>
            <TableRow>
              <TableCell>{location}</TableCell>
              <TableCell>{grade_level}</TableCell>
              <TableCell>{term}</TableCell>
              <TableCell>{lexile || 'N/A'}</TableCell>
            </TableRow>
            <ActiveTableRow>
              <BoldTableCell colSpan={'2'}>
                {I18n.jsT('table_headers.map.growth_window')}
              </BoldTableCell>
              <BoldTableCell>
                {I18n.jsT('table_headers.map.projected')}
              </BoldTableCell>
              <BoldTableCell>
                {I18n.jsT('table_headers.map.actual')}
              </BoldTableCell>
            </ActiveTableRow>
            <TableRow>
              <TableCell colSpan={'2'}>
                {I18n.jsT('dates.timespan.fall_to_fall')}
              </TableCell>
              <TableCell>{fall_projected || 'N/A'}</TableCell>
              <TableCell>{fall_actual || 'N/A'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={'2'}>
                {I18n.jsT('dates.timespan.fall_to_spring')}
              </TableCell>
              <TableCell>{spring_projected || 'N/A'}</TableCell>
              <TableCell>{spring_actual || 'N/A'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={'2'}>
                {I18n.jsT('dates.timespan.fall_to_winter')}
              </TableCell>
              <TableCell>{winter_projected || 'N/A'}</TableCell>
              <TableCell>{winter_actual || 'N/A'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button aria-label="close" onClick={onHide} variant={'outlined'}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MapDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  map: PropTypes.shape({
    student_id: PropTypes.number,
    subject: PropTypes.string,
    date: PropTypes.string,
    location: PropTypes.string,
    grade_level: PropTypes.string,
    term: PropTypes.string,
    lexile: PropTypes.number,
    fall_projected: PropTypes.number,
    fall_actual: PropTypes.number,
    spring_projected: PropTypes.number,
    spring_actual: PropTypes.number,
    winter_projected: PropTypes.number,
    winter_actual: PropTypes.number
  })
}

export default MapDialog
