import React from 'react'
import PropTypes from 'prop-types'
import {
  styled,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell as MuiTableCell,
  TableRow
} from '@mui/material'
import { groupBy, round, sumBy } from 'lodash'

const calculateProficiency = data => {
  const weightedCredits = sumBy(data, r => r.average_credit * r.responses)
  const responses = sumBy(data, 'responses')

  return round((weightedCredits / responses) * 100, 2)
}

function standardOrParentFullStatement(standard, hmh_oas) {
  return (
    hmh_oas[standard]?.full_statement ||
    hmh_oas[standard.replace(/\.[a-z]+$/, '')]?.full_statement
  )
}

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  verticalAlign: 'top',
  borderBottomColor: theme.palette.grey[200]
}))
const TableHeadCell = styled(TableCell)(() => ({
  fontWeight: 'bold',
  whiteSpace: 'nowrap'
}))

export function StandardsBreakdown({ stats, hmh_oas }) {
  const statsByStandard = Object.entries(groupBy(stats, 'standard'))

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Standard</TableHeadCell>
              <TableHeadCell>Standard Description</TableHeadCell>
              <TableHeadCell>Proficiency</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statsByStandard.map(([standard, data]) => (
              <TableRow key={standard}>
                <TableCell>{standard}</TableCell>
                <TableCell>
                  {standardOrParentFullStatement(standard, hmh_oas)}
                </TableCell>
                <TableCell>{calculateProficiency(data)}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

StandardsBreakdown.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string.isRequired,
      standard: PropTypes.string.isRequired,
      module_number: PropTypes.number.isRequired,
      grade_level: PropTypes.number.isRequired,
      school_id: PropTypes.number.isRequired,
      responses: PropTypes.number.isRequired,
      average_credit: PropTypes.number.isRequired
    })
  ),
  hmh_oas: PropTypes.objectOf(
    PropTypes.shape({
      full_statement: PropTypes.string.isRequired
    })
  )
}

export default StandardsBreakdown
