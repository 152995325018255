import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import { useSelector } from 'react-redux'
import { TextField } from '@mui/material'
import { useDebounce } from 'hooks/useDebounce'
import List from '@mui/material/List'
import StudentListItem from '../search/list-items/student'
import { useSearch } from '../../../hooks/useSearch'
import { isEqual } from 'lodash'

function useSearchOptions({ search, schoolId }) {
  const params = { search, type: 'Student' }
  if (schoolId) {
    params['school'] = schoolId
  }
  const options = {
    enabled: search.length > 0,
    initialData: []
  }
  const { data } = useSearch({ params, options })

  return data.map(item => ({
    name: `${item.searchable.first_name} ${item.searchable.last_name}`,
    label: `${item.searchable.first_name} ${item.searchable.last_name} (${item.searchable.number})`,
    value: item.searchable.id,
    searchResult: item
  }))
}

function isOptionEqualToValue({ searchResult: a }, { searchResult: b }) {
  return isEqual(a, b)
}

function StudentMultiSelect({
  selectedOption,
  setSelectedOption,
  isSubmitted
}) {
  const [value, setValue] = useState(selectedOption || [])
  const [inputValue, setInputValue] = useState('')
  const search = useDebounce(inputValue, 500)
  const isError = isSubmitted && selectedOption.length === 0

  const { schoolId } = useSelector(state => ({
    schoolId: state.schools.schoolId
  }))

  const searchOptions = useSearchOptions({ search, schoolId })

  return (
    <>
      <Autocomplete
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue)
          setSelectedOption(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(_event, newValue) => {
          setInputValue(newValue)
        }}
        filterOptions={x => x}
        multiple
        getOptionLabel={option => option.label}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            label={'Students'}
            {...params}
            error={isError}
            required={selectedOption.length === 0}
            inputProps={{
              ...params.inputProps,
              'data-testid': 'student-select-input'
            }}
            helperText={
              'If you have sections at multiple schools (e.g., OTEP), change your selected school to ALL SCHOOLS to see all available students.'
            }
          />
        )}
        fullWidth
        id={'student-select'}
        label={'Students'}
        options={searchOptions}
        autoHighlight={true}
        ListboxComponent={List}
        renderOption={(props, option) => (
          <StudentListItem searchResult={option.searchResult} {...props} />
        )}
        isOptionEqualToValue={isOptionEqualToValue}
        noOptionsText={'No options … Input student name or number to search'}
      />
    </>
  )
}

StudentMultiSelect.propTypes = {
  selectedOption: PropTypes.array,
  setSelectedOption: PropTypes.func.isRequired,
  isSubmitted: PropTypes.bool.isRequired
}

export default StudentMultiSelect
