import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'
import { optionsPropTypes, withOptions } from 'utils/contexts/options'
import { concernStatusOptions } from 'utils/enum-options'
import FilterContainer from '../../shared/filter-container'
import WellnessConcernsCsvLink from './interventions-csv-link'
import { isNull } from 'lodash'

function InterventionFilters({
  search,
  setSearch,
  status,
  isRequestingHelp,
  createdBy,
  severity,
  concernTypeId,
  concernTypes,
  createdByNames,
  handleChange,
  resetState,
  hasInterventions,
  hasSupports,
  hasExternalReferrals,
  hasNotes,
  hasDocuments,
  isHighSecurity
}) {
  const hasSelection =
    search ||
    status ||
    concernTypeId ||
    !isNull(isRequestingHelp) ||
    createdBy ||
    severity ||
    !isNull(hasInterventions) ||
    !isNull(hasSupports) ||
    !isNull(hasExternalReferrals) ||
    !isNull(hasNotes) ||
    !isNull(hasDocuments) ||
    !isNull(isHighSecurity)

  return (
    <FilterContainer>
      <Box
        component="form"
        sx={{
          my: 4
        }}
      >
        <Stack direction="row" spacing={2}>
          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="status-select-label">Filter by Status</InputLabel>
            <Select
              label="Filter by Status"
              labelId="status-select-label"
              id="status-select"
              value={status || ''}
              onChange={e => handleChange('status', e.target.value)}
              variant="outlined"
            >
              {concernStatusOptions.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="concernTypeId-select-label">
              Filter by Concern Type
            </InputLabel>
            <Select
              id="concernTypeId-select"
              labelId="concernTypeId-select-label"
              label="Filter by Concern Type"
              value={concernTypeId || ''}
              onChange={e => handleChange('concernTypeId', e.target.value)}
              variant="outlined"
            >
              {concernTypes &&
                [{ label: 'All', value: null }]
                  .concat(
                    concernTypes.map(concern => ({
                      label: concern.name,
                      value: concern.id
                    }))
                  )
                  .map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="isRequestingHelp-select-label">
              Filter by Requesting Help
            </InputLabel>
            <Select
              label="Filter by Requesting Help"
              id="isRequestingHelp-select"
              labelId="isRequestingHelp-select-label"
              value={isRequestingHelp || ''}
              onChange={e => handleChange('isRequestingHelp', e.target.value)}
              variant="outlined"
            >
              {[
                { label: 'All', value: null },
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="createdByNames-select-label">
              Filter by Created By
            </InputLabel>
            <Select
              label="Filter by Created By"
              labelId="createdByNames-select-label"
              id="createdByNames-select"
              value={createdBy || ''}
              onChange={e => handleChange('createdBy', e.target.value)}
              variant="outlined"
            >
              {createdByNames &&
                [{ label: 'All', value: null }]
                  .concat(
                    createdByNames.map(name => ({
                      label: name,
                      value: name
                    }))
                  )
                  .map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="severity-select-label">
              Filter by Severity (Behavior Only)
            </InputLabel>
            <Select
              id="severity-select"
              label="Filter by Severity"
              labelId="severity-select-label"
              value={severity || ''}
              onChange={e => handleChange('severity', e.target.value)}
              variant="outlined"
            >
              {[
                { label: 'All', value: null },
                { label: 'Low', value: 'low' },
                { label: 'Medium', value: 'medium' },
                { label: 'High', value: 'high' }
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="hasInterventions-select-label">
              Filter by Has Interventions
            </InputLabel>
            <Select
              label="Filter by Has Interventions"
              id="hasInterventions-select"
              labelId="hasInterventions-select-label"
              value={hasInterventions || ''}
              onChange={e => handleChange('hasInterventions', e.target.value)}
              variant="outlined"
            >
              {[
                { label: 'All', value: null },
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="hasSupports-select-label">
              Filter by Has Supports
            </InputLabel>
            <Select
              label="Filter by Has Supports"
              id="hasSupports-select"
              labelId="hasSupports-select-label"
              value={hasSupports || ''}
              onChange={e => handleChange('hasSupports', e.target.value)}
              variant="outlined"
            >
              {[
                { label: 'All', value: null },
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="hasExternalReferrals-select-label">
              Filter by Has External Referrals
            </InputLabel>
            <Select
              label="Filter by Has External Referrals"
              id="hasExternalReferrals-select"
              labelId="hasExternalReferrals-select-label"
              value={hasExternalReferrals || ''}
              onChange={e =>
                handleChange('hasExternalReferrals', e.target.value)
              }
              variant="outlined"
            >
              {[
                { label: 'All', value: null },
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="hasNotes-select-label">
              Filter by Has Notes
            </InputLabel>
            <Select
              label="Filter by Has Notes"
              id="hasNotes-select"
              labelId="hasNotes-select-label"
              value={hasNotes || ''}
              onChange={e => handleChange('hasNotes', e.target.value)}
              variant="outlined"
            >
              {[
                { label: 'All', value: null },
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="hasDocuments-select-label">
              Filter by Has Documents
            </InputLabel>
            <Select
              label="Filter by Has Documents"
              id="hasDocuments-select"
              labelId="hasDocuments-select-label"
              value={hasDocuments || ''}
              onChange={e => handleChange('hasDocuments', e.target.value)}
              variant="outlined"
            >
              {[
                { label: 'All', value: null },
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '25%' }}>
            <InputLabel id="isHighSecurity-select-label">
              Filter by High Sensitivity
            </InputLabel>
            <Select
              label="Filter by High Sensitivity"
              id="isHighSecurity-select"
              labelId="isHighSecurity-select-label"
              value={isHighSecurity || ''}
              onChange={e => handleChange('isHighSecurity', e.target.value)}
              variant="outlined"
            >
              {[
                { label: 'All', value: null },
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' }
              ].map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: '25%' }}></FormControl>
        </Stack>
      </Box>
      <Box
        sx={{
          mt: 2
        }}
      >
        {hasSelection && (
          <Button variant="contained" color="error" onClick={resetState}>
            Reset Filters
          </Button>
        )}
      </Box>
      <Box
        sx={{
          mt: 6,
          mb: 2
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
        >
          <FormControl fullWidth>
            <TextField
              label="Search"
              variant="outlined"
              id="search"
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </FormControl>
          <WellnessConcernsCsvLink />
        </Stack>
      </Box>
    </FilterContainer>
  )
}

InterventionFilters.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  status: PropTypes.object,
  isRequestingHelp: PropTypes.string,
  createdBy: PropTypes.string,
  severity: PropTypes.string,
  concernTypeId: PropTypes.number,
  concernTypes: PropTypes.array,
  createdByNames: PropTypes.array,
  handleChange: PropTypes.func,
  resetState: PropTypes.func,
  hasInterventions: PropTypes.string,
  hasSupports: PropTypes.string,
  hasExternalReferrals: PropTypes.string,
  hasNotes: PropTypes.string,
  hasDocuments: PropTypes.string,
  isHighSecurity: PropTypes.string,
  ...optionsPropTypes
}

export default withOptions(InterventionFilters)
