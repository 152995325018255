import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Switch from '@mui/material/Switch'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/styles'
import Typography from '@mui/material/Typography'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'

import {
  getTeamUsers,
  createTeamUser,
  updateTeamUser,
  destroyTeamUser
} from 'api/wellness'

export function WellnessTeamForm({ teamId, usersWithAccess }) {
  const theme = useTheme()
  const [selectedUserId, setSelectedUserId] = useState('')

  const queryClient = useQueryClient()
  const queryKey = ['wellness-team', teamId]

  const { data: teamUsers } = useQuery(
    queryKey,
    () => getTeamUsers({ wellness_team_id: teamId }).then(({ data }) => data),
    {
      initialData: [],
      enabled: Boolean(teamId)
    }
  )

  const createMutation = useMutation(
    userId => {
      return createTeamUser({
        user_id: userId,
        wellness_team_id: teamId
      })
    },
    {
      onSuccess: () => {
        setSelectedUserId('')
        queryClient.invalidateQueries(queryKey)
      }
    }
  )

  const updateMutation = useMutation(
    ({ teamUserId, params }) => {
      return updateTeamUser(teamUserId, params)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey)
      }
    }
  )

  const removeMutation = useMutation(destroyTeamUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey)
    }
  })

  const nonUsers = usersWithAccess.filter(
    user => !teamUsers.map(x => x.user_id).includes(user.id)
  )

  function handleCreate(event) {
    event.preventDefault()
    createMutation.mutate(selectedUserId)
  }

  function handleUpdate(teamUserId, params) {
    updateMutation.mutate({ teamUserId, params })
  }

  function handleRemove(teamUserId) {
    removeMutation.mutate(teamUserId)
  }

  function handleSelectUser(event) {
    setSelectedUserId(event.target.value)
  }

  return (
    <Stack>
      <Typography>
        Please select any school site member needing school-wide access to
        various types/levels of concerns. You can also enable or disable email
        notifications for these school site members based on concern type/level.
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="Leadership Team Form Table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell colSpan={9}>
                Manage user email preferences below. If toggled on, users will
                receive an email for new concerns of that type.
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align={'center'}>Academics</TableCell>
              <TableCell align={'center'}>Attendance</TableCell>
              <TableCell align={'center'}>Basic Needs</TableCell>
              <TableCell align={'center'}>Behavior</TableCell>
              <TableCell align={'center'}>Enrollment</TableCell>
              <TableCell align={'center'}>General Health</TableCell>
              <TableCell align={'center'}>Mental Health</TableCell>
              <TableCell align={'center'}>Tech</TableCell>
              <TableCell align={'center'}>High Sensitivity</TableCell>
              <TableCell align={'center'}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamUsers.map(row => (
              <TableRow key={row.id}>
                {/* User and NSSM Membership */}
                <TableCell component="th" scope="row">
                  {row.user.name} {row.is_nssm && ' (SFSS District)'}
                </TableCell>

                {/* Academics */}
                <TableCell align={'center'}>
                  <Switch
                    size={'small'}
                    checked={row.academics}
                    onChange={() =>
                      handleUpdate(row.id, {
                        academics: !row.academics
                      })
                    }
                    inputProps={{ 'aria-label': 'academics checkbox' }}
                  />
                </TableCell>

                {/* Attendance */}
                <TableCell align={'center'}>
                  <Switch
                    size={'small'}
                    checked={row.attendance}
                    onChange={() =>
                      handleUpdate(row.id, {
                        attendance: !row.attendance
                      })
                    }
                    inputProps={{ 'aria-label': 'attendance checkbox' }}
                  />
                </TableCell>

                {/* Basic Needs */}
                <TableCell align={'center'}>
                  <Switch
                    size={'small'}
                    checked={row.basic_needs}
                    onChange={() =>
                      handleUpdate(row.id, {
                        basic_needs: !row.basic_needs
                      })
                    }
                    inputProps={{ 'aria-label': 'basic needs checkbox' }}
                  />
                </TableCell>

                {/* Behavior */}
                <TableCell align={'center'}>
                  <Switch
                    size={'small'}
                    checked={row.behavior}
                    onChange={() =>
                      handleUpdate(row.id, {
                        behavior: !row.behavior
                      })
                    }
                    inputProps={{ 'aria-label': 'behavior checkbox' }}
                  />
                </TableCell>

                {/* Enrollment */}
                <TableCell align={'center'}>
                  <Switch
                    size={'small'}
                    checked={row.enrollment}
                    onChange={() =>
                      handleUpdate(row.id, {
                        enrollment: !row.enrollment
                      })
                    }
                    inputProps={{ 'aria-label': 'enrollment checkbox' }}
                  />
                </TableCell>

                {/* General Health */}
                <TableCell align={'center'}>
                  <Switch
                    size={'small'}
                    checked={row.general_health}
                    onChange={() =>
                      handleUpdate(row.id, {
                        general_health: !row.general_health
                      })
                    }
                    inputProps={{ 'aria-label': 'general_health checkbox' }}
                  />
                </TableCell>

                {/* Mental Health */}
                <TableCell align={'center'}>
                  <Switch
                    size={'small'}
                    checked={row.mental_health}
                    onChange={() =>
                      handleUpdate(row.id, {
                        mental_health: !row.mental_health
                      })
                    }
                    inputProps={{ 'aria-label': 'mental health checkbox' }}
                  />
                </TableCell>

                {/* Tech */}
                <TableCell align={'center'}>
                  <Switch
                    size={'small'}
                    checked={row.tech}
                    onChange={() =>
                      handleUpdate(row.id, {
                        tech: !row.tech
                      })
                    }
                    inputProps={{ 'aria-label': 'tech checkbox' }}
                  />
                </TableCell>

                {/* High Sensitivity */}
                <TableCell align={'center'}>
                  <Switch
                    size={'small'}
                    checked={row.high_sensitivity}
                    onChange={() =>
                      handleUpdate(row.id, {
                        high_sensitivity: !row.high_sensitivity
                      })
                    }
                    inputProps={{ 'aria-label': 'high sensitivity checkbox' }}
                    disabled={row.is_nssm}
                  />
                </TableCell>

                {/* Actions */}
                <TableCell align={'center'}>
                  {!row.is_nssm && (
                    <IconButton
                      onClick={() => handleRemove(row.id)}
                      style={{ color: theme.palette.error.main }}
                      aria-label="remove"
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row">
        <FormControl fullWidth>
          <InputLabel id="school-user-select-label">School User</InputLabel>
          <Select
            labelId="school-user-select-label"
            id="school-user-select"
            value={selectedUserId}
            onChange={handleSelectUser}
            input={<OutlinedInput label="School User" />}
          >
            <MenuItem key={''} value={''}>
              &nbsp;
            </MenuItem>
            {nonUsers.map(user => (
              <MenuItem key={user.id} value={user.id}>
                {user.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <Button
            variant="contained"
            onClick={handleCreate}
            disabled={!selectedUserId}
            sx={{ height: '100%' }}
          >
            Add&nbsp;User
          </Button>
        </FormControl>
      </Stack>
    </Stack>
  )
}

WellnessTeamForm.propTypes = {
  teamId: PropTypes.number.isRequired,
  usersWithAccess: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  )
}

export default WellnessTeamForm
