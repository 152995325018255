import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ObservationForm from './observation-form'
// import ObservationView from './observation-view'

// Material
import Button from '@mui/material/Button'
import Dialog from 'components/shared/dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'

export function ObservationDialog({
  isViewOnly,
  observation,
  observerId,
  teacher,
  onClose,
  ...props
}) {
  const formRef = useRef()
  const [submitText, setSubmitText] = useState('Submit')

  function handleCancel(event) {
    formRef.current.cancel(event)
  }

  function handleDelete(event) {
    formRef.current.delete(event)
  }

  function handleSubmit(event, isDraft) {
    formRef.current.submit(event, isDraft)
  }

  function onCancel() {
    onClose()
  }

  function onDelete() {
    onClose({ invalidate: true })
  }

  function onSubmit() {
    onClose({ invalidate: true })
  }

  // Render
  if (isViewOnly) {
    return (
      observation && (
        <Dialog title={'Observation Touchpoint'} onClose={onClose} {...props}>
          <DialogContent>
            {/* <ObservationView teacher={teacher} observation={observation} /> */}
            <ObservationForm
              ref={formRef}
              disabled={true}
              {...{
                observation,
                observerId,
                teacher,
                setSubmitText
              }}
            />
          </DialogContent>

          <DialogActions>
            <Grid container justifyContent={'flex-end'}>
              <Grid item>
                <Button
                  color="primary"
                  onClick={onClose}
                  style={{ marginRight: '10px' }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )
    )
  } else {
    return (
      <Dialog
        title={`${observation ? 'Edit' : 'New'} Observation Touchpoint`}
        onClose={onClose}
        {...props}
      >
        <DialogContent>
          <ObservationForm
            ref={formRef}
            {...{
              observation,
              observerId,
              teacher,
              onCancel,
              onDelete,
              onSubmit,
              setSubmitText
            }}
          />
        </DialogContent>

        <DialogActions>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              {observation && observation.observer_id === observerId && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDelete}
                >
                  Delete
                </Button>
              )}
            </Grid>
            <Grid item>
              <Button
                color="primary"
                onClick={e => handleSubmit(e, true)}
                style={{ marginRight: '10px' }}
              >
                Save as Draft
              </Button>
              <Button
                color="primary"
                onClick={handleCancel}
                style={{ marginRight: '10px' }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={e => handleSubmit(e, false)}
              >
                {submitText}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }
}

ObservationDialog.propTypes = {
  observation: PropTypes.object,
  observerId: PropTypes.number.isRequired,
  teacher: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  isViewOnly: PropTypes.bool.isRequired
}

export default ObservationDialog
