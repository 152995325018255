import ExportsApi from './api'
import { types } from './reducer'

export const createReport = params => {
  return dispatch => {
    return ExportsApi.createReport(params).then(data => {
      dispatch({
        type: types.CREATE_REPORT,
        payload: data
      })
    })
  }
}

export const deleteReport = reportId => {
  return dispatch => {
    return ExportsApi.destroyReport(reportId).then(data => {
      dispatch({
        type: types.DELETE_REPORT,
        payload: {
          reportId: data.report_id
        }
      })
      return Promise.resolve()
    })
  }
}

export const fetchReports = params => {
  return dispatch => {
    return ExportsApi.getReports(params).then(data => {
      dispatch({ type: types.FETCH_REPORTS, payload: data })
    })
  }
}

export const fetchStudents = params => {
  return dispatch => {
    return ExportsApi.getStudents(params).then(data => {
      dispatch({ type: types.FETCH_STUDENTS, payload: data })
    })
  }
}

export const setSelectedStudents = students => {
  return dispatch => {
    dispatch({ type: types.SET_STUDENTS_LIST, payload: students })
    return Promise.resolve()
  }
}
