import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { mapLocalizableObjectsToOptions } from 'utils/select-option-helpers'
import {
  getActivities,
  getSchoolActivities,
  createSchoolActivity,
  updateSchoolActivity,
  deleteSchoolActivity
} from '../../api/activities'
import { isNull } from 'lodash'
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material'
import { Cancel, Check, Delete, Edit } from '@mui/icons-material'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import ConfirmModalButton from 'components/shared/confirm-modal/button'

const LOCALIZATION_LANGUAGES = ['es']

const CATEGORIES = {
  academic: 'Academic',
  athletic: 'Athletic',
  extracurricular: 'Extracurricular'
}
const categoryOptions = Object.entries(CATEGORIES).map(([value, label]) => ({
  label,
  value
}))

function activitySorter(locale = 'en', options = {}) {
  return (a, b) => a.name[locale].localeCompare(b.name[locale], locale, options)
}

function Activities({ schoolId }) {
  const [activityID, setActivityID] = useState(null)
  const [category, setCategory] = useState(null)
  const [editedSchoolActivity, setEditedSchoolActivity] = useState(null)

  const queryClient = useQueryClient()
  const { data: activities } = useQuery(
    ['activities'],
    () => getActivities().then(res => res.data),
    { initialData: [] }
  )
  const { data: schoolActivities } = useQuery(
    ['school-activities', schoolId],
    () =>
      getSchoolActivities(schoolId).then(res =>
        res.data.sort(activitySorter())
      ),
    { initialData: [] }
  )

  const handleCreate = () => {
    if (activityID && category) {
      const params = {
        school_id: schoolId,
        activity_id: activityID.value,
        category: category.value
      }
      createSchoolActivity(params).then(() => {
        queryClient.invalidateQueries({
          queryKey: ['school-activities', schoolId]
        })
        setActivityID(null)
        setCategory(null)
      })
    }
  }

  const resetEditedOptions = () => {
    setEditedSchoolActivity(null)
  }

  const handleUpdate = () => {
    updateSchoolActivity(
      editedSchoolActivity.id,
      editedSchoolActivity.category
    ).then(_schoolActivity => {
      queryClient.invalidateQueries({
        queryKey: ['school-activities', schoolId]
      })
      resetEditedOptions()
    })
  }

  const handleDelete = id => {
    deleteSchoolActivity(id).then(() => {
      queryClient.invalidateQueries({
        queryKey: ['school-activities', schoolId]
      })
    })
  }

  return (
    <Box>
      <Typography variant="h3" mb={5}>
        Activities
      </Typography>
      <form
        onSubmit={e => {
          e.preventDefault()
          handleCreate()
        }}
      >
        <Stack
          direction="column"
          alignItems="flex-start"
          spacing={3}
          sx={{ maxWidth: 'sm' }}
        >
          <Box
            sx={{
              width: '100%'
            }}
          >
            <Autocomplete
              value={activityID}
              onChange={(event, newValue) => {
                setActivityID(newValue)
              }}
              options={mapLocalizableObjectsToOptions(
                activities.filter(
                  activity =>
                    !schoolActivities.some(
                      schoolActivity =>
                        schoolActivity.activity_id === activity.id
                    )
                ),
                'name',
                'id'
              )}
              getOptionLabel={option => option.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select or type an activity"
                  variant="outlined"
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />
          </Box>
          <Box
            sx={{
              width: '100%'
            }}
          >
            <Autocomplete
              value={category}
              onChange={(event, newValue) => {
                setCategory(newValue)
              }}
              options={categoryOptions}
              getOptionLabel={option => option.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select or type a category"
                  variant="outlined"
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />
          </Box>
          <Box
            sx={{
              width: '100%'
            }}
          >
            <Button
              type="submit"
              color="primary"
              disabled={isNull(activityID) || isNull(category)}
              variant="contained"
            >
              Add
            </Button>
          </Box>
        </Stack>
      </form>
      {schoolActivities && (
        <Box
          sx={{
            mt: 2
          }}
        >
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Activity (en)</TableCell>
                  {LOCALIZATION_LANGUAGES.map((language, i) => (
                    <TableCell key={i}>
                      Activity Translation ({language})
                    </TableCell>
                  ))}
                  <TableCell>Category</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {schoolActivities.map(schoolActivity => (
                  <TableRow key={schoolActivity.id}>
                    <TableCell>{schoolActivity.name.en}</TableCell>
                    {LOCALIZATION_LANGUAGES.map(language => (
                      <TableCell key={language}>
                        {schoolActivity.name[language]}
                      </TableCell>
                    ))}
                    <TableCell>
                      {editedSchoolActivity?.id === schoolActivity.id ? (
                        <Stack
                          component="form"
                          direction="column"
                          alignItems="flex-start"
                          sx={{ maxWidth: 'sm' }}
                        >
                          <Select
                            onChange={e => {
                              setEditedSchoolActivity(x => ({
                                ...x,
                                category: e.target.value
                              }))
                            }}
                            value={
                              editedSchoolActivity.category ||
                              schoolActivity.category
                            }
                            placeholder="Click or type here to select a category"
                          >
                            {categoryOptions.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <IconButton
                            title={'Update'}
                            onClick={() => handleUpdate()}
                            color="primary"
                            variant="outlined"
                          >
                            <Check />
                          </IconButton>
                          <IconButton
                            title={'Cancel'}
                            onClick={() => resetEditedOptions()}
                            color="secondary"
                            variant="outlined"
                          >
                            <Cancel />
                          </IconButton>
                        </Stack>
                      ) : (
                        CATEGORIES[schoolActivity.category] ||
                        schoolActivity.category
                      )}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={2}>
                        <Button
                          size="small"
                          title={'Edit'}
                          onClick={() => {
                            setEditedSchoolActivity(schoolActivity)
                          }}
                          color="secondary"
                          variant="outlined"
                          startIcon={<Edit />}
                        >
                          Edit
                        </Button>
                        <ConfirmModalButton
                          confirmTitle={`Remove ${schoolActivity.category} activity “${schoolActivity.name.en}”`}
                          modalMessage={
                            'Are you sure you want to remove this activity?'
                          }
                          size="small"
                          color="error"
                          showCancelButton={true}
                          cancelButtonText="No"
                          onConfirm={() => handleDelete(schoolActivity.id)}
                          startIcon={<Delete />}
                          variant="outlined"
                        >
                          Delete
                        </ConfirmModalButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  )
}

Activities.propTypes = {
  schoolId: PropTypes.number.isRequired
}

export default Activities
