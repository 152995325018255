import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField
} from '@mui/material'
import MoreOptionsPopover from './more-options-popover'
import RemoveIcon from '@mui/icons-material/Remove'
import { compact } from 'lodash'
export default class DatasetFilters extends React.Component {
  state = {
    anchorEl: null
  }

  hasCohorts = () =>
    this.props.cohortOptions && this.props.cohortOptions.length > 0

  isHighSchool = () => this.props.school && this.props.school.high

  checkboxToggled = property => {
    this.props.filterChanged(property, { value: !this.state[property] })
  }

  checkboxChanged = (property, values) => {
    this.props.filterChanged(property, { value: values })
  }

  radioChanged = (property, value) => {
    this.props.filterChanged(property, { value: value })
  }

  multiselectChanged = (property, values) => {
    let list = values.map(val => val.value)
    this.props.filterChanged(property, { value: list })
  }

  moreOptionsList = () => {
    const { gender, ells, gifted, sped, socioeconomicallyDisadvantaged } =
      this.props
    const options = []

    if (gender === 'M') {
      options.push('Male')
    } else if (gender === 'F') {
      options.push('Female')
    }

    if (ells.length === 1 || typeof ells === 'string') {
      const option = typeof ells === 'string' ? ells : ells[0]

      switch (option) {
        case 'Yes':
          options.push('ML')
          break
        case 'No':
          options.push('Not ML')
          break
        case 'Former':
          options.push('Monitored/Exited ML')
          break
      }
    } else {
      if (ells.includes('Yes') && ells.includes('Former')) {
        options.push('Current ML or Monitored/Exited')
      } else if (ells.includes('No') && ells.includes('Former')) {
        options.push('Not Current ML')
      } else if (ells.includes('Yes') && ells.includes('No')) {
        options.push('Not Monitored/Exited')
      }
    }

    if (gifted) {
      options.push('Gifted')
    } else if (gifted === false) {
      options.push('Not Gifted')
    }

    if (sped) {
      options.push('Sped')
    } else if (sped === false) {
      options.push('Not Sped')
    }

    if (socioeconomicallyDisadvantaged) {
      options.push('Disadvantaged')
    } else if (socioeconomicallyDisadvantaged === false) {
      options.push('Not Disadvantaged')
    }

    return options
  }

  handleOptionDelete = option => {
    const { filterChanged } = this.props
    switch (option) {
      case 'Male':
      case 'Female':
        filterChanged('gender', { value: null })
        break
      case 'ML':
      case 'Not ML':
      case 'Monitored/Exited ML':
        filterChanged('ells', { value: [] })
        break
      case 'Gifted':
      case 'Not Gifted':
        filterChanged('gifted', { value: null })
        break
      case 'Sped':
      case 'Not Sped':
        filterChanged('sped', { value: null })
        break
      case 'Disadvantaged':
      case 'Not Disadvantaged':
        filterChanged('socioeconomicallyDisadvantaged', { value: null })
        break
      default:
        console.warn('Unknown option', option)
    }
  }

  genderOptions() {
    return ['M', 'F'].map(o => ({ label: o, value: o }))
  }

  cohortOptions() {
    const { cohortOptions, schoolYear } = this.props
    // add the grade before the cohort year, eg "2018" => "12th Grade (2018)"
    return compact(
      cohortOptions.map(option => {
        const yearDif = option.value - schoolYear
        let grade = ''
        const grades = [
          '12th',
          '11th',
          '10th',
          '9th',
          '8th',
          '7th',
          '6th',
          '5th',
          '4th',
          '3rd',
          '2nd',
          '1st'
        ]
        if (schoolYear > option.value) {
          return null
        } else if (yearDif >= 0 && yearDif < grades.length) {
          grade = grades[yearDif]
        }

        return { ...option, label: `${grade} Grade (${option.label})` }
      })
    )
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const {
      ethnicityOptions,
      gradeLevelOptions,
      schoolYearOptions,
      schoolYear,
      ethnicities,
      gradeLevels,
      ells,
      cohorts,
      gender,
      gifted,
      sped,
      socioeconomicallyDisadvantaged,
      filterChanged,
      removeDataset,
      removable
    } = this.props

    const moreOptions = this.moreOptionsList()

    return (
      <Paper sx={{ p: 4 }}>
        <Box id="test-dataset-filters">
          <Box
            id="form-set-actions"
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mb: 2,
              visibility: removable ? 'visible' : 'hidden'
            }}
          >
            <Button
              variant="contained"
              color="error"
              id="js-remove-subset"
              onClick={removeDataset}
            >
              <RemoveIcon />
            </Button>
          </Box>

          <Box sx={{ mb: 3 }}>
            <FormControl fullWidth required>
              <InputLabel>School Year</InputLabel>
              <Select
                labelId="school-year-select-label"
                id="school-year-select"
                value={schoolYear || ''}
                label="School Year"
                onChange={event =>
                  filterChanged('schoolYear', { value: event.target.value })
                }
              >
                {schoolYearOptions.map(option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ mb: 3 }}>
            <Autocomplete
              id="test-select-ethnicity"
              multiple
              value={ethnicityOptions.filter(option =>
                ethnicities.includes(option.value)
              )}
              options={ethnicityOptions}
              getOptionLabel={option => option.label}
              onChange={(event, newValue) =>
                this.multiselectChanged('ethnicities', newValue)
              }
              renderInput={params => (
                <TextField {...params} label="Ethnicity" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.value}
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Box>

          {this.hasCohorts() && (
            <Box sx={{ mb: 3 }}>
              <Autocomplete
                id="cohort-select"
                multiple
                value={this.cohortOptions().filter(option =>
                  cohorts.includes(option.value)
                )}
                options={this.cohortOptions()}
                getOptionLabel={option => option.label}
                onChange={(event, newValue) =>
                  this.multiselectChanged('cohorts', newValue)
                }
                renderInput={params => <TextField {...params} label="Cohort" />}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
              />
            </Box>
          )}

          {!this.isHighSchool() && (
            <Box sx={{ mb: 3 }}>
              <Autocomplete
                id="grade-level-select"
                multiple
                value={gradeLevelOptions.filter(option =>
                  gradeLevels.includes(option.value)
                )}
                options={gradeLevelOptions}
                getOptionLabel={option => option.label}
                onChange={(event, newValue) =>
                  this.multiselectChanged('gradeLevels', newValue)
                }
                renderInput={params => (
                  <TextField {...params} label="Grade Level" />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
              />
            </Box>
          )}

          <Box>
            <Button
              aria-describedby={
                this.state.anchorEl ? 'popover-positioned-bottom' : null
              }
              onClick={this.handleClick}
              variant="contained"
            >
              Select Options...
            </Button>
            <MoreOptionsPopover
              id={this.state.anchorEl ? 'popover-positioned-bottom' : null}
              open={Boolean(this.state.anchorEl)}
              anchorEl={this.state.anchorEl}
              handleClose={this.handleClose}
              radioChanged={this.radioChanged}
              checkboxChanged={this.checkboxChanged}
              ells={ells}
              gifted={gifted}
              gender={gender}
              sped={sped}
              socioeconomicallyDisadvantaged={socioeconomicallyDisadvantaged}
            />
            <Stack direction="row" spacing={1} flexWrap="wrap" mt={1}>
              {moreOptions.map((option, index) => (
                <Chip
                  key={index}
                  label={option}
                  size="small"
                  color="primary"
                  variant="outlined"
                  onDelete={() => this.handleOptionDelete(option)}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      </Paper>
    )
  }
}

DatasetFilters.propTypes = {
  school: PropTypes.object,
  cohortOptions: PropTypes.array,
  schoolYearOptions: PropTypes.array,
  ethnicityOptions: PropTypes.array.isRequired,
  ellOptions: PropTypes.array,
  gradeLevelOptions: PropTypes.array,
  schoolYear: PropTypes.number,
  schoolId: PropTypes.number,
  ethnicities: PropTypes.array,
  gradeLevels: PropTypes.array,
  ells: PropTypes.array,
  cohorts: PropTypes.array,
  gender: PropTypes.string,
  gifted: PropTypes.bool,
  sped: PropTypes.bool,
  socioeconomicallyDisadvantaged: PropTypes.bool,
  filterChanged: PropTypes.func,
  removeDataset: PropTypes.func,
  duplicateDataset: PropTypes.func,
  removable: PropTypes.bool
}
