import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PageTitle from 'components/shared/page-title'
import FilterContainer from 'components/shared/filter-container'
import MapChart from './chart'
import MapTable from './table'
import { currentSchoolYear } from 'utils'
import SectionsSelect from 'shared/filters/sections-select'
import YearSelect from './filters/year-select'
import TestingWindowSelect from './filters/testing-window-select'
import { useTeacher } from 'hooks/useTeacher'
import AbsoluteSpinner from 'components/shared/spinner/absolute'

function Map({ sectionId, sectionOptions, sectionChanged, teacherId }) {
  const [year, setYear] = useState(currentSchoolYear)
  const [startTerm, setStartTerm] = useState('fall')
  const [endTerm, setEndTerm] = useState('winter')

  const params = {
    year: year,
    start_term: startTerm,
    end_term: endTerm,
    section_id: sectionId === 0 ? '' : sectionId,
    include_grade_levels: true
  }
  const { isSuccess, data } = useTeacher(teacherId, params)

  return isSuccess ? (
    <React.Fragment>
      <FilterContainer topBorder={false}>
        <YearSelect year={year} setYear={setYear} />
        <TestingWindowSelect
          testingWindow={startTerm}
          setTestingWindow={setStartTerm}
          label="Start"
          includedTerms={['fall', 'winter']}
        />
        <TestingWindowSelect
          testingWindow={endTerm}
          setTestingWindow={setEndTerm}
          label="End"
          includedTerms={['winter', 'spring']}
        />
        <SectionsSelect
          section={sectionId}
          sectionChanged={sectionChanged}
          sectionOptions={sectionOptions}
        />
      </FilterContainer>

      <PageTitle title={'MAP Scores'} />
      <MapChart chartData={data.teacher.student_map_chart_data} />

      <MapTable sectionId={sectionId} teacher={data.teacher} year={year} />
    </React.Fragment>
  ) : (
    <AbsoluteSpinner />
  )
}

Map.propTypes = {
  sectionId: PropTypes.number,
  sectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number
    })
  ).isRequired,
  sectionChanged: PropTypes.func.isRequired,
  teacherId: PropTypes.number.isRequired
}

export default Map
