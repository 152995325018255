import { sortBy, flatMap, values } from 'lodash'

const sectionsForCurrentSchool = ({ schools, sections }) => {
  if (schools.schoolId) {
    return sections.bySchoolId[schools.schoolId] || []
  }
  //All schools being returned if no schoolId is passed
  return sortBy(flatMap(values(sections.bySchoolId)), 'teacher_last_name')
}

export default {
  sectionsForCurrentSchool
}
