import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function MultipleGradeScales({ gradeScale, setGradeScale }) {
  const gradeScaleOptions = [
    { value: 'af', label: 'A-F' },
    { value: 'eu', label: 'E-U' }
  ]

  return (
    <FormControl variant="outlined" sx={{ m: 1 }}>
      <InputLabel id="grade-scale-select-label">Scale</InputLabel>
      <Select
        labelId="grade-scale-select-label"
        id="grade-scale-select"
        value={gradeScale}
        label="Group By"
        onChange={e => setGradeScale(e.target.value)}
      >
        {gradeScaleOptions.map(g => (
          <MenuItem key={`grade-scale-option-${g.value}`} value={g.value}>
            {g.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

MultipleGradeScales.propTypes = {
  gradeScale: PropTypes.string.isRequired,
  setGradeScale: PropTypes.func.isRequired
}

export default MultipleGradeScales
