import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import ReferralDialog from './dialog'
import { useChildStudyReferral } from 'hooks/useChildStudyReferral'

function ReferralButton({ childStudyId, schoolId, studentId }) {
  const [dialogOpen, setDialogOpen] = useState(false)
  const { data: childStudyReferral } = useChildStudyReferral({
    childStudyId: childStudyId,
    studentId: studentId,
    options: {
      initialData: {}
    }
  })

  const handleOpen = () => {
    setDialogOpen(true)
  }

  const handleClose = () => {
    setDialogOpen(false)
  }

  return Object.keys(childStudyReferral).length > 0 ? (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        disabled={!childStudyReferral}
        sx={{ marginLeft: '0.5em' }}
      >
        See Referral
      </Button>
      <ReferralDialog
        childStudyReferral={childStudyReferral}
        onHide={handleClose}
        open={dialogOpen}
        schoolId={schoolId}
        studentId={studentId}
      />
    </React.Fragment>
  ) : null
}

ReferralButton.propTypes = {
  childStudyId: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired,
  studentId: PropTypes.number.isRequired
}

export default ReferralButton
