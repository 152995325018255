import React from 'react'
import { Box } from '@mui/material'
import { connect } from 'react-redux'

const ConditionallyRenderForRoles = ({
  user,
  roles = ['district'],
  children
}) => {
  return roles.includes(user.role) ? <Box>{children}</Box> : <></>
}

const mapStateToProps = state => ({
  user: state.currentUser.user
})

export default connect(mapStateToProps)(ConditionallyRenderForRoles)
