import GoalsApi from './api'
import { types } from './reducer'

export const fetchTableData = params => {
  return dispatch => {
    return GoalsApi.getGoals(params).then(data => {
      dispatch({ type: types.FETCH_GOALS, payload: data })
    })
  }
}

export const setActiveGoal = goal => {
  return dispatch => {
    dispatch({ type: types.SET_GOAL, payload: goal })
  }
}

export const updateGoal = params => {
  return dispatch => {
    return GoalsApi.updateGoal(params).then(data => {
      dispatch({ type: types.UPDATE_GOAL, payload: data })
    })
  }
}
