import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const ChartContainer = ({ children }) => (
  <Box sx={{ mt: 2, p: 2 }}>{children}</Box>
)

ChartContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default ChartContainer
