import React from 'react'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Grid,
  Divider,
  FormControl,
  Stack,
  Typography,
  Paper
} from '@mui/material'
import {
  FormSelect,
  FormHiddenInput,
  FormTextInput,
  FormTextAreaInput,
  FormCheckbox,
  FormDatePicker,
  FormDateTimePicker,
  setLocale
} from 'shared/forms/formik'
import initFormValues from 'shared/forms/initFormValues'
import { getMentalWellnessAgencies } from 'api/confidential-crisis-forms'
import { getMentalWellnessReferrals } from 'api/MentalWellnessReferral'
import { useQuery } from '@tanstack/react-query'
import { handleRailsRequestJsResponse } from '../../utils/handleRailsRequestJsResponse'
import { isArray } from 'lodash'
import { MentalWellnessReferralForm } from '../mental_wellness_referrals/MentalWellnessReferralForm'

const stringAttributes = [
  'student_number',
  'student_id',
  'school_id',
  'student_name',
  'school_name',
  'grade_level',
  'student_guardian_name',
  'student_guardian_relationship',
  'student_guardian_phone',
  'student_guardian_email',
  'reporter_name',
  'reporter_email',
  'reporter_title',
  'reporter_phone',
  'other_personnel_involved',
  'titles_of_other_personnel_involved',
  'primary_concern',
  'current_reason_for_referral',
  'previous_concerns_for_student',
  'student_has_current_iep',
  'student_has_section_504_plan',
  'student_mh_provider_name',
  'student_mh_provider_agency',
  'student_mh_provider_phone',
  'student_mh_provider_email',
  'mental_wellness_agency_id',
  'guardian_signature',
  'guardian_agrees_to_mh_referral',
  'guardian_agrees_to_mh_referral_signature',
  'dhs_representative',
  'dhs_referral_number',
  'dhs_student_currently_in_custody',
  'dhs_caseworker_name',
  'dhs_caseworker_phone',
  'dhs_caseworker_email',
  'weapon_involved',
  'weapon_description',
  'contacted_campus_police',
  'contacted_911',
  'submitted_tps_weapons_report',
  'success_plan_created',
  'osde_student_entry_plan_completed',
  'copes_consultant_name',
  'copes_recommendations',
  'additional_information',
  'school_leader_signature',
  'has_mh_provider',
  'has_contacted_copes',
  'is_draft',
  'threat_assessment_completed'
]

const dateAttributes = [
  'form_dated_on',
  'student_birthday',
  'student_mh_immediate_referral_date',
  'student_guardian_contacted_date',
  'guardian_agrees_to_mh_referral_signature_date',
  'dhs_referral_date',
  'osde_student_entry_plan_expected_completion',
  'copes_date_of_consult',
  'school_leader_signature_date'
]

const CrisisForm = ({ prefill = {}, onSubmitValidated, disabled }) => {
  setLocale()

  const initialValues = initFormValues(prefill, {
    stringAttributes,
    dateAttributes
  })

  const validationSchema = Yup.object({
    student_name: Yup.string().required('Required'),
    school_name: Yup.string().required('Required'),
    form_dated_on: Yup.date().required('Required'),
    primary_concern: Yup.string().required('Required')
  })

  const { data: mentalWellnessAgencies } = useQuery(
    ['mental-wellness-agencies'],
    async () => {
      const response = await getMentalWellnessAgencies()
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  const optionsForAgencies = isArray(mentalWellnessAgencies)
    ? mentalWellnessAgencies.map(obj => ({
        label: obj.name,
        value: obj.id
      }))
    : []

  const { data: referrals } = useQuery(
    ['mental-wellness-referrals', prefill?.student_number],
    async () => {
      const response = await getMentalWellnessReferrals({
        search: prefill?.student_number
      })
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: [],
      refetchOnWindowFocus: true,
      enabled: prefill?.student_number ? true : false
    }
  )

  const needsReferralForm = referrals.length > 0 ? false : true

  const openReferralLinkNew = student_id => {
    const link = `/mental_wellness_referrals/new?student_id=${student_id}&closeAfterSubmit=true`
    window.open(link, '_blank')
  }

  const openReferralLinkEdit = referral_id => {
    const link = `/mental_wellness_referrals/${referral_id}/edit?closeAfterSubmit=true`
    window.open(link, '_blank')
  }

  const optionsYesOrNo = [
    { label: 'No', value: 'No' },
    { label: 'Yes', value: 'Yes' }
  ]

  const optionsPrimaryConcern = [
    { label: 'Active Psychotic Features', value: 'Active Psychotic Features' },
    { label: 'Threat of or Self Harm', value: 'Threat of or Self Harm' },
    { label: 'Threat or Harm to Others', value: 'Threat or Harm to Others' }
  ]

  const AgencyInfo = ({ agencyId }) => {
    const agency = mentalWellnessAgencies.filter(obj => obj.id == agencyId)[0]

    return (
      <>
        <strong>
          <div>{agency?.name}</div>
          <div>{agency?.phone}</div>
          <div>{agency?.email}</div>
          {agency?.website && (
            <div>
              <a href={agency.website} target="_blank" rel="noreferrer">
                {agency.website}
              </a>
            </div>
          )}
        </strong>
      </>
    )
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        onSubmitValidated(values)
        setSubmitting(false)
      }}
    >
      {({ values }) => (
        <Form>
          <Box
            sx={{
              pt: 4
            }}
          >
            <Box
              disabled={disabled}
              component="fieldset"
              sx={{ border: 'none' }}
            >
              <FormHiddenInput name="school_id" />
              <FormHiddenInput name="student_id" />
              <Stack spacing={2}>
                <Box>
                  <Grid container spacing={4}>
                    <Grid item xs={12} sm={8}>
                      <Stack>
                        <Stack direction="row" spacing={2}>
                          <FormControl fullWidth>
                            <FormTextInput
                              name="student_name"
                              label={I18n.t('labels.student')}
                              disabled={true}
                            />
                          </FormControl>
                          <FormControl fullWidth>
                            <FormTextInput
                              name="student_number"
                              label={I18n.t('labels.student_number')}
                              disabled={true}
                            />
                          </FormControl>
                        </Stack>

                        <FormTextInput
                          name="school_name"
                          label={I18n.t('labels.school')}
                          disabled={true}
                        />

                        <FormTextInput
                          name="grade_level"
                          label={I18n.t('labels.grade_level')}
                          disabled={true}
                        />

                        <FormDatePicker
                          name="student_birthday"
                          label={I18n.t('confidential_crisis.student_birthday')}
                          readOnly={disabled}
                          disabled={true}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Stack>
                        <FormTextInput
                          name="student_guardian_name"
                          label={I18n.t(
                            'confidential_crisis.student_guardian_name'
                          )}
                        />

                        <FormTextInput
                          name="student_guardian_phone"
                          label={I18n.t(
                            'confidential_crisis.student_guardian_phone'
                          )}
                        />

                        <FormTextInput
                          name="student_guardian_email"
                          label={I18n.t(
                            'confidential_crisis.student_guardian_email'
                          )}
                        />

                        <FormTextInput
                          name="student_guardian_relationship"
                          label={I18n.t(
                            'confidential_crisis.relationship_to_student'
                          )}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>

                <Divider />

                <FormDatePicker
                  name="form_dated_on"
                  label={I18n.t('labels.date')}
                  readOnly={disabled}
                />
                <FormDatePicker
                  name="student_guardian_contacted_date"
                  label={I18n.t(
                    'confidential_crisis.student_guardian_contacted_date'
                  )}
                  readOnly={disabled}
                />

                <FormTextInput
                  name="reporter_name"
                  label={I18n.t('confidential_crisis.reporter_name')}
                />

                <FormTextInput
                  name="reporter_email"
                  label={I18n.t('confidential_crisis.reporter_email')}
                />

                <FormTextInput
                  name="reporter_title"
                  label={I18n.t('confidential_crisis.reporter_title')}
                />

                <FormTextInput
                  name="reporter_phone"
                  label={I18n.t('confidential_crisis.reporter_phone')}
                />

                <FormTextAreaInput
                  name="other_personnel_involved"
                  label={I18n.t('confidential_crisis.other_personnel_involved')}
                />

                <FormTextAreaInput
                  name="titles_of_other_personnel_involved"
                  label={I18n.t(
                    'confidential_crisis.titles_of_other_personnel_involved'
                  )}
                />

                <Box>
                  <Divider />
                </Box>

                <FormSelect
                  readOnly={disabled}
                  items={optionsPrimaryConcern}
                  label={I18n.t('confidential_crisis.primary_concern')}
                  name="primary_concern"
                />

                <FormTextAreaInput
                  name="current_reason_for_referral"
                  label={I18n.t(
                    'confidential_crisis.current_reason_for_referral'
                  )}
                />

                <FormTextAreaInput
                  name="previous_concerns_for_student"
                  label={I18n.t(
                    'confidential_crisis.previous_concerns_for_student'
                  )}
                />

                <FormSelect
                  readOnly={disabled}
                  items={optionsYesOrNo}
                  label={I18n.t('confidential_crisis.has_mh_provider')}
                  name="has_mh_provider"
                />

                {values.has_mh_provider === 'Yes' && (
                  <>
                    <Typography>
                      * The family should be assisted in contacting the provider
                      for an assessment.
                    </Typography>
                    <FormTextInput
                      name="student_mh_provider_name"
                      label={I18n.t(
                        'confidential_crisis.student_mh_provider_name'
                      )}
                    />

                    <FormTextInput
                      name="student_mh_provider_agency"
                      label={I18n.t(
                        'confidential_crisis.student_mh_provider_agency'
                      )}
                    />

                    <FormTextInput
                      name="student_mh_provider_phone"
                      label={I18n.t(
                        'confidential_crisis.student_mh_provider_phone'
                      )}
                    />

                    <FormTextInput
                      name="student_mh_provider_email"
                      label={I18n.t(
                        'confidential_crisis.student_mh_provider_email'
                      )}
                    />
                  </>
                )}

                <FormCheckbox name="guardian_agrees_to_mh_referral">
                  {I18n.t('confidential_crisis.guardian_agrees_to_mh_referral')}
                </FormCheckbox>

                {values.has_mh_provider === 'No' &&
                  values.guardian_agrees_to_mh_referral && (
                    <>
                      <FormSelect
                        readOnly={disabled}
                        items={optionsForAgencies}
                        label={I18n.t(
                          'confidential_crisis.student_mh_immediate_referral_name'
                        )}
                        name="mental_wellness_agency_id"
                      />
                      {values.mental_wellness_agency_id && (
                        <AgencyInfo
                          agencyId={values.mental_wellness_agency_id}
                        />
                      )}

                      <FormDateTimePicker
                        name="student_mh_immediate_referral_date"
                        label={I18n.t(
                          'confidential_crisis.student_mh_immediate_referral_date'
                        )}
                        readOnly={disabled}
                      />

                      <Typography variant="h6">
                        I understand that my student has been provided an
                        immediate referral for a free mental health assessment
                        and I agree to accept this referral for my student.
                      </Typography>

                      <FormTextInput
                        name="guardian_signature"
                        label={I18n.t('labels.guardian_signature')}
                      />

                      <Typography variant="h6">
                        I understand that my student has been provided an
                        immediate referral for a free mental health assessment
                        due to safety concerns.
                      </Typography>

                      <FormTextInput
                        name="guardian_agrees_to_mh_referral_signature"
                        label={I18n.t(
                          'confidential_crisis.guardian_agrees_to_mh_referral_signature'
                        )}
                      />

                      <FormDatePicker
                        name="guardian_agrees_to_mh_referral_signature_date"
                        label={I18n.t(
                          'confidential_crisis.guardian_agrees_to_mh_referral_signature_date'
                        )}
                        readOnly={disabled}
                      />

                      {needsReferralForm == true && (
                        <Button
                          variant="contained"
                          onClick={() => openReferralLinkNew(values.student_id)}
                        >
                          New Mentall Wellness Referral
                        </Button>
                      )}

                      {needsReferralForm == false &&
                        referrals.map(referral => (
                          <Paper sx={{ p: 4 }} key={referral.id}>
                            <MentalWellnessReferralForm
                              key={referral.updated_at}
                              prefill={referral}
                              disabled={true}
                              hideStudentAndGuardianInfo={true}
                            />
                            <Button
                              sx={{ my: 4 }}
                              variant="contained"
                              onClick={() => openReferralLinkEdit(referral.id)}
                            >
                              Edit
                            </Button>
                          </Paper>
                        ))}
                    </>
                  )}

                {values.has_mh_provider === 'No' &&
                  !values.guardian_agrees_to_mh_referral && (
                    <>
                      <FormTextInput
                        name="dhs_representative"
                        label={I18n.t('confidential_crisis.dhs_representative')}
                      />
                      <FormDateTimePicker
                        name="dhs_referral_date"
                        label={I18n.t('confidential_crisis.dhs_referral_date')}
                        readOnly={disabled}
                      />
                      <FormTextInput
                        name="dhs_referral_number"
                        label={I18n.t(
                          'confidential_crisis.dhs_referral_number'
                        )}
                      />
                      <FormCheckbox name="dhs_student_currently_in_custody">
                        {I18n.t(
                          'confidential_crisis.dhs_student_currently_in_custody'
                        )}
                      </FormCheckbox>
                      {values.dhs_student_currently_in_custody && (
                        <>
                          <FormTextInput
                            name="dhs_caseworker_name"
                            label={I18n.t(
                              'confidential_crisis.dhs_caseworker_name'
                            )}
                          />

                          <FormTextInput
                            name="dhs_caseworker_phone"
                            label={I18n.t(
                              'confidential_crisis.dhs_caseworker_phone'
                            )}
                          />
                          <Typography variant="body1" component="div">
                            <strong>
                              *Oklahoma DHS 24-Hour State Referral Line:
                              1-800-522-3511
                            </strong>
                          </Typography>

                          <FormTextInput
                            name="dhs_caseworker_email"
                            label={I18n.t(
                              'confidential_crisis.dhs_caseworker_email'
                            )}
                          />
                        </>
                      )}
                    </>
                  )}

                <Typography variant="h3">
                  COPES Crisis Hotline: 918-744-4800
                </Typography>

                <FormSelect
                  disabled={disabled}
                  items={optionsYesOrNo}
                  label={I18n.t('confidential_crisis.was_copes_contacted')}
                  name="has_contacted_copes"
                />
                {values.has_contacted_copes == 'Yes' && (
                  <>
                    <FormTextInput
                      name="copes_consultant_name"
                      label={I18n.t(
                        'confidential_crisis.copes_consultant_name'
                      )}
                    />

                    <FormDateTimePicker
                      name="copes_date_of_consult"
                      label={I18n.t(
                        'confidential_crisis.copes_date_of_consult'
                      )}
                      readOnly={disabled}
                    />

                    <FormTextAreaInput
                      name="copes_recommendations"
                      label={I18n.t(
                        'confidential_crisis.copes_recommendations'
                      )}
                    />
                  </>
                )}

                <FormCheckbox name="student_has_current_iep">
                  {I18n.t('confidential_crisis.student_has_current_iep')}
                </FormCheckbox>

                <FormCheckbox name="student_has_section_504_plan">
                  {I18n.t('confidential_crisis.student_has_section_504_plan')}
                </FormCheckbox>

                <FormCheckbox name="weapon_involved">
                  {I18n.t('confidential_crisis.weapon_involved')}{' '}
                  <a
                    href="https://docs.google.com/document/d/1hQKGgO1YeBqzIrfWn0wbwQaiCqdZ7M1O"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (Link)
                  </a>
                </FormCheckbox>

                {values.weapon_involved && (
                  <>
                    <FormTextAreaInput
                      name="weapon_description"
                      label={I18n.t('confidential_crisis.weapon_description')}
                    />

                    <FormCheckbox name="submitted_tps_weapons_report">
                      {I18n.t(
                        'confidential_crisis.submitted_tps_weapons_report'
                      )}
                    </FormCheckbox>
                  </>
                )}

                <FormCheckbox name="contacted_campus_police">
                  {I18n.t('confidential_crisis.contacted_campus_police')}
                </FormCheckbox>

                <FormCheckbox name="contacted_911">
                  {I18n.t('confidential_crisis.contacted_911')}
                </FormCheckbox>

                <FormCheckbox name="threat_assessment_completed">
                  {I18n.t('confidential_crisis.threat_assessment_completed')}{' '}
                  <a
                    href="https://docs.google.com/document/d/1TZg2kkkwMLoFNjp2lfaMeo3SERIubBKf2CBeC_EPkpQ/edit?usp=sharing"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (Link)
                  </a>
                </FormCheckbox>

                <FormCheckbox name="success_plan_created">
                  {I18n.t('confidential_crisis.success_plan_created')}{' '}
                  <a
                    href="https://docs.google.com/document/d/1IfvJNqazz8_PuZ6NF1XWfkDEg2Uu06ML5rzMt6IKzY0/edit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (Link)
                  </a>
                </FormCheckbox>

                <FormCheckbox name="osde_student_entry_plan_completed">
                  {I18n.t(
                    'confidential_crisis.osde_student_entry_plan_completed'
                  )}{' '}
                  <a
                    href="https://sde.ok.gov/sde/sites/ok.gov.sde/files/STUDNET%20RE-ENTRY%20PLAN%20OSDE.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    (Link)
                  </a>{' '}
                </FormCheckbox>

                {!values.osde_student_entry_plan_completed && (
                  <FormDatePicker
                    name="osde_student_entry_plan_expected_completion"
                    label={I18n.t(
                      'confidential_crisis.osde_student_entry_plan_expected_completion'
                    )}
                    readOnly={disabled}
                  />
                )}

                <FormTextAreaInput
                  name="additional_information"
                  label={I18n.t('confidential_crisis.additional_information')}
                />

                <FormTextInput
                  name="school_leader_signature"
                  label={I18n.t('confidential_crisis.school_leader_signature')}
                />

                {values.school_leader_signature && (
                  <FormDatePicker
                    name="school_leader_signature_date"
                    label={I18n.t(
                      'confidential_crisis.school_leader_signature_date'
                    )}
                    readOnly={disabled}
                  />
                )}

                <FormCheckbox name="is_draft">
                  {I18n.t('confidential_crisis.is_draft')}
                </FormCheckbox>

                {!disabled && (
                  <Button variant="contained" type="submit">
                    Submit
                  </Button>
                )}
              </Stack>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

CrisisForm.propTypes = {
  prefill: PropTypes.object,
  onSubmitValidated: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  agencyId: PropTypes.number
}

export { CrisisForm }
