export const types = {
  SET_CURRENT_USER: 'current_user/set_user'
}

const initialState = {
  user: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      return { ...state, user: action.payload }
    default:
      return state
  }
}
