import React from 'react'
import PropTypes from 'prop-types'
import PageTitle from 'components/shared/page-title'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { useTeacher } from 'hooks/useTeacher'
import Grid from '@mui/material/Grid'
import ReferralsList from './list'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/styles'

function Referrals({ teacherId }) {
  const theme = useTheme()
  const params = {
    include_referrals: true
  }
  const { isSuccess, data } = useTeacher(teacherId, params)

  const teacher = data?.teacher

  return (
    <React.Fragment>
      <PageTitle title={'Referral Data'} />
      <Grid
        container
        wrap={'nowrap'}
        columnSpacing={2}
        justifyContent={'space-between'}
      >
        <Grid item flex={1}>
          <Typography variant="h2">Referrals</Typography>
          {isSuccess && <ReferralsList referrals={teacher.current_referrals} />}
        </Grid>
        <Grid item flex={1}>
          <Typography variant="h2">Historical</Typography>
          <TableContainer
            component={Paper}
            sx={{ marginTop: theme.spacing(4) }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th">School Year</TableCell>
                  <TableCell align="center" component="th">
                    Referrals
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isSuccess &&
                  teacher.historical_referrals.map(yearData => (
                    <TableRow key={yearData.year}>
                      <TableCell>{`${yearData.year - 1}-${
                        yearData.year
                      }`}</TableCell>
                      <TableCell align="center">{yearData.data}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

Referrals.propTypes = {
  teacherId: PropTypes.number.isRequired
}

export default Referrals
