import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Table from '@mui/material/Table'
import Typography from '@mui/material/Typography'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Tooltip from 'components/shared/tooltip'
import { titleize } from 'utils'
import { styled } from '@mui/material/styles'

function MapTable({
  language,
  mapData,
  maxLexile,
  setActiveMap,
  setDialogOpen,
  subject
}) {
  const handleActiveMap = map => {
    setActiveMap(map)
    setDialogOpen(true)
  }

  const ActiveTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover
  }))

  const ActiveTableCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: theme.palette.action.hover
  }))

  const BoldTableCell = styled(TableCell)(() => ({
    fontWeight: 'bold'
  }))

  return (
    <React.Fragment>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Typography variant="h3">
          {I18n.jsT('titles.all_map_growth_scores', {
            subject: titleize(subject),
            language: titleize(language)
          })}
        </Typography>
        <h6>
          ({I18n.jsT('labels.max_lexile')}:{' '}
          {maxLexile ? maxLexile.lexile : 'N/A'}){' '}
          <Tooltip
            id="risk-score"
            content={I18n.jsT('text.lexile_explanation', { subject })}
            placement="left"
          />
        </h6>
      </Stack>
      <Table
        id="map-table"
        sx={{
          table: { marginTop: '30px' }
        }}
      >
        <TableHead>
          <ActiveTableRow>
            <BoldTableCell>
              {I18n.jsT('table_headers.map.testing_window')}
            </BoldTableCell>
            <BoldTableCell>
              {I18n.jsT('table_headers.map.rit_score')}
            </BoldTableCell>
            <BoldTableCell>
              {I18n.jsT('table_headers.map.district_rit_avg')}
            </BoldTableCell>
            <BoldTableCell>
              {I18n.jsT('table_headers.map.national_norm')}
            </BoldTableCell>
            <BoldTableCell>
              {I18n.jsT('table_headers.map.test_percentile')}
            </BoldTableCell>
            <BoldTableCell>
              {I18n.jsT('table_headers.map.actions')}
            </BoldTableCell>
          </ActiveTableRow>
        </TableHead>
        <TableBody>
          {mapData.map((mapTest, i) => (
            <TableRow key={i}>
              <ActiveTableCell>{mapTest.title}</ActiveTableCell>
              <TableCell>{mapTest.score}</TableCell>
              <TableCell>{mapTest.district_average}</TableCell>
              <TableCell>{mapTest.national_average}</TableCell>
              <TableCell>{mapTest.percentile || '-'}</TableCell>
              <TableCell>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  size={'xs'}
                  onClick={() => handleActiveMap(mapTest)}
                >
                  {I18n.jsT('buttons.see_details')}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}

MapTable.propTypes = {
  language: PropTypes.string,
  mapData: PropTypes.array,
  maxLexile: PropTypes.number,
  setActiveMap: PropTypes.func,
  setDialogOpen: PropTypes.func,
  subject: PropTypes.string
}

export default MapTable
