export default {
  login: '/signin',
  student: (id, tabPath = 'overview') => `/student/${id}/${tabPath}`,
  section: (teacherId, sectionId) =>
    `/teachers/${teacherId}/sections?section_id=${sectionId}`,
  newStudentIntervention: studentId => `/student/${studentId}/concerns/new`,
  api: {
    schoolMetrics: schoolId => `/api/web/schools/${schoolId}/school_metrics`
  }
}
