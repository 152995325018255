import React from 'react'
import { Box, Button } from '@mui/material'
import GoalsModal from './goals-modal'
import TuneIcon from '@mui/icons-material/Tune'

class GoalButton extends React.Component {
  state = {
    modalOpen: false
  }

  handleModalHide = () => {
    this.setState({ modalOpen: false })
  }

  openModal = () => {
    this.setState({ modalOpen: true })
  }

  render() {
    return (
      <Box>
        <GoalsModal show={this.state.modalOpen} onHide={this.handleModalHide} />

        <Button
          id="js-admin-view-goal-settings"
          variant="contained"
          onClick={this.openModal}
          startIcon={<TuneIcon />}
        >
          Goal Settings
        </Button>
      </Box>
    )
  }
}

export default GoalButton
