import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'

const ChildStudyStatusBadge = ({ status }) => {
  if ([0, 'referred'].includes(status)) {
    return <Chip color="warning" label="Referred" />
  } else if ([1, 'active'].includes(status)) {
    return <Chip color="success" label="Active" />
  } else if ([2, 'closed'].includes(status)) {
    return <Chip color="default" label="Closed" />
  } else if ([3, 'on_hold_teacher'].includes(status)) {
    return <Chip color="error" label="On Hold - Teacher" />
  } else {
    return <></>
  }
}

ChildStudyStatusBadge.propTypes = {
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default ChildStudyStatusBadge
