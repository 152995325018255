import React from 'react'
import PropTypes from 'prop-types'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import PersonIcon from '@mui/icons-material/Person'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import PageviewIcon from '@mui/icons-material/Pageview'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import CreateIcon from '@mui/icons-material/Create'
import { withStyles, makeStyles } from '@mui/styles'
import moment from 'moment'
import { titleize } from 'utils'
import { useQuery } from '@tanstack/react-query'
import { getTouchpointIndicatorSets } from 'api/touchpoints'

const useStyles = makeStyles({
  root: {
    minWidth: 345,
    maxWidth: 900,
    marginTop: 20,
    padding: 5
  },
  icon: {
    marginRight: 5,
    verticalAlign: 'middle'
  },
  chip: {
    marginTop: '40px',
    fontSize: '15px',
    color: '#CE8826',
    backgroundColor: '#FFF3E0'
  }
})

const CustomSlider = withStyles({
  root: {
    color: '#0096D7',
    height: 4
  },
  thumb: {
    display: 'none'
  },
  active: {},
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#0096D7'
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#0096D7'
  }
})(Slider)

const formatSectionName = section =>
  section && `${section.hour} - ${section.name}`

const formatIndicatorSetName = indicatorSet =>
  indicatorSet && `${indicatorSet.title}`

export default function TouchpointCard({
  primaryFocus,
  observation,
  onEdit,
  onShow,
  user
}) {
  const classes = useStyles()

  const indicatorSetsQuery = useQuery(
    ['indicator-set-hash'],
    async () => {
      const response = await getTouchpointIndicatorSets()
      return response.data.reduce((h, indicatorSet) => {
        h[indicatorSet.id] = indicatorSet
        return h
      }, {})
    },
    {}
  )

  const visibility =
    observation.is_draft && user.id === observation.observer_id
      ? 'Only You'
      : 'Admins'

  const indicatorScores = observation.indicator_answers
    .map(answer => answer.score)
    .filter(score => score !== null)
  const indicatorScoreSum = indicatorScores.reduce((acc, cur) => acc + cur, 0)

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          {primaryFocus === 'observer' && (
            <Grid item>
              <PersonIcon className={classes.icon} />
              {observation.observer_name}
            </Grid>
          )}
          <Grid item>
            <CalendarTodayIcon className={classes.icon} />
            {moment(observation.date).format('MM/DD/YYYY')}
          </Grid>
          <Grid item>
            <VisibilityIcon className={classes.icon} />
            Visible to: {visibility}
          </Grid>
          {user.role !== 'teacher' && (
            <Grid item>
              <IconButton onClick={() => onShow(observation)}>
                <PageviewIcon color="primary" />
              </IconButton>
              {user.id === observation.observer_id && (
                <IconButton onClick={() => onEdit(observation)}>
                  <CreateIcon color="primary" />
                </IconButton>
              )}
            </Grid>
          )}
        </Grid>

        <Divider style={{ marginTop: '15px' }} />

        {primaryFocus === 'observee' && (
          <Typography variant="body1" style={{ marginTop: '20px' }}>
            Teacher Observed: <b>{titleize(observation.observee_name)}</b>
          </Typography>
        )}

        {observation.summary && (
          <div style={{ marginTop: '1rem' }}>
            <Typography variant="overline">Observation Summary</Typography>
            <Typography variant="body1">{observation.summary}</Typography>
          </div>
        )}

        {observation.observer_id == user.id && observation.note_to_self && (
          <div style={{ marginTop: '1rem' }}>
            <Typography variant="overline">Note-to-Self</Typography>
            <Typography variant="body1">{observation.note_to_self}</Typography>
          </div>
        )}

        {user.role !== 'teacher' && (
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            style={{ marginTop: '1rem' }}
          >
            <Grid item>
              {indicatorSetsQuery.data && (
                <div>
                  <div>
                    Section:{' '}
                    {formatSectionName(
                      observation.sections.find(
                        section => section.id == observation.section_id
                      )
                    )}
                  </div>
                  <div>
                    Rubric:{' '}
                    {formatIndicatorSetName(
                      indicatorSetsQuery.data[
                        observation.touchpoint_indicatorset_id
                      ]
                    )}
                  </div>
                </div>
              )}
              {Boolean(indicatorScores.length) && (
                <React.Fragment>
                  <Typography
                    variant="body2"
                    style={{ marginTop: '25px', color: '#8a8a8a' }}
                  >
                    Indicator Success {indicatorScoreSum}/
                    {indicatorScores.length}
                  </Typography>
                  <CustomSlider
                    value={indicatorScoreSum}
                    color="primary"
                    min={0}
                    max={indicatorScores.length}
                    disabled
                  />
                </React.Fragment>
              )}
            </Grid>
            {observation.is_draft && (
              <Grid item>
                <Chip
                  color="secondary"
                  label="Draft"
                  className={classes.chip}
                />
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

TouchpointCard.propTypes = {
  primaryFocus: PropTypes.string.isRequired,
  observation: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onShow: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}
