import React from 'react'
import PropTypes from 'prop-types'
import { deleteChildStudyNote } from 'api/child-study-notes'
import { RequirementPropType } from 'modules/requirements'
import ChildStudyNoteModal from '../action-modals/child-study-note'
import ConfirmModalButton from 'components/shared/confirm-modal/button'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Button, Stack, Tooltip } from '@mui/material'

const NoteRequirement = ({
  activeId,
  activeModal,
  fetchRequirements,
  requirement,
  setActiveId,
  setActiveModal,
  user,
  handleCloseAndRefreshFeed
}) => {
  const userIsOwner = () => user.id === requirement.user_id

  const deleteButton = () =>
    userIsOwner() && (
      <ConfirmModalButton
        id="delete-child-study-note"
        modalMessage="Are you sure you want to delete this Child Study Note?"
        showCancelButton={false}
        onConfirm={() =>
          deleteChildStudyNote(requirement.data_id, fetchRequirements)
        }
        color="error"
        startIcon={<DeleteIcon />}
        variant="outlined"
      >
        Delete
      </ConfirmModalButton>
    )

  const editButton = () =>
    userIsOwner() && (
      <Tooltip title="Edit Child Study Note" placement="top" arrow>
        <Button
          color="secondary"
          id="edit-child-study-note"
          onClick={() =>
            setActiveModal('childStudyNote') & setActiveId(requirement.data_id)
          }
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      </Tooltip>
    )

  return (
    <React.Fragment>
      <div>
        <p>{requirement.text}</p>
        {requirement.author_name && <p>- {requirement.author_name}</p>}
      </div>

      <Stack direction="row" justifyContent="center">
        {editButton()}
        {deleteButton()}
      </Stack>

      {userIsOwner() && (
        <ChildStudyNoteModal
          show={
            activeModal == 'childStudyNote' && activeId == requirement.data_id
          }
          onHide={handleCloseAndRefreshFeed}
          noteId={requirement.data_id}
          noteText={requirement.text}
          childStudyId={requirement.child_study_id}
          editing
        />
      )}
    </React.Fragment>
  )
}

NoteRequirement.propTypes = {
  activeId: PropTypes.number,
  activeModal: PropTypes.string.isRequired,
  fetchRequirements: PropTypes.func.isRequired,
  requirement: RequirementPropType.isRequired,
  setActiveId: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  handleCloseAndRefreshFeed: PropTypes.func.isRequired
}

export default NoteRequirement
