import React from 'react'
import Alert from '@mui/material/Alert'

const PRODUCTION_URL = new URL('https://data.tulsaschools.org')

function AppInstanceAlert() {
  if (window.location.host === PRODUCTION_URL.host) {
    return null
  }

  return (
    <Alert severity="warning">
      This instance is development.{' '}
      <span style={{ whiteSpace: 'nowrap' }}>
        Production is here:{' '}
        <a href={PRODUCTION_URL} target="_blank" rel="noreferrer">
          {PRODUCTION_URL.toString()}
        </a>
      </span>
    </Alert>
  )
}

export default AppInstanceAlert
