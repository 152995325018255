import React from 'react'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Legend,
  XAxis,
  YAxis,
  ColumnSeries,
  Tooltip
} from 'react-jsx-highcharts'
import PropTypes from 'prop-types'
import { fetchGroupCategories } from 'utils/demographic-types'

function GradesChart({ counts, grouping, gradeScale }) {
  const options = {
    column: {
      stacking: 'normal'
    }
  }

  const categories = gradeScale

  const renderSeriesData = (groupValue, index, accessor) => {
    const sanitizedGroupValue =
      groupValue === 'Monitored/Exited' ? 'Former' : String(groupValue)
    return (
      <ColumnSeries
        id={`series-${accessor}-${sanitizedGroupValue}`}
        name={sanitizedGroupValue}
        animation={false}
        key={`series-${accessor}-${sanitizedGroupValue}`}
        data={categories.map(c =>
          counts[c] && typeof counts[c] === 'object'
            ? counts[c][sanitizedGroupValue] || 0
            : 0
        )}
        marker={{ enabled: false }}
      />
    )
  }

  return (
    <HighchartsChart plotOptions={options}>
      <Chart type="column" />
      <Legend layout="horizontal" align="center" verticalAlign="bottom" />
      <Tooltip
        headerFormat="<span style='font-size:16px;'>Grade {point.x}</span><table>"
        pointFormat={
          '<tr><td style="color:{series.color};padding:0;font-size:14px;">{series.name}:&nbsp;</td>' +
          '<td style="padding:0;font-size:14px;"><b>{point.y}</b></td></tr>'
        }
        footerFormat="</table>"
        shared={true}
        crosshairs={true}
        useHTML={true}
      />
      <XAxis categories={categories}>
        <XAxis.Title>Grade</XAxis.Title>
      </XAxis>
      <YAxis id="number" allowDecimals={false}>
        <YAxis.Title>Student Count</YAxis.Title>
      </YAxis>
      {grouping !== '' ? (
        fetchGroupCategories(grouping).map((groupValue, index) =>
          renderSeriesData(groupValue, index, grouping)
        )
      ) : (
        <ColumnSeries
          id={'series-none'}
          name={'Count'}
          animation={false}
          key={'no-grouping'}
          data={categories.map(c =>
            typeof counts[c] === 'number' ? counts[c] : 0
          )}
          marker={{ enabled: false }}
        />
      )}
    </HighchartsChart>
  )
}

GradesChart.propTypes = {
  counts: PropTypes.object.isRequired,
  grouping: PropTypes.string.isRequired,
  gradeScale: PropTypes.array.isRequired
}

export default withHighcharts(GradesChart, window.Highcharts)
