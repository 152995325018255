import { alert } from 'utils/alert'
import { get } from 'utils/ajax'

export const getMapReadingTiers = params => {
  return get('/api/web/map_reading_tiers.json', { params: params }).catch(
    _error => {
      alert('There was an error fetching the map reading tiers.', 'danger')
    }
  )
}
