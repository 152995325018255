import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import PieChartFiveSlices from 'components/shared/charts/PieChartFiveSlices'
import PaperBox from 'components/shared/PaperBox'
import { getAcademicInterventionsSummary } from 'api/academic-interventions'
import { handleRailsRequestJsResponse } from 'utils/handleRailsRequestJsResponse'
import { useQuery } from '@tanstack/react-query'
import SimpleAutocomplete from 'shared/simple-autocomplete'
import { currentSchoolYear } from 'utils'
import { fetchSchoolDetails } from 'api/schools'
import moment from 'moment'
import { isUndefined, toInteger, sortBy } from 'lodash'
import { useOptions } from 'utils/contexts/options'

const useAcademicInterventionsSummary = ({
  schoolId,
  year,
  weekNumber,
  ostpLevel,
  gradeLevel,
  elementaryMapTier,
  secondaryMapTier
}) => {
  const [data, setData] = useState({
    yearOptions: [`${currentSchoolYear}`],
    weekNumberOptions: [],
    academicInterventionsSummary: []
  })

  const query = useQuery(
    [
      'academic-interventions-summary',
      {
        schoolId,
        year,
        weekNumber,
        ostpLevel,
        gradeLevel,
        elementaryMapTier,
        secondaryMapTier
      }
    ],
    async () => {
      const params = {
        include_options: true,
        school_year: year
      }

      if (schoolId) {
        params['school_id'] = schoolId
      }

      if (weekNumber) {
        params['week_number'] = weekNumber
      }

      if (ostpLevel) {
        params['ostp_level'] = ostpLevel
      }

      if (gradeLevel) {
        params['grade_level'] = gradeLevel
      }

      if (elementaryMapTier) {
        params['elementary_map_tier'] = elementaryMapTier
      }

      if (secondaryMapTier) {
        params['secondary_map_tier'] = secondaryMapTier
      }

      const response = await getAcademicInterventionsSummary(params)
      const json = await handleRailsRequestJsResponse(response)

      const yearOptions = json.data.options.map(o => o.year)
      const weekNumbersHash =
        json.data.options.find(o => o.year == year)?.week_numbers || {}
      const weekNumberOptions = sortBy(
        Object.entries(weekNumbersHash).map(([weekNum, weekStart]) => ({
          label: `Week ${weekNum} - ${moment(weekStart).format(
            'MM/DD/YYYY'
          )} to ${moment(weekStart).add(4, 'days').format('MM/DD/YYYY')}`,
          value: toInteger(weekNum)
        })),
        'value'
      )
      const academicInterventionsSummary = json.data.platform_data

      return { yearOptions, weekNumberOptions, academicInterventionsSummary }
    },
    {
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (query.isSuccess) {
      setData(query.data)
    }
  }, [query.data, query.isSuccess])

  return data
}

async function fetchGradeLevels(schoolId = null, gradeLevels = []) {
  const allGradeLevelOptions = Object.entries(gradeLevels).map(
    ([label, value]) => ({ label, value: `${value}` })
  )

  if (!schoolId) {
    return allGradeLevelOptions
  }

  const schoolDetails = await fetchSchoolDetails(schoolId)

  return allGradeLevelOptions.filter(({ label }) =>
    schoolDetails.grade_levels.includes(label)
  )
}

function AcademicInterventions({ school }) {
  const [year, setYear] = useState(currentSchoolYear.toString())
  const [weekNumber, setWeekNumber] = useState('')
  const [ostpLevel, setOstpLevel] = useState('')
  const [elementaryMapTier, setElementaryMapTier] = useState('')
  const [secondaryMapTier, setSecondaryMapTier] = useState('')
  const [gradeLevel, setGradeLevel] = useState('')
  const schoolId = school?.id
  const schoolCategory = school?.category
  const { gradeLevels } = useOptions()

  const { data: gradeLevelOptions } = useQuery(
    ['schoolUsers', schoolId],
    () => fetchGradeLevels(schoolId, gradeLevels),
    {
      refetchOnWindowFocus: false,
      initialData: []
    }
  )

  const { yearOptions, weekNumberOptions, academicInterventionsSummary } =
    useAcademicInterventionsSummary({
      schoolCategory,
      schoolId,
      year,
      weekNumber,
      ostpLevel,
      gradeLevel,
      elementaryMapTier,
      secondaryMapTier
    })

  const amiraInterventionsSummary = academicInterventionsSummary.find(
    r => r['platform'] == 'amira'
  )
  const iStationInterventionsSummary = academicInterventionsSummary.find(
    r => r['platform'] == 'istation'
  )
  const imagineLearningInterventionsSummary = academicInterventionsSummary.find(
    r => r['platform'] == 'imagine_learning'
  )
  const exactPathInterventionsSummary = academicInterventionsSummary.find(
    r => r['platform'] == 'exact_path'
  )
  const achieve3000InterventionsSummary = academicInterventionsSummary.find(
    r => r['platform'] == 'achieve_3000'
  )

  const summitInterventionsSummary = academicInterventionsSummary.find(
    r => r['platform'] == 'summit'
  )

  const ostpLevelOptions = [
    { label: 'Below Basic', value: 4 },
    { label: 'Basic', value: 5 },
    { label: 'Proficient', value: 2 },
    { label: 'Advanced', value: 3 }
  ]

  const elementaryMapTierOptions = [
    { label: 'Advanced', value: 'Advanced' },
    { label: 'Tier 1', value: 'Tier 1' },
    { label: 'Tier 1+', value: 'Tier 1+' },
    { label: 'Tier 2', value: 'Tier 2' },
    { label: 'Tier 3', value: 'Tier 3' }
  ]

  const secondaryMapTierOptions = [
    { label: 'Tier 1', value: 'Tier 1' },
    { label: 'Tier 2', value: 'Tier 2' },
    { label: 'Tier 3', value: 'Tier 3' }
  ]

  const elementaryGradeLevels = ['-2', '-1', '0', '1', '2', '3', '4', '5']
  const secondaryGradeLevels = ['6', '7', '8', '9', '10', '11', '12']

  useEffect(() => {
    if (Boolean(yearOptions.length) && !yearOptions.includes(parseInt(year))) {
      setYear(Math.max(...yearOptions).toString())
    }
  }, [year, yearOptions])

  useEffect(() => {
    if (
      Boolean(weekNumberOptions.length) &&
      !weekNumberOptions.map(o => o.value).includes(parseInt(weekNumber))
    ) {
      setWeekNumber(Math.max(...weekNumberOptions.map(o => o.value)).toString())
    }
  }, [weekNumberOptions])

  useEffect(() => {
    if (secondaryGradeLevels.includes(gradeLevel)) {
      setElementaryMapTier('')
    } else if (elementaryGradeLevels.includes(gradeLevel)) {
      setSecondaryMapTier('')
    }
  }, [gradeLevel, elementaryMapTier, secondaryMapTier])

  return (
    <Page
      name="District Overall Metrics"
      title="Academic Interventions - District Overall Metrics"
    >
      <Stack spacing={6} sx={{ mt: 6 }}>
        <Stack direction="row" spacing={2}>
          <FormControl component={Box} fullWidth>
            <InputLabel id={'year-select'}>Select Year</InputLabel>
            <Select
              labelId={'year-select'}
              label={'Select Year'}
              value={year}
              onChange={e => {
                setYear(e.target.value)
              }}
            >
              {yearOptions.map(y => (
                <MenuItem key={`year-${y}`} value={`${y}`}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl component={Box} fullWidth>
            <InputLabel id={'week-select'}>Select Week</InputLabel>
            <Select
              labelId={'week-select'}
              label={'Select Week'}
              value={weekNumber}
              onChange={e => setWeekNumber(e.target.value)}
            >
              {weekNumberOptions.map(w => (
                <MenuItem key={w.label} value={w.value}>
                  {w.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl component={Box} fullWidth>
            <SimpleAutocomplete
              label={'Grade Level'}
              value={gradeLevel}
              onChange={e => setGradeLevel(e.value)}
              options={gradeLevelOptions}
            />
          </FormControl>
          {/* Not every student appears to have a OSTP level */}
          <FormControl component={Box} fullWidth>
            <SimpleAutocomplete
              label={'Latest OSTP'}
              value={ostpLevel}
              onChange={e => setOstpLevel(e.value)}
              options={ostpLevelOptions}
            />
          </FormControl>
          {(isUndefined(schoolId) || school?.category === 'elementary') && (
            <FormControl component={Box} fullWidth>
              <SimpleAutocomplete
                disabled={
                  !!secondaryMapTier ||
                  secondaryGradeLevels.includes(gradeLevel)
                }
                label={'Elementary MAP Tier'}
                value={elementaryMapTier}
                onChange={e => setElementaryMapTier(e.value)}
                options={elementaryMapTierOptions}
              />
            </FormControl>
          )}
          {(isUndefined(schoolId) ||
            ['middle', 'high'].includes(school?.category)) && (
            <FormControl component={Box} fullWidth>
              <SimpleAutocomplete
                disabled={
                  !!elementaryMapTier ||
                  elementaryGradeLevels.includes(gradeLevel)
                }
                label={'Secondary MAP Tier'}
                value={secondaryMapTier}
                onChange={e => setSecondaryMapTier(e.value)}
                options={secondaryMapTierOptions}
              />
            </FormControl>
          )}
        </Stack>
        <Stack>
          {(isUndefined(schoolId) || school?.category === 'elementary') &&
            !secondaryGradeLevels.includes(gradeLevel) && (
              <>
                <Box id="amiraInterventionsSummary">
                  <Typography variant="h2">Amira</Typography>
                  <PaperBox
                    title="Usage by Active Students With Amira As Primary Intervention This Week"
                    helperText={{
                      'Usage':
                        'Any time or activities recorded by the vendor for a given student, by week',
                      'Primary Intervention':
                        'The Walk-to-Read intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in Primary Interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
                      'Threshold':
                        'Spent at least 40 minutes in lessons or 4 stories this week.'
                    }}
                  >
                    <PieChartFiveSlices
                      one={{
                        title: 'Used - Threshold Met',
                        value: amiraInterventionsSummary?.counts?.threshold_met,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'amira'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Amira as their primary platform."
                        }
                      }}
                      two={{
                        title: 'Used - Threshold Not Met',
                        value:
                          amiraInterventionsSummary?.counts?.threshold_not_met,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'amira'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Amira as their primary platform."
                        }
                      }}
                      three={{
                        title: 'Not Used',
                        value: amiraInterventionsSummary?.counts?.not_used,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'amira'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Amira as their primary platform."
                        }
                      }}
                    />
                  </PaperBox>
                </Box>
                <Box id="IstationInterventionsSummary">
                  <Typography variant="h2">Istation</Typography>
                  <PaperBox
                    title="Usage by Active Students With Istation As Primary Intervention This Week"
                    helperText={{
                      'Usage':
                        'Any time or activities recorded by the vendor for a given student, by week',
                      'Primary Intervention':
                        'The Walk-to-Read intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in Primary Interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
                      'Threshold': '40 minutes per week for all grades.'
                    }}
                  >
                    <PieChartFiveSlices
                      one={{
                        title: 'Used - Threshold Met',
                        value:
                          iStationInterventionsSummary?.counts?.threshold_met,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'istation'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Istation as their primary platform."
                        }
                      }}
                      two={{
                        title: 'Used - Threshold Not Met',
                        value:
                          iStationInterventionsSummary?.counts
                            ?.threshold_not_met,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'istation'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Istation as their primary platform."
                        }
                      }}
                      three={{
                        title: 'Not Used',
                        value: iStationInterventionsSummary?.counts?.not_used,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'istation'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Istation as their primary platform."
                        }
                      }}
                    />
                  </PaperBox>
                </Box>
                <Box id="imagineLearningInterventionsSummary">
                  <Typography variant="h2">Imagine Learning</Typography>
                  <PaperBox
                    title="Usage by Active Students With Imagine Learning As Primary Intervention This Week"
                    helperText={{
                      'Usage':
                        'Any time or activities recorded by the vendor for a given student, by week',
                      'Primary Intervention':
                        'The Walk-to-Read intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in Primary Interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
                      'Threshold': 'Pass at least 1 lesson per week.'
                    }}
                  >
                    <PieChartFiveSlices
                      one={{
                        title: 'Used - Threshold Met',
                        value:
                          imagineLearningInterventionsSummary?.counts
                            ?.threshold_met,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'imagine_learning'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Imagine Learning as their primary platform."
                        }
                      }}
                      two={{
                        title: 'Used - Threshold Not Met',
                        value:
                          imagineLearningInterventionsSummary?.counts
                            ?.threshold_not_met,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'imagine_learning'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Imagine Learning as their primary platform."
                        }
                      }}
                      three={{
                        title: 'Not Used',
                        value:
                          imagineLearningInterventionsSummary?.counts?.not_used,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'imagine_learning'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Imagine Learning as their primary platform."
                        }
                      }}
                    />
                  </PaperBox>
                </Box>
              </>
            )}
          {(isUndefined(schoolId) ||
            ['middle', 'high'].includes(school?.category)) &&
            !elementaryGradeLevels.includes(gradeLevel) && (
              <>
                <Box id="exactPathInterventionsSummary">
                  <Typography variant="h2">Exact Path</Typography>
                  <PaperBox
                    title="Usage by Active Students With Exact Path As Primary Intervention This Week"
                    helperText={{
                      'Usage':
                        'Any time or activities recorded by the vendor for a given student, by week',
                      'Primary Intervention':
                        'The intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in Primary Interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
                      'Threshold':
                        'Minimum of 40 minutes on the literacy learning path.'
                    }}
                  >
                    <PieChartFiveSlices
                      one={{
                        title: 'Used - Threshold Met',
                        value:
                          exactPathInterventionsSummary?.counts?.threshold_met,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'exact_path'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Exact Path as their primary platform."
                        }
                      }}
                      two={{
                        title: 'Used - Threshold Not Met',
                        value:
                          exactPathInterventionsSummary?.counts
                            ?.threshold_not_met,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'exact_path'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Exact Path as their primary platform."
                        }
                      }}
                      three={{
                        title: 'Not Used',
                        value: exactPathInterventionsSummary?.counts?.not_used,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'exact_path'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Exact Path as their primary platform."
                        }
                      }}
                    />
                  </PaperBox>
                </Box>
                <Box id="achieve3000InterventionsSummary">
                  <Typography variant="h2">Achieve3000</Typography>
                  <PaperBox
                    title="Usage by Active Students With Achieve3000 As Primary Intervention This Week"
                    helperText={{
                      'Usage':
                        'Any time or activities recorded by the vendor for a given student, by week',
                      'Primary Intervention':
                        'The intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in Primary Interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
                      'Threshold':
                        'Complete 1 activity per week (one 5 step lesson) AND Score 75% or higher on their first try of the multiple choice activity'
                    }}
                  >
                    <PieChartFiveSlices
                      one={{
                        title: 'Used - Threshold Met',
                        value:
                          achieve3000InterventionsSummary?.counts
                            ?.threshold_met,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'achieve_3000'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Achieve3000 as their primary platform."
                        }
                      }}
                      two={{
                        title: 'Used - Threshold Not Met',
                        value:
                          achieve3000InterventionsSummary?.counts
                            ?.threshold_not_met,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'achieve_3000'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Achieve3000 as their primary platform."
                        }
                      }}
                      three={{
                        title: 'Not Used',
                        value:
                          achieve3000InterventionsSummary?.counts?.not_used,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            view: 'Reading Interventions',
                            current_platform: 'achieve_3000'
                          },
                          alert_text:
                            "We've filtered the page to show Reading Intervention data for students with Achieve3000 as their primary platform."
                        }
                      }}
                    />
                  </PaperBox>
                </Box>
                {(isUndefined(schoolId) ||
                  [
                    'EAST CENTRAL MIDDLE SCHOOL',
                    'HALE MIDDLE SCHOOL',
                    'NORTH STAR ACADEMY HIGH SCHOOL',
                    'TULSA MET MS',
                    'VIRTUAL MIDDLE SCHOOL',
                    'VIRTUAL HIGH SCHOOL',
                    'WEBSTER MIDDLE SCHOOL'
                  ].includes(school?.name)) && (
                  <Box id="summitInterventionsSummary">
                    <Typography variant="h2">Summit K12</Typography>
                    <PaperBox
                      title="Usage Per Week by Active Students With Summit K12 As Primary Intervention"
                      helperText={{
                        'Usage':
                          'Any time or activities recorded by the vendor for a given student, by week',
                        'Primary Intervention':
                          'The intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in Primary Interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
                        'Threshold': '75 minutes weekly'
                      }}
                    >
                      <PieChartFiveSlices
                        one={{
                          title: 'Used - Threshold Met',
                          value:
                            summitInterventionsSummary?.counts?.threshold_met,
                          options: {
                            pathname: '/students',
                            query_params: {
                              school_id: schoolId,
                              view: 'Reading Interventions',
                              current_platform: 'summit'
                            },
                            alert_text:
                              "We've filtered the page to show Reading Intervention data for students with Summit K12 as their primary platform."
                          }
                        }}
                        two={{
                          title: 'Used - Threshold Not Met',
                          value:
                            summitInterventionsSummary?.counts
                              ?.threshold_not_met,
                          options: {
                            pathname: '/students',
                            query_params: {
                              school_id: schoolId,
                              view: 'Reading Interventions',
                              current_platform: 'summit'
                            },
                            alert_text:
                              "We've filtered the page to show Reading Intervention data for students with Summit K12 as their primary platform."
                          }
                        }}
                        three={{
                          title: 'Not Used',
                          value: summitInterventionsSummary?.counts?.not_used
                        }}
                      />
                    </PaperBox>
                  </Box>
                )}
              </>
            )}
        </Stack>
      </Stack>
    </Page>
  )
}

AcademicInterventions.propTypes = {
  school: PropTypes.object
}

export default AcademicInterventions
