import { alert } from 'utils/alert'
import { patch, post } from 'utils/ajax'

export const createConcernRootCause = params => {
  return post('/api/web/concern_root_causes', {
    concern_root_cause: params
  }).catch(_error => {
    alert("There was an error creating the concern's root cause.", 'danger')
  })
}

export const updateConcernRootCause = (id, params) => {
  return patch(`/api/web/concern_root_causes/${id}`, {
    concern_root_cause: params
  }).catch(_error => {
    alert("There was an error updating the concern's root cause.", 'danger')
  })
}

export const createConcernRootCauseStrategy = params => {
  return post('/api/web/concern_root_cause_strategies', {
    concern_root_cause_strategy: params
  }).catch(_error => {
    alert('There was an error creating the strategy.', 'danger')
  })
}

export const updateConcernRootCauseStrategy = (id, params) => {
  return patch(`/api/web/concern_root_cause_strategies/${id}`, {
    concern_root_cause_strategy: params
  }).catch(_error => {
    alert('There was an error updating the strategy.', 'danger')
  })
}
