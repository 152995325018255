import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import moment from 'moment'
import { updateConcern } from 'api/interventions'
import { createNote } from 'api/wellness'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { titleize } from 'utils'

function SeverityForm({ concern, setShow, show }) {
  const queryClient = useQueryClient()
  const [note, setNote] = useState('')

  const initialSeverity = concern.severity
  const [severity, setSeverity] = useState(initialSeverity)

  const updateConcernMutation = useMutation(
    async ({ concernId, params }) => {
      if (note) {
        const systemNote = `The concern's severity has been changed from ${titleize(
          initialSeverity
        )} to ${titleize(severity)}.`
        const sanitizedNote = `${systemNote} ${note}`
        const noteParams = {
          data_type: 'Concern',
          data_id: concern.id,
          content: sanitizedNote,
          date: moment()
        }
        await createNote(noteParams)
      }
      await updateConcern(concernId, params)
    },
    {
      onSuccess: _data => {
        setShow(false)
        setNote('')

        queryClient.invalidateQueries(['concern', concern.id])
      }
    }
  )

  const handleSubmit = () => {
    updateConcernMutation.mutate({
      concernId: concern.id,
      params: {
        severity: severity
      }
    })
  }

  const determineDisabled = severity => {
    return initialSeverity === severity
  }

  const displayBehaviorNote =
    [concern.concern_name, concern.parent_concern_type_name].includes(
      'Behavior'
    ) &&
    "If the severity is Medium, the student will appear on your school's Tier 2 list. If the severity is High, the student will appear on your school's Tier 3 list."

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={show}
      onClose={() => setShow(false)}
    >
      <DialogTitle>
        <Grid item container justifyContent="space-between" xs={12}>
          <span>
            Indicate the severity of the {concern.concern_name} concern
          </span>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            m: 4,
            mb: 6
          }}
        >
          <Box>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="severity-label">Severity</InputLabel>
              <Select
                labelId="severity-label"
                id="severity"
                label="Status"
                value={severity}
                onChange={val => setSeverity(val.target.value)}
              >
                <MenuItem
                  key="severity-option-low"
                  value="low"
                  disabled={determineDisabled('low')}
                >
                  Low
                </MenuItem>
                <MenuItem
                  key="severity-option-medium"
                  value="medium"
                  disabled={determineDisabled('medium')}
                >
                  Medium
                </MenuItem>
                <MenuItem
                  key="severity-option-high"
                  value="high"
                  disabled={determineDisabled('high')}
                >
                  High
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <TextField
              required
              id="severity-note"
              label="Rationale (Required)"
              value={note}
              onChange={e => setNote(e.target.value)}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
            />
            <Typography variant="caption">
              Please add notes to document why the concern’s severity is being
              changed. {displayBehaviorNote}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShow(false)}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!note.trim() || determineDisabled(severity)}
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

SeverityForm.propTypes = {
  concern: PropTypes.object.isRequired,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

export default SeverityForm
