const disciplinesSelector = ({ disciplines }, studentId) =>
  disciplines.byStudentId[studentId] || []

const riskScoresSelector = ({ disciplines }, studentId) =>
  disciplines.riskScoresByStudentId[studentId] || []

export default {
  disciplines: disciplinesSelector,
  riskScores: riskScoresSelector
}
