import React from 'react'
import PropTypes from 'prop-types'
import { RemoteTable } from 'components/shared/table'
import { columns } from 'components/students/show/concerns/table-columns'

function ConcernsTable({ concerns, invalidateQuery, isLoading }) {
  return (
    <React.Fragment>
      <RemoteTable
        className="table table-condensed table-bordered"
        columns={columns}
        loading={isLoading}
        onFetchData={invalidateQuery}
        data={concerns}
      />
    </React.Fragment>
  )
}

ConcernsTable.propTypes = {
  concerns: PropTypes.array.isRequired,
  invalidateQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default ConcernsTable
