import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Box, FormControl, Stack, TextField } from '@mui/material'
import { Dialog } from 'shared/dialog'

import { fetchRequirements } from 'modules/requirements'
import { createChildStudyNote, editChildStudyNote } from 'api/child-study-notes'

function ChildStudyNoteModal({
  childStudyId,
  fetchRequirements,
  onHide,
  show,
  editing,
  noteId,
  noteText
}) {
  const [note, setNote] = useState('')

  const onSubmitAction = () => {
    if (editing) {
      return editChildStudyNote(noteId, note)
    } else {
      return createChildStudyNote(childStudyId, note)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()

    onSubmitAction().then(() => {
      onHide()
      fetchRequirements(childStudyId)
      setNote('')
    })
  }

  const handleHide = () => {
    setNote('')
    onHide()
  }

  const actionType = editing ? 'Update' : 'Create'

  return (
    <Dialog
      open={show}
      onClose={handleHide}
      onEntering={() => editing && setNote(noteText)}
      title={`${actionType} Child Study Note`}
    >
      <Box sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <FormControl fullWidth>
              <TextField
                id="child-study-note"
                label="Child Study Note"
                multiline
                minRows={5}
                value={note}
                onChange={e => setNote(e.target.value)}
                placeholder="Add a note for this student. These notes are confidential and are only viewable by people who have access to this Child Study."
                variant="outlined"
              />
            </FormControl>
            <Button type="submit" color="primary" variant="contained">
              {`${actionType} Note`}
            </Button>
          </Stack>
        </form>
      </Box>
    </Dialog>
  )
}

ChildStudyNoteModal.propTypes = {
  childStudyId: PropTypes.number.isRequired,
  fetchRequirements: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  editing: PropTypes.bool,
  noteId: PropTypes.number,
  noteText: PropTypes.string
}

const mapDispatchToProps = {
  fetchRequirements
}

export default connect(null, mapDispatchToProps)(ChildStudyNoteModal)
