import React, { useMemo } from 'react'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Box, Chip } from '@mui/material'
import { isEmpty, toUpper, castArray, startCase } from 'lodash'

const DistrictMetricsTable = ({
  data,
  schools,
  academic_interventions,
  team_meetings
}) => {
  const findBySchoolId = (
    school_id,
    records_with_school_id,
    key = 'school_id'
  ) =>
    schools
      ? castArray(records_with_school_id).find(
          record => record?.[key] === school_id
        )
      : ''

  const zeroIfEmpty = param => (typeof param === 'number' ? param : 0)

  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorFn: row =>
          findBySchoolId(row?.school_id, schools, 'id')?.display_name,
        id: 'school_name',
        header: 'School Name',
        Header: () => (
          <Box>
            School
            <br />
            Name
          </Box>
        ),
        size: 100
      },
      {
        accessorFn: row =>
          findBySchoolId(row?.school_id, schools, 'id')?.network,
        id: 'school_network',
        header: 'School Network',
        Header: () => (
          <Box>
            School
            <br />
            Network
          </Box>
        ),
        size: 100
      },
      {
        accessorFn: row => {
          const designation = findBySchoolId(
            row?.school_id,
            schools,
            'id'
          )?.designation
          return isEmpty(designation)
            ? 'No Designation'
            : `${toUpper(designation)} Designation`
        },
        id: 'school_designation',
        header: 'School Designation',
        Header: () => (
          <Box>
            School
            <br />
            Designation
          </Box>
        ),
        size: 100
      },
      {
        accessorFn: row => {
          const category = findBySchoolId(
            row?.school_id,
            schools,
            'id'
          )?.category
          const display = startCase(`${category} School`)

          return display
        },
        id: 'school_category',
        header: 'School Category',
        Header: () => (
          <Box>
            School
            <br />
            Category
          </Box>
        ),
        size: 100
      },
      {
        accessorKey: 'enrollment',
        id: 'enrollment',
        header: 'School Enrollment',
        Header: () => (
          <Box>
            School
            <br />
            Enrollment
          </Box>
        ),
        size: 150
      },
      {
        accessorFn: row => `${Math.round(row?.attendance)} %`,
        id: 'attendance',
        header: 'Attendance',
        size: 150
      },
      {
        accessorFn: row => zeroIfEmpty(row?.map_reading_tiers?.advanced),
        id: 'map_tier_advanced',
        header: 'MAP Tier Advanced',
        size: 150
      },
      {
        accessorFn: row => zeroIfEmpty(row?.map_reading_tiers?.one),
        header: 'MAP Tier One',
        id: 'map_tier_one',
        size: 150
      },
      {
        accessorFn: row => zeroIfEmpty(row?.map_reading_tiers?.one_plus),
        header: 'MAP Tier One Plus',
        id: 'map_tier_one_plus',
        size: 150
      },
      {
        accessorFn: row => zeroIfEmpty(row?.map_reading_tiers?.two),
        header: 'MAP Tier Two',
        id: 'map_tier_two',
        size: 150
      },
      {
        accessorFn: row => zeroIfEmpty(row?.map_reading_tiers?.three),
        header: 'MAP Tier Three',
        id: 'map_tier_three',
        size: 150
      },
      {
        accessorFn: row =>
          zeroIfEmpty(
            findBySchoolId(row?.school_id, academic_interventions)
              ?.used_this_week_count_sum
          ),
        header: 'Academic Inteventions Used Last Week',
        size: 150
      },
      {
        accessorFn: row =>
          zeroIfEmpty(
            findBySchoolId(row?.school_id, academic_interventions)
              ?.threshold_met_count_sum
          ),
        header: 'Academic Inteventions Threshold Met Last Week',
        id: 'academic_intervention_threshold_met_count_sum',
        size: 150
      },
      {
        accessorFn: row =>
          zeroIfEmpty(
            findBySchoolId(row?.school_id, academic_interventions)
              ?.threshold_not_met_count_sum
          ),
        header: 'Academic Inteventions Threshold Not Met Last Week',
        id: 'academic_intervention_threshold_not_met_count_sum',
        size: 150,
        Cell: ({ renderedCellValue, row }) => {
          const not_met = Number(
            zeroIfEmpty(
              findBySchoolId(row?.original?.school_id, academic_interventions)
                ?.threshold_not_met_count_sum
            )
          )

          const met = Number(
            zeroIfEmpty(
              findBySchoolId(row?.original?.school_id, academic_interventions)
                ?.threshold_met_count_sum
            )
          )

          const conditionalCell =
            not_met > met ? (
              <Chip color="warning" label={renderedCellValue} />
            ) : (
              <Box>{renderedCellValue}</Box>
            )

          return conditionalCell
        }
      },
      {
        accessorFn: row =>
          zeroIfEmpty(
            findBySchoolId(row?.school_id, team_meetings)
              ?.number_of_mtss_meetings
          ),
        header: 'MTSS Meetings',
        id: 'meetings_mtss',
        size: 150
      },
      {
        accessorFn: row =>
          zeroIfEmpty(
            findBySchoolId(row?.school_id, team_meetings)
              ?.number_of_attendance_meetings
          ),
        header: 'Attendance Meetings',
        id: 'meetings_attendance',
        size: 150
      },
      {
        accessorKey: 'behavior_tiers.tier_two_behavior',
        header: 'Behavior Tier 2',
        id: 'behavior_tier_2',
        size: 150
      },
      {
        accessorKey: 'behavior_tiers.tier_three_behavior',
        header: 'Behavior Tier 3',
        id: 'behavior_tier_3',
        size: 150
      },
      {
        accessorKey:
          'completed_informal_observation_stats.total_informal_observations',
        header: 'Total Informal Observations',
        id: 'informal_observations_total',
        size: 150
      },
      {
        accessorKey:
          'completed_informal_observation_stats.unique_informal_observations',
        header: 'Unique Informal Observations',
        id: 'informal_observations_unique',
        size: 150
      },
      {
        accessorKey:
          'completed_informal_observation_stats.total_informal_observations_with_three_plus_average_score',
        header: 'Total Informal Observations With Three Plus Average Score',
        id: 'informal_observations_total_three_plus_average_score',
        size: 150
      },
      {
        accessorKey:
          'completed_informal_observation_stats.unique_informal_observations_with_three_plus_average_score',
        header: 'Unique Informal Observations With Three Plus Average Score',
        id: 'informal_observations_unique_three_plus_average_score',
        size: 150
      },
      {
        accessorKey: 'six_week_intervention_stats.academic_followed_up',
        header: 'Interventon Academic Followed Up',
        id: 'six_week_intervention_stats_academic_followed_up',
        size: 150
      },
      {
        accessorKey: 'six_week_intervention_stats.behavior_followed_up',
        header: 'Intervention Behavior Followed Up',
        id: 'six_week_intervention_stats_behavior_followed_up',
        size: 150
      },
      {
        accessorKey: 'six_week_intervention_stats.attendance_followed_up',
        header: 'Intervention Attendance Followed Up',
        id: 'six_week_intervention_stats_attendance_followed_up',
        size: 150
      },
      {
        accessorKey: 'six_week_intervention_stats.academic_did_not_follow_up',
        header: 'Intervention Academic Did Not Follow Up',
        id: 'six_week_intervention_stats_academic_did_not_follow_up',
        size: 150
      },
      {
        accessorKey: 'six_week_intervention_stats.behavior_did_not_follow_up',
        header: 'Intervention Behavior Did Not Follow Up',
        id: 'six_week_intervention_stats_behavior_did_not_follow_up',
        size: 150
      },
      {
        accessorKey: 'six_week_intervention_stats.attendance_did_not_follow_up',
        header: 'Intervention Attendance Did Not Follow Up',
        id: 'six_week_intervention_stats_Attendance_did_not_follow_up',
        size: 150
      }
    ],
    [schools, academic_interventions]
  )

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enablePagination: false,
    enableColumnFilterModes: true,
    enableColumnOrdering: true,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableGrouping: true,
    initialState: {
      grouping: ['school_category', 'school_network'],
      columnPinning: {
        left: ['school_name']
      },
      expanded: true,
      pagination: {
        pageIndex: 0,
        pageSize: 100
      },
      sorting: [
        {
          id: 'school_name',
          desc: false
        }
      ]
    }
  })

  return (
    <Box id="district-metrics-table">
      <MaterialReactTable table={table} />
    </Box>
  )
}

export default DistrictMetricsTable
