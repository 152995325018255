import PropTypes from 'prop-types'
export { fetchInterventionActions } from './actions'
export { reducer } from './reducer'

export const InterventionActionPropType = PropTypes.shape({
  id: PropTypes.number
})

export const InterventionActionsPropType = PropTypes.arrayOf(
  InterventionActionPropType
)
