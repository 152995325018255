import { getBaseUrl, get } from 'utils/ajax'
import { alert } from 'utils/alert'

const getAssignments = (studentId, params = {}) => {
  return get(`${getBaseUrl()}/students/${studentId}/assignments`, {
    params
  }).catch(() => {
    alert('There was an error getting assignments.', 'danger')
  })
}

export default {
  getAssignments
}
