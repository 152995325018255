import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { default as SharedPage } from 'components/shared/page'
import PageContainer from 'shared/page-container'
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
  TextField,
  Link as MuiLink
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import DownloadIcon from '@mui/icons-material/Download'
import { Link, Route, Switch } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import PrintIcon from '@mui/icons-material/Print'
import EditIcon from '@mui/icons-material/Edit'
import TranslateIcon from '@mui/icons-material/Translate'
import { MentalWellnessReferralForm } from './MentalWellnessReferralForm'
import {
  patchMentalWellnessReferral,
  postMentalWellnessReferral,
  destroyMentalWellnessReferral,
  getMentalWellnessReferral,
  getMentalWellnessReferrals,
  getPrefilledMentalWellnessReferral,
  getMentalWellnessReferralReports
} from 'api/MentalWellnessReferral'
import moment from 'moment'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import StudentSelector from '../shared/search/studentSelector'
import DocumentsManager from 'components/shared/documents-manager'
import SimpleAutocomplete from 'shared/simple-autocomplete'
import { handleRailsRequestJsResponse } from 'utils/handleRailsRequestJsResponse'
import { isArray, isString } from 'lodash'
import { useDebounce } from 'hooks/useDebounce'
import { styled } from '@mui/system'
import MentalWellnessReferralInstructions from './instructions'
import { withRouterPropTypes } from 'utils/react-router-prop-types'
import { useSelector } from 'react-redux'

export const basePath = '/mental_wellness_referrals'

export function Routes() {
  return (
    <Switch>
      <Route
        exact
        path={`${basePath}`}
        component={MentalWellnessReferralIndex}
      />
      <Route
        exact
        path={`${basePath}/new`}
        component={MentalWellnessReferralNew}
      />
      <Route
        exact
        path={`${basePath}/reports`}
        component={MentalWellnessReferralReports}
      />
      <Route
        exact
        path={`${basePath}/:id/documents`}
        component={MentalWellnessReferralDocuments}
      />
      <Route
        exact
        path={`${basePath}/:id/print`}
        component={MentalWellnessReferralPrint}
      />
      <Route
        path={`${basePath}/:id/edit`}
        component={MentalWellnessReferralEdit}
      />
      <Route path={`${basePath}/:id`} component={MentalWellnessReferralShow} />
    </Switch>
  )
}
Routes.basePath = basePath

function Page({ children, breadcrumbs = [], breadcrumb, ...props }) {
  return (
    <SharedPage {...props}>
      <PageContainer>
        <Breadcrumbs aria-label="breadcrumb" variant={'overline'}>
          <MuiLink component={Link} to="/mental_wellness_referrals">
            Mental Wellness Referrals
          </MuiLink>

          {breadcrumbs.map((crumb, index) =>
            isString(crumb) ? (
              <Typography key={index} variant="overline" color={'text.primary'}>
                {crumb}
              </Typography>
            ) : (
              <MuiLink key={index} component={Link} {...crumb} />
            )
          )}

          <Typography variant="overline" color={'text.primary'}>
            {breadcrumb || props.title}
          </Typography>
        </Breadcrumbs>

        <Typography variant={'h1'}>{props.title}</Typography>

        {children}
      </PageContainer>
    </SharedPage>
  )
}

Page.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.string, MuiLink.propTypes])
  ),
  breadcrumb: PropTypes.string,
  ...SharedPage.propTypes
}

const StripedTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  }
}))

function MentalWellnessReferralIndex() {
  const user = useSelector(state => state.currentUser.user)
  const [tableMutateEvents, setTableMutateEvents] = useState(0)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)

  const { data: forms } = useQuery(
    ['MentalWellnessReferral-index-mui', tableMutateEvents, debouncedSearch],
    async () => {
      const params = { search: search }
      const response = await getMentalWellnessReferrals(params)
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  const [schoolName, setSchool] = useState('ALL')

  const filterMentalWellnessReferral = () => {
    if (schoolName !== 'ALL') {
      return forms.filter(form => form.school.name == schoolName)
    } else {
      return forms
    }
  }

  const handleFormDestroy = async id => {
    const response = await destroyMentalWellnessReferral(id)
    await handleRailsRequestJsResponse(response)
    setTableMutateEvents(tableMutateEvents + 1)
  }

  const schoolOptions = [
    'ALL',
    ...new Set(isArray(forms) ? forms.map(form => form.school.name).sort() : [])
  ]

  const studentFullName = student =>
    `${student.first_name} ${student.last_name}`

  return (
    <Page
      name="All Mental Wellness Referrals"
      title="Mental Wellness Referrals"
      breadcrumb={'All Referrals'}
    >
      <Grid container spacing={2} justifyContent="flex-end" width={'100%'}>
        {!user?.rights?.is_mental_wellness_referral_team_member ? null : (
          <Grid item>
            <Button
              id="mwr-reports-button"
              startIcon={<AnalyticsIcon />}
              component={Link}
              variant="contained"
              color="primary"
              to="/mental_wellness_referrals/reports"
            >
              Reports
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button
            startIcon={<EditNoteIcon />}
            component={Link}
            variant="contained"
            color="primary"
            to="/mental_wellness_referrals/new"
          >
            New Mental Wellness Referral
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="space-around" width={'100%'}>
        <Grid item xs={12} sm={6}>
          <TextField
            size={'medium'}
            label={'Search by Name or Student Number'}
            value={search}
            onChange={val => setSearch(val.target.value)}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SimpleAutocomplete
            key={`school-${schoolName}`}
            label={'Filter by School'}
            options={schoolOptions}
            value={schoolName}
            onChange={option => setSchool(option || 'ALL')}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table id="mwr-index-table">
          <TableHead>
            <TableRow>
              <TableCell>Actions</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Agency</TableCell>
              <TableCell>Student Number</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>School Name</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isArray(forms) &&
              filterMentalWellnessReferral().map(form => (
                <StripedTableRow key={form.id}>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                      <Button
                        component={Link}
                        variant="contained"
                        color="primary"
                        size="small"
                        to={`/mental_wellness_referrals/${form.id}`}
                      >
                        Show
                      </Button>
                      <Button
                        component={Link}
                        variant="contained"
                        color="secondary"
                        size="small"
                        to={`/mental_wellness_referrals/${form.id}/edit?cacheKey=${form.updated_at}`}
                      >
                        Edit
                      </Button>
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="outlined"
                        color="error"
                        size="small"
                        onClick={() => handleFormDestroy(form.id)}
                      >
                        Destroy
                      </Button>
                    </Stack>
                  </TableCell>
                  <TableCell>{form.status}</TableCell>
                  <TableCell>{form.mental_wellness_agency.name}</TableCell>
                  <TableCell>{form.student.number}</TableCell>
                  <TableCell>
                    <Link to={`/student/${form.student_id}/overview`}>
                      {studentFullName(form.student)}
                    </Link>
                  </TableCell>
                  <TableCell>{form.school.name}</TableCell>
                  <TableCell>{moment(form.created_at).fromNow()}</TableCell>
                  <TableCell>{moment(form.updated_at).fromNow()}</TableCell>
                </StripedTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  )
}

const MentalWellnessReferralNew = ({ history, location }) => {
  const search = new URLSearchParams(location.search)
  const student_id = search.get('student_id')
  const closeAfterSubmit = search.get('closeAfterSubmit')

  const onSubmitValidated = async mental_wellness_referral => {
    const response = await postMentalWellnessReferral(mental_wellness_referral)
    const newForm = await handleRailsRequestJsResponse(response)

    if (newForm && closeAfterSubmit) {
      window.close()
    } else if (newForm) {
      history.push(`/mental_wellness_referrals/${newForm.data.id}`)
    }
  }

  const { data: mental_wellness_referral } = useQuery(
    ['MentalWellnessReferral-new-mui', student_id],
    async () => {
      const response = await getPrefilledMentalWellnessReferral({
        student_id
      })
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: null,
      refetchOnWindowFocus: false,
      enabled: student_id ? true : false
    }
  )

  return (
    <Page
      name="New Mental Wellness Referral"
      title="New Mental Wellness Referral"
      breadcrumb={'New Referral'}
    >
      <MentalWellnessReferralInstructions />

      <Stack>
        <Box>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <StudentSelector redirectTo="/mental_wellness_referrals/new?student_id=" />
            </Grid>
          </Grid>
        </Box>

        <Paper sx={{ p: 4 }}>
          {mental_wellness_referral && (
            <MentalWellnessReferralForm
              prefill={mental_wellness_referral}
              onSubmitValidated={onSubmitValidated}
            />
          )}
        </Paper>
      </Stack>
    </Page>
  )
}
MentalWellnessReferralNew.propTypes = {
  ...withRouterPropTypes
}

const MentalWellnessReferralShow = ({ location, match }) => {
  const id = match.params.id
  const search = new URLSearchParams(location.search)
  const locale = search.get('locale') || 'en'
  const bustCache = search.get('cacheKey') || 'none'

  const { data: mental_wellness_referral } = useQuery(
    ['MentalWellnessReferral-show-mui', id, bustCache],
    async () => {
      const response = await getMentalWellnessReferral({
        id: id
      })
      const json = await handleRailsRequestJsResponse(response)

      return json.data
    },
    {
      initialData: null,
      refetchOnWindowFocus: true
    }
  )

  return (
    <Page
      name="Show Mental Wellness Referral"
      title="Show Mental Wellness Referral"
      breadcrumb={`Show Referral #${id}`}
    >
      {mental_wellness_referral && (
        <>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                startIcon={<EditIcon />}
                component={Link}
                variant="contained"
                color="secondary"
                to={`/mental_wellness_referrals/${mental_wellness_referral.id}/edit?cacheKey=${mental_wellness_referral.updated_at}`}
              >
                Edit
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                startIcon={<PrintIcon />}
                variant="contained"
                color="primary"
                target="_blank"
                to={`/mental_wellness_referrals/${mental_wellness_referral.id}/print/?locale=${locale}`}
              >
                Print
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                startIcon={<AttachFileIcon />}
                variant="contained"
                color="primary"
                to={`/mental_wellness_referrals/${mental_wellness_referral.id}/documents`}
              >
                Documents
              </Button>
            </Grid>
            <Grid item>
              <Button
                component={Link}
                startIcon={<TranslateIcon />}
                variant="contained"
                color="primary"
                to={locale == 'en' ? '?locale=es' : '?locale=en'}
              >
                Translate to {locale == 'en' ? 'Spanish' : 'English'}
              </Button>
            </Grid>
          </Grid>
          <MentalWellnessReferralForm
            disabled={true}
            prefill={mental_wellness_referral}
          />
        </>
      )}
    </Page>
  )
}
MentalWellnessReferralShow.propTypes = {
  ...withRouterPropTypes
}

const MentalWellnessReferralEdit = ({ history, location, match }) => {
  const id = match.params.id
  const search = new URLSearchParams(location.search)
  const bustCache = search.get('cacheKey') || 'none'
  const closeAfterSubmit = search.get('closeAfterSubmit')

  const onSubmitValidated = async form => {
    const response = await patchMentalWellnessReferral(id, form)
    const editedForm = await handleRailsRequestJsResponse(response)

    if (editedForm?.data && closeAfterSubmit) {
      window.close()
    } else if (editedForm) {
      history.push(
        `/mental_wellness_referrals/${id}?cacheKey=${editedForm.data.updated_at}`
      )
    }
  }

  const { data: mental_wellness_referral } = useQuery(
    ['MentalWellnessReferral-edit-mui', id, bustCache],
    async () => {
      const response = await getMentalWellnessReferral({
        id: id
      })
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: null,
      refetchOnWindowFocus: false
    }
  )
  return (
    <Page
      name="Edit Mental Wellness Referral"
      title="Edit Mental Wellness Referral"
      breadcrumb={`Edit Referral #${id}`}
    >
      {mental_wellness_referral && (
        <MentalWellnessReferralForm
          prefill={mental_wellness_referral}
          onSubmitValidated={onSubmitValidated}
        />
      )}
    </Page>
  )
}
MentalWellnessReferralEdit.propTypes = {
  ...withRouterPropTypes
}

const MentalWellnessReferralPrint = ({ match }) => {
  const id = match.params.id
  const [formLoaded, setFormLoaded] = useState(false)

  const { data: mental_wellness_referral } = useQuery(
    ['MentalWellnessReferral-print-mui', id],
    async () => {
      const response = await getMentalWellnessReferral({
        id: id
      })

      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: null,
      refetchOnWindowFocus: false
    }
  )

  useLayoutEffect(() => {
    mental_wellness_referral && formLoaded && window.print()
  }, [mental_wellness_referral, formLoaded])

  return (
    mental_wellness_referral && (
      <Stack sx={{ m: 4 }}>
        <Typography variant="h3">
          Tulsa Public Schools Mental Wellness Referral
        </Typography>

        <MentalWellnessReferralForm
          disabled={true}
          prefill={mental_wellness_referral}
          setFormLoaded={setFormLoaded}
        />
      </Stack>
    )
  )
}
MentalWellnessReferralPrint.propTypes = {
  ...withRouterPropTypes
}

const MentalWellnessReferralDocuments = ({ match }) => {
  const id = match.params.id
  const breadcrumbs = [
    { to: `/mental_wellness_referrals/${id}`, children: `Referral #${id}` }
  ]

  return (
    <Page
      name="Mental Wellness Referral Documents"
      title="Mental Wellness Referral Documents"
      breadcrumbs={breadcrumbs}
      breadcrumb={'Documents'}
    >
      <DocumentsManager
        associations={{
          documentable_id: id,
          documentable_type: 'MentalWellnessReferral',
          category: 'Mental Wellness Referral'
        }}
      />
    </Page>
  )
}
MentalWellnessReferralDocuments.propTypes = {
  ...withRouterPropTypes
}

const MentalWellnessReferralReports = () => {
  const { data: reports } = useQuery(
    ['MentalWellnessReferral-reports-mui'],
    async () => {
      const response = await getMentalWellnessReferralReports()
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: null,
      refetchOnWindowFocus: false
    }
  )

  const agency_with_count_of_current_status =
    reports?.agency_with_count_of_current_status

  return (
    <Page
      name="Mental Wellness Referral Reports"
      title="Mental Wellness Referral Reports"
      breadcrumb={'Reports'}
    >
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item>
          <Button
            id="mwr-csv-button"
            startIcon={<DownloadIcon />}
            variant="contained"
            color="secondary"
            href="/api/web/mental_wellness_referrals.csv"
          >
            Download All Referrals as CSV
          </Button>
        </Grid>
      </Grid>

      <Typography variant="h3">Current Status Counts By Agency</Typography>

      <TableContainer component={Paper}>
        <Table aria-label="Referrals by agency">
          <TableHead>
            <TableRow>
              <TableCell>Agency Name</TableCell>
              <TableCell align="right">Total Referrals</TableCell>
              <TableCell align="right">New Referral</TableCell>
              <TableCell align="right">Intake Completed</TableCell>
              <TableCell align="right">In Progress</TableCell>
              <TableCell align="right">Declined Services</TableCell>
              <TableCell align="right">Last Referral</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* shows referrals by agency w/ count of current status */}
            {agency_with_count_of_current_status &&
              Object.entries(agency_with_count_of_current_status).map(
                ([agency, current_status_counts]) => (
                  <StripedTableRow key={agency}>
                    <TableCell>{agency}</TableCell>
                    <TableCell align="right">
                      {current_status_counts['Total Referrals']}
                    </TableCell>
                    <TableCell align="right">
                      {current_status_counts['New Referral']}
                    </TableCell>
                    <TableCell align="right">
                      {current_status_counts['Intake Completed']}
                    </TableCell>
                    <TableCell align="right">
                      {current_status_counts['In Progress']}
                    </TableCell>
                    <TableCell align="right">
                      {current_status_counts['Declined Services']}
                    </TableCell>
                    <TableCell align="right">
                      {current_status_counts['Last Referral']}
                    </TableCell>
                  </StripedTableRow>
                )
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  )
}
MentalWellnessReferralReports.propTypes = {
  ...withRouterPropTypes
}

export default MentalWellnessReferralIndex

export {
  MentalWellnessReferralIndex,
  MentalWellnessReferralNew,
  MentalWellnessReferralEdit,
  MentalWellnessReferralShow,
  MentalWellnessReferralPrint,
  MentalWellnessReferralDocuments,
  MentalWellnessReferralReports
}
