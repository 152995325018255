import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardHeader,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'

export default function ChartOptions({
  type,
  subject,
  language,
  onOptionChanged
}) {
  const typeChanged = (event, val) => {
    if (val !== null) onOptionChanged('chartType', val)
  }
  const subjectChanged = (event, val) => {
    if (val !== null) onOptionChanged('chartSubject', val)
  }
  const languageChanged = (event, val) => {
    if (val !== null) onOptionChanged('chartLanguage', val)
  }

  return (
    <Box
      sx={{
        mt: 2
      }}
    >
      <Card>
        <CardHeader
          title={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <Typography variant="subtitle1">Chart Options</Typography>
              <Box
                sx={{
                  ml: 2
                }}
              >
                <ToggleButtonGroup
                  value={type}
                  onChange={typeChanged}
                  exclusive
                  aria-label="chart type"
                >
                  <ToggleButton
                    size="small"
                    value="proficiency"
                    aria-label="proficiency"
                  >
                    Proficiency
                  </ToggleButton>
                  <ToggleButton size="small" value="growth" aria-label="growth">
                    Growth
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              <Box
                sx={{
                  ml: 2
                }}
              >
                <ToggleButtonGroup
                  value={subject}
                  onChange={subjectChanged}
                  exclusive
                  aria-label="subject"
                >
                  <ToggleButton
                    size="small"
                    value="mathematics"
                    aria-label="mathematics"
                  >
                    Mathematics
                  </ToggleButton>
                  <ToggleButton
                    size="small"
                    value="reading"
                    aria-label="reading"
                  >
                    Reading
                  </ToggleButton>
                  <ToggleButton
                    size="small"
                    value="both"
                    aria-label="both"
                    disabled={type === 'growth'}
                  >
                    Both
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>

              <Box
                sx={{
                  ml: 2
                }}
              >
                <ToggleButtonGroup
                  value={language}
                  onChange={languageChanged}
                  exclusive
                  aria-label="language"
                >
                  <ToggleButton
                    size="small"
                    value="english"
                    aria-label="english"
                  >
                    English
                  </ToggleButton>
                  <ToggleButton
                    size="small"
                    value="spanish"
                    aria-label="spanish"
                  >
                    Spanish
                  </ToggleButton>
                  <ToggleButton size="small" value="both" aria-label="both">
                    Both
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
          }
        />
      </Card>
    </Box>
  )
}

ChartOptions.propTypes = {
  type: PropTypes.string,
  subject: PropTypes.string,
  language: PropTypes.string,
  onOptionChanged: PropTypes.func
}
