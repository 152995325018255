import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import logo from 'images/TPS_WHITE.png'
import {
  AppBar as MuiAppBar,
  Drawer,
  Divider,
  Link as MuiLink,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Stack,
  Toolbar,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import LayersIcon from '@mui/icons-material/Layers'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import FlagIcon from '@mui/icons-material/Flag'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SkateboardingIcon from '@mui/icons-material/Skateboarding'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import ConsecutiveSnackbars from './consecutive-snackbars'

const drawerWidth = 225

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}))

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: 0
    })
  })
)

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

function PageContainer({ children }) {
  const [open, setOpen] = useState(false)
  const drawerWidth = 225

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <ConsecutiveSnackbars />

      <AppBar position="fixed" open={open} color={'grey'}>
        <Toolbar>
          <IconButton
            id="test-open-left-nav-button"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>

          <Stack direction={'row'} sx={{ alignItems: 'center', flexGrow: 1 }}>
            <Box>
              <a href="/" style={{ padding: '0px' }}>
                <img src={logo} className="logo" style={{ height: '50px' }} />
              </a>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5">Admin Panel - Data Dashboard</Typography>
            </Box>
          </Stack>

          <Box>
            <Box component={MuiLink} href="/district/metrics">
              <Tooltip arrow title="Go back to dashboard" placement="left">
                <IconButton id="test-back-to-dashboard">
                  <ExitToAppIcon sx={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          'width': drawerWidth,
          'flexShrink': 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItemButton component={RouterLink} to="/admins/users">
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/admins/activities">
            <ListItemIcon>
              <SkateboardingIcon />
            </ListItemIcon>
            <ListItemText primary="Activities" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/admins/district_dates">
            <ListItemIcon>
              <CalendarMonthIcon />
            </ListItemIcon>
            <ListItemText primary="District Dates" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/admins/goals">
            <ListItemIcon>
              <FlagIcon />
            </ListItemIcon>
            <ListItemText primary="Goals" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/admins/jobs">
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Jobs" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/admins/schools">
            <ListItemIcon>
              <DirectionsBusIcon />
            </ListItemIcon>
            <ListItemText primary="Schools" />
          </ListItemButton>
          <ListItemButton component={RouterLink} to="/admins/system_statuses">
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="System Statuses" />
          </ListItemButton>
        </List>
      </Drawer>

      <Main open={open}>
        {/* Here we are using DrawerHeader as a spacer */}
        <DrawerHeader />

        {children}
      </Main>
    </Box>
  )
}

PageContainer.propTypes = {
  bannerImage: PropTypes.string,
  bannerPosition: PropTypes.string,
  isKiosk: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  logoColor: PropTypes.string,
  logoPosition: PropTypes.string,
  noSidebar: PropTypes.bool
}

export default PageContainer
