import { types } from './types'

const initialState = {
  byStudentId: {},
  riskScoresByStudentId: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DISCIPLINES:
      return {
        ...state,
        byStudentId: {
          ...state.byStudentId,
          [action.payload.studentId]: action.payload.data
        }
      }
    case types.SET_RISK_SCORES:
      return {
        ...state,
        riskScoresByStudentId: {
          ...state.riskScoresByStudentId,
          [action.payload.studentId]: action.payload.data
        }
      }
    default:
      return state
  }
}
