import React from 'react'
import PropTypes from 'prop-types'
import { RemoteTable } from 'components/shared/table'
import { Box, Link as MuiLink, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import CompareArrow from 'components/shared/table/compare_arrow'
import { theme } from '../app/theme'

export default class SchoolsTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
    totalPages: PropTypes.number,
    fetchData: PropTypes.func
  }

  renderCurrentCell(value) {
    const color =
      value === null || value === undefined ? 'text.secondary' : 'text.primary'

    const displayValue =
      value !== null && value !== undefined ? `${value}%` : '--'

    return (
      <Box display="flex" alignItems="center">
        <Typography variant="body2" color={color}>
          {displayValue}
        </Typography>
      </Box>
    )
  }

  renderChangeCell(value, inverse) {
    return <CompareArrow value={value} inverse={inverse} />
  }

  columns() {
    return [
      {
        Header: 'School',
        sortable: false,
        columns: [
          {
            id: 'name',
            Header: 'Name',
            accessor: obj => obj,
            minWidth: 275,
            Cell: props => (
              <MuiLink
                component={RouterLink}
                to={`/schools/${props.value.id}/overview`}
                sx={{
                  textDecoration: 'none',
                  color: theme.palette.primary.main,
                  fontWeight: 'bold'
                }}
              >
                {props.value.name}
              </MuiLink>
            )
          }
        ]
      },
      {
        Header: 'Attendance',
        sortable: false,
        columns: [
          {
            Header: 'Current',
            accessor: 'current_school_metric.attendance',
            minWidth: 75,
            Cell: props => this.renderCurrentCell(props.value)
          },
          {
            Header: 'Change',
            accessor: 'attendance_difference',
            minWidth: 75,
            Cell: props => this.renderChangeCell(props.value, false)
          }
        ]
      },
      {
        Header: 'Chronic',
        sortable: false,
        columns: [
          {
            Header: 'Current',
            accessor: 'current_school_metric.absenteeism',
            minWidth: 75,
            Cell: props => this.renderCurrentCell(props.value)
          },
          {
            Header: 'Change',
            accessor: 'absenteeism_difference',
            minWidth: 75,
            Cell: props => this.renderChangeCell(props.value, true)
          }
        ]
      },
      {
        Header: 'Suspensions',
        sortable: false,
        columns: [
          {
            Header: 'Current',
            accessor: 'current_school_metric.suspensions',
            minWidth: 75,
            Cell: props => this.renderCurrentCell(props.value)
          },
          {
            Header: 'Change',
            accessor: 'suspensions_difference',
            minWidth: 75,
            Cell: props => this.renderChangeCell(props.value, true)
          }
        ]
      },
      {
        Header: 'Proficiency',
        sortable: false,
        columns: [
          {
            Header: 'Current',
            accessor: 'current_school_metric.proficiency',
            minWidth: 75,
            Cell: props => this.renderCurrentCell(props.value)
          },
          {
            Header: 'Change',
            accessor: 'proficiency_difference',
            minWidth: 75,
            Cell: props => this.renderChangeCell(props.value, false)
          }
        ]
      },
      {
        Header: 'Math Growth',
        sortable: false,
        columns: [
          {
            Header: 'Current',
            accessor: 'current_school_metric.math_growth',
            minWidth: 75,
            Cell: props => this.renderCurrentCell(props.value)
          },
          {
            Header: 'Change',
            accessor: 'math_growth_difference',
            minWidth: 75,
            Cell: props => this.renderChangeCell(props.value, false)
          }
        ]
      },
      {
        Header: 'Reading Growth',
        sortable: false,
        columns: [
          {
            Header: 'Current',
            accessor: 'current_school_metric.reading_growth',
            minWidth: 75,
            Cell: props => this.renderCurrentCell(props.value)
          },
          {
            Header: 'Change',
            accessor: 'reading_growth_difference',
            minWidth: 75,
            Cell: props => this.renderChangeCell(props.value, false)
          }
        ]
      },
      {
        Header: 'Third Grade',
        sortable: false,
        columns: [
          {
            Header: 'Current',
            accessor: 'current_school_metric.third_grade_proficiency',
            minWidth: 75,
            Cell: props => this.renderCurrentCell(props.value)
          },
          {
            Header: 'Change',
            accessor: 'third_grade_proficiency_difference',
            minWidth: 75,
            Cell: props => this.renderChangeCell(props.value, false)
          }
        ]
      }
    ]
  }

  render() {
    const { data, loading, fetchData, totalPages } = this.props

    return (
      <Box>
        <RemoteTable
          columns={this.columns()}
          data={data}
          onFetchData={fetchData}
          pages={totalPages}
          loading={loading}
        />
      </Box>
    )
  }
}
