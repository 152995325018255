import { createSelector } from 'reselect'
import { sortBy, values } from 'lodash'
import { mapToOptions, mapObjectsToOptions } from 'utils/select-option-helpers'

const currentSchoolSelector = ({ schools }) => schools.byId[schools.schoolId]

const cohortOptionsSelector = createSelector(currentSchoolSelector, school =>
  mapToOptions(school && school.cohorts)
)

const allGradeLevels = [
  'pre-k (3)',
  'pre-k',
  'k',
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th'
]

const gradeLevelOptionsSelector = createSelector(
  currentSchoolSelector,
  school => {
    return mapToOptions(
      school.name === 'ALL SCHOOLS' ? allGradeLevels : school.grade_levels
    )
  }
)

const schoolOptionsSelector = ({ schools }, opts = {}) => {
  const allOptions = mapObjectsToOptions(
    sortBy(values(schools.byId), 'name'),
    'name',
    'id'
  )

  const allSchoolsOption = allOptions.find(opt => opt.value === null)
  const schoolOptions = allOptions.filter(opt => opt.value !== null)

  if (opts.includeAllSchoolsOption) {
    return [allSchoolsOption, ...schoolOptions]
  } else {
    return schoolOptions
  }
}

export default {
  cohortOptions: cohortOptionsSelector,
  currentSchool: currentSchoolSelector,
  gradeLevelOptions: gradeLevelOptionsSelector,
  schoolOptions: schoolOptionsSelector
}
