import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import { times } from 'lodash'

const SeatsTable = ({ sections }) => {
  const seatIcon = idx => {
    return <PersonIcon key={idx} color="secondary" />
  }

  const renderSectionDetails = section => {
    return (
      <TableCell key={section.id}>
        {`${section.seat_count} teacher${
          section.seat_count > 1 ? 's' : ''
        } assigned`}
        <Box>{times(section.seat_count, seatIcon)}</Box>
      </TableCell>
    )
  }

  return (
    <Box
      overflowX="auto"
      sx={{
        width: '100%'
      }}
    >
      <TableContainer minWidth="650">
        <Table>
          <TableHead>
            <TableRow>
              {sections.map(section => (
                <TableCell key={section.id}>{section.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {sections.map(section => renderSectionDetails(section))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

SeatsTable.propTypes = {
  sections: PropTypes.array.isRequired
}

export default SeatsTable
