import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Box,
  Chip,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material'

import PersonIcon from '@mui/icons-material/Person'
import EnvelopeIcon from '@mui/icons-material/Email'
import FileIcon from '@mui/icons-material/FilePresent'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import PhoneIcon from '@mui/icons-material/Phone'
import PaperBox from 'components/shared/PaperBox'
import { PersonSearch } from '@mui/icons-material'

const TeacherInfo = ({ teacher }) => {
  const Info = () => {
    return (
      <Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="overline">Employee ID</Typography>
              </TableCell>
              <TableCell>
                <PersonIcon /> {teacher.employee_id}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="overline">Email</Typography>
              </TableCell>
              <TableCell>
                <Link href={`mailto:${teacher.email}`}>
                  <EnvelopeIcon /> {teacher.email.split('@')[0]}
                </Link>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="overline">Certifications</Typography>
              </TableCell>
              <TableCell>
                <Stack spacing={2}>
                  {teacher.certifications &&
                    teacher.certifications.map(c => (
                      <Tooltip
                        key={c.id}
                        arrow
                        placement="right"
                        color={
                          moment(c.expiration_date) < moment()._d
                            ? 'error'
                            : 'success'
                        }
                        title={moment(c.expiration_date).format('MM/DD/YYYY')}
                      >
                        <Chip label={c.area} size="small" />
                      </Tooltip>
                    ))}
                </Stack>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    )
  }

  const Links = () => {
    return (
      <Box sx={{ p: 2 }}>
        <Stack sx={{ pl: 2 }}>
          <Typography variant="overline">Helpful Links</Typography>
          <List>
            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                href="https://ok.pcgeducation.com/oktulsa"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon>
                  <LocalLibraryIcon />
                </ListItemIcon>
                <ListItemText primary="Looking for EdPlan?" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                href="https://drive.google.com/file/d/0B6wCjxmubjamUnBMT01WTk9PdFU/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary="Need help from Language Line?" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                href="https://tulsaschools.az1.qualtrics.com/jfe/form/SV_dmTWdiQiV0f1lFb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon>
                  <FileIcon />
                </ListItemIcon>
                <ListItemText primary="Need a document translated?" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                component={Link}
                href="https://tp1.goteachpoint.com/sso/login?district=tulsaschools"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ListItemIcon>
                  <PersonSearch />
                </ListItemIcon>
                <ListItemText primary="Looking for Vector Evaluations?" />
              </ListItemButton>
            </ListItem>
          </List>
        </Stack>
      </Box>
    )
  }

  return (
    <PaperBox title="Teacher Information">
      <Box>
        <Info />
        <Links />
      </Box>
    </PaperBox>
  )
}

TeacherInfo.propTypes = {
  teacher: PropTypes.object.isRequired
}

export default TeacherInfo
