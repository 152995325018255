import React from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box } from '@mui/material'

function NewWindowButton({ href, text, ...props }) {
  return (
    <Button
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        columnGap: 0.5,
        ...props.sx
      }}
    >
      <Box>{text}</Box>
      <OpenInNewIcon fontSize={'inherit'} display={'block'} />
    </Button>
  )
}

NewWindowButton.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default NewWindowButton
