import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { Timeline } from '@mui/lab'
import TimelineRow from './row'

function ConcernLogTimeline({ concern }) {
  const [conciseView, setConciseView] = useState(true)

  return (
    <React.Fragment>
      <Timeline align="left" style={{ paddingLeft: 0, paddingRight: 0 }}>
        {concern.logs.map(
          (log, index) =>
            (!conciseView || (conciseView && index < 4)) && (
              <TimelineRow log={log} concern={concern} key={`log-${log.id}`} />
            )
        )}

        {conciseView && concern.logs.length == 0 && (
          <Typography variant="body1">No concern logs found.</Typography>
        )}

        {conciseView && concern.logs.length > 4 ? (
          <Typography
            variant="subtitle1"
            style={{ color: '#28abe2', cursor: 'pointer' }}
            onClick={() => setConciseView(false)}
          >
            See All ({concern.logs.length} Total)
          </Typography>
        ) : null}
      </Timeline>
    </React.Fragment>
  )
}

ConcernLogTimeline.propTypes = {
  concern: PropTypes.object.isRequired
}

export default ConcernLogTimeline
