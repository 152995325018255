import { fetchSchoolDetails } from 'api/schools'
import { types } from './reducer'
import { actions as filterActions } from 'modules/student-filters'

export const setSchoolsList = schools => ({
  type: types.SET_SCHOOLS_LIST,
  payload: schools
})

export const selectSchool = value => {
  const id = parseInt(value) || null

  return dispatch => {
    dispatch({ type: types.SCHOOL_SELECTED, payload: id })

    if (id) {
      dispatch(filterActions.schoolChanged(id))
      fetchSchoolDetails(id).then(resp =>
        dispatch({ type: types.SET_DETAILS_FOR_SCHOOL, payload: resp })
      )
    }
  }
}

export const determineCurrentSchool = () => {
  return dispatch => {
    dispatch({
      type: types.DETERMINE_CURRENT_SCHOOL
    })
  }
}

export default {
  selectSchool
}
