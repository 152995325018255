import {
  createStore as createReduxStore,
  applyMiddleware,
  compose,
  combineReducers
} from 'redux'
import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createHistory from 'history/createBrowserHistory'

import { reducer as alertsReducer } from 'modules/alerts'
import { reducer as assignmentsReducer } from 'modules/assignments'
import { reducer as attendancesReducer } from 'modules/attendances'
import { reducer as childStudiesReducer } from 'modules/child-studies'
import { reducer as configReducer } from 'modules/config'
import { reducer as currentUserReducer } from 'modules/current-user'
import { reducer as disciplinesReducer } from 'modules/disciplines'
import { reducer as exportsReducer } from 'modules/exports'
import { reducer as gradesReducer } from 'modules/grades'
import { reducer as goalsReducer } from 'modules/goals'
import { reducer as interventionActionsReducer } from 'modules/intervention-actions'
import { reducer as requirementsReducer } from 'modules/requirements'
import { reducer as schoolsReducer } from 'modules/schools'
import { reducer as sectionsReducer } from 'modules/sections'
import { reducer as studentFiltersReducer } from 'modules/student-filters'
import { reducer as studentsReducer } from 'modules/students'
import { reducer as teachersReducer } from 'modules/teachers'

// admins store
import { reducer as jobsReducer } from './modules/admins/jobs'
import { reducer as adminSchoolsReducer } from './modules/admins/schools'
import { reducer as usersReducer } from './modules/admins/users'

export const RESET_STATE = 'app/reset_state'
export const history = createHistory()
const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

const i18nInit = () => {
  I18n.jsT = (key, options) => I18n.t(key, { scope: 'javascript', ...options })
}

if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger')
  middleware.push(logger)
}

if (process.env.NODE_ENV === 'development') {
  window.devToolsExtension && enhancers.push(window.devToolsExtension())
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers)

const createAppReducer = history =>
  combineReducers({
    router: connectRouter(history),
    alerts: alertsReducer,
    assignments: assignmentsReducer,
    attendances: attendancesReducer,
    childStudies: childStudiesReducer,
    config: configReducer,
    currentUser: currentUserReducer,
    disciplines: disciplinesReducer,
    exports: exportsReducer,
    grades: gradesReducer,
    goals: goalsReducer,
    interventionActions: interventionActionsReducer,
    requirements: requirementsReducer,
    schools: schoolsReducer,
    sections: sectionsReducer,
    studentFilters: studentFiltersReducer,
    students: studentsReducer,
    teachers: teachersReducer,
    // admins store
    jobs: jobsReducer,
    adminSchools: adminSchoolsReducer,
    users: usersReducer
  })

const createRootReducer = history => (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined
  }

  // TODO: determine why I18n is undefined in reading partners
  if (typeof I18n !== 'undefined') {
    i18nInit()
    I18n.locale = 'en'
  }

  return createAppReducer(history)(state, action)
}

export function createStore(initialState = {}) {
  return createReduxStore(
    createRootReducer(history),
    initialState,
    composedEnhancers
  )
}

const store = createStore(initialState)

export default store
