import React from 'react'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import Header from 'components/shared/header'

function ReportSection({ title, href, children }) {
  return (
    <>
      <h3>{title}</h3>
      {children}
      <p>
        <a href={href} target="_blank" rel="noopener noreferrer">
          Open in a new tab
        </a>
      </p>
    </>
  )
}
ReportSection.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  children: PropTypes.any
}

export function PowerBI() {
  return (
    <Page>
      <PageContainer>
        <Header>
          <Header.Contents>
            <Header.Title>Power BI Dashboards</Header.Title>
          </Header.Contents>
        </Header>
        <div>
          <ReportSection
            title={'Enrollment and Applications Dashboard'}
            href={
              'https://app.powerbi.com/reportEmbed?reportId=4825f10a-0684-4282-810a-b719cfbbf95c&groupId=adcb1096-15a5-4ded-819c-473f6b38c66c&autoAuth=true&ctid=3ba516a8-acff-4799-b41e-3e5364ddf80a&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLW5vcnRoLWNlbnRyYWwtYi1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D'
            }
          >
            <p>
              The data displayed here is meant to give a year-over-year view of
              application trends, as well as, current enrollment status for each
              school.
            </p>
          </ReportSection>

          <ReportSection
            title={'Back to School Dashboard'}
            href={
              'https://app.powerbi.com/reportEmbed?reportId=5a864a73-ec0b-48de-a9b6-96849cc3531e&autoAuth=true&ctid=3ba516a8-acff-4799-b41e-3e5364ddf80a'
            }
          >
            <p>
              The data displayed here is meant for schools to use in contacting
              parents. It is very important that we gather Back to School
              economic status information, as this brings funding to TPS. Also,
              there are student who have not enrolled, but have shown interest
              in attending individual schools via applications. Contacting these
              parents to complete enrollment may bring more students to your
              school.
            </p>
          </ReportSection>

          <ReportSection
            title={'Availability Dashboard'}
            href={
              'https://app.powerbi.com/reportEmbed?reportId=aa00b892-2db4-47b0-9a9e-a17b16861912&autoAuth=true&ctid=3ba516a8-acff-4799-b41e-3e5364ddf80a'
            }
          >
            <p>
              The data displayed here shows seats available for transfer
              students through the school year.
            </p>
          </ReportSection>

          <ReportSection
            title={'District Certified Teachers and Courses'}
            href={
              'https://app.powerbi.com/reportEmbed?reportId=c806250a-4652-466f-85a4-b006d5ed18f9&autoAuth=true&ctid=3ba516a8-acff-4799-b41e-3e5364ddf80a'
            }
          >
            <p>
              The data displayed here list the areas teachers are certified to
              teach in, and, lists all the teachers who are certified to teach
              specific areas.
            </p>
          </ReportSection>
        </div>
      </PageContainer>
    </Page>
  )
}

export default PowerBI
