import React from 'react'
import PropTypes from 'prop-types'
import { actions } from 'modules/schools'
import { useDispatch, useSelector } from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { styled, lighten, darken } from '@mui/system'
import { sortBy } from 'lodash'

export function SchoolAutocomplete({
  includeAllSchoolsOption = false,
  onChange = () => {},
  categories = ['early_childhood', 'elementary', 'middle', 'high'],
  ...props
}) {
  const dispatch = useDispatch()
  const schools = useSelector(state => {
    const validSchools = Object.values(state.schools.byId).filter(s =>
      categories.includes(s.category)
    )

    const options = sortBy(validSchools, ['category', 'name']).map(option => {
      const group = `${snakeToTitleCase(option.category)} Schools`
      return { ...option, group }
    })
    if (includeAllSchoolsOption) {
      options.unshift({
        name: 'ALL SCHOOLS',
        id: null,
        group: 'Other'
      })
    }

    const selected =
      options.find(option => option.id === state.schools.schoolId) || null

    if (!selected) {
      handleSchoolChange(options[0])
    }

    return { options, selected }
  })

  function handleSchoolChange(option) {
    const value = option?.id || null
    dispatch(actions.selectSchool(value))
  }

  function snakeToTitleCase(str) {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ')
  }

  const sortedByGroupOptions = [...schools.options].sort((a, b) => {
    return categories.indexOf(a.category) - categories.indexOf(b.category)
  })

  return (
    <Autocomplete
      openOnFocus
      value={schools.selected}
      onChange={(_event, option) => {
        handleSchoolChange(option)
        onChange(option)
      }}
      options={sortedByGroupOptions}
      renderInput={params => <TextField {...params} label={'School'} />}
      getOptionLabel={option => option.name.toUpperCase()}
      groupBy={option => option.group}
      renderGroup={params => {
        return (
          <Box key={params.group}>
            <GroupHeader>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </Box>
        )
      }}
      {...props}
    />
  )
}

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}))

export const GroupItems = styled('ul')({
  padding: 0
})

SchoolAutocomplete.propTypes = {
  includeAllSchoolsOption: PropTypes.bool,
  onChange: PropTypes.func,
  categories: PropTypes.array
}

export default SchoolAutocomplete
