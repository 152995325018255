import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material'
import StatusChart from './chart'
import StatusTable from './table'
import { useQuery } from '@tanstack/react-query'
import { fetchStats } from 'api/grad-tracker'

function GraduationStatus({ schoolId }) {
  const [groupBy, setGroupBy] = useState('cohort')

  const { data: graduationStats, isSuccess } = useQuery(
    ['graduationStats', schoolId, groupBy],
    () =>
      fetchStats({
        groupBy: groupBy,
        schoolId: schoolId
      })
  )

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <FormControl variant="outlined">
              <InputLabel id="group-by">Group By</InputLabel>
              <Select
                value={groupBy}
                onChange={e => setGroupBy(e.target.value)}
                label="Group By"
              >
                <MenuItem value="cohort">Cohort</MenuItem>
                <MenuItem value="ell">ML</MenuItem>
                <MenuItem value="idea">SPED</MenuItem>
                <MenuItem value="ethnicity">Ethnicity</MenuItem>
                <MenuItem value="socioeconomically_disadvantaged">
                  Socioeconomically Disadvantaged
                </MenuItem>
                <MenuItem value="gender">Gender</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isSuccess && (
            <StatusChart data={graduationStats.status} groupBy={groupBy} />
          )}
        </Grid>
        <Grid item xs={12}>
          {isSuccess && (
            <StatusTable data={graduationStats.status} groupBy={groupBy} />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

GraduationStatus.propTypes = {
  schoolId: PropTypes.number
}

export default GraduationStatus
