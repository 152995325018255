import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { actions as schoolActions, selectors } from 'modules/schools'
import Suspensions from './suspensions'
import Attendance from './attendance'
import Referrals from './referrals'
import Map from './map'
import Ostp from './ostp'
import Overview from './overview'
import Grades from './grades'
import SatPage from './sat'
import GradTracker from './grad-tracker'
import Enrollment from './enrollment'
import { routerPropTypes } from 'utils/prop-types'
import { userPropTypes } from 'modules/current-user'
import { isEqual, includes } from 'lodash'
import PageContainer from 'components/shared/page-container'
import Page from 'shared/page'
import SlipCompliance from 'components/shared/slip-compliance'
import HmhAssessments from '../district/hmh_assessments'
import Settings from '../settings'
import { Box, Stack, Typography } from '@mui/material'
import AcademicInterventions from '../district/academic_interventions'
import AcademicAssessments from './overview/academic-platforms/assessments'
import SchoolAutocomplete from '../shared/school-autocomplete'
import { Tabs as SharedTabs } from 'components/shared/tabs'
import ProtectedRoute from '../shared/protected-route'

const makeNonTeacherTabs = ({
  baseUrl,
  user,
  isElementary,
  isMiddle,
  isHigh,
  school
}) => {
  return [
    { label: 'Overview', to: `${baseUrl}/overview` },
    { label: 'Enrollment', to: `${baseUrl}/enrollment` },
    {
      label: 'Academic Interventions',
      to: `${baseUrl}/academic-interventions`
    },
    {
      label: 'Academic Assessments',
      to: `${baseUrl}/academic-assessments`
    },
    { label: 'Grades', to: `${baseUrl}/grades`, className: 'test-grades' },
    {
      label: 'Suspensions',
      to: `${baseUrl}/suspensions`
    },
    { label: 'Referrals', to: `${baseUrl}/referrals` },
    {
      label: 'Attendance',
      to: `${baseUrl}/attendance`
    },
    { label: 'MAP', to: `${baseUrl}/map` },
    {
      label: 'OSTP',
      to: `${baseUrl}/ostp`,
      access: () => isElementary || isMiddle
    },
    { label: 'SAT', to: `${baseUrl}/sat`, access: () => isHigh },
    {
      label: 'Grad',
      to: `${baseUrl}/grad-tracker`,
      access: () => isHigh && user.role !== 'partner'
    },
    {
      label: 'SLIP',
      to: `${baseUrl}/slip`,
      access: () =>
        isElementary ||
        user.rights.is_rsa_coordinator ||
        includes(user.rights.is_wellness_team_member_at_schools, school.id)
    },
    // { label: 'HMH', to: `${baseUrl}/hmh-assessments`, access: () => isElementary },
    {
      label: 'Settings',
      to: `${baseUrl}/settings`,
      access: () => ['principal', 'district'].includes(user.role)
    }
  ].filter(tab => !tab.access || tab.access())
}

const makeTeacherTabs = ({ baseUrl, user, school }) => {
  return [
    {
      label: 'Overview',
      to: `${baseUrl}/overview`,
      access: () =>
        includes(user.rights.is_wellness_team_member_at_schools, school.id)
    },
    { label: 'Enrollment', to: `${baseUrl}/enrollment` },
    {
      label: 'SLIP',
      to: `${baseUrl}/slip`,
      access: () =>
        user.rights.is_rsa_coordinator ||
        includes(user.rights.is_wellness_team_member_at_schools, school.id)
    }
  ].filter(tab => !tab.access || tab.access())
}

function Tabs({
  baseUrl,
  user,
  isElementary,
  isMiddle,
  isHigh,
  school,
  schools
}) {
  const isTeacherAndNotWellnessTeamMember =
    user.role === 'teacher' &&
    user.rights.is_wellness_team_member_at_schools.length === 0
  const tabParams = {
    baseUrl,
    user,
    isElementary,
    isMiddle,
    isHigh,
    school,
    schools
  }
  const tabDepends = [
    baseUrl,
    user,
    isElementary,
    isMiddle,
    isHigh,
    isTeacherAndNotWellnessTeamMember
  ]
  const tabs = React.useMemo(
    () =>
      isTeacherAndNotWellnessTeamMember
        ? makeTeacherTabs(tabParams)
        : makeNonTeacherTabs(tabParams),
    tabDepends
  )

  return <SharedTabs tabs={tabs} />
}

Tabs.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  isHigh: PropTypes.bool.isRequired,
  isMiddle: PropTypes.bool.isRequired,
  isElementary: PropTypes.bool.isRequired,
  school: PropTypes.object.isRequired,
  schools: PropTypes.array.isRequired
}

class School extends React.Component {
  static propTypes = {
    ...routerPropTypes,
    ...userPropTypes
  }

  checkForSchoolInMatchParams() {
    const { match, schools } = this.props
    const id = parseInt(match.params.id)
    const school = id ? schools.find(s => isEqual(s.value, id)) : schools[0]
    this.schoolChanged(school)
  }

  componentDidMount() {
    this.checkForSchoolInMatchParams()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.schoolId, this.props.schoolId)) {
      this.updateUrlForSchool()
    }
  }

  schoolChanged = val => {
    if (val && val.value) {
      this.props.selectSchool(val.value)
    }
  }

  updateUrlForSchool() {
    const { schoolId, location, history } = this.props
    const newPath = location.pathname.split('/')
    newPath[2] = schoolId

    if (!newPath[3]) {
      // NOTE: for some reason <Redirect exact> below does not function when direct by URL `/schools/ID`
      newPath[3] = 'overview'
    }

    history.push(newPath.join('/'))
  }

  render() {
    const { school, schoolId, schools, user } = this.props
    const baseUrl = `/schools/${schoolId}`
    const isElementary = school && school.category === 'elementary'
    const isMiddle = school && school.category === 'middle'
    const isHigh = school && school.category === 'high'

    if (!schoolId) {
      this.schoolChanged(schools.find(value => value))
    }

    return (
      school && (
        <Page title={school.name || 'Schools'} name="School Show Page">
          <PageContainer>
            <Stack>
              <Stack direction={'column-reverse'}>
                <Box>
                  <Typography variant="h1" id="test_header_title">
                    {school.name || 'Schools'}
                  </Typography>
                  <Typography
                    variant="h5"
                    id="test_subheader_title"
                  >{`Principal ${school?.principal}`}</Typography>
                </Box>
                <Box>
                  {schools.length > 1 && (
                    <Stack direction="row" justifyContent="flex-end">
                      <Box
                        id="test-school-selector"
                        sx={{
                          minWidth: '20rem'
                        }}
                      >
                        <SchoolAutocomplete disableClearable />
                      </Box>
                    </Stack>
                  )}
                </Box>
              </Stack>
              <Box>
                <Tabs
                  {...{
                    baseUrl,
                    user,
                    isHigh,
                    isMiddle,
                    isElementary,
                    school,
                    schools
                  }}
                />

                <Box
                  sx={{
                    pt: 6
                  }}
                >
                  <Switch>
                    <Redirect
                      from={baseUrl}
                      exact={true}
                      to={`${baseUrl}/overview`}
                    />

                    <Redirect
                      from={`${baseUrl}/map-math`}
                      exact={true}
                      to={`${baseUrl}/map`}
                    />

                    <Redirect
                      from={`${baseUrl}/map-reading`}
                      exact={true}
                      to={`${baseUrl}/map`}
                    />

                    {!isHigh && (
                      <Redirect from={`${baseUrl}/grad-tracker`} to={baseUrl} />
                    )}

                    <Route path={`${baseUrl}/overview`}>
                      <Overview school={school} />
                    </Route>

                    <Route path={`${baseUrl}/grades`}>
                      <Grades school={school} />
                    </Route>

                    <Route path={`${baseUrl}/suspensions`}>
                      <Suspensions />
                    </Route>

                    <Route path={`${baseUrl}/attendance`}>
                      <Attendance />
                    </Route>

                    <Route path={`${baseUrl}/referrals`}>
                      <Referrals />
                    </Route>

                    <ProtectedRoute
                      path={`${baseUrl}/grad-tracker`}
                      isPermitted={school && !['partner'].includes(user.role)}
                      redirectUrl={baseUrl}
                      component={
                        <GradTracker school={school} schoolId={schoolId} />
                      }
                    />

                    <Route path={`${baseUrl}/enrollment`}>
                      <Enrollment />
                    </Route>

                    <Route path={`${baseUrl}/map`}>
                      <Map />
                    </Route>

                    {(isElementary || isMiddle) && (
                      <Route path={`${baseUrl}/ostp`}>
                        <Ostp school={school} />
                      </Route>
                    )}

                    <Route path={`${baseUrl}/sat`}>
                      <SatPage />
                    </Route>

                    <Route path={`${baseUrl}/slip`}>
                      {isElementary && <SlipCompliance schoolId={school.id} />}
                    </Route>

                    <Route path={`${baseUrl}/hmh-assessments`}>
                      <HmhAssessments schoolId={school.id} />
                    </Route>

                    <Route path={`${baseUrl}/academic-interventions`}>
                      <AcademicInterventions school={school} />
                    </Route>

                    <Route path={`${baseUrl}/academic-assessments`}>
                      <AcademicAssessments school={school} />
                    </Route>

                    <ProtectedRoute
                      path={`${baseUrl}/settings`}
                      isPermitted={['principal', 'district'].includes(
                        user.role
                      )}
                      redirectUrl={baseUrl}
                      component={Settings}
                    />
                  </Switch>
                </Box>
              </Box>
            </Stack>
          </PageContainer>
        </Page>
      )
    )
  }
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  school: selectors.currentSchool(state),
  schoolId: state.schools.schoolId,
  schools: selectors.schoolOptions(state)
})

const mapDispatchToProps = {
  selectSchool: schoolActions.selectSchool
}

export default connect(mapStateToProps, mapDispatchToProps)(School)
