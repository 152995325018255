import { get } from 'utils/ajax'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export function useTeacherSections({ params, options }) {
  const queryKey = ['teacher-sections', params]
  const queryFn = () => get('/api/web/teacher_sections', { params })
  const query = useQuery(queryKey, queryFn, {
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}
