import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { optionsPropTypes, withOptions } from 'utils/contexts/options'
import { currentSchoolYear } from 'utils'
import { selectors } from 'modules/schools'
import { actions as filterActions, emptyDataset } from 'modules/student-filters'
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material'

class QuickFilters extends React.Component {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    gradeLevels: PropTypes.array.isRequired,
    cohorts: PropTypes.array.isRequired,
    filtersChanged: PropTypes.func.isRequired,
    defaultSchoolId: PropTypes.number,
    submit: PropTypes.func,
    ...optionsPropTypes
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search)
    if (params.quick_filter) {
      const quickFilter = params.quick_filter
      this.filter(quickFilter)
    } else {
      this.filter(this.props.selectedFilter)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.defaultSchoolId !== this.props.defaultSchoolId) {
      const { school, selectedFilter, filters } = this.props
      const isHighSchool = school && school.category === 'high'

      if (selectedFilter === 'custom') {
        this.clearInvalidOptions(filters)
        this.props.filtersChanged(filters).then(this.props.submit)
      } else {
        if (isHighSchool && selectedFilter === 'grade_levels') {
          this.props.filterSelected('cohorts')
          this.filter('cohorts')
        } else if (!isHighSchool && selectedFilter === 'cohorts') {
          this.props.filterSelected('grade_levels')
          this.filter('grade_levels')
        } else {
          this.filter(selectedFilter)
        }
      }
    }
  }

  clearInvalidOptions = filters => {
    const { cohorts, gradeLevels } = this.props

    filters.forEach(filterSet => {
      if (filterSet.gradeLevels) {
        filterSet.gradeLevels = (filterSet.gradeLevels || []).filter(g =>
          gradeLevels.map(g => g.value).includes(g)
        )
      }

      if (filterSet.cohorts) {
        filterSet.cohorts = filterSet.cohorts.filter(g =>
          cohorts.map(g => g.value).includes(g)
        )
      }
    })
  }

  compareSchoolYears = () => {
    const years = [currentSchoolYear, currentSchoolYear - 1]
    return this.compareData('schoolYear', years)
  }

  compareGradeLevels = () => {
    const gradeLevels = this.props.gradeLevels.map(g => g.value)
    return this.compareArrayData('gradeLevels', gradeLevels)
  }

  compareCohorts = () => {
    const cohorts = [
      currentSchoolYear,
      currentSchoolYear + 1,
      currentSchoolYear + 2,
      currentSchoolYear + 3
    ]

    return this.compareArrayData('cohorts', cohorts)
  }

  compareGenders = () => {
    const genders = ['M', 'F']
    return this.compareData('gender', genders)
  }

  compareEthnicities = () => {
    const { ethnicities } = this.props
    const ethnicityValues = ethnicities.map(e => e.value)
    return this.compareArrayData('ethnicities', ethnicityValues)
  }

  compareElls = () => {
    const ell = ['Yes', 'Former', 'No']
    return this.compareData('ells', ell)
  }

  compareSped = () => {
    const sped = [true, false]
    return this.compareData('sped', sped)
  }

  compareSocioeconomicallyDisadvantaged = () => {
    const socioeconomicallyDisadvantaged = [true, false]
    return this.compareData(
      'socioeconomicallyDisadvantaged',
      socioeconomicallyDisadvantaged
    )
  }

  compareThirdGradeProficiency = () => {
    this.props.filterSelected('school_years')
    const years = [currentSchoolYear, currentSchoolYear - 1]
    const datasets = years.map(year => {
      return emptyDataset(this.props.defaultSchoolId, {
        ['schoolYear']: year,
        gradeLevels: ['3rd']
      })
    })

    return this.props.filtersChanged(datasets)
  }

  compareArrayData = (property, values) => {
    return this.compareData(property, values, true)
  }

  compareData = (property, values, isArray = false) => {
    const datasets = values.map(value => {
      if (isArray) {
        value = [value]
      }
      return emptyDataset(this.props.defaultSchoolId, { [property]: value })
    })

    return this.props.filtersChanged(datasets)
  }

  filter = filter => {
    switch (filter) {
      case 'school_years':
        this.compareSchoolYears().then(this.props.submit)
        break
      case 'grade_levels':
        this.compareGradeLevels().then(this.props.submit)
        break
      case '3rd_grade_proficiency':
        this.compareThirdGradeProficiency().then(this.props.submit)
        break
      case 'cohorts':
        this.compareCohorts().then(this.props.submit)
        break
      case 'ethnicities':
        this.compareEthnicities().then(this.props.submit)
        break
      case 'genders':
        this.compareGenders().then(this.props.submit)
        break
      case 'ells':
        this.compareElls().then(this.props.submit)
        break
      case 'sped':
        this.compareSped().then(this.props.submit)
        break
      case 'socioeconomically_disadvantaged':
        this.compareSocioeconomicallyDisadvantaged().then(this.props.submit)
        break
      default:
        this.props.submit()
    }
  }

  quickFilterSelected = e => {
    const filter = e.target.value
    this.props.filterSelected(filter)
    this.filter(filter)
  }

  render() {
    const { school, selectedFilter } = this.props
    const isHighSchool = school && school.category === 'high'

    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box>
          <FormControl>
            <InputLabel>Quick Compare</InputLabel>
            <Select
              id="quick-compare-select"
              label="Quick Compare"
              autoWidth
              onChange={this.quickFilterSelected}
              value={selectedFilter}
            >
              <MenuItem value="school_years">Compare School Years</MenuItem>
              {isHighSchool ? (
                <MenuItem value="cohorts">Compare Cohorts</MenuItem>
              ) : (
                <MenuItem value="grade_levels">Compare Grade Levels</MenuItem>
              )}
              <MenuItem value="ethnicities">Compare Ethnicities</MenuItem>
              <MenuItem value="genders">Compare Genders</MenuItem>
              <MenuItem value="ells">Compare ML Status</MenuItem>
              <MenuItem value="sped">Compare SPED Status</MenuItem>
              <MenuItem value="socioeconomically_disadvantaged">
                Compare Socioeconomic Status
              </MenuItem>
              <MenuItem value="custom">Custom Filters...</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  filters: state.studentFilters.filters,
  cohorts: selectors.cohortOptions(state),
  gradeLevels: selectors.gradeLevelOptions(state),
  school: selectors.currentSchool(state),
  defaultSchoolId: state.schools.schoolId,
  selectedFilter: state.studentFilters.selectedQuickFilter
})

const mapDispatchToProps = {
  filterSelected: filterActions.quickFilterSelected,
  filtersChanged: filterActions.filtersChanged
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOptions(QuickFilters))
