// TODO: Use a form library for handling validation
//   - https://formik.org/
//   - https://formik.org/docs/examples/with-material-ui

import React, {
  useEffect,
  useReducer,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react'
import PropTypes from 'prop-types'
import IndicatorInput from './indicator-input'
import { isNull } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'

// Api
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createTouchpointObservation,
  updateTouchpointObservation,
  deleteTouchpointObservation,
  getTouchpointIndicatorSets
} from 'api/touchpoints'
import { fetchTeachers, getTeacherInfo } from 'api/teachers'
import { actions as schoolActions, selectors } from 'modules/schools'

// Material
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Dialog from 'components/shared/dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { makeStyles } from '@mui/styles'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'

const useStyles = makeStyles(_theme => ({
  formControl: {
    width: '100%'
  }
}))

const validateForm = state => {
  const { input, indicators } = state
  const errors = {}

  // Section
  if (!input.section_id) {
    errors.section_id = 'Section is required'
  }

  // Rubric
  if (!input.touchpoint_indicatorset_id) {
    errors.touchpoint_indicatorset_id = 'Rubric is required'
  }

  // Indicators
  const indicator_errors = Object.entries(indicators).reduce(
    (indicator_errors, [id, indicator]) => {
      const value = input.touchpoint_observation_indicators[id]
      switch (indicator.field_type) {
        case 'yes_no':
        case 'yes_no_na':
        case 'yes_approaching_no':
        case 'yes_approaching_no_na':
          if (!indicator.field_options.includes(value)) {
            indicator_errors[id] = 'Invalid value'
          }
          break
      }
      return indicator_errors
    },
    {}
  )
  if (Object.keys(indicator_errors).length) {
    errors.touchpoint_observation_indicators = indicator_errors
  }

  return { ...state, errors }
}

const initForm = ({ observation, observerId }) => {
  const section_id = observation?.section_id || ''
  const touchpoint_indicatorset_id =
    observation?.touchpoint_indicatorset_id || ''
  const summary = observation?.summary || ''
  const note_to_self = observation?.note_to_self || ''
  const touchpoint_observation_indicators =
    observation?.indicator_answers.reduce(
      (h, { touchpoint_indicator_id, answer }) => {
        h[touchpoint_indicator_id] = answer
        return h
      },
      {}
    ) || {}
  const is_draft = observation?.is_draft || false

  return validateForm({
    input: {
      observer_id: observerId,
      touchpoint_indicatorset_id,
      section_id,
      summary,
      note_to_self,
      touchpoint_observation_indicators,
      is_draft
    },
    indicators: {},
    indicatorSet: undefined,
    indicatorSets: [],
    showErrors: false
  })
}

const formReducer = (state, action) => {
  const { type, payload } = action
  const { input, indicators } = state

  switch (type) {
    case 'init': {
      return initForm(payload)
    }
    case 'showErrors': {
      return { ...state, showErrors: true }
    }
    case 'hideErrors': {
      return { ...state, showErrors: false }
    }
    case 'input': {
      const input = { ...state.input, ...payload }
      if (
        input.touchpoint_indicatorset_id ==
        state.input.touchpoint_indicatorset_id
      ) {
        return validateForm({ ...state, input })
      }

      const indicatorSet = state.indicatorSets.find(
        indicatorSet => indicatorSet.id == input.touchpoint_indicatorset_id
      )
      const indicators = indicatorSet
        ? indicatorSet.indicators.reduce((indicators, indicator) => {
            indicators[indicator.id] = indicator
            return indicators
          }, {})
        : {}
      input.touchpoint_observation_indicators = {}
      return validateForm({
        ...state,
        input,
        indicators,
        indicatorSet,
        showErrors: false
      })
    }
    case 'input:indicator': {
      const { id, value } = payload
      const indicator = indicators[id]
      input.touchpoint_observation_indicators =
        input.touchpoint_observation_indicators || {}
      input.touchpoint_observation_indicators[id] = value

      // Propagate to parent and children
      if (
        [
          'yes_no',
          'yes_no_na',
          'yes_approaching_no',
          'yes_approaching_no_na'
        ].includes(indicator.field_type)
      ) {
        // children
        indicator.children_indicator_ids.forEach(child_id => {
          const indicator = indicators[child_id]
          switch (indicator.field_type) {
            case 'yes_no':
            case 'yes_approaching_no':
              input.touchpoint_observation_indicators[child_id] = [
                'yes'
              ].includes(value)
                ? value
                : null
              break
            case 'yes_no_na':
            case 'yes_approaching_no_na':
              input.touchpoint_observation_indicators[child_id] = [
                'yes',
                'n/a'
              ].includes(value)
                ? value
                : null
              break
            default:
              input.touchpoint_observation_indicators[child_id] = null
          }
        })

        // parent
        if (indicator.parent_indicator_id) {
          const parent_indicator = indicators[indicator.parent_indicator_id]
          if (
            [
              'yes_no',
              'yes_no_na',
              'yes_approaching_no',
              'yes_approaching_no_na'
            ].includes(parent_indicator.field_type)
          ) {
            const siblings = Object.values(indicators).filter(
              indicator =>
                `${indicator.parent_indicator_id}` === `${parent_indicator.id}`
            )
            const sibling_values = new Set(
              siblings.map(
                sibling => input.touchpoint_observation_indicators[sibling.id]
              )
            )

            if (
              sibling_values.has('approaching') &&
              ['yes_approaching_no', 'yes_approaching_no_na'].includes(
                parent_indicator.field_type
              )
            ) {
              input.touchpoint_observation_indicators[
                indicator.parent_indicator_id
              ] = 'approaching'
            } else if (sibling_values.has('no')) {
              input.touchpoint_observation_indicators[
                indicator.parent_indicator_id
              ] = 'no'
            } else if (sibling_values.has('yes')) {
              input.touchpoint_observation_indicators[
                indicator.parent_indicator_id
              ] = 'yes'
            } else {
              input.touchpoint_observation_indicators[
                indicator.parent_indicator_id
              ] = null
            }
          }
        }
      }

      return validateForm({ ...state, input })
    }
    case 'indicatorSets': {
      const indicatorSets = payload
      const id = state.input.touchpoint_indicatorset_id
      const indicatorSet = id
        ? indicatorSets.find(indicatorSet => indicatorSet.id == id)
        : undefined
      const indicators = indicatorSet
        ? indicatorSet.indicators.reduce((indicators, indicator) => {
            indicators[indicator.id] = indicator
            return indicators
          }, {})
        : {}
      return validateForm({ ...state, indicators, indicatorSet, indicatorSets })
    }
    default: {
      throw new Error(`Invalid action type: ${type}`)
    }
  }
}

export const ObservationForm = forwardRef(function ObservationForm(
  {
    observation,
    observerId,
    teacher,
    onCancel,
    onDelete,
    onSubmit,
    onError,
    disabled = false,
    setSubmitText
  },
  ref
) {
  const dispatch = useDispatch()
  const schoolId = useSelector(state => state.schools.schoolId)
  const schools = useSelector(state => selectors.schoolOptions(state))
  const user = useSelector(state => state.currentUser.user)

  const classes = useStyles()
  const formRef = useRef()

  // State
  const [teachers, setTeachers] = useState(null)
  const [teacherId, setTeacherId] = useState(teacher && teacher.id)
  const [globalTeacher, setGlobalTeacher] = useState(teacher)
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [date, setDate] = useState(observation?.date || new Date())
  const [form, dispatchForm] = useReducer(
    formReducer,
    { observation, observerId },
    initForm
  )
  const section =
    teacher &&
    teacher.sections.find(section => section.id == form.input?.section_id)

  // Query
  const indicatorSetQuery = useQuery(
    ['indicator-sets'],
    async () => {
      const response = await getTouchpointIndicatorSets()
      return response.data
    },
    {}
  )

  const createObservationMutation = useMutation(createTouchpointObservation, {
    onError: (error, _variables, _context) => {
      onError && onError(error)
    },
    onSuccess: (_data, _variables, _context) => {
      onSubmit && onSubmit()
    }
  })

  const updateObservationMutation = useMutation(updateTouchpointObservation, {
    onError: (error, _variables, _context) => {
      onError && onError(error)
    },
    onSuccess: (_data, _variables, _context) => {
      onSubmit && onSubmit()
    }
  })

  const deleteObservationMutation = useMutation(deleteTouchpointObservation, {
    onError: (error, _variables, _context) => {
      onError && onError(error)
    },
    onSuccess: (_data, _variables, _context) => {
      onDelete && onDelete()
    }
  })

  // Imperative Handle
  // https://www.fabiofranchino.com/blog/call-child-method-from-parent-rect-component/
  // https://dev.to/anikcreative/react-hooks-explained-useimperativehandle-5g44
  useImperativeHandle(
    ref,
    () => ({
      cancel(_event) {
        onCancel && onCancel()
      },
      delete(_event) {
        setOpenConfirmDelete(true)
      },
      submit(_event, is_draft) {
        dispatchForm({ type: 'input', payload: { is_draft } })

        setTimeout(triggerSubmit, 0)
      }
    }),
    []
  )

  // Handlers
  function handleIndicatorScore(id, value) {
    dispatchForm({ type: 'input:indicator', payload: { id, value } })
  }

  function handleDeleteConfirmed() {
    setOpenConfirmDelete(false)
    deleteObservationMutation.mutate(observation.id)
  }

  function handleDeleteCancelled() {
    setOpenConfirmDelete(false)
  }

  function handleSubmit(event) {
    event.preventDefault()
    const input = {
      observee_id:
        (teacher && teacher.user_id) ||
        (globalTeacher && globalTeacher.user_id),
      date: date,
      ...form.input
    }

    if (Object.keys(form.errors).length) {
      dispatchForm({ type: 'showErrors' })
      return
    } else {
      dispatchForm({ type: 'hideErrors' })
    }

    if (observation) {
      updateObservationMutation.mutate({ id: observation.id, ...input })
    } else {
      createObservationMutation.mutate(input)
    }
  }

  function triggerSubmit() {
    formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))
  }

  useEffect(() => {
    if (indicatorSetQuery.data) {
      dispatchForm({ type: 'indicatorSets', payload: indicatorSetQuery.data })
    }
  }, [indicatorSetQuery.data])

  useEffect(() => {
    if (schoolId) {
      fetchTeachers({
        school_id: schoolId,
        as_options: true,
        per_page: 10000
      }).then(res => {
        setTeachers(res.data)
      })
    }
  }, [schoolId])

  useEffect(() => {
    if (teacherId) {
      getTeacherInfo(teacherId, { include_sections: true }).then(res => {
        setGlobalTeacher(res.teacher)
      })
    }
  }, [teacherId])

  useEffect(() => {
    if (isNull(form)) {
      setSubmitText('Submit')
    } else if (isNull(observation) && form.input.summary !== '') {
      {
        /* New observation and text not blank */
      }
      setSubmitText('Submit and Email Teacher')
    } else if (form.input.is_draft && form.input.summary !== '') {
      {
        /* Draft observation and text not blank */
      }
      setSubmitText('Submit and Email Teacher')
    } else {
      setSubmitText('Submit')
    }
  }, [form])

  // Render

  if (indicatorSetQuery.isLoading || !indicatorSetQuery.data) {
    return (
      <Typography align={'center'} style={{ margin: '1rem' }}>
        Loading…
      </Typography>
    )
  }

  const handleSchoolChange = id => {
    dispatch(schoolActions.selectSchool(id))
    setTeacherId(null)
  }

  const handleTeacherChange = val => {
    setTeacherId(val.target.value)
    dispatchForm({ type: 'input', payload: { section_id: null } })
  }

  return (
    <React.Fragment>
      <Dialog
        title={'Delete Observation'}
        open={openConfirmDelete}
        onClose={handleDeleteCancelled}
      >
        <DialogContent>
          <Typography>
            Are you sure you want to delete this observation? It cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDeleteCancelled}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDeleteConfirmed}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <form ref={formRef} onSubmit={handleSubmit}>
        <Grid container alignItems={'flex-start'} spacing={3}>
          <Grid item xs={12} md={7}>
            <Stack>
              {teacher && <Typography variant="h3">{teacher.name}</Typography>}

              <FormControl>
                <DesktopDatePicker
                  label="Date"
                  disableFuture
                  variant="inline"
                  value={date}
                  onChange={val => setDate(val)}
                  disabled={disabled}
                  autoOk={true}
                  renderInput={props => <TextField {...props} />}
                />
              </FormControl>

              {observation ? (
                <Box>
                  <div>Section: {section?.full_name}</div>
                  <div>Rubric: {form.indicatorSet?.title}</div>
                </Box>
              ) : (
                <React.Fragment>
                  {schools && !teacher && (
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                      error={Boolean(form.showErrors && form.errors.section_id)}
                    >
                      <InputLabel id="school-id-selector-label">
                        Select School
                      </InputLabel>
                      <Select
                        id="school-id-selector"
                        labelId="school-id-selector-label"
                        label="Select School"
                        value={schoolId}
                        onChange={val => handleSchoolChange(val.target.value)}
                      >
                        {schools.map(school => (
                          <MenuItem key={school.value} value={school.value}>
                            {school.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {teachers && !teacher && (
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                      error={Boolean(form.showErrors && form.errors.section_id)}
                    >
                      <InputLabel id="teacher-id-selector-label">
                        Select Teacher
                      </InputLabel>
                      <Select
                        id="teacher-id-selector"
                        labelId="teacher-id-selector-label"
                        label="Select Teacher"
                        value={teacherId}
                        onChange={val => handleTeacherChange(val)}
                      >
                        {teachers &&
                          teachers.map(t => (
                            <MenuItem key={t.value} value={t.value}>
                              {t.label}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  )}

                  {globalTeacher && (
                    <FormControl
                      className={classes.formControl}
                      variant="outlined"
                      error={Boolean(form.showErrors && form.errors.section_id)}
                    >
                      <InputLabel id="section-id-selector-label">
                        Select Section
                      </InputLabel>
                      <Select
                        id="section-id-selector"
                        labelId="section-id-selector-label"
                        label="Select Section"
                        value={form.input.section_id}
                        onChange={event =>
                          dispatchForm({
                            type: 'input',
                            payload: { section_id: event.target.value }
                          })
                        }
                        disabled={
                          disabled ||
                          (globalTeacher && globalTeacher.sections.length === 0)
                        }
                      >
                        {globalTeacher.sections.map(section => (
                          <MenuItem key={section.id} value={section.id}>
                            {section.full_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                    error={Boolean(
                      form.showErrors && form.errors.touchpoint_indicatorset_id
                    )}
                  >
                    <InputLabel id="indicatorSetSelector">
                      Select Rubric
                    </InputLabel>
                    <Select
                      labelId="indicatorSetSelector"
                      value={form.input.touchpoint_indicatorset_id}
                      label="Select Rubric"
                      onChange={event =>
                        dispatchForm({
                          type: 'input',
                          payload: {
                            touchpoint_indicatorset_id: event.target.value
                          }
                        })
                      }
                      disabled={disabled}
                    >
                      {form.indicatorSets?.map(set => (
                        <MenuItem key={set.id} value={set.id}>
                          {set.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </React.Fragment>
              )}

              {Object.values(form.indicators)
                .filter(indicator => !indicator.parent_indicator_id)
                .map(indicator => (
                  <IndicatorInput
                    key={indicator.id}
                    indicatorId={indicator.id}
                    indicators={form.indicators}
                    values={form.input.touchpoint_observation_indicators}
                    errors={form.errors.touchpoint_observation_indicators || {}}
                    onChange={handleIndicatorScore}
                    showErrors={form.showErrors}
                    disabled={disabled}
                  />
                ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={5}>
            <Stack>
              <FormControl>
                <TextField
                  label={'Feedback'}
                  placeholder={
                    'You may thank your teacher, and include any affirmations, impacts, or challenges.'
                  }
                  helperText={'Optional; to teacher & everyone.'}
                  value={form.input.summary}
                  multiline
                  rows={7}
                  fullWidth
                  disabled={disabled}
                  onChange={event =>
                    dispatchForm({
                      type: 'input',
                      payload: { summary: event.target.value }
                    })
                  }
                />
              </FormControl>

              {(!observation || observation.observer_id == user.id) && (
                <FormControl>
                  <TextField
                    label={'Note-to-Self'}
                    helperText={'Only you can see and find this.'}
                    value={form.input.note_to_self}
                    multiline
                    rows={7}
                    fullWidth
                    disabled={disabled}
                    onChange={event =>
                      dispatchForm({
                        type: 'input',
                        payload: { note_to_self: event.target.value }
                      })
                    }
                  />
                </FormControl>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  )
})

ObservationForm.propTypes = {
  observation: PropTypes.object,
  observerId: PropTypes.number.isRequired,
  teacher: PropTypes.object,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  disabled: PropTypes.bool,
  setSubmitText: PropTypes.func,
  school: PropTypes.object
}

export default ObservationForm
