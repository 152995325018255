import { alert } from 'utils/alert'
import { createManySupports } from 'api/interventions'
import { useSelector } from 'react-redux'
import { selectors } from 'modules/schools'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers'
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Stack
} from '@mui/material'
import StudentSelectContainer from './StudentSelectContainer'
import Dialog from 'components/shared/dialog'
import { useMutation } from '@tanstack/react-query'
import ConcernTypeAutocomplete from './ConcernTypeAutocomplete'
import SupportAutocomplete from './SupportAutocomplete'

function BulkSupportsDialog({ onHide, show }) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [content, setContent] = useState('')
  const [concernTypeId, setConcernTypeId] = useState(null)
  const [date, setDate] = useState(moment().toDate())
  const [selectedStudentOption, setSelectedStudentOption] = useState([])
  const { school } = useSelector(state => ({
    userId: state.currentUser.user.id,
    school: selectors.currentSchool(state),
    schoolId: state.schools.schoolId
  }))
  const [interventionTypeId, setInterventionTypeId] = useState(null)

  const createManySupportsMutation = useMutation(
    ({ params }) => createManySupports(params),
    {
      onSuccess: data => {
        alert(
          `Successfully created supports for ${data.length} students.`,
          'success'
        )
        onHide({ action: 'create', data })
        resetForm()
      }
    }
  )

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)

    const params = {
      studentIds: selectedStudentOption.map(o => o.value),
      concernTypeId,
      interventionTypeId,
      date,
      content
    }

    createManySupportsMutation.mutate({ params })
  }

  const resetForm = () => {
    setIsSubmitted(false)
    setConcernTypeId(null)
    setDate(moment())
    setContent('')
  }

  return (
    <Dialog
      title={'Create Support'}
      open={show}
      onClose={() => onHide({ action: 'cancel' })}
    >
      <DialogContent>
        <Stack component="form">
          <Stack direction={'row'} justifyContent={'space-between'}>
            <StudentSelectContainer
              setSelectedStudentOption={setSelectedStudentOption}
              selectedStudentOption={selectedStudentOption}
              isSubmitted={isSubmitted}
            />

            <DatePicker
              renderInput={props => <TextField {...props} />}
              disableToolbar
              required
              fullWidth
              inputFormat="MM/DD/YYYY"
              inputVariant="inline"
              id="date-event-on"
              label="Date"
              value={moment(date).toDate()}
              onChange={val => setDate(moment(val))}
              autoOk={true}
            />
          </Stack>
          <Stack direction={'row'}>
            <ConcernTypeAutocomplete
              fullWidth
              required
              onChange={(_event, newValue) => {
                setConcernTypeId(newValue?.id || null)
              }}
            />
            <SupportAutocomplete
              schoolCategory={school?.category}
              concernTypeId={concernTypeId}
              fullWidth
              required
              onChange={(_event, newValue) => {
                setInterventionTypeId(newValue?.id || null)
              }}
            />
          </Stack>
          <TextField
            id="details"
            label="Details"
            multiline
            fullWidth
            variant="outlined"
            rows={5}
            value={content}
            onChange={val => setContent(val.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Stack direction={'row'}>
              <Button
                variant={'outline'}
                onClick={() => onHide({ action: 'cancel' })}
              >
                Cancel
              </Button>
              <Button
                variant={'contained'}
                color="primary"
                onClick={handleSubmit}
                disabled={
                  !(
                    selectedStudentOption.length &&
                    concernTypeId &&
                    interventionTypeId
                  )
                }
              >
                Create
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

BulkSupportsDialog.propTypes = {
  concern: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  studentId: PropTypes.number,
  childStudyId: PropTypes.number
}

export default BulkSupportsDialog
