import RequirementsApi from './api'
import { types } from './reducer'

export const fetchRequirements = childStudyId => {
  return dispatch => {
    return RequirementsApi.getRequirements(childStudyId).then(resp => {
      dispatch({ type: types.FETCH_REQUIREMENTS, payload: resp.data })
    })
  }
}
