import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material'
import GraduationPlanChart from './chart'
import GraduationPlanTable from './table'
import { useQuery } from '@tanstack/react-query'
import { fetchStats } from 'api/grad-tracker'

function GraduationPlans({ schoolId }) {
  const [groupBy, setGroupBy] = useState('cohort')

  const { data: graduationStats, isSuccess } = useQuery(
    ['graduationStats', schoolId, groupBy],
    () =>
      fetchStats({
        groupBy: groupBy,
        schoolId: schoolId
      }),
    {
      refetchOnWindowFocus: false
    }
  )

  const determinePlans = () => {
    let output = []
    const array = Object.entries(graduationStats.plans)
    array.map(obj => {
      let planCounts = obj[1]
      output.push(Object.keys(planCounts))
    })
    return [...new Set(output.flat().sort())]
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <FormControl variant="outlined" ml={1}>
              <InputLabel id="group-by">Group By</InputLabel>
              <Select
                value={groupBy}
                onChange={e => setGroupBy(e.target.value)}
                label="Group By"
              >
                <MenuItem value="cohort">Cohort</MenuItem>
                <MenuItem value="ell">ML</MenuItem>
                <MenuItem value="idea">SPED</MenuItem>
                <MenuItem value="ethnicity">Ethnicity</MenuItem>
                <MenuItem value="socioeconomically_disadvantaged">
                  Socioeconomically Disadvantaged
                </MenuItem>
                <MenuItem value="gender">Gender</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isSuccess && (
            <GraduationPlanChart
              data={graduationStats.plans}
              plans={determinePlans()}
              groupBy={groupBy}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {isSuccess && (
            <GraduationPlanTable
              data={graduationStats.plans}
              plans={determinePlans()}
              groupBy={groupBy}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

GraduationPlans.propTypes = {
  schoolId: PropTypes.number
}

export default GraduationPlans
