import React from 'react'
import PropTypes from 'prop-types'
import { uniqBy, flatten, sortBy } from 'lodash'
import { titleize } from 'utils'
import { Table } from 'components/shared/table'
import BreakdownModal from './incident-breakdown-modal'
import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import ListIcon from '@mui/icons-material/List'

export default class SummaryTable extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    onFilterChanged: PropTypes.func,
    filters: PropTypes.object,
    type: PropTypes.oneOf(['suspensions', 'referrals']).isRequired
  }

  state = {
    breakdownModalOpen: false,
    breakdownData: [],
    breakdownSeriesName: '',
    incidentTypeOptions: []
  }

  showBreakdownModal = series => {
    this.setState({
      breakdownModalOpen: true,
      breakdownData: series.breakdown,
      breakdownSeriesName: series.name
    })
  }

  handleModalHide = () => {
    this.setState({ breakdownModalOpen: false })
  }

  incidentTypeFilterChanged = (event, value) => {
    const newFilter = {
      incidentType: value && value.value
    }

    this.props.onFilterChanged(newFilter)
  }

  incidentTierFilterChanged = (event, value) => {
    const newFilter = {
      incidentTier: value && value.value,
      incidentType: null
    }

    this.props.onFilterChanged(newFilter).then(data => {
      let incidentTypeOptions = data.map(dataset =>
        dataset.breakdown.map(incident => ({
          value: incident.id,
          label: incident.name
        }))
      )

      incidentTypeOptions = sortBy(
        uniqBy(flatten(incidentTypeOptions), 'value'),
        'label'
      )

      this.setState({ incidentTypeOptions })
    })
  }

  render() {
    const { data, filters, type } = this.props
    const {
      breakdownModalOpen,
      breakdownData,
      breakdownSeriesName,
      incidentTypeOptions
    } = this.state

    const pastTenseType = type === 'suspensions' ? 'Suspended' : 'Referred'

    let columns = [
      {
        Header: '',
        accessor: 'name',
        Cell: props => <strong>{props.value}</strong>,
        maxWidth: '120px'
      },
      {
        Header: `Total ${titleize(type)}`,
        accessor: 'total'
      }
    ]

    if (type === 'suspensions') {
      columns.push({
        Header: `Days Per ${titleize(type)}`,
        accessor: 'days_per'
      })
    }

    columns = columns.concat([
      {
        Header: `Students ${pastTenseType}`,
        accessor: 'students_cited'
      },
      {
        Header: 'Total Students',
        accessor: 'total_students'
      }
    ])

    if (!filters.incidentType) {
      columns.push({
        Header: 'Breakdown',
        id: 'breakdown',
        accessor: obj => obj,
        Cell: props => (
          <Button
            variant="contained"
            onClick={() => this.showBreakdownModal(props.value)}
            startIcon={<ListIcon />}
          >
            Breakdown
          </Button>
        )
      })
    }

    const incidentTierOptions = [1, 2, 3, 4].map(n => ({
      value: n,
      label: `Tier ${n}`
    }))

    incidentTierOptions.unshift({ label: 'All tiers', value: null })

    return (
      <Box
        sx={{
          mb: 4
        }}
      >
        <Typography variant="h5" gutterBottom>
          {titleize(type)} Data
        </Typography>
        <Box
          sx={{
            display: 'flex',
            mb: 2
          }}
        >
          <Box
            sx={{
              flexGrow: 1
            }}
          >
            <Typography variant="subtitle1">Filter by Incident Tier</Typography>
            <Autocomplete
              value={
                incidentTierOptions.find(
                  option => option.value === filters.incidentTier
                ) || null
              }
              options={incidentTierOptions}
              onChange={this.incidentTierFilterChanged}
              renderInput={params => <TextField {...params} />}
              getOptionLabel={option => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />
          </Box>
          {filters.incidentTier && (
            <Box
              sx={{
                flexGrow: 1,
                ml: 2
              }}
            >
              <Typography variant="subtitle1">
                Filter by Incident Type
              </Typography>
              <Autocomplete
                value={
                  incidentTypeOptions.find(
                    option => option.value === filters.incidentType
                  ) || null
                }
                options={incidentTypeOptions}
                onChange={this.incidentTypeFilterChanged}
                renderInput={params => <TextField {...params} />}
                getOptionLabel={option => option.label}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
              />
            </Box>
          )}
        </Box>
        <Table
          columns={columns}
          data={data}
          resizable={false}
          showPagination={false}
        />
        <BreakdownModal
          type={type}
          show={breakdownModalOpen}
          onHide={this.handleModalHide}
          data={breakdownData}
          seriesName={breakdownSeriesName}
          filters={filters}
        />
      </Box>
    )
  }
}
