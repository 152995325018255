import React from 'react'
import { Box, Stack, Tooltip } from '@mui/material'
import { includes, pickBy, some, sum } from 'lodash'
import PropTypes from 'prop-types'

function GradeDistribution({ gradeDistribution }) {
  const gradeNames = {
    none_count: 'MISSING',
    dash_count: '--',
    blank_count: 'BLANK',
    a_count: 'A',
    b_count: 'B',
    c_count: 'C',
    d_count: 'D',
    f_count: 'F',
    e_count: 'E',
    s_count: 'S',
    n_count: 'N',
    u_count: 'U'
  }

  const barClass = {
    none_count: 'info.main',
    dash_count: 'info.main',
    blank_count: 'info.main',
    a_count: 'success.dark',
    b_count: 'success.light',
    c_count: 'warning.light',
    d_count: 'warning.dark',
    f_count: 'error.dark',
    e_count: 'success.dark',
    s_count: 'success.light',
    n_count: 'warning.light',
    u_count: 'error.dark'
  }

  const originalCounts = pickBy(gradeDistribution, (_value, key) =>
    some(['count'], str => includes(key, str))
  )

  if (!originalCounts) {
    return null
  }

  // Remove anything that has a 0 count
  const counts = Object.fromEntries(
    Object.entries(originalCounts).filter(([_key, value]) => value !== 0)
  )

  delete counts.none_count
  delete counts.dash_count

  const total = sum(Object.values(counts))
  const percentage = count => (total ? (count / total) * 100 : 0)

  return (
    <Stack
      direction="row"
      spacing={0}
      sx={{
        overflow: 'hidden',
        height: 20,
        borderRadius: 1,
        boxShadow: 1,
        backgroundColor: 'grey.200'
      }}
    >
      {Object.entries(counts).map(([letter, count]) => {
        const widthPercentage = percentage(count)
        const text = gradeNames[letter]
        const estimatedTextWidth = text.length * 8
        const boxWidth = (widthPercentage / 100) * 200
        const showText = boxWidth > estimatedTextWidth

        return (
          <Tooltip
            key={letter}
            title={`${count} grade(s) scored as ${text}`}
            arrow
            placement="top"
          >
            <Box
              sx={{
                width: `${widthPercentage}%`,
                backgroundColor: barClass[letter],
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '0.65rem',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '100%'
              }}
            >
              {showText && text}
            </Box>
          </Tooltip>
        )
      })}
    </Stack>
  )
}

GradeDistribution.propTypes = {
  gradeDistribution: PropTypes.object
}

export default GradeDistribution
