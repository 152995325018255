import React from 'react'
import { titleize } from 'utils'
import { alert } from 'utils/alert'
import SimpleAutocomplete from 'shared/simple-autocomplete'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Grid, Box, TextField, Typography } from '@mui/material'
import { actions as goalActions } from 'redux/modules/goals'
import Dialog from 'shared/dialog'

class GoalModal extends React.Component {
  static propTypes = {
    fetchData: PropTypes.func,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    goal: PropTypes.object,
    updateGoal: PropTypes.func
  }

  state = {
    activeGoal: this.props.goal
  }

  handleChange = (attribute, _value) => {
    this.setState(prevState => ({
      activeGoal: {
        ...prevState.activeGoal,
        [attribute]: _value
      }
    }))
  }

  resetModal = () => {
    this.setState({
      activeGoal: {}
    })
  }

  getPlaceholder = (goal, identifier) => {
    const goal_message = `goal_${identifier}_message`
    return goal[goal_message]
      ? titleize(` ${goal[goal_message]} At ${goal[identifier]}`)
      : goal[identifier]
      ? goal[identifier]
      : `Enter a new goal ${identifier}`
  }

  handleGoalSubmit = event => {
    event.preventDefault()
    const { activeGoal } = this.state
    const { updateGoal, fetchData, onHide } = this.props

    const params = {
      goal_id: this.props.goal.goal_id,
      ...activeGoal
    }
    updateGoal(params)
      .then(() => {
        alert('Goal has been successfully updated', 'success')
        fetchData()
        onHide()
      })
      .catch(err => {
        alert(
          'There was a problem updating the goal. ' + err.data.message + '.',
          'danger'
        )
      })
  }

  render() {
    const { onHide, show, goal } = this.props
    const { activeGoal } = this.state
    const options = [
      { label: 'Incremental or Maintain', value: 0 },
      { label: 'Moderate', value: 1 },
      { label: 'Aggressive', value: 2 }
    ]

    return (
      <Dialog
        open={show}
        onClose={onHide}
        onShow={this.resetModal}
        title={titleize(goal.category)}
      >
        <Box sx={{ padding: 2 }}>
          <form onSubmit={this.handleGoalSubmit}>
            <Typography variant="h6" gutterBottom>
              Set Your Goal
            </Typography>
            <TextField
              value={activeGoal.value}
              placeholder={this.getPlaceholder(goal, 'value')}
              fullWidth
              type="number"
              margin="normal"
              variant="outlined"
              label="My Goal"
              name="goal-value"
              id="goal-value"
              step="0.1"
              min="0.0"
              max="100"
              disabled={goal.goal_setting && !goal.goal_setting.enabled_value}
              onChange={val => this.handleChange('value', val.target.value)}
              required
            />
            <SimpleAutocomplete
              label="Goal Type"
              value={activeGoal.goal_type}
              placeholder={titleize(this.getPlaceholder(goal, 'type'))}
              options={options}
              id="goal-type"
              disabled={goal.goal_setting && !goal.goal_setting.enabled_type}
              onChange={val => this.handleChange('goal_type', val.value)}
            />
            <TextField
              label="Comments"
              placeholder={this.getPlaceholder(goal, 'comment')}
              fullWidth
              margin="normal"
              variant="outlined"
              name="goal-comment"
              id="goal-comment"
              multiline
              rows={4}
              value={activeGoal.comment || goal.comment}
              disabled={goal.goal_setting && !goal.goal_setting.enabled_comment}
              onChange={val => this.handleChange('comment', val.target.value)}
            />
            <Grid container justifyContent="flex-end">
              <Button type="submit" variant="contained" color="primary">
                Set Goal
              </Button>
            </Grid>
          </form>
        </Box>
      </Dialog>
    )
  }
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  goal: state.goals.activeGoal
})

const mapDispatchToProps = {
  updateGoal: goalActions.updateGoal
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalModal)
