import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

export function Note({ value }) {
  return (
    <Typography variant={'subtitle2'} sx={{ whiteSpace: 'pre-wrap' }}>
      {value || <i>none</i>}
    </Typography>
  )
}

Note.propTypes = {
  value: PropTypes.string
}

export default Note
