import PropTypes from 'prop-types'
export { fetchRequirements } from './actions'
export { reducer } from './reducer'

export const RequirementPropType = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  child_study_id: PropTypes.number,
  data_type: PropTypes.string,
  data_id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  text: PropTypes.string
})

export const RequirementsPropType = PropTypes.arrayOf(RequirementPropType)
