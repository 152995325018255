import React, { useState, useEffect } from 'react'
import { currentSchoolYear } from 'utils'
import { alert } from 'utils/alert'
import DistrictDatesForm from './form'
import { getDistrictDates } from 'api/admin/district_dates'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/admin-page-container'
import { Stack, Typography } from '@mui/material'

export default function DistrictDates() {
  const [districtDates, setDistrictDates] = useState([])
  const year = currentSchoolYear

  useEffect(() => {
    getDistrictDates()
      .then(res => {
        setDistrictDates(res)
      })
      .catch(err => {
        alert(err)
      })
  }, [])

  return (
    <Page name="Admin-DistrictDates" title="District Dates">
      <PageContainer>
        <Typography variant="h1">District Dates</Typography>
        <Stack>
          {districtDates &&
            districtDates.map((districtDate, i) => (
              <DistrictDatesForm
                key={i}
                category={districtDate.category}
                year={year}
                startDate={districtDate.start_date}
                endDate={districtDate.end_date}
              />
            ))}
        </Stack>
      </PageContainer>
    </Page>
  )
}
