import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Breadcrumbs } from '@mui/material'

function Header({ children }) {
  return <Box component="header">{children}</Box>
}

Header.Contents = ({ children, ...props }) => (
  <Box
    {...props}
    sx={{
      pt: 0
    }}
  >
    {children}
  </Box>
)

Header.Title = ({ children }) => (
  <Typography variant="h1" id="test_header_title">
    {children}
  </Typography>
)

Header.Filters = ({ children }) => (
  <Box>
    <Box>{children}</Box>
  </Box>
)

Header.Breadcrumbs = ({ children }) => (
  <Breadcrumbs aria-label="breadcrumbs">{children}</Breadcrumbs>
)

Header.propTypes = { children: PropTypes.node }
Header.Contents.propTypes = { children: PropTypes.node }
Header.Title.propTypes = { children: PropTypes.node }
Header.Filters.propTypes = { children: PropTypes.node }
Header.Breadcrumbs.propTypes = { children: PropTypes.node }
export default Header
