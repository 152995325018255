import React from 'react'
import PropTypes from 'prop-types'
import { developmentAreaShape } from './prop-types'
import { groupBy } from 'lodash'
import Typography from '@mui/material/Typography'

const DevelopmentAreaTitle = ({ developmentArea, masteryLevelId = null }) => {
  const { indicators } = developmentArea
  const groupedMasteryLevels = groupBy(indicators, 'mastery_level.id')
  const masteryLevels = Object.values(groupedMasteryLevels).map(
    ([{ mastery_level }]) => mastery_level
  )

  const determineTitle = () => {
    return masteryLevels.find(
      masteryLevel => masteryLevel.id === masteryLevelId
    )?.name
  }

  return <Typography>{determineTitle()}</Typography>
}

DevelopmentAreaTitle.propTypes = {
  developmentArea: developmentAreaShape.isRequired,
  masteryLevelId: PropTypes.number
}

export default DevelopmentAreaTitle
