import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PaperBox from 'components/shared/PaperBox'
import PieChartFiveSlices from 'components/shared/charts/PieChartFiveSlices'
import { useQuery } from '@tanstack/react-query'
import { handleRailsRequestJsResponse } from 'utils/handleRailsRequestJsResponse'
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'

// These use rails/request.js
import { getAcademicInterventionsSummary } from 'api/academic-interventions'

export function ElementaryAcademicPlatforms({ school }) {
  const [platform, setPlatform] = useState('amira')

  const { data: academicInterventionsSummary } = useQuery(
    ['academic-interventions-school-summary', school.id],
    async () => {
      const response = await getAcademicInterventionsSummary({
        school_id: school.id
      })
      const json = await handleRailsRequestJsResponse(response)

      return json.data.platform_data
    },
    {
      initialData: null,
      refetchOnWindowFocus: false
    }
  )

  if (!academicInterventionsSummary) {
    return null
  }

  const amiraInterventionsSummary = academicInterventionsSummary?.find(
    r => r['platform'] == 'amira'
  )
  const istationInterventionsSummary = academicInterventionsSummary?.find(
    r => r['platform'] == 'istation'
  )
  const imagineLearningInterventionsSummary =
    academicInterventionsSummary?.find(r => r['platform'] == 'imagine_learning')

  const platformOptions = [
    { label: 'Amira', value: 'amira' },
    { label: 'Imagine Learning', value: 'imagine_learning' },
    { label: 'Istation', value: 'istation' }
  ]
  return (
    <Stack>
      <FormControl fullWidth>
        <InputLabel id="platform-label">Platform</InputLabel>
        <Select
          id="platform"
          labelId="platform-label"
          label="Platform"
          value={platform}
          onChange={val => setPlatform(val.target.value)}
        >
          {platformOptions.map(option => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {platform === 'amira' && (
        <PaperBox
          title={
            'Usage by Active Students With Amira As Primary Intervention Last Week'
          }
          helperText={{
            'Usage':
              'Any time or activities recorded by the vendor for a given student, by week',
            'Primary Intervention':
              'The Walk-to-Read intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in Primary Interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
            'Threshold':
              'Spent at least 40 minutes in lessons or 4 stories this week.'
          }}
        >
          <PieChartFiveSlices
            one={{
              title: 'Used - Threshold Met',
              value: amiraInterventionsSummary?.counts?.threshold_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'amira'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Amira as their primary platform."
              }
            }}
            two={{
              title: 'Used - Threshold Not Met',
              value: amiraInterventionsSummary?.counts?.threshold_not_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'amira'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Amira as their primary platform."
              }
            }}
            three={{
              title: 'Not Used',
              value: amiraInterventionsSummary?.counts?.not_used,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'amira'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Amira as their primary platform."
              }
            }}
          />
        </PaperBox>
      )}
      {platform === 'istation' && (
        <PaperBox
          title={
            'Usage by Active Students With iStation As Primary Intervention Last Week'
          }
          helperText={{
            'Usage':
              'Any time or activities recorded by the vendor for a given student, by week',
            'Primary Intervention':
              'The Walk-to-Read intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in Primary Interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
            'Threshold': '40 minutes per week for all grades.'
          }}
        >
          <PieChartFiveSlices
            one={{
              title: 'Used - Threshold Met',
              value: istationInterventionsSummary?.counts?.threshold_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'istation'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Istation as their primary platform."
              }
            }}
            two={{
              title: 'Used - Threshold Not Met',
              value: istationInterventionsSummary?.counts?.threshold_not_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'istation'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Istation as their primary platform."
              }
            }}
            three={{
              title: 'Not Used',
              value: istationInterventionsSummary?.counts?.not_used,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'istation'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Istation as their primary platform."
              }
            }}
          />
        </PaperBox>
      )}
      {platform === 'imagine_learning' && (
        <PaperBox
          title={
            'Usage by Active Students With Imagine Learning As Primary Intervention Last Week'
          }
          helperText={{
            'Usage':
              'Any time or activities recorded by the vendor for a given student, by week',
            'Primary Intervention':
              'The Walk-to-Read intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in Primary Interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
            'Threshold': 'Pass at least 1 lesson per week.'
          }}
        >
          <PieChartFiveSlices
            one={{
              title: 'Used - Threshold Met',
              value: imagineLearningInterventionsSummary?.counts?.threshold_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'imagine_learning'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Imagine Learning as their primary platform."
              }
            }}
            two={{
              title: 'Used - Threshold Not Met',
              value:
                imagineLearningInterventionsSummary?.counts?.threshold_not_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'imagine_learning'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Imagine Learning as their primary platform."
              }
            }}
            three={{
              title: 'Not Used',
              value: imagineLearningInterventionsSummary?.counts?.not_used,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'imagine_learning'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Imagine Learning as their primary platform."
              }
            }}
          />
        </PaperBox>
      )}
    </Stack>
  )
}

ElementaryAcademicPlatforms.propTypes = {
  school: PropTypes.shape({
    id: PropTypes.number
  })
}

export default ElementaryAcademicPlatforms
