import { get, post } from 'utils/ajax'
import { alert } from 'utils/alert'

export const createTeamMeeting = params => {
  return post('/api/web/team_meetings', { team_meeting: params }).catch(
    _err => {
      alert('Something went wrong when creating the team meeting', 'danger')
    }
  )
}

export const getTeamMeetings = params => {
  return get('/api/web/team_meetings', { params: params }).catch(_err => {
    alert('Something went wrong when fetching the team meetings.', 'danger')
  })
}
