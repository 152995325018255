import {
  createTeacherMeeting,
  updateTeacherMeeting
} from 'api/teacher-meetings'
import { alert } from 'utils/alert'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import Dialog from 'components/shared/dialog'
import {
  styled,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { getHours, getMinutes } from 'utils/time-helpers'
import { grey } from '@mui/material/colors'

function MeetingDialog({
  meeting,
  refreshFeed,
  setShowMeeting,
  showMeeting,
  teacher,
  user
}) {
  const [date, setDate] = useState(
    meeting ? moment(meeting.date)._d : moment()._d
  )
  const [startTime, setStartTime] = useState(
    meeting ? moment(meeting.start_time)._d : moment()._d
  )
  const [endTime, setEndTime] = useState(
    meeting
      ? moment(meeting.end_time)._d
      : Math.min(moment().endOf('day')._d, moment().add(30, 'minutes')._d)
  )
  const [subject, setSubject] = useState(meeting?.subject || '')
  const [notes, setNotes] = useState(meeting?.notes || '')
  const [isPrivate, setIsPrivate] = useState(
    meeting ? meeting.is_private : true
  )
  const hasTimeError = endTime < startTime

  const resetValues = () => {
    setStartTime(moment()._d)
    setEndTime(moment().add(30, 'minutes')._d)
    setSubject('')
    setNotes('')
    setIsPrivate(true)
  }

  const resetDialogAndRefresh = () => {
    setShowMeeting(false)
    resetValues()
    refreshFeed()
  }

  const StyledToggleButton = styled(ToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: grey[300]
    }
  })

  const handleSubmit = () => {
    if (hasTimeError) {
      alert('The end time must be after the start time.')
    } else {
      const endHours = getHours(endTime)
      const endMins = getMinutes(endTime)
      const startHours = getHours(startTime)
      const startMins = getMinutes(startTime)

      const amendedEndTime = moment(date)
        .set('hours', endHours)
        .set('minutes', endMins)._d
      const amendedStartTime = moment(date)
        .set('hours', startHours)
        .set('minutes', startMins)._d

      const params = {
        teacher_id: teacher.id,
        user_id: user.id,
        date: date,
        start_time: amendedStartTime,
        end_time: amendedEndTime,
        subject: subject,
        notes: notes,
        is_private: isPrivate
      }

      if (meeting) {
        updateTeacherMeeting(meeting.id, params).then(() => {
          resetDialogAndRefresh()
        })
      } else {
        createTeacherMeeting(params).then(() => {
          resetDialogAndRefresh()
        })
      }
    }
  }

  return (
    <Dialog fullWidth open={showMeeting} onClose={() => setShowMeeting(false)}>
      <DialogTitle>
        {meeting ? 'Update' : 'Log'} a Meeting with {teacher.name}
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Grid container>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <DatePicker
                  label="Date"
                  value={date}
                  onChange={newValue => setDate(newValue)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <TimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={newValue => setStartTime(newValue)}
                  views={['year', 'month', 'day', 'hours', 'minutes']}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth variant="outlined">
                <TimePicker
                  label="End Time"
                  value={endTime}
                  onChange={newValue => setEndTime(newValue)}
                  views={['year', 'month', 'day', 'hours', 'minutes']}
                />
              </FormControl>
            </Grid>
          </Grid>
          <FormControl fullWidth variant="outlined">
            <TextField
              label={'Subject'}
              id="subject"
              value={subject}
              onChange={val => setSubject(val.target.value)}
              required
            />
          </FormControl>
          <FormControl fullWidth variant="outlined">
            <TextField
              multiline
              rows={5}
              label={'Notes'}
              id="notes"
              value={notes}
              onChange={val => setNotes(val.target.value)}
              required
            />
          </FormControl>
          <ToggleButtonGroup
            exclusive
            value={isPrivate}
            onChange={val => setIsPrivate(val.target.value === 'true')}
          >
            <StyledToggleButton value={true} aria-label="private">
              Private <LockIcon />
            </StyledToggleButton>
            <StyledToggleButton value={false} aria-label="public">
              Public <LockOpenIcon />
            </StyledToggleButton>
          </ToggleButtonGroup>
          {isPrivate
            ? 'The meeting is only visible to you and the teacher.'
            : 'The meeting is visible to all users with access to the teacher.'}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          color={'primary'}
          id="save-meeting"
          onClick={() => handleSubmit()}
          disabled={!notes || !subject}
        >
          {meeting ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

MeetingDialog.propTypes = {
  meeting: PropTypes.object,
  refreshFeed: PropTypes.func.isRequired,
  setShowMeeting: PropTypes.func.isRequired,
  showMeeting: PropTypes.bool.isRequired,
  teacher: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

export default MeetingDialog
