import React from 'react'
import PropTypes from 'prop-types'
import { Stack, Typography } from '@mui/material'
import moment from 'moment'
import PaperBox from 'components/shared/PaperBox'
import Note from '../note'
import NewWindowLink from '../../../components/shared/new-window-link'

const formatObservationTitle = observation => {
  const started_at = moment(observation.started_at).format('MM/DD/YYYY')
  const section = `${observation.section.hour} ${observation.section.name}`
  const observer = observation.observer.user.name

  return `${started_at} - ${section} - ${observer}`
}

export default function RecentFeedback({ observations }) {
  return (
    <Stack>
      <Typography variant={'h2'}>Most Recent Feedback</Typography>
      {observations.length > 0 ? (
        observations.map(observation => (
          <PaperBox
            key={observation.id}
            title={formatObservationTitle(observation)}
          >
            <Note value={observation.note_to_teacher} />
          </PaperBox>
        ))
      ) : (
        <Typography variant={'body1'}>
          No recent informal observations.
        </Typography>
      )}

      <Typography variant={'h2'}>Resources</Typography>
      <Typography variant={'body1'}>
        <NewWindowLink
          text={'Tulsa Public Schools Curriculum Resources'}
          href={'https://sites.google.com/tulsaschools.org/curriculum/home'}
        />
      </Typography>
    </Stack>
  )
}

RecentFeedback.propTypes = {
  observations: PropTypes.arrayOf(PropTypes.object).isRequired
}
