import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'
import Dialog from 'components/shared/dialog'
import { DatePicker } from '@mui/x-date-pickers'
import {
  createExternalReferral,
  updateExternalReferral
} from 'api/external-referrals'
import { fetchExternalAgencies } from 'api/external-agencies'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'

function ExternalReferralModal({ show, setShow, concern, referral }) {
  const queryClient = useQueryClient()

  const [date, setDate] = useState(
    moment(referral?.date).toDate() || moment().toDate()
  )
  const [note, setNote] = useState(referral?.note || '')
  const [externalAgencyId, setExternalAgencyId] = useState(
    referral?.external_agency_id || ''
  )

  const handleClose = () => setShow(false)

  const resetForm = () => {
    setDate(moment().toDate())
    setNote('')
    setExternalAgencyId('')
  }

  const { data: agencies } = useQuery(
    ['external-agencies'],
    async () => {
      const res = await fetchExternalAgencies()
      return res.data
    },
    {
      initialData: []
    }
  )

  const newExternalReferralMutation = useMutation(
    params => createExternalReferral(params),
    {
      onSuccess: () => {
        resetForm()
        queryClient.invalidateQueries(['concern', concern.id])
      },
      onSettled: () => {
        handleClose()
      }
    }
  )

  const updateExternalReferralMutation = useMutation(
    params => updateExternalReferral(referral.id, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['concern', concern.id])
      },
      onSettled: () => {
        handleClose()
      }
    }
  )

  const handleSubmit = event => {
    event.preventDefault()

    const params = {
      concern_id: concern.id,
      external_agency_id: externalAgencyId,
      date,
      note: note.trim()
    }
    if (referral) {
      updateExternalReferralMutation.mutate(params)
    } else {
      newExternalReferralMutation.mutate(params)
    }
  }

  return (
    <Dialog
      title={`${referral ? 'Edit' : 'New'} External Referral`}
      open={show}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack>
            <DatePicker
              renderInput={props => <TextField {...props} />}
              disableToolbar
              required
              fullWidth
              value={date}
              inputFormat="MM/DD/YYYY"
              inputVariant="inline"
              margin="normal"
              id="date-event-on"
              onChange={val => setDate(val)}
              label="Date"
            />

            <FormControl fullWidth variant="outlined">
              <InputLabel id="external-referral-label">
                Department or External Agency
              </InputLabel>
              <Select
                labelId="external-referral-label"
                id="external-referral"
                label="Department or External Agency"
                value={externalAgencyId}
                onChange={e => setExternalAgencyId(e.target.value)}
                required
              >
                {agencies.map(a => (
                  <MenuItem key={`agency-option-${a.id}`} value={a.id}>
                    {a.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="external-referral-note"
              label="Note (Optional)"
              value={note}
              onChange={e => setNote(e.target.value.replace(/^\s+/, ''))}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              helperText={'Background for this external referral'}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            variant="contained"
            color="primary"
            type={'submit'}
            disabled={!externalAgencyId}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

ExternalReferralModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  concern: PropTypes.object.isRequired,
  referral: PropTypes.object
}

export default ExternalReferralModal
