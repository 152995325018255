import React from 'react'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useInformalObservation } from 'hooks/tulsa-way/useInformalObservation'
import { sortBy, uniqBy } from 'lodash'
import LearningWalkHeader from '../learning-walk/components/header'
import MasteryLevelTitle from './mastery-level-title'
import { useSelector } from 'react-redux'
import DevelopmentAreaScale from './development-area-scale'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import DevelopmentAreaPrompt from './development-area-prompt'
import { withRouterPropTypes } from 'utils/react-router-prop-types'
import { LowInferenceNotes } from './low-inference-notes'
import Note from '../note'

export function InformalObservationReview({ match }) {
  const user = useSelector(state => state.currentUser.user)

  const { data: observation } = useInformalObservation(match.params.id)

  if (!observation) {
    return null
  }

  // TODO: Ordering is inconsistent between observe form and review.
  const developmentAreas = sortBy(
    uniqBy(
      observation.development_area_masteries.map(
        developmentAreaMastery => developmentAreaMastery.development_area
      )
    ),
    ['weight']
  )

  return (
    <Page
      name={`Tulsa Way - ${observation.observation_type} Review`}
      title={`Tulsa Way - ${observation.observation_type} Review`}
    >
      <PageContainer>
        <Stack spacing={5}>
          <LearningWalkHeader observation={observation} />

          {observation.notes.length > 0 && (
            <>
              <Typography variant={'h4'}>Low Inference Notes</Typography>
              <LowInferenceNotes observation={observation} viewOnly />
            </>
          )}

          {(user.id === observation.observer.user.id ||
            user.rights?.has_admin_access ||
            user.rights?.is_leadership_development_coach) &&
            developmentAreas.map(developmentArea => (
              <Paper
                key={developmentArea.id}
                elevation={3}
                sx={theme => ({ padding: theme.spacing(3) })}
              >
                <Stack>
                  <Grid
                    container
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                  >
                    <Grid item>
                      <Typography variant={'h3'}>
                        {developmentArea.name}
                      </Typography>{' '}
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mb: 3
                      }}
                    >
                      <DevelopmentAreaScale
                        developmentArea={developmentArea}
                        masteryLevelId={
                          observation.development_area_masteries.find(
                            mastery =>
                              mastery.tulsa_way_development_area_id ===
                              developmentArea.id
                          ).tulsa_way_mastery_level_id
                        }
                      />
                      <MasteryLevelTitle
                        {...{
                          developmentArea,
                          masteryLevelId:
                            observation.development_area_masteries.find(
                              mastery =>
                                mastery.tulsa_way_development_area_id ===
                                developmentArea.id
                            ).tulsa_way_mastery_level_id
                        }}
                      />
                    </Grid>
                  </Grid>
                  <FormGroup>
                    <DevelopmentAreaPrompt
                      {...{
                        developmentArea,
                        masteryLevelId:
                          observation.development_area_masteries.find(
                            mastery =>
                              mastery.tulsa_way_development_area_id ===
                              developmentArea.id
                          ).tulsa_way_mastery_level_id
                      }}
                    />
                  </FormGroup>
                </Stack>
              </Paper>
            ))}
          <Stack>
            <Typography variant={'h4'}>Notes</Typography>
            {user.id === observation.observer.user.id && (
              <React.Fragment>
                <Typography variant={'subtitle1'}>
                  Private Note (Visible to Me)
                </Typography>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Note value={observation.note_to_self} />
                </Paper>
              </React.Fragment>
            )}
            {user.id === observation.observer.user.id && (
              <Typography variant={'subtitle1'}>
                Public Note (Visible to Teacher)
              </Typography>
            )}
            <Paper elevation={3} sx={{ p: 3 }}>
              <Note value={observation.note_to_teacher} />
            </Paper>
          </Stack>
        </Stack>
      </PageContainer>
    </Page>
  )
}

InformalObservationReview.propTypes = {
  ...withRouterPropTypes
}

export default InformalObservationReview
