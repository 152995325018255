import React from 'react'
import PropTypes from 'prop-types'
import ChildStudyReferralForm from './child-study-referral-form'
import Dialog from 'components/shared/dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

function ChildStudyReferralModal({
  show,
  onHide,
  studentId,
  handleCreateChildStudy
}) {
  return (
    <Dialog
      title={'Refer Student to Child Study Team'}
      open={show}
      onClose={onHide}
    >
      <ChildStudyReferralForm
        studentId={studentId}
        onHide={onHide}
        handleCreateChildStudy={handleCreateChildStudy}
        ActionsComponent={DialogActions}
        ContentComponent={DialogContent}
      />
    </Dialog>
  )
}

ChildStudyReferralModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  studentId: PropTypes.number,
  handleCreateChildStudy: PropTypes.func
}

export default ChildStudyReferralModal
