import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import BulkNoteGeneratorDialog from './bulk-note-generator-dialog'

function BulkNoteGeneratorButton({
  studentIds,
  filters,
  columnApiIncludes,
  totalResults
}) {
  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        disabled={studentIds.length === 0}
      >
        Add Note
      </Button>
      <BulkNoteGeneratorDialog
        onClose={onClose}
        open={open}
        studentIds={studentIds}
        totalResults={totalResults}
        filters={filters}
        columnApiIncludes={columnApiIncludes}
      />
    </React.Fragment>
  )
}

BulkNoteGeneratorButton.propTypes = {
  studentIds: PropTypes.array.isRequired,
  filters: PropTypes.array,
  columnApiIncludes: PropTypes.array,
  totalResults: PropTypes.array
}

export default BulkNoteGeneratorButton
