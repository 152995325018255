export function determineTermOptions(reportCardType) {
  switch (reportCardType) {
    case 'pre_k':
    case 'kindergarten':
    case 'elementary':
      return [
        { value: 'Q1', label: 'Q1' },
        { value: 'Q2', label: 'Q2' },
        { value: 'Q3', label: 'Q3' },
        { value: 'Q4', label: 'Q4' }
      ]
    case 'montessori_pre_k':
    case 'montessori_kindergarten':
    case 'montessori_elementary':
    case 'middle':
    case 'grading_for_understanding_middle':
    case 'high':
      return [
        { value: 'S1', label: 'S1' },
        { value: 'S2', label: 'S2' }
      ]
    default:
      return []
  }
}

export const reportCardTypeOptions = [
  { value: 'pre_k', label: 'Pre-K' },
  { value: 'montessori_pre_k', label: 'Pre-K (Montessori)' },
  { value: 'kindergarten', label: 'Kindergarten' },
  {
    value: 'montessori_kindergarten',
    label: 'Kindergarten (Montessori)'
  },
  { value: 'elementary', label: 'Elementary' },
  {
    value: 'montessori_elementary',
    label: 'Elementary (Montessori)'
  },
  { value: 'middle', label: 'Middle' },
  {
    value: 'grading_for_understanding_middle',
    label: 'Middle (G4U Pilot)'
  },
  { value: 'high', label: 'High' }
]
