import React from 'react'
import PropTypes from 'prop-types'
import SupportItem from './support/item'
import ExternalReferralRow from './external-referral/item'
import NoteRow from './note/item'
import ConcernStatusItem from './concern-status/item'
import ConcernRootCauseItem from './root-cause/item'

function Row({ concern, log }) {
  return (
    <React.Fragment>
      {log.loggable_type === 'Wellness::Note' && (
        <NoteRow
          parent={concern}
          queryName="concern"
          note={log.data}
          key={`note-log-${log.data.id}`}
          concern={concern}
        />
      )}
      {log.loggable_type === 'ExternalReferral' && (
        <ExternalReferralRow
          externalReferral={log.data}
          concern={concern}
          key={`external-referral-log-${log.data.id}`}
        />
      )}
      {log.loggable_type === 'Support' && (
        <SupportItem
          key={`support-log-${log.data.id}`}
          support={log.data}
          concern={concern}
        />
      )}
      {log.loggable_type === 'ConcernStatus' && (
        <ConcernStatusItem
          key={`concern-status-log-${log.data.id}`}
          concernStatus={log.data}
        />
      )}
      {log.loggable_type === 'ConcernRootCause' && (
        <ConcernRootCauseItem
          key={`concern-status-log-${log.data.id}`}
          concernRootCause={log.data}
          concern={concern}
        />
      )}
    </React.Fragment>
  )
}

Row.propTypes = {
  concern: PropTypes.object.isRequired,
  log: PropTypes.object.isRequired
}

export default Row
