import Button from '@mui/material/Button'
import TeacherNoteDialog from './note-dialog'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

function NoteButton({ teacherId, refreshFeed }) {
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <React.Fragment>
      <TeacherNoteDialog
        show={modalOpen}
        onHide={() => setModalOpen(false)}
        refreshFeed={refreshFeed}
        teacherId={teacherId}
      />
      <Button
        variant={'contained'}
        color={'primary'}
        onClick={() => setModalOpen(true)}
        id="create-teacher-note"
      >
        Create Teacher Note
      </Button>
    </React.Fragment>
  )
}

NoteButton.propTypes = {
  teacherId: PropTypes.number,
  refreshFeed: PropTypes.func.isRequired
}

export default NoteButton
