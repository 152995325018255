import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { calculatePercentage } from 'utils'
import DangerCell from './danger-cell'

const displayPercent = (counts, grade, accessor, value) => {
  const sanitizedValue = String(value === 'Monitored/Exited' ? 'Former' : value)

  if (counts[grade] === undefined) {
    return <DangerCell percent={0} isDanger={false} />
  }

  let part = 0,
    whole = 0

  if (typeof counts[grade] === 'number') {
    // No grouping: counts[grade] is a number
    part = counts[grade]
    whole = Object.keys(counts).reduce((acc, g) => {
      return acc + (typeof counts[g] === 'number' ? counts[g] : 0)
    }, 0)
  } else {
    // Grouping: counts[grade] is an object
    const gradeGroup = counts[grade] || {}
    part = gradeGroup[sanitizedValue] || 0
    whole = Object.values(gradeGroup).reduce((acc, num) => acc + num, 0)
  }

  const percent = calculatePercentage(part, whole, 0)
  const isDanger = percent >= 20 && ['F', 'U'].includes(grade)

  return <DangerCell percent={percent} isDanger={isDanger} />
}

function GradeRow({
  title,
  accessor,
  value,
  displayValue,
  gradeScale,
  counts
}) {
  return (
    <TableRow key={`${title}-${value}-row`}>
      <TableCell>
        <Typography variant="body1">{displayValue}</Typography>
      </TableCell>
      {gradeScale.map(c => (
        <TableCell align="center" key={`${title}-${value}-column-${c}`}>
          {displayPercent(counts, c, accessor, value)}
        </TableCell>
      ))}
    </TableRow>
  )
}

GradeRow.propTypes = {
  title: PropTypes.string.isRequired,
  accessor: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  displayValue: PropTypes.string.isRequired,
  gradeScale: PropTypes.array.isRequired,
  counts: PropTypes.object.isRequired
}

export default GradeRow
