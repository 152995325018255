import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { titleize } from 'utils'

function TestingWindowSelect({
  setTestingWindow,
  testingWindow,
  label = 'testing-window',
  includedTerms = ['fall', 'winter', 'spring']
}) {
  const termOptions = [
    {
      value: 'fall',
      label: 'Fall'
    },
    {
      value: 'winter',
      label: 'Winter'
    },
    {
      value: 'spring',
      label: 'Spring'
    }
  ]

  return (
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 180 }}>
      <InputLabel id={`${label}-select-label`}>{titleize(label)}</InputLabel>
      <Select
        labelId={`${label}-select-label`}
        id={`${label}-select`}
        value={testingWindow}
        label={`${titleize(label)}`}
        onChange={e => setTestingWindow(e.target.value)}
      >
        {termOptions
          .filter(t => includedTerms.includes(t.value))
          .map(term => (
            <MenuItem key={`term-option-${term.value}`} value={term.value}>
              {term.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}

TestingWindowSelect.propTypes = {
  setTestingWindow: PropTypes.func.isRequired,
  testingWindow: PropTypes.string.isRequired,
  label: PropTypes.string,
  includedTerms: PropTypes.array
}

export default TestingWindowSelect
