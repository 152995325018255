import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Chip from '@mui/material/Chip'
import { isEqual, isNull } from 'lodash'
import { Table } from 'components/shared/table'
import {
  fetchAssignments,
  selectors as assignmentSelectors
} from 'modules/assignments'
import { selectors } from 'modules/shared'
import { colorLetterGrade } from 'utils/color-class'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Alert from '@mui/material/Alert'

class AssignmentsModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    assignments: PropTypes.array.isRequired,
    fetchAssignments: PropTypes.func.isRequired,
    section: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      hour: PropTypes.string.isRequired
    }).isRequired,
    studentId: PropTypes.number.isRequired,
    isParentPortal: PropTypes.bool
  }

  state = {
    isLoading: false
  }

  componentDidMount() {
    this.handleFetchAssignments()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.section.id, this.props.section.id)) {
      this.handleFetchAssignments()
    }
  }

  handleFetchAssignments = () => {
    const { fetchAssignments, section, studentId } = this.props
    this.setState({ isLoading: true })
    fetchAssignments(studentId, { section_id: section.id }).then(_ =>
      this.setState({ isLoading: false })
    )
  }

  columns = () => {
    return [
      {
        Header: I18n.jsT('table_headers.assignments.name'),
        accessor: 'name'
      },
      {
        Header: I18n.jsT('table_headers.assignments.due_date'),
        accessor: 'due_date'
      },
      {
        Header: I18n.jsT('table_headers.assignments.points_earned'),
        accessor: 'points'
      },
      {
        Header: I18n.jsT('table_headers.assignments.total_points'),
        accessor: 'total_points'
      },
      {
        id: 'letter-label',
        Header: I18n.jsT('table_headers.assignments.letter'),
        maxWidth: 60,
        accessor: obj => obj.letter_grade,
        Cell: props =>
          props.value == null ? (
            <Chip variant="outlined" size="small" color="default" label="NG" />
          ) : (
            <Chip
              key={props.value}
              label={props.value}
              size="small"
              variant="outlined"
              color={colorLetterGrade(props.value)}
            />
          )
      },
      {
        id: 'percent',
        Header: I18n.jsT('table_headers.assignments.percent'),
        accessor: assignment =>
          !isNull(assignment.percent) && `${assignment.percent.toFixed(0)}%`
      }
    ]
  }

  render() {
    const { isParentPortal, onHide, show, assignments, section } = this.props
    const { isLoading } = this.state
    const modalTitle = `${I18n.jsT('titles.assignments')}: ${section.name} - ${
      section.hour
    }`

    return (
      <Dialog onClose={onHide} open={show} title={modalTitle}>
        <DialogContent>
          {!isParentPortal && (
            <Alert severity="info">
              The assignments are updated nightly from PowerSchool
            </Alert>
          )}

          <Table
            columns={this.columns()}
            data={assignments}
            loading={isLoading}
            pageSize={10}
            showPageSizeOptions={false}
            defaultSorted={[
              {
                id: 'due_date',
                desc: true
              }
            ]}
          />
        </DialogContent>
      </Dialog>
    )
  }
}

const mapStateToProps = (state, { studentId }) => ({
  assignments: assignmentSelectors.assignments(state, { studentId }),
  isParentPortal: selectors.isParentPortal(state)
})

const mapDispatchToProps = {
  fetchAssignments
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentsModal)
