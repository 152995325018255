import React from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { connect } from 'react-redux'
import { fetchSections, setSectionSelections } from 'modules/sections'
import { createReport } from 'modules/exports'
import { titleize } from 'utils'
import { alert, Severity } from 'utils/alert'
import { determineTermOptions, reportCardTypeOptions } from 'utils/report-cards'
import {
  Box,
  Button,
  Alert,
  Stack,
  Typography,
  TextField,
  Autocomplete
} from '@mui/material'
import DialogActions from '@mui/material/DialogActions'

class SectionReportOptions extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    gradeLevel: PropTypes.bool,
    sections: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    selectedSections: PropTypes.array,
    fetchSections: PropTypes.func.isRequired,
    setSectionSelections: PropTypes.func.isRequired,
    createReport: PropTypes.func.isRequired,
    schoolId: PropTypes.number
  }

  state = {
    locale: null,
    reportName: null,
    reportType: null,
    sectionsLoaded: false,
    color: 'black_and_white',
    term: null,
    reportCardType: null
  }

  componentDidMount() {
    this.handleFetchSections()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.gradeLevel !== this.props.gradeLevel) {
      this.handleFetchSections()
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    const sectionIds = this.props.selectedSections.map(({ value }) => value)
    const { closeModal, createReport } = this.props
    const { locale, reportType, reportName, color, term, reportCardType } =
      this.state

    if (this.props.selectedSections.length === 0) {
      alert('Please select at least one section'), Severity.ERROR
      return
    }

    const params = {
      locale,
      report_type: reportType,
      report_name: reportName,
      section_ids: sectionIds,
      color: color,
      selected_term: term,
      report_card_type: reportCardType
    }
    createReport(params).then(_ => {
      closeModal()
      alert(
        'Reports processing - refresh page to check status.',
        Severity.SUCCESS
      )
    })
  }

  handleSelectedSections = (event, newValue) => {
    this.setState({ localSelectedSections: newValue }, () => {
      this.props.setSectionSelections(newValue)
    })
  }

  handleFetchSections = debounce(
    input => {
      const { fetchSections, gradeLevel, schoolId } = this.props
      const params = {
        as_options: true,
        grade_level: gradeLevel,
        school_id: schoolId,
        search: input,
        per_page: 100
      }
      fetchSections(schoolId, params).then(_ => {
        this.setState({ sectionsLoaded: true })
      })
    },
    300,
    { leading: false, trailing: true }
  )

  reportTypeChanged = (event, newValue) => {
    const reportType = newValue ? newValue.value : null
    this.setState({
      reportType,
      reportName: reportType ? titleize(reportType) : null
    })
  }

  showLocaleSelect = () => {
    const { reportType } = this.state
    return (
      !reportType ||
      ![
        'graduation_summaries',
        'graduation_letters',
        'oklahoma_promise_letters',
        'ncaa_division_one',
        'ncaa_division_two',
        'missing_assignments'
      ].includes(reportType)
    )
  }

  render() {
    const {
      locale,
      reportName,
      reportType,
      sectionsLoaded,
      term,
      reportCardType
    } = this.state

    const { gradeLevel, sections, selectedSections, closeModal } = this.props

    const sectionOptions = Array.isArray(sections)
      ? sections
      : [sections].filter(Boolean)

    const reportTypeOptions = [
      { value: 'student_summaries', label: 'Student Summary' },
      { value: 'chronic_absenteeism', label: 'Chronic Absenteeism' },
      {
        value: 'five_consecutive_absences',
        label: 'Five Consecutive Absences'
      },
      { value: 'graduation_summaries', label: 'Grad Check - Summary' },
      { value: 'graduation_letters', label: 'Grad Check - Full' },
      { value: 'oklahoma_promise_letters', label: 'OK Promise GPA' },
      { value: 'ncaa_division_one', label: 'NCAA D1' },
      { value: 'ncaa_division_two', label: 'NCAA D2' },
      { value: 'missing_assignments', label: 'Missing Assignments' }
    ]

    const localeOptions = [
      { value: 'en', label: 'English' },
      { value: 'es', label: 'Spanish' },
      { value: 'chk', label: 'Chuukese (Attendance Letters Only)' },
      { value: 'prs', label: 'Dari (Attendance Letters Only)' },
      { value: 'hmn', label: 'Hmong (Attendance Letters Only)' },
      { value: 'mh', label: 'Marshallese (Attendance Letters Only)' },
      { value: 'ps', label: 'Pashto (Attendance Letters Only)' }
    ]

    return (
      <form onSubmit={this.handleSubmit}>
        <Stack spacing={2}>
          <Box>
            <Autocomplete
              multiple
              id="sections"
              options={sectionOptions}
              value={selectedSections}
              onChange={this.handleSelectedSections}
              onInputChange={(event, newInputValue) => {
                this.handleFetchSections(newInputValue)
              }}
              getOptionLabel={option => option.label}
              renderInput={params => (
                <TextField
                  {...params}
                  label={gradeLevel ? 'Grade Levels' : 'Sections'}
                  variant="outlined"
                  error={selectedSections.length === 0}
                  helperText={
                    selectedSections.length === 0
                      ? 'Please select at least one section'
                      : ' '
                  }
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <Autocomplete
              id="report-type"
              options={reportTypeOptions}
              value={
                reportTypeOptions.find(option => option.value === reportType) ||
                null
              }
              onChange={this.reportTypeChanged}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Report Type"
                  variant="outlined"
                  required
                />
              )}
            />
          </Box>

          <Box sx={{ mb: 2 }}>
            <TextField
              required
              id="report-name"
              label="Report Name"
              variant="outlined"
              fullWidth
              value={reportName || ''}
              onChange={e => this.setState({ reportName: e.target.value })}
            />
          </Box>

          {this.showLocaleSelect() ? (
            <Box sx={{ mb: 2 }}>
              <Autocomplete
                id="locale"
                options={localeOptions}
                value={
                  localeOptions.find(option => option.value === locale) || null
                }
                onChange={(event, newValue) =>
                  this.setState({ locale: newValue ? newValue.value : null })
                }
                renderInput={params => (
                  <TextField {...params} label="Language" variant="outlined" />
                )}
              />
            </Box>
          ) : (
            <Alert severity="info">
              This report is currently only available in English.
            </Alert>
          )}

          {reportType === 'report_cards' && (
            <>
              <Box sx={{ mb: 2 }}>
                <Autocomplete
                  id="reportCardType"
                  options={reportCardTypeOptions}
                  value={
                    reportCardTypeOptions.find(
                      option => option.value === reportCardType
                    ) || null
                  }
                  onChange={(event, newValue) =>
                    this.setState({
                      reportCardType: newValue ? newValue.value : null,
                      term: null
                    })
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Report Card Type"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <Autocomplete
                  id="term"
                  options={determineTermOptions(reportCardType)}
                  value={
                    determineTermOptions(reportCardType).find(
                      option => option.value === term
                    ) || null
                  }
                  onChange={(event, newValue) =>
                    this.setState({ term: newValue ? newValue.value : null })
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Term"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </Box>
            </>
          )}

          {sectionsLoaded ? (
            <DialogActions>
              <Button variant="text" onClick={closeModal}>
                Close
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={selectedSections.length === 0}
              >
                Save
              </Button>
            </DialogActions>
          ) : (
            <Typography variant="h4">
              Please wait while sections load...
            </Typography>
          )}
        </Stack>
      </form>
    )
  }
}

const mapStateToProps = state => ({
  sections: state.sections.bySchoolId.undefined,
  selectedSections: state.sections.selectedSections
})

const mapDispatchToProps = {
  fetchSections,
  createReport,
  setSectionSelections
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionReportOptions)
