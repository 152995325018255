import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GradesTable from './table'
import AssignmentsModal from './assignments-modal'
import { useCurrentGrades } from 'hooks/useCurrentGrades'

function CurrentGrades({ student }) {
  const [showModal, setShowModal] = useState(false)
  const [selectedSection, setSelectedSection] = useState(null)

  const closeModal = () => {
    setShowModal(false)
    setSelectedSection(null)
  }

  const openModal = section => {
    setShowModal(true)
    setSelectedSection(section)
  }

  const params = {
    studentId: student.id,
    schoolCategory: student.school.category
  }
  const { isSuccess, isLoading, data: grades } = useCurrentGrades({ params })

  return (
    <React.Fragment>
      {selectedSection && (
        <AssignmentsModal
          onHide={closeModal}
          show={showModal}
          section={selectedSection}
          studentId={student.id}
        />
      )}
      {isSuccess && (
        <GradesTable
          grades={grades}
          isSuccess={isSuccess}
          isLoading={isLoading}
          openModal={openModal}
        />
      )}
    </React.Fragment>
  )
}

export default CurrentGrades

CurrentGrades.propTypes = {
  student: PropTypes.object.isRequired
}
