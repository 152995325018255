import { destroy, patch, post } from 'utils/ajax'

export const createTeacherMeeting = meeting => {
  return post('/api/web/teacher_meetings', {
    teacher_meeting: meeting
  })
}

export const destroyTeacherMeeting = meetingId => {
  return destroy(`/api/web/teacher_meetings/${meetingId}`)
}

export const updateTeacherMeeting = (id, meeting) => {
  return patch(`/api/web/teacher_meetings/${id}`, {
    teacher_meeting: meeting
  })
}
