import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

const optionFinder = (v, options) => {
  if (v == null || v === '') return ''
  return (
    options.find(option => option === v) ||
    options.find(option => option?.label === v) ||
    options.find(option => option?.value === v) ||
    options.find(option => option?.id === v) ||
    ''
  )
}

export default function SimpleAutocomplete({
  label,
  options,
  value: valueProp,
  onChange,
  helperText,
  openOnFocus = true,
  required = false,
  ...props
}) {
  const [value, setValue] = useState(() => optionFinder(valueProp, options))

  useEffect(() => {
    setValue(optionFinder(valueProp, options))
  }, [options, valueProp])

  return (
    <Autocomplete
      freeSolo
      options={options}
      value={value || null}
      openOnFocus={openOnFocus}
      onChange={(_event, newValue) => {
        const finalValue = newValue == null ? '' : newValue
        setValue(finalValue)
        onChange(finalValue)
      }}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          required={required}
          helperText={helperText}
        />
      )}
      {...props}
    />
  )
}

//https://mui.com/material-ui/react-autocomplete/#options-structure
const optionTypes = (() => {
  const stringOrNumberType = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
  const optionType = PropTypes.shape({
    label: stringOrNumberType.isRequired,
    value: stringOrNumberType,
    id: stringOrNumberType
  })
  return [stringOrNumberType, optionType]
})()

SimpleAutocomplete.propTypes = {
  ...Autocomplete.propTypes,
  renderInput: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  label: PropTypes.string.isRequired,
  options: PropTypes.oneOfType(optionTypes.map(PropTypes.arrayOf)).isRequired,
  value: PropTypes.oneOfType(optionTypes),
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.any,
  required: PropTypes.bool
}
