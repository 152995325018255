import { get, post } from 'utils/ajax'

export const getDistrictDates = () => {
  return get('/api/admin/district_dates')
}

export const updateDistrictDate = params => {
  return post('/api/admin/district_dates', {
    district_date: params
  })
}
