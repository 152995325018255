import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { round, last } from 'lodash'
import { currentSchoolYear } from 'utils'
import Table from './table'
import Chart from './chart'
import AttendanceBadge from './badge'
import {
  fetchAttendances,
  selectors as attendanceSelectors
} from 'modules/attendances'
import CompareArrow from 'shared/table/compare_arrow'
import { selectors } from 'modules/shared'
import PageTitle from 'components/shared/page-title'
import ChartContainer from 'components/shared/chart-container'
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography
} from '@mui/material'

class Attendance extends React.Component {
  static propTypes = {
    attendances: PropTypes.array,
    chartData: PropTypes.array,
    fetchAttendances: PropTypes.func.isRequired,
    isParentPortal: PropTypes.bool,
    studentId: PropTypes.number.isRequired
  }

  state = {
    isLoading: false,
    selectedYear: currentSchoolYear
  }

  static selectableYears = [
    currentSchoolYear,
    currentSchoolYear - 1,
    currentSchoolYear - 2
  ]

  componentDidMount() {
    const opts = { include_chart_data: true }
    this.handleFetchAttendances(this.state.selectedYear, opts)
  }

  handleFetchAttendances = (year, opts = {}) => {
    const { fetchAttendances, studentId } = this.props
    const params = {
      include_tardies: true,
      school_year: year,
      ...opts
    }

    this.setState({ isLoading: true })
    fetchAttendances(studentId, params).then(() => {
      this.setState({ isLoading: false })
    })
  }

  handleSelect = event => {
    const newYear = event.target.value
    this.setState({ selectedYear: newYear })
    this.handleFetchAttendances(newYear)
  }

  yearOptions() {
    return Attendance.selectableYears.map(year => (
      <MenuItem key={year} value={year}>
        {`${year - 1}-${year}`}
      </MenuItem>
    ))
  }

  render() {
    const { attendances, chartData, isParentPortal } = this.props
    const { isLoading, selectedYear } = this.state
    const currentAttendance = attendances.length > 0 && last(attendances)
    const showDifference = currentSchoolYear === selectedYear

    const yearSelect = (
      <Paper sx={{ p: 2 }}>
        <FormControl fullWidth>
          <InputLabel>{I18n.jsT('labels.select_school_year')}</InputLabel>
          <Select
            value={selectedYear}
            onChange={this.handleSelect}
            label={I18n.jsT('labels.select_school_year')}
          >
            {this.yearOptions()}
          </Select>
        </FormControl>
      </Paper>
    )

    return (
      <React.Fragment>
        <PageTitle title={'Attendance Info'} />
        <Stack>
          {yearSelect}
          <Box>
            <Typography variant="h3">
              {I18n.jsT('titles.ytd_attendance', {
                year: `${selectedYear - 1}-${selectedYear}`
              })}
              :{currentAttendance && round(currentAttendance.cumulative, 2)}%
              {currentAttendance.cumulative && (
                <AttendanceBadge
                  isParentPortal={isParentPortal}
                  score={currentAttendance.cumulative}
                />
              )}
            </Typography>
            {showDifference && (
              <Typography variant="h4">
                <span>{I18n.jsT('titles.previous_month')}: </span>
                {currentAttendance && (
                  <CompareArrow
                    value={currentAttendance.change}
                    colorText={true}
                    inverse={false}
                  />
                )}
              </Typography>
            )}
          </Box>

          <ChartContainer>
            <Chart data={chartData} selectedYear={selectedYear} />
          </ChartContainer>

          <Box>
            <Table data={attendances} loading={isLoading} />
          </Box>
        </Stack>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state, { studentId }) => ({
  attendances: attendanceSelectors.attendances(state, studentId),
  chartData: attendanceSelectors.attendancesChartData(state, studentId),
  isParentPortal: selectors.isParentPortal(state)
})

const mapDispatchToProps = {
  fetchAttendances
}

export default connect(mapStateToProps, mapDispatchToProps)(Attendance)
