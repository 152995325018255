import PropTypes from 'prop-types'
import React, { useState } from 'react'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import PaperBox from 'components/shared/PaperBox'

function FailingGrades({
  currentFailingGrades,
  previousDayMetric,
  previousWeekMetric,
  previousTwoWeekMetric,
  previousMonthMetric,
  previousYearMetric
}) {
  const [comparison, setComparison] = useState('day')

  const getComparison = (courseName, current) => {
    const currentCount = current.length
    let previousCount = null

    if (comparison === 'day') {
      previousCount =
        previousDayMetric?.current_failing_grades?.data[courseName]?.length
    } else if (comparison === 'week') {
      previousCount =
        previousWeekMetric?.current_failing_grades?.data[courseName]?.length
    } else if (comparison === 'two_week') {
      previousCount =
        previousTwoWeekMetric?.current_failing_grades?.data[courseName]?.length
    } else if (comparison === 'month') {
      previousCount =
        previousMonthMetric?.current_failing_grades?.data[courseName]?.length
    } else if (comparison === 'year') {
      previousCount =
        previousYearMetric?.current_failing_grades?.data[courseName]?.length
    }

    if (!previousCount) {
      return ''
    }

    if (currentCount > previousCount) {
      return <div style={{ color: 'red' }}>+{currentCount - previousCount}</div>
    } else if (currentCount == previousCount) {
      return <div>0</div>
    } else if (currentCount < previousCount) {
      return (
        <div style={{ color: 'green' }}>-{previousCount - currentCount}</div>
      )
    }
  }

  return (
    <PaperBox title="Students Failing Courses" id="students_failing_courses">
      <Stack>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography>Total students failing one or more courses:</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Typography variant="h3" component="div">
            {currentFailingGrades && currentFailingGrades['unique_students']}
          </Typography>
        </Box>
        <Box>
          Compare to:{' '}
          <ToggleButtonGroup
            color="primary"
            value={comparison}
            exclusive
            onChange={val => setComparison(val.target.value)}
            aria-label="Display as"
          >
            <ToggleButton
              title="1 Day Ago"
              value="day"
              disabled={!previousDayMetric?.current_failing_grades?.data}
            >
              D
            </ToggleButton>
            <ToggleButton
              title="1 Week Ago"
              value="week"
              disabled={!previousWeekMetric?.current_failing_grades?.data}
            >
              W
            </ToggleButton>

            {/* TODO: re-enable when data works */}
            <ToggleButton
              title="2 Weeks Ago"
              value="two_week"
              disabled={
                true || !previousTwoWeekMetric?.current_failing_grades?.data
              }
            >
              2W
            </ToggleButton>
            <ToggleButton
              title="1 Month Ago"
              value="month"
              disabled={
                true || !previousMonthMetric?.current_failing_grades?.data
              }
            >
              M
            </ToggleButton>
            <ToggleButton
              title="1 Year Ago"
              value="year"
              disabled={
                true || !previousYearMetric?.current_failing_grades?.data
              }
            >
              Y
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <TableContainer>
          <Table size="small" aria-label="school-failing-grades-summary-table">
            <TableHead>
              <TableRow>
                <TableCell>Course</TableCell>
                <TableCell>Student Count</TableCell>
                <TableCell>Change</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(currentFailingGrades.data)
                .sort(
                  ([_nameA, failingA], [_nameB, failingB]) =>
                    failingB.length - failingA.length
                )
                .map(([courseName, current]) => {
                  return (
                    <TableRow key={courseName}>
                      <TableCell>{courseName}</TableCell>

                      <TableCell>{current.length}</TableCell>
                      <TableCell>
                        {getComparison(courseName, current)}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </PaperBox>
  )
}

FailingGrades.propTypes = {
  currentFailingGrades: PropTypes.object.isRequired,
  previousDayMetric: PropTypes.object.isRequired,
  previousWeekMetric: PropTypes.object.isRequired,
  previousTwoWeekMetric: PropTypes.object.isRequired,
  previousMonthMetric: PropTypes.object.isRequired,
  previousYearMetric: PropTypes.object.isRequired
}

export default FailingGrades
