import { get } from '@rails/request.js'

export const getAcademicAssessmentAmira = id =>
  get(`/api/web/academic_assessment/amiras/${id}.json`)

export const getAcademicAssessmentAmiras = query =>
  get('/api/web/academic_assessment/amiras.json', { query })

export const getAcademicAssessmentAmirasDyslexiaFlagged = query =>
  get('/api/web/academic_assessment/amiras/at_risk_of_dyslexia.json', { query })

export const getAcademicAssessmentAmirasSummary = query =>
  get('/api/web/academic_assessment/amiras/summary.json', { query })

export const getAcademicAssessmentAmirasDistrictSummary = query =>
  get('/api/web/academic_assessment/amiras/district_summary.json', { query })
