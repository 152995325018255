import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'utils/ajax'
import moment from 'moment'
import { titleize, calculatePercentage } from 'utils'
import { useQuery } from '@tanstack/react-query'
import GraduationStatusChip from 'components/shared/students/show/graduation_check/graduation-status-chip'
import PaperBox from 'components/shared/PaperBox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Stack from '@mui/material/Stack'

export function StudentInfo({ studentId }) {
  const params = {
    include_recent_map: true,
    include_yearly_attendance: true,
    include_alerts: false,
    include_school: true
  }

  const getStudentInfo = () =>
    get(`/api/web/students/${studentId}`, { params }).then(res => res.student)

  const { data: student } = useQuery(
    ['student-overview-info', studentId],
    getStudentInfo
  )

  const ifHighSchoolStudentGradPlanStatusCohort =
    student?.school?.category === 'high'
      ? [
          {
            key: 'graduation-plan',
            label: 'Graduation Plan',
            value: student?.graduation_plan
          },
          {
            key: 'graduation-status',
            label: 'Status',
            value: student?.graduation_status ? (
              <GraduationStatusChip label={student.graduation_status} />
            ) : (
              ''
            )
          },
          {
            key: 'cohort',
            label: 'Cohort',
            value: student?.cohort
          }
        ]
      : []

  const studentMeta = [
    {
      key: 'student-id',
      label: 'ID',
      value: student?.number
    },
    {
      key: 'school-name',
      label: 'School',
      value: titleize(student?.school ? student.school.name : '')
    },
    {
      key: 'grade-level',
      label: 'Grade',
      value: student?.grade_level
    },
    ...ifHighSchoolStudentGradPlanStatusCohort,
    {
      key: 'ethnicity',
      label: 'Ethnicity',
      value: student?.ethnicity
    },
    {
      key: 'primary-language',
      label: 'Primary Language',
      value: student?.native_language ? student.native_language : 'English'
    },
    {
      key: 'birthday',
      label: 'Birthday',
      value: student?.birthday
        ? moment(student.birthday).format('MM/DD/YYYY')
        : ''
    },
    {
      key: 'rsa-retained',
      label: 'RSA Retained',
      value: student?.rsa_retained
    },
    {
      key: 'socioeconomically-disadvantaged',
      label: 'Socioeconomically Disadvantaged',
      value: student?.socioeconomically_disadvantaged ? 'Yes' : 'No'
    },
    {
      key: 'email',
      label: 'Email',
      value: student?.email ? student.email.split('@')[0] : 'N/A',
      url: `mailto:${student?.email}`
    },
    {
      key: 'map-lexile',
      label: 'Lexile (MAP)',
      value: student?.recent_map ? student.recent_map.lexile : 'N/A'
    },
    {
      key: 'attendance',
      label: 'Attendance',
      value:
        student?.yearly_attendance[0] &&
        Math.round(
          calculatePercentage(
            student.yearly_attendance[0].present,
            student.yearly_attendance[0].membership
          )
        ) + '%'
    },
    {
      key: 'current_platform',
      label: 'Academic Intervention Platform',
      value: titleize(student?.current_platform)
    }
  ]

  const parentGuardianMeta = student
    ? [
        {
          key: 'parent-guardian-address',
          label: 'Address',
          value: `${titleize(student.address_street)} ${titleize(
            student.address_city
          )} ${student.address_state} ${student.address_zip}`
        },
        {
          key: 'parent-guardian-name',
          label: 'Name',
          value: `${student.guardian_first_name} ${student.guardian_last_name}`
        },
        {
          key: 'parent-guardian-phone',
          label: 'Phone',
          value: `${student.guardian_phone_number} ${
            student.guardian_phone_number2 !== null
              ? student.guardian_phone_number2
              : ''
          }`
        },
        {
          key: 'parent-guardian-email',
          label: 'Email',
          value: student?.guardian_email
            ? student.guardian_email.split('@')[0]
            : 'N/A',
          url: `mailto:${student?.guardian_email}`
        },
        {
          key: 'parent-guardian-update-contact-info',
          label: 'Update Contact Info',
          value: 'See Guidance',
          url: 'https://docs.google.com/document/d/1MWlIiVkNHEUBjhL7yJ0puYzGH3peKBSIXHjYQlpAYVo/edit?usp=sharing'
        }
      ]
    : []

  const renderRow = row => (
    <TableRow
      hover={true}
      key={row.key}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {row.label == 'ID' ? 'Number' : row.label}
      </TableCell>

      <TableCell align="right">
        {row.url ? (
          <a href={row.url} rel="noopener noreferrer" target="_blank">
            {row.value}
          </a>
        ) : (
          row.value
        )}
      </TableCell>
    </TableRow>
  )

  return (
    <Stack spacing={4}>
      {student ? (
        <React.Fragment>
          <PaperBox title="Student Information">
            <Table>
              <TableBody>{studentMeta.map(row => renderRow(row))}</TableBody>
            </Table>
          </PaperBox>

          <PaperBox title="Parent/Guardian Information">
            <Table>
              <TableBody>
                {parentGuardianMeta.map(row => renderRow(row))}
              </TableBody>
            </Table>
          </PaperBox>
        </React.Fragment>
      ) : (
        <span>loading...</span>
      )}
    </Stack>
  )
}

StudentInfo.propTypes = {
  studentId: PropTypes.number.isRequired
}

export default StudentInfo
