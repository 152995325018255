import DistrictSlipCompliance from './slip/compliance'
import AcademicInterventions from './academic_interventions'
import Schools from './schools'
import Enrollment from './enrollment'
import Metrics from './metrics'
import DistrictMetricsBySchool from './metrics-by-school'
import DistrictOverview from './overview'
import PageContainer from 'shared/page-container'
import Page from 'shared/page'
import { Redirect, Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'
import { Box, Stack, Typography } from '@mui/material'
import Tabs from 'components/shared/tabs'
import { withRouterPropTypes } from 'utils/react-router-prop-types'

const tabs = [
  { label: 'Overview', to: '/district/overview' },
  { label: 'Metrics', to: '/district/metrics' },
  { label: 'Metrics By School', to: '/district/metrics-by-school' },
  { label: 'Schools', to: '/district/schools' },
  { label: 'Enrollment', to: '/district/enrollment' },
  { label: 'Academic Interventions', to: '/district/academic-interventions' },
  { label: 'SLIP Compliance', to: '/district/slip-compliance' }
]

function District({ dayOfSchool }) {
  const { day_of_school, ordinal } = dayOfSchool
  const dayOfSchoolHeader =
    day_of_school > 0
      ? `${day_of_school}${ordinal} Day`
      : 'School’s out for summer 😎'

  return (
    <Page title="District Overview" name="District Overview">
      <PageContainer>
        <Stack>
          <Box
            sx={{
              mb: 6
            }}
          >
            <Typography variant="h1">District Data</Typography>
            <Typography variant="h2">{dayOfSchoolHeader}</Typography>
          </Box>

          <Tabs tabs={tabs} />

          <Box
            sx={{
              pt: 6
            }}
          >
            <Switch>
              <Redirect from="/district" exact={true} to="district/overview" />
              <Route path="/district/overview" component={DistrictOverview} />
              <Route path="/district/metrics" component={Metrics} />
              <Route
                path="/district/metrics-by-school"
                component={DistrictMetricsBySchool}
              />
              <Route path="/district/schools" component={Schools} />
              <Route path="/district/enrollment" component={Enrollment} />
              <Route
                path="/district/slip-compliance"
                component={DistrictSlipCompliance}
              />
              <Route
                path="/district/academic-interventions"
                component={AcademicInterventions}
              />
            </Switch>
          </Box>
        </Stack>
      </PageContainer>
    </Page>
  )
}

District.propTypes = {
  dayOfSchool: PropTypes.shape({
    day_of_school: PropTypes.number.isRequired,
    ordinal: PropTypes.string.isRequired
  }),
  ...withRouterPropTypes
}

export default District
