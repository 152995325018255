import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import Dialog from 'components/shared/dialog'
import {
  Avatar,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { studentShape } from 'utils/grad-plan-progress-prop-types'
import {
  createGradPlanNote,
  destroyGradPlanNote,
  fetchGradPlanNotes
} from 'api/grad-plan-notes'
import moment from 'moment'
import { Add, Assistant, Delete, Drafts, Email } from '@mui/icons-material'
import { isNull, maxBy, sortBy } from 'lodash'
import { EmailPreview, EmailView } from './email-preview'
import { sendStudentEmail } from 'api/grad-planner'

const noteShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  content: PropTypes.string,
  created_at: PropTypes.string.isRequired,
  created_by_user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })
})

const predefinedNotes = {
  'Graduation Track Status': [
    'On-Track: Your student is on track for an on time graduation.',
    'Off Track: Your student is off track for an on time graduation and needs to complete their recovery plan in order to get back on track.'
  ],
  'Recovery Plan': [
    'I would like to discuss your academic progress with you. Please schedule a meeting at your earliest convenience.',
    'To get back on track for on-time graduation, you must attend summer school.',
    'To get back on track for on-time graduation, you need to enroll in Twilight Academy.',
    'To get back on track for on-time graduation, you need to enroll in New Vision School.',
    'You are enrolled in credit recovery at Twilight.',
    'You are enrolled in Tulsa Tech Success Center (non-TPS).',
    'You are enrolled in on-site credit recovery.',
    'You are currently enrolled in course on-site.',
    'You are enrolled in Tulsa Community College.',
    'You are enrolling in New Vision School.',
    'You are enrolling in Summer School.',
    'You are completing non-course graduation requirements: ICAP, WBL, CPR, PFL, and FAFSA.'
  ],
  'Enrichment Opportunities': [
    'You may be eligible for Advanced Placement, college courses, and/or an internship. Please speak with your school counselor to review your options.',
    'You can find information on our webpage and resources are here: https://docs.google.com/document/d/1qJ7TusXwnIM7UY9JRrWgb-nA8WKKqtRuJTg5e1VQXDI.',
    'Now is a good time to start thinking about life after high school. Please speak with your College and Career Advisor to review your options.'
  ]
}

const getInitials = name => {
  return name
    .split(' ')
    .map(s => s.charAt(0))
    .join('')
}

function NoteInfo({ note, userId, handleDelete, ...props }) {
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      {...props}
    >
      <Box>
        <Typography variant={'body2'}>{note.created_by_user.name}</Typography>
        <Typography variant={'body2'}>
          {moment(note.created_at).format('MM/DD/YYYY hh:mm A')}
        </Typography>
      </Box>
      <Box>
        {userId === note.created_by_user.id && (
          <IconButton
            size={'small'}
            onClick={event => {
              event.stopPropagation()
              handleDelete(note.id)
            }}
          >
            <Delete fontSize={'inherit'} />
          </IconButton>
        )}
      </Box>
    </Stack>
  )
}
NoteInfo.propTypes = {
  note: noteShape.isRequired,
  userId: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired
}

function NoteChip({ note, userId, handleDelete }) {
  const [detailed, setDetailed] = React.useState(false)
  const noteCreatedByUserInitials = getInitials(note.created_by_user.name)

  return (
    <Box
      sx={{
        cursor: 'pointer',
        width: '100%'
      }}
      onClick={() => setDetailed(!detailed)}
    >
      <Chip
        size={'medium'}
        color={userId === note.created_by_user.id ? 'primary' : 'default'}
        key={note.id}
        label={
          <Box
            sx={{
              whiteSpace: 'pre-wrap'
            }}
          >
            {note.content}
          </Box>
        }
        sx={{
          'alignItems': 'flex-start',
          'py': 0.5,
          'mr': 1,
          'height': 'auto',
          '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal'
          }
        }}
        avatar={
          <Avatar title={note.created_by_user.name}>
            {noteCreatedByUserInitials}
          </Avatar>
        }
      />
      {detailed && (
        <NoteInfo
          note={note}
          userId={userId}
          handleDelete={handleDelete}
          sx={{ px: 2 }}
        />
      )}
    </Box>
  )
}
NoteChip.propTypes = {
  note: noteShape.isRequired,
  userId: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired
}

function Comment({ note, userId, handleDelete }) {
  const [detailed, setDetailed] = React.useState(false)

  return (
    <Stack
      spacing={1}
      width={'100%'}
      sx={{ cursor: 'pointer' }}
      onClick={() => setDetailed(!detailed)}
    >
      <Typography variant={'body'} sx={{ whiteSpace: 'pre-wrap' }}>
        {note.content}
      </Typography>
      {detailed && (
        <>
          <Divider />
          <NoteInfo note={note} userId={userId} handleDelete={handleDelete} />
        </>
      )}
    </Stack>
  )
}
Comment.propTypes = {
  note: noteShape.isRequired,
  userId: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired
}

export function Notes({
  student,
  userId,
  sentEmails,
  gradPlanRequirementActions,
  nodeIdsInPrimaryGradPlan
}) {
  const studentId = student.id
  const queryClient = useQueryClient()

  const [viewSentEmail, setViewSentEmail] = useState(null)
  const [openEmailPreview, setOpenEmailPreview] = useState(false)
  const [newInternalNote, setNewInternalNote] = useState('')
  const [newExternalNote, setNewExternalNote] = useState('')
  const [modifierKey, setModifierKey] = useState(null)

  const [addCommentOpen, setAddCommentOpen] = useState(false)
  const handleAddCommentOpen = _event => {
    setAddCommentOpen(true)
  }
  const handleAddCommentClose = _event => {
    setAddCommentOpen(false)
  }

  const { data: notes } = useQuery(
    ['grad-plan-notes', studentId],
    () => {
      return fetchGradPlanNotes({ student_id: studentId }).then(res =>
        sortBy(res.data, 'created_at').reverse()
      )
    },
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )
  const mostRecentSentEmail = maxBy(sentEmails, 'created_at')
  const internalNotes = notes.filter(note => note.note_type === 'internal')
  const externalNotes = notes.filter(note => note.note_type === 'external')
  const newExternalNotes = mostRecentSentEmail?.created_at
    ? externalNotes.filter(
        note => note.created_at > mostRecentSentEmail.created_at
      )
    : externalNotes

  const invalidateGradPlanNotesQuery = () => {
    queryClient.invalidateQueries({ queryKey: ['grad-plan-notes', studentId] })
  }

  const handleSubmit = async noteType => {
    if (noteType === 'internal') {
      if (!newInternalNote) {
        return
      }

      const params = {
        student_id: studentId,
        created_by_user_id: userId,
        note_type: noteType,
        content: newInternalNote
      }

      await createGradPlanNote(params)
      invalidateGradPlanNotesQuery()
      setNewInternalNote('')
    } else {
      if (!newExternalNote) {
        return
      }

      const params = {
        student_id: studentId,
        created_by_user_id: userId,
        note_type: noteType,
        content: newExternalNote
      }

      await createGradPlanNote(params)
      invalidateGradPlanNotesQuery()
      setNewExternalNote('')
    }
  }

  const handleDelete = id => {
    destroyGradPlanNote(id).then(() => {
      invalidateGradPlanNotesQuery()
    })
  }

  const handleEmailClick = () => {
    sendStudentEmail(studentId).then(() => {
      invalidateGradPlanNotesQuery()
      queryClient.invalidateQueries({
        queryKey: ['student-grad-planner', studentId]
      })
    })
  }

  const activeRequirementActions = gradPlanRequirementActions
    .filter(x => x.status == 'active')
    .filter(x => !isNull(x.ps_gp_node))
    .filter(x => nodeIdsInPrimaryGradPlan.includes(x.ps_gp_node.id))

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12} lg={4} xl={4}>
          <Stack component={Paper} padding={2}>
            <Box
              component={'form'}
              onSubmit={event => {
                event.preventDefault()
                handleSubmit('internal')
              }}
            >
              <Typography variant={'h4'}>Notes</Typography>
              <Stack direction={'row'} spacing={1}>
                <FormControl fullWidth>
                  <TextField
                    required
                    label={'Note (Internal)'}
                    placeholder={'Add an internal note here'}
                    multiline
                    rows={3}
                    margin="normal"
                    variant={'outlined'}
                    value={newInternalNote || ''}
                    maxLength={250}
                    onChange={event => setNewInternalNote(event.target.value)}
                    onKeyDown={event => {
                      if (event.key === 'Meta') {
                        setModifierKey(true)
                      }
                      switch (event.key) {
                        case 'Meta':
                          setModifierKey(true)
                          break
                        case 'Enter':
                          modifierKey && handleSubmit('internal')
                          break
                      }
                    }}
                    onKeyUp={event => {
                      switch (event.key) {
                        case 'Meta':
                          setModifierKey(false)
                          break
                      }
                    }}
                  />
                </FormControl>
                <Stack pt={2} spacing={1}>
                  <Tooltip arrow title={'Add comment'} placement={'left'}>
                    <IconButton type={'submit'}>
                      <Add />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Box>
            {Boolean(internalNotes?.length) && (
              <Stack
                spacing={0}
                component={Paper}
                padding={1}
                elevation={2}
                overflow={'auto'}
              >
                {internalNotes.map(internalNote => (
                  <Box key={internalNote.id} sx={{ display: 'flex', py: 1 }}>
                    <NoteChip
                      userId={userId}
                      note={internalNote}
                      handleDelete={handleDelete}
                    />
                  </Box>
                ))}
              </Stack>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} lg={8} xl={8}>
          <Stack component={Paper} padding={2}>
            <Typography variant={'h4'}>
              Guardian and Student Communication
            </Typography>

            <Stack
              direction={'row'}
              component={Paper}
              padding={2}
              elevation={4}
            >
              <Drafts />
              <Stack width={'100%'}>
                <Box>
                  <Typography>
                    To: {student.guardian_first_name}{' '}
                    {student.guardian_last_name} &lt;{student.guardian_email}
                    &gt;
                  </Typography>
                  <Typography>
                    Cc: {student.name} &lt;{student.email}&gt;
                  </Typography>
                </Box>

                <Typography variant={'h6'}>Active Completion Plans</Typography>
                <TableContainer fullWidth>
                  <Table size={'small'}>
                    <TableHead>
                      <TableRow>
                        <TableCell width={1}>Requirement</TableCell>
                        <TableCell width={1}>Term</TableCell>
                        <TableCell>Completion Plan</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activeRequirementActions.map(activeRequirementAction => (
                        <TableRow key={activeRequirementAction.id}>
                          <TableCell sx={{ verticalAlign: 'top' }}>
                            {activeRequirementAction.ps_gp_node.name}
                          </TableCell>
                          <TableCell sx={{ verticalAlign: 'top' }}>
                            {activeRequirementAction.planned_school_year - 2001}
                            /
                            {activeRequirementAction.planned_school_year - 2000}
                            &nbsp;{activeRequirementAction.planned_term}
                          </TableCell>
                          <TableCell sx={{ verticalAlign: 'top' }}>
                            <Typography variant={'body1'}>
                              {activeRequirementAction.method_of_completion}
                            </Typography>
                            <Typography variant={'body2'}>
                              {activeRequirementAction.notes}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Typography variant={'h6'}>Comments from Counselor</Typography>

                {Boolean(newExternalNotes?.length) && (
                  <Stack spacing={1}>
                    {newExternalNotes.reverse().map(externalNote => (
                      <Box
                        key={externalNote.id}
                        component={Paper}
                        elevation={1}
                        sx={{
                          padding: 1
                        }}
                      >
                        <Comment
                          userId={userId}
                          note={externalNote}
                          handleDelete={handleDelete}
                        />
                      </Box>
                    ))}
                  </Stack>
                )}

                <Stack
                  direction={'row'}
                  spacing={1}
                  component={'form'}
                  onSubmit={event => {
                    event.preventDefault()
                    handleSubmit('external')
                  }}
                >
                  <FormControl fullWidth>
                    <TextField
                      required
                      label={'Comment'}
                      placeholder={'Add comment here'}
                      multiline
                      rows={3}
                      margin="normal"
                      variant={'outlined'}
                      value={newExternalNote || ''}
                      onChange={event => setNewExternalNote(event.target.value)}
                      onKeyDown={event => {
                        if (event.key === 'Meta') {
                          setModifierKey(true)
                        }
                        switch (event.key) {
                          case 'Meta':
                            setModifierKey(true)
                            break
                          case 'Enter':
                            modifierKey && handleSubmit('external')
                            break
                        }
                      }}
                      onKeyUp={event => {
                        switch (event.key) {
                          case 'Meta':
                            setModifierKey(false)
                            break
                        }
                      }}
                    />
                  </FormControl>
                  <Stack pt={2} spacing={1}>
                    <Tooltip
                      arrow
                      title={'Add comment'}
                      placement={'left'}
                      open={addCommentOpen}
                      onOpen={handleAddCommentOpen}
                      onClose={handleAddCommentClose}
                    >
                      <IconButton type={'submit'}>
                        <Add />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      arrow
                      title={'Comment suggestions'}
                      placement={'left'}
                    >
                      <IconButton onClick={handleClick}>
                        <Assistant />
                      </IconButton>
                    </Tooltip>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                      {Object.entries(predefinedNotes).map(([k, v]) => (
                        <>
                          <ListSubheader>{k}</ListSubheader>
                          {v.map((value, i) => (
                            <MenuItem
                              key={i}
                              onClick={() => {
                                setNewExternalNote(
                                  newExternalNote.length > 0
                                    ? newExternalNote.concat(` ${value}`)
                                    : value
                                )
                                handleClose()
                              }}
                            >
                              {value}
                            </MenuItem>
                          ))}
                        </>
                      ))}
                    </Menu>
                  </Stack>
                </Stack>

                <Stack direction={'row'} justifyContent={'flex-end'}>
                  <Box onClick={handleAddCommentOpen}>
                    <Button
                      variant={'contained'}
                      color={'primary'}
                      onClick={event => {
                        event.stopPropagation()
                        setOpenEmailPreview(true)
                      }}
                      disabled={newExternalNote.length}
                    >
                      Email Guardian and Student
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Stack>

            {sentEmails.map((sentEmail, index) => (
              <Stack
                key={index}
                direction={'row'}
                component={Paper}
                padding={2}
                onClick={() => setViewSentEmail(sentEmail)}
                sx={{ cursor: 'pointer' }}
              >
                <Email />
                <Stack
                  direction={'row'}
                  width={'100%'}
                  justifyContent={'space-between'}
                >
                  <Box>
                    <Typography>
                      Sent:{' '}
                      {moment(sentEmail.created_at).format(
                        'MM/DD/YYYY hh:mm A'
                      )}
                    </Typography>
                    <Typography>To: {sentEmail.headers['To']}</Typography>
                    <Typography>Cc: {sentEmail.headers['Cc']}</Typography>
                    <Typography>From: {sentEmail.headers['From']}</Typography>
                  </Box>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Grid>
      </Grid>
      <Dialog
        title={'Email Preview'}
        open={openEmailPreview}
        onClose={() => setOpenEmailPreview(false)}
      >
        <DialogContent>
          <EmailPreview
            student={student}
            sx={{ width: '100%', height: '70vh' }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size={'medium'}
            onClick={() => {
              setOpenEmailPreview(false)
            }}
          >
            Cancel
          </Button>
          <Button
            variant={'contained'}
            color={'primary'}
            size={'medium'}
            onClick={() => {
              handleEmailClick()
              setOpenEmailPreview(false)
            }}
          >
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        title={'Email View'}
        open={viewSentEmail}
        onClose={() => setViewSentEmail(null)}
      >
        <DialogContent>
          {viewSentEmail && (
            <EmailView
              sentEmail={viewSentEmail}
              sx={{ width: '100%', height: '70vh' }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size={'medium'}
            onClick={() => {
              setViewSentEmail(null)
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
Notes.propTypes = {
  student: studentShape,
  userId: PropTypes.number.isRequired,
  sentEmails: PropTypes.array,
  gradPlanRequirementActions: PropTypes.array.isRequired,
  nodeIdsInPrimaryGradPlan: PropTypes.array.isRequired
}

export default Notes
