const GRADE_LEVEL_RANK = {
  'pre-k (3)': -2,
  'pre-k': -1,
  'k': 0,
  '1st': 1,
  '2nd': 2,
  '3rd': 3,
  '4th': 4,
  '5th': 5,
  '6th': 6,
  '7th': 7,
  '8th': 8,
  '9th': 9,
  '10th': 10,
  '11th': 11,
  '12th': 12,
  'Graduated': 99
}

export const gradeLevelSort = collection => {
  return collection.sort(
    (a, b) => GRADE_LEVEL_RANK[a.name] - GRADE_LEVEL_RANK[b.name]
  )
}
