import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Typography, Link as MuiLink } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

export const H1 = props => <Typography variant={'h1'} {...props} />
export const H2 = props => <Typography variant={'h2'} {...props} />
export const H3 = props => <Typography variant={'h3'} {...props} />
export const H4 = props => <Typography variant={'h4'} {...props} />
export const H5 = props => <Typography variant={'h5'} {...props} />
export const H6 = props => <Typography variant={'h6'} {...props} />
export const P = props => <Typography variant={'body1'} {...props} />
export const A = ({ href, children, ...props }) => {
  try {
    new URL(href)
    return (
      <MuiLink href={href} target={'_blank'} {...props}>
        {children}
      </MuiLink>
    )
  } catch {
    return (
      <MuiLink component={RouterLink} to={href} {...props}>
        {children}
      </MuiLink>
    )
  }
}
export const Caption = props => <Typography variant={'caption'} {...props} />
export const Footnote = props => <Typography variant={'caption'} {...props} />

export const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  p: P,
  a: A,
  // em
  // strong
  // ul,
  // ol,
  // li,
  // blockquote
  // table
  // thead
  // tbody
  // tr
  // th
  // td
  // img
  // code
  // hr

  // non-standard html tags must not be lowercase
  Caption,
  Footnote
}

export const MdxComponentProvider = props => (
  <MDXProvider components={components} {...props} />
)
