import PropTypes from 'prop-types'
export {
  createChildStudy,
  deleteChildStudy,
  fetchChildStudy,
  fetchEligibleChildStudyStudents
} from './actions'
export { reducer } from './reducer'

export const ChildStudyPropType = PropTypes.shape({
  id: PropTypes.number,
  student_id: PropTypes.number,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  created_at: PropTypes.string,
  last_action_taken_at: PropTypes.string,
  meetings_count: PropTypes.number,
  concerns_count: PropTypes.number,
  stage: PropTypes.string,
  current_status: PropTypes.string
})

export const ChildStudiesPropType = PropTypes.arrayOf(ChildStudyPropType)
