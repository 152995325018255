import React from 'react'
import PropTypes from 'prop-types'
import StudentFilters from 'components/shared/student-filters'
import queryString from 'query-string'
import { AbsoluteSpinner } from 'components/shared/spinner'
import SummaryTable from './summary-table'
import { convertKeysToSnake } from 'utils'
import { post, showError } from 'utils/ajax'
import Chart from './chart'
import ChartOptions from './chart-options'
import withStudentsTable, {
  withStudentsTablePropTypes
} from '../shared/hoc-with-students-table'
import { optionsPropTypes } from 'utils/contexts/options'
import { connect } from 'react-redux'
import { userPropTypes } from 'modules/current-user'
import Page from 'components/shared/page'
import ChartContainer from 'components/shared/chart-container'
import { selectors } from 'modules/schools'
import RouterLinkButton from '../../shared/router-link-button'
import { Typography } from '@mui/material'

class Attendance extends React.Component {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    ...withStudentsTablePropTypes,
    ...optionsPropTypes,
    ...userPropTypes
  }

  state = {
    chartType: 'ada_cumulative',
    chartResolution: 'month',
    chartData: null,
    chartSchoolYear: null,
    loadingChart: false,
    loadingSummaryTable: false
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search)
    if (params.chart_type) {
      if (
        ['ada_cumulative', 'school_attendance_percent', 'chronic'].includes(
          params.chart_type
        )
      ) {
        this.setState({ chartType: params.chart_type })
      }
    }

    if (params.chart_resolution) {
      if (['month', 'day', 'week'].includes(params.chart_resolution)) {
        this.setState({ chartResolution: params.chart_resolution })
      }
    }
  }

  apiParams = datasets => {
    const { chartType, chartResolution } = this.state
    // convert param keys to snake-case for API (eg. schoolYear -> school_year)
    const datasetParams = (datasets || this.props.filters).map(dataset => {
      return convertKeysToSnake(dataset)
    })

    return {
      datasets: datasetParams,
      chart_options: {
        chart_type: chartType,
        chart_resolution: chartResolution
      }
    }
  }

  submit = (datasetFilters, fetchSummary = true) => {
    const datasets = this.copyDatasets(datasetFilters || this.props.filters)
    const loadingVals = { loadingChart: true }
    if (fetchSummary) {
      loadingVals.loadingSummaryTable = true
    }

    this.setState({ datasets, ...loadingVals })
    this.props
      .fetchDatasetNames(this.apiParams(datasets))
      .then(() => this.fetchChartData(datasets))
      .then(() => fetchSummary && this.fetchSummaryTableData(datasets))
      .then(() => this.setState({ loading: false }))
      .catch(err => {
        this.setState({ loadingChart: false, loadingSummaryTable: false })
        showError('An error occurred fetching data', err)
      })
  }

  copyDatasets = datasets => {
    return datasets.map(d => ({ ...d }))
  }

  fetchChartData = datasets => {
    return post(
      '/api/web/attendance/chart_data',
      this.apiParams(datasets)
    ).then(res => {
      this.setState({ loadingChart: false })
      this.setState({
        chartData: res.data.data,
        chartSchoolYear: res.data.year
      })
    })
  }

  fetchSummaryTableData = datasets => {
    return post(
      '/api/web/attendance/summary_table_data',
      this.apiParams(datasets)
    ).then(data => {
      this.setState({ loadingSummaryTable: false })
      this.setState({
        summaryTableData: data.data,
        summaryTableSchoolYear: data.year // only populated if all same
      })
      return data.data
    })
  }

  // toggle between chronic/ADA/cumulative ADA
  // uses the last "submitted" filters - from state, not props
  chartOptionChanged = options => {
    Object.keys(options).forEach(key => {
      if (!['chartType', 'chartResolution'].includes(key)) {
        throw new Error(`Invalid chart option: ${key}`)
      }
    })

    this.setState({ ...options }, () => {
      this.submit(this.state.datasets, false)
    })
  }

  viewStudentsForDataset = datasetName => {
    const options = this.props.tableDatasetOptions()
    const selectedDatasetTable = options.find(o => o.label === datasetName)
    if (selectedDatasetTable) {
      this.props.displayToggled('table')
      this.props.tableDatasetChanged(selectedDatasetTable)
    }
  }

  render() {
    const { school, user } = this.props

    const {
      loadingChart,
      loadingSummaryTable,
      chartData,
      chartType,
      chartResolution,
      chartSchoolYear,
      summaryTableData
    } = this.state

    return (
      <Page name="Attendance" title={`Attendance – ${school.name}`}>
        <StudentFilters submit={this.submit} />

        <Typography variant="h3" id="test_h3_title">
          Attendance
        </Typography>

        {user.role !== 'partner' && (
          <RouterLinkButton
            to={`/students?school_id=${school.id}&view=Attendance`}
          >
            See Student Data
          </RouterLinkButton>
        )}

        <React.Fragment>
          <ChartOptions
            type={chartType}
            resolution={chartResolution}
            onOptionChanged={this.chartOptionChanged}
          />
          <ChartContainer>
            <div>
              {loadingChart && <AbsoluteSpinner size={50} />}
              <Chart
                data={chartData}
                type={chartType}
                resolution={chartResolution}
                schoolYear={chartSchoolYear}
                schoolName={school && school.name}
              />
            </div>
          </ChartContainer>
          <div>
            {loadingSummaryTable && <AbsoluteSpinner size={30} />}
            <SummaryTable
              data={summaryTableData}
              onViewStudentsClicked={this.viewStudentsForDataset}
            />
          </div>
        </React.Fragment>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  filters: state.studentFilters.filters,
  school: selectors.currentSchool(state)
})

export default connect(mapStateToProps)(withStudentsTable(Attendance))
