import { destroy, get, patch, post } from 'utils/ajax'
import { alert } from 'utils/alert'

export const fetchGradPlanRequirementActions = params => {
  return get('/api/web/grad_plan_requirement_actions', { params }).catch(() => {
    alert(
      'There was an error fetching the grad plan requirement actions.',
      'danger'
    )
  })
}

export const createGradPlanRequirementAction = gradPlanRequirementAction => {
  return post('/api/web/grad_plan_requirement_actions', {
    grad_plan_requirement_action: gradPlanRequirementAction
  }).catch(() => {
    alert(
      'There was an error creating the grad plan requirement action.',
      'danger'
    )
  })
}

export const updateGradPlanRequirementAction = (
  id,
  gradPlanRequirementAction
) => {
  return patch(`/api/web/grad_plan_requirement_actions/${id}`, {
    grad_plan_requirement_action: gradPlanRequirementAction
  }).catch(() => {
    alert(
      'There was an error updating the grad plan requirement action.',
      'danger'
    )
  })
}

export const destroyGradPlanRequirementAction = id => {
  return destroy(`/api/web/grad_plan_requirement_actions/${id}`).catch(() => {
    alert(
      'There was an error deleting the grad plan requirement action.',
      'danger'
    )
  })
}
