import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import { Typography, Grid, Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import { get } from '@rails/request.js'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import DocumentsList from './documents-list'

async function getDocuments(query) {
  const response = await get('/documents', {
    contentType: 'application/json',
    responseKind: 'json',
    query
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return await response.json
}

export const DocumentsManager = ({
  associations,
  children,
  disabled = false
}) => {
  const [documents, setDocuments] = useState({
    documents: [],
    uploaded: [],
    uploading: {} // an object so they can be individually removed
  })
  const [dragOver, setDragOver] = useState(false)
  const fileInputRef = useRef()
  const queryClient = useQueryClient()
  const uploadCounterRef = useRef(0)

  const documentsQueryKey = ['documents', associations]
  const documentsQuery = useQuery(documentsQueryKey, () =>
    getDocuments(associations)
  )
  const invalidate = () => {
    queryClient.invalidateQueries(documentsQueryKey)
  }

  const preventDragDefaults = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDragOver = event => {
    preventDragDefaults(event)
    setDragOver(true)
  }

  const handleDragEnter = event => {
    preventDragDefaults(event)
  }

  const handleDragLeave = () => {
    setDragOver(false)
  }

  const handleDrop = event => {
    // prevent default. otherwise page is replaced with dropped file
    event.preventDefault()

    // dataTransfer is present for DnD
    const target = event.dataTransfer || event.target

    // files is a FileSet
    const files = [...target.files]

    const newUploads = Object.fromEntries(
      files.map(file => {
        const id = ++uploadCounterRef.current

        return [id, { id, file }]
      })
    )

    setDocuments(({ documents, uploaded, uploading }) => {
      return { documents, uploaded, uploading: { ...newUploads, ...uploading } }
    })
    setDragOver(false)
  }

  React.useEffect(() => {
    if (documentsQuery.data) {
      setDocuments(({ uploading }) => ({
        uploading,
        uploaded: [], // reset uploaded
        documents: documentsQuery.data
      }))
    }
  }, [documentsQuery.data])

  if (!documentsQuery.data) {
    // TODO: something if documentsQuery.isError
    return null
  }

  return (
    <React.Fragment>
      <h3>Documents</h3>

      {children}

      <Box
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current.click()}
        sx={{
          w: '100%',
          h: '100%',
          backgroundColor: dragOver ? grey[500] : grey[300],
          borderStyle: 'dotted'
        }}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ py: '5rem' }}
        >
          <Grid item xs={3}>
            <Typography variant="body1" color="text.secondary">
              Click to add documents or drag them here.
            </Typography>

            <input
              disabled={disabled}
              ref={fileInputRef}
              style={{ visibility: 'hidden' }}
              multiple={true}
              type="file"
              onChange={handleDrop}
            />
          </Grid>
        </Grid>
      </Box>

      <DocumentsList
        documents={documents.documents}
        uploading={documents.uploading}
        uploaded={documents.uploaded}
        associations={associations}
        onEdited={_id => invalidate()}
        onDestroyed={_id => invalidate()}
        onUpload={({ upload, doc }) => {
          const newUpload = { ...doc, id: upload.id }
          invalidate()
          setDocuments(({ documents, uploaded, uploading }) => {
            delete uploading[upload.id]
            return { documents, uploaded: [newUpload, ...uploaded], uploading }
          })
        }}
      />
    </React.Fragment>
  )
}

DocumentsManager.propTypes = {
  associations: PropTypes.object.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool
}

export default DocumentsManager
