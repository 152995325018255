import React from 'react'
import PropTypes from 'prop-types'

import { titleize } from 'utils'

import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormLabel from '@mui/material/FormLabel'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Tooltip from '@mui/material/Tooltip'

const useStyles = makeStyles(_theme => ({
  tooltip: {
    fontSize: '1em'
  }
}))

export function IndicatorInput({
  indicatorId,
  indicators,
  values,
  errors,
  onChange,
  level = 0,
  showErrors = false,
  disabled = false
}) {
  const classes = useStyles()
  const indicator = indicators[indicatorId]

  const { id, title, description } = indicator
  const error = showErrors && errors[id]

  const determineLabel = option => {
    switch (option) {
      case null:
      case '':
      case 'n/a':
        return 'N/A'
      case true:
      case 'yes':
        return 'Yes'
      case false:
      case 'no':
        return 'No'
      default:
        return titleize(`${option}`)
    }
  }

  return (
    <Box style={{ marginTop: '2rem' }}>
      <FormControl component="fieldset" error={Boolean(error)}>
        <FormLabel component="legend" style={{ borderBottom: 'none' }}>
          {title}
          {description && (
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={description}
              placement="right"
            >
              <HelpOutlineIcon
                style={{
                  marginLeft: '1rem'
                }}
              />
            </Tooltip>
          )}
        </FormLabel>
        <RadioGroup
          row
          onChange={val => onChange(id, val.target.value)}
          value={values[id] || ''}
          style={{
            flexWrap: 'nowrap'
          }}
        >
          {indicator.field_options.map(
            option =>
              option !== null && (
                <FormControlLabel
                  key={`${indicator}-${option}`}
                  value={option}
                  control={<Radio color="primary" />}
                  label={determineLabel(option)}
                  disabled={disabled}
                />
              )
          )}
        </RadioGroup>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>

      {indicator.children_indicator_ids.map(id => (
        <Box
          key={id}
          style={{
            paddingLeft: '2rem'
          }}
        >
          <IndicatorInput
            indicatorId={id}
            level={level + 1}
            {...{ values, errors, onChange, indicators, showErrors, disabled }}
          />
        </Box>
      ))}
    </Box>
  )
}

IndicatorInput.propTypes = {
  indicatorId: PropTypes.number.isRequired,
  indicators: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  level: PropTypes.number,
  showErrors: PropTypes.bool,
  disabled: PropTypes.bool
}

export default IndicatorInput
