import { get, post, patch, destroy } from '@rails/request.js'

const destroyPastThirdGradeSlip = async id =>
  await destroy(`/slip_past_third_grade_forms/${id}`)

const getPastThirdGradeSlips = async params =>
  await get('/slip_past_third_grade_forms', {
    query: params,
    responseKind: 'json'
  })

const getPastThirdGradeSlip = async params =>
  await get(`/slip_past_third_grade_forms/${params.id}`)

const getPastThirdGradeSlipDocuments = async id =>
  await get(`/slip_past_third_grade_forms/${id}/documents`)

const getPrefilledPastThirdGradeSlip = async params =>
  await get('/slip_past_third_grade_forms/new', {
    query: params.student_id ? params : {}
  })

const postPastThirdGradeSlip = async params =>
  await post('/slip_past_third_grade_forms', {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

const patchPastThirdGradeSlip = async (id, params) =>
  await patch(`/slip_past_third_grade_forms/${id}`, {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

const getPastThirdGradeSlipReports = async params =>
  await get('/slip_past_third_grade_forms/reports', {
    query: params,
    responseKind: 'json'
  })

export {
  destroyPastThirdGradeSlip,
  getPastThirdGradeSlip,
  getPastThirdGradeSlips,
  getPrefilledPastThirdGradeSlip,
  getPastThirdGradeSlipDocuments,
  postPastThirdGradeSlip,
  patchPastThirdGradeSlip,
  getPastThirdGradeSlipReports
}
