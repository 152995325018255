export const types = {
  FETCH_ACTIVITY_FEED: 'teachers/fetch_activity_feed',
  FETCH_MORE_ACTIVITY_FEED: 'teachers/fetch_more_activity_feed'
}

const initialState = {
  feedItems: [],
  meta: {},
  hasMore: true,
  sections: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ACTIVITY_FEED:
      return {
        ...state,
        feedItems: action.payload.data,
        meta: action.payload.meta,
        hasMore: action.payload.meta.page < action.payload.meta.pages
      }
    case types.FETCH_MORE_ACTIVITY_FEED:
      return {
        ...state,
        feedItems: [...state.feedItems, ...action.payload.data],
        meta: action.payload.meta,
        hasMore: action.payload.meta.page < action.payload.meta.pages
      }
    default:
      return state
  }
}
