import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import SidebarNavLink from './sidebar-nav-link'
import HomeIcon from '@mui/icons-material/Home'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import SchoolIcon from '@mui/icons-material/School'
import FaceIcon from '@mui/icons-material/Face'
import PeopleIcon from '@mui/icons-material/People'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
import FlagIcon from '@mui/icons-material/Flag'
import InputIcon from '@mui/icons-material/Input'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic'
import TouchAppIcon from '@mui/icons-material/TouchApp'
import SettingsIcon from '@mui/icons-material/Settings'
import SupportIcon from '@mui/icons-material/Support'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MapIcon from '@mui/icons-material/Map'
import ArticleIcon from '@mui/icons-material/Article'
import { useLocation } from 'react-router-dom'
import logo from 'images/TPS_2Color.png'

const drawerWidth = 225

const determineSchoolOverviewLink = (user, schoolId) => {
  const base = schoolId ? `/schools/${schoolId}` : '/schools'
  if (
    ['district', 'principal', 'support', 'partner'].includes(user.role) ||
    user.rights.is_wellness_team_member_at_schools.length > 0
  ) {
    return `${base}/overview`
  } else if (user.role === 'teacher' && user.rights.is_rsa_coordinator) {
    return `${base}/ipri`
  }
}

function Sidebar({ mobileOpen, setMobileOpen, permanentSx = {} }) {
  const user = useSelector(state => state.currentUser.user)
  const schoolId = useSelector(state => state.schools.schoolId)
  const location = useLocation()
  const path = location.pathname

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const activeLink = prefix => {
    // remove any trailing slashes since we’ll add one in the regex pattern
    const normalizedPrefix = prefix.replace(/\/+$/, '')

    // create a regex pattern from the prefix
    const pattern = `^${normalizedPrefix}(/|$)`

    return new RegExp(pattern).test(path)
  }

  const drawer = (
    <React.Fragment>
      {mobileOpen && (
        <React.Fragment>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <a href="/" style={{ padding: '0px' }}>
                <img src={logo} className="logo" style={{ height: '50px' }} />
              </a>
            </Box>
            <IconButton onClick={handleDrawerToggle}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>

          <Divider />
        </React.Fragment>
      )}

      <List>
        {user.role === 'district' && (
          <SidebarNavLink
            title={'District'}
            url={'/district'}
            icon={<HomeIcon />}
            active={activeLink('/district')}
          />
        )}

        {(['district', 'principal', 'support', 'partner'].includes(user.role) ||
          user.rights.is_rsa_coordinator ||
          user.rights.is_wellness_team_member_at_schools.length > 0) && (
          <SidebarNavLink
            title={'School'}
            url={determineSchoolOverviewLink(user, schoolId)}
            active={activeLink('/schools') && !path.includes('/goals')}
            icon={<DirectionsBusIcon />}
          />
        )}

        {['district', 'principal', 'support'].includes(user.role) && (
          <SidebarNavLink
            title={'Sections'}
            url={'/sections'}
            active={activeLink('/sections')}
            icon={<AutoAwesomeMosaicIcon />}
          />
        )}

        {['district', 'principal'].includes(user.role) && (
          <SidebarNavLink
            title={'Teachers'}
            url={'/teachers'}
            active={activeLink('/teachers')}
            icon={<PeopleIcon />}
          />
        )}

        {(['district', 'principal', 'support'].includes(user.role) ||
          user.rights.is_wellness_team_member_at_schools.length > 0) && (
          <SidebarNavLink
            title={'Students'}
            url={`/students${schoolId ? `?school_id=${schoolId}` : ''}`}
            active={activeLink('/students')}
            icon={<SchoolIcon />}
          />
        )}

        {(user.role === 'teacher' || user.has_sections) && (
          <SidebarNavLink
            title={'Teacher'}
            url={`/teachers/${user.teacher.id}`}
            active={activeLink(`/teachers/${user.teacher.id}`)}
            icon={<PeopleIcon />}
          />
        )}

        {['district', 'principal', 'support', 'teacher'].includes(
          user.role
        ) && (
          <SidebarNavLink
            title={'Student Supports and Concerns'}
            url={'/wellness'}
            active={activeLink('/wellness')}
            icon={<FaceIcon />}
          />
        )}

        {(['district', 'principal'].includes(user.role) ||
          user.rights?.child_study_team_school_ids?.length > 0) && (
          <SidebarNavLink
            title={'Child Studies'}
            url={'/child_studies'}
            active={activeLink('/child_studies')}
            icon={<PersonSearchIcon />}
          />
        )}

        {['district', 'principal', 'support'].includes(user.role) && (
          <SidebarNavLink
            title={'Goals'}
            url={`/schools/${schoolId}/goals`}
            active={activeLink(`/schools/${schoolId}/goals`)}
            icon={<FlagIcon />}
          />
        )}

        {['district', 'principal', 'teacher', 'support'].includes(
          user.role
        ) && (
          <SidebarNavLink
            title={'Exports'}
            url={'/exports'}
            active={activeLink('/exports')}
            icon={<InputIcon />}
          />
        )}

        {['district', 'principal', 'support', 'teacher'].includes(
          user.role
        ) && (
          <SidebarNavLink
            title={'Tulsa Way'}
            url={'/tulsa-way'}
            active={activeLink('/tulsa-way')}
            icon={<TouchAppIcon />}
          />
        )}

        {['district', 'support', 'teacher', 'principal'].includes(
          user.role
        ) && (
          <SidebarNavLink
            title={'Forms'}
            url={'/forms'}
            active={activeLink('/forms')}
            icon={<ArticleIcon />}
          />
        )}
      </List>

      <Divider />

      {['district', 'principal'].includes(user.role) && (
        <SidebarNavLink
          title={'Power BI Dashboards'}
          url={'/power-bi'}
          active={activeLink('/power-bi')}
          icon={<AnalyticsIcon />}
        />
      )}

      {['district', 'principal', 'support'].includes(user.role) && (
        <SidebarNavLink
          title={'Strategy Dashboards'}
          url={'https://strategydashboard.tulsaschools.org/'}
          icon={<MapIcon />}
          newTab={true}
        />
      )}
      <SidebarNavLink
        title={'Request Training'}
        url={
          'https://tulsaschools.sjc1.qualtrics.com/jfe/form/SV_eEYkTrbR6LA8mGh'
        }
        icon={<SupportIcon />}
        newTab={true}
      />
    </React.Fragment>
  )

  return (
    <React.Fragment>
      <Drawer
        id={'drawer-permanent'}
        variant="permanent"
        sx={{
          'display': { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            zIndex: 999,
            ...permanentSx
          }
        }}
        open
      >
        {drawer}
      </Drawer>

      <Drawer
        id={'drawer-temporary'}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          'display': { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}
      >
        {drawer}
      </Drawer>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  mobileOpen: PropTypes.bool.isRequired,
  setMobileOpen: PropTypes.func.isRequired,
  permanentSx: PropTypes.any
}

export default Sidebar
