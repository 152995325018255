import React, { useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import { snakeCase } from 'lodash'
import { titleize } from 'utils'
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  Alert
} from '@mui/material'
import { BarChart } from '@mui/x-charts/BarChart'
import { People, QuestionMark } from '@mui/icons-material'
import { userPropTypes } from 'modules/current-user'
import Page from 'components/shared/page'
import PaperBox from 'components/shared/PaperBox'
import PieChartFiveSlices from 'components/shared/charts/PieChartFiveSlices'
import {
  fetchDistrictOverview,
  fetchDistrictSchools,
  fetchDistrictFoundationalMetrics,
  fetchDistrictAcademicInterventions,
  fetchDistrictTulsaWayObservations,
  fetchDistrictMtss,
  fetchDistrictTeamMeetings
} from 'api/district-overview'
import FoundationalMetrics from './foundational-metrics'
import { AbsoluteSpinner } from 'components/shared/spinner'

function DistrictOverview({ user, schools }) {
  const [followUpsAnchorEl, setFollowUpsAnchorEl] = React.useState(null)
  const followUpsOpen = Boolean(followUpsAnchorEl)
  const followUpsId = followUpsOpen
    ? 'follow-ups-helper-text-popover'
    : undefined
  const [successPlansAnchorEl, setSuccessPlansAnchorEl] = React.useState(null)
  const successPlansOpen = Boolean(successPlansAnchorEl)
  const successPlansId = successPlansOpen
    ? 'follow-ups-helper-text-popover'
    : undefined
  // const [attendees, setAttendees] = useState([])
  const [selectedNetwork, setSelectedNetwork] = useState('All')
  const [selectedDesignation, setSelectedDesignation] = useState('All')
  const [selectedCategory, setSelectedCategory] = useState('All')

  const handleSelectCategory = event => {
    setSelectedCategory(event.target.value)
  }

  const handleSelectDesignation = event => {
    setSelectedDesignation(event.target.value)
  }

  const handleSelectNetwork = event => {
    setSelectedNetwork(event.target.value)
  }

  const {
    isFetching,
    data: { filtered_schools }
  } = useQuery(
    [
      'district-schools',
      selectedCategory,
      selectedNetwork,
      selectedDesignation
    ],
    async () =>
      await fetchDistrictSchools({
        category: selectedCategory,
        network: selectedNetwork,
        designation: selectedDesignation
      }),
    {
      initialData: {},
      refetchOnWindowFocus: false,
      enabled: !!(selectedCategory && selectedNetwork && selectedDesignation)
    }
  )
  const {
    data: { select_menu_items }
  } = useQuery(
    ['district-overview'],
    async () => await fetchDistrictOverview(),
    {
      initialData: {},
      refetchOnWindowFocus: false,
      enabled: !!(selectedCategory && selectedNetwork && selectedDesignation)
    }
  )

  const {
    data: { foundational_metrics }
  } = useQuery(
    [
      'foundational-metrics',
      selectedCategory,
      selectedNetwork,
      selectedDesignation
    ],
    async () =>
      await fetchDistrictFoundationalMetrics({
        category: selectedCategory,
        network: selectedNetwork,
        designation: selectedDesignation
      }),
    {
      initialData: {},
      refetchOnWindowFocus: false,
      enabled: !!(selectedCategory && selectedNetwork && selectedDesignation)
    }
  )

  const {
    data: { academic_interventions }
  } = useQuery(
    [
      'academic-interventions',
      selectedCategory,
      selectedNetwork,
      selectedDesignation
    ],
    async () =>
      await fetchDistrictAcademicInterventions({
        category: selectedCategory,
        network: selectedNetwork,
        designation: selectedDesignation
      }),
    {
      initialData: {},
      refetchOnWindowFocus: false,
      enabled: !!(selectedCategory && selectedNetwork && selectedDesignation)
    }
  )

  const {
    data: { tulsa_way_observations }
  } = useQuery(
    [
      'tulsa-way-observations',
      selectedCategory,
      selectedNetwork,
      selectedDesignation
    ],
    async () =>
      await fetchDistrictTulsaWayObservations({
        category: selectedCategory,
        network: selectedNetwork,
        designation: selectedDesignation
      }),
    {
      initialData: {},
      refetchOnWindowFocus: false,
      enabled: !!(selectedCategory && selectedNetwork && selectedDesignation)
    }
  )

  const {
    data: { mtss }
  } = useQuery(
    ['mtss', selectedCategory, selectedNetwork, selectedDesignation],
    async () =>
      await fetchDistrictMtss({
        category: selectedCategory,
        network: selectedNetwork,
        designation: selectedDesignation
      }),
    {
      initialData: {},
      refetchOnWindowFocus: false,
      enabled: !!(selectedCategory && selectedNetwork && selectedDesignation)
    }
  )

  const {
    data: { team_meetings }
  } = useQuery(
    ['team-meetings', selectedCategory, selectedNetwork, selectedDesignation],
    async () =>
      await fetchDistrictTeamMeetings({
        category: selectedCategory,
        network: selectedNetwork,
        designation: selectedDesignation
      }),
    {
      initialData: {},
      refetchOnWindowFocus: false,
      enabled: !!(selectedCategory && selectedNetwork && selectedDesignation)
    }
  )

  const schoolCategoryMenuItems = select_menu_items
    ? select_menu_items['category']
    : []
  const schoolNetworkMenuItems = select_menu_items
    ? select_menu_items['network']
    : []

  const arrayWrap = value => {
    if (value === null || value === undefined) {
      return []
    }
    return Array.isArray(value) ? value : [value]
  }

  const has_ssp = mtss?.attendance_related_student_success_plans?.has_ssp
  const no_ssp = mtss?.attendance_related_student_success_plans?.no_ssp

  const resetAllFilters = () => {
    setSelectedNetwork('All')
    setSelectedDesignation('All')
    setSelectedCategory('All')
  }

  const disabledReset =
    selectedCategory == 'All' &&
    selectedDesignation == 'All' &&
    selectedNetwork == 'All'

  return (
    <Page name="District Overall Metrics" title="District Overall Metrics">
      {isFetching ? (
        <AbsoluteSpinner size={50} />
      ) : (
        <Stack>
          <Stack>
            <Box>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="school-category-label">
                      School Category
                    </InputLabel>
                    <Select
                      id="school-category"
                      labelId="school-category-label"
                      label="School Category"
                      value={selectedCategory}
                      onChange={handleSelectCategory}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {schoolCategoryMenuItems.map(item => (
                        <MenuItem value={item} key={item}>
                          {titleize(item)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="school-designation-label">
                      Designation
                    </InputLabel>
                    <Select
                      id="school-designation"
                      labelId="school-designation-label"
                      label="Designation"
                      value={selectedDesignation}
                      onChange={handleSelectDesignation}
                    >
                      <MenuItem value="All">All</MenuItem>
                      <MenuItem value="mri">MRI</MenuItem>
                      <MenuItem value="csi">CSI</MenuItem>
                      <MenuItem value="csi_and_mri">CSI & MRI</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="school-network-label">Network</InputLabel>
                    <Select
                      id="school-network"
                      labelId="school-network-label"
                      label="Network"
                      value={selectedNetwork}
                      onChange={handleSelectNetwork}
                    >
                      <MenuItem value="All">All</MenuItem>
                      {schoolNetworkMenuItems.map(item => (
                        <MenuItem value={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={3}>
                  <Button
                    color={'warning'}
                    variant={'contained'}
                    onClick={() => resetAllFilters()}
                    disabled={disabledReset}
                  >
                    Reset Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>

            {filtered_schools?.length > 0 ? (
              <Box component={Stack}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <Box>
                      <Stack>
                        <FoundationalMetrics metric={foundational_metrics} />

                        <PaperBox
                          title={
                            <Link href="/district/academic-interventions">
                              Academic Interventions (Last Week)
                            </Link>
                          }
                        >
                          <PieChartFiveSlices
                            one={{
                              title: 'Used - Threshold Met',
                              value:
                                academic_interventions?.threshold_met_count_sum
                            }}
                            two={{
                              title: 'Used - Threshold Not Met',
                              value:
                                academic_interventions?.threshold_not_met_count_sum
                            }}
                            three={{
                              title: 'Not Used',
                              value:
                                academic_interventions?.not_used_this_week_count_sum
                            }}
                          />
                        </PaperBox>

                        {['district', 'principal'].includes(user.role) && (
                          <PaperBox
                            title={
                              <Link href={'/tulsa-way'}>
                                Tulsa Way Observations
                              </Link>
                            }
                          >
                            <Grid item md={12}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell>
                                      Unique Teacher Count (#)
                                    </TableCell>
                                    <TableCell>Total Count (#)</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>Informal Observations</TableCell>
                                    <TableCell>
                                      {
                                        tulsa_way_observations?.unique_informal_observations
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {
                                        tulsa_way_observations?.total_informal_observations
                                      }
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>3+ Average Score</TableCell>
                                    <TableCell>
                                      {
                                        tulsa_way_observations?.unique_informal_observations_with_three_plus_average_score
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {
                                        tulsa_way_observations?.total_informal_observations_with_three_plus_average_score
                                      }
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </Grid>
                          </PaperBox>
                        )}
                      </Stack>
                    </Box>
                  </Grid>

                  <Grid item md={6}>
                    <Box>
                      <Stack>
                        <PaperBox title="MTSS">
                          <Stack>
                            {/* No instruction as to where attendance tiers should go (their values currently being used as denominator for SSP graphic) */}
                            {/* {attendanceTiers && (
                              <PaperBox
                                title="Students Needing Attendance Support"
                                helperText={{
                                  'Who is included?':
                                    'Any student currently enrolled at the school.',
                                  'What attendance data is being used to determine the tier/risk level?':
                                    "We use attendance data from the student's current school this academic year to determine the tier/risk level. This does not include possible attendance data from other schools. If you'd like to see the student's YTD attendance %, go to an individual student's Attendance tab.",
                                  'Tier 1 (Not At Risk)':
                                    'ADA is above 95%. Students should receive standard district and schoolwide practices.',
                                  'Tier 1+ (At Risk)':
                                    'ADA is above 90% and less than or equal to 95%. Students should receive early supports.',
                                  'Tier 2 (Chronic)':
                                    'ADA is above 80% and less than or equal to 90%. Students should receive targeted interventions.',
                                  'Tier 3 (Critically Chronic)':
                                    'ADA is less than or equal to 80%. Students should receive the most intense, targeted interventions.'
                                }}
                              >
                                <PieChartFiveSlices
                                  one={{
                                    title: 'Tier 1 (Not At Risk)',
                                    value: attendanceTiers?.tier_one,
                                    options: {
                                      pathname: '/students',
                                      query_params: {
                                        school_id: school.id,
                                        view: 'Attendance',
                                        chronic: ['perfect', 'not_at_risk']
                                      }
                                    }
                                  }}
                                  two={{
                                    title: 'Tier 1+ (At Risk)',
                                    value: attendanceTiers?.tier_one_plus,
                                    options: {
                                      pathname: '/students',
                                      query_params: {
                                        school_id: school.id,
                                        view: 'Attendance',
                                        chronic: 'at_risk'
                                      }
                                    }
                                  }}
                                  three={{
                                    title: 'Tier 2 (Chronic)',
                                    value: attendanceTiers?.tier_two,
                                    options: {
                                      pathname: '/students',
                                      query_params: {
                                        school_id: school.id,
                                        view: 'Attendance',
                                        chronic: 'chronic'
                                      }
                                    }
                                  }}
                                  four={{
                                    title: 'Tier 3 (Critically Chronic)',
                                    value: attendanceTiers?.tier_three,
                                    options: {
                                      pathname: '/students',
                                      query_params: {
                                        school_id: school.id,
                                        view: 'Attendance',
                                        chronic: 'critically_chronic'
                                      }
                                    }
                                  }}
                                />
                              </PaperBox>
                            )} */}

                            <Box sx={{ border: 1, p: 2 }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'flex-start'
                                }}
                              >
                                <Typography variant="h6">
                                  Attendance-Related Student Success Plans
                                </Typography>
                                <IconButton
                                  onClick={event =>
                                    setSuccessPlansAnchorEl(event.currentTarget)
                                  }
                                  size="small"
                                >
                                  <QuestionMark />
                                </IconButton>
                                <Popover
                                  id={successPlansId}
                                  open={successPlansOpen}
                                  anchorEl={successPlansAnchorEl}
                                  onClose={() => setSuccessPlansAnchorEl(null)}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left'
                                  }}
                                >
                                  <Box sx={{ maxWidth: 250 }}>
                                    <Typography sx={{ p: 2 }}>
                                      <b>
                                        How is &apos;Has SSP&apos; calculated?
                                      </b>{' '}
                                      Count of unique students with either a
                                      Student Success Plan attached to an
                                      attendance concern or having a Student
                                      Success Plan Intervention.
                                      <Divider sx={{ my: 2 }} />
                                      <b>
                                        How is &apos;No SSP&apos; calculated?
                                      </b>{' '}
                                      Count of unique students without Student
                                      Success Plan attached to an attendance
                                      concern nor having a student success plan
                                      intervention.
                                      <Divider sx={{ my: 2 }} />
                                      <b>
                                        How often is this data updated?
                                      </b>{' '}
                                      These counts are recalculated nightly.
                                    </Typography>
                                  </Box>
                                </Popover>
                              </Box>
                              <BarChart
                                height={300}
                                onAxisClick={(_e, column) => {
                                  // combine perfect and not at risk to match yearly attendance risk levels
                                  const filters =
                                    column.axisValue == 'Not At Risk'
                                      ? 'chronic=not_at_risk,perfect'
                                      : `chronic=${snakeCase(column.axisValue)}`
                                  window.location.assign(
                                    `/students?view=Attendance&${filters}`
                                  )
                                }}
                                series={[
                                  {
                                    data: [
                                      has_ssp?.not_at_risk || 0,
                                      has_ssp?.at_risk || 0,
                                      has_ssp?.chronic || 0,
                                      has_ssp?.critically_chronic || 0
                                    ],
                                    label: 'Has SSP',
                                    stack: 'total'
                                  },
                                  {
                                    data: [
                                      no_ssp?.not_at_risk || 0,
                                      no_ssp?.at_risk || 0,
                                      no_ssp?.chronic || 0,
                                      no_ssp?.critically_chronic || 0
                                    ],
                                    label: 'No SSP',
                                    stack: 'total'
                                  }
                                ]}
                                xAxis={[
                                  {
                                    id: 'tiers',
                                    data: [
                                      'Not At Risk',
                                      'At Risk',
                                      'Chronic',
                                      'Critically Chronic'
                                    ],
                                    scaleType: 'band',
                                    label: 'Attendance Risk Levels'
                                  }
                                ]}
                              />
                            </Box>
                            <Table sx={{ border: 1 }}>
                              <TableHead>
                                <TableRow>
                                  <TableCell colSpan={3}>
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'flex-start'
                                      }}
                                    >
                                      <Typography variant="h6">
                                        Follow-Ups (Last 6 Weeks)
                                      </Typography>
                                      <IconButton
                                        onClick={event =>
                                          setFollowUpsAnchorEl(
                                            event.currentTarget
                                          )
                                        }
                                        size="small"
                                      >
                                        <QuestionMark />
                                      </IconButton>
                                      <Popover
                                        id={followUpsId}
                                        open={followUpsOpen}
                                        anchorEl={followUpsAnchorEl}
                                        onClose={() =>
                                          setFollowUpsAnchorEl(null)
                                        }
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'left'
                                        }}
                                      >
                                        <Box sx={{ maxWidth: 250 }}>
                                          <Typography sx={{ p: 2 }}>
                                            <b>
                                              How is Followed Up calculated?
                                            </b>{' '}
                                            All records, regardless of status,
                                            with updates in the last 6 weeks.
                                            <Divider />
                                            <b>
                                              How is Did Not Follow Up
                                              calculated?
                                            </b>{' '}
                                            All records without updates in the
                                            last 6 weeks.
                                          </Typography>
                                        </Box>
                                      </Popover>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Category</TableCell>
                                  <TableCell>Followed Up (#)</TableCell>
                                  <TableCell>Did Not Follow Up (#)</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>
                                    <Link href={'/wellness'}>
                                      Academic Targeted Interventions
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    {mtss?.total_academic_followed_up}
                                  </TableCell>
                                  <TableCell>
                                    {mtss?.total_academic_did_not_follow_up}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <Link href={'/wellness'}>
                                      Behavior Targeted Interventions
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    {mtss?.total_behavior_followed_up}
                                  </TableCell>
                                  <TableCell>
                                    {mtss?.total_behavior_did_not_follow_up}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <Link href={'/wellness'}>
                                      Attendance Targeted Interventions
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    {mtss?.total_attendance_followed_up}
                                  </TableCell>
                                  <TableCell>
                                    {mtss?.total_attendance_did_not_follow_up}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>
                                    <Link href={'/child_studies'}>
                                      Child Studies
                                    </Link>
                                  </TableCell>
                                  <TableCell>
                                    {mtss?.total_child_studies_followed_up}
                                  </TableCell>
                                  <TableCell>
                                    {
                                      mtss?.total_child_studies_did_not_follow_up
                                    }
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Stack>
                        </PaperBox>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
                <PaperBox title="Latest Meetings (limit 30)">
                  <Table sx={{ tableLayout: 'fixed' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>School</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Next Steps</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Attendees</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {arrayWrap(team_meetings).map(meeting => {
                        return (
                          <TableRow key={meeting.id}>
                            <TableCell>
                              <Link
                                href={`/schools/${meeting.school_id}/overview`}
                              >
                                {schools[meeting.school_id].name}
                              </Link>
                            </TableCell>
                            <TableCell>{meeting.meeting_type}</TableCell>
                            <TableCell>
                              {moment(meeting.date).format('MM/DD/YYYY')}
                            </TableCell>
                            <TableCell>{meeting.next_steps}</TableCell>
                            <TableCell sx={{ overflow: 'hidden' }}>
                              {meeting.notes}
                            </TableCell>
                            <TableCell>
                              <Tooltip
                                placement={'top'}
                                arrow
                                title={
                                  <Box>
                                    {arrayWrap(
                                      meeting?.team_meeting_attendees
                                    ).map((attendee, i) => (
                                      <Typography key={i}>
                                        {titleize(attendee.role)}{' '}
                                        {attendee.user
                                          ? `- ${attendee.user.name}`
                                          : ''}
                                      </Typography>
                                    ))}
                                  </Box>
                                }
                              >
                                <People />
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </PaperBox>
              </Box>
            ) : (
              <Alert severity={'warning'}>
                There is no data available for the selected filters.
              </Alert>
            )}
          </Stack>
        </Stack>
      )}
    </Page>
  )
}

DistrictOverview.propTypes = {
  ...userPropTypes
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  schools: state.schools.byId
})

export default connect(mapStateToProps)(DistrictOverview)
