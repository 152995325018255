import React from 'react'
import PropTypes from 'prop-types'
import UserTypeIcon from './user-type-icon'

export default function NameCell({ user }) {
  const today = new Date().toISOString().substring(0, 10)

  const user_override_expired =
    user.override &&
    user.override_expiration &&
    user.override_expiration <= today

  return (
    <div>
      {user.name}

      <span style={{ marginLeft: '0.5rem', cursor: 'default' }}>
        {user.override && (
          <UserTypeIcon
            id="user-override"
            text="📌"
            tip="User has override in place"
          />
        )}

        {user_override_expired && (
          <UserTypeIcon
            id="user-override-expiration"
            text="⏲"
            tip="User’s override has expired"
          />
        )}

        {user.beta && (
          <UserTypeIcon id="user-beta" text="⚡️" tip="Beta user" />
        )}
      </span>
    </div>
  )
}
NameCell.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    override: PropTypes.bool.isRequired,
    beta: PropTypes.bool.isRequired,
    override_expiration: PropTypes.string
  }).isRequired
}
