import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'

const PageTitle = ({ title, children }) => (
  <Grid container justifyContent="space-between">
    <Grid item xs={8}>
      <h1 style={{ marginTop: '10px', marginBottom: '20px' }}>{title}</h1>
    </Grid>
    <Grid item xs={4} sx={{ marginTop: '10px', marginBottom: '20px' }}>
      {children}
    </Grid>
  </Grid>
)

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any
}

export default PageTitle
