import React from 'react'
import PropTypes from 'prop-types'
import StarRating from 'components/shared/star-rating'
import { Box } from '@mui/material'

export const STAR_RATING_SCALE = 5

export const EssentialContentRating = ({ essentialContent }) => (
  <Box className="rating">
    <StarRating rating={essentialContent.score} scale={STAR_RATING_SCALE} />{' '}
    {essentialContent.score}/{STAR_RATING_SCALE} Essential Content
  </Box>
)
EssentialContentRating.propTypes = {
  essentialContent: PropTypes.object
}

export default EssentialContentRating
