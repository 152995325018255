import { getConcernType } from 'api/interventions'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export function useConcernType({ concernTypeId, options }) {
  const queryKey = ['concern-type', concernTypeId]
  const queryFn = () => getConcernType(concernTypeId)
  const query = useQuery(queryKey, queryFn, {
    enabled: Boolean(concernTypeId),
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  const data = query.data

  return { data, query, invalidate }
}
