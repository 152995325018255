import React from 'react'
import PropTypes from 'prop-types'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Title,
  Subtitle,
  Legend,
  XAxis,
  YAxis,
  LineSeries,
  Tooltip
} from 'react-jsx-highcharts'
import { titleize } from 'utils'

// TODO: Re-add school goal line if all series have the same goal to avoid confusion/clutter
// const calculateGoalLines = data => {
//   if (!data || !data.length) return []
//   const goals = data.map(series => series.goal)
//   const allSame = goals.every(goal => goal === goals[0])
//   if (!allSame) return []
//
//   return [
//     {
//       value: goals[0],
//       zIndex: 5,
//       color: 'black',
//       width: 2,
//       dashStyle: 'shortdash',
//       label: {
//         text: 'School Goal'
//       }
//     }
//   ]
// }

const AttendanceChart = ({ data, type, schoolName, resolution }) => {
  if (!data) {
    return <div style={styles.container} />
  }

  let xAxisTitle
  if (resolution === 'month') {
    xAxisTitle = 'Month'
  } else {
    xAxisTitle = `${titleize(resolution)} of School`
  }

  let yAxisTitle
  if (type === 'chronic') {
    yAxisTitle = '% Students Chronically Absent'
  } else {
    yAxisTitle = 'Attendance %'
  }

  // // Since we are using the same tooltip for all three chart resolutions,
  // we use specific point attributes that are unique to month versus weekly/daily.
  // This way only specific tooltip information pops up in the charts we want to view.
  // To view information coming into the tool tip, place the following code just below the '<Tooltip' line
  // formatter={()=>{debugger}}
  // then go to the attendance chart, hover over a point to trigger the debugger, then access "this".
  return (
    <div id="js-attendance-line-chart" style={styles.container}>
      <HighchartsChart id="js-attendance-line-chart">
        <Chart />
        <Title>% Attendance</Title>
        <Subtitle>{schoolName}</Subtitle>
        <Legend
          layout="horizontal"
          align="center"
          verticalAlign="bottom"
          borderWidth={0}
        />
        <Tooltip
          headerFormat="<p style='font-size:18px' class='text-center'>{point.point.name}{point.point.label}{point.point.options.x}</p><table>"
          pointFormat="<tr><td style='color:{series.color};padding:0;font-size:14px;'>{series.name}: </td><td style='padding:0;font-size:14px;'><b> {point.y:.1f}% {point.date}</b></td></tr>"
          footerFormat="</table>"
          shared={true}
          crosshairs={true}
          useHTML={true}
        />
        {/* TODO: set categories, title */}
        <XAxis type="category">
          <XAxis.Title>{xAxisTitle}</XAxis.Title>
        </XAxis>

        <YAxis id="number">
          <YAxis.Title>{yAxisTitle}</YAxis.Title>
        </YAxis>

        {data.map((series, index) => (
          <LineSeries
            id={`series-${index}`}
            name={series.name}
            data={series.data}
            marker={{ enabled: true }}
            key={index}
          />
        ))}
      </HighchartsChart>
    </div>
  )
}

AttendanceChart.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string.isRequired,
  resolution: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
  schoolName: PropTypes.string
}

const styles = {
  container: {
    minHeight: '400px'
  }
}

export default withHighcharts(AttendanceChart, window.Highcharts)
