import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { titleize } from 'utils'
import VisibilityIcon from '@mui/icons-material/Visibility'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { isNull, truncate } from 'lodash'
import {
  Box,
  IconButton,
  TableCell as MuiTableCell,
  Stack,
  TableRow,
  styled
} from '@mui/material'
import moment from 'moment'
import { Link } from 'react-router-dom'
import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp
} from '@mui/icons-material'

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  verticalAlign: 'top',
  borderBottomColor: theme.palette.grey[200]
}))

const TRUNCATE_LENGTH = 120

export default function ObservationRow({
  determineRoute,
  observation,
  onDelete,
  user
}) {
  const isComplete = !isNull(observation.ended_at)
  const [shouldTruncate, setShouldTruncate] = useState(true)

  return (
    <TableRow>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {moment(observation.started_at || observation.created_at).format(
          'MMM D, YYYY'
        )}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <Stack spacing={2}>
          {user.id !== observation.observee.user.id &&
          observation.observee.teacher ? (
            <Link to={`/teachers/${observation.observee.teacher.id}`}>
              {observation.observee.user.name}
            </Link>
          ) : (
            <Box>{observation.observee.user.name}</Box>
          )}
          {observation.observee.teacher ? (
            <Link
              to={`/teachers/${observation.observee.teacher.id}/sections?section_id=${observation.section.id}`}
            >
              {observation.section.hour}{' '}
              {observation.section.name_with_nickname}
            </Link>
          ) : (
            <Box>
              {observation.section.hour}{' '}
              {observation.section.name_with_nickname}
            </Box>
          )}
        </Stack>
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {observation.observer.user.name}
      </TableCell>
      <TableCell>{titleize(observation.status)}</TableCell>
      <TableCell>{observation.observation_type}</TableCell>
      <TableCell>
        <Box>
          {isComplete
            ? shouldTruncate
              ? truncate(observation.note_to_teacher, {
                  length: TRUNCATE_LENGTH
                })
              : observation.note_to_teacher
            : 'Will be visible once observation is marked as complete'}
          {(observation.note_to_teacher || '').length > TRUNCATE_LENGTH && (
            <IconButton
              title={`Click to ${shouldTruncate ? 'expand' : 'collapse'}`}
              onClick={() => setShouldTruncate(!shouldTruncate)}
              size={'small'}
            >
              {shouldTruncate ? (
                <KeyboardDoubleArrowDown />
              ) : (
                <KeyboardDoubleArrowUp />
              )}
            </IconButton>
          )}
        </Box>
      </TableCell>
      <TableCell sx={{ display: 'flex' }}>
        {
          <IconButton
            aria-label="show observation"
            title="show"
            component={Link}
            to={`/tulsa-way/${determineRoute(observation)}/${observation.id}`}
          >
            <VisibilityIcon />
          </IconButton>
        }
        {user.id === observation.observer.user.id && (
          <React.Fragment>
            <IconButton
              aria-label="edit observation"
              title="edit"
              component={Link}
              to={`/tulsa-way/${determineRoute(observation)}/${
                observation.id
              }/observe`}
              size={'small'}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete observation"
              title="delete"
              onClick={onDelete(observation)}
              size={'small'}
            >
              <DeleteIcon />
            </IconButton>
          </React.Fragment>
        )}
      </TableCell>
    </TableRow>
  )
}

ObservationRow.propTypes = {
  determineRoute: PropTypes.func.isRequired,
  observation: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}
