import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { titleize } from 'utils'

function TimelineRow({ concernStatus }) {
  return (
    <React.Fragment>
      <TimelineItem style={{ paddingRight: 0 }}>
        <TimelineOppositeContent
          style={{ flex: 0, padding: 0 }}
        ></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
            <FontAwesomeIcon icon={faUser} style={{ color: '#fff7eb' }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{ paddingRight: 0 }}>
          <Typography variant="h4">{titleize(concernStatus.status)}</Typography>
          <Typography style={{ maxWidth: '35em' }}>
            {concernStatus.note}
          </Typography>
          <Typography variant="body2" color="textSecondary" className="mr-4">
            Status changed on {moment(concernStatus.date).format('MM/DD/YYYY')}{' '}
            {concernStatus.user_name && `by ${concernStatus.user_name}`}
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </React.Fragment>
  )
}

TimelineRow.propTypes = {
  concernStatus: PropTypes.object.isRequired
}

export default TimelineRow
