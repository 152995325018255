import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

class ProtectedRoute extends React.Component {
  static propTypes = {
    isPermitted: PropTypes.bool.isRequired,
    redirectUrl: PropTypes.string.isRequired,
    component: PropTypes.any.isRequired,
    path: PropTypes.string.isRequired
  }

  isComponent(component) {
    return typeof component === 'function' || !!component.displayName
  }

  componentCheck(Component, props) {
    if (this.isComponent(Component)) {
      return <Component {...props} />
    } else {
      return Component
    }
  }

  render() {
    const { component, isPermitted, redirectUrl, path, ...props } = this.props
    return (
      <Route
        path={path}
        render={routerProps =>
          isPermitted ? (
            // `...routerProps`: pass to preserve possible existing use of routerProps in components.
            // `...props`: after routerProps, so if the user passes a conflicting prop name, we take theirs instead of routerProps’.
            this.componentCheck(component, { ...routerProps, ...props })
          ) : (
            <Redirect to={redirectUrl} />
          )
        }
        {...props}
      />
    )
  }
}

export default ProtectedRoute
