import React from 'react'
import PropTypes from 'prop-types'
import EventIcon from 'components/shared/activity-feed/event-icon'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

const FeedItemHeader = ({ title, titleIcon, category, date, children }) => {
  return (
    <Box sx={{ backgroundColor: '#f5f5f5', p: 2 }}>
      {children ? (
        children
      ) : (
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          <span style={{ marginRight: '4rem' }}>
            {date.format('MMM DD, YYYY')}
          </span>

          <span>
            {titleIcon && <EventIcon category={category} />}
            {title}
          </span>
        </Typography>
      )}
    </Box>
  )
}

FeedItemHeader.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.bool,
  category: PropTypes.string,
  status: PropTypes.oneOf(['success', 'info', 'danger']).isRequired,
  date: PropTypes.object.isRequired,
  children: PropTypes.node
}

export default FeedItemHeader
