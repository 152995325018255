import MeetingDialog from './meeting-dialog'
import NoteButton from './note-button'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useSelector, connect } from 'react-redux'
import { Button, Grid } from '@mui/material'

const TeacherActionButtons = ({ refreshFeed, teacher, user }) => {
  const [showMeeting, setShowMeeting] = useState(false)
  const isTeacher = useSelector(
    state => state.currentUser.user.role === 'teacher'
  )
  return (
    !isTeacher && (
      <Grid container spacing={2} sx={{ pb: 4 }}>
        <Grid item>
          <NoteButton teacherId={teacher.id} refreshFeed={refreshFeed} />
        </Grid>
        <Grid item>
          {['principal'].includes(user.role) && (
            <Button
              sx={{ ml: 2 }}
              variant={'contained'}
              color={'primary'}
              onClick={() => setShowMeeting(true)}
              id="log-teacher-meeting"
            >
              Log a Meeting
            </Button>
          )}
          <MeetingDialog
            refreshFeed={refreshFeed}
            setShowMeeting={setShowMeeting}
            showMeeting={showMeeting}
            teacher={teacher}
            user={user}
          />
        </Grid>
      </Grid>
    )
  )
}

TeacherActionButtons.propTypes = {
  refreshFeed: PropTypes.func.isRequired,
  teacher: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.currentUser.user
})

export default connect(mapStateToProps)(TeacherActionButtons)
