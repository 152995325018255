import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'components/shared/table'

export default class DisciplineTable extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
  }

  render() {
    const columns = [
      {
        Header: I18n.jsT('table_headers.discipline.date'),
        accessor: 'date'
      },
      {
        Header: I18n.jsT('table_headers.discipline.discipline_type'),
        accessor: 'type'
      },
      {
        Header: I18n.jsT('table_headers.discipline.incident'),
        accessor: 'incident'
      },
      {
        Header: I18n.jsT('table_headers.discipline.referrer'),
        accessor: 'referrer'
      },
      {
        Header: I18n.jsT('table_headers.discipline.consequence'),
        accessor: 'consequence'
      },
      {
        Header: I18n.jsT('table_headers.discipline.days'),
        accessor: 'discipline_duration_days'
      },
      {
        Header: I18n.jsT('table_headers.discipline.incident_score'),
        accessor: 'incident_score'
      }
    ]

    const { data, loading } = this.props

    return (
      <div style={styles.container}>
        <Table
          columns={columns}
          data={data}
          loading={loading}
          resizable={false}
          showPagination={true}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    marginBottom: '2em'
  }
}
