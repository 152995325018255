import { destroy, patch, post } from 'utils/ajax'
import { alert } from 'utils/alert'

export const createExternalReferral = params => {
  return post('/api/web/external_referrals', {
    external_referral: params
  }).catch(() => {
    alert('There was an error creating the external referral.', 'danger')
  })
}

export const updateExternalReferral = (id, params) => {
  return patch(`/api/web/external_referrals/${id}`, {
    external_referral: params
  }).catch(_error => {
    alert('There was an error updating the external_referral!', 'danger')
  })
}

export const destroyExternalReferral = id => {
  return destroy(`/api/web/external_referrals/${id}`, {})
    .catch(_ => {
      alert('There was an error deleting the external_referral!', 'danger')
    })
    .then(() => {
      alert('External referral was deleted!', 'warning')
    })
}
