import { get, post, destroy } from 'utils/ajax'
import { alert, Severity } from 'utils/alert'

export const downloadReport = (requirementId, requirementName) => {
  let requirementReportName = null
  if (requirementName.includes('ChildStudy')) {
    requirementReportName = requirementName.replace('ChildStudy', '')
  }
  return post('/api/web/reports', {
    report_type: `child_study_${
      requirementReportName
        ? requirementReportName.toLowerCase()
        : requirementName.toLowerCase()
    }`,
    report_name: `Child Study ${
      requirementReportName ? requirementReportName : requirementName
    }`,
    requirement_id: requirementId,
    requirement_name: requirementName
  })
    .then(_res => {
      alert(
        `${
          requirementReportName ? requirementReportName : requirementName
        } generation processing - check <a href="/exports">Exports page</a> for status.`
      )
    })
    .catch(() => {
      alert(
        `There was an error generating this ${
          requirementReportName ? requirementReportName : requirementName
        }.`,
        Severity.ERROR
      )
    })
}

export const getReports = (params = {}) => {
  return get('/api/web/reports', { ...params }).catch(_err => {
    alert('An error occurred while fetching the reports', Severity.ERROR)
  })
}

export const destroyReport = reportId => {
  return destroy(`/api/web/reports/${reportId}`, {})
    .then(data => {
      alert('Successfully deleted report', Severity.SUCCESS)
      return data
    })
    .catch(_err =>
      alert('An error occurred while deleting the report', Severity.ERROR)
    )
}

export const createReport = params => {
  return post('/api/web/reports', {
    ...params
  }).catch(_err => {
    alert('An error occurred creating the report.', Severity.ERROR)
  })
}

export default {
  downloadReport
}
