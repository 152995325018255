import { destroy, get, patch, post } from 'utils/ajax'
import { alert } from 'utils/alert'

export const getAdminActivities = () => {
  return get('/api/admin/activities').catch(error => {
    alert('An error occurred while fetching activities', 'danger')
    return Promise.reject(error)
  })
}

export const updateAdminActivity = (activityId, activity) => {
  return patch(`/api/admin/activities/${activityId}`, {
    activity: activity
  }).catch(error => {
    alert('An error occurred while updating the activity', 'danger')
    return Promise.reject(error)
  })
}

export const createAdminActivity = name => {
  return post('/api/admin/activities', {
    activity: {
      name: name
    }
  }).catch(error => {
    alert('An error occurred while creating the activity', 'danger')
    return Promise.reject(error)
  })
}

export const destroyAdminActivity = activityId => {
  return destroy(`/api/admin/activities/${activityId}`).catch(error => {
    alert('An error occurred while deleting the activity', 'danger')
    return Promise.reject(error)
  })
}
