import AttendancesApi from './api'
import { types } from './types'

export const fetchDisciplines = (studentId, params) => {
  return dispatch => {
    return AttendancesApi.getDisciplines(studentId, params).then(data => {
      dispatch({
        type: types.SET_DISCIPLINES,
        payload: {
          ...data,
          studentId
        }
      })
    })
  }
}

export const fetchRiskScores = (studentId, params) => {
  return dispatch => {
    return AttendancesApi.getRiskScores(studentId, params).then(data => {
      dispatch({
        type: types.SET_RISK_SCORES,
        payload: {
          ...data,
          studentId
        }
      })
    })
  }
}
