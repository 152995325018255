import { chartColors } from 'utils/chart-colors'

export function colorStudentAttendance(seriesName, year) {
  const parseYear = parseInt(seriesName.split('-')[1])
  if (parseYear == year) {
    return chartColors.blue
  } else {
    return chartColors.grey
  }
}
