import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { getStudent } from 'api/students'
import { Typography } from '@mui/material'
import PropTypes from 'prop-types'

function StudentNameDisplay({ studentId }) {
  const { data: student } = useQuery(
    ['student', studentId],
    async () => {
      if (studentId) {
        const data = await getStudent({ studentId: studentId })
        return data.student
      }
    },
    {
      initialData: null,
      refetchOnWindowFocus: false
    }
  )
  return (
    <Typography variant="h1" component="h2">
      For {student?.name}
    </Typography>
  )
}

StudentNameDisplay.propTypes = {
  studentId: PropTypes.number
}

export default StudentNameDisplay
