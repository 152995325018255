export default function createLineGraph(container, table, yAxis, max) {
  Highcharts.chart(container, {
    credits: {
      enabled: false
    },
    data: {
      table: table
    },
    chart: {
      zoomType: 'x'
    },
    title: {
      text: '',
      style: {
        fontWeight: 'bold'
      }
    },
    yAxis: {
      title: {
        text: yAxis
      },
      max: max
    },
    tooltip: {
      headerFormat:
        '<span style="font-size:16px;">Day {point.x} of School</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0;font-size:14px;">{series.name}: </td>' +
        '<td style="padding:0;font-size:14px;"><b> {point.y:.1f}%</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      crosshairs: true,
      useHTML: true
    }
  })
}
