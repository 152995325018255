import { get } from 'utils/ajax'
import { alert } from 'utils/alert'

const getRequirements = childStudyId => {
  const params = { child_study_id: childStudyId }
  return get('/api/web/requirements', { params }).catch(_err => {
    alert('An error occurred while fetching the child study events', 'danger')
  })
}

export default {
  getRequirements
}
