import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { post } from 'utils/ajax'

const withStudentsTable = Component => {
  return class extends React.Component {
    state = {
      datasetNames: null,
      selectedDatasetTable: null,
      display: 'chart'
    }

    componentDidMount() {
      const params = queryString.parse(window.location.search)
      if (params.view && params.view === 'table') {
        this.setState({ display: 'table' })
      }
    }

    fetchDatasetNames = params => {
      return post('/api/web/student_filters/dataset_names', params).then(
        datasetNames => {
          const selectedDatasetTable =
            this.state.selecteDatasetTable ||
            this.tableDatasetOptions(datasetNames)[0]
          this.setState({ datasetNames, selectedDatasetTable })
        }
      )
    }

    tableDatasetChanged = selectedDatasetTable => {
      this.setState({ selectedDatasetTable })
    }

    tableDatasetOptions = names => {
      const datasetNames = names || this.state.datasetNames
      if (datasetNames) {
        return datasetNames.map((set, i) => ({ label: set.name, value: i }))
      }
    }

    render() {
      const { selectedDatasetTable, display } = this.state
      return (
        <Component
          display={display}
          fetchDatasetNames={this.fetchDatasetNames}
          selectedDatasetTable={selectedDatasetTable}
          tableDatasetOptions={this.tableDatasetOptions}
          tableDatasetChanged={this.tableDatasetChanged}
          displayToggled={display => this.setState({ display })}
          {...this.props}
        />
      )
    }
  }
}

export default withStudentsTable

export const withStudentsTablePropTypes = {
  display: PropTypes.string.isRequired,
  selectedDatasetTable: PropTypes.object,
  tableDatasetOptions: PropTypes.func.isRequired,
  displayToggled: PropTypes.func.isRequired,
  fetchDatasetNames: PropTypes.func.isRequired,
  tableDatasetChanged: PropTypes.func.isRequired
}
