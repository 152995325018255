import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'
import Dialog from 'components/shared/dialog'
import { DatePicker } from '@mui/x-date-pickers'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { createConcernRootCause, updateConcernRootCause } from 'api/root-causes'

function RootCauseDialog({ show, setShow, concern, rootCause }) {
  const queryClient = useQueryClient()
  const [rootCauseId, setRootCauseId] = useState(
    rootCause?.root_cause_id || null
  )

  const [date, setDate] = useState(
    moment(rootCause?.date).toDate() || moment().toDate()
  )
  const [notes, setNotes] = useState(rootCause?.notes || '')

  const handleClose = () => setShow(false)

  const resetForm = () => {
    setRootCauseId(null)
    setDate(moment().toDate())
    setNotes('')
  }

  const newRootCauseMutation = useMutation(
    params => createConcernRootCause(params),
    {
      onSuccess: () => {
        resetForm()
        queryClient.invalidateQueries(['concern', concern.id])
      },
      onSettled: () => {
        handleClose()
      }
    }
  )

  const updateRootCauseMutation = useMutation(
    params => updateConcernRootCause(rootCause.id, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['concern', concern.id])
      },
      onSettled: () => {
        handleClose()
      }
    }
  )

  const handleSubmit = event => {
    event.preventDefault()

    const params = {
      concern_id: concern.id,
      root_cause_id: rootCauseId,
      date,
      notes: notes.trim()
    }
    if (rootCause) {
      updateRootCauseMutation.mutate(params)
    } else {
      newRootCauseMutation.mutate(params)
    }
  }

  return (
    <Dialog
      title={`${rootCause ? 'Edit' : 'New'} Root Cause`}
      open={show}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack>
            <DatePicker
              renderInput={props => <TextField {...props} />}
              disableToolbar
              required
              fullWidth
              value={date}
              inputFormat="MM/DD/YYYY"
              inputVariant="inline"
              margin="normal"
              id="date-event-on"
              onChange={val => setDate(val)}
              label="Date"
            />

            <FormControl fullWidth variant="outlined">
              <InputLabel id="rootCause-label">Root Cause</InputLabel>
              <Select
                labelId="rootCause-label"
                id="rootCause"
                label="Root Cause"
                value={rootCauseId}
                onChange={e => setRootCauseId(e.target.value)}
                required
              >
                {concern.root_causes.map(cause => (
                  <MenuItem key={`option-${cause.id}`} value={cause.id}>
                    {cause.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="rootCause-note"
              label="Note (Optional)"
              value={notes}
              onChange={e => setNotes(e.target.value.replace(/^\s+/, ''))}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              helperText={'How did you learn of or identify the root cause?'}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            variant="contained"
            color="primary"
            type={'submit'}
            disabled={!rootCauseId}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

RootCauseDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  concern: PropTypes.object.isRequired,
  rootCause: PropTypes.object
}

export default RootCauseDialog
