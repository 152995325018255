import React from 'react'
import ConfirmModal from '../shared/confirm-modal/modal'

export default class InitialVisit extends React.Component {
  state = {
    understandFerpaModalOpen: false
  }

  convertToBoolean = stringBool => {
    return stringBool == 'true' ? true : false
  }

  componentDidMount() {
    const ferpaConfirmed = this.convertToBoolean(
      localStorage.getItem('ferpa_confirmed')
    )
    this.setState({ understandFerpaModalOpen: !ferpaConfirmed })
  }

  handleConfirmation = () => {
    localStorage.setItem('ferpa_confirmed', true)
  }

  onHide = () => {
    this.setState({ understandFerpaModalOpen: false })
  }

  render() {
    const { understandFerpaModalOpen } = this.state
    return (
      <div>
        <ConfirmModal
          title="FERPA Confirmation"
          message="FERPA allows school officials access to records when they have a 'legitimate educational interest' in those records, meaning (by board policy) that 'the official needs to review an educational record in order to fulfill his or her professional responsibility.' School officials should only access a student's records if a legitimate educational interest exists with respect to that student, and only as to those portions necessary for the task at hand."
          show={understandFerpaModalOpen}
          confirmButtonText="I Understand"
          onHide={this.onHide}
          onConfirm={this.handleConfirmation}
          showCancelButton={false}
        />
      </div>
    )
  }
}
