import React from 'react'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import ExportsTable from './table'
import { useQueryClient } from '@tanstack/react-query'
import GenerateReportButton from './generate-report-button'
import { Typography, Button, Box, Stack } from '@mui/material'
import RedoIcon from '@mui/icons-material/Redo'

function Exports() {
  const queryClient = useQueryClient()

  return (
    <Page name="Exports" title={'Exports'}>
      <PageContainer>
        <Box
          sx={{
            mb: 6
          }}
        >
          <Typography variant="h1" id="test_header_title">
            Exports
          </Typography>
          <Typography variant="h2" id="test_subheader_title">
            All Reports
          </Typography>
        </Box>

        <Box
          sx={{
            my: 4
          }}
        >
          <Stack direction="row" spacing={2}>
            <GenerateReportButton />

            <Button
              variant="outlined"
              onClick={() => queryClient.invalidateQueries('reports')}
              startIcon={<RedoIcon />}
            >
              Refresh
            </Button>
          </Stack>
        </Box>

        <Box>
          <ExportsTable />
        </Box>
      </PageContainer>
    </Page>
  )
}

Exports.propTypes = {}

export default Exports
