import React from 'react'
import PropTypes from 'prop-types'
import { groupBy } from 'lodash'
import { get } from 'utils/ajax'
import { currentSchoolYear } from 'utils'
import urls from 'utils/urls'
import PaperBox from 'shared/PaperBox'
import PieChartFiveSlices from 'shared/charts/PieChartFiveSlices'
import { Box, Stack, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

const useGradeCountsQuery = ({ schoolId, schoolYear }) => {
  const query = useQuery(
    ['school-metrics-grade-counts', { schoolId, schoolYear }],
    async () => {
      const params = { school_year: currentSchoolYear }
      const data = await get(urls.api.schoolMetrics(schoolId), { params })
      const failingGradeCounts = Object.entries(groupBy(data.school_metric?.failing_grade_counts))
      const blankGradeCounts = Object.entries(groupBy(data.school_metric?.blank_grade_counts))
      return { failingGradeCounts, blankGradeCounts }
    },
    {
      initialData: {
        failingGradeCounts: null,
        blankGradeCounts: null
      }
    }
  )

  return query
}

const calculateCount = (counts, val, operator) => {
  if (counts) {
    if (operator === 'equals') {
      if (counts.find(count => count[0] === val)) {
        return counts.find(count => count[0] === val)[1].length
      }
    } else if (operator === 'greater_than_or_equal_to') {
      return counts
        .filter(o => o[0] >= val)
        .map(o => o[1].length)
        .reduce((a, b) => a + b, 0)
    }
  }
}

function Grades({ school }) {
  const { data: { failingGradeCounts, blankGradeCounts } } = useGradeCountsQuery({ schoolId: school.id, schoolYear: currentSchoolYear })

  const getFailingCount = (val, operator) => calculateCount(failingGradeCounts, val, operator)
  const getBlankCount = (val, operator) => calculateCount(blankGradeCounts, val, operator)

  return (
    <Box>
      <Typography variant="h3">Grades For All Students</Typography>

      <Stack spacing={2}>
        {failingGradeCounts && (
          <PaperBox
            title="Failing Grades"
            helperText={{
              'Source': 'Current Grades in PowerSchool',
              'Update Frequency': 'Nightly',
              'What Is Failing?': 'U or F letter grades'
            }}
          >
            <PieChartFiveSlices
              one={{
                title: '0',
                value: getFailingCount('0', 'equals'),
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Default',
                    failing_multiple: 0,
                    failing_multiple_symbol: 'equals'
                  }
                }
              }}
              two={{
                title: '1',
                value: getFailingCount('1', 'equals'),
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Default',
                    failing_multiple: 1,
                    failing_multiple_symbol: 'equals'
                  }
                }
              }}
              three={{
                title: '2',
                value: getFailingCount('2', 'equals'),
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Default',
                    failing_multiple: 2,
                    failing_multiple_symbol: 'equals'
                  }
                }
              }}
              four={{
                title: '3+',
                value: getFailingCount('3', 'greater_than_or_equal_to'),
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Default',
                    failing_multiple: 3,
                    failing_multiple_symbol: 'greater_than_or_equal_to'
                  }
                }
              }}
            />
          </PaperBox>
        )}

        {blankGradeCounts && (
          <PaperBox
            title="Blank Grades"
            helperText={{
              'Source': 'Current Grades in PowerSchool',
              'Update Frequency': 'Nightly',
              'What Is Blank?':
                "Current grades with a missing or '--' letter grade."
            }}
          >
            <PieChartFiveSlices
              one={{
                title: '0',
                value: getBlankCount('0', 'equals'),
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Default',
                    blank_grades_count: 0,
                    blank_grades_symbol: 'equals'
                  }
                }
              }}
              two={{
                title: '1',
                value: getBlankCount('1', 'equals'),
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Default',
                    blank_grades_count: 1,
                    blank_grades_symbol: 'equals'
                  }
                }
              }}
              three={{
                title: '2',
                value: getBlankCount('2', 'equals'),
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Default',
                    blank_grades_count: 2,
                    blank_grades_symbol: 'equals'
                  }
                }
              }}
              four={{
                title: '3+',
                value: getBlankCount('3', 'greater_than_or_equal_to'),
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Default',
                    blank_grades_count: 3,
                    blank_grades_symbol: 'greater_than_or_equal_to'
                  }
                }
              }}
            />
          </PaperBox>
        )}
      </Stack>
    </Box>
  )
}

Grades.propTypes = {
  school: PropTypes.object.isRequired
}

export default Grades
