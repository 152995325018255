import { createSelector } from 'reselect'
import moment from 'moment'

const timeFrame = (_, props) => props.currentTimeFrame
const missing = (_, props) => (props.status === 'missing' ? true : false)
const graded = (_, props) => (props.status === 'graded' ? true : false)

const assignmentsSelector = ({ assignments }, { studentId }) =>
  assignments.byStudentId[studentId] || []

const filteredAssignmentsSelector = createSelector(
  assignmentsSelector,
  timeFrame,
  missing,
  graded,
  (assignments, days, missing, graded) => {
    if (missing) {
      assignments = assignments.filter(
        assignment => assignment.points === null || assignment.points === 0
      )
    }
    if (graded) {
      assignments = assignments.filter(assignment => assignment.actual !== null)
    }
    assignments = assignments.filter(assignment =>
      moment(assignment.updated_at).isAfter(moment().subtract(days, 'days'))
    )
    return assignments
  }
)

export default {
  assignments: assignmentsSelector,
  filteredAssignments: filteredAssignmentsSelector
}
