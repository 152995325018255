import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../protected-route'

// Container for the tab data in a tab picker
//
// Typical usage:
//   <TabPane path="/my_page">
//     <MySubComponent />
//   </TabPane>
//
// React Router props can also be passed to sub-component, by passing a function as children:
//   <TabPane path="/my_page">
//     {props => (
//       <MySubComponent {...props} />
//     )}
//   </TabPane>
export default function TabPane({
  path,
  children,
  protectedRoute,
  redirectUrl,
  isPermitted,
  ...props
}) {
  return protectedRoute ? (
    <div>
      <ProtectedRoute
        path={path}
        redirectUrl={redirectUrl}
        isPermitted={isPermitted}
        component={children}
        {...props}
      />
    </div>
  ) : (
    <div>
      <Route
        path={path}
        render={routerProps => {
          if (typeof children === 'function') {
            return children(routerProps)
          } else {
            return children
          }
        }}
        {...props}
      />
    </div>
  )
}

TabPane.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  protectedRoute: PropTypes.bool,
  redirectUrl: PropTypes.string,
  isPermitted: PropTypes.bool
}
