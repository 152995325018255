import React from 'react'
import PropTypes from 'prop-types'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Title,
  Subtitle,
  Legend,
  XAxis,
  YAxis,
  BarSeries,
  Tooltip
} from 'react-jsx-highcharts'

const SatBenchmarkChart = ({ data, schoolName }) => {
  if (!data || !data.length) {
    return (
      <div style={{ padding: '5em' }}>
        <h5 />
      </div>
    )
  }

  return (
    <div id="js-sat-bar-chart">
      <HighchartsChart>
        <Chart />
        <Title>Percent passing benchmark</Title>
        <Subtitle>{schoolName}</Subtitle>
        <Legend layout="horizontal" align="center" verticalAlign="bottom" />
        <Tooltip
          headerFormat="<span style='font-size:16px;'>Percent Passing {point.x} Benchmark</span><table>"
          pointFormat={
            '<tr><td style="color:{series.color};padding:0;font-size:14px;">{series.name}:&nbsp;</td>' +
            '<td style="padding:0;font-size:14px;"><b> {point.y}%</b></td></tr>'
          }
          footerFormat="</table>"
          shared={true}
          crosshairs={true}
          useHTML={true}
        />
        <XAxis categories={['Math', 'English', 'Passing Both']}>
          <XAxis.Title>Test Type</XAxis.Title>
        </XAxis>

        <YAxis id="number">
          <YAxis.Title>Percent Passing</YAxis.Title>
        </YAxis>

        {data.map((series, index) => (
          <BarSeries
            id={`series-${index}`}
            name={series.name}
            data={[
              series.data.math_benchmark,
              series.data.english_benchmark,
              series.data.composite_benchmark
            ]}
            key={index}
            marker={{ enabled: false }}
          />
        ))}
      </HighchartsChart>
    </div>
  )
}

SatBenchmarkChart.propTypes = {
  data: PropTypes.array,
  schoolYear: PropTypes.number,
  schoolName: PropTypes.string
}

export default withHighcharts(SatBenchmarkChart, window.Highcharts)
