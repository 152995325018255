import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import NoteForm from './form'

function Note({ parent, queryName }) {
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)

  const determineDisabledStatus = () => {
    if (queryName === 'concern') {
      return parent.closed || parent.is_resolved || parent.is_archived
    } else {
      return (
        parent.closed ||
        parent.is_resolved ||
        parent.concern.closed ||
        parent.concern.is_resolved ||
        parent.concern.is_archived
      )
    }
  }

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={handleShow}
        disabled={determineDisabledStatus()}
        style={{ marginBottom: '10px' }}
      >
        Add Note
      </Button>

      <NoteForm
        handleClose={handleClose}
        parent={parent}
        queryName={queryName}
        show={show}
      />
    </React.Fragment>
  )
}

Note.propTypes = {
  parent: PropTypes.object.isRequired,
  queryName: PropTypes.string.isRequired
}

export default Note
