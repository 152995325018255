import { keyBy } from 'lodash'

export const types = {
  FETCH_SCHOOLS: 'schools/fetch_schools'
}

const initialState = {
  byId: {},
  meta: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SCHOOLS:
      return {
        ...state,
        byId: keyBy(action.payload.data, 'id'),
        meta: action.payload.meta
      }
    default:
      return state
  }
}
