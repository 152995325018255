import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableRow, TableCell, TableHead } from '@mui/material'
import {
  fetchGroupCategories,
  humanizeCategories
} from 'utils/demographic-types'
import { calculatePercentage, titleize } from 'utils'
import { sum } from 'lodash'

function GraduationPlanTable({ data, plans, groupBy }) {
  const categories = fetchGroupCategories(groupBy)

  function returnPercentAndCount(category, risk) {
    if (data[category]) {
      const part = data[category][risk]
      const whole = sum(Object.values(data[category]))
      const percent = parseFloat(calculatePercentage(part, whole, 1))
      return isNaN(percent) ? '-' : `${percent}% (${part})`
    }
  }

  const title = () => {
    if (groupBy === 'ell') {
      return 'ML'
    } else if (groupBy === 'idea') {
      return 'SPED'
    } else {
      return titleize(groupBy)
    }
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{title()}</TableCell>
          <TableCell align="center">Count</TableCell>
          {plans.map(plan => (
            <TableCell align="center" key={plan}>
              {humanizeCategories(plan)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {categories.map(c => (
          <TableRow key={`${c}`}>
            <TableCell>{humanizeCategories(c)}</TableCell>
            <TableCell align="center">
              {data[c] && sum(Object.values(data[c]))}
            </TableCell>
            {plans.map(plan => (
              <TableCell align="center" key={`row-${c}-${plan}`}>
                {returnPercentAndCount(c, plan)}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

GraduationPlanTable.propTypes = {
  data: PropTypes.object,
  plans: PropTypes.array,
  groupBy: PropTypes.string
}

export default GraduationPlanTable
