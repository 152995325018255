import React from 'react'
import PropTypes from 'prop-types'
import { styled, lighten, darken } from '@mui/system'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getConcernTypes } from 'api/interventions'

const makeOptions = data => {
  return data.flatMap(({ name: categoryName, children }) => {
    return children.map(({ id, name }) => ({ id, name, categoryName }))
  })
}

const concernTypeFilterOptions = createFilterOptions({
  stringify: option => `${option.name} ${option.categoryName}`
})

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8)
}))

const GroupItems = styled('ul')({
  padding: 0
})

const defaultValue = (id, options) =>
  (id && options.find(option => option.id === id)) || null

const useConcernTypeOptionsDefaults = {
  options: {}
}
export function useConcernTypeOptions({
  includeConcernTypeCategory = false,
  options
} = useConcernTypeOptionsDefaults) {
  const queryKey = ['concern-type-options']
  const queryFn = async () => {
    const res = await getConcernTypes()

    if (includeConcernTypeCategory) {
      return res.data.concat(makeOptions(res.data))
    } else {
      return makeOptions(res.data)
    }
  }
  const query = useQuery(queryKey, queryFn, {
    initialData: [],
    refetchOnWindowFocus: false,
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}

export function ConcernTypeAutocomplete({
  inputProps = {},
  onChange = () => {},
  onInputChange = () => {},
  value: id,
  inputValue: inputValueProp = '',
  required,
  ...props
}) {
  const [value, setValue] = React.useState(null)
  const [inputValue, setInputValue] = React.useState(inputValueProp)
  const { data: options } = useConcernTypeOptions()

  React.useEffect(() => {
    if (!options.length && (value || inputValue)) {
      setValue(null)
    }
  }, [options])

  return (
    <Autocomplete
      disabled={!options.length}
      value={value || defaultValue(id, options)}
      onChange={(event, newValue) => {
        setValue(newValue)
        onChange(event, newValue)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
        onInputChange(event, newInputValue)
      }}
      options={options.sort(
        (a, b) => -b.categoryName.localeCompare(a.categoryName)
      )}
      groupBy={option => option.categoryName}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          label={'Concern Type'}
          required={required}
          {...inputProps}
          {...params}
        />
      )}
      renderGroup={params => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      filterOptions={concernTypeFilterOptions}
      blurOnSelect={true}
      {...props}
    />
  )
}

ConcernTypeAutocomplete.propTypes = {
  inputProps: PropTypes.shape(TextField.propTypes),
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  value: PropTypes.number,
  inputValue: PropTypes.string,
  required: PropTypes.bool
}

export default ConcernTypeAutocomplete
