import React from 'react'
import { titleize } from 'utils'
import GoalModal from './modal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import LockIcon from '@mui/icons-material/Lock'
import EditIcon from '@mui/icons-material/Edit'
import BuildIcon from '@mui/icons-material/Build'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { Table } from 'components/shared/table'
import { actions as goalActions } from 'redux/modules/goals'

class GoalsTable extends React.Component {
  static propTypes = {
    fetchData: PropTypes.func,
    activeGoal: PropTypes.object,
    setActiveGoal: PropTypes.func,
    editAccess: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    goals: PropTypes.object,
    year: PropTypes.number
  }

  state = {
    openModal: false
  }

  handleModalHide = () => {
    this.setState({ openModal: false })
  }

  handleOpenModal = goal => {
    const { setActiveGoal } = this.props
    setActiveGoal(goal)
    this.setState({
      activeGoal: goal,
      openModal: true
    })
  }

  columns = () => {
    const { editAccess } = this.props
    return [
      {
        id: 'category',
        Header: 'Category',
        sortable: false,
        accessor: obj => obj.category,
        Cell: props => <Box>{titleize(props.value)}</Box>
      },
      {
        id: 'goal_value',
        Header: 'Goal',
        sortable: false,
        accessor: obj => obj.value,
        Cell: props => (
          <Box>{props.value ? titleize(props.value) : 'Not Set'}</Box>
        )
      },
      {
        id: 'goal_type',
        Header: 'Goal Type',
        sortable: false,
        accessor: obj => obj.type,
        Cell: props => (
          <Box>{props.value ? titleize(props.value) : 'Not Set'}</Box>
        )
      },
      {
        id: 'comment',
        Header: 'Comment',
        sortable: false,
        accessor: obj => obj.comment,
        Cell: props => <Box>{props.value ? props.value : 'Not Set'}</Box>
      },

      {
        id: 'actions',
        Header: 'Actions',
        sortable: false,
        accessor: obj => obj,
        Cell: props => {
          const isGoalEditable = props.value.goal_open

          return (
            <Box>
              {editAccess && !isGoalEditable && (
                <IconButton
                  id={`goal-locked-${props.value.goal_id}`}
                  size="small"
                >
                  <Tooltip title="Goal is currently locked" placement="top">
                    <LockIcon />
                  </Tooltip>
                </IconButton>
              )}
              {editAccess && isGoalEditable && (
                <Button
                  size="small"
                  color="secondary"
                  data-toggle="modal"
                  id={`edit-goal-${props.value.goal_id}`}
                  onClick={() => this.handleOpenModal(props.value)}
                >
                  <Tooltip
                    trigger={['hover', 'focus']}
                    placement="top"
                    rootClose
                    overlay={
                      <Tooltip id="tooltip-positioned-bottom">
                        Set/Edit Goal
                      </Tooltip>
                    }
                  >
                    <EditIcon />
                  </Tooltip>
                </Button>
              )}
              &nbsp;
              {props.value.link_to_tool && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  href={props.value.link_to_tool}
                  target="_blank"
                  id={`view-goal-tool-${props.value.goal_id}`}
                  rel="noopener noreferrer"
                >
                  <Tooltip title="View Goal Tool" placement="top">
                    <BuildIcon />
                  </Tooltip>
                </Button>
              )}
              &nbsp;
              {props.value.link_to_video && (
                <Button
                  variant="outlined"
                  size="small"
                  href={props.value.link_to_video}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={`view-goal-tutorial-${props.value.goal_id}`}
                >
                  <Tooltip title="View Tutorial" placement="top">
                    <PlayArrowIcon />
                  </Tooltip>
                </Button>
              )}
              &nbsp;
              {props.value.see_tool && props.value.see_tool}
            </Box>
          )
        }
      }
    ]
  }

  render() {
    const { goals, fetchData, loading, year } = this.props
    const { openModal } = this.state

    return (
      <Box id="goals-table">
        <GoalModal
          show={openModal}
          onHide={() => this.handleModalHide()}
          fetchData={fetchData}
        />
        <Table columns={this.columns()} data={goals[year]} loading={loading} />
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  activeGoal: state.goals.activeGoal,
  goals: state.goals.byYear
})

const mapDispatchToProps = {
  setActiveGoal: goalActions.setActiveGoal
}

export default connect(mapStateToProps, mapDispatchToProps)(GoalsTable)
