import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createNote } from 'api/notes'
import { getStudentNotes } from 'api/students'
import CreateIcon from '@mui/icons-material/Create'
import { titleize } from 'utils'
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  TextField,
  Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import urls from 'utils/urls'
import Dialog from 'components/shared/dialog/dialog'

export default function StudentNotesModal({ student }) {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [notes, setNotes] = useState([])
  const [newNote, setNewNote] = useState('')

  const handleClose = () => {
    setModalOpen(false)
    // setNotes([])
  }

  const handleChange = event => {
    setNewNote(event.target.value)
  }

  const fetchStudentNotes = studentIdFromRow => {
    getStudentNotes(studentIdFromRow).then(res => {
      setNotes(res.data)
    })
  }

  const createNewNote = studentId => {
    createNote(newNote, studentId).then(() => {
      setNewNote('')
      fetchStudentNotes(studentId)
    })
  }

  const fetchDataAndOpenPopover = (_e, studentId) => {
    setModalOpen(true)
    fetchStudentNotes(studentId)
  }

  const noteContent = note => {
    return (
      <React.Fragment key={note.id}>
        <Grid container item spacing={2} mt={2}>
          <Grid item xs={12}>
            <Typography variant="body2" display="inline" mr={1}>
              {titleize(note.author)}
            </Typography>
            <Typography variant="caption" display="inline">
              {note.date}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">{note.comment}</Typography>
          </Grid>
        </Grid>
        <Divider />
      </React.Fragment>
    )
  }

  return (
    <Box>
      <Button onClick={e => fetchDataAndOpenPopover(e, student.id)}>
        <CreateIcon />
      </Button>
      <Dialog
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="studnet-notes-modal-title"
        aria-describedby="student-notes-modal-description"
        fullWidth
        maxWidth="md"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Box
            sx={{
              boxShadow: 3,
              p: 3,
              width: '100%'
            }}
          >
            <Typography variant="h6">Student Notes</Typography>
            <Typography variant="body1">
              <Link to={`${urls.student(student.id)}`} target="_blank">
                {student.first_name} {student.last_name}
              </Link>
            </Typography>
          </Box>
          <Box
            container
            overflowY="auto"
            sx={{
              maxHeight: '300px',
              padding: '20px',
              paddingBottom: '0px',
              width: '100%'
            }}
          >
            <Grid>
              {notes && notes.length != 0 ? (
                notes.map(noteContent)
              ) : (
                <Grid container item>
                  <Typography variant="body1">
                    This student has no notes
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid container p={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    onChange={handleChange}
                    label="Your Note"
                    value={newNote}
                    multiline
                    rows={4}
                    variant="outlined"
                    maxLength={250}
                    helperText="Will be saved to the student's page."
                  />
                </FormControl>
              </Grid>
              <Grid container justifyContent="flex-end" mt={2}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => createNewNote(student.id)}
                  >
                    Add Note
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Dialog>
    </Box>
  )
}

StudentNotesModal.propTypes = {
  student: PropTypes.object.isRequired
}
