import React from 'react'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import { Stack, Typography } from '@mui/material'

export default class Calendar extends React.Component {
  render() {
    return (
      <Page name="School Leader Calendar" title="School Leader Calendar">
        <PageContainer>
          <Stack spacing={2}>
            <Typography variant="h3">School Leader Calendar</Typography>

            <Typography variant="body1">
              This calendar contains important district-level events, deadlines,
              and other milestones school leaders need to know about (e.g. SLIs,
              Title 1 budget deadlines, and the Panorama survey). If you have
              any questions or suggestions, reach out to Erin Vincent (
              <a href="mailto:vinceer@tulsaschools.org">
                vinceer@tulsaschools.org
              </a>
              ) or Darcie Hobbs (
              <a href="mailto:hobbsda@tulsaschools.org">
                hobbsda@tulsaschools.org
              </a>
              ).
            </Typography>

            <iframe
              src="https://airtable.com/embed/shrgkPL5PFwUXxzpC?backgroundColor=cyan&viewControls=on&mode=week"
              frameBorder="0"
              width="100%"
              height="533"
            ></iframe>
          </Stack>
        </PageContainer>
      </Page>
    )
  }
}
