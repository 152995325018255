let districtOrPrincipal = ['district', 'principal']

const checkUserDestroyAccess = (requirement, user, student) => {
  return (
    user.id === requirement.created_by_user_id ||
    districtOrPrincipal.includes(user.role) ||
    user.rights.child_study_team_school_ids.includes(student?.school?.id)
  )
}

const checkUserEditAccess = (requirement, user, student) => {
  return (
    !requirement.closed_at &&
    (user.id === requirement.created_by_user_id ||
      districtOrPrincipal.includes(user.role) ||
      user.rights.child_study_team_school_ids.includes(student?.school?.id))
  )
}

const checkUserFollowUpAccess = (action, requirement, user) => {
  return (
    [requirement.created_by_user_id, action.owner_id].includes(user.id) ||
    districtOrPrincipal.includes(user.role)
  )
}

export default {
  checkUserEditAccess,
  checkUserDestroyAccess,
  checkUserFollowUpAccess
}
