import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Menu, MenuItem, Typography } from '@mui/material'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStickyNote, faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { destroyNote } from 'api/wellness'
import NoteForm from 'components/wellness/note/form'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { theme } from 'components/app/theme'

function TimelineRow({ note, parent, queryName }) {
  const queryClient = useQueryClient()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const [editedNote, setEditedNote] = useState(null)
  const [show, setShow] = useState(false)
  const user = useSelector(state => state.currentUser.user)
  const determineDisabledStatus = () => {
    if (queryName === 'concern') {
      return parent.closed || parent.is_resolved || parent.is_archived
    } else {
      return (
        parent.closed ||
        parent.is_resolved ||
        parent.concern.closed ||
        parent.concern.is_resolved ||
        parent.concern.is_archived
      )
    }
  }

  const isDisabled = determineDisabledStatus()

  const handleClose = () => {
    setEditedNote(null)
    setAnchorEl(null)
  }

  const deleteNoteMutation = useMutation(({ noteId }) => destroyNote(noteId), {
    onSuccess: () => {
      handleClose()
      queryClient.invalidateQueries(queryName, parent.id)
    }
  })

  useEffect(() => {
    if (editedNote) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [editedNote])

  const determineBackgroundColor = () => {
    if (isDisabled) {
      return theme.palette.primary.disabledLight
    } else {
      return theme.palette.primary.contrastText
    }
  }

  const determineColor = () => {
    if (isDisabled) {
      return theme.palette.primary.disabledDark
    } else {
      return theme.palette.primary.main
    }
  }

  return (
    <React.Fragment>
      <TimelineItem style={{ paddingRight: 0 }} key={note.id}>
        <TimelineOppositeContent
          style={{ flex: 0, padding: 0 }}
        ></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
            <FontAwesomeIcon icon={faStickyNote} style={{ color: '#fff7eb' }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{ paddingRight: 0 }}>
          <Typography variant="h4">Note</Typography>
          <Typography style={{ maxWidth: '35em' }}>{note.content}</Typography>
          <Typography>
            {user.id === note.user_id && (
              <React.Fragment>
                <Button
                  id={`menu-${note.id}`}
                  aria-controls={open ? `menu-${note.id}` : false}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : false}
                  onClick={event => setAnchorEl(event.currentTarget)}
                  style={{
                    backgroundColor: determineBackgroundColor(),
                    color: determineColor()
                  }}
                  disabled={isDisabled}
                >
                  <FontAwesomeIcon icon={faEllipsisH} />
                </Button>
                <Menu
                  color="primary"
                  aria-labelledby={`menu-${note.id}`}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null)
                      setEditedNote(note)
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      deleteNoteMutation.mutate({ noteId: note.id })
                    }
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {moment(note.date).format('MM/DD/YYYY')}
            {note.author_name && `by ${note.author_name}`}
          </Typography>
        </TimelineContent>
      </TimelineItem>
      {editedNote && (
        <NoteForm
          handleClose={handleClose}
          parent={parent}
          note={editedNote}
          queryName={queryName}
          show={show}
        />
      )}
    </React.Fragment>
  )
}

TimelineRow.propTypes = {
  note: PropTypes.object.isRequired,
  parent: PropTypes.object.isRequired,
  queryName: PropTypes.string.isRequired
}

export default TimelineRow
