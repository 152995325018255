import { post, destroy } from 'utils/ajax'
import { alert } from 'utils/alert'

export const destroyChildStudyReferralForm = (id, callback) => {
  return destroy(`/api/web/child_study_referral_forms/${id}`, {})
    .catch(_err => {
      alert('There was an error destroying the upload.', 'danger')
    })
    .then(() => callback())
}

export const createChildStudyReferralForm = data => {
  return post('/api/web/child_study_referral_forms', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 30000
  }).catch(() => {
    alert('There was an error uploading the form.', 'danger')
  })
}
