import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'components/shared/table'

export default class SummaryTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    onViewStudentsClicked: PropTypes.func.isRequired
  }

  state = {}

  viewStudents = (e, dataset) => {
    e.preventDefault()
    this.props.onViewStudentsClicked(dataset.original.name)
  }

  render() {
    const { data } = this.props

    let columns = [
      {
        Header: '',
        accessor: 'name',
        Cell: props => <strong>{props.value}</strong>,
        maxWidth: '120px'
      },
      {
        Header: 'Total Students',
        accessor: 'total_students'
      },
      {
        id: 'ytd_attendance_percent',
        Header: 'YTD Attendance',
        accessor: obj =>
          obj.ytd_attendance_percent ? `${obj.ytd_attendance_percent}%` : '--'
      },
      {
        id: 'chronic_percent',
        Header: '% Chronic',
        accessor: obj =>
          obj.chronic_percent ? `${obj.chronic_percent}%` : '--'
      }
    ]

    return (
      <div style={styles.container}>
        <h3>Attendance Data</h3>
        <Table
          columns={columns}
          data={data}
          resizable={false}
          showPagination={false}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    marginBottom: '2em'
  },
  filters: {
    width: '100%',
    display: 'flex',
    marginBottom: '1em'
  },
  filterSelect: {
    minWidth: '150px'
  }
}
