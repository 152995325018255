import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import PageTitle from 'components/shared/page-title'
import FilterContainer from 'components/shared/filter-container'
import { useSelector } from 'react-redux'
import { selectors } from 'modules/schools'
import { useQuery } from '@tanstack/react-query'
import {
  getGradeDistribution,
  getGradeDistributionDetails
} from 'api/grade-distributions'
import GradeChart from './chart'
import GradeTable from './table/index'
import { uniq, isEmpty, groupBy } from 'lodash'
import { currentSemester, currentQuarter } from 'utils'
import { aToFGrades, eToUGrades } from 'utils/grade-scales'
import TermFilter from './filters/terms-select'
import GroupByFilter from './filters/grouping-select'
import MultipleGradeScalesFilter from './filters/multiple-grade-scales'
import SectionsSelect from 'shared/filters/sections-select'

function GradesTab({ sectionId, sectionOptions, sectionChanged, teacherId }) {
  const school = useSelector(selectors.currentSchool)
  const [term, setTerm] = useState(
    school.category === 'elementary' ? currentQuarter : currentSemester
  )
  const [grouping, setGrouping] = useState('')
  const [gradeCount, setGradeCount] = useState(null)
  const [displayGradeScale, setDisplayGradeScale] = useState(aToFGrades)
  const [gradeScale, setGradeScale] = useState('af')
  const [multipleGradeScales, setMultipleGradeScales] = useState(false)
  const defaultGradeScaleArray = gradeScale === 'af' ? aToFGrades : eToUGrades

  const { data: aggData, isSuccess: aggSuccess } = useQuery(
    ['grade_distribution', teacherId],
    () => getGradeDistribution(teacherId)
  )

  const { data: detailData, isSuccess: _detailSuccess } = useQuery(
    ['grade_details', teacherId],
    () => getGradeDistributionDetails(teacherId)
  )

  const transformedAggregated = useMemo(() => {
    if (!aggData) return null
    const filtered = aggData.filter(
      r =>
        (!term || r.termbin_storecode === term) &&
        (!sectionId || r.section_id === sectionId)
    )
    if (gradeScale === 'af') {
      return {
        A: filtered.reduce((sum, rec) => sum + rec.a_count, 0),
        B: filtered.reduce((sum, rec) => sum + rec.b_count, 0),
        C: filtered.reduce((sum, rec) => sum + rec.c_count, 0),
        D: filtered.reduce((sum, rec) => sum + rec.d_count, 0),
        F: filtered.reduce((sum, rec) => sum + rec.f_count, 0)
      }
    } else {
      return {
        E: filtered.reduce((sum, rec) => sum + rec.e_count, 0),
        S: filtered.reduce((sum, rec) => sum + rec.s_count, 0),
        N: filtered.reduce((sum, rec) => sum + rec.n_count, 0),
        U: filtered.reduce((sum, rec) => sum + rec.u_count, 0)
      }
    }
  }, [aggData, term, sectionId, gradeScale])

  const transformedDetail = useMemo(() => {
    if (!detailData || grouping === '') return null
    const filtered = detailData.filter(
      r =>
        (!term || r.term === term) && (!sectionId || r.section_id === sectionId)
    )
    const groupedByGrade = groupBy(filtered, 'letter_grade')
    const numericDetail = {}
    Object.keys(groupedByGrade).forEach(letter => {
      const subGroup = groupBy(groupedByGrade[letter], r => String(r[grouping]))
      numericDetail[letter] = {}
      Object.keys(subGroup).forEach(key => {
        numericDetail[letter][key] = subGroup[key].length
      })
    })
    return numericDetail
  }, [detailData, grouping, term, sectionId])

  const availableGrades = useMemo(() => {
    if (!aggData) return new Set()
    const grades = new Set()
    aggData.forEach(rec => {
      if (
        rec.a_count ||
        rec.b_count ||
        rec.c_count ||
        rec.d_count ||
        rec.f_count
      ) {
        grades.add('A')
        grades.add('B')
        grades.add('C')
        grades.add('D')
        grades.add('F')
      }
      if (rec.e_count || rec.s_count || rec.n_count || rec.u_count) {
        grades.add('E')
        grades.add('S')
        grades.add('N')
        grades.add('U')
      }
    })
    return grades
  }, [aggData])

  useEffect(() => {
    if (availableGrades.size > 0) {
      const hasAF = [...availableGrades].some(g => aToFGrades.includes(g))
      const hasEU = [...availableGrades].some(g => eToUGrades.includes(g))
      if (hasAF && hasEU) {
        setMultipleGradeScales(true)
      } else if (hasAF) {
        setGradeScale('af')
      } else if (hasEU) {
        setGradeScale('eu')
      }
    }
  }, [availableGrades])

  useEffect(() => {
    if (aggData && aggData.length > 0) {
      const availableTerms = uniq(aggData.map(r => r.termbin_storecode)).sort()

      if (availableTerms.length > 0) {
        if (school.category === 'elementary') {
          setTerm(
            availableTerms.includes(currentQuarter)
              ? currentQuarter
              : availableTerms[0]
          )
        } else {
          setTerm(
            availableTerms.includes(currentSemester)
              ? currentSemester
              : availableTerms[0]
          )
        }
      }
    }
  }, [aggData, school.category, teacherId])

  useEffect(() => {
    if (grouping !== '' && transformedDetail) {
      setGradeCount(transformedDetail)
      setDisplayGradeScale(Object.keys(transformedDetail).sort())
    } else if (transformedAggregated) {
      setGradeCount(transformedAggregated)
      setDisplayGradeScale(defaultGradeScaleArray)
    }
  }, [
    grouping,
    transformedDetail,
    transformedAggregated,
    defaultGradeScaleArray
  ])

  const uniqTerms = useMemo(() => {
    if (!aggData) return []
    return uniq(aggData.map(r => r.termbin_storecode)).sort()
  }, [aggData])
  const termOptions = uniqTerms.map(t => ({ label: t, value: t }))
  const isTermsPresent = !isEmpty(termOptions)

  const groupOptions = useMemo(() => {
    if (!detailData) return []
    return uniq(detailData.map(record => record.ethnicity))
      .sort()
      .map(val => ({ label: val, value: val }))
  }, [detailData])

  return (
    <React.Fragment>
      <FilterContainer topBorder={false}>
        {isTermsPresent && (
          <TermFilter term={term} setTerm={setTerm} termOptions={termOptions} />
        )}
        <GroupByFilter
          grouping={grouping}
          setGrouping={setGrouping}
          groupOptions={groupOptions}
        />
        {multipleGradeScales && (
          <MultipleGradeScalesFilter
            gradeScale={gradeScale}
            setGradeScale={setGradeScale}
          />
        )}
        <SectionsSelect
          section={sectionId}
          sectionChanged={sectionChanged}
          sectionOptions={sectionOptions}
        />
      </FilterContainer>
      <PageTitle title={'Grade Distribution'} />
      <React.Fragment>
        {aggSuccess && gradeCount !== null ? (
          <React.Fragment>
            <GradeChart
              grouping={grouping}
              counts={gradeCount}
              gradeScale={displayGradeScale}
            />
            <GradeTable
              grouping={grouping}
              counts={gradeCount}
              gradeScale={displayGradeScale}
            />
          </React.Fragment>
        ) : (
          <div>No grade data available for the selected filters.</div>
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

GradesTab.propTypes = {
  sectionId: PropTypes.number,
  sectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number
    })
  ).isRequired,
  sectionChanged: PropTypes.func.isRequired,
  teacherId: PropTypes.number.isRequired
}

export default GradesTab
