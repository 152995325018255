import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { getGraduationCheckStats } from 'api/grad-tracker'
import { getPostsecondaryEligibilityTiers } from 'api/postsecondary-eligibility-tiers'
import Page from 'components/shared/page'
import PaperBox from 'components/shared/PaperBox'
import PieChartFiveSlices from 'components/shared/charts/PieChartFiveSlices'
import GraduationStatus from './status'
import GraduationPlans from './plan'
import {
  Button,
  Box,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'

export default function GradTracker({ schoolId, school }) {
  const [postsecondaryEligibilityTiers, setPostsecondaryEligibilityTiers] =
    useState(null)
  const [graduationCheckStats, setGraduationCheckStats] = useState(null)
  const [key, setKey] = useState('graduation-checks')
  const history = useHistory()

  const openGraduationCheckTable = () => {
    history.push({
      pathname: '/students',
      search: new URLSearchParams({ view: 'Graduation Tracker' }).toString()
    })
  }

  useEffect(() => {
    if (key == 'graduation-checks') {
      getGraduationCheckStats(schoolId).then(res => {
        setGraduationCheckStats(res.stats)
      })
    } else if (key == 'postsecondary-readiness') {
      getPostsecondaryEligibilityTiers({ school_id: schoolId }).then(res => {
        setPostsecondaryEligibilityTiers(res)
      })
    }
  }, [key])

  return (
    <Page
      name="Grad Tracker"
      title={`Grad Tracker – ${school.name}`}
      id="graduation-page"
    >
      <Stack spacing={2}>
        <Typography variant="h3" id="test_h3_title">
          Graduation Tracker
        </Typography>
        <Box>
          <Button
            variant="contained"
            onClick={() => openGraduationCheckTable()}
          >
            Go to Students
          </Button>
        </Box>

        {/* Share context of group by on grad tracker index */}
        {/* Different PR: Update tab component */}
        <Box>
          <ToggleButtonGroup
            value={key}
            exclusive
            onChange={(e, value) => setKey(value)}
            size="small"
            aria-label="graduation tracker tabs"
          >
            <ToggleButton value="graduation-checks">
              Graduation Checks
            </ToggleButton>
            <ToggleButton value="postsecondary-readiness">
              Postsecondary Readiness
            </ToggleButton>
            <ToggleButton value="graduation-status">
              Graduation Statuses
            </ToggleButton>
            <ToggleButton value="graduation-plans">
              Graduation Plans
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {key === 'graduation-checks' && (
          <Box>
            <Grid
              container
              spacing={4}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item md={6}>
                <PaperBox title="Graduation Checks - Notes">
                  <PieChartFiveSlices
                    one={{
                      title: 'With Notes',
                      value: graduationCheckStats?.with_notes,
                      options: {
                        pathname: '/students',
                        query_params: {
                          school_id: schoolId,
                          view: 'Graduation Tracker',
                          has_notes: 'true'
                        }
                      }
                    }}
                    two={{
                      title: 'Without Notes',
                      value: graduationCheckStats?.without_notes,
                      options: {
                        pathname: '/students',
                        query_params: {
                          school_id: schoolId,
                          view: 'Graduation Tracker',
                          has_notes: 'false'
                        }
                      }
                    }}
                  />
                </PaperBox>
              </Grid>
              <Grid item md={6}>
                <PaperBox title="Graduation Checks - Emails">
                  <PieChartFiveSlices
                    one={{
                      title: 'Emailed',
                      value: graduationCheckStats?.emailed,
                      options: {
                        pathname: '/students',
                        query_params: {
                          school_id: schoolId,
                          view: 'Graduation Tracker',
                          was_emailed: 'true'
                        }
                      }
                    }}
                    two={{
                      title: 'Not Emailed',
                      value: graduationCheckStats?.not_emailed,
                      options: {
                        pathname: '/students',
                        query_params: {
                          school_id: schoolId,
                          view: 'Graduation Tracker',
                          was_emailed: 'false'
                        }
                      }
                    }}
                  />
                </PaperBox>
              </Grid>
            </Grid>
          </Box>
        )}
        {key === 'postsecondary-readiness' && (
          <Box>
            <Grid
              container
              spacing={4}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item md={6}>
                {postsecondaryEligibilityTiers && (
                  <PaperBox title="Advanced Placement">
                    <PieChartFiveSlices
                      one={{
                        title: 'Enrolled',
                        value: postsecondaryEligibilityTiers.advanced_placement,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            advanced_placement: 'enrolled',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                      two={{
                        title: 'Qualified (PSAT)',
                        value:
                          postsecondaryEligibilityTiers
                            .ap_potential_based_on_psat?.true,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            advanced_placement: 'qualified',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                      three={{
                        title: 'Not Qualified (PSAT)',
                        value:
                          postsecondaryEligibilityTiers
                            .ap_potential_based_on_psat?.false,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            advanced_placement: 'not_qualified',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                    />
                  </PaperBox>
                )}
              </Grid>
              <Grid item md={6}>
                {postsecondaryEligibilityTiers && (
                  <PaperBox title="College">
                    <PieChartFiveSlices
                      one={{
                        title: 'Enrolled',
                        value: postsecondaryEligibilityTiers.college_level,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            college: 'enrolled',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                      two={{
                        title: 'Qualified (MAP)',
                        value:
                          postsecondaryEligibilityTiers.college_ready_map?.true,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            college: 'qualified',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                      three={{
                        title: 'Not Qualified (MAP)',
                        value:
                          postsecondaryEligibilityTiers.college_ready_map
                            ?.false,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            college: 'not_qualified',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                    />
                  </PaperBox>
                )}
              </Grid>
              <Grid item md={6}>
                {postsecondaryEligibilityTiers && (
                  <PaperBox title="International Baccalaureate Program">
                    <PieChartFiveSlices
                      one={{
                        title: 'Enrolled',
                        value:
                          postsecondaryEligibilityTiers
                            .international_baccalaureate_program?.true,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            ib: 'enrolled',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                      two={{
                        title: 'Not Enrolled',
                        value:
                          postsecondaryEligibilityTiers
                            .international_baccalaureate_program?.false,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            ib: 'not_enrolled',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                    />
                  </PaperBox>
                )}
              </Grid>
              <Grid item md={6}>
                {postsecondaryEligibilityTiers && (
                  <PaperBox title="Assessment Requirements">
                    <PieChartFiveSlices
                      one={{
                        title: 'Met',
                        value:
                          postsecondaryEligibilityTiers
                            .assessment_requirements_post_sec?.true,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            assessment_requirements: 'met',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                      two={{
                        title: 'Not Met',
                        value:
                          postsecondaryEligibilityTiers
                            .assessment_requirements_post_sec?.false,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            assessment_requirements: 'not_met',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                    />
                  </PaperBox>
                )}
              </Grid>
              <Grid item md={6}>
                {postsecondaryEligibilityTiers && (
                  <PaperBox title="Stepmojo">
                    <PieChartFiveSlices
                      one={{
                        title: 'Enrolled',
                        value:
                          postsecondaryEligibilityTiers.stepmojo_student?.true,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            stepmojo: 'enrolled',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                      two={{
                        title: 'Not Enrolled',
                        value:
                          postsecondaryEligibilityTiers.stepmojo_student?.false,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            stepmojo: 'not_enrolled',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                    />
                  </PaperBox>
                )}
              </Grid>
              <Grid item md={6}>
                {postsecondaryEligibilityTiers && (
                  <PaperBox title="Career Tech">
                    <PieChartFiveSlices
                      one={{
                        title: 'Enrolled',
                        value:
                          postsecondaryEligibilityTiers.career_tech_student
                            ?.true,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            career_tech: 'enrolled',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                      two={{
                        title: 'Not Enrolled',
                        value:
                          postsecondaryEligibilityTiers.career_tech_student
                            ?.false,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: schoolId,
                            career_tech: 'not_enrolled',
                            view: 'Postsecondary Readiness'
                          }
                        }
                      }}
                    />
                  </PaperBox>
                )}
              </Grid>
            </Grid>
          </Box>
        )}

        {key === 'graduation-status' && (
          <Box>
            <GraduationStatus schoolId={schoolId} />
          </Box>
        )}

        {key === 'graduation-plans' && (
          <Box>
            <GraduationPlans schoolId={schoolId} />
          </Box>
        )}
      </Stack>
    </Page>
  )
}

GradTracker.propTypes = {
  schoolId: PropTypes.number.isRequired,
  school: PropTypes.object.isRequired
}
