import { getBaseUrl, get } from 'utils/ajax'
import { alert } from 'utils/alert'

const getDisciplines = (studentId, params = {}) => {
  return get(`${getBaseUrl()}/students/${studentId}/disciplines`, {
    params
  }).catch(_err => {
    alert('There was an error loading student data.', 'danger')
  })
}

const getRiskScores = (studentId, params = {}) => {
  return get(`${getBaseUrl()}/students/${studentId}/risk_scores`, {
    params
  }).catch(_err => {
    alert('There was an error loading student data.', 'danger')
  })
}

export default {
  getDisciplines,
  getRiskScores
}
