import { isEqual } from 'lodash'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Dialog, DialogContent, DialogActions } from 'shared/dialog'

function ConfirmModal({
  cancelButtonText = null,
  confirmButtonText = 'Yes',
  confirmWithText = null,
  title = 'Are you sure?',
  message = 'Are you sure you want to do this?',
  onConfirm,
  onHide,
  show,
  showCancelButton = true
}) {
  const [errors, setErrors] = useState({})
  const [value, setValue] = useState('')
  const hasErrors = React.useMemo(
    () => confirmWithText && !isEqual(confirmWithText, value),
    [confirmWithText, value]
  )

  const handleChange = event => {
    setValue(event.target.value)
    setErrors({})
  }

  const handleClose = event => {
    event.preventDefault()
    onHide()
    setErrors({})
    setValue('')
  }

  const handleSubmit = (event, onConfirm) => {
    event.preventDefault()
    if (hasErrors) {
      setErrors({ confirmText: true })
    } else {
      onConfirm()
      onHide()
      setErrors({})
      setValue('')
    }
  }

  // const hasErrors = _ => {
  //   if (confirmWithText && !isEqual(confirmWithText, value)) {
  //     return true
  //   }
  //   return false
  // }

  return (
    <Dialog open={show} title={title} onClose={onHide} id="confirm-modal">
      <DialogContent>
        <Stack>
          <Typography variant="body1">{message}</Typography>

          {confirmWithText && (
            <TextField
              autoFocus={Boolean(confirmWithText)}
              variant="outlined"
              label={`Type "${confirmWithText}" to confirm`}
              name="confirm-text"
              onChange={handleChange}
              error={!!errors.confirmText}
              helperText={
                errors.confirmText && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <RemoveCircleOutlineIcon sx={{ color: '#d32f2f', mr: 1 }} />
                    Confirmation text must match exactly.
                  </Box>
                )
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-error fieldset': {
                    borderColor: '#d32f2f'
                  }
                }
              }}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          autoFocus={!confirmWithText}
          onClick={e => handleSubmit(e, onConfirm)}
          disabled={hasErrors}
        >
          {confirmButtonText}
        </Button>

        {!!showCancelButton && (
          <Button onClick={handleClose} variant="outlined">
            {cancelButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

ConfirmModal.propTypes = {
  cancelButtonText: PropTypes.string,
  showCancelButton: PropTypes.bool,
  confirmWithText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

export default ConfirmModal
