import React from 'react'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { useSelector } from 'react-redux'
import { destroyImpersonation } from 'api/devise'

function ImpersonationStatus() {
  const user = useSelector(state => state.currentUser.user)

  if (user.true_user) {
    return null
  }

  return (
    <Alert
      severity="info"
      action={
        <Button
          color="inherit"
          size="small"
          id="turn-off-impersonation"
          onClick={() => destroyImpersonation(user.id)}
        >
          Turn Off
        </Button>
      }
    >
      IMPERSONATION MODE: Currently logged is as {user.name} - {user.role}
    </Alert>
  )
}

export default ImpersonationStatus
