import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

function SlipButton({ slipId, studentId, gradeLevel }) {
  // const is_past_third = ['4th', '5th'].includes(gradeLevel)
  const is_k3 = ['k', '1st', '2nd', '3rd'].includes(gradeLevel)
  const grade_range = is_k3 ? 'K-3' : 'Past 3rd'
  const color = slipId ? 'primary' : 'secondary'
  const display = slipId
    ? `View ${grade_range} SLIP`
    : `Create ${grade_range} SLIP`
  const controller = is_k3 ? 'slip_forms' : 'slip_past_third_grade_forms'
  const view_or_edit = slipId
    ? `/${controller}/${slipId}/edit`
    : `/${controller}/new?student_id=${studentId}`
  return (
    <Button
      variant="contained"
      color={color}
      size="small"
      href={`${view_or_edit}`}
      target="_blank"
      sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}
    >
      {display}

      <OpenInNewIcon fontSize={'inherit'} display={'block'} />
    </Button>
  )
}

SlipButton.propTypes = {
  studentId: PropTypes.number,
  slipId: PropTypes.number,
  gradeLevel: PropTypes.string
}

export default SlipButton
