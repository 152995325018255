/* eslint react/prop-types: "off" */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { RemoteTable } from 'components/shared/table'
import Status from 'components/concerns/show/status'
import { connect } from 'react-redux'
import { isNull } from 'lodash'
import {
  Box,
  Grid,
  Divider,
  Link as MuiLink,
  Tooltip,
  Typography
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import LockIcon from '@mui/icons-material/Lock'
import { titleize } from 'utils'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { theme } from '../../app/theme'

export const StatusLegend = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="inherit" fontWeight="bold">
          Open:
        </Typography>
        <Typography variant="inherit">Active and in progress.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="inherit" fontWeight="bold">
          Resolved:
        </Typography>
        <Typography variant="inherit">
          No longer a concern due to successful interventions and supports.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="inherit" fontWeight="bold">
          Closed:
        </Typography>
        <Typography variant="inherit">No longer a concern.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="inherit" fontWeight="bold">
          Archived:
        </Typography>
        <Typography variant="inherit">
          The concern will no longer be visible.
        </Typography>
      </Grid>
    </Grid>
  )
}

function InterventionsTable({
  interventions,
  fetchInterventions,
  loading,
  meta,
  schoolId,
  queryKey
}) {
  const [columns, setColumns] = useState([])

  useEffect(() => {
    const initialColumns = [
      {
        id: 'concern_types.name',
        Header: 'Concern',
        sortable: false,
        accessor: obj => obj,
        minWidth: 200,
        width: 200,
        Cell: ({ original }) => (
          <Box display="flex" alignItems="center">
            <MuiLink
              component={RouterLink}
              to={`/concerns/${original.id}`}
              sx={{
                textDecoration: 'none',
                color: theme.palette.primary.main,
                fontWeight: 'bold'
              }}
            >
              {original.concern_name}
            </MuiLink>
            {original.child_study_id && (
              <Tooltip arrow placement="top" title="Linked to Child Study">
                <Box
                  component="span"
                  sx={{
                    ml: 1
                  }}
                >
                  <FolderOpenIcon />
                </Box>
              </Tooltip>
            )}
            {original.is_requesting_help && (
              <Tooltip
                arrow
                placement="top"
                title="Requesting help from the Leadership Team"
              >
                <Box
                  component="span"
                  sx={{
                    ml: 1
                  }}
                >
                  <PriorityHighIcon />
                </Box>
              </Tooltip>
            )}
            {original.is_high_security && (
              <Tooltip
                arrow
                placement="top"
                title="Only principals, owners, and Leadership Team members can view this concern."
              >
                <Box
                  component="span"
                  sx={{
                    ml: 1
                  }}
                >
                  <LockIcon />
                </Box>
              </Tooltip>
            )}
          </Box>
        )
      },
      // Conditionally include school name if All Schools option is selected
      {
        id: 'students.school_name',
        Header: 'School Name',
        sortable: false,
        include: isNull(schoolId),
        accessor: obj => obj,
        Cell: ({ original }) => {
          return original.school.name
        }
      },
      {
        id: 'students.name',
        Header: 'Name',
        accessor: 'student_name',
        sortable: false,
        minWidth: 200,
        width: 200
      },
      {
        id: 'created_by',
        Header: 'Created By',
        accessor: 'created_by',
        sortable: false,
        minWidth: 200,
        width: 200
      },
      {
        id: 'intervention_count',
        Header: 'Interventions',
        sortable: false,
        accessor: obj => obj,
        Cell: ({ original }) => {
          return original.interventions.length
        }
      },
      {
        id: 'supports_count',
        Header: 'Supports',
        sortable: false,
        accessor: obj => obj,
        Cell: ({ original }) => {
          return original.supports.length
        }
      },
      {
        id: 'external_referrals_count',
        Header: 'External Referrals',
        sortable: false,
        accessor: obj => obj,
        Cell: ({ original }) => {
          return original.external_referrals.length
        }
      },
      {
        id: 'notes_count',
        Header: 'Notes',
        sortable: false,
        accessor: obj => obj,
        Cell: ({ original }) => {
          return original.notes.length
        }
      },
      {
        id: 'documents_count',
        Header: 'Documents',
        sortable: false,
        accessor: obj => obj,
        Cell: ({ original }) => {
          return original.documents.length
        }
      },
      {
        id: 'updated_at',
        Header: 'Last Update',
        accessor: obj => obj,
        Cell: ({ original }) => (
          <Tooltip
            arrow
            placement="top"
            title={moment(original.updated_at).format('MM/DD/YYYY')}
          >
            <Box>
              {moment(original.updated_at, 'YYYY-MM-DDTHH:mm').fromNow()}
            </Box>
          </Tooltip>
        )
      },
      {
        id: 'severity',
        Header: () => (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box component="span" mr={1}>
              Severity
            </Box>
            <Tooltip
              id="Severity (Behavior Only)"
              placement="top"
              arrow
              title={`New or existing behavior concerns are defaulted to a
                      severity of Low. As a behavior worsens or is
                      reaffirmed by other staff at your site, you can
                      elevate the concern's severity to Medium or High so
                      that the concern is surfaced to your school's
                      Leadership or Student Success Teams.`}
            >
              <InfoOutlinedIcon fontSize="xs" />
            </Tooltip>
          </Box>
        ),
        accessor: obj => obj,
        Cell: ({ original }) => {
          // 11 is the id of the parent concern type "Behavior"
          const isBehavior = [
            original.concern_type_id,
            original.parent_concern_type_id
          ].includes(11)

          return <span>{isBehavior && titleize(original.severity)}</span>
        },
        sortable: false
      },
      {
        id: 'status',
        Header: () => {
          return (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box component="span" mr={1}>
                Status
              </Box>
              <Tooltip
                id="Severity (Behavior Only)"
                placement="top"
                arrow
                title={<StatusLegend />}
              >
                <InfoOutlinedIcon fontSize="xs" />
              </Tooltip>
            </Box>
          )
        },
        sortable: false,
        accessor: obj => obj,
        Cell: ({ original }) => (
          <Status
            clickable={false}
            parent={original}
            queryName="concern"
            queryKey={queryKey}
          />
        )
      }
    ]

    const remainingColumns = initialColumns.filter(
      ({ include = true }) => include
    )
    setColumns(remainingColumns)
  }, [schoolId])

  return (
    <Box id="intervention-table">
      <RemoteTable
        columns={columns}
        data={interventions}
        pages={meta?.pages}
        onFetchData={fetchInterventions}
        loading={loading}
      />
    </Box>
  )
}

InterventionsTable.propTypes = {
  interventions: PropTypes.array.isRequired,
  fetchInterventions: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  meta: PropTypes.object,
  schoolId: PropTypes.number,
  queryKey: PropTypes.array
}

const mapStateToProps = state => ({
  schoolId: state.schools.schoolId
})

export default connect(mapStateToProps)(InterventionsTable)
