/* eslint react/prop-types: "off" */
import React from 'react'
import moment from 'moment'
import Status from 'components/concerns/show/status'
import { StatusLegend } from '../../../schools/interventions/interventions-table'
import { titleize } from 'utils'
import { Box, Link, Tooltip, Typography } from '@mui/material'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import LockIcon from '@mui/icons-material/Lock'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { theme } from '../../../app/theme'

export const columns = [
  {
    id: 'concern_name',
    Header: 'Concern',
    sortable: false,
    width: 150,
    accessor: obj => obj,
    Cell: props => (
      <>
        <Box>
          <Link
            id={`show-intervention-${props.original.id}`}
            href={`/student/${props.original.student_id}/concerns/${props.original.id}`}
            sx={{
              textDecoration: 'none',
              color: theme.palette.primary.main,
              fontWeight: 'bold'
            }}
          >
            {props.original.concern_name}
          </Link>
        </Box>
        {props.original.child_study_id && (
          <Tooltip arrow placement="top" title="Linked to Child Study">
            <Box component="span" ml={1}>
              <FolderOpenIcon />
            </Box>
          </Tooltip>
        )}
        {props.original.is_requesting_help && (
          <Box>
            <Tooltip
              id="active-status"
              title="Requesting help from the Leadership Team"
            >
              <PriorityHighIcon />
            </Tooltip>
          </Box>
        )}
        {props.original.is_high_security && (
          <Box>
            <Tooltip
              id="active-status"
              title="Only principals, creators, and Leadership Team members can view this concern."
            >
              <LockIcon color="error" />
            </Tooltip>
          </Box>
        )}
      </>
    )
  },
  {
    id: 'concern_notes',
    Header: 'Concern Details',
    width: 250,
    sortable: false,
    style: { whiteSpace: 'unset', maxHeight: '55px' },
    accessor: obj => obj,
    Cell: ({ original }) => (
      <Tooltip
        id="concern-notes"
        title={original.concern_notes}
        placement="top"
        arrow
      >
        <Typography>{original.concern_notes}</Typography>
      </Tooltip>
    )
  },
  {
    id: 'created_by',
    Header: 'Created By',
    sortable: false,
    width: 200,
    accessor: obj => obj,
    Cell: ({ original }) => (
      <Box>
        {original.created_by}
        {original.created_by_child_study_team && (
          <Box key={original.id}>
            <Tooltip
              id="popover-positioned-bottom"
              title="Concern Created by Child Study Team"
            >
              <LocalLibraryIcon />
            </Tooltip>
          </Box>
        )}
      </Box>
    )
  },
  {
    id: 'intervention_count',
    Header: 'Interventions',
    sortable: false,
    width: 110,
    accessor: obj => obj,
    Cell: ({ original }) => {
      return original.interventions.length
    }
  },
  {
    id: 'support_count',
    Header: 'Supports',
    sortable: false,
    accessor: obj => obj,
    Cell: ({ original }) => {
      return original.supports.length
    }
  },
  {
    id: 'external_referral_count',
    Header: 'External Referrals',
    width: 150,
    sortable: false,
    accessor: obj => obj,
    Cell: ({ original }) => {
      return original.external_referrals.length
    }
  },
  {
    id: 'notes_count',
    Header: 'Notes',
    sortable: false,
    accessor: obj => obj,
    Cell: ({ original }) => {
      return original.notes.length
    }
  },
  {
    id: 'documents_count',
    Header: 'Documents',
    sortable: false,
    accessor: obj => obj,
    Cell: ({ original }) => {
      return original.documents.length
    }
  },
  {
    Header: 'Last Update',
    accessor: 'last_update',
    sortable: false,
    Cell: props => {
      return moment(props.value).format('MM/DD/YYYY')
    }
  },
  {
    id: 'severity',
    Header: () => (
      <Typography>
        Severity
        <Tooltip
          id="Severity (Behavior Only)"
          placement="top"
          arrow
          title={`New or existing behavior concerns are defaulted to a
              severity of Low. As a behavior worsens or is
              reaffirmed by other staff at your site, you can
              elevate the concern's severity to Medium or High so
              that the concern is surfaced to your school's
              Leadership or Student Success Teams.`}
        >
          <InfoOutlinedIcon sx={{ fontSize: 'small', ml: 1 }} />
        </Tooltip>
      </Typography>
    ),
    width: 105,
    accessor: obj => obj,
    Cell: ({ original }) => {
      // 11 is the id of the parent concern type "Behavior"
      const isBehavior = [
        original.concern_type_id,
        original.parent_concern_type_id
      ].includes(11)

      return <span>{isBehavior && titleize(original.severity)}</span>
    }
  },
  {
    id: 'status',
    Header: () => {
      return (
        <Box>
          Status
          <Tooltip
            id="Status Legend"
            title={<StatusLegend />}
            arrow
            placement="top"
          >
            <InfoOutlinedIcon sx={{ fontSize: 'small', ml: 1 }} />
          </Tooltip>
        </Box>
      )
    },
    sortable: false,
    accessor: obj => obj,
    Cell: ({ original }) => (
      <Status clickable={false} parent={original} queryName="concern" />
    )
  }
]
