import React, { useState } from 'react'
import PropTypes from 'prop-types'
import urls from 'utils/urls'
import { Link } from 'react-router-dom'
import DestroyAction from './destroy-action'
import { destroyTeacherNote } from 'api/notes'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'
import MeetingDialog from './../meeting-dialog'
import { destroyTeacherMeeting } from 'api/teacher-meetings'
import TeacherNoteDialog from './../note-dialog'

function TeacherFeedActions({ feedItem, refreshFeed, teacher, user }) {
  const eventType = feedItem.event_type
  const [showNote, setShowNote] = useState(false)
  const [showMeeting, setShowMeeting] = useState(false)
  const isUserCreated = feedItem => feedItem.created_by_user_id === user.id
  const canDestroyNote = feedItem => feedItem.is_note && isUserCreated(feedItem)
  const canEditNote = feedItem => feedItem.is_note && isUserCreated(feedItem)
  const canEditMeeting = feedItem =>
    feedItem.is_meeting && isUserCreated(feedItem)
  const canDestroyMeeting = feedItem =>
    feedItem.is_meeting && isUserCreated(feedItem)

  const destroyNote = noteId => {
    destroyTeacherNote(noteId).then(() => refreshFeed())
  }
  const destroyMeeting = meetingId =>
    destroyTeacherMeeting(meetingId).then(refreshFeed)

  const renderDestroyNoteAction = () =>
    canDestroyNote(feedItem) && (
      <DestroyAction
        feedItem={feedItem}
        destroy={destroyNote}
        objectType="note"
      />
    )

  const renderUpdateNoteAction = () => {
    if (canEditNote(feedItem)) {
      return (
        <React.Fragment>
          <IconButton
            variant={'contained'}
            color={'primary'}
            onClick={() => setShowNote(true)}
            id={`edit-note-${feedItem.id}`}
          >
            <EditIcon />
          </IconButton>
          <TeacherNoteDialog
            note={feedItem.data}
            show={showNote}
            onHide={() => setShowNote(false)}
            refreshFeed={refreshFeed}
            teacherId={teacher.id}
          />
        </React.Fragment>
      )
    }
  }

  const renderUpdateMeetingAction = () => {
    if (canEditMeeting(feedItem)) {
      return (
        <React.Fragment>
          <IconButton
            variant={'contained'}
            color={'primary'}
            onClick={() => setShowMeeting(true)}
            id={`edit-meeting-${feedItem.id}`}
          >
            <EditIcon />
          </IconButton>
          <MeetingDialog
            meeting={feedItem.data}
            refreshFeed={refreshFeed}
            setShowMeeting={setShowMeeting}
            showMeeting={showMeeting}
            teacher={teacher}
            user={user}
          />
        </React.Fragment>
      )
    }
  }

  const renderDestroyMeetingAction = () =>
    canDestroyMeeting(feedItem) && (
      <DestroyAction
        feedItem={feedItem}
        destroy={destroyMeeting}
        objectType="meeting"
      />
    )

  const renderStudentSuspended = () => {
    const student = feedItem.student

    return (
      eventType === 'student_suspended' &&
      student && (
        <React.Fragment>
          <a href={urls.newStudentIntervention(student.id)}>
            Create Concern &raquo;
          </a>
          {!!student.guardian_phone_number && (
            <div>
              <a href={`tel:${student.guardian_phone_number}`}>Call Home</a>:{' '}
              {feedItem.student.guardian_phone_number}
            </div>
          )}
        </React.Fragment>
      )
    )
  }

  const renderStudentChronicallyAbsent = () => {
    return (
      eventType === 'student_chronically_absent' && (
        <a href={urls.student(feedItem.student.id)}>
          View student&nbsp;
          <i className="fa fa-angle-right" />
        </a>
      )
    )
  }

  const renderSectionHighFailureRate = () => {
    return (
      eventType === 'section_high_failure_rate' && (
        <Link to={`${urls.section(teacher.id, feedItem.data_id)}`}>
          View section&nbsp;
          <i className="fa fa-angle-right" />
        </Link>
      )
    )
  }

  const renderIepStatusChanged = () => {
    return (
      eventType === 'iep_status_changed' && (
        <a href={urls.student(feedItem.student.id)}>
          View student&nbsp;
          <i className="fa fa-angle-right" />
        </a>
      )
    )
  }

  const renderNewMapScores = () => {
    const dataId = feedItem.data_id

    return (
      eventType === 'new_map_scores' &&
      !!dataId && (
        <a href={urls.section(teacher.id, dataId)}>
          View section&nbsp;
          <i className="fa fa-angle-right" />
        </a>
      )
    )
  }

  return (
    <React.Fragment>
      {renderUpdateMeetingAction()}
      {renderDestroyMeetingAction()}
      {renderUpdateNoteAction()}
      {renderDestroyNoteAction()}
      {renderStudentSuspended()}
      {renderStudentChronicallyAbsent()}
      {renderSectionHighFailureRate()}
      {renderIepStatusChanged()}
      {renderNewMapScores()}
    </React.Fragment>
  )
}

TeacherFeedActions.propTypes = {
  feedItem: PropTypes.object.isRequired,
  refreshFeed: PropTypes.func.isRequired,
  teacher: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

export default TeacherFeedActions
