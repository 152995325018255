import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { observationTypelookForShape } from './prop-types'
import { Box, Switch } from '@mui/material'

export const LookForCheckBox = ({
  observationTypeLookFor,
  lookForObserved,
  checked = false,
  nullable = false,
  ...props
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start'
    }}
  >
    <FormControlLabel
      sx={theme => ({
        alignItems: 'flex-start',
        marginY: theme.spacing(2),
        color: observationTypeLookFor.look_for_category_color.split(':')[1],
        cursor: lookForObserved ? 'pointer' : 'default'
      })}
      disabled={checked === null}
      control={
        <Checkbox
          className={'look-for-checkbox'}
          checked={Boolean(checked)}
          sx={{
            'marginTop': 0,
            'paddingTop': 0,
            'color':
              observationTypeLookFor.look_for_category_color.split(':')[1],
            '&.Mui-checked': {
              color:
                observationTypeLookFor.look_for_category_color.split(':')[1]
            },
            '& .MuiSvgIcon-root': { fontSize: 28 },
            'cursor': lookForObserved ? 'pointer' : 'default'
          }}
          onChange={event =>
            lookForObserved &&
            lookForObserved(observationTypeLookFor.id, event.target.checked)
          }
        />
      }
      label={observationTypeLookFor.description}
      {...props}
    />
    {nullable && (
      <FormControlLabel
        control={
          <Switch
            className={'look-for-na-switch'}
            size={'small'}
            checked={checked === null}
            onChange={event =>
              lookForObserved &&
              lookForObserved(
                observationTypeLookFor.id,
                event.target.checked ? null : false
              )
            }
          />
        }
        label={'N/A'}
      />
    )}
  </Box>
)

LookForCheckBox.propTypes = {
  observationTypeLookFor: observationTypelookForShape.isRequired,
  lookForObserved: PropTypes.func,
  checked: PropTypes.bool,
  nullable: PropTypes.bool
}

export default LookForCheckBox
