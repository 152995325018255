import React from 'react'
import Page from 'components/shared/page'
import HtmlLoader from 'components/shared/html-loader'
import { fetchDistrictGraphs } from 'utils/district-charts.js'

function Charts() {
  return (
    <Page name="District Overall Metrics" title="District Overall Metrics">
      <HtmlLoader
        url={'/district_metrics'}
        showLoadingIndicator={true}
        onLoad={fetchDistrictGraphs}
      />
    </Page>
  )
}

export default Charts
