import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TopNavbar from './top-navbar'
import Sidebar from './sidebar'
import ConsecutiveSnackbars from './consecutive-snackbars'

// TODO: not sure why this isn’t working correctly.
// eslint-disable-next-line import/no-unresolved
import { useBoundingClientRect } from '@wbe/use-bounding-client-rect'

function PageContainer({ children, noSidebar = false }) {
  const [mobileOpen, setMobileOpen] = useState(false)
  const drawerWidth = noSidebar ? 0 : 225
  const appBarRef = React.useRef()
  const appBarRect = useBoundingClientRect(appBarRef)

  return (
    <Box sx={{ pb: 32 }}>
      <ConsecutiveSnackbars />

      <Box sx={{ flexGrow: 1, p: 0 }}>
        <TopNavbar
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          appBarRef={appBarRef}
        />
      </Box>

      {noSidebar ? null : (
        <Sidebar
          mobileOpen={mobileOpen}
          setMobileOpen={setMobileOpen}
          permanentSx={{
            paddingTop: `${appBarRect?.height || 0}px`
          }}
        />
      )}

      <Stack
        component="main"
        sx={theme => ({
          flexGrow: 1,
          bgcolor: 'background.default',
          p: theme.spacing(2),
          width: { sm: `calc(100% - ${drawerWidth + theme.spacing(4)}px)` },
          ml: { sm: `${drawerWidth}px` },
          marginTop: `${appBarRect?.height || 0}px`
        })}
      >
        {children}
      </Stack>
    </Box>
  )
}

PageContainer.propTypes = {
  bannerImage: PropTypes.string,
  bannerPosition: PropTypes.string,
  isKiosk: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  logoColor: PropTypes.string,
  logoPosition: PropTypes.string,
  noSidebar: PropTypes.bool
}

export default PageContainer
