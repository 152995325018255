export const childStudyFormCategories = [
  {
    label: 'Vision/Hearing Screening',
    value: 'vision_hearing_screening'
  },
  {
    label: 'Classroom Observation (K to 6)',
    value: 'classroom_observation_k_to_6'
  },

  {
    label: 'Classroom Observation (6 to 12)',
    value: 'classroom_observation_6_to_12'
  },
  {
    label: 'Academic Characteristics',
    value: 'academic_characteristics'
  },
  {
    label: 'Organizational Characteristics',
    value: 'organizational_characteristics'
  },
  {
    label: 'Behavioral Characteristics',
    value: 'behavioral_characteristics'
  },

  {
    label: 'Social Characteristics',
    value: 'social_characteristics'
  },
  {
    label: 'Functional Behavior Assessment',
    value: 'functional_behavior_assessment'
  },
  {
    label: 'Behavior Intervention Plan',
    value: 'behavior_intervention_plan'
  },
  {
    label: 'Consent for Release of Confidential Information',
    value: 'consent_for_release_of_confidential_information'
  },
  {
    label: 'Parent Notified',
    value: 'parent_notified'
  },
  {
    label: 'Student Work',
    value: 'student_work'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export const meetingRoles = [
  { label: 'Child Study Team Lead', value: 'child_study_team_lead' },
  { label: 'Counselor', value: 'counselor' },
  { label: 'Sped', value: 'sped' },
  { label: 'Eld', value: 'eld' },
  { label: 'General Ed Teacher', value: 'general_ed_teacher' },
  { label: 'Administrator', value: 'administrator' },
  { label: 'Social Worker', value: 'social_worker' },
  { label: 'Therapist', value: 'therapist' },
  { label: 'Social Services Specialist', value: 'social_services_specialist' },
  { label: 'Other', value: 'other' }
]

export const booleanValues = [
  { label: 'Yes', value: true },
  { label: 'No', value: false }
]

export const morningBellTimes = [
  { label: '7:00 AM', value: '07:00:00' },
  { label: '7:05 AM', value: '07:05:00' },
  { label: '7:10 AM', value: '07:10:00' },
  { label: '7:15 AM', value: '07:15:00' },
  { label: '7:20 AM', value: '07:20:00' },
  { label: '7:25 AM', value: '07:25:00' },
  { label: '7:30 AM', value: '07:30:00' },
  { label: '7:35 AM', value: '07:35:00' },
  { label: '7:40 AM', value: '07:40:00' },
  { label: '7:45 AM', value: '07:45:00' },
  { label: '7:50 AM', value: '07:50:00' },
  { label: '7:55 AM', value: '07:55:00' },
  { label: '8:00 AM', value: '08:00:00' },
  { label: '8:05 AM', value: '08:05:00' },
  { label: '8:10 AM', value: '08:10:00' },
  { label: '8:15 AM', value: '08:15:00' },
  { label: '8:20 AM', value: '08:20:00' },
  { label: '8:25 AM', value: '08:25:00' },
  { label: '8:30 AM', value: '08:30:00' },
  { label: '8:35 AM', value: '08:35:00' },
  { label: '8:40 AM', value: '08:40:00' },
  { label: '8:45 AM', value: '08:45:00' },
  { label: '8:50 AM', value: '08:50:00' },
  { label: '8:55 AM', value: '08:55:00' },
  { label: '9:00 AM', value: '09:00:00' },
  { label: '9:05 AM', value: '09:05:00' },
  { label: '9:10 AM', value: '09:10:00' },
  { label: '9:15 AM', value: '09:15:00' },
  { label: '9:20 AM', value: '09:20:00' },
  { label: '9:25 AM', value: '09:25:00' },
  { label: '9:30 AM', value: '09:30:00' }
]

export const afternoonBellTimes = [
  { label: '2:00 PM', value: '14:00:00' },
  { label: '2:05 PM', value: '14:05:00' },
  { label: '2:10 PM', value: '14:10:00' },
  { label: '2:15 PM', value: '14:15:00' },
  { label: '2:20 PM', value: '14:20:00' },
  { label: '2:25 PM', value: '14:25:00' },
  { label: '2:30 PM', value: '14:30:00' },
  { label: '2:35 PM', value: '14:35:00' },
  { label: '2:40 PM', value: '14:40:00' },
  { label: '2:45 PM', value: '14:45:00' },
  { label: '2:50 PM', value: '14:50:00' },
  { label: '2:55 PM', value: '14:55:00' },
  { label: '3:00 PM', value: '15:00:00' },
  { label: '3:05 PM', value: '15:05:00' },
  { label: '3:10 PM', value: '15:10:00' },
  { label: '3:15 PM', value: '15:15:00' },
  { label: '3:20 PM', value: '15:20:00' },
  { label: '3:25 PM', value: '15:25:00' },
  { label: '3:30 PM', value: '15:30:00' },
  { label: '3:35 PM', value: '15:35:00' },
  { label: '3:40 PM', value: '15:40:00' },
  { label: '3:45 PM', value: '15:45:00' },
  { label: '3:50 PM', value: '15:50:00' },
  { label: '3:55 PM', value: '15:55:00' },
  { label: '4:00 PM', value: '16:00:00' },
  { label: '4:05 PM', value: '16:05:00' },
  { label: '4:10 PM', value: '16:10:00' },
  { label: '4:15 PM', value: '16:15:00' },
  { label: '4:20 PM', value: '16:20:00' },
  { label: '4:25 PM', value: '16:25:00' },
  { label: '4:30 PM', value: '16:30:00' }
]

export const schoolCategoryOptions = [
  { label: 'Early Childhood', value: 'early_childhood' },
  { label: 'Elementary', value: 'elementary' },
  { label: 'Middle', value: 'middle' },
  { label: 'High', value: 'high' }
]

export const schoolBooleanOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Alternative', value: 'is_alternative' },
  { label: 'Charter', value: 'is_charter' },
  { label: 'Has Lottery-Based Admissions', value: 'has_lottery_admissions' },
  { label: 'Has Criteria-Based Admissions', value: 'has_criteria_admissions' },
  { label: 'Magnet', value: 'is_magnet' },
  { label: 'Neighborhood', value: 'is_neighborhood' },
  {
    label: 'Receive 3/6/9 Attendance Email',
    value: 'is_receiving_attendance_email'
  }
]

export const interventionStatusOptions = [
  { label: 'All', value: null },
  {
    label: 'Goal Met',
    value: 'goal_met',
    bsStyle: 'success',
    description: 'Student reached goal'
  },
  {
    label: 'In Progress',
    value: 'in_progress',
    bsStyle: 'primary',
    description: 'Still occuring and follow-up date has not passed'
  },
  {
    label: 'No Progress',
    value: 'no_progress',
    bsStyle: 'warning',
    description: 'Executed but student did not progress towards goal'
  },
  {
    label: 'Not Executed',
    value: 'not_executed',
    bsStyle: 'warning',
    description: 'Intervention did not occur'
  },
  {
    label: 'Past Follow-Up',
    value: 'past_follow_up',
    bsStyle: 'yellow',
    description: 'Follow-up date has passed with no follow-up from owner'
  },
  {
    label: 'Progress Made',
    value: 'progress_made',
    bsStyle: 'success',
    description: 'Student showed some progress toward goal'
  }
]

export const concernStatusOptions = [
  { label: 'All', value: null },
  {
    label: 'In Progress',
    value: 'in_progress'
  },
  {
    label: 'Resolved',
    value: 'resolved'
  },
  {
    label: 'Closed',
    value: 'closed'
  }
]
