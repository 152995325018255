import Alert from '@mui/material/Alert'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from 'components/shared/dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import Stack from '@mui/material/Stack'
import LinearProgress from '@mui/material/LinearProgress'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Popover from '@mui/material/Popover'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { patch, destroy } from '@rails/request.js'
import { Edit } from '@mui/icons-material'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

const documentCategories = [
  'Confidential Crisis',
  'Mental Wellness Referral',
  'Bullying',
  'Seclusion & Restraint',
  'Student Success Plan',
  'Weapon Report',
  'Student Re-Entry Plan',
  'Protected PHI',
  'Child Abuse Reporting',
  'Threat Assessment',
  'Safety Plan',
  'Assault & Battery Against Staff',
  'Application to Project Accept',
  'Initial Line of Inquiry',
  'Attendance Student Success Plan',
  'Other'
]

export function DocumentListItem({
  doc,
  loadedPercentage = 100,
  onEdited = _id => {},
  onDestroyed = _id => {}
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDestroyDialog, setOpenDestroyDialog] = useState(false)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [docFileName, setDocFileName] = useState(doc.filename)
  const [docCategory, setDocCategory] = useState(doc.category)

  const handleOpenPopover = event => {
    if (doc.document_url) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClosePopover = () => setAnchorEl(null)

  const handleDestroyDialogClickOpen = () => {
    setOpenDestroyDialog(true)
  }

  const handleDestroyDialogClose = () => {
    setOpenDestroyDialog(false)
    handleClosePopover()
  }

  const handleDestroyRequest = async () => {
    await destroy(`/documents/${doc.id}`, {
      contentType: 'application/json',
      responseKind: 'json'
    })

    handleDestroyDialogClose()
    onDestroyed(doc.id)
  }

  const handleEditDialogClickOpen = () => {
    setOpenEditDialog(true)
  }

  const handleEditDialogClose = () => {
    setOpenEditDialog(false)
    handleClosePopover()
  }

  const handleEditRequest = async () => {
    const documentParams = {
      document: {
        filename: docFileName,
        category: docCategory
      }
    }
    await patch(`/documents/${doc.id}.json`, {
      body: documentParams
    })

    handleEditDialogClose()
    onEdited(doc.id)
  }

  return (
    <>
      <ListItemButton id={doc.id} onClick={handleOpenPopover}>
        <ListItemAvatar>
          <Avatar>
            <FilePresentIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${doc.category || 'Unknown Category'} - ${doc.filename}`}
          secondary={moment(doc.created_at).format('MM/DD/YYYY hh:mm:ss A')}
        />
      </ListItemButton>

      {loadedPercentage !== 100 && (
        <LinearProgress variant="determinate" value={loadedPercentage} />
      )}

      <Dialog
        open={openDestroyDialog}
        onClose={handleDestroyDialogClose}
        title={`Are you sure you want to delete ${doc.filename}?`}
      >
        <Alert severity="warning">Warning! This action is permanent.</Alert>

        <DialogActions>
          <Button onClick={handleDestroyDialogClose}>Cancel</Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDestroyRequest}
            autoFocus
          >
            Yes, I want to delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openEditDialog}
        onClose={handleEditDialogClose}
        title="Update File"
        sx={{ ml: 2, mr: 2 }}
      >
        <DialogContent>
          <Stack>
            <TextField
              label={'File Name'}
              value={docFileName}
              onChange={e => setDocFileName(e.target.value)}
            />
            <FormControl>
              <InputLabel id="file-category-label">File Category</InputLabel>
              <Select
                id="file-category-select"
                labelId="file-category-label"
                value={docCategory}
                onChange={val => setDocCategory(val.target.value)}
                label="File Category"
              >
                {documentCategories.map((value, index) => (
                  <MenuItem key={index} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleEditDialogClose}>Cancel</Button>
          <Button
            variant="outlined"
            color="info"
            onClick={handleEditRequest}
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
      >
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          <Button
            onClick={handleEditDialogClickOpen}
            color="info"
            startIcon={<Edit />}
          >
            Edit
          </Button>
          <Button
            onClick={handleClosePopover}
            target="_blank"
            href={doc.document_url}
            color="success"
            startIcon={<VisibilityOutlinedIcon />}
          >
            View
          </Button>
          <Button
            onClick={handleDestroyDialogClickOpen}
            color="error"
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </ButtonGroup>
      </Popover>
    </>
  )
}

DocumentListItem.propTypes = {
  doc: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    id: PropTypes.number,
    category: PropTypes.string,
    created_at: PropTypes.string,
    document_url: PropTypes.string
  }).isRequired,
  loadedPercentage: PropTypes.number,
  onEdited: PropTypes.func,
  onDestroyed: PropTypes.func
}

export default DocumentListItem
