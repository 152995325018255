import { actions } from 'redux/modules/admins/users'
import { actions as jobActions } from 'redux/modules/admins/jobs'
import { convertObjectToReactTableFormat } from 'utils/react-table-helpers'
import { useDebounce } from 'hooks/useDebounce'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import AdminUsersTable from './table'
import PageContainer from 'components/shared/admin-page-container'
import PropTypes from 'prop-types'
import UserModal from './user-modal'
import { fetchSchools } from '../../../api/admin/schools'
import {
  Box,
  Button,
  Stack,
  Typography,
  FormControl,
  Select,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  MenuItem
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'

function AdminUsers() {
  const [formType, setFormType] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [role, setRole] = useState('')
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)
  const [sorter, setSorter] = useState({ column: 'name', direction: 'asc' })
  const [status, setStatus] = useState('active')
  const [userType, setUserType] = useState('')
  const dispatch = useDispatch()
  const pages = useSelector(state => state.users.meta.pages)
  const users = useSelector(state => state.users.byId)
  const [schoolId, setSchoolId] = useState('')
  const [schools, setSchools] = useState([])

  const fetchJobRoles = () => dispatch(jobActions.fetchJobRoles())
  const fetchUserTableData = params =>
    dispatch(actions.fetchUserTableData(params))

  const handleFetchUserTableData = attrs => {
    setIsLoading(true)
    const params = {
      search: debouncedSearch,
      role,
      status,
      user_type: userType,
      ...attrs
    }
    fetchUserTableData(params)
      .then(() => {
        if (attrs?.sort && attrs?.sort_direction) {
          setSorter({ columns: attrs.sort, direction: attrs.sort_direction })
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function handleModalHide() {
    setOpenModal(false)
  }

  function openModalForNewExternalUser() {
    setOpenModal(true)
    setFormType('Create')
  }

  function searchChanged(e) {
    setSearch(e.target.value)
  }

  useEffect(() => {
    fetchJobRoles()
    fetchSchools({ active: true, per_page: 1000 }).then(({ data }) =>
      setSchools(data)
    )
  }, [])

  useEffect(() => {
    handleFetchUserTableData({
      role,
      search: debouncedSearch,
      status,
      user_type: userType,
      school_id: schoolId || undefined
    })
  }, [role, debouncedSearch, status, userType, schoolId])

  return (
    <PageContainer>
      <Stack spacing={2}>
        <Box>
          <Typography variant="h1">Users</Typography>
        </Box>

        <Box>
          <Button
            id="js-new-user-link"
            onClick={openModalForNewExternalUser}
            variant="contained"
            startIcon={<AddIcon />}
          >
            New External User
          </Button>
        </Box>

        <Box>
          <FormControl fullWidth>
            <InputLabel id="search-label">Search</InputLabel>
            <OutlinedInput
              id="search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              label="Search"
              value={search}
              onChange={e => {
                searchChanged(e)
              }}
            />
          </FormControl>
        </Box>

        <Box>
          <form>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              id="test-table-filters"
            >
              <FormControl fullWidth>
                <InputLabel id="role-type-label">User Role</InputLabel>
                <Select
                  label="User Role"
                  id="role-type"
                  labelId="role-type-label"
                  value={role}
                  onChange={e => setRole(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="district">District</MenuItem>
                  <MenuItem value="guardian">Guardian</MenuItem>
                  <MenuItem value="partner">Partner</MenuItem>
                  <MenuItem value="principal">Principal</MenuItem>
                  <MenuItem value="support">Support</MenuItem>
                  <MenuItem value="teacher">Teacher</MenuItem>
                  <MenuItem value="unassigned">Unassigned</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="userType-label">User Type</InputLabel>
                <Select
                  label="User Type"
                  id="user-type"
                  labelId="userType-label"
                  value={userType}
                  onChange={e => setUserType(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="external">External</MenuItem>
                  <MenuItem value="internal">Internal</MenuItem>
                  <MenuItem value="overridden">Overridden 📌</MenuItem>
                  <MenuItem value="override_expired">
                    Override expired 📌⏲
                  </MenuItem>
                  <MenuItem value="beta">Beta ⚡️</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="status-type-label">User Status</InputLabel>
                <Select
                  label="User Status"
                  id="status-type"
                  labelId="status-type-label"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="school-label">School</InputLabel>
                <Select
                  label="School"
                  id="school"
                  labelId="school-label"
                  value={schoolId}
                  onChange={e => setSchoolId(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  {schools.map(school => (
                    <MenuItem key={school.id} value={school.id}>
                      {school.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </form>
        </Box>
        <UserModal
          show={openModal}
          formType={formType}
          onHide={() => handleModalHide()}
          fetchData={handleFetchUserTableData}
        />
        <AdminUsersTable
          data={convertObjectToReactTableFormat(
            users,
            sorter.columns,
            sorter.direction
          )}
          totalPages={pages}
          loading={isLoading}
          fetchData={handleFetchUserTableData}
        />
      </Stack>
    </PageContainer>
  )
}

AdminUsers.propTypes = {
  fetchUserTableData: PropTypes.func,
  fetchJobRoles: PropTypes.func,
  users: PropTypes.object,
  pages: PropTypes.number
}

export default AdminUsers
