import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { useStudentConcerns } from 'hooks/useStudentConcerns'
import { Link } from 'react-router-dom'

const Form = ({ children, ...props }) => <form {...props}>{children}</form>
Form.propTypes = {
  children: PropTypes.any
}

export function ChildStudyReferralForm({
  studentId,
  onHide,
  handleCreateChildStudy,
  FormComponent = Form,
  ActionsComponent = Box,
  ContentComponent = Box
}) {
  const [note, setNote] = useState('')
  const [concernSelectOpen, setConcernSelectOpen] = useState(false)
  const [selectedStudentOption, setSelectedStudentOption] = useState(null)
  const [selectedStudentId, setSelectedStudentId] = useState(null)

  const [submitEnabled, setSubmitEnabled] = useState(false)
  const { userId, eligibleStudents } = useSelector(state => ({
    userId: state.currentUser.user.id,
    eligibleStudents: state.childStudies.eligibleChildStudyStudents
  }))

  const { query: studentConcernsQuery } = useStudentConcerns({
    studentId: selectedStudentId
  })
  const { data: studentConcerns } = studentConcernsQuery
  const [concernsValue, setConcernsValue] = useState([])
  const removeConcernValue = value => {
    const concernsValueSet = new Set(concernsValue)
    concernsValueSet.delete(value)
    setConcernsValue([...concernsValueSet])
  }
  const [missingConcerns, setMissingConcerns] = useState(true)
  const [missingTargetedInterventions, setMissingTargetedInterventions] =
    useState(true)
  useEffect(() => {
    if (studentConcerns) {
      setMissingConcerns(!studentConcerns.length)
      const concernsWithTargetedInterventions = studentConcerns.filter(
        ({ id, interventions }) => {
          return interventions.length && concernsValue.includes(id)
        }
      )
      setMissingTargetedInterventions(!concernsWithTargetedInterventions.length)
    } else {
      setMissingConcerns(true)
      setMissingTargetedInterventions(true)
    }
  }, [
    studentConcerns,
    concernsValue,
    setMissingConcerns,
    setMissingTargetedInterventions
  ])

  useEffect(() => {
    setSubmitEnabled(Boolean(selectedStudentId && concernsValue.length))
  }, [selectedStudentId, concernsValue, missingConcerns, setSubmitEnabled])

  useEffect(() => {
    setSelectedStudentId(studentId || selectedStudentOption?.value)
  }, [selectedStudentOption, studentId, setSelectedStudentId])

  const handleSubmit = e => {
    e.preventDefault()
    if (!submitEnabled) {
      // TODO: this is an error.
      return
    }
    handleCreateChildStudy(selectedStudentId, userId, concernsValue, note)
    onHide()
  }

  const eligibleStudentOptions = (eligibleStudents || []).map(
    ({ label, value }) => ({
      name: label,
      label: `${label} (${value})`,
      value
    })
  )

  return (
    <FormComponent onSubmit={e => handleSubmit(e)}>
      <ContentComponent>
        <Stack>
          <Box>
            <p>
              Before you refer a student to the child study team, be sure you
              have completed the following required steps:
            </p>

            <ul>
              <li>Notified parent/guardian of concern(s)</li>
              <li>Documented at least 2 interventions on the dashboard</li>
            </ul>
          </Box>

          {!studentId && (
            <FormControl fullWidth>
              <Autocomplete
                id={'student-select'}
                label={'Student'}
                options={eligibleStudentOptions}
                autoHighlight={true}
                value={selectedStudentOption}
                onChange={(_event, newValue) => {
                  setSelectedStudentOption(newValue)
                }}
                renderInput={params => (
                  <TextField
                    label={'Student'}
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      'data-testid': 'student-select-input'
                    }}
                  />
                )}
                getOptionLabel={option => option.label}
                renderOption={(props, option) => (
                  <Stack
                    direction={'row'}
                    justifyContent={'flex-start'}
                    {...props}
                  >
                    <Box>{option.name}</Box>
                    <Box>
                      <small style={{ color: '#8a8a8a' }}>{option.value}</small>
                    </Box>
                  </Stack>
                )}
              />
            </FormControl>
          )}

          {studentConcerns && (
            <React.Fragment>
              {!studentConcerns.length ? (
                <Alert severity="warning">
                  No concerns have been established for this student. Please
                  visit their{' '}
                  <Link to={`/student/${selectedStudentId}/wellness`}>
                    Supports &amp; Concerns
                  </Link>{' '}
                  page.
                </Alert>
              ) : (
                <React.Fragment>
                  <FormControl fullWidth id={'student-concerns-form-control'}>
                    <InputLabel id={'student-concerns-select'}>
                      Concerns
                    </InputLabel>
                    <Select
                      open={concernSelectOpen}
                      multiple
                      labelId={'student-concerns-select'}
                      label={'Concerns'}
                      value={concernsValue}
                      onOpen={() => setConcernSelectOpen(true)}
                      onClose={() => setConcernSelectOpen(false)}
                      onChange={event => {
                        setConcernsValue(event.target.value)
                        setConcernSelectOpen(false)
                      }}
                      renderValue={selected => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {selected.map(value => {
                            const studentConcern = studentConcerns.find(
                              ({ id }) => id == value
                            )
                            const label = studentConcern?.concern_name || value
                            const onMouseDown = event => {
                              // https://stackoverflow.com/a/60209711/1596013
                              event.stopPropagation()
                              event.preventDefault()
                            }
                            const onDelete = event => {
                              event.stopPropagation()
                              removeConcernValue(value)
                            }
                            return (
                              <Chip
                                key={value}
                                label={label}
                                onMouseDown={onMouseDown}
                                onDelete={onDelete}
                              />
                            )
                          })}
                        </Box>
                      )}
                    >
                      {(studentConcerns || []).map(({ id, concern_name }) => (
                        <MenuItem key={id} value={id}>
                          {concern_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {Boolean(concernsValue.length) &&
                    missingTargetedInterventions && (
                      <Alert severity="warning">
                        Please note that one or more of the concerns you’ve
                        selected are missing on-going targeted interventions and
                        are unlikely able to receive support from academic
                        psychomtrists until they are recorded. Please speak with
                        your school leader for details. Create targeted
                        interventions for:{' '}
                        {concernsValue.map((concernId, i) => {
                          const studentConcern = studentConcerns.find(
                            ({ id }) => id == concernId
                          )
                          return (
                            <React.Fragment key={concernId}>
                              {i ? ', ' : ''}
                              <Link to={`/concerns/${concernId}`}>
                                {studentConcern.concern_name}
                              </Link>
                            </React.Fragment>
                          )
                        })}
                        .
                      </Alert>
                    )}

                  <FormControl fullWidth>
                    <TextField
                      id={'note'}
                      name={'note'}
                      multiline
                      label={'Notes'}
                      rows={4}
                      value={note}
                      onChange={val => setNote(val.target.value)}
                    />
                  </FormControl>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Stack>
      </ContentComponent>
      <ActionsComponent>
        <Button
          variant={'contained'}
          color={'primary'}
          type={'submit'}
          disabled={!submitEnabled}
        >
          Refer to Child Study Team
        </Button>
      </ActionsComponent>
    </FormComponent>
  )
}

ChildStudyReferralForm.propTypes = {
  studentId: PropTypes.number,
  concernOptions: PropTypes.object,
  onHide: PropTypes.func,
  handleCreateChildStudy: PropTypes.func,
  FormComponent: PropTypes.any,
  ActionsComponent: PropTypes.any,
  ContentComponent: PropTypes.any
}

export default ChildStudyReferralForm
