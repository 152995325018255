import { get, post } from 'utils/ajax'
import { alert } from 'utils/alert'

const createReport = params => {
  return post('/api/web/reports', {
    ...params
  }).catch(_err => {
    alert('An error occurred creating the report.', 'danger')
  })
}

const getReports = (params = {}) => {
  return get('/api/web/reports', { params }).catch(_err => {
    alert('An error occurred while fetching the reports', 'danger')
  })
}

export default {
  getReports,
  createReport
}
