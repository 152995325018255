import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  TextField
} from '@mui/material'
import Dialog from 'components/shared/dialog'
import { DatePicker } from '@mui/x-date-pickers'
import moment from 'moment'
import { createNote, updateNote } from 'api/wellness'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { titleize } from 'utils'

function NoteForm({ handleClose, parent, note, queryName, show }) {
  const queryClient = useQueryClient()

  const [date, setDate] = useState(
    moment(note?.date).toDate() || moment().toDate()
  )
  const [notes, setNotes] = useState(note?.content || '')

  const resetForm = () => {
    setDate(moment().toDate())
    setNotes('')
  }

  const newNoteMutation = useMutation(params => createNote(params), {
    onSuccess: () => {
      resetForm()
      queryClient.invalidateQueries([queryName, parent.id])
      handleClose()
    }
  })

  const updateNoteMutation = useMutation(
    params => updateNote(note.id, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryName, parent.id])
        handleClose()
      }
    }
  )

  const handleSubmit = event => {
    event.preventDefault()

    const params = {
      data_type: titleize(queryName),
      data_id: parent.id,
      content: notes.trim(),
      date: date
    }

    if (note) {
      updateNoteMutation.mutate(params)
    } else {
      newNoteMutation.mutate(params)
    }
  }

  return (
    <Dialog
      title={`${note ? 'Edit' : 'New'} Note`}
      open={show}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack>
            <DatePicker
              renderInput={props => <TextField {...props} />}
              disableToolbar
              required
              fullWidth
              inputFormat="MM/DD/YYYY"
              inputVariant="inline"
              margin="normal"
              id="date-event-on"
              label="Date"
              value={date}
              onChange={val => setDate(val)}
              autoOk={true}
            />

            <TextField
              id="notes"
              label="Notes"
              multiline
              fullWidth
              variant="outlined"
              rows={5}
              value={notes}
              onChange={val => setNotes(val.target.value.replace(/^\s+/, ''))}
              required
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            type={'submit'}
            color="primary"
            variant="contained"
            disabled={!notes}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

NoteForm.propTypes = {
  handleClose: PropTypes.func.isRequired,
  parent: PropTypes.object.isRequired,
  note: PropTypes.object,
  queryName: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired
}

export default NoteForm
