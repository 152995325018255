import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { Box, Button, Stack } from '@mui/material'
import { AbsoluteSpinner } from 'components/shared/spinner'
import ReactTable from 'react-table'
import { isNull } from 'lodash'

const StudentsTable = ({
  columns,
  data,
  fetchData,
  loading,
  totalPages,
  totalResults
}) => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [sort, setSort] = useState('last_name')
  const [sortDirection, setSortDirection] = useState('asc')

  const fetchWithTableParams = tableParams => {
    fetchData(tableParams)
  }

  const onPageChange = newPage => {
    fetchWithTableParams({
      page: newPage + 1,
      per_page: pageSize,
      sort: sort,
      sort_direction: sortDirection
    })
    setPage(newPage)
  }

  const onPageSizeChange = (newPageSize, _newPage) => {
    fetchWithTableParams({
      page: 0,
      per_page: newPageSize,
      sort: sort,
      sort_direction: sortDirection
    })
    setPage(0)
    setPageSize(newPageSize)
  }

  const onSortedChange = (newSorted, _column, _shiftKey) => {
    const newSortedColName = newSorted[0].id
    const newSortedColDirection = newSorted[0].desc ? 'desc' : 'asc'

    fetchWithTableParams({
      page: 0,
      per_page: pageSize,
      sort: newSortedColName,
      sort_direction: newSortedColDirection
    })

    setPage(0)
    setSort(newSortedColName)
    setSortDirection(newSortedColDirection)
  }

  return (
    <Box id="students-table">
      {loading && <AbsoluteSpinner size={50} />}

      {!loading && (
        <>
          {!isNull(totalResults) && (
            <Typography variant="overline" component="div">
              {totalResults > 0
                ? `${totalResults} records`
                : 'No Records Found'}
            </Typography>
          )}

          <Box sx={{ position: 'relative', width: '100%' }}>
            <ReactTable
              manual
              className="-striped"
              columns={columns}
              data={data}
              minRows={1}
              noDataText={I18n.jsT('labels.no_data_text')}
              previousText={I18n.jsT('buttons.previous')}
              nextText={I18n.jsT('buttons.next')}
              loadingText={I18n.jsT('text.loading')}
              pageText={I18n.jsT('labels.page')}
              ofText={I18n.jsT('labels.of')}
              pages={totalPages}
              page={page}
              onPageChange={onPageChange}
              pageSize={pageSize}
              onPageSizeChange={onPageSizeChange}
              onSortedChange={onSortedChange}
            />
          </Box>
        </>
      )}
    </Box>
  )
}

StudentsTable.propTypes = {
  data: PropTypes.array,
  totalPages: PropTypes.number,
  fetchData: PropTypes.func,
  selectedStudentIds: PropTypes.array,
  columns: PropTypes.array.isRequired, // array of fully defined columns
  getTrProps: PropTypes.func,
  totalResults: PropTypes.number,
  loading: PropTypes.bool
}

export default StudentsTable
