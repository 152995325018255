import React from 'react'
import PropTypes from 'prop-types'
import { createGoal } from 'api/admin/goals'
import { Button, Box, Stack, TextField, Typography } from '@mui/material'
import { titleize, currentSchoolYear } from 'utils'
import Dialog from 'shared/dialog'
import { fetchSchools } from 'api/admin/schools'
import SimpleAutocomplete from 'shared/simple-autocomplete'
import { alert, Severity } from 'utils/alert'

export default class NewGoalModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    goalTypes: PropTypes.array,
    goalCategories: PropTypes.array
  }

  years = [
    {
      value: currentSchoolYear + 1,
      label: `${currentSchoolYear}-${currentSchoolYear + 1}`
    },
    {
      value: currentSchoolYear,
      label: `${currentSchoolYear - 1}-${currentSchoolYear}`
    },
    {
      value: currentSchoolYear - 1,
      label: `${currentSchoolYear - 2}-${currentSchoolYear - 1}`
    }
  ]

  initialState = {
    year: null,
    category: '',
    goalType: '',
    school: null,
    principal: null,
    goalValue: '',
    comment: '',
    schools: [],
    principals: []
  }

  state = this.initialState

  componentDidMount() {
    this.handleFetchSchools()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.show && this.props.show) {
      this.resetModal()
    }
  }

  handleCreateGoal = event => {
    event.preventDefault()
    const params = {
      user_id: this.state.principal?.value,
      school_id: this.state.school?.value,
      category: this.state.category?.value,
      year: this.state.year?.value,
      value: this.state.goalValue,
      goal_type: this.state.goalType?.value,
      comment: this.state.comment
    }

    createGoal(params)
      .then(response => {
        alert('Goal successfully created', Severity.SUCCESS)
        this.props.fetchData()
        this.props.onHide()
      })
      .catch(error => {
        console.error('Error after submission:', error)
        alert('There was an error creating the goal.', Severity.ERROR)
      })
  }

  handleFetchSchools = () => {
    const params = { active: true, per_page: 1000 }
    fetchSchools(params)
      .then(res => {
        if (res && res.data) {
          const schools = res.data.map(school => ({
            label: titleize(school.name),
            value: school.id,
            principals: school.goal_principals.map(([name, id]) => ({
              label: titleize(name),
              value: id
            }))
          }))
          schools.sort((a, b) => a.label.localeCompare(b.label))
          this.setState({ schools })
        }
      })
      .catch(() => {
        alert('Failed to fetch schools', Severity.error)
      })
  }

  handleChange = (identifier, newValue) => {
    if (identifier === 'school') {
      const selectedSchool = this.state.schools.find(
        s => s.value === newValue?.value
      )
      this.setState({
        school: newValue,
        principal: null,
        principals: selectedSchool ? selectedSchool.principals : []
      })
    } else {
      this.setState({ [identifier]: newValue })
    }
  }

  resetModal = () => {
    this.setState({
      ...this.initialState,
      schools: this.state.schools,
      principals: []
    })
  }

  render() {
    const { onHide, show, goalTypes, goalCategories } = this.props
    const {
      year,
      category,
      goalType,
      schools,
      school,
      principal,
      principals,
      goalValue,
      comment
    } = this.state

    return (
      <Dialog open={show} onClose={onHide} title="Create Goal">
        <Box
          sx={{
            p: 4
          }}
        >
          <form onSubmit={this.handleCreateGoal}>
            <Stack spacing={2}>
              <Typography variant="h5">Select Goal Details</Typography>

              <SimpleAutocomplete
                label="School"
                options={schools || []}
                value={school}
                onChange={val => this.handleChange('school', val)}
                id="school-select"
                isClearable
                placeholder="Select a school..."
              />

              <SimpleAutocomplete
                label="Principal"
                options={principals || []}
                value={principal}
                onChange={val => this.handleChange('principal', val)}
                id="principal-select"
                isDisabled={!school}
                isClearable
                placeholder="Select a principal..."
              />

              <SimpleAutocomplete
                label="Year"
                options={this.years}
                value={year}
                onChange={val => this.handleChange('year', val)}
                id="year-select"
                isSearchable={false}
              />

              <SimpleAutocomplete
                label="Category"
                options={goalCategories}
                value={category}
                onChange={val => this.handleChange('category', val)}
                id="category-select"
              />

              <SimpleAutocomplete
                label="Type"
                options={goalTypes}
                value={goalType}
                onChange={val => this.handleChange('goalType', val)}
                id="type-select"
              />

              <TextField
                type="number"
                step="0.1"
                min="0.0"
                max="100"
                id="goal-value"
                value={goalValue}
                onChange={val => this.setState({ goalValue: val.target.value })}
                label="Value"
                placeholder="Enter value as a decimal between 0.0 and 100.0"
                fullWidth
              />

              <TextField
                id="comment"
                value={comment || ''}
                onChange={val => this.setState({ comment: val.target.value })}
                label="Comment"
                placeholder="Enter comments"
                multiline
                rows={4}
                fullWidth
              />

              <Button variant="contained" type="submit">
                Create Goal
              </Button>
            </Stack>
          </form>
        </Box>
      </Dialog>
    )
  }
}
