import { groupBy } from 'lodash'

export const types = {
  FETCH_SECTIONS: 'sections/fetch_sections',
  SET_SECTIONS_LIST: 'sections/update_sections_list'
}

const initialState = {
  bySchoolId: {},
  selectedSections: [],
  meta: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SECTIONS:
      return {
        ...state,
        bySchoolId: groupBy(action.payload.data, 'school_id'),
        meta: action.payload.meta
      }
    case types.SET_SECTIONS_LIST:
      return {
        ...state,
        selectedSections: action.payload
      }
    default:
      return state
  }
}
