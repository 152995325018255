import React from 'react'
import PropTypes from 'prop-types'
import { round, isNull } from 'lodash'
import { Typography, Box } from '@mui/material'
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  RemoveRounded
} from '@mui/icons-material'
import { theme } from '../../app/theme'

const CompareArrow = ({ value, inverse = false, percentage = true }) => {
  const calculateColor = React.useMemo(() => {
    if (isNull(value) || value === 0) {
      return 'text.secondary'
    }

    if (inverse) {
      return value < 0 ? theme.palette.success.main : theme.palette.error.main
    } else {
      return value > 0 ? theme.palette.success.main : theme.palette.error.main
    }
  }, [value, inverse])

  const calculateDirectionIcon = React.useMemo(() => {
    const iconProps = { sx: { fontSize: 16 } }

    if (isNull(value)) {
      return null
    } else if (value === 0) {
      return <RemoveRounded {...iconProps} />
    } else if (value > 0) {
      return <KeyboardArrowUp {...iconProps} />
    } else {
      return <KeyboardArrowDown {...iconProps} />
    }
  }, [value])

  const displayValue = React.useMemo(() => {
    if (isNull(value)) {
      return '--'
    }
    return `${round(value, 2)}${percentage ? '%' : ''}`
  }, [value, percentage])
  return (
    <Box display="flex" alignItems="center">
      <Box color={calculateColor} display="flex" alignItems="center" mr={0.5}>
        {calculateDirectionIcon}
      </Box>
      <Typography variant="body2" color={calculateColor}>
        {displayValue}
      </Typography>
    </Box>
  )
}

CompareArrow.propTypes = {
  value: PropTypes.number,
  inverse: PropTypes.bool,
  percentage: PropTypes.bool
}

export default CompareArrow
