import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow,
  TextField,
  styled,
  Stack,
  Typography,
  Box,
  Divider,
  DialogActions,
  Button,
  DialogContent
} from '@mui/material'
import {
  createObservationNote,
  updateObservationNote,
  deleteObservationNote
} from 'api/tulsa-way/observation-notes'
import moment from 'moment'
import { isNull } from 'lodash'
import { Cancel, Check, Delete, Edit } from '@mui/icons-material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Dialog } from '../../../components/shared/dialog'

export const TableCell = styled(MuiTableCell)(({ theme }) => ({
  verticalAlign: 'top',
  borderBottomColor: theme.palette.grey[200]
}))

function ConfirmDialog({
  children,
  content = null,
  onConfirm = () => { },
  onCancel = () => { },
  ...props
}) {
  const [open, setOpen] = React.useState(false)

  const handleConfirm = () => {
    setOpen(false)
    onConfirm()
  }

  const handleCancel = () => {
    setOpen(false)
    onCancel()
  }

  const openConfirmDialog = () => {
    setOpen(true)
  }

  return (
    <>
      {children({ openConfirmDialog })}
      <Dialog open={open} {...props}>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button variant={'contained'} onClick={handleConfirm} color={'error'}>
            Confirm
          </Button>
          <Button variant={'outlined'} onClick={handleCancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ConfirmDialog.propTypes = {
  children: PropTypes.func.isRequired,
  content: PropTypes.any,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

function RowTeacherTextField({
  teacherInputRef,
  editedNote,
  setEditedNote,
  onKeyUp
}) {
  return (
    <TextField
      inputRef={element => (teacherInputRef.current = element)}
      fullWidth
      size="small"
      id="edit-teacher-comment"
      label={'Teacher'}
      value={editedNote.teacher_comment}
      placeholder={
        'Example: Teacher instructed students to get into groups of five.'
      }
      onChange={event =>
        setEditedNote(state => ({
          ...state,
          teacher_comment: event.target.value
        }))
      }
      onKeyUp={onKeyUp}
    />
  )
}

RowTeacherTextField.propTypes = {
  teacherInputRef: PropTypes.shape({
    current: PropTypes.element
  }).isRequired,
  editedNote: PropTypes.shape({
    teacher_comment: PropTypes.string
  }).isRequired,
  setEditedNote: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired
}

function RowStudentsTextField({ editedNote, setEditedNote, onKeyUp }) {
  return (
    <TextField
      disabled={!editedNote.teacher_comment}
      fullWidth
      size="small"
      id="edit-student-comment"
      label={'Student(s)'}
      value={editedNote.student_comment}
      placeholder={'Example: Only 5 of 20 students followed instructions.'}
      onChange={event =>
        setEditedNote(state => ({
          ...state,
          student_comment: event.target.value
        }))
      }
      onKeyUp={onKeyUp}
    />
  )
}

RowStudentsTextField.propTypes = {
  editedNote: PropTypes.shape({
    teacher_comment: PropTypes.string,
    student_comment: PropTypes.string
  }).isRequired,
  setEditedNote: PropTypes.func.isRequired,
  onKeyUp: PropTypes.func.isRequired
}

function RowActions({
  note,
  editedNote,
  handleSubmit,
  handleCancel,
  handleEdit,
  handleDelete
}) {
  return (
    <>
      {editedNote ? (
        <>
          <IconButton
            title={'Create'}
            onClick={handleSubmit}
            disabled={
              !editedNote.teacher_comment || !editedNote.student_comment
            }
            size={'small'}
          >
            <Check fontSize="inherit" />
          </IconButton>

          <IconButton title={'Cancel'} onClick={handleCancel} size={'small'}>
            <Cancel fontSize="inherit" />
          </IconButton>
        </>
      ) : (
        <>
          <IconButton
            title={'Edit'}
            disabled={!isNull(editedNote)}
            onClick={handleEdit}
            size={'small'}
          >
            <Edit fontSize="inherit" />
          </IconButton>

          <ConfirmDialog
            title={'Delete Note'}
            onConfirm={() => handleDelete()}
            content={
              <Box>
                <Typography variant={'overline'}>Teacher</Typography>
                <Box>{note.teacher_comment}</Box>
                <Typography variant={'overline'}>Student(s)</Typography>
                <Box>{note.student_comment}</Box>
              </Box>
            }
          >
            {({ openConfirmDialog }) => (
              <IconButton
                title={'Delete'}
                disabled={!isNull(editedNote)}
                onClick={openConfirmDialog}
                size={'small'}
              >
                <Delete fontSize="inherit" />
              </IconButton>
            )}
          </ConfirmDialog>
        </>
      )}
    </>
  )
}

RowActions.propTypes = {
  note: PropTypes.shape({
    teacher_comment: PropTypes.string,
    student_comment: PropTypes.string
  }).isRequired,
  editedNote: PropTypes.shape({
    teacher_comment: PropTypes.string,
    student_comment: PropTypes.string
  }),
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func
}

function Row({
  note,
  onSave = () => { },
  onDelete = () => { },
  onCancel = () => { },
  viewOnly,
  mobile = false
}) {
  const [editedNote, setEditedNote] = useState(null)
  const teacherInputRef = useRef(null)

  const handleEdit = () => {
    setEditedNote(note)
  }

  const handleCancel = () => {
    setEditedNote(null)
    onCancel()
  }

  const handleSubmit = () => {
    if (!editedNote.teacher_comment || !editedNote.student_comment) {
      return
    }

    if (
      note.teacher_comment !== editedNote.teacher_comment ||
      note.student_comment !== editedNote.student_comment
    ) {
      onSave(editedNote)
      setEditedNote(null)
    } else {
      handleCancel()
    }
  }

  const handleDelete = () => {
    onDelete(note.id)
    setEditedNote(null)
  }

  const focus = () => {
    teacherInputRef.current.focus()
  }

  const onKeyUp = ({ key }) => {
    switch (key) {
      case 'Enter':
        handleSubmit()
        break
      case 'Escape':
        handleCancel()
        break
    }
  }

  React.useEffect(() => {
    if (editedNote?.id) {
      focus()
    }
  }, [editedNote?.id])

  if (mobile) {
    return (
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <Typography variant={'caption'}>
            <RowActions
              {...{
                note,
                editedNote,
                handleSubmit,
                handleCancel,
                handleEdit,
                handleDelete
              }}
            />{' '}
            {moment(note.created_at).format('h:mm A')}
          </Typography>
        </Box>
        <Typography variant={'overline'}>Teacher</Typography>
        <Box>
          {!editedNote ? (
            note.teacher_comment
          ) : (
            <RowTeacherTextField
              {...{ teacherInputRef, editedNote, setEditedNote, onKeyUp }}
            />
          )}
        </Box>
        <Typography variant={'overline'}>Student(s)</Typography>
        <Box>
          {!editedNote ? (
            note.student_comment
          ) : (
            <RowStudentsTextField {...{ editedNote, setEditedNote, onKeyUp }} />
          )}
        </Box>
      </Box>
    )
  }

  return (
    <TableRow key={note.id}>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {moment(note.created_at).format('h:mm A')}
      </TableCell>

      <TableCell>
        {!editedNote ? (
          note.teacher_comment
        ) : (
          <RowTeacherTextField
            {...{ teacherInputRef, editedNote, setEditedNote, onKeyUp }}
          />
        )}
      </TableCell>

      <TableCell>
        {!editedNote ? (
          note.student_comment
        ) : (
          <RowStudentsTextField {...{ editedNote, setEditedNote, onKeyUp }} />
        )}
      </TableCell>

      {!viewOnly && (
        <TableCell sx={{ display: 'flex', flexDirection: 'row' }}>
          <RowActions
            {...{
              note,
              editedNote,
              handleSubmit,
              handleCancel,
              handleEdit,
              handleDelete
            }}
          />
        </TableCell>
      )}
    </TableRow>
  )
}

Row.propTypes = {
  note: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func,
  viewOnly: PropTypes.bool,
  mobile: PropTypes.bool
}

function TeacherTextField({
  teacherInputRef,
  studentInputRef,
  teacherComment,
  setTeacherComment
}) {
  return (
    <TextField
      inputRef={element => (teacherInputRef.current = element)}
      fullWidth
      size="small"
      id="teacher-comment"
      label={'Teacher'}
      value={teacherComment}
      onChange={event => setTeacherComment(event.target.value)}
      onKeyDown={e => {
        if (teacherComment && e.key === 'Enter') {
          studentInputRef.current.focus()
        }
      }}
    />
  )
}

TeacherTextField.propTypes = {
  teacherInputRef: PropTypes.shape({
    current: PropTypes.element
  }).isRequired,
  studentInputRef: PropTypes.shape({
    current: PropTypes.element
  }).isRequired,
  teacherComment: PropTypes.string,
  setTeacherComment: PropTypes.func.isRequired
}

function StudentsTextField({
  teacherComment,
  studentInputRef,
  studentComment,
  setStudentComment,
  handleSubmit
}) {
  return (
    <TextField
      inputRef={element => (studentInputRef.current = element)}
      disabled={!teacherComment}
      fullWidth
      size="small"
      id="student-comment"
      label={'Student(s)'}
      value={studentComment}
      onChange={event => setStudentComment(event.target.value)}
      onKeyDown={e => {
        if (studentComment && e.key === 'Enter') {
          handleSubmit()
        }
      }}
    />
  )
}

StudentsTextField.propTypes = {
  teacherComment: PropTypes.string,
  studentInputRef: PropTypes.shape({
    current: PropTypes.element
  }).isRequired,
  studentComment: PropTypes.string,
  setStudentComment: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
}

export function LowInferenceNotes({
  observation,
  invalidate = () => { },
  viewOnly = false
}) {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('sm'))

  const [teacherComment, setTeacherComment] = useState('')
  const [studentComment, setStudentComment] = useState('')
  const teacherInputRef = useRef(null)
  const studentInputRef = useRef(null)

  const handleSubmit = () => {
    createObservationNote({
      tulsa_way_observation_id: observation.id,
      teacher_comment: teacherComment.trim(),
      student_comment: studentComment.trim()
    }).then(() => {
      setTeacherComment('')
      setStudentComment('')
      invalidate()
      teacherInputRef.current.focus()
    })
  }

  const handleUpdate = editedNote => {
    const params = {
      teacher_comment: editedNote.teacher_comment.trim(),
      student_comment: editedNote.student_comment.trim()
    }
    updateObservationNote(editedNote.id, params).then(() => {
      invalidate()
      teacherInputRef.current.focus()
    })
  }

  const handleDelete = id => {
    deleteObservationNote(id).then(() => {
      invalidate()
      teacherInputRef.current.focus()
    })
  }

  const handleCancel = () => {
    teacherInputRef.current.focus()
  }

  if (mobile) {
    return (
      <Paper elevation={3} sx={{ p: 3 }}>
        <Stack spacing={1} divider={<Divider />}>
          <Typography variant={'h6'}>What did they say?</Typography>
          <TeacherTextField
            {...{
              studentInputRef,
              teacherInputRef,
              teacherComment,
              setTeacherComment
            }}
          />
          <StudentsTextField
            {...{
              teacherComment,
              studentInputRef,
              studentComment,
              setStudentComment,
              handleSubmit
            }}
          />
          {observation.notes.map(note => (
            <Row
              key={note.id}
              note={note}
              onSave={handleUpdate}
              onDelete={handleDelete}
              onCancel={handleCancel}
              viewOnly={viewOnly}
              mobile={true}
            />
          ))}
        </Stack>
      </Paper>
    )
  }

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width={1}>Time</TableCell>
            <TableCell>
              What did the teacher{' '}
              <span style={{ whiteSpace: 'nowrap' }}>say/do?</span>
            </TableCell>
            <TableCell>
              What did the student(s){' '}
              <span style={{ whiteSpace: 'nowrap' }}>say/do?</span>
            </TableCell>
            {!viewOnly && <TableCell width={1}>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {!viewOnly && (
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <TeacherTextField
                  {...{
                    studentInputRef,
                    teacherInputRef,
                    teacherComment,
                    setTeacherComment
                  }}
                />
              </TableCell>
              <TableCell>
                <StudentsTextField
                  {...{
                    teacherComment,
                    studentInputRef,
                    studentComment,
                    setStudentComment,
                    handleSubmit
                  }}
                />
              </TableCell>
              <TableCell>
                <IconButton
                  title={'Create'}
                  onClick={handleSubmit}
                  disabled={!teacherComment || !studentComment}
                >
                  <Check />
                </IconButton>
              </TableCell>
            </TableRow>
          )}
          {observation.notes.map(note => (
            <Row
              key={note.id}
              note={note}
              onSave={handleUpdate}
              onDelete={handleDelete}
              onCancel={handleCancel}
              viewOnly={viewOnly}
            />
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

LowInferenceNotes.propTypes = {
  observation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    notes: PropTypes.arrayOf(PropTypes.any)
  }),
  invalidate: PropTypes.func,
  viewOnly: PropTypes.bool
}
