import React from 'react'
import PropTypes from 'prop-types'
import { currentSchoolYear } from 'utils'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function YearSelect({ year, setYear }) {
  const yearOptions = [
    {
      value: currentSchoolYear,
      label: `${currentSchoolYear - 1} - ${currentSchoolYear}`
    },
    {
      value: currentSchoolYear - 1,
      label: `${currentSchoolYear - 2} - ${currentSchoolYear - 1}`
    },
    {
      value: currentSchoolYear - 2,
      label: `${currentSchoolYear - 3} - ${currentSchoolYear - 2}`
    }
  ]

  return (
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 180 }}>
      <InputLabel id="year-select-label">Year</InputLabel>
      <Select
        labelId="year-select-label"
        id="year-select"
        value={year}
        label="Year"
        onChange={e => setYear(e.target.value)}
      >
        {yearOptions.map(y => (
          <MenuItem key={`year-option-${y.value}`} value={y.value}>
            {y.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

YearSelect.propTypes = {
  year: PropTypes.number,
  setYear: PropTypes.func.isRequired
}

export default YearSelect
