import React from 'react'
import PropTypes from 'prop-types'
import { Box, Tooltip, Stack } from '@mui/material'

export default function UserTypeIcon({ id, text, tip }) {
  return (
    <Stack direction="column" alignItems="flex-start">
      <Tooltip key={id} placement="top" title={tip} arrow>
        <Box
          verticalAlign="middle"
          sx={{
            display: 'inline-block'
          }}
        >
          {text}
        </Box>
      </Tooltip>
    </Stack>
  )
}

UserTypeIcon.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  tip: PropTypes.string.isRequired
}
