import React from 'react'
import { debounce } from 'lodash'
import SystemStatusesTable from './table'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/admin-page-container'
import { fetchSystemStatusTableData } from 'api/admin/system_statuses'
import SystemStatusModal from './modal'
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export default class SystemStatuses extends React.Component {
  state = {
    isLoading: true,
    systemStatuses: [],
    pages: null,
    search: '',
    modalOpen: false
  }

  componentDidMount() {
    this.handleFetchSystemStatusTableData()
  }

  searchChanged = e => {
    const search = e.target.value
    this.setState({ search }, () => {
      this.handleFetchSystemStatusTableData()
    })
  }

  handleFetchSystemStatusTableData = debounce(
    attrs => {
      this.setState({ isLoading: true })

      const params = {
        search: this.state.search,
        ...attrs
      }

      fetchSystemStatusTableData(params)
        .then(res => {
          this.setState({
            systemStatuses: res.data,
            pages: res.meta.pages,
            isLoading: false
          })
          return res.data
        })
        .catch(() => {
          this.setState({ isLoading: false })
        })
    },
    300,
    {
      leading: false,
      trailing: true
    }
  )

  handleModalOpen = () => {
    this.setState({ modalOpen: true })
  }

  onHide = () => {
    this.setState({ modalOpen: false })
  }

  render() {
    const { isLoading, systemStatuses, pages, search, modalOpen } = this.state

    return (
      <Page name="System Statuses" title="System Statuses">
        <PageContainer>
          <Stack spacing={2}>
            <Typography variant="h1">System Statuses</Typography>

            <Box>
              <Button
                variant="contained"
                onClick={() => this.handleModalOpen()}
              >
                Create a System Status
              </Button>
            </Box>

            <Box>
              <FormControl fullWidth>
                <InputLabel htmlFor="search">Search</InputLabel>
                <OutlinedInput
                  id="search"
                  value={search}
                  onChange={this.searchChanged}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </Box>

            <Box>
              <SystemStatusesTable
                data={systemStatuses}
                totalPages={pages}
                loading={isLoading}
                fetchData={this.handleFetchSystemStatusTableData}
              />
            </Box>

            <SystemStatusModal
              show={modalOpen}
              onHide={this.onHide}
              fetchData={this.handleFetchSystemStatusTableData}
            />
          </Stack>
        </PageContainer>
      </Page>
    )
  }
}
