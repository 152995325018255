import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material'

class Form extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    sections: PropTypes.array.isRequired,
    teachers: PropTypes.array.isRequired
  }

  state = {
    selectedTeacherId: '',
    selectedSectionId: ''
  }

  updateSelectedSection = event =>
    this.setState({ selectedSectionId: event.target.value })
  updateSelectedTeacher = event =>
    this.setState({ selectedTeacherId: event.target.value })

  toSectionOptions = sections =>
    sections.map(section => (
      <MenuItem key={section.id} value={section.id}>
        {section.name}
      </MenuItem>
    ))

  toTeacherOptions = teachers =>
    teachers.map(teacher => (
      <MenuItem key={teacher.id} value={teacher.id}>
        {teacher.last_name}, {teacher.first_name}
      </MenuItem>
    ))

  handleSubmit = event => {
    event.preventDefault()

    const { selectedTeacherId, selectedSectionId } = this.state
    this.props.onSubmit(selectedTeacherId, selectedSectionId)
    this.setState({
      selectedTeacherId: '',
      selectedSectionId: ''
    })
  }

  render() {
    const { teachers, sections } = this.props
    const { selectedTeacherId, selectedSectionId } = this.state

    return (
      <form onSubmit={this.handleSubmit}>
        <Stack direction="row" spacing={2}>
          <FormControl fullWidth>
            <InputLabel id="section-label">Select a grade level...</InputLabel>
            <Select
              id="section-select"
              labelId="section-label"
              label="Select a grade level..."
              value={selectedSectionId}
              onChange={this.updateSelectedSection}
              required
            >
              {this.toSectionOptions(sections)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="teacher-label">Select a teacher...</InputLabel>
            <Select
              id="teacher-select"
              labelId="teacher-label"
              label="Select a teacher..."
              value={selectedTeacherId}
              onChange={this.updateSelectedTeacher}
              required
            >
              {this.toTeacherOptions(teachers)}
            </Select>
          </FormControl>
          <Box>
            <Button type="submit" variant="contained" color="success">
              Grant Access
            </Button>
          </Box>
        </Stack>
      </form>
    )
  }
}

export default Form
