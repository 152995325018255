import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import { calculatePercentage, titleize } from 'utils'
import { useTheme } from '@mui/styles'
import DangerCell from './danger-cell'
import GradeRow from './row'
import { fetchGroupCategories } from 'utils/demographic-types'

const displayTotalPercent = (counts, grade) => {
  let part = 0
  let whole = 0

  if (typeof counts[grade] === 'object') {
    part = Object.values(counts[grade] || {}).reduce((sum, val) => sum + val, 0)

    Object.keys(counts).forEach(g => {
      if (counts[g] && typeof counts[g] === 'object') {
        whole += Object.values(counts[g]).reduce((sum, val) => sum + val, 0)
      }
    })
  } else {
    part = counts[grade] || 0
    whole = Object.values(counts).reduce((acc, count) => acc + count, 0)
  }

  const percent = calculatePercentage(part, whole, 0)
  const isDanger = percent >= 20 && ['F', 'U'].includes(grade)
  return <DangerCell percent={percent} isDanger={isDanger} />
}

const sanitizeTitle = title => {
  if (['ell', 'sped'].includes(title)) {
    return title.toUpperCase()
  } else {
    return titleize(title)
  }
}

function GradesTable({ counts, grouping, gradeScale }) {
  const theme = useTheme()
  return (
    <TableContainer component={Paper} sx={{ marginTop: theme.spacing(4) }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">
              <Typography
                variant="body1"
                style={{ fontWeight: theme.typography.fontWeightBold }}
              >
                {grouping === '' ? 'Total' : sanitizeTitle(grouping)}
              </Typography>
            </TableCell>
            {gradeScale.map(c => (
              <TableCell component="th" align="center" key={`column-${c}`}>
                <Typography
                  variant="body1"
                  style={{ fontWeight: theme.typography.fontWeightBold }}
                >
                  {c}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {grouping !== '' &&
            fetchGroupCategories(grouping).map(s => (
              <GradeRow
                key={`${grouping}-row-${s}`}
                title={grouping}
                accessor={grouping}
                value={s}
                gradeScale={gradeScale}
                counts={counts}
                displayValue={s}
              />
            ))}
          <TableRow className="active">
            <TableCell>
              <Typography variant="body1">Total</Typography>
            </TableCell>
            {gradeScale.map(c => (
              <TableCell align="center" key={`column-${c}`}>
                {displayTotalPercent(counts, c)}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

GradesTable.propTypes = {
  counts: PropTypes.object,
  grouping: PropTypes.string.isRequired,
  gradeScale: PropTypes.array.isRequired
}

export default GradesTable
