import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import SortableHeader from './sortable_header'
import { AbsoluteSpinner } from 'components/shared/spinner'

// add the custom header arrows we use on sortable columns
const addSortIconsToHeaders = columns => {
  return [...columns].map(col => {
    if (typeof col.Header === 'string' && col.sortable !== false) {
      col.Header = <SortableHeader title={col.Header} />
    }

    if (col.columns) {
      col.columns = addSortIconsToHeaders(col.columns)
    }

    return col
  })
}

export default function Table({
  striped,
  className,
  columns,
  data,
  minRows,
  loading,
  ...props
}) {
  const sortableColumns = addSortIconsToHeaders(columns)

  return (
    <div style={styles.container}>
      {loading && <AbsoluteSpinner />}
      <div style={{ ...styles.loading, opacity: loading ? 0.6 : 1 }}>
        <ReactTable
          className={`${striped ? '-striped' : ''} ${className}`}
          columns={sortableColumns}
          data={data}
          minRows={minRows}
          noDataText={I18n.jsT('labels.no_data_text')}
          previousText={I18n.jsT('buttons.previous')}
          nextText={I18n.jsT('buttons.next')}
          loadingText={I18n.jsT('text.loading')}
          pageText={I18n.jsT('labels.page')}
          ofText={I18n.jsT('labels.of')}
          {...props}
        />
      </div>
    </div>
  )
}

const styles = {
  container: {
    position: 'relative',
    width: '100%'
  },
  loading: {
    transition: 'all 300ms'
  }
}

Table.defaultProps = {
  minRows: 1,
  striped: true
}

Table.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  minRows: PropTypes.number,
  striped: PropTypes.bool,
  loading: PropTypes.bool
}
