import { find, keyBy, isEmpty } from 'lodash'

export const types = {
  DETERMINE_CURRENT_SCHOOL: 'schools/determine_current_school',
  SCHOOL_SELECTED: 'schools/school_selected',
  FETCH_SCHOOLS_STARTED: 'schools/fetch_schools_started',
  SET_SCHOOLS_LIST: 'schools/set_schools_list',
  SET_SCHOOLS_DETAILS: 'school/set_schools_details',
  SET_ALL_GRADE_LEVELS: 'school/set_all_grade_levels',
  SET_DETAILS_FOR_SCHOOL: 'school/set_details_for_school'
}

const initialState = {
  byId: {},
  schoolId: null
}

const getCurrentSchoolId = schoolsById => {
  if (isEmpty(schoolsById)) return null

  const localSchoolId = parseInt(localStorage.getItem('school_id'))
  const school = find(schoolsById, school => school.id === localSchoolId)
  const schoolId = (school && school.id) || Object.keys(schoolsById)[0]

  return parseInt(schoolId)
}

function addDetailsToSelectedSchool(state, action) {
  const { payload } = action
  const { id, ...details } = payload
  const school = state[id]

  return {
    ...state,
    [id]: {
      ...school,
      ...details
    }
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SCHOOLS_STARTED:
      return { ...state, loading: true }
    case types.SCHOOL_SELECTED:
      localStorage.setItem('school_id', action.payload)

      return {
        ...state,
        schoolId: action.payload
      }
    case types.SET_SCHOOLS_LIST:
      return {
        ...state,
        byId: keyBy(action.payload, 'id')
      }
    case types.DETERMINE_CURRENT_SCHOOL:
      return {
        ...state,
        schoolId: getCurrentSchoolId(state.byId)
      }
    case types.SET_DETAILS_FOR_SCHOOL:
      return {
        ...state,
        byId: addDetailsToSelectedSchool(state.byId, action)
      }
    default:
      return state
  }
}
