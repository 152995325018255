import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'

function MenuItemLink(props) {
  const linkStyle = {
    all: 'inherit',
    margin: '-6px -16px',
    flexGrow: 1,
    ...props.style
  }

  return (
    <MenuItem>
      {props.to ? (
        <Link {...props} style={linkStyle} />
      ) : (
        <a {...props} style={linkStyle} />
      )}
    </MenuItem>
  )
}

MenuItemLink.propTypes = {
  to: PropTypes.oneOf([PropTypes.string, PropTypes.object, PropTypes.func]),
  style: PropTypes.object
}

export default MenuItemLink
