import { getBaseUrl, get } from 'utils/ajax'
import { alert } from 'utils/alert'

const getAttendances = (studentId, params = {}) => {
  return get(`${getBaseUrl()}/students/${studentId}/attendances`, {
    params
  }).catch(_err => {
    alert('There was an error loading student data.', 'danger')
  })
}

export default {
  getAttendances
}
