import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ProtectedRoute from 'shared/protected-route'
import School from './show'
import Goal from './goals'
import { optionsPropTypes, withOptions } from 'utils/contexts/options'
import { routerPropTypes } from 'utils/prop-types'
import { includes } from 'lodash'
import { actions as schoolActions } from 'modules/schools'

const baseUrl = '/schools'

function isPermittedToSchools(user) {
  return !!(
    includes(['district', 'principal', 'support', 'partner'], user.role) ||
    user.rights.is_rsa_coordinator ||
    user.rights.is_wellness_team_member_at_schools.length > 0
  )
}

export function Schools({
  history,
  schoolId,
  school,
  user,
  schools,
  selectSchool
}) {
  React.useEffect(() => {
    const path = history.location.pathname
    const tabMatch = path.match(/^\/schools\/tab\/(.*)/)
    const schoolMatch = path.match(/^\/schools\/[0-9]/)
    const noSchoolMatch = path.match(/^\/schools($|\/[^0-9])/)

    const id = schoolMatch ? path.split('/')[2] : (schoolId || schools[0].id)

    if (tabMatch) {
      const tab = tabMatch[1]
      history.replace(`/schools/${id}/${tab}`)
    } else if (noSchoolMatch && id) {
      const url = path.replace(/^\/schools/, `/schools/${id}`)
      history.replace(url)
    } else if (schoolMatch && id) {
      selectSchool(id)
    }
  }, [])

  return (
    <Switch>
      <Route exact path={baseUrl} component={SchoolIndex} />
      <Route path={`${baseUrl}/tab`} render={() => <h4>Tab</h4>} />
      <Route exact path={`${baseUrl}/:id/goals`} component={Goal} />
      <ProtectedRoute
        path={`${baseUrl}/:id`}
        component={School}
        school={school}
        isPermitted={isPermittedToSchools(user)}
        redirectUrl="/forbidden"
      />
      <Route
        render={() => (
          <div>
            <h4>{"It's a 404"}</h4>
          </div>
        )}
      />
    </Switch>
  )
}
Schools.propTypes = {
  ...routerPropTypes,
  ...optionsPropTypes
}

function SchoolIndex() {
  const schoolId = useSelector(state => state.schools.schoolId)
  const schools = useSelector(state => state.schools.byId)
  const id = schoolId || Object.keys(schools)[0]

  return <Redirect to={`/schools/${id}`} />
}

const mapStateToProps = state => ({
  schools: Object.values(state.schools.byId),
  schoolId: state.schools.schoolId,
  user: state.currentUser.user
})

const mapDispatchToProps = {
  selectSchool: schoolActions.selectSchool
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOptions(Schools))
