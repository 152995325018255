import { getConcerns } from 'api/interventions'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export function useStudentConcerns({ studentId, options }) {
  const queryKey = ['student-concerns', studentId]
  const queryFn = () =>
    getConcerns({ student_id: studentId }).then(res => res.data)
  const query = useQuery(queryKey, queryFn, {
    enabled: Boolean(studentId),
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { query, invalidate }
}
