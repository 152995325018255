import React, { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import GenerateReportModal from './modal'
import Button from '@mui/material/Button'
import AssessmentIcon from '@mui/icons-material/Assessment'

function GenerateReportButton() {
  const [modalOpen, setModalOpen] = useState(false)
  const queryClient = useQueryClient()

  const handleClose = () => {
    queryClient.invalidateQueries(['reports'])
    setModalOpen(false)
  }

  return (
    <React.Fragment>
      <GenerateReportModal show={modalOpen} onHide={() => handleClose()} />
      <Button
        color="secondary"
        variant="contained"
        onClick={() => setModalOpen(true)}
        startIcon={<AssessmentIcon />}
      >
        New Report
      </Button>
    </React.Fragment>
  )
}

GenerateReportButton.propTypes = {}

export default GenerateReportButton
