import React from 'react'
import PropTypes from 'prop-types'
import { titleize } from 'utils'
import AccessChart from './chart'
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import ChartContainer from 'components/shared/chart-container'
import { useStudent } from 'hooks/useStudents'
import Spinner from 'shared/spinner/spinner'
import LinkIcon from '@mui/icons-material/Link'
import PageTitle from 'components/shared/page-title'
function Access({ studentId }) {
  const params = { include_accesses: true }
  const {
    data: data,
    isLoading,
    isSuccess
  } = useStudent({
    id: studentId,
    params
  })

  const categories = [
    'composite',
    'listening',
    'writing',
    'comprehension',
    'speaking',
    'reading',
    'literacy',
    'oral'
  ]

  return (
    <React.Fragment>
      <PageTitle title={'Access Scores'} />
      <ChartContainer>
        {isLoading && <Spinner mt={2} mb={2} centered={true} />}

        {isSuccess && (
          <AccessChart
            accesses={data.student.accesses}
            categories={categories}
            student={data.student}
          />
        )}
      </ChartContainer>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Button
                  target="_blank"
                  variant="contained"
                  href="https://login.ellevationeducation.com/"
                >
                  <LinkIcon />
                  <Typography variant="button">Ellevation</Typography>
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Year</TableCell>
              {categories.map(c => (
                <TableCell key={c}>{titleize(c)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell>
                  <Spinner mt={2} mb={2} centered={true} />
                </TableCell>
              </TableRow>
            )}

            {isSuccess &&
              data.student.accesses.map(access => (
                <TableRow key={access.id}>
                  <TableCell>{access.year}</TableCell>
                  {categories.map(c => (
                    <TableCell key={c}>{access[`${c}_proficiency`]}</TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

Access.propTypes = {
  studentId: PropTypes.number.isRequired
}

export default Access
