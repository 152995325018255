import React from 'react'
import PropTypes from 'prop-types'
import {
  Paper,
  FormGroup,
  Stack,
  Box,
  Typography,
  Switch,
  FormControlLabel
} from '@mui/material'
import { observationTypelookForShape } from './prop-types'
import LookForCheckBox from './look-for-checkbox'

export default function DevelopmentArea({
  name,
  form,
  lookForObserved,
  observationTypeLookFors,
  nullable = false
}) {
  const [na, setNa] = React.useState(false)

  const nonNullValues = observationTypeLookFors
    .map(({ id }) => form.lookForsObserved[id])
    .filter(value => value !== null)

  const clickNa = checked => {
    setNa(checked)
    observationTypeLookFors.forEach(({ id }) => {
      lookForObserved(id, checked ? null : false)
    })
  }

  React.useEffect(() => {
    if (na) {
      nonNullValues.length && setNa(false)
    } else if (observationTypeLookFors.length) {
      nonNullValues.length || setNa(true)
    }
  }, [nonNullValues, na, observationTypeLookFors])

  return (
    <Paper
      key={name}
      elevation={3}
      sx={theme => ({ padding: theme.spacing(3) })}
      className={'development-area'}
    >
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant={'h3'}>{name}</Typography>
          {nullable && (
            <FormControlLabel
              control={
                <Switch
                  size={'small'}
                  checked={na}
                  onChange={event => {
                    clickNa(event.target.checked)
                  }}
                />
              }
              label={'N/A'}
            />
          )}
        </Box>
        <FormGroup className={'development-area-form-group'}>
          {observationTypeLookFors.map((observationTypeLookFor, key) => (
            <LookForCheckBox
              key={key}
              checked={form.lookForsObserved[observationTypeLookFor.id]}
              nullable={observationTypeLookFor.nullable || nullable}
              {...{ observationTypeLookFor, lookForObserved }}
            />
          ))}
        </FormGroup>
      </Stack>
    </Paper>
  )
}

DevelopmentArea.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  lookForObserved: PropTypes.func.isRequired,
  observationTypeLookFors: PropTypes.arrayOf(observationTypelookForShape)
    .isRequired,
  nullable: PropTypes.bool
}
