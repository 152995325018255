import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import { snakeCase } from 'lodash'
import SchoolMetrics from './metrics'
import FailingGrades from './failing-grades'
import PaperBox from 'components/shared/PaperBox'
import { currentSchoolYear, titleize } from 'utils'
import { DatePicker } from '@mui/x-date-pickers'
import {
  Box,
  Button,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Tooltip,
  DialogTitle
} from '@mui/material'
import { BarChart } from '@mui/x-charts/BarChart'
import urls from 'utils/urls'
import { get } from 'utils/ajax'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import SimpleAutocomplete from 'shared/simple-autocomplete'
import { fetchSchoolDetails } from 'api/schools'
import { userPropTypes } from 'modules/current-user'
import { connect } from 'react-redux'
import { createTeamMeeting, getTeamMeetings } from 'api/team-meetings'
import moment from 'moment'

import ElementaryAcademicPlatforms from './academic-platforms/elementary'
import SecondaryAcademicPlatforms from './academic-platforms/secondary'
import { Delete, NoteAlt, People, QuestionMark } from '@mui/icons-material'

const ifNaN = (value, defaultValue = '') =>
  isNaN(value) ? defaultValue : value

const roles = [
  { label: 'Principal', value: 'principal' },
  { label: 'Assistant Principal', value: 'assistant_principal' },
  { label: 'Counselor', value: 'counselor' },
  { label: 'Dean', value: 'dean' },
  { label: 'Attendance Clerk', value: 'attendance_clerk' },
  { label: 'Teacher (Department Lead)', value: 'teacher_department_lead' },
  { label: 'Teacher (Grade Level Lead)', value: 'teacher_grade_level_lead' },
  { label: 'Teacher (Content Chair)', value: 'teacher_content_chair' },
  {
    label: 'Social Worker/Social Services',
    value: 'social_worker_social_services'
  },
  {
    label: 'Nurse',
    value: 'nurse'
  },
  {
    label: 'Health Assistant',
    value: 'health_assistant'
  },
  {
    label: 'Attendance Recovery Coordinator',
    value: 'attendance_recovery_coordinator'
  },
  {
    label: 'Indian Education',
    value: 'indian_education'
  },
  {
    label: 'External Partner',
    value: 'external_partner'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

const params = { school_year: currentSchoolYear }

// combine perfect and not at risk to match yearly attendance risk levels
const combinePerfectAndNotAtRisk = ({ not_at_risk, perfect, ...rest }) => {
  if (not_at_risk && perfect) {
    not_at_risk += perfect
  }

  return { not_at_risk, ...rest }
}

function Overview({ school, user }) {
  const [teacherObservationComparison, setTeacherObservationComparison] =
    useState('two_week')

  const [followUpsAnchorEl, setFollowUpsAnchorEl] = React.useState(null)
  const followUpsOpen = Boolean(followUpsAnchorEl)
  const followUpsId = followUpsOpen
    ? 'follow-ups-helper-text-popover'
    : undefined
  const [successPlansAnchorEl, setSuccessPlansAnchorEl] = React.useState(null)
  const successPlansOpen = Boolean(successPlansAnchorEl)
  const successPlansId = successPlansOpen
    ? 'follow-ups-helper-text-popover'
    : undefined
  const [dialogOpen, setDialogOpen] = useState(false)
  const [date, setDate] = useState(moment()._d)
  const [meetingType, setMeetingType] = useState('')
  const [notes, setNotes] = useState('')
  const [nextSteps, setNextSteps] = useState('')
  const [attendees, setAttendees] = useState([])
  const [seeAllMeetings, setSeeAllMeetings] = useState(false)
  const queryClient = useQueryClient()
  const [role, setRole] = useState('')
  const [userId, setUserId] = useState('')

  const schoolMetricsQuery = useQuery(
    ['school-metrics', school.id, params],
    () => get(urls.api.schoolMetrics(school.id), { params }),
    {
      refetchOnWindowFocus: false
    }
  )
  const { data: schoolMetrics } = schoolMetricsQuery
  const metric = schoolMetrics?.school_metric || null
  const previousDayMetric = schoolMetrics?.previous_day_metric || null
  const previousWeekMetric = schoolMetrics?.previous_week_metric || null
  const previousTwoWeekMetric = schoolMetrics?.previous_two_week_metric || null
  const previousMonthMetric = schoolMetrics?.previous_month_metric || null
  const previousYearMetric = schoolMetrics?.previous_year_metric || null
  const attendanceTiers =
    schoolMetrics?.school_metric?.attendance_tiers?.find(
      t => t.school_year == currentSchoolYear
    ) || null
  const interventionStats =
    schoolMetrics?.school_metric?.six_week_intervention_stats || {}
  const childStudyStats =
    schoolMetrics?.school_metric?.six_week_child_study_stats || {}
  const studentSuccessPlanCounts = combinePerfectAndNotAtRisk(
    schoolMetrics?.school_metric?.student_success_plans_by_risk_level || {}
  )
  const informalObservationStats =
    schoolMetrics?.school_metric?.completed_informal_observation_stats || {}
  const walkToReadStats =
    schoolMetrics?.school_metric?.completed_walk_to_read_stats || null
  const currentFailingGrades =
    schoolMetrics?.school_metric?.current_failing_grades || null

  const { data: meetings } = useQuery(
    ['school-meetings', school.id],
    () => {
      return getTeamMeetings({ school_id: school.id })
    },
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  const handleClose = () => {
    setDialogOpen(false)
  }

  const resetDialog = () => {
    setDialogOpen(false)
    setDate(moment()._d)
    setMeetingType('')
    setAttendees([])
    setNotes('')
    setNextSteps('')
  }

  const handleSubmit = e => {
    e.preventDefault()

    const params = {
      school_id: school.id,
      created_by_user_id: user.id,
      meeting_type: meetingType,
      team_meeting_attendees_attributes: attendees,
      notes: notes.trim(),
      next_steps: nextSteps.trim(),
      date: date
    }

    createTeamMeeting(params).then(() => {
      resetDialog()
      queryClient.invalidateQueries({
        queryKey: ['school-meetings', school.id]
      })
    })
  }

  const { data: usersWithAccess } = useQuery(
    ['school-users-with-access', school.id],
    async () => {
      const res = await fetchSchoolDetails(school.id)

      return res.users_with_access.map(userWithAccess => ({
        label: userWithAccess.name,
        value: userWithAccess.id
      }))
    },
    {
      initialData: []
    }
  )

  return (
    <Page name="Overview" title={school.name}>
      <Grid container spacing={4}>
        <Grid item md={6}>
          <Box>
            <Stack>
              {Boolean(metric) && (
                <SchoolMetrics
                  loading={schoolMetricsQuery.isLoading}
                  school={school}
                  metric={metric}
                  previousWeekMetric={previousWeekMetric}
                  previousMonthMetric={previousMonthMetric}
                  previousYearMetric={previousYearMetric}
                />
              )}
              <PaperBox
                title="Academic Interventions"
                id="academic_interventions"
              >
                {school.category == 'elementary' && (
                  <ElementaryAcademicPlatforms school={school} />
                )}

                {['middle', 'high'].includes(school.category) && (
                  <SecondaryAcademicPlatforms school={school} />
                )}
              </PaperBox>

              {['district', 'principal'].includes(user.role) && (
                <PaperBox
                  title={
                    <Link href={'/tulsa-way'}>Tulsa Way Observations</Link>
                  }
                  id="tulsa_way_observations"
                >
                  <Grid item md={12}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Compare to:{' '}
                            <ToggleButtonGroup
                              color="primary"
                              value={teacherObservationComparison}
                              exclusive
                              onChange={val =>
                                setTeacherObservationComparison(
                                  val.target.value
                                )
                              }
                              aria-label="Display as"
                            >
                              <ToggleButton
                                title="Two Weeks Ago"
                                value="two_week"
                              >
                                2W
                              </ToggleButton>
                              <ToggleButton title="1 Month Ago" value="month">
                                M
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </TableCell>
                          <TableCell>Unique Teacher Count (#)</TableCell>
                          <TableCell>Change</TableCell>
                          <TableCell>Total Count (#)</TableCell>
                          <TableCell>Change</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>Informal Observations</TableCell>
                          <TableCell>
                            {
                              informalObservationStats?.unique_informal_observations
                            }
                          </TableCell>
                          <TableCell>
                            {ifNaN(
                              informalObservationStats?.unique_informal_observations -
                                (teacherObservationComparison === 'two_week'
                                  ? previousTwoWeekMetric
                                      ?.completed_informal_observation_stats
                                      ?.unique_informal_observations
                                  : previousMonthMetric
                                      ?.completed_informal_observation_stats
                                      ?.unique_informal_observations)
                            )}
                          </TableCell>
                          <TableCell>
                            {
                              informalObservationStats?.total_informal_observations
                            }
                          </TableCell>
                          <TableCell>
                            {ifNaN(
                              informalObservationStats?.total_informal_observations -
                                (teacherObservationComparison === 'two_week'
                                  ? previousTwoWeekMetric
                                      ?.completed_informal_observation_stats
                                      ?.total_informal_observations
                                  : previousMonthMetric
                                      ?.completed_informal_observation_stats
                                      ?.total_informal_observations)
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>3+ Average Score</TableCell>
                          <TableCell>
                            {
                              informalObservationStats?.unique_informal_observations_with_three_plus_average_score
                            }
                          </TableCell>
                          <TableCell>
                            {ifNaN(
                              informalObservationStats?.unique_informal_observations_with_three_plus_average_score -
                                (teacherObservationComparison === 'two_week'
                                  ? previousTwoWeekMetric
                                      ?.completed_informal_observation_stats
                                      ?.unique_informal_observations_with_three_plus_average_score
                                  : previousMonthMetric
                                      ?.completed_informal_observation_stats
                                      ?.unique_informal_observations_with_three_plus_average_score)
                            )}
                          </TableCell>
                          <TableCell>
                            {
                              informalObservationStats?.total_informal_observations_with_three_plus_average_score
                            }
                          </TableCell>
                          <TableCell>
                            {ifNaN(
                              informalObservationStats?.total_informal_observations_with_three_plus_average_score -
                                (teacherObservationComparison === 'two_week'
                                  ? previousTwoWeekMetric
                                      ?.completed_informal_observation_stats
                                      ?.total_informal_observations_with_three_plus_average_score
                                  : previousMonthMetric
                                      ?.completed_informal_observation_stats
                                      ?.total_informal_observations_with_three_plus_average_score)
                            )}
                          </TableCell>
                        </TableRow>
                        {school.category === 'elementary' && (
                          <>
                            <TableRow>
                              <TableCell>
                                Walk to Read (W2R) Observations
                              </TableCell>
                              <TableCell>
                                {walkToReadStats?.unique_walk_to_read}
                              </TableCell>
                              <TableCell>
                                {ifNaN(
                                  walkToReadStats?.unique_walk_to_read -
                                    (teacherObservationComparison === 'two_week'
                                      ? previousTwoWeekMetric
                                          ?.completed_walk_to_read_stats
                                          ?.unique_walk_to_read
                                      : previousMonthMetric
                                          ?.completed_walk_to_read_stats
                                          ?.unique_walk_to_read)
                                )}
                              </TableCell>
                              <TableCell>
                                {walkToReadStats?.total_walk_to_read}
                              </TableCell>
                              <TableCell>
                                {ifNaN(
                                  walkToReadStats?.total_walk_to_read -
                                    (teacherObservationComparison === 'two_week'
                                      ? previousTwoWeekMetric
                                          ?.completed_walk_to_read_stats
                                          ?.total_walk_to_read
                                      : previousMonthMetric
                                          ?.completed_walk_to_read_stats
                                          ?.total_walk_to_read)
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Meeting All W2R Look Fors</TableCell>
                              <TableCell>
                                {
                                  walkToReadStats?.unique_walk_to_read_with_all_look_fors_observed
                                }
                              </TableCell>
                              <TableCell>
                                {ifNaN(
                                  walkToReadStats?.unique_walk_to_read_with_all_look_fors_observed -
                                    (teacherObservationComparison === 'two_week'
                                      ? previousTwoWeekMetric
                                          ?.completed_walk_to_read_stats
                                          ?.unique_walk_to_read_with_all_look_fors_observed
                                      : previousMonthMetric
                                          ?.completed_walk_to_read_stats
                                          ?.unique_walk_to_read_with_all_look_fors_observed)
                                )}
                              </TableCell>
                              <TableCell>
                                {
                                  walkToReadStats?.total_walk_to_read_with_all_look_fors_observed
                                }
                              </TableCell>
                              <TableCell>
                                {ifNaN(
                                  walkToReadStats?.total_walk_to_read_with_all_look_fors_observed -
                                    (teacherObservationComparison === 'two_week'
                                      ? previousTwoWeekMetric
                                          ?.completed_walk_to_read_stats
                                          ?.total_walk_to_read_with_all_look_fors_observed
                                      : previousMonthMetric
                                          ?.completed_walk_to_read_stats
                                          ?.total_walk_to_read_with_all_look_fors_observed)
                                )}
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </Grid>
                </PaperBox>
              )}
            </Stack>
          </Box>
        </Grid>

        <Grid item md={6}>
          <Box>
            <Stack>
              <PaperBox title="MTSS" id="mtss">
                <Stack>
                  {/* No instruction as to where attendance tiers should go (their values currently being used as denominator for SSP graphic) */}
                  {/* {attendanceTiers && (
                  <PaperBox
                    title="Students Needing Attendance Support"
                    helperText={{
                      'Who is included?':
                        'Any student currently enrolled at the school.',
                      'What attendance data is being used to determine the tier/risk level?':
                        "We use attendance data from the student's current school this academic year to determine the tier/risk level. This does not include possible attendance data from other schools. If you'd like to see the student's YTD attendance %, go to an individual student's Attendance tab.",
                      'Tier 1 (Not At Risk)':
                        'ADA is above 95%. Students should receive standard district and schoolwide practices.',
                      'Tier 1+ (At Risk)':
                        'ADA is above 90% and less than or equal to 95%. Students should receive early supports.',
                      'Tier 2 (Chronic)':
                        'ADA is above 80% and less than or equal to 90%. Students should receive targeted interventions.',
                      'Tier 3 (Critically Chronic)':
                        'ADA is less than or equal to 80%. Students should receive the most intense, targeted interventions.'
                    }}
                  >
                    <PieChartFiveSlices
                      one={{
                        title: 'Tier 1 (Not At Risk)',
                        value: attendanceTiers?.tier_one,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: school.id,
                            view: 'Attendance',
                            chronic: ['perfect', 'not_at_risk']
                          }
                        }
                      }}
                      two={{
                        title: 'Tier 1+ (At Risk)',
                        value: attendanceTiers?.tier_one_plus,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: school.id,
                            view: 'Attendance',
                            chronic: 'at_risk'
                          }
                        }
                      }}
                      three={{
                        title: 'Tier 2 (Chronic)',
                        value: attendanceTiers?.tier_two,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: school.id,
                            view: 'Attendance',
                            chronic: 'chronic'
                          }
                        }
                      }}
                      four={{
                        title: 'Tier 3 (Critically Chronic)',
                        value: attendanceTiers?.tier_three,
                        options: {
                          pathname: '/students',
                          query_params: {
                            school_id: school.id,
                            view: 'Attendance',
                            chronic: 'critically_chronic'
                          }
                        }
                      }}
                    />
                  </PaperBox>
                )} */}
                  {attendanceTiers && (
                    <Box sx={{ border: 1, p: 2 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start'
                        }}
                      >
                        <Typography variant="h6">
                          Attendance-Related Student Success Plans
                        </Typography>
                        <IconButton
                          onClick={event =>
                            setSuccessPlansAnchorEl(event.currentTarget)
                          }
                          size="small"
                        >
                          <QuestionMark />
                        </IconButton>
                        <Popover
                          id={successPlansId}
                          open={successPlansOpen}
                          anchorEl={successPlansAnchorEl}
                          onClose={() => setSuccessPlansAnchorEl(null)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                          }}
                        >
                          <Box sx={{ maxWidth: 250 }}>
                            <Typography sx={{ p: 2 }}>
                              <b>How is &apos;Has SSP&apos; calculated?</b>{' '}
                              Count of unique students with either a Student
                              Success Plan attached to an attendance concern or
                              having a Student Success Plan Intervention.
                              <Divider sx={{ my: 2 }} />
                              <b>How is &apos;No SSP&apos; calculated?</b> Count
                              of unique students without a Student Success Plan
                              attached to an attendance concern nor having a
                              student success plan intervention.
                              <Divider sx={{ my: 2 }} />
                              <b>How often is this data updated?</b> These
                              counts are recalculated nightly.
                            </Typography>
                          </Box>
                        </Popover>
                      </Box>
                      <BarChart
                        height={300}
                        onAxisClick={(_e, column) => {
                          // combine perfect and not at risk to match yearly attendance risk levels
                          const filters =
                            column.axisValue == 'Not At Risk'
                              ? 'chronic=not_at_risk,perfect'
                              : `chronic=${snakeCase(column.axisValue)}`
                          window.location.assign(
                            `/students?view=Attendance&${filters}`
                          )
                        }}
                        series={[
                          {
                            data: [
                              studentSuccessPlanCounts?.not_at_risk || 0,
                              studentSuccessPlanCounts?.at_risk || 0,
                              studentSuccessPlanCounts?.chronic || 0,
                              studentSuccessPlanCounts?.critically_chronic || 0
                            ],
                            label: 'Has SSP',
                            stack: 'total'
                          },
                          {
                            data: [
                              attendanceTiers.tier_one -
                                (studentSuccessPlanCounts?.not_at_risk || 0),
                              attendanceTiers.tier_one_plus -
                                (studentSuccessPlanCounts?.at_risk || 0),
                              attendanceTiers.tier_two -
                                (studentSuccessPlanCounts?.chronic || 0),
                              attendanceTiers.tier_three -
                                (studentSuccessPlanCounts?.critically_chronic ||
                                  0)
                            ],
                            label: 'No SSP',
                            stack: 'total'
                          }
                        ]}
                        xAxis={[
                          {
                            id: 'tiers',
                            data: [
                              'Not At Risk',
                              'At Risk',
                              'Chronic',
                              'Critically Chronic'
                            ],
                            scaleType: 'band',
                            label: 'Attendance Risk Levels'
                          }
                        ]}
                      />
                    </Box>
                  )}
                  <Table sx={{ border: 1 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-start'
                            }}
                          >
                            <Typography variant="h6">
                              Follow-Ups (Last 6 Weeks)
                            </Typography>
                            <IconButton
                              onClick={event =>
                                setFollowUpsAnchorEl(event.currentTarget)
                              }
                              size="small"
                            >
                              <QuestionMark />
                            </IconButton>
                            <Popover
                              id={followUpsId}
                              open={followUpsOpen}
                              anchorEl={followUpsAnchorEl}
                              onClose={() => setFollowUpsAnchorEl(null)}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                            >
                              <Box sx={{ maxWidth: 250 }}>
                                <Typography sx={{ p: 2 }}>
                                  <b>
                                    How is &apos;Followed Up&apos; calculated?
                                  </b>{' '}
                                  All records, regardless of status, with
                                  updates in the last 6 weeks.
                                  <Divider sx={{ my: 2 }} />
                                  <b>
                                    How is &apos;Did Not Follow Up&apos;
                                    calculated?
                                  </b>{' '}
                                  All records without updates in the last 6
                                  weeks.
                                </Typography>
                              </Box>
                            </Popover>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>Followed Up (#)</TableCell>
                        <TableCell>Did Not Follow Up (#)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Link href={'/wellness'}>
                            Academic Targeted Interventions
                          </Link>
                        </TableCell>
                        <TableCell>
                          {interventionStats?.academic_followed_up}
                        </TableCell>
                        <TableCell>
                          {interventionStats?.academic_did_not_follow_up}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Link href={'/wellness'}>
                            Behavior Targeted Interventions
                          </Link>
                        </TableCell>
                        <TableCell>
                          {interventionStats?.behavior_followed_up}
                        </TableCell>
                        <TableCell>
                          {interventionStats?.behavior_did_not_follow_up}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Link href={'/wellness'}>
                            Attendance Targeted Interventions
                          </Link>
                        </TableCell>
                        <TableCell>
                          {interventionStats?.attendance_followed_up}
                        </TableCell>
                        <TableCell>
                          {interventionStats?.attendance_did_not_follow_up}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Link href={'/child_studies'}>Child Studies</Link>
                        </TableCell>
                        <TableCell>{childStudyStats?.followed_up}</TableCell>
                        <TableCell>
                          {childStudyStats?.did_not_follow_up}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table sx={{ border: 1 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography variant={'h6'}>
                            Latest Meetings
                            <Button
                              sx={{ ml: 1 }}
                              disabled={meetings.length == 0}
                              onClick={() => setSeeAllMeetings(!seeAllMeetings)}
                            >
                              {!seeAllMeetings ? 'See All' : 'Collapse'}
                            </Button>
                            <Button
                              id="log-meeting"
                              onClick={() => setDialogOpen(true)}
                              variant={'contained'}
                              color={'primary'}
                            >
                              Log Meeting
                            </Button>
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Next Steps</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Attendees</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {meetings.map((meeting, index) => {
                        if (seeAllMeetings || index < 2) {
                          return (
                            <TableRow key={meeting.id}>
                              <TableCell>{meeting.meeting_type}</TableCell>
                              <TableCell>
                                {moment(meeting.date).format('MM/DD/YYYY')}
                              </TableCell>
                              <TableCell>{meeting.next_steps}</TableCell>
                              <TableCell>
                                <Tooltip
                                  placement={'top'}
                                  arrow
                                  title={
                                    <Typography>{meeting.notes}</Typography>
                                  }
                                >
                                  <NoteAlt />
                                </Tooltip>
                              </TableCell>
                              <TableCell>
                                <Tooltip
                                  placement={'top'}
                                  arrow
                                  title={
                                    <Box>
                                      {meeting.attendees.map((attendee, i) => (
                                        <Typography key={i}>
                                          {titleize(attendee.role)}{' '}
                                          {attendee.user
                                            ? `- ${attendee.user.name}`
                                            : ''}
                                        </Typography>
                                      ))}
                                    </Box>
                                  }
                                >
                                  <People />
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          )
                        }
                      })}
                    </TableBody>
                  </Table>
                  <Dialog
                    fullWidth
                    title={'Log Meeting'}
                    open={dialogOpen}
                    onClose={handleClose}
                  >
                    <DialogTitle>Log a Meeting</DialogTitle>
                    <form onSubmit={handleSubmit}>
                      <DialogContent>
                        <Stack>
                          <DatePicker
                            renderInput={props => <TextField {...props} />}
                            disableToolbar
                            required
                            fullWidth
                            value={date}
                            inputFormat="MM/DD/YYYY"
                            inputVariant="inline"
                            margin="normal"
                            id="date-event-on"
                            onChange={val => setDate(val)}
                            label="Date"
                          />

                          <FormControl fullWidth variant="outlined">
                            <InputLabel id="team-meeting-type-label" required>
                              Meeting Type
                            </InputLabel>
                            <Select
                              labelId="team-meeting-type-label"
                              id="team-meeting-type"
                              label="Meeting Type"
                              value={meetingType}
                              onChange={e => setMeetingType(e.target.value)}
                              required
                            >
                              <MenuItem key="attendance" value="Attendance">
                                Attendance Team Meeting
                              </MenuItem>
                              <MenuItem key="mtss" value="MTSS Leadership">
                                MTSS Tier 1 Leadership Team Meeting
                              </MenuItem>
                            </Select>
                          </FormControl>

                          <FormControl fullWidth variant="outlined">
                            <SimpleAutocomplete
                              required={attendees.length === 0}
                              label={'Select Attendees'}
                              options={roles}
                              value={role}
                              onChange={val => setRole(val.value)}
                            />
                          </FormControl>
                          {role && (
                            <>
                              <FormControl fullWidth variant="outlined">
                                <SimpleAutocomplete
                                  label={'Select User (Optional)'}
                                  options={usersWithAccess}
                                  value={userId}
                                  onChange={val => setUserId(val.value)}
                                />
                              </FormControl>
                              <Button
                                onClick={() => {
                                  setAttendees(
                                    attendees.concat({
                                      role: role,
                                      user_id: userId
                                    })
                                  )
                                  setRole('')
                                  setUserId('')
                                }}
                              >
                                Add to meeting
                              </Button>
                            </>
                          )}
                          {attendees.length > 0 && (
                            <Typography>Attendees</Typography>
                          )}

                          {attendees.map((attendee, index) => (
                            <Box key={`attendee-${index}`}>
                              <Box>
                                {
                                  roles.find(r => r.value === attendee.role)
                                    .label
                                }
                              </Box>
                              <Box>
                                {
                                  usersWithAccess.find(
                                    r => r.value === attendee.user_id
                                  )?.label
                                }
                              </Box>
                              <IconButton
                                onClick={() => {
                                  const output = attendees.filter(
                                    (_attendee, i) => i !== index
                                  )
                                  setAttendees(output)
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          ))}

                          <TextField
                            required
                            id="team-meeting-details"
                            label="What data point was your biggest area of focus and why?"
                            value={notes}
                            onChange={e =>
                              setNotes(e.target.value.replace(/^\s+/, ''))
                            }
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            placeholder={
                              'Example: 5th grade attendance is the lowest across all grade-levels for this last month, 6th grade level 1 referrals are 5% higher this time at this time when compared to last year.'
                            }
                          />
                          <TextField
                            required
                            id="team-meeting-next-steps"
                            label="What are your next steps?"
                            value={nextSteps}
                            onChange={e =>
                              setNextSteps(e.target.value.replace(/^\s+/, ''))
                            }
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            placeholder={
                              'Examples: As the attendance team, we will 1) share this trend at the upcoming Monthly MTSS Leadership Meeting 2) dig deeper into 5th grade students and log attendance interventions for students who are chronically absent. As the MTSS Leadership Team, we will 1) share the data about 6th grade referrals at next week’s staff meeting, and 2) ask staff to revisit their implementation of our passing procedures as most referrals are occurring between classes in the hallways.'
                            }
                          />
                        </Stack>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                        <Button
                          variant="contained"
                          color="primary"
                          type={'submit'}
                          disabled={
                            !meetingType ||
                            !attendees.length ||
                            !notes ||
                            !nextSteps
                          }
                        >
                          Save
                        </Button>
                      </DialogActions>
                    </form>
                  </Dialog>
                </Stack>
              </PaperBox>
              <PaperBox title="Common Formative Assessments">
                <Grid item md={12}>
                  Feature is in development. Check back later.
                  {/* <div>
                  Common Formative Assessments Table (Most Recent Module) By
                  Grade Level w/ Completion % and Proficiency %
                </div> */}
                </Grid>
              </PaperBox>

              {['middle', 'high'].includes(school.category) &&
                currentFailingGrades && (
                  <FailingGrades
                    currentFailingGrades={currentFailingGrades}
                    previousDayMetric={previousDayMetric}
                    previousWeekMetric={previousWeekMetric}
                    previousTwoWeekMetric={previousTwoWeekMetric}
                    previousMonthMetric={previousMonthMetric}
                    previousYearMetric={previousYearMetric}
                  />
                )}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Page>
  )
}

Overview.propTypes = {
  school: PropTypes.object.isRequired,
  ...userPropTypes
}

const mapStateToProps = state => ({
  user: state.currentUser.user
})

export default connect(mapStateToProps)(Overview)
