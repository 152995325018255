import { get } from 'utils/ajax'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const defaults = { params: {}, options: {} }

export function useDevelopmentAreas({ params, options } = defaults) {
  const queryKey = ['tulsa-way:development-areas']
  const queryFn = () => get('/api/web/tulsa_way/development_areas', { params })
  const query = useQuery(queryKey, queryFn, options)

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}
