import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/admin-page-container'
import {
  afternoonBellTimes,
  morningBellTimes,
  schoolBooleanOptions,
  schoolCategoryOptions
} from 'utils/enum-options'
import { alert } from 'utils/alert'
import { editSchool, fetchSchools } from 'api/admin/schools'
import { get } from 'utils/ajax'
import { titleize } from 'utils'
import { useTheme } from '@mui/styles'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'

function AdminEditSchool() {
  const [activeSchool, setActiveSchool] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const [schools, setSchools] = useState([])
  const [schoolId, setSchoolId] = useState('')

  const theme = useTheme()

  useEffect(() => {
    schoolIdFromUrl()
    schoolId && fetchSchoolInfo()
    handleFetchSchools()
  }, [schoolId])

  const fetchSchoolInfo = () => {
    return get(`/api/admin/schools/${schoolId}?edit=true`).then(res => {
      setActiveSchool(res.data)
    })
  }

  const formatSchoolSelections = schools => {
    const schoolOptions = schools.map(school => ({
      label: titleize(school.name),
      value: school.id
    }))
    setSchools(schoolOptions)
  }

  const handleChange = (identifier, _value) => {
    setActiveSchool({
      ...activeSchool,
      [identifier]: _value
    })
  }

  const handleEditSchool = (e, activeSchool) => {
    e.preventDefault()
    editSchool(activeSchool)
      .catch(() => {
        alert('There was an error updating the school.', 'danger')
      })
      .then(() => {
        alert('Successfully updated the school.', 'success')
        setRedirect(true)
      })
  }

  const handleFetchSchools = () => {
    const params = {
      active: true,
      per_page: 1000,
      as_options: true
    }
    fetchSchools(params)
      .then(res => {
        formatSchoolSelections(res)
      })
      .catch(() => {
        alert('Failed to fetch schools')
      })
  }

  function schoolIdFromUrl() {
    setSchoolId(parseInt(window.location.href.split('schools/')[1]))
  }

  if (redirect) {
    return <Redirect to="/admins/schools" />
  }

  return (
    <Page name="School" title="School">
      <PageContainer>
        <Box>
          {activeSchool && (
            <form onSubmit={e => handleEditSchool(e, activeSchool)}>
              <Typography variant="h1">Edit School</Typography>
              <Typography variant="h2">{activeSchool.name}</Typography>
              <Grid container spacing={4}>
                <Grid xs={2} item>
                  <h4>School Info</h4>
                </Grid>
                <Grid xs={4} item>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      color="primary"
                      label="Name"
                      required
                      defaultValue={activeSchool['name']}
                      onChange={val => handleChange('name', val.target.value)}
                      id="name_field"
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
                <Grid xs={4} item>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      color="primary"
                      label="Display Name"
                      required
                      defaultValue={activeSchool['display_name']}
                      onChange={val =>
                        handleChange('display_name', val.target.value)
                      }
                      id="display_name_field"
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid xs={2} item />
                <Grid xs={3} item>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      color="primary"
                      label="Website"
                      defaultValue={activeSchool['website']}
                      onChange={val =>
                        handleChange('website', val.target.value)
                      }
                      id="website_field"
                      variant="filled"
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid xs={2} item>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      color="primary"
                      label="Phone"
                      defaultValue={activeSchool['phone']}
                      onChange={val => handleChange('phone', val.target.value)}
                      id="phone_field"
                      variant="filled"
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid xs={2} item>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      color="primary"
                      label="Address"
                      defaultValue={activeSchool['address']}
                      onChange={val =>
                        handleChange('address', val.target.value)
                      }
                      id="address_field"
                      variant="filled"
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid xs={2} item>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      color="primary"
                      label="Zip"
                      defaultValue={activeSchool['zip']}
                      onChange={val => handleChange('zip', val.target.value)}
                      id="zip_field"
                      variant="filled"
                      required
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={2} />
                <Grid item xs={3}>
                  <FormControl variant="filled" fullWidth margin="normal">
                    <InputLabel>Category</InputLabel>
                    <Select
                      id="category-selector"
                      value={activeSchool['category']}
                      onChange={val =>
                        handleChange('category', val.target.value)
                      }
                      required
                    >
                      {schoolCategoryOptions.map(option => (
                        <MenuItem key={option.label} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <FormControl variant="filled" fullWidth margin="normal">
                    <InputLabel>Start Time</InputLabel>
                    <Select
                      id="start_time-selector"
                      value={
                        activeSchool['start_time'] &&
                        activeSchool['start_time'].length > 10
                          ? activeSchool['start_time'].slice(11, 19)
                          : activeSchool['start_time']
                      }
                      onChange={val =>
                        handleChange('start_time', val.target.value)
                      }
                      required
                    >
                      {morningBellTimes.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl variant="filled" fullWidth margin="normal">
                    <InputLabel>End Time</InputLabel>
                    <Select
                      id="end_time-selector"
                      value={
                        activeSchool['end_time'] &&
                        activeSchool['end_time'].length > 10
                          ? activeSchool['end_time'].slice(11, 19)
                          : activeSchool['end_time']
                      }
                      onChange={val =>
                        handleChange('end_time', val.target.value)
                      }
                      required
                    >
                      {afternoonBellTimes.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={4}>
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      color="primary"
                      label="Notes"
                      defaultValue={activeSchool['notes']}
                      onChange={val => handleChange('notes', val.target.value)}
                      id="notes_field"
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Divider style={{ margin: theme.spacing(2) }} />

              <Grid container spacing={4}>
                <Grid item xs={2}>
                  <h4>Principal Info</h4>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      color="primary"
                      label="Principal"
                      defaultValue={activeSchool['principal']}
                      onChange={val =>
                        handleChange('principal', val.target.value)
                      }
                      id="principal_field"
                      variant="filled"
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      color="primary"
                      label="Principal Phone"
                      defaultValue={activeSchool['principal_phone']}
                      onChange={val =>
                        handleChange('principal_phone', val.target.value)
                      }
                      id="principal_phone_field"
                      variant="filled"
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth margin="normal">
                    <TextField
                      color="primary"
                      label="Principal Email"
                      defaultValue={activeSchool['principal_email']}
                      onChange={val =>
                        handleChange('principal_email', val.target.value)
                      }
                      id="principal_email_field"
                      variant="filled"
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Divider style={{ margin: theme.spacing(2) }} />

              <Grid container spacing={4}>
                <Grid item xs={2}>
                  <h4>Connections</h4>
                </Grid>
                <Grid item xs={3}>
                  <FormControl
                    fullWidth
                    variant="filled"
                    margin="normal"
                    key="dualSite"
                  >
                    <InputLabel>Dual Site</InputLabel>
                    <Select
                      id="dual-site-selector"
                      defaultValue={activeSchool.dual_site || ''}
                      onChange={val =>
                        handleChange('dual_site', val.target.value)
                      }
                    >
                      <MenuItem value="">None</MenuItem>
                      {!!schools &&
                        schools.map(school => (
                          <MenuItem
                            key={`school-${school.value}`}
                            value={school.value}
                          >
                            {school.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Divider style={{ margin: theme.spacing(2) }} />

              <Grid container spacing={4}>
                <Grid item xs={2}>
                  <h4>Additional Options</h4>
                </Grid>

                <Grid item xs={3}>
                  <InputLabel>Primary Color</InputLabel>
                  <Box
                    style={{
                      width: '3rem',
                      height: '3rem',
                      backgroundColor: activeSchool.primary_color || '#fff'
                    }}
                  ></Box>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>Secondary Color</InputLabel>
                  <Box
                    style={{
                      width: '3rem',
                      height: '3rem',
                      backgroundColor: activeSchool.primary_color || '#fff'
                    }}
                  ></Box>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={2} />
                <Grid item xs={6}>
                  {schoolBooleanOptions.map(option => (
                    <FormControlLabel
                      key={option.label}
                      control={
                        <Checkbox
                          color="primary"
                          checked={activeSchool[option.value]}
                          onChange={val =>
                            handleChange(option.value, val.target.checked)
                          }
                          id={`${option.value}_field`}
                        />
                      }
                      label={option.label}
                    />
                  ))}
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    id="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Box>
      </PageContainer>
    </Page>
  )
}

export default AdminEditSchool
