import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import EngineeringIcon from '@mui/icons-material/Engineering'

export function WellnessTeamManageButton({ disabled, onClick = () => {} }) {
  return (
    <Button
      onClick={onClick}
      aria-label="manage"
      variant="contained"
      color="secondary"
      disabled={disabled}
      startIcon={<EngineeringIcon />}
    >
      Manage Leadership Team
    </Button>
  )
}

WellnessTeamManageButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

export default WellnessTeamManageButton
