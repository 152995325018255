import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PaperBox from 'components/shared/PaperBox'
import PieChartFiveSlices from 'components/shared/charts/PieChartFiveSlices'
import { useQuery } from '@tanstack/react-query'
import { handleRailsRequestJsResponse } from 'utils/handleRailsRequestJsResponse'
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
// import urls from 'utils/urls'
// import { get } from 'utils/ajax'

// These use rails/request.js
import { getAcademicInterventionsSummary } from 'api/academic-interventions'

export function SecondaryAcademicPlatforms({ school }) {
  const [platform, setPlatform] = useState('exact_path')

  // const [literacyInterventionEnrollments, setLiteracyInterventionEnrollments] = useState({})

  const { data: academicInterventionsSummary } = useQuery(
    ['academic-interventions-school-summary', school.id],
    async () => {
      const response = await getAcademicInterventionsSummary({
        school_id: school.id
      })
      const json = await handleRailsRequestJsResponse(response)
      return json.data.platform_data
    },
    {
      initialData: null,
      refetchOnWindowFocus: false
    }
  )

  if (!academicInterventionsSummary) {
    return null
  }

  // useEffect(() => {
  //   const params = { school_year: currentSchoolYear }

  //   get(urls.api.schoolMetrics(school.id), { params }).then(data => {
  //     setLiteracyInterventionEnrollments(
  //       data.school_metric?.literacy_intervention_enrollments
  //     )
  //   })
  // }, [school.id])

  // no data exists yet for these, so charts will be blank.
  const exactPathInterventionsSummary = academicInterventionsSummary?.find(
    r => r['platform'] == 'exact_path'
  )
  const achieve3000InterventionsSummary = academicInterventionsSummary?.find(
    r => r['platform'] == 'achieve_3000'
  )
  const summitInterventionsSummary = academicInterventionsSummary?.find(
    r => r['platform'] == 'summit'
  )

  const isSummitPilotSchool = [
    'EAST CENTRAL MIDDLE SCHOOL',
    'HALE MIDDLE SCHOOL',
    'NORTH STAR ACADEMY HIGH SCHOOL',
    'TULSA MET MS',
    'VIRTUAL MIDDLE SCHOOL',
    'VIRTUAL HIGH SCHOOL',
    'WEBSTER MIDDLE SCHOOL'
  ].includes(school?.name)
    ? true
    : false

  const conditionallyAddSummitPlatformOption = isSummitPilotSchool
    ? [{ label: 'Summit K12', value: 'summit' }]
    : []

  const defaultPlatformOptions = [
    { label: 'Exact Path', value: 'exact_path' },
    { label: 'Achieve3000', value: 'achieve_3000' }
  ]

  const platformOptions = [
    ...defaultPlatformOptions,
    ...conditionallyAddSummitPlatformOption
  ]

  return (
    <Stack>
      <FormControl fullWidth>
        <InputLabel id="platform-label">Platform</InputLabel>
        <Select
          id="platform"
          labelId="platform-label"
          label="Platform"
          value={platform}
          onChange={val => setPlatform(val.target.value)}
        >
          {platformOptions.map(option => (
            <MenuItem key={option.label} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {platform === 'exact_path' && (
        <PaperBox
          title="Usage by Active Students With an Exact Path Intervention Last Week"
          helperText={{
            Usage:
              'Any time or activities recorded by the vendor for a given student, by week',
            Intervention:
              'The intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in primary interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
            Threshold:
              'Minimum 40 minutes (engaged/time on task) and one skill mastered per week on the Reading Learning Path'
          }}
        >
          <PieChartFiveSlices
            one={{
              title: 'Used - Threshold Met',
              value: exactPathInterventionsSummary?.counts?.threshold_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'exact_path'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Exact Path as their primary platform."
              }
            }}
            two={{
              title: 'Used - Threshold Not Met',
              value: exactPathInterventionsSummary?.counts?.threshold_not_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'exact_path'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Exact Path as their primary platform."
              }
            }}
            three={{
              title: 'Not Used',
              value: exactPathInterventionsSummary?.counts?.not_used,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'exact_path'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Exact Path as their primary platform."
              }
            }}
          />
        </PaperBox>
      )}
      {platform === 'achieve_3000' && (
        <PaperBox
          title="Usage by Active Students With an Achieve3000 Intervention Last Week"
          helperText={{
            Usage:
              'Any time or activities recorded by the vendor for a given student, by week',
            Intervention:
              'The intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in primary interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
            Threshold:
              'Complete 1 activity per week (one 5 step lesson) AND Score 75% or higher on their first try of the multiple choice activity'
          }}
        >
          <PieChartFiveSlices
            one={{
              title: 'Used - Threshold Met',
              value: achieve3000InterventionsSummary?.counts?.threshold_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'achieve_3000'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Achieve3000 as their primary platform."
              }
            }}
            two={{
              title: 'Used - Threshold Not Met',
              value: achieve3000InterventionsSummary?.counts?.threshold_not_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'achieve_3000'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Achieve3000 as their primary platform."
              }
            }}
            three={{
              title: 'Not Used',
              value: achieve3000InterventionsSummary?.counts?.not_used,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'achieve_3000'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Achieve3000 as their primary platform."
              }
            }}
          />
        </PaperBox>
      )}

      {platform === 'summit' && (
        <PaperBox
          title="Usage Per Week by Active Students With Summit K12 As Primary Intervention"
          helperText={{
            'Usage':
              'Any time or activities recorded by the vendor for a given student, by week',
            'Primary Intervention':
              'The intervention platform that has been assigned to a student based on their grade level, ML status, and reading course number. While students may participate in other digital reading interventions based on teacher guidance, participation in Primary Interventions is tracked week-by-week to ensure proper dosage of the appropriate intervention to designated students.',
            'Threshold': '75 minutes weekly'
          }}
        >
          <PieChartFiveSlices
            one={{
              title: 'Used - Threshold Met',
              value: summitInterventionsSummary?.counts?.threshold_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'summit'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Summit K12 as their primary platform."
              }
            }}
            two={{
              title: 'Used - Threshold Not Met',
              value: summitInterventionsSummary?.counts?.threshold_not_met,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'summit'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Summit K12 as their primary platform."
              }
            }}
            three={{
              title: 'Not Used',
              value: summitInterventionsSummary?.counts?.not_used,
              options: {
                pathname: '/students',
                query_params: {
                  school_id: school?.id,
                  view: 'Reading Interventions',
                  current_platform: 'summit'
                },
                alert_text:
                  "We've filtered the page to show Reading Intervention data for students with Summit K12 as their primary platform."
              }
            }}
          />
        </PaperBox>
      )}

      {/* {['middle', 'high'].includes(school?.category) &&
        literacyInterventionEnrollments && (
          <PaperBox
            title="Literacy Intervention Enrollment"
            helperText={{
              'Who Is Included?':
                'Enrolled students at or below 25th percentile on MAP Reading',
              'Update Frequency': 'Three times per year (Fall/Winter/Spring)',
              'What Qualifies As A Literacy Intervention?':
                'Student is enrolled in a READ 180, ELD, or ACADEMIC LANGUAGE course'
            }}
          >
            <PieChartFiveSlices
              one={{
                title: 'Enrolled',
                value: literacyInterventionEnrollments.enrolled,
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'MAP',
                    latest_map_reading_percentile_range: [1, 25],
                    enrolled_in_literacy_intervention: true
                  }
                }
              }}
              two={{
                title: 'Not Enrolled',
                value: literacyInterventionEnrollments.not_enrolled,
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'MAP',
                    latest_map_reading_percentile_range: [1, 25],
                    enrolled_in_literacy_intervention: false
                  }
                }
              }}
            />
          </PaperBox>
        )} */}
    </Stack>
  )
}

SecondaryAcademicPlatforms.propTypes = {
  school: PropTypes.shape({
    id: PropTypes.number
  })
}

export default SecondaryAcademicPlatforms
