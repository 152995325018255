import { get, post, patch } from 'utils/ajax'
import { alert } from 'utils/alert'
import moment from 'moment'

export const createUser = params => {
  params.expiration_date = moment().format()
  return post('/api/admin/users', {
    user: params
  })
    .then(_res => {
      alert('User successfully created', 'success')
    })
    .catch(err => {
      alert(
        'An error occurred while creating the user. ' + err.data.message + '.',
        'danger'
      )
    })
}

export const resetUserPassword = userId => {
  return get(`/admins/generate_new_password_email?id=${userId}`)
    .then(_res => {
      alert('User password reset and email sent!', 'success')
    })
    .catch(_err => {
      alert('An error occurred while changing the user`s password', 'danger')
    })
}

export const updateUser = params => {
  return patch('/api/admin/users/' + params.id, {
    user: params
  })
    .then(_res => {
      alert('User successfully updated', 'success')
    })
    .catch(err => {
      alert(
        'An error occurred while updating the user. ' + err.data.message + '.',
        'danger'
      )
    })
}
