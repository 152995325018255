import React from 'react'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import Header from 'components/shared/header'

const NotFound = () => (
  <Page name="NotFound" title="Not Found">
    <PageContainer noSidebar>
      <Header.Contents>
        <Header.Title>Uh oh, that’s a 404 🌝</Header.Title>
      </Header.Contents>

      <p>
        We cannot find the resource you are looking for. If you think you might
        be seeing this in error, use the feedback form at the bottom of this
        page to send us a note.
      </p>
    </PageContainer>
  </Page>
)

export default NotFound
