import { currentSchoolYear } from 'utils'

export const sped = ['true', 'false']
export const ell = ['Yes', 'No', 'Monitored/Exited']
export const ethnicity = [
  'Hispanic',
  'African American',
  'Asian/P. Islander',
  'White',
  'Multiracial',
  'Native American'
]
export const gender = ['M', 'F']
export const currentCohorts = [
  currentSchoolYear,
  currentSchoolYear + 1,
  currentSchoolYear + 2,
  currentSchoolYear + 3
]
export const socioeconomically_disadvantaged = ['true', 'false']
export const graduationRisks = ['On Track', 'Off Track', 'At Risk', 'High Risk']
export const fetchGroupCategories = group => {
  if (group === 'ell') {
    return ell
  } else if (group === 'sped' || group === 'idea') {
    return sped
  } else if (group === 'ethnicity') {
    return ethnicity
  } else if (group === 'gender') {
    return gender
  } else if (group === 'cohort') {
    return currentCohorts
  } else if (group === 'socioeconomically_disadvantaged') {
    return socioeconomically_disadvantaged
  }
}

export const humanizeCategories = c => {
  switch (c) {
    case 'true':
      return 'Yes'
    case 'false':
      return 'No'
    case '':
      return 'Blank'
    default:
      return c
  }
}
