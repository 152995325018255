export const types = {
  FETCH_INTERVENTIONS: 'interventions/fetch_interventions'
}

const initialState = {
  data: [],
  meta: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_INTERVENTIONS:
      return action.payload
    default:
      return state
  }
}
