export const types = {
  FETCH_STUDENTS: 'reports/fetch_students',
  SET_STUDENTS_LIST: 'reports/set_students_list'
}

const initialState = {
  selectedStudents: [],
  students: [],
  meta: null
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_STUDENTS:
      return {
        ...state,
        students: action.payload.data,
        meta: action.payload.meta
      }
    case types.SET_STUDENTS_LIST:
      return {
        ...state,
        selectedStudents: action.payload.selected
      }
    default:
      return state
  }
}
