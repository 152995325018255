import PropTypes from 'prop-types'

export const categoryShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  color_name: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired
})

export const developmentAreaShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  weight: PropTypes.number.isRequired
})

export const lookForShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  category: categoryShape.isRequired,
  areas: PropTypes.arrayOf(developmentAreaShape).isRequired,
  text: PropTypes.string.isRequired
})

export const observationTypelookForShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  development_area_name: PropTypes.string,
  look_for_category_name: PropTypes.string,
  look_for_category_color: PropTypes.string,
  nullable: PropTypes.bool.isRequired
})
