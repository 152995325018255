import { get, post, patch, destroy } from '@rails/request.js'
import { alert } from 'utils/alert'

const destroyConfidentialCrisisTeamMembership = async id =>
  await destroy(`/api/web/confidential_crisis_team_memberships/${id}`)

const getConfidentialCrisisTeamMemberships = async () => {
  const response = await get('/api/web/confidential_crisis_team_memberships')

  if (response.statusCode == 422) {
    const msg = await response.json
    if (msg.detail == 401) {
      window.location = '/signin'
    }
  }

  if (response.ok) {
    return await response.json
  } else {
    alert(JSON.stringify(await response.json), 'danger')
    return null
  }
}

const createConfidentialCrisisTeamMembership = async params => {
  const response = await post('/api/web/confidential_crisis_team_memberships', {
    body: JSON.stringify(params),
    contentType: 'application/json',
    responseKind: 'json'
  })

  if (response.ok) {
    return await response.json
  } else {
    alert(JSON.stringify(await response.json), 'danger')
    return null
  }
}

const updateConfidentialCrisisTeamMembership = async (id, params) => {
  const response = await patch(
    `/api/web/confidential_crisis_team_memberships/${id}`,
    {
      body: JSON.stringify(params),
      contentType: 'application/json',
      responseKind: 'json'
    }
  )

  if (response.ok) {
    return await response.json
  } else {
    alert(JSON.stringify(await response.json), 'danger')
    return null
  }
}

const userSearchConfidentialCrisisTeamMembership = async params => {
  const url =
    '/api/web/confidential_crisis_team_memberships/user_search?' +
    new URLSearchParams(params)

  const response = await get(url)

  return response.json
}

export {
  destroyConfidentialCrisisTeamMembership,
  getConfidentialCrisisTeamMemberships,
  createConfidentialCrisisTeamMembership,
  updateConfidentialCrisisTeamMembership,
  userSearchConfidentialCrisisTeamMembership
}
