import React from 'react'
import PropTypes from 'prop-types'
import Chart from './chart'
import SummaryTable from './summary-table'
import StudentFilters from 'components/shared/student-filters'
import { AbsoluteSpinner } from 'components/shared/spinner'
import { convertKeysToSnake, titleize } from 'utils'
import withStudentsTable, {
  withStudentsTablePropTypes
} from '../shared/hoc-with-students-table'
import { optionsPropTypes } from 'utils/contexts/options'
import { connect } from 'react-redux'
import { userPropTypes } from 'modules/current-user'
import { post } from 'utils/ajax'
import Page from 'components/shared/page'
import PageTitle from 'components/shared/page-title'
import ChartContainer from 'components/shared/chart-container'
import { selectors } from 'modules/schools'
import RouterLinkButton from '../../shared/router-link-button'

class DisciplineIndex extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['referrals', 'suspensions']),
    filters: PropTypes.array.isRequired,
    school: PropTypes.object,
    ...withStudentsTablePropTypes,
    ...optionsPropTypes,
    ...userPropTypes
  }

  state = {
    tableFilters: {}
  }

  datasetParams = (datasets = null) => {
    // convert param keys to snake-case for API (eg. schoolYear -> school_year)
    const datasetParams = (datasets || this.props.filters).map(dataset => {
      return convertKeysToSnake(dataset)
    })

    return {
      datasets: datasetParams
    }
  }

  submit = () => {
    this.setState({ loading: true })
    const params = this.datasetParams(this.props.filters)

    this.props
      .fetchDatasetNames(params)
      .then(() => this.fetchChartData(params))
      .then(() => this.fetchSummaryTableData(params))
      .then(() => this.setState({ loading: false }))
      .catch(err => {
        this.setState({ loading: false })
        alert(`An error occurred fetching data: ${err.message}`)
      })
  }

  fetchChartData = params => {
    const { type } = this.props
    return post(`/api/web/${type}/percentages_by_day_chart_data`, params).then(
      data => {
        this.setState({
          percentagesByDayData: data.data,
          percentagesByDaySchoolYear: data.year // only populated if all same
        })
      }
    )
  }

  fetchSummaryTableData = params => {
    const { type } = this.props
    params.filters = convertKeysToSnake(this.state.tableFilters)

    return post(`/api/web/${type}/summary_table_data`, params).then(data => {
      this.setState({
        summaryTableData: data.data,
        summaryTableSchoolYear: data.year // only populated if all same
      })
      return data.data
    })
  }

  onTableFilterChanged = updatedFilter => {
    return new Promise(resolve => {
      this.setState(
        {
          tableFilters: {
            ...this.state.tableFilters,
            ...updatedFilter
          }
        },
        () => {
          resolve(this.fetchSummaryTableData(this.datasetParams()))
        }
      )
    })
  }

  render() {
    const { type, school, user } = this.props

    const {
      percentagesByDayData,
      percentagesByDaySchoolYear,
      summaryTableData,
      summaryTableSchoolYear,
      loading,
      tableFilters
    } = this.state

    const title = titleize(type)

    return (
      <Page name={title} title={`${title} – ${school && school.name}`}>
        <StudentFilters submit={this.submit} />

        <PageTitle title={title} />

        {user.role !== 'partner' && (
          <RouterLinkButton
            to={`/students?school_id=${school.id}&view=Discipline`}
          >
            See Student Data
          </RouterLinkButton>
        )}

        <div style={styles.container}>
          {loading && <AbsoluteSpinner size={50} />}
          <div style={loading ? styles.faded : null}>
            <ChartContainer>
              <Chart
                type={type}
                data={percentagesByDayData}
                schoolYear={percentagesByDaySchoolYear}
                schoolName={school && school.name}
              />
            </ChartContainer>
            {summaryTableData && (
              <SummaryTable
                type={type}
                filters={tableFilters}
                data={summaryTableData}
                onFilterChanged={this.onTableFilterChanged}
                schoolYear={summaryTableSchoolYear}
              />
            )}
          </div>
        </div>
      </Page>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    position: 'relative'
  },
  faded: {
    opacity: 0.6
  }
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  school: selectors.currentSchool(state),
  filters: state.studentFilters.filters
})

export default connect(mapStateToProps)(withStudentsTable(DisciplineIndex))
