import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'
import Dialog from 'components/shared/dialog'
import { DatePicker } from '@mui/x-date-pickers'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { createSupport, updateSupport } from 'api/wellness'
import moment from 'moment'
import { getInterventionTypesForConcern } from 'api/interventions'

function SupportModal({ concern, setShow, show, support }) {
  const queryClient = useQueryClient()
  const [interventionTypeId, setInterventionTypeId] = useState(
    support?.intervention_type_id || ''
  )
  const [date, setDate] = useState(
    moment(support?.date).toDate() || moment().toDate()
  )
  const [note, setNote] = useState(support?.content || '')

  const handleClose = () => setShow(false)

  const resetForm = () => {
    setInterventionTypeId('')
    setDate(moment().toDate())
    setNote('')
  }
  const { data: interventionTypeOptions } = useQuery(
    ['intervention-types', concern.concern_type_id],
    async () => {
      const res = await getInterventionTypesForConcern({
        level: ['middle', 'high'].includes(concern.school.category)
          ? 'secondary'
          : 'elementary',
        concern_type_id: concern.concern_type_id
      })
      const options = res.data.map(t => ({
        label: t.name,
        value: t.id,
        is_archived: t.is_archived
      }))
      return options
    },
    {
      initialData: []
    }
  )

  const newSupportMutation = useMutation(params => createSupport(params), {
    onSuccess: () => {
      resetForm()
      queryClient.invalidateQueries(['concern', concern.id])
    },
    onSettled: () => {
      handleClose()
    }
  })

  const updateSupportMutation = useMutation(
    params => updateSupport(support.id, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['concern', concern.id])
      },
      onSettled: () => {
        handleClose()
      }
    }
  )

  const handleSubmit = event => {
    event.preventDefault()

    const params = {
      concern_id: concern.id,
      intervention_type_id: interventionTypeId,
      date,
      content: note.trim()
    }
    if (support) {
      updateSupportMutation.mutate(params)
    } else {
      newSupportMutation.mutate(params)
    }
  }

  const filteredInterventionTypeOptions = interventionTypeOptions.filter(
    t => !t.is_archived || t.value === support?.intervention_type_id
  )

  return (
    <Dialog
      title={`${support ? 'Edit' : 'New'} Support`}
      open={show}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack>
            <DatePicker
              renderInput={props => <TextField {...props} />}
              disableToolbar
              required
              fullWidth
              inputFormat="MM/DD/YYYY"
              inputVariant="outlined"
              margin="normal"
              id="date-event-on"
              label="Date"
              value={date}
              onChange={val => setDate(val)}
              autoOk={true}
            />

            <FormControl fullWidth>
              <InputLabel id="support-label">Support</InputLabel>
              <Select
                labelId="support-label"
                id="support"
                label="Support"
                onChange={val => setInterventionTypeId(val.target.value)}
                value={interventionTypeId}
                variant="outlined"
                required
              >
                {filteredInterventionTypeOptions.map(option => (
                  <MenuItem
                    key={`interventionType-${option.value}`}
                    value={option.value}
                  >
                    {option.label}
                    {option.is_archived && (
                      <span style={{ marginLeft: '0.5em', color: '#aaa' }}>
                        (archived)
                      </span>
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="note"
              label="Note (Optional)"
              multiline
              fullWidth
              variant="outlined"
              rows={5}
              defaultValue={note}
              onChange={val => setNote(val.target.value.replace(/^\s+/, ''))}
              helperText={'Background for this support'}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!interventionTypeId}
            type={'submit'}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

SupportModal.propTypes = {
  concern: PropTypes.object.isRequired,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  support: PropTypes.object
}

export default SupportModal
