import React from 'react'
import PropTypes from 'prop-types'
import BulletedListItem from './bulleted-list-item'
import { developmentAreaShape } from './prop-types'
import List from '@mui/material/List'
import { sortBy } from 'lodash'
import Typography from '@mui/material/Typography'

function DevelopmentAreaPrompt({ developmentArea, masteryLevelId }) {
  const indicatorByMasteryFilter =
    masteryLevelId =>
    ({ mastery_level }) =>
      mastery_level.id === masteryLevelId

  const indicators = developmentArea.indicators.filter(
    indicatorByMasteryFilter(masteryLevelId)
  )
  const weightedIndicators = sortBy(indicators, 'weight')

  return (
    <React.Fragment>
      <Typography variant={'overline'} sx={{ lineHeight: 1.2 }}>
        {developmentArea.prompt}
      </Typography>
      <List>
        {weightedIndicators.map(({ id, description }) => (
          <BulletedListItem key={id}>{description}</BulletedListItem>
        ))}
      </List>
    </React.Fragment>
  )
}

DevelopmentAreaPrompt.propTypes = {
  developmentArea: developmentAreaShape.isRequired,
  masteryLevelId: PropTypes.number
}

export default DevelopmentAreaPrompt
