// TODO: These need to come from app/models/teacher_feed_item.rb
const filterOptions = [
  {
    label: 'Assigned Intervention',
    value: 'assigned_intervention'
  },
  {
    label: 'IEP Status Changed',
    value: 'iep_status_changed'
  },
  {
    label: 'Meeting',
    value: 'meeting'
  },
  {
    label: 'New MAP Scores',
    value: 'new_map_scores'
  },
  {
    label: 'Note',
    value: 'note'
  },
  {
    label: 'Section High Failure Rate',
    value: 'section_high_failure_rate'
  },
  {
    label: 'Student Birthdays',
    value: 'student_birthdays'
  },
  {
    label: 'Student Chronically Absent',
    value: 'student_chronically_absent'
  },
  {
    label: 'Student Suspended',
    value: 'student_suspended'
  },
  {
    label: 'Suspension Last Day',
    value: 'suspension_last_day'
  },
  {
    label: 'Teacher Birthday',
    value: 'teacher_birthday'
  }
]

export default filterOptions
