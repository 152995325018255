import { get, post, patch } from 'utils/ajax'

export const fetchGoals = params => {
  return get('/api/admin/goals', { params })
}

export const updateGoal = params => {
  return patch(`/api/admin/goals/${params.id}`, {
    goal: params
  })
}

export const createGoal = params => {
  return post('/api/admin/goals', {
    goal: params
  })
}
