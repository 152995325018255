import React from 'react'
import { titleize } from 'utils'
import PropTypes from 'prop-types'
import { updateGoalSetting } from 'api/admin/goal_settings'
import {
  Button,
  FormControlLabel,
  TableCell,
  TableRow,
  Switch
} from '@mui/material'
import { alert, Severity } from 'utils/alert'

export default class GoalSettingForm extends React.Component {
  static propTypes = {
    goalSettingId: PropTypes.number.isRequired,
    enabledType: PropTypes.bool.isRequired,
    enabledValue: PropTypes.bool.isRequired,
    enabledComment: PropTypes.bool.isRequired,
    category: PropTypes.string.isRequired,
    fetchGoalSettings: PropTypes.func.isRequired,
    year: PropTypes.number.isRequired
  }

  state = {
    goalSettingId: this.props.goalSettingId,
    enabledValue: this.props.enabledValue,
    enabledType: this.props.enabledType,
    enabledComment: this.props.enabledComment,
    hasChanges: false
  }

  handleGoalSettingChange = (identifier, value) => {
    this.setState({ [identifier]: value, hasChanges: true }, () => {
      const params = {
        id: this.state.goalSettingId,
        [identifier]: value
      }

      updateGoalSetting(params)
        .then(() => {
          this.props.fetchGoalSettings(this.props.year)
        })
        .catch(() => {
          alert('There was an error saving the changes.', Severity.ERROR)
        })
    })
  }

  handleSave = async () => {
    const { goalSettingId, enabledValue, enabledType, enabledComment } =
      this.state
    const params = {
      id: goalSettingId,
      enabled_value: enabledValue,
      enabled_type: enabledType,
      enabled_comment: enabledComment
    }

    try {
      await updateGoalSetting(params)
      this.setState({ hasChanges: false })
      this.props.fetchGoalSettings(this.props.year)
      alert('Changes saved successfully.', Severity.SUCCESS)
    } catch (error) {
      alert('There was an error saving the changes.', Severity.ERROR)
    }
  }

  render() {
    const { category } = this.props
    const { enabledValue, enabledType, enabledComment, hasChanges } = this.state

    return (
      <TableRow data-testid={`goal-setting-${this.props.goalSettingId}`}>
        <TableCell>{titleize(category)}</TableCell>
        <TableCell>
          <FormControlLabel
            control={
              <Switch
                checked={enabledValue}
                onChange={event =>
                  this.handleGoalSettingChange(
                    'enabledValue',
                    event.target.checked
                  )
                }
                data-testid={`value-switch-${this.props.goalSettingId}`}
              />
            }
            label=""
          />
        </TableCell>
        <TableCell>
          <FormControlLabel
            control={
              <Switch
                checked={enabledType}
                onChange={event =>
                  this.handleGoalSettingChange(
                    'enabledType',
                    event.target.checked
                  )
                }
                data-testid={`type-switch-${this.props.goalSettingId}`}
              />
            }
            label=""
          />
        </TableCell>
        <TableCell>
          <FormControlLabel
            control={
              <Switch
                checked={enabledComment}
                onChange={event =>
                  this.handleGoalSettingChange(
                    'enabledComment',
                    event.target.checked
                  )
                }
                data-testid={`comment-switch-${this.props.goalSettingId}`}
              />
            }
            label=""
          />
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleSave}
            disabled={!hasChanges}
            data-testid={`save-button-${this.props.goalSettingId}`}
          >
            Save Changes
          </Button>
        </TableCell>
      </TableRow>
    )
  }
}
