export const types = {
  DELETE_CHILD_STUDY: 'child_studies/fetch_child_study',
  FETCH_ELIGIBLE_CHILD_STUDY_STUDENTS:
    'child_studies/fetch_eligible_child_study_students'
}

const initialState = {
  childStudiesList: [],
  eligibleChildStudyStudents: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_ELIGIBLE_CHILD_STUDY_STUDENTS:
      return {
        ...state,
        eligibleChildStudyStudents: action.payload.students
      }
    case types.DELETE_CHILD_STUDY:
      return {
        childStudiesList: state.childStudiesList.filter(
          childStudy => childStudy.id !== action.payload.childStudyId
        ),
        eligibleChildStudyStudents: state.eligibleChildStudyStudents.filter(
          student => student.value !== action.payload.studentId
        )
      }
    default:
      return state
  }
}
