import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Chip, Button, Typography, Stack, DialogContent } from '@mui/material'
import Dialog from 'shared/dialog'
import GraduationStatusRiskTable from './graduation-status-risk-table'

export function GraduationStatusLegendDialog({ children }) {
  const [modalOpen, setModalOpen] = useState(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)
  return (
    <>
      {typeof children === 'function' ? children({ openModal, closeModal }) : (children || null)}

      <Dialog
        open={modalOpen}
        onClose={closeModal}
        title={'How is the graduation status determined?'}
      >
        <DialogContent>
          <Typography variant="h4">
            Graduation Status Risk Calculation
          </Typography>
          <Typography variant="body1">
            The graduation status calculation compares a student&#39;s current
            credit accumulation with what we would expect them to have earned by
            that given semester and academic year. Their status is based on the
            severity of their credit deficiency. This is determined via a
            calculation that evaluates how many credits they are deficient as
            well as the type of credits they are deficient in. Once the
            student&#39;s credits are calculated, the following thresholds
            determine the status:
          </Typography>
          <GraduationStatusRiskTable />
        </DialogContent>
      </Dialog>
    </>
  )
}
GraduationStatusLegendDialog.propTypes = {
  children: PropTypes.any
}

export default function GraduationStatusLegend() {
  return (
    <Stack spacing={1}>
      <Typography variant="h6">Graduation Status Key</Typography>
      <Stack direction={'row'} spacing={1}>
        <Chip color="success" label="On Track" />
        <Chip color="secondary" label="Off Track" />
        <Chip color="warning" label="At Risk" />
        <Chip color="error" label="High Risk" />
      </Stack>
      <GraduationStatusLegendDialog>
        {({ openModal }) => (
          <Typography variant="body2"><Button onClick={openModal}>How is this calculated?</Button></Typography>
        )}
      </GraduationStatusLegendDialog>
    </Stack>
  )
}
