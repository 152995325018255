import { keyBy } from 'lodash'

export const types = {
  FETCH_JOBS: 'jobs/fetch_jobs',
  FETCH_JOB_ROLES: 'jobs/fetch_job_roles'
}

const initialState = {
  byId: {},
  meta: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_JOBS:
      return {
        ...state,
        byId: keyBy(action.payload.data, 'id'),
        meta: action.payload.meta
      }
    case types.FETCH_JOB_ROLES:
      return {
        ...state,
        byRole: action.payload.job_roles
      }
    default:
      return state
  }
}
