import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function SchoolSelect({ schoolId, schoolOptions, schoolChanged }) {
  return (
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="school-select-label">Schools</InputLabel>
      <Select
        labelId="schools-select-label"
        id="schools-select"
        value={schoolId}
        label="School"
        onChange={e => schoolChanged(e.target.value)}
      >
        {schoolOptions.map(s => (
          <MenuItem key={`school-option-${s.value}`} value={s.value}>
            {s.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SchoolSelect.propTypes = {
  schoolId: PropTypes.number.isRequired,
  schoolChanged: PropTypes.func.isRequired,
  schoolOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
}

export default SchoolSelect
