import React from 'react'
import PropTypes from 'prop-types'

/**
 * A simple cell renderer for React Table.
 *
 * Adding a span/title over the value provides a browser native hover of the content that might
 * otherwise be hidden behind `…` in a narrow column.
 *
 * This component assumes basic accessor usage in the React Table column definition.
 *
 * @param {*} props
 */
function SimpleCell({ value }) {
  return <span title={value}>{value}</span>
}

SimpleCell.propTypes = {
  value: PropTypes.any.isRequired
}

export default SimpleCell
