import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardHeader,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'

export default function ChartOptions({ type, resolution, onOptionChanged }) {
  const typeChanged = (event, chartType) => {
    if (chartType !== null) {
      const options = { chartType }
      if (chartType === 'chronic') {
        options.chartResolution = 'day'
      }
      onOptionChanged(options)
    }
  }

  const resolutionChanged = (event, val) => {
    if (val !== null) {
      onOptionChanged({ chartResolution: val })
    }
  }

  return (
    <Box
      sx={{
        mt: 2
      }}
    >
      <Card>
        <CardHeader
          title={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <Typography variant="subtitle1">Chart Options</Typography>
              <Box
                sx={{
                  ml: 2
                }}
              >
                <ToggleButtonGroup
                  exclusive
                  value={type}
                  onChange={typeChanged}
                  aria-label="chart type"
                >
                  <ToggleButton
                    size="small"
                    value="ada_cumulative"
                    aria-label="cumulative ada"
                    style={{ whiteSpace: 'nowrap' }}
                  >
                    Cumulative ADA
                  </ToggleButton>
                  <ToggleButton size="small" value="ada" aria-label="ada">
                    ADA
                  </ToggleButton>
                  <ToggleButton
                    size="small"
                    value="chronic"
                    aria-label="chronic"
                  >
                    Chronic
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box
                sx={{
                  ml: 2
                }}
              >
                <ToggleButtonGroup
                  exclusive
                  value={resolution}
                  onChange={resolutionChanged}
                  aria-label="chart resolution"
                >
                  <ToggleButton
                    size="small"
                    value="month"
                    aria-label="monthly"
                    disabled={type === 'chronic'}
                  >
                    Monthly
                  </ToggleButton>
                  <ToggleButton
                    size="small"
                    value="week"
                    aria-label="weekly"
                    disabled={type === 'chronic'}
                  >
                    Weekly
                  </ToggleButton>
                  <ToggleButton size="small" value="day" aria-label="daily">
                    Daily
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            </Box>
          }
        />
      </Card>
    </Box>
  )
}

ChartOptions.propTypes = {
  type: PropTypes.string,
  resolution: PropTypes.string,
  onOptionChanged: PropTypes.func
}
