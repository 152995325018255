import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import TaskAltIcon from '@mui/icons-material/TaskAlt'

function InterventionTypeOptionCard({
  option,
  checked,
  setInterventionType,
  changeIntervention
}) {
  const handleClick = () => {
    if (checked) {
      setInterventionType(null)
      changeIntervention({
        targeted_intervention_type_id: null
      })
    } else {
      setInterventionType(option.value)
      changeIntervention({
        targeted_intervention_type_id: option.value
      })
    }
  }

  return (
    <Card
      onClick={() => handleClick()}
      sx={theme => ({
        'cursor': 'pointer',
        'borderStyle': 'solid',
        'borderWidth': 2,
        'borderColor': checked ? theme.palette.primary.main : 'white',
        '&:hover': {
          borderColor: checked ? undefined : theme.palette.primary.grey
        }
      })}
    >
      <CardContent>
        <Grid
          container
          wrap={'nowrap'}
          columnSpacing={2}
          justifyContent={'space-between'}
        >
          <Grid item flexGrow={1}>
            <Typography>{option.label}</Typography>
            <Typography sx={{ lineHeight: 1.2, fontSize: '85%', marginTop: 2 }}>
              {option.description}
            </Typography>
          </Grid>
          <Grid item>
            {checked ? (
              <TaskAltIcon
                fontSize={'large'}
                sx={theme => ({ color: theme.palette.primary.main })}
              />
            ) : (
              <RadioButtonUncheckedIcon
                fontSize={'large'}
                sx={theme => ({ color: theme.palette.primary.grey })}
              />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

InterventionTypeOptionCard.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  setInterventionType: PropTypes.func.isRequired,
  changeIntervention: PropTypes.func.isRequired
}

export default InterventionTypeOptionCard
