import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Button, Chip, Box } from '@mui/material'
import { RemoteTable } from 'components/shared/table'
import { updateSystemStatus } from 'api/admin/system_statuses'

export default class SystemStatusesTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    fetchData: PropTypes.func,
    loading: PropTypes.bool,
    totalPages: PropTypes.number
  }

  handleUpdateSystemStatus = params => {
    let updatedParams = { ...params, active: false }
    const { fetchData } = this.props
    updateSystemStatus(updatedParams).then(() => {
      fetchData()
    })
  }

  columns = () => {
    return [
      {
        id: 'title',
        Header: 'Title',
        accessor: 'title'
      },
      {
        id: 'description',
        Header: 'Description',
        accessor: 'description'
      },
      {
        id: 'expiration_date',
        Header: 'Expiration Date',
        accessor: 'expiration_date',
        Cell: props => moment(props.value).format('MM/DD/YYYY')
      },
      {
        id: 'active',
        Header: 'Active',
        accessor: 'active',
        Cell: props => (
          <Chip
            color={props.value === true ? 'success' : 'default'}
            label={props.value === true ? 'active' : 'inactive'}
          />
        )
      },
      {
        id: 'actions',
        Header: 'Actions',
        Cell: props => (
          <Button
            id={`mark-as-complete-${props.id}`}
            disabled={!props.original.active}
            variant="contained"
            onClick={() => this.handleUpdateSystemStatus(props.original)}
          >
            Mark as Complete
          </Button>
        )
      }
    ]
  }

  render() {
    const { data, loading, fetchData, totalPages, ...props } = this.props

    return (
      <Box id="system-status-table">
        <RemoteTable
          columns={this.columns()}
          data={data}
          loading={loading}
          onFetchData={params => fetchData(params)}
          pages={totalPages}
          {...props}
        />
      </Box>
    )
  }
}
