import React from 'react'
import Page from 'components/shared/page'
import PropTypes from 'prop-types'
import StudentInfo from './info'
import StudentFeed from './feed'
import Grid from '@mui/material/Grid'

export function Overview({ student }) {
  return (
    <Page name="Overview" title={student.name}>
      <Grid container spacing={4}>
        <Grid item md={4}>
          <StudentInfo studentId={student.id} />
        </Grid>
        <Grid item md={8}>
          <StudentFeed studentId={student.id} />
        </Grid>
      </Grid>
    </Page>
  )
}

Overview.propTypes = {
  student: PropTypes.object.isRequired
}

export default Overview
