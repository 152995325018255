import React from 'react'
import { titleize } from 'utils'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import JobModal from './modal.jsx'
import { RemoteTable } from 'components/shared/table'
import { Box, IconButton, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'

export default class AdminJobsTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    fetchData: PropTypes.func,
    loading: PropTypes.bool,
    totalPages: PropTypes.number
  }

  state = {
    openModal: false,
    activeJob: {}
  }

  handleModalHide = () => {
    this.setState({ openModal: false })
  }

  openModalWithJob = job => {
    this.setState({
      activeJob: job,
      openModal: true
    })
  }

  columns = () => {
    return [
      {
        id: 'title',
        Header: 'Job Title',
        accessor: obj => obj,
        Cell: props => (
          <Link
            to={{
              pathname: `/admins/jobs/id/${props.value.id}`
            }}
          >
            {props.value.title}
          </Link>
        )
      },
      {
        id: 'code',
        Header: 'Job Code',
        accessor: obj => obj.code
      },
      {
        id: 'role',
        Header: 'Job Role',
        accessor: obj => obj.role,
        Cell: props => titleize(props.value)
      },
      {
        id: 'users_with_job_title',
        Header: 'Active Users With Job Title',
        accessor: obj => obj.users_with_job_title
      },
      {
        id: 'all_schools',
        Header: 'All Schools?',
        accessor: obj => obj.all_schools,
        Cell: props => (props.value === true ? 'True' : 'False')
      },
      {
        id: 'actions',
        Header: 'Actions',
        accessor: obj => obj,
        Cell: props => (
          <Box id="react-jobs-selector">
            <Tooltip
              arrow
              id="edit-job-role"
              title="Edit Job Role"
              placement="top"
            >
              <IconButton
                id={`js-admin-view-job-${props.value.id}`}
                onClick={() => this.openModalWithJob(props.value)}
                color="secondary"
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )
      }
    ]
  }

  render() {
    const { data, loading, fetchData, totalPages, ...props } = this.props
    const { activeJob, openModal } = this.state
    return (
      <div id="job-table">
        <JobModal
          key={activeJob.id}
          show={openModal}
          job={activeJob}
          showAllFields={false}
          onHide={() => this.handleModalHide()}
          fetchData={fetchData}
        />
        <RemoteTable
          columns={this.columns()}
          data={data}
          loading={loading}
          onFetchData={params => fetchData(params)}
          pages={totalPages}
          {...props}
        />
      </div>
    )
  }
}
