import React from 'react'
import { debounce } from 'lodash'
import AdminGoalsTable from './table'
import { fetchGoals } from 'api/admin/goals'
import Page from 'components/shared/page'
import NewGoalModal from './new-goal-modal'
import GoalSettings from './../goal_settings/index'
import DistrictGoals from './../district_goals/index'
import { getReactSelectFormat } from 'utils/react-table-helpers'
import PageContainer from 'components/shared/admin-page-container'
import {
  Box,
  Button,
  Stack,
  FormControl,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  InputAdornment
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import SearchIcon from '@mui/icons-material/Search'

export default class AdminGoals extends React.Component {
  state = {
    openModal: false,
    isLoading: true,
    goals: [],
    pages: null,
    search: '',
    goalType: ''
  }

  componentDidMount() {
    this.handleFetchGoals()
  }

  searchChanged = e => {
    const search = e.target.value
    this.setState({ search }, () => {
      this.handleFetchGoals()
    })
  }

  goalTypeChanged = e => {
    const goalType = e.target.value
    this.setState({ goalType }, () => {
      this.handleFetchGoals()
    })
  }

  handleFetchGoals = debounce(
    attrs => {
      this.setState({ isLoading: true })

      const params = {
        search: this.state.search,
        goal_type: this.state.goalType,
        ...attrs
      }
      fetchGoals(params)
        .then(res => {
          this.setState({
            goals: res.data,
            pages: res.meta.pages,
            allGoalCategories: res.goal_categories,
            allGoalTypes: res.goal_types,
            isLoading: false
          })
          return res.data
        })
        .catch(() => {
          this.setState({ isLoading: false })
          alert('Failed to fetch goals :(')
        })
    },
    300,
    { leading: false, trailing: true }
  )

  handleModalHide = () => {
    this.setState({ openModal: false })
  }

  openModalForNewGoal = () => {
    this.setState({
      openModal: true
    })
  }

  render() {
    if (!this.state.goals) {
      return <div />
    }

    const {
      isLoading,
      pages,
      search,
      goals,
      goalType,
      openModal,
      allGoalTypes,
      allGoalCategories
    } = this.state

    return (
      <Page name="Admin-Goals" title="All Goals">
        {allGoalTypes && (
          <PageContainer>
            <Stack spacing={2}>
              <Typography variant="h1">Goals</Typography>
              <Box>
                <form>
                  <Stack spacing={2}>
                    <FormControl fullWidth>
                      <InputLabel id="search-label">Search</InputLabel>
                      <OutlinedInput
                        id="search"
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        }
                        label="Search"
                        value={search}
                        onChange={this.searchChanged}
                      />
                    </FormControl>

                    <FormControl fullWidth>
                      <InputLabel id="goal-type-label">Goal Type</InputLabel>
                      <Select
                        id="goal-type"
                        labelId="goal-type-label"
                        label="Goal Type"
                        value={goalType}
                        onChange={this.goalTypeChanged}
                      >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="incremental_or_maintain">
                          Incremental or Maintain
                        </MenuItem>
                        <MenuItem value="moderate">Moderate</MenuItem>
                        <MenuItem value="aggressive">Aggresive</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </form>
              </Box>

              <Box>
                <Stack direction="row" spacing={2}>
                  <DistrictGoals />
                  <GoalSettings />
                  <Button
                    id="js-new-goal-link"
                    variant="contained"
                    onClick={this.openModalForNewGoal}
                    startIcon={<AddIcon />}
                  >
                    New Goal
                  </Button>
                </Stack>
              </Box>

              <Box>
                <AdminGoalsTable
                  data={goals}
                  totalPages={pages}
                  loading={isLoading}
                  fetchData={this.handleFetchGoals}
                  goalTypes={getReactSelectFormat(allGoalTypes)}
                  goalCategories={getReactSelectFormat(allGoalCategories)}
                />
              </Box>
            </Stack>
          </PageContainer>
        )}

        {allGoalTypes && (
          <NewGoalModal
            show={openModal}
            onHide={this.handleModalHide}
            fetchData={this.handleFetchGoals}
            goalTypes={getReactSelectFormat(allGoalTypes)}
            goalCategories={getReactSelectFormat(allGoalCategories)}
          />
        )}
      </Page>
    )
  }
}
