import React from 'react'
import PropTypes from 'prop-types'
import FilterContainer from 'components/shared/filter-container'
import SectionFilter from 'components/shared/filters/sections-select'
import SchoolFilter from '../sections/filters/school-select.jsx'
import SlipCompliance from 'components/shared/slip-compliance'

function TeacherSlipCompliance({
  sectionId,
  sectionOptions,
  schools,
  schoolOptions,
  schoolId,
  sectionChanged,
  schoolChanged,
  teacher
}) {
  return (
    <React.Fragment>
      <FilterContainer>
        <SectionFilter
          section={sectionId}
          sectionChanged={sectionChanged}
          sectionOptions={sectionOptions}
        />
        {schools.length > 1 && (
          <SchoolFilter
            schoolId={schoolId}
            schoolOptions={schoolOptions}
            schoolChanged={schoolChanged}
          />
        )}
      </FilterContainer>

      <SlipCompliance
        schoolId={schools.length > 1 ? schoolId : schools[0].id}
        sectionId={sectionId}
        teacherId={teacher.id}
      />
    </React.Fragment>
  )
}

TeacherSlipCompliance.propTypes = {
  sectionId: PropTypes.number,
  sectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number
    })
  ).isRequired,
  schools: PropTypes.array.isRequired,
  schoolOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number
    })
  ).isRequired,
  schoolId: PropTypes.number,
  sectionChanged: PropTypes.func.isRequired,
  schoolChanged: PropTypes.func.isRequired,
  teacher: PropTypes.object.isRequired
}

export default TeacherSlipCompliance
