import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'

function ConcernChildrenDropdown({
  value,
  onChange,
  options,
  isSubmitted,
  multiple = false,
  label = 'Concern Type',
  required = true,
  hasInterventions = false
}) {
  const isRequired = options.length > 0 && required
  const isDisabled = options.length === 0 || hasInterventions
  const isError = isSubmitted && !value

  return (
    <FormControl
      error={isError}
      fullWidth
      required={isRequired}
      disabled={isDisabled}
    >
      <InputLabel id="concern-type-select" data-testid="concern-type-label">
        {label}
      </InputLabel>
      <Select
        labelId="concern-type-select"
        data-testid="concern-type-select"
        label={label}
        value={value}
        onChange={val => onChange(val.target.value)}
        multiple={multiple}
      >
        {options.map((option, i) => (
          <MenuItem
            data-testid={`concern-child-${option.value}`}
            key={`concern-child-${i}`}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {isError && <FormHelperText>Concern Type is Required</FormHelperText>}
    </FormControl>
  )
}

ConcernChildrenDropdown.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.oneOf(['']),
    PropTypes.number,
    PropTypes.array
  ]),
  onChange: PropTypes.func,
  options: PropTypes.array,
  isSubmitted: PropTypes.bool,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  hasInterventions: PropTypes.bool
}

export default ConcernChildrenDropdown
