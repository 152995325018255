import React, { useState } from 'react'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import { selectors } from 'modules/schools'
import TeachersTable from './table'
import TeachersCsvLink from './teachers-csv-link'
import { fetchAllTeachers } from 'api/teachers'
import { useSelector } from 'react-redux'
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import SchoolAutocomplete from '../shared/school-autocomplete'

function Teachers() {
  const [search, setSearch] = useState('')
  const schoolId = useSelector(state => state.schools.schoolId)
  const schools = useSelector(state => state.schools.byId)
  const school = schools[schoolId]

  const schoolOptions = useSelector(state => selectors.schoolOptions(state))

  return (
    <Page name="Teachers" title="Teachers">
      <PageContainer>
        <Box>
          {schoolOptions.length > 1 && (
            <Stack direction="row" justifyContent="flex-end">
              <Box
                id="test-school-selector"
                sx={{
                  minWidth: '20rem'
                }}
              >
                <SchoolAutocomplete disableClearable includeAllSchoolsOption />
              </Box>
            </Stack>
          )}
        </Box>

        <Box
          sx={{
            mb: 6
          }}
        >
          <Typography variant="h1">Teachers</Typography>
          <Typography variant="h2">{school.name}</Typography>
        </Box>

        <Stack>
          <Box>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs={10}>
                <form onSubmit={e => e.preventDefault()}>
                  <FormControl fullWidth sx={{ my: 2 }}>
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Search
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      label="Search"
                      value={search}
                      onChange={e => {
                        setSearch(e.target.value)
                      }}
                    />
                  </FormControl>
                </form>
              </Grid>
              <Grid item>
                <TeachersCsvLink
                  schoolId={schoolId}
                  fetchAllTeachers={fetchAllTeachers}
                />
              </Grid>
            </Grid>
          </Box>

          <Box>
            <TeachersTable {...{ search, schoolId }} />
          </Box>
        </Stack>
      </PageContainer>
    </Page>
  )
}

export default Teachers
