import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import {
  createInterventionScore,
  updateInterventionScore
} from 'api/intervention-scores'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DatePicker } from '@mui/x-date-pickers'
import { isNull } from 'lodash'
import Button from '@mui/material/Button'
import Dialog from 'components/shared/dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Stack from '@mui/material/Stack'

function ScoreDialog({ interventionId, previousScore, show, handleClose }) {
  const queryClient = useQueryClient()
  const emptyScore = {
    id: null,
    intervention_id: interventionId,
    event_on: moment(),
    score: '',
    notes: ''
  }

  const [score, setScore] = useState(previousScore || emptyScore)

  const newInterventionScore = useMutation(
    () => createInterventionScore({ params: score }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['intervention', interventionId])
        queryClient.invalidateQueries([
          `interventions/${interventionId}/scores`
        ])
        setScore(emptyScore)
      },
      onSettled: () => {
        handleClose()
      }
    }
  )

  const updateScore = useMutation(
    () => updateInterventionScore({ params: score }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['intervention', interventionId])
        queryClient.invalidateQueries([
          `interventions/${interventionId}/scores`
        ])
      },
      onSettled: () => {
        handleClose()
      }
    }
  )

  const submitForm = e => {
    e.preventDefault()

    if (isNull(score.id)) {
      newInterventionScore.mutate()
    } else {
      updateScore.mutate()
    }
  }

  return (
    <Dialog
      open={show}
      onClose={() => handleClose()}
      title={`${score?.id ? 'Edit' : 'Add'} Measurement`}
    >
      <DialogContent>
        <Box
          component="form"
          sx={{
            m: 4,
            mb: 6
          }}
        >
          <Stack>
            <DatePicker
              renderInput={props => <TextField {...props} />}
              disableToolbar
              required
              fullWidth
              inputFormat="MM/DD/YYYY"
              inputVariant="inline"
              margin="normal"
              id="date-event-on"
              label="Date"
              value={moment(score.event_on).toDate()}
              onChange={val => setScore({ ...score, event_on: val })}
            />
            <TextField
              type="number"
              required
              fullWidth
              id="score-score"
              label="Value"
              variant="outlined"
              value={score.score}
              helperText={!score.score ? 'Value is Required' : ''}
              onChange={e => setScore({ ...score, score: e.target.value })}
            />
            <TextField
              fullWidth
              multiline
              rows={3}
              id="score-notes"
              label="Note (Optional)"
              variant="outlined"
              value={score.notes}
              onChange={e => setScore({ ...score, notes: e.target.value })}
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>
          Close
        </Button>
        <Button
          disabled={!score.score}
          variant="contained"
          color="primary"
          onClick={e => submitForm(e)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ScoreDialog.propTypes = {
  interventionId: PropTypes.number.isRequired,
  previousScore: PropTypes.object,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default ScoreDialog
