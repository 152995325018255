import { get, patch } from 'utils/ajax'

export const fetchDistrictGoals = params => {
  return get('/api/admin/district_goals', { params })
}

export const updateDistrictGoal = params => {
  return patch(`/api/admin/district_goals/${params.id}`, {
    district_goal: params
  })
}
