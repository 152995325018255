import React from 'react'
import PropTypes from 'prop-types'
import { ListItemText, ListItemButton, Typography, Stack } from '@mui/material'
import SchoolIcon from '@mui/icons-material/School'
import ListItemIcon from '@mui/material/ListItemIcon'

function StudentListItem({ searchResult, ...props }) {
  return (
    <ListItemButton {...props}>
      <ListItemIcon>
        <SchoolIcon />
      </ListItemIcon>
      <ListItemText
        primary={`${searchResult.searchable.first_name} ${searchResult.searchable.last_name}`}
        secondary={
          <Stack component={'span'} direction={'row'}>
            <Typography component={'span'}>Student</Typography>
            <Typography component={'span'}>
              {searchResult.searchable.number}
            </Typography>
            <Typography component={'span'}>
              {searchResult.searchable.grade_level}
            </Typography>
            <Typography component={'span'}>
              {searchResult.searchable.current_school}
            </Typography>
          </Stack>
        }
      />
    </ListItemButton>
  )
}

StudentListItem.propTypes = {
  searchResult: PropTypes.object.isRequired
}

export default StudentListItem
