import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import QuickFilters from './quick-filters'
import AdvancedFilters from './advanced-filters'
import { withOptions } from 'utils/contexts/options'
import { actions as filterActions } from 'modules/student-filters'
import FilterContainer from 'components/shared/filter-container'

class StudentFilters extends React.Component {
  static propTypes = {
    submit: PropTypes.func,
    filters: PropTypes.array.isRequired,
    filtersExpanded: PropTypes.bool.isRequired,
    toggleFiltersExpanded: PropTypes.func.isRequired
  }

  render() {
    const { toggleFiltersExpanded, submit } = this.props

    return (
      <FilterContainer topBorder={false}>
        <div className="StudentFilters StudentFilters--bg">
          <div>
            <QuickFilters submit={submit} />
            <AdvancedFilters
              toggleFiltersExpanded={toggleFiltersExpanded}
              submit={submit}
            />
          </div>
        </div>
      </FilterContainer>
    )
  }
}

const mapStateToProps = state => ({
  filtersExpanded: state.studentFilters.filtersExpanded,
  filters: state.studentFilters.filters
})

const mapDispatchToProps = {
  toggleFiltersExpanded: filterActions.toggleFiltersExpanded
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withOptions(StudentFilters))
