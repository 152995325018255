import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function SectionsSelect({ section, sectionChanged, sectionOptions }) {
  return (
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="sections-select-label">Sections</InputLabel>
      <Select
        labelId="sections-select-label"
        id="sections-select"
        value={section}
        label="Sections"
        placeholder="All Sections"
        onChange={e => sectionChanged(e.target.value)}
      >
        {sectionOptions.map(s => (
          <MenuItem key={`section-option-${s.value}`} value={s.value}>
            {s.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

SectionsSelect.propTypes = {
  section: PropTypes.number,
  sectionChanged: PropTypes.func.isRequired,
  sectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  )
}

export default SectionsSelect
