import React from 'react'
import PropTypes from 'prop-types'

// shared context that stores common options for form elements,
// eg. schools, ethnicities, etc.
// see documentation for contexts: https://reactjs.org/docs/context.html
// all of thes are in format { label: '', value: '' }
export const OptionsContext = React.createContext({
  schools: [],
  schoolYears: [],
  ethnicities: [],
  ells: [],
  current_academic_year: null,
  gradeLevels: {},
  availableYears: {}
})

export const withOptions = Component => {
  return function OptionsComponent(props) {
    return (
      <OptionsContext.Consumer>
        {options => <Component {...options} {...props} />}
      </OptionsContext.Consumer>
    )
  }
}

export const useOptions = () => React.useContext(OptionsContext)

export const optionsPropTypes = {
  schools: PropTypes.array,
  schoolYears: PropTypes.array,
  ethnicities: PropTypes.array,
  ells: PropTypes.array,
  current_academic_year: PropTypes.object,
  gradeLevels: PropTypes.object,
  availableYears: PropTypes.object
}
