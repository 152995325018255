import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { isNull, debounce } from 'lodash'
import { columnDefinitions } from './with-built-columns'
import {
  createColumnSet,
  deleteColumnSet,
  updateColumnSet
} from 'api/column-sets'
import { useMutation } from '@tanstack/react-query'
import ConfirmModalButton from 'components/shared/confirm-modal/button'
import { titleize } from 'utils'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material'
import { useColumnSets } from './column-sets'
import SimpleAutocomplete from '../simple-autocomplete'
import { GroupHeader, GroupItems } from '../school-autocomplete'

function DebouncedTextField({
  value: valueProp,
  onChange: onChangeProp,
  ...props
}) {
  const [value, setValue] = useState(valueProp)
  const onChangeDebounced = React.useCallback(debounce(onChangeProp, 300), [
    onChangeProp
  ])
  const onChange = event => {
    setValue(event.target.value)
    onChangeDebounced(event)
  }

  React.useEffect(() => {
    setValue(valueProp)
  }, [valueProp])

  return <TextField {...{ value, onChange }} {...props} />
}

DebouncedTextField.propTypes = TextField.propTypes

function TooltipIconButton({ tooltipProps = {}, ...props }) {
  return (
    <Tooltip enterDelay={1_000} {...tooltipProps}>
      <span>
        <IconButton size={'small'} {...props} />
      </span>
    </Tooltip>
  )
}

TooltipIconButton.propTypes = {
  tooltipProps: PropTypes.object,
  ...IconButton.propTypes
}

function Selector({ userId, columnSets, setView }) {
  return (
    <Box>
      <Button onClick={() => setView('create')}>Create a new table</Button>
      {columnSets.filter(columnSet => columnSet.user_id === userId).length >
        0 && (
        <React.Fragment>
          or
          <Button
            onClick={() => {
              setView('update')
            }}
          >
            Update an existing table
          </Button>
        </React.Fragment>
      )}
    </Box>
  )
}

Selector.propTypes = {
  userId: PropTypes.number.isRequired,
  columnSets: PropTypes.array.isRequired,
  setView: PropTypes.func.isRequired
}

function Content({
  setView,
  view,
  hideTemplateSelectorAndClearTemplateColumns,
  columnSets,
  showTemplateSelector,
  activeColumnSet,
  setActiveColumnSet,
  viewableToAllActiveColumnSets,
  myActiveColumnSets,
  myInactiveColumnSets,
  name,
  setName,
  hasNamingError,
  description,
  setDescription,
  hasAdminAccess,
  visibleToAll,
  setVisibleToAll,
  active,
  setActive,
  templateColumnSet,
  handleTemplateColumnChange,
  handleNewColumn,
  columnDefinitionOptions,
  children
}) {
  const columnOptions = columnDefinitionOptions
    .filter(column => column !== 'selector')
    .map(column => ({
      label: titleize(column),
      value: column
    }))

  const myActiveTemplateOptions = myActiveColumnSets.map(columnSet => ({
    label: columnSet.name,
    value: columnSet.id,
    group: 'My Active Table Views',
    columnSet
  }))

  const myInactiveTemplateOptions = myInactiveColumnSets.map(columnSet => ({
    label: columnSet.name,
    value: columnSet.id,
    group: 'My Inactive Table Views',
    columnSet
  }))

  const myTemplateOptions = myActiveTemplateOptions.concat(
    myInactiveTemplateOptions
  )

  const creatableTemplateOptions = viewableToAllActiveColumnSets
    .map(columnSet => ({
      label: columnSet.name,
      value: columnSet.id,
      group: 'Shared With You',
      columnSet
    }))
    .concat(myActiveTemplateOptions)

  const editableFields =
    view === 'create' || (view === 'update' && templateColumnSet)

  return (
    <Stack spacing={2}>
      <Box>
        <Button onClick={() => setView(null)}>Start Over</Button>
      </Box>

      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <Stack spacing={2} width={{ md: '50%' }}>
          {columnSets && showTemplateSelector ? (
            <>
              {view === 'create' && (
                // Only let users choose from active column sets shared with them or owned by them.
                <>
                  <Typography variant={'h6'}>
                    Choose a starting template
                  </Typography>

                  <SimpleAutocomplete
                    freeSolo={false}
                    label={'Template'}
                    value={activeColumnSet}
                    isOptionEqualToValue={(o, v) => o.label === v.label}
                    onChange={option => {
                      option && setActiveColumnSet(option.label)
                    }}
                    options={creatableTemplateOptions}
                    autoSelect
                    autoHighlight
                    blurOnSelect
                    disableClearable
                    groupBy={option => option.group}
                    renderGroup={params => {
                      return (
                        <Box key={params.group}>
                          <GroupHeader>{params.group}</GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </Box>
                      )
                    }}
                    helperText={
                      <Link
                        onClick={() =>
                          hideTemplateSelectorAndClearTemplateColumns()
                        }
                        sx={{ cursor: 'pointer' }}
                      >
                        Start from scratch
                      </Link>
                    }
                  />
                </>
              )}

              {view === 'update' && (
                // Only let users update their own active or inactive column sets
                <>
                  <Typography variant={'h6'}>
                    Choose a table to update
                  </Typography>

                  <SimpleAutocomplete
                    freeSolo={false}
                    label={'Template'}
                    value={activeColumnSet}
                    isOptionEqualToValue={(o, v) => o.label === v.label}
                    onChange={option => {
                      option && setActiveColumnSet(option.label)
                    }}
                    options={myTemplateOptions}
                    autoSelect
                    autoHighlight
                    blurOnSelect
                    disableClearable
                    groupBy={option => option.group}
                    renderGroup={params => {
                      return (
                        <Box key={params.group}>
                          <GroupHeader>{params.group}</GroupHeader>
                          <GroupItems>{params.children}</GroupItems>
                        </Box>
                      )
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <Typography variant={'h6'}>Start from scratch</Typography>
          )}
          {editableFields && (
            <>
              <FormControl fullWidth>
                <DebouncedTextField
                  value={name}
                  onChange={event => setName(event.target.value)}
                  required
                  label={'Name'}
                  error={hasNamingError}
                  helperText={'Must be unique'}
                />
              </FormControl>

              <FormControl fullWidth>
                <DebouncedTextField
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                  label={'Description (Optional)'}
                />
              </FormControl>

              {hasAdminAccess && (
                <FormControl fullWidth>
                  <InputLabel id={'visibility-select'}>Visible to:</InputLabel>
                  <Select
                    label={'Visible to:'}
                    labelId={'visibility-select'}
                    value={visibleToAll}
                    onChange={val => setVisibleToAll(val.target.value)}
                  >
                    <MenuItem value={false}>Me</MenuItem>
                    <MenuItem value={true}>All Users</MenuItem>
                  </Select>
                </FormControl>
              )}

              <FormControl fullWidth>
                <InputLabel id={'visibility-status-select'}>
                  Visibility Status:
                </InputLabel>
                <Select
                  label={'Visibility Status:'}
                  labelId={'visibility-status-select'}
                  value={active}
                  onChange={val => setActive(Boolean(val.target.value))}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </>
          )}
        </Stack>

        <Stack spacing={2} width={{ md: '50%' }}>
          {editableFields && (
            <>
              <Typography variant={'h6'}>Columns</Typography>
              <SimpleAutocomplete
                freeSolo={false}
                label={'Add New Column(s)'}
                value={''}
                onChange={option => handleNewColumn(option.value)}
                options={columnOptions}
                autoSelect
                autoHighlight
                blurOnSelect
                disableClearable
                clearOnEscape
              />

              {templateColumnSet && (
                <List>
                  {templateColumnSet?.columns
                    ?.filter(column => column !== 'selector')
                    .map((column, index) => (
                      <ListItem
                        key={column}
                        sx={{ justifyContent: 'space-between' }}
                      >
                        <Box>{titleize(column)}</Box>

                        <Box>
                          <TooltipIconButton
                            tooltipProps={{
                              arrow: true,
                              placement: 'top-start',
                              title: 'Adjust column left'
                            }}
                            disabled={index === 0}
                            onClick={() =>
                              handleTemplateColumnChange(column, index - 1)
                            }
                          >
                            <ArrowUpwardIcon fontSize={'inherit'} />
                          </TooltipIconButton>

                          <TooltipIconButton
                            tooltipProps={{
                              arrow: true,
                              placement: 'top-start',
                              title: 'Adjust column right'
                            }}
                            disabled={
                              index === templateColumnSet?.columns.length - 1
                            }
                            onClick={() =>
                              handleTemplateColumnChange(column, index + 1)
                            }
                          >
                            <ArrowDownwardIcon fontSize={'inherit'} />
                          </TooltipIconButton>

                          <TooltipIconButton
                            tooltipProps={{
                              arrow: true,
                              placement: 'top-start',
                              title: 'Remove column'
                            }}
                            onClick={() =>
                              handleTemplateColumnChange(column, null)
                            }
                          >
                            <DeleteIcon fontSize={'inherit'} />
                          </TooltipIconButton>
                        </Box>
                      </ListItem>
                    ))}
                </List>
              )}
            </>
          )}
        </Stack>
      </Stack>

      {children}
    </Stack>
  )
}

Content.propTypes = {
  setView: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  hideTemplateSelectorAndClearTemplateColumns: PropTypes.func.isRequired,
  columnSets: PropTypes.array.isRequired,
  showTemplateSelector: PropTypes.bool.isRequired,
  activeColumnSet: PropTypes.string.isRequired,
  setActiveColumnSet: PropTypes.func.isRequired,
  viewableToAllActiveColumnSets: PropTypes.array.isRequired,
  myActiveColumnSets: PropTypes.array.isRequired,
  myInactiveColumnSets: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  setName: PropTypes.func.isRequired,
  hasNamingError: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
  hasAdminAccess: PropTypes.bool.isRequired,
  visibleToAll: PropTypes.bool.isRequired,
  setVisibleToAll: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  templateColumnSet: PropTypes.object,
  handleTemplateColumnChange: PropTypes.func.isRequired,
  handleNewColumn: PropTypes.func.isRequired,
  columnDefinitionOptions: PropTypes.array.isRequired,
  children: PropTypes.any
}

function Actions({
  name,
  handleSubmit,
  templateColumnSet,
  hasNamingError,
  userId,
  handleDelete
}) {
  return (
    <>
      <Button
        color={'primary'}
        variant={'contained'}
        onClick={() => handleSubmit()}
        disabled={
          name === '' ||
          templateColumnSet?.columns?.length == 0 ||
          hasNamingError
        }
      >
        Save
      </Button>

      {templateColumnSet?.user_id === userId && (
        <ConfirmModalButton
          confirmWithText={'confirm'}
          modalMessage={'Are you sure you want to delete this table view?'}
          color={'error'}
          showCancelButton={true}
          cancelButtonText="No"
          onConfirm={() => handleDelete()}
          startIcon={<DeleteIcon />}
          varaint="outlined"
        >
          Delete
        </ConfirmModalButton>
      )}
    </>
  )
}

Actions.propTypes = {
  name: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  templateColumnSet: PropTypes.object,
  hasNamingError: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired
}

export function ColumnSet({
  setDrawerName,
  activeColumnSet,
  setActiveColumnSet,
  hasAdminAccess,
  userId,
  children
}) {
  // state
  const { data: columnSets, invalidate } = useColumnSets({ userId })
  const [templateColumnSet, setTemplateColumnSet] = useState(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [active, setActive] = useState(true)
  const [view, setView] = useState(null)
  const [showTemplateSelector, setShowTemplateSelector] = useState(true)
  const [visibleToAll, setVisibleToAll] = useState(false)

  const columnSetNames = React.useMemo(() => {
    return columnSets
      .filter(
        columnSet => view === 'create' || columnSet.id !== templateColumnSet?.id
      )
      .map(columnSet => columnSet.name)
  }, [view, columnSets, templateColumnSet])
  const hasNamingError = columnSetNames.includes(name)

  const newColumnSet = useMutation(({ params }) => createColumnSet(params), {
    onSuccess: params => {
      if (params.is_active) {
        setActiveColumnSet(params.name)
      } else {
        setActiveColumnSet('Default')
      }
      setDrawerName(null)
      invalidate()
    }
  })

  const updateColumnSetMutation = useMutation(
    ({ id, params }) => updateColumnSet(id, params),
    {
      onSuccess: params => {
        if (params.is_active) {
          setActiveColumnSet(params.name)
        } else {
          setActiveColumnSet('Default')
        }
        setDrawerName(null)
        invalidate()
      }
    }
  )

  // handlers
  const handleTemplateColumnChange = (attribute, desiredPosition) => {
    let filteredColumnSet = templateColumnSet?.columns.filter(
      column => column !== attribute
    )
    if (!isNull(desiredPosition)) {
      filteredColumnSet.splice(desiredPosition + 1, 0, attribute)
    }
    setTemplateColumnSet({ ...templateColumnSet, columns: filteredColumnSet })
  }

  const handleNewColumn = newColumn => {
    const updatedColumns = [newColumn, ...(templateColumnSet?.columns || [])]
    setTemplateColumnSet({ ...templateColumnSet, columns: updatedColumns })
  }

  const handleSubmit = () => {
    const sanitizedColumns = [
      ...new Set(['selector'].concat(templateColumnSet?.columns))
    ]
    const params = {
      name: name,
      description: description,
      columns: sanitizedColumns,
      is_active: active,
      user_id: userId,
      is_viewable_to_all: visibleToAll
    }

    if (view === 'create') {
      newColumnSet.mutate({ params })
    } else if (view === 'update') {
      updateColumnSetMutation.mutate({ id: templateColumnSet?.id, params })
    }
  }

  const handleDelete = () => {
    if (templateColumnSet?.user_id === userId) {
      deleteColumnSet(templateColumnSet?.id).then(() => {
        invalidate()
        setView(null)
        setActiveColumnSet('Default')
      })
    }
  }

  const hideTemplateSelectorAndClearTemplateColumns = () => {
    setShowTemplateSelector(false)
    setTemplateColumnSet({ ...templateColumnSet, columns: [] })
  }

  const resetCustomizationOptions = () => {
    setTemplateColumnSet(null)
    setName('')
    setDescription('')
    setActive(true)
    setView(null)
    setShowTemplateSelector(true)
  }

  const columnDefinitionOptions = columnDefinitions.filter(
    column => !templateColumnSet?.columns?.includes(column)
  )

  const viewableToAllActiveColumnSets = columnSets.filter(
    columnSet =>
      (columnSet.is_viewable_to_all || columnSet.name === 'Default') &&
      columnSet.user_id !== userId
  )
  const myActiveColumnSets = columnSets.filter(
    columnSet => columnSet.user_id === userId && columnSet.is_active === true
  )
  const myInactiveColumnSets = columnSets.filter(
    columnSet => columnSet.user_id === userId && columnSet.is_active === false
  )
  const myColumnSets = myActiveColumnSets.concat(myInactiveColumnSets)

  const title =
    view === 'create'
      ? 'Create Table View'
      : view === 'update'
      ? 'Update Table View'
      : 'Customize Table'

  useEffect(() => {
    setDrawerName(title)
  }, [title])

  useEffect(() => {
    if (activeColumnSet && columnSets) {
      if (view === 'create') {
        const set = columnSets.find(
          columnSet => columnSet.name === activeColumnSet
        )
        setTemplateColumnSet(set)
      }
      if (view === 'update') {
        const set = myColumnSets.find(
          columnSet => columnSet.name === activeColumnSet
        )
        setTemplateColumnSet(set)
        setName(set?.name || '')
        setDescription(set?.description || '')
        setActive(Boolean(set?.is_active))
      }
    }
  }, [activeColumnSet, view])

  useEffect(() => {
    if (view) {
      setShowTemplateSelector(true)
    } else {
      resetCustomizationOptions()
    }
  }, [view])

  const actions = view && (
    <Actions
      {...{
        name,
        handleSubmit,
        templateColumnSet,
        hasNamingError,
        userId,
        handleDelete
      }}
    />
  )
  const selectorProps = { userId, columnSets, setView }
  const contentProps = {
    setView,
    view,
    hideTemplateSelectorAndClearTemplateColumns,
    columnSets,
    showTemplateSelector,
    activeColumnSet,
    setActiveColumnSet,
    viewableToAllActiveColumnSets,
    myActiveColumnSets,
    myInactiveColumnSets,
    name,
    setName,
    hasNamingError,
    description,
    setDescription,
    hasAdminAccess,
    visibleToAll,
    setVisibleToAll,
    active,
    setActive,
    templateColumnSet,
    handleTemplateColumnChange,
    handleNewColumn,
    columnDefinitionOptions
  }

  if (typeof children === 'function') {
    const content = view ? (
      <Content {...contentProps} />
    ) : (
      <Selector {...selectorProps} />
    )
    return children({ content, actions })
  } else {
    contentProps.children = (
      <>
        {children}
        {actions}
      </>
    )
    return view ? (
      <Content {...contentProps} />
    ) : (
      <Selector {...selectorProps} />
    )
  }
}

ColumnSet.propTypes = {
  setDrawerName: PropTypes.func.isRequired,
  activeColumnSet: PropTypes.string.isRequired,
  setActiveColumnSet: PropTypes.func.isRequired,
  hasAdminAccess: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
  children: PropTypes.any
}

const mapStateToProps = (state, _ownProps) => ({
  userId: state.currentUser.user.id,
  hasAdminAccess: state.currentUser.user.rights.has_admin_access
})

export default connect(mapStateToProps)(ColumnSet)
