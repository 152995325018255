import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import MeetingModal from './action-modals/meeting'
import ChildStudyNoteModal from './action-modals/child-study-note'
import UploadFormModal from './action-modals/upload-form'
import { updateChildStudyStatus } from 'api/child-study-statuses'
import { useSelector } from 'react-redux'
import ConfirmModalButton from 'components/shared/confirm-modal/button'
import ConcernDialog from 'components/shared/concerns/dialog'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchChildStudy } from 'modules/child-studies'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ChildStudyStatusBadge from 'components/shared/child-study-status-badge'
import ReferralButton from './referral/button'
import DownloadIcon from '@mui/icons-material/Download'
import { Stack, Box } from '@mui/material'
import PaperBox from 'components/shared/PaperBox'

function Actions({
  activeModal,
  childStudyId,
  invalidateChildStudyRequirements,
  invalidateConcernsQuery,
  setActiveModal,
  studentId,
  schoolId
}) {
  const user = useSelector(state => state.currentUser.user)
  const hasChildStudyAdminAccess =
    user.rights.child_study_team_school_ids.includes(schoolId) ||
    ['district', 'principal'].includes(user.role)
  const queryClient = useQueryClient()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const { isSuccess, data: childStudyStatus } = useQuery(
    ['child-study-status', childStudyId],
    async () => {
      const res = await fetchChildStudy(childStudyId)
      return res.current_status
    },
    {
      refetchOnWindowFocus: false
    }
  )

  const updateStatusMutation = useMutation(
    async ({ showOpen }) => {
      const status = showOpen ? 'active' : 'closed'
      await updateChildStudyStatus(childStudyId, user.id, status)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['child-study-status', childStudyId])
        setActiveModal('')
        closeMenu()
      }
    }
  )

  const handleCloseAndRefreshConcerns = () => {
    invalidateConcernsQuery()
    setActiveModal('')
  }

  const handleCloseAndRefreshFeed = () => {
    setActiveModal('')
    invalidateChildStudyRequirements()
  }

  const download = () => {
    window.location = `/api/web/child_studies/download?id=${childStudyId}&student_id=${studentId}`
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const closeMenuAndOpenModal = modalType => {
    closeMenu()
    setActiveModal(modalType)
    document.body.style.overflow = 'auto'
  }

  const ChildStudyStatusButton = () => {
    const showOpen = ['referred', 'closed', 'on_hold_teacher'].includes(
      childStudyStatus
    )
    const modalAction = showOpen ? 'open' : 'close'
    const buttonText = showOpen ? 'Open Child Study' : 'Close Child Study'
    const buttonColor = showOpen ? 'success' : 'error'

    return (
      <ConfirmModalButton
        modalMessage={`Are you sure you want to ${modalAction} this Child Study? If so, please type 'YES' below.`}
        confirmWithText="YES"
        showCancelButton={false}
        className="childStudyConfirmModalButton"
        onConfirm={() => {
          updateStatusMutation.mutate({ showOpen })
        }}
        variant="outlined"
        color={buttonColor}
        sx={{
          fontWeight: 'bold',
          textTransform: 'uppercase'
        }}
      >
        {buttonText}
      </ConfirmModalButton>
    )
  }

  return (
    <Page name="Child Study Process" title="Student - Child Study Process">
      <PaperBox
        title={
          <Box
            gap={1}
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            Current Status:
            <ChildStudyStatusBadge status={childStudyStatus} />
          </Box>
        }
      >
        {isSuccess && (
          <Stack spacing={2}>
            {hasChildStudyAdminAccess && (
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  id="actions"
                  aria-controls="actions-menu"
                  aria-haspopup="true"
                  variant="contained"
                  color="primary"
                  onClick={e => setAnchorEl(e.currentTarget)}
                >
                  Child Study Actions
                </Button>

                <Menu
                  id="actions-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={closeMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <MenuItem
                    onClick={() => closeMenuAndOpenModal('concern')}
                    id="concern-dialog"
                  >
                    Create Concern
                  </MenuItem>
                  <MenuItem
                    onClick={() => closeMenuAndOpenModal('meeting')}
                    id="meeting"
                  >
                    Create Meeting
                  </MenuItem>
                  <MenuItem
                    onClick={() => closeMenuAndOpenModal('childStudyNote')}
                    id="child-study-note"
                  >
                    Add Note
                  </MenuItem>
                  <MenuItem
                    onClick={() => closeMenuAndOpenModal('uploadForm')}
                    id="upload-form"
                  >
                    Upload Form
                  </MenuItem>
                  <MenuItem>
                    <ChildStudyStatusButton />
                  </MenuItem>
                </Menu>

                <ReferralButton
                  childStudyId={childStudyId}
                  schoolId={schoolId}
                  studentId={studentId}
                />
                <Box>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<DownloadIcon />}
                    onClick={download}
                  >
                    Download
                  </Button>
                </Box>
              </Stack>
            )}
          </Stack>
        )}
      </PaperBox>
      <Box id="test-modals">
        <MeetingModal
          show={activeModal === 'meeting'}
          childStudyId={childStudyId}
          studentId={studentId}
          schoolId={schoolId}
          onHide={handleCloseAndRefreshFeed}
        />
        <ConcernDialog
          show={activeModal === 'concern'}
          studentId={studentId}
          childStudyId={childStudyId}
          onHide={handleCloseAndRefreshConcerns}
        />
        <ChildStudyNoteModal
          show={activeModal === 'childStudyNote'}
          childStudyId={childStudyId}
          onHide={handleCloseAndRefreshFeed}
        />
        <UploadFormModal
          show={activeModal === 'uploadForm'}
          childStudyId={childStudyId}
          onHide={handleCloseAndRefreshFeed}
          accept={[
            ['.doc', 'application/msword'],
            [
              '.docx',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ],
            ['.jpg', '.jpeg', '.jpe', 'image/jpeg'],
            ['.pdf', 'application/pdf'],
            ['.png', 'image/png']
          ]}
        />
      </Box>
    </Page>
  )
}

Actions.propTypes = {
  activeModal: PropTypes.string.isRequired,
  childStudyId: PropTypes.number.isRequired,
  invalidateChildStudyRequirements: PropTypes.func.isRequired,
  invalidateConcernsQuery: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
  studentId: PropTypes.number.isRequired,
  schoolId: PropTypes.number.isRequired
}

export default Actions
