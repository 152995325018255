import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, Typography } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { formatDate } from 'utils/time-helpers'
import { useParams, useHistory } from 'react-router'
import { truncate } from 'lodash'
import Status from '../status'
import Chip from '@mui/material/Chip'

function InterventionCards({ intervention }) {
  const params = useParams()

  const history = useHistory()
  const [hover, setHover] = useState(false)

  const url = params.student_id
    ? `/student/${params.student_id}/interventions/${intervention.id}`
    : `/interventions/${intervention.id}`

  const redirectToIntervention = () => {
    history.push(url)
  }

  const determineScoreProgressColor = () => {
    if (intervention.score_progress == 'Target met') {
      return 'success'
    } else if (intervention.score_progress == 'Progress made') {
      return 'info'
    } else if (intervention.score_progress == 'No progress made') {
      return 'warning'
    } else if (
      intervention.score_progress == 'Missing baseline/target values'
    ) {
      return 'error'
    } else {
      return 'default'
    }
  }

  return (
    <Card
      style={{
        boxShadow: '0px 2px 4px 0px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        backgroundColor: hover ? '#E3F2FD' : '#FFF',
        marginBottom: '16px'
      }}
      onClick={redirectToIntervention}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <CardContent>
        <Typography variant="h6" style={{ marginBottom: '8px' }}>
          {intervention.targeted_intervention_name}
          <small>
            <Status
              clickable={false}
              parent={intervention}
              queryName="intervention"
              greyScale={true}
            />
            <Chip
              sx={{ marginLeft: '8px', fontSize: '0.875rem' }}
              clickable={false}
              label={intervention.score_progress}
              color={determineScoreProgressColor()}
            />
          </small>
        </Typography>
        <Typography variant="body2" style={{ marginBottom: '8px' }}>
          <strong>Since</strong> {formatDate(intervention.followed_up_at)}{' '}
          <FontAwesomeIcon icon={faCircle} style={{ color: '#ddd' }} />{' '}
          <strong>By</strong> {intervention.ownerName}{' '}
          <FontAwesomeIcon icon={faCircle} style={{ color: '#ddd' }} />
        </Typography>
        <Typography variant="body2">
          <strong>Goal</strong>{' '}
          {truncate(intervention.follow_up_notes, { length: 48 })}
        </Typography>
      </CardContent>
    </Card>
  )
}

InterventionCards.propTypes = {
  intervention: PropTypes.object.isRequired
}

export default InterventionCards
