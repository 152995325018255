import React from 'react'
import PropTypes from 'prop-types'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import MenuItemLink from './menu-item-link'

function ApplicationLink({ title, icon, children, ...props }) {
  return (
    <MenuItemLink target={'_blank'} {...props}>
      {children || (
        <React.Fragment>
          <ListItemIcon>
            {typeof icon === 'string' ? (
              <img src={icon} style={{ height: '25px' }} />
            ) : (
              icon
            )}
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2" color="text.secondary">
              {title}
            </Typography>
          </ListItemText>
        </React.Fragment>
      )}
    </MenuItemLink>
  )
}

ApplicationLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.any.isRequired,
  children: PropTypes.any
}

export default ApplicationLink
