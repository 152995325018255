import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import TablePagination from '@mui/material/TablePagination'

function Pagination({ count, page, perPage, setPage, setPerPage }) {
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleChangeRowsPerPage = event => {
    setPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      spacing={2}
      paddingBottom={4}
    >
      <Grid item md={4} align="middle">
        <TablePagination
          component="div"
          count={count}
          page={page - 1}
          onPageChange={handleChangePage}
          rowsPerPage={perPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
    </Grid>
  )
}

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setPerPage: PropTypes.func.isRequired
}

export default Pagination
