import React, { useState } from 'react'
import { Chip, Button, Typography, Stack, DialogContent } from '@mui/material'
import Dialog from 'shared/dialog'
import AttendanceRiskTable from './attendance-risk-table'

export default function AttendanceAtRiskLegend() {
  const [modalOpen, setModalOpen] = useState(false)
  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return (
    <Stack spacing={1}>
      <Typography variant="h6">Attendance Key</Typography>
      <Stack direction={'row'} spacing={1}>
        <Chip color="success" label="Not At Risk" />
        <Chip color="secondary" label="At Risk" />
        <Chip color="warning" label="Chronic" />
        <Chip color="error" label="Critically Chronic" />
      </Stack>
      <Typography variant="body2">
        <Button onClick={openModal}>How is this calculated?</Button>
      </Typography>

      <Dialog
        open={modalOpen}
        onClose={closeModal}
        title={'How is the attendance risk score calculated?'}
      >
        <DialogContent>
          <Typography variant="h4">Attendance Risk Calculation</Typography>
          <Typography variant="body1">
            The attendance risk calculation uses the average daily attendance
            (ADA) for a student: ADA = 100 * days present / days in membership
            where: days present = The number of days a student attended a school
            site days in membership = The number of days a student was enrolled
            at a school site Once the student&apos;s ADA is calculated, the
            following thresholds determine the risk:
          </Typography>
          <AttendanceRiskTable />
        </DialogContent>
      </Dialog>
    </Stack>
  )
}
