import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Delete from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import { useDestroyReport } from 'hooks/useReports'
import { useQueryClient } from '@tanstack/react-query'

function DestroyButton({ id }) {
  const { mutate, isSuccess } = useDestroyReport(id)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isSuccess) {
      queryClient.invalidateQueries('reports')
    }
  }, [isSuccess])

  return (
    <Button
      onClick={() => mutate()}
      variant="contained"
      color="error"
      startIcon={<Delete />}
    >
      Delete
    </Button>
  )
}

DestroyButton.propTypes = {
  id: PropTypes.number.isRequired
}

export default DestroyButton
