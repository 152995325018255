import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  compact,
  flattenDeep,
  includes,
  isNull,
  isNumber,
  pickBy,
  some,
  sum,
  uniq
} from 'lodash'
import { currentSchoolYear, titleize } from 'utils'
import { alert } from 'utils/alert'
import { colorDiscipline, colorAttendance } from 'utils/color-class'
import AlertPopover from './alerts-popover'
import RiskStatusPopover from './risk-status-popover'
import StudentNotesModal from './student-notes-modal'
import GradeDistribution from 'components/shared/grade-distribution'
import GraduationStatusChip from 'components/shared/students/show/graduation_check/graduation-status-chip'
import {
  Box,
  Chip,
  Stack,
  Button,
  Link as MuiLink,
  Tooltip,
  IconButton,
  Avatar,
  AvatarGroup,
  colors,
  Typography,
  styled
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import MuiInfoIcon from '@mui/icons-material/Info'
import ProgressBar from '../students/show/graduation_progress/progress-bar'
import QuestionMark from '@mui/icons-material/QuestionMark'
import { theme } from 'components/app/theme'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import LanguageIcon from '@mui/icons-material/Language'
import BookIcon from '@mui/icons-material/MenuBook'
import SuperscriptIcon from '@mui/icons-material/Superscript'
import HomeIcon from '@mui/icons-material/Home'
import StickyNote2Icon from '@mui/icons-material/StickyNote2'
import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
import WarningIcon from '@mui/icons-material/Warning'
import PsychologyIcon from '@mui/icons-material/Psychology'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import BalanceIcon from '@mui/icons-material/Balance'
import TranslateIcon from '@mui/icons-material/Translate'
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural'
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const iconMapping = {
  calendar: EventBusyIcon,
  child_study: PsychologyIcon,
  chronically_absent: EventBusyIcon,
  discipline: BalanceIcon,
  ell: TranslateIcon,
  gifted: FaceRetouchingNaturalIcon,
  guardian: EscalatorWarningIcon,
  high_math_scores: SuperscriptIcon,
  high_reading_scores: BookIcon,
  homeless: HomeIcon,
  idea: StickyNote2Icon,
  low_math_scores: SuperscriptIcon,
  low_reading_scores: BookIcon,
  medical: LocalHospitalIcon,
  native_language: LanguageIcon,
  services: DirectionsCarIcon,
  warning: WarningIcon
}

const InfoIcon = styled(MuiInfoIcon)(() => ({ fontSize: 'inherit' }))

const GraduationStatusChipCell = ({ value }) =>
  value && (
    <GraduationStatusChip
      label={value}
      sx={{ fontSize: '0.7em', height: '20px' }}
    />
  )

GraduationStatusChipCell.propTypes = {
  value: PropTypes.any
}

// moved from students-table/presenters.jsx
const checkMark = (value, altTextTrue = 'Yes', altTextFalse = 'No') => {
  if (value) {
    return (
      <Box>
        <Tooltip title={altTextTrue}>
          <IconButton color="success">
            <CheckCircleIcon />
          </IconButton>
        </Tooltip>
      </Box>
    )
  } else {
    return (
      <Box>
        <Tooltip title={altTextFalse}>
          <IconButton color="secondary">
            <PriorityHighIcon />
          </IconButton>
        </Tooltip>
      </Box>
    )
  }
}

// reads the 'includes' property from column definitions and determines
// the appropriate includes params to send to the API

export const determineIncludesFromColumns = columns => {
  let includes = columns.map(column => {
    if (column.columns) {
      return [column.includes, determineIncludesFromColumns(column.columns)]
    } else {
      return column.includes
    }
  })

  return uniq(compact(flattenDeep(includes)))
}

export const columnDefinitions = [
  'id',
  'selector',
  'last_name',
  'last_name_new_tab',
  'first_name',
  'number',
  'school_name',
  'alerts',
  'grade_level',
  // 'section_grade', TODO: NOT APPLICABLE TO STUDENT TABLES OUTSIDE OF SECTION#SHOW
  'grade_distribution',
  'num_grades',
  'birthday',
  'age',
  'ethnicity',
  'socioeconomically_disadvantaged',
  'ell',
  'idea',
  'tardies_count',
  'school_attendance_present',
  'school_attendance_absent',
  'school_attendance_membership',
  'school_attendance_percent',
  'attendance_prediction',
  'school_attendance_chronic',
  'school_attendance_weekly_change',
  'school_attendance_monthly_change',
  'quarter_one_present',
  'quarter_one_absences',
  'quarter_one_membership',
  'quarter_one_percent',
  'quarter_two_present',
  'quarter_two_absences',
  'quarter_two_membership',
  'quarter_two_percent',
  'quarter_three_present',
  'quarter_three_absences',
  'quarter_three_membership',
  'quarter_three_percent',
  'quarter_four_present',
  'quarter_four_absences',
  'quarter_four_membership',
  'quarter_four_percent',
  'semester_one_present',
  'semester_one_absences',
  'semester_one_membership',
  'semester_one_percent',
  'semester_two_present',
  'semester_two_absences',
  'semester_two_membership',
  'semester_two_percent',
  'number_of_attendance_concerns',
  'latest_map_math_percentile',
  'latest_map_reading_percentile',
  'map_math_fall_percentile',
  'map_math_winter_percentile',
  'map_math_spring_percentile',
  'map_reading_fall_percentile',
  'map_reading_winter_percentile',
  'map_reading_spring_percentile',
  'projected_ostp_level_based_on_latest_map_reading',
  'projected_ostp_level_based_on_latest_map_math',
  'latest_ostp_ela_band',
  'previous_ostp_ela_band',
  'latest_ostp_mathematics_band',
  'previous_ostp_mathematics_band',
  'latest_ostp_science_band',
  'previous_ostp_science_band',
  'discipline_score',
  'num_referrals',
  'num_suspensions',
  'num_days_suspended',
  'date_of_last_suspension',
  // 'suspensions_breakdown', NOT WORKING
  'address_street',
  'address_zip',
  'address_city',
  'grad_plan',
  'graduation_status',
  'cpr',
  'pfl',
  'cohort',
  'gpa',
  'ok_promise_gpa',
  'sat_composite',
  'graduation_credits',
  'failed_core_courses_count',
  'student_notes',
  'yearly_major_discipline_count',
  'yearly_in_class_discipline_count',
  'active_behavior_concerns_with_medium_severity_count',
  'active_behavior_concerns_with_high_severity_count',
  'yearly_in_school_suspension_days_count',
  'yearly_out_of_school_suspension_days_count',
  'advanced_placement',
  'international_baccalaureate_program',
  'college_level',
  'college_ready_map',
  'assessment_requirements_post_sec',
  'ap_potential_based_on_psat',
  'stepmojo_student',
  'career_tech_student',
  'access_expected_growth',
  'access_growth_made',
  'access_growth_goal_met',
  'us_civics_status',
  'current_academic_intervention_platform',
  'weeks_met_threshold_on_intervention_platform',
  'last_four_weeks_met_threshold_on_intervention_platform',
  'student_success_plan_count',
  'homeroom_teacher',
  'tps_grad_fafsa',
  'tps_grad_flp',
  'tps_grad_cpr',
  'tps_grad_uscivics',
  'tps_grad_icap9',
  'tps_grad_icap10',
  'tps_grad_icap11',
  'tps_grad_icap12',
  'tps_grad_icap_wbl',
  'approximate_fay_status',
  'grad_progress_last_event_at'
]

export const withBuiltColumns = Component => {
  return class WithBuiltColumnsHOC extends React.Component {
    static propTypes = {
      filters: PropTypes.object,
      columns: PropTypes.array.isRequired,
      onRowInteraction: PropTypes.func
    }

    state = {
      students: [],
      selectedStudentIds: [],
      openProgressPopover: false,
      studentId: null,
      studentName: ''
    }

    sanitizeBoolean = val => {
      if (isNull(val)) {
        return ''
      } else if (val) {
        return (
          <IconButton color="success">
            <CheckCircleIcon />
          </IconButton>
        )
      } else {
        return (
          <IconButton color="warning">
            <PriorityHighIcon />
          </IconButton>
        )
      }
    }

    renderChip = (
      value,
      tierThree = null,
      tierTwo = null,
      valueForComparison = null
    ) => {
      if (!value || value < 0) {
        return ''
      }

      const color = (v => {
        if (tierThree && tierTwo) {
          if (v >= tierThree) {
            return 'error'
          }
          if (v >= tierTwo) {
            return 'warning'
          }
        }
        return 'default'
      })(valueForComparison || value)

      return <Chip color={color} label={`${value}`} />
    }

    columnDefinitions = {
      id: {
        Header: 'Dashboard ID',
        accessor: 'id'
      },
      selector: {
        id: 'id',
        Header: '',
        accessor: obj => obj.id,
        width: 50,
        sortable: false,
        Cell: props => (
          <input
            type="checkbox"
            disabled={this.state.selectedStudentIds.includes('all')}
            checked={
              this.state.selectedStudentIds.includes('all') ||
              this.isStudentSelected(props.value)
            }
            onChange={() => this.toggleStudentSelected(props.value)}
          />
        )
      },
      last_name: {
        id: 'last_name',
        Header: 'Last Name',
        maxWidth: 200,
        accessor: obj => obj,
        Cell: props => (
          <MuiLink
            component={Link}
            to={`/student/${props.value.id}/overview`}
            sx={{
              textDecoration: 'none',
              color: theme.palette.primary.main,
              fontWeight: 'bold'
            }}
          >
            {props.value.last_name}
          </MuiLink>
        )
      },
      last_name_new_tab: {
        id: 'last_name',
        Header: 'Last Name',
        maxWidth: 200,
        accessor: obj => obj,
        Cell: props => (
          <a
            href={`/student/${props.value.id}/overview`}
            title={props.value.last_name}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.value.last_name}
          </a>
        )
      },
      first_name: {
        Header: 'First Name',
        accessor: 'first_name',
        maxWidth: 200,
        Cell: props => <Box title={props.value}>{props.value}</Box>
      },
      number: {
        Header: 'Student Number',
        accessor: 'number'
      },
      school_name: {
        Header: 'School Name',
        accessor: 'school_name',
        includes: 'school'
      },
      alerts: {
        id: 'alerts',
        Header: () => <AlertPopover />,
        accessor: obj => obj.alerts,
        minWidth: 110,
        sortable: false,
        Cell: props => {
          if (!props.value) return null

          return (
            <AvatarGroup spacing={2} max={100}>
              {props.value.map(alert => {
                const IconComponent = iconMapping[alert.icon]

                if (!IconComponent) {
                  return (
                    <Tooltip key={alert.id} title={alert.content}>
                      <InfoOutlinedIcon
                        sx={{
                          fontSize: '.95rem',
                          color: alertColor
                        }}
                      />
                    </Tooltip>
                  )
                }

                const alertColor = (() => {
                  switch (alert.category) {
                    case 'low_math_scores':
                    case 'low_reading_scores':
                      return theme.palette.error.main
                    case 'high_math_scores':
                    case 'high_reading_scores':
                      return theme.palette.success.main
                    case 'discipline':
                      return theme.palette.secondary.dark
                    case 'homeless':
                    case 'guardian':
                      return theme.palette.secondary.main
                    case 'medical':
                      return theme.palette.error.dark
                    case 'chronically_absent':
                      return theme.palette.error.dark
                    case 'other':
                      return theme.palette.secondary.light
                    default:
                      return theme.palette.primary.main
                  }
                })()

                return (
                  <Tooltip
                    key={alert.id}
                    placement="top"
                    arrow
                    title={alert.content}
                  >
                    <Avatar sx={{ bgcolor: alertColor, width: 24, height: 24 }}>
                      <IconComponent
                        sx={{
                          fontSize: '.95rem',
                          color: 'white'
                        }}
                      />
                    </Avatar>
                  </Tooltip>
                )
              })}
            </AvatarGroup>
          )
        }
      },
      grade_level: {
        Header: 'Grade Level',
        accessor: 'grade_level',
        width: 90
      },
      grade_distribution: {
        id: 'grade_distribution',
        includes: 'grade_distribution',
        Header: 'Grades',
        accessor: obj => obj,
        sortable: false,
        minWidth: 200,
        Cell: props => {
          const gradeDistribution = props.value.grade_distribution
          return <GradeDistribution gradeDistribution={gradeDistribution} />
        }
      },
      num_grades: {
        id: 'num_grades',
        Header: 'Grades (#)',
        accessor: obj => obj,
        sortable: false,
        Cell: props => {
          const counts = pickBy(props.value.grade_distribution, (_value, key) =>
            some(['count'], str => includes(key, str))
          )
          delete counts['none_count']
          delete counts['dash_count']

          if (counts == null) {
            return ''
          }

          return sum(Object.values(counts))
        }
      },
      section_grade: {
        id: 'section_percent_grade',
        Header: 'Grade',
        accessor: obj => {
          const percent = obj.section_percent_grade
            ? ` (${obj.section_percent_grade})`
            : ''
          return obj.section_letter_grade
            ? `${obj.section_letter_grade}${percent}`
            : '--'
        },
        includes: 'grades',
        sortable: false
      },
      birthday: {
        id: 'Birthday',
        Header: 'Birthday',
        accessor: obj => moment(obj.birthday).format('MM/DD/YYYY')
      },
      age: {
        id: 'age',
        Header: 'Age',
        accessor: obj => moment().diff(obj.birthday, 'years')
      },
      ethnicity: {
        Header: 'Ethnicity',
        accessor: 'ethnicity',
        minWidth: 120
      },
      socioeconomically_disadvantaged: {
        Header: 'Socioeconomically Disadvantaged',
        accessor: 'socioeconomically_disadvantaged',
        Cell: ({ value }) => {
          return `${value ? 'Yes' : 'No'}`
        },
        minWidth: 120
      },
      ell: {
        id: 'ell',
        Header: 'ML',
        accessor: obj => obj.ell,
        Cell: props =>
          props.value === 'Former' ? 'Monitored/Exited' : props.value,
        maxWidth: 100
      },
      idea: {
        id: 'idea',
        Header: 'SPED',
        accessor: obj => (isNull(obj.idea) ? 'N/A' : obj.idea ? 'Yes' : 'No'),
        maxWidth: 70
      },
      tardies_count: {
        id: 'tardies_count',
        Header: 'Tardies',
        accessor: 'tardies_count',
        maxWidth: 85,
        Cell: props => {
          return <Box>{props.value}</Box>
        },
        includes: 'ada'
      },
      school_attendance_present: {
        id: 'school_attendance_present',
        Header: () => (
          <Box>
            <Tooltip
              placement="top"
              arrow
              title={
                <Box>
                  This is the number of Days Present for this school year at the
                  current school. For a student&apos;s YTD data, which includes
                  data from all schools attended during the school year, go to a
                  student&apos;s Attendance tab.
                </Box>
              }
            >
              <span>Days Present</span>
            </Tooltip>
          </Box>
        ),
        accessor: 'school_attendance_present',
        includes: 'ada'
      },
      school_attendance_absent: {
        id: 'school_attendance_absent',
        Header: () => (
          <Box>
            <Tooltip
              arrow
              placement="top"
              title={
                <Box>
                  This is the number of Days Absent for this school year at the
                  current school. For a students YTD data, which includes data
                  from all schools attended during the school year, go to a
                  student&apos;s Attendance tab.
                </Box>
              }
            >
              <span>Days Absent</span>
            </Tooltip>
          </Box>
        ),
        accessor: 'school_attendance_absent',
        maxWidth: 100,
        includes: 'ada'
      },
      school_attendance_membership: {
        id: 'school_attendance_membership',
        Header: () => (
          <Box>
            <Tooltip
              arrow
              placement="top"
              title={
                <Box>
                  This is the number of Days in Membership for this school year
                  at the current school. For a student&apos;s YTD data, which
                  includes data from all schools attended during the school
                  year, go to a student&apos;s Attendance tab.
                </Box>
              }
            >
              <span>Days in Membership</span>
            </Tooltip>
          </Box>
        ),
        accessor: 'school_attendance_membership',
        maxWidth: 100,
        includes: 'ada'
      },
      school_attendance_percent: {
        id: 'school_attendance_percent',
        Header: () => (
          <Box>
            <Tooltip
              placement="top"
              title={
                <Box>
                  This is the student&apos;s attendance percentage for this
                  school year at the current school. Both excused and unexcused
                  absences are included in this calculation. For a
                  student&apos;s YTD data, which includes data from all schools
                  attended during the school year, go to a student&apos;s
                  Attendance tab.
                </Box>
              }
            >
              <span>Attendance</span>
            </Tooltip>
          </Box>
        ),
        accessor: obj => obj.school_attendance_percent,
        Cell: props =>
          props.value === null || props.value === undefined ? (
            '--'
          ) : (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
            >
              <Chip
                size="small"
                color={colorAttendance(props.value)}
                label={`${props.value}%`}
                sx={{
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              />
            </Box>
          ),
        width: 105,
        includes: 'ada'
      },
      attendance_prediction: {
        id: 'attendance_prediction',
        Header: () => (
          <Box>
            <Tooltip
              placement="top"
              title="This is the predicted attendance for the upcoming year based on our data model."
            >
              <span>Predicted ADA</span>
            </Tooltip>
          </Box>
        ),
        accessor: 'attendance_prediction',
        Cell: props =>
          props.value == null ? (
            <Chip color="secondary" label="New" />
          ) : (
            <Chip
              color={colorAttendance(props.value)}
              label={`${props.value}%`}
            />
          ),
        maxWidth: 100,
        includes: 'attendance_prediction'
      },
      school_attendance_chronic: {
        id: 'school_attendance_chronic',
        Header: () => (
          <Box>
            <Tooltip
              placement="top"
              title={
                <Box>
                  A student is considered chronic if the student has been
                  enrolled at the current school for at least 20 days and the
                  attendance percentage is less than or equal to 90%.
                </Box>
              }
            >
              <span>Chronic</span>
            </Tooltip>
          </Box>
        ),
        accessor: obj => (obj.school_attendance_chronic ? 'Yes' : 'No'),
        maxWidth: 70,
        sortable: false,
        includes: 'ada'
      },
      school_attendance_weekly_change: {
        id: 'school_attendance_weekly_change',
        Header: '1 Week Change',
        accessor: obj =>
          isNumber(obj.school_attendance_weekly_change)
            ? `${obj.school_attendance_weekly_change}`
            : '--',
        maxWidth: 100,
        Cell: props => {
          return (
            <Stack
              direction="row"
              spacing={1}
              color={props.value >= 0 ? 'success' : 'warning'}
            >
              {props.value >= 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              {Math.abs(props.value)}%
            </Stack>
          )
        },
        includes: 'ada'
      },
      school_attendance_monthly_change: {
        id: 'school_attendance_monthly_change',
        Header: '1 Month Change',
        accessor: obj =>
          isNumber(obj.school_attendance_monthly_change)
            ? `${obj.school_attendance_monthly_change}`
            : '--',
        maxWidth: 100,
        Cell: props => {
          return (
            <Stack
              direction="row"
              spacing={1}
              color={props.value >= 0 ? 'success' : 'warning'}
            >
              {props.value >= 0 ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
              {Math.abs(props.value)}%
            </Stack>
          )
        },
        includes: 'ada'
      },
      quarter_one_present: {
        Header: 'Q1 Days Present',
        accessor: 'school_quarter_one_present',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_one_ada']
      },
      quarter_one_absences: {
        Header: 'Q1 Absences',
        accessor: 'school_quarter_one_absences',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_one_ada']
      },
      quarter_one_membership: {
        Header: 'Q1 Days in Membership',
        accessor: 'school_quarter_one_membership',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_one_ada']
      },
      quarter_one_percent: {
        Header: 'Q1 ADA (%)',
        accessor: 'school_quarter_one_percent',
        maxWidth: 100,
        Cell: props =>
          props.value === null ? (
            '--'
          ) : (
            <Chip
              color={colorAttendance(props.value)}
              label={`${props.value}%`}
            />
          ),
        includes: ['quarterly_ada', 'quarter_one_ada']
      },
      quarter_two_present: {
        Header: 'Q2 Days Present',
        accessor: 'school_quarter_two_present',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_two_ada']
      },
      quarter_two_absences: {
        Header: 'Q2 Absences',
        accessor: 'school_quarter_two_absences',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_two_ada']
      },
      quarter_two_membership: {
        Header: 'Q2 Days in Membership',
        accessor: 'school_quarter_two_membership',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_two_ada']
      },
      quarter_two_percent: {
        Header: 'Q2 ADA (%)',
        accessor: 'school_quarter_two_percent',
        maxWidth: 100,
        Cell: props =>
          props.value === null ? (
            '--'
          ) : (
            <Chip
              color={colorAttendance(props.value)}
              label={`${props.value}%`}
            />
          ),
        includes: ['quarterly_ada', 'quarter_two_ada']
      },
      quarter_three_present: {
        Header: 'Q3 Days Present',
        accessor: 'school_quarter_three_present',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_three_ada']
      },
      quarter_three_absences: {
        Header: 'Q3 Absences',
        accessor: 'school_quarter_three_absences',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_three_ada']
      },
      quarter_three_membership: {
        Header: 'Q3 Days in Membership',
        accessor: 'school_quarter_three_membership',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_three_ada']
      },
      quarter_three_percent: {
        Header: 'Q3 ADA (%)',
        accessor: 'school_quarter_three_percent',
        maxWidth: 100,
        Cell: props =>
          props.value === null ? (
            '--'
          ) : (
            <Chip
              color={colorAttendance(props.value)}
              label={`${props.value}%`}
            />
          ),
        includes: ['quarterly_ada', 'quarter_three_ada']
      },
      quarter_four_present: {
        Header: 'Q4 Days Present',
        accessor: 'school_quarter_four_present',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_four_ada']
      },
      quarter_four_absences: {
        Header: 'Q4 Absences',
        accessor: 'school_quarter_four_absences',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_four_ada']
      },
      quarter_four_membership: {
        Header: 'Q4 Days in Membership',
        accessor: 'school_quarter_four_membership',
        maxWidth: 100,
        includes: ['quarterly_ada', 'quarter_four_ada']
      },
      quarter_four_percent: {
        Header: 'Q4 ADA (%)',
        accessor: 'school_quarter_four_percent',
        maxWidth: 100,
        Cell: props =>
          props.value === null ? (
            '--'
          ) : (
            <Chip
              color={colorAttendance(props.value)}
              label={`${props.value}%`}
            />
          ),
        includes: ['quarterly_ada', 'quarter_four_ada']
      },
      semester_one_present: {
        Header: 'S1 Days Present',
        accessor: 'school_semester_one_present',
        maxWidth: 100,
        includes: ['semesterly_ada', 'semester_one_ada']
      },
      semester_one_absences: {
        Header: 'S1 Absences',
        accessor: 'school_semester_one_absences',
        maxWidth: 100,
        includes: ['semesterly_ada', 'semester_one_ada']
      },
      semester_one_membership: {
        Header: 'S1 Days in Membership',
        accessor: 'school_semester_one_membership',
        maxWidth: 100,
        includes: ['semesterly_ada', 'semester_one_ada']
      },
      semester_one_percent: {
        Header: 'S1 ADA (%)',
        accessor: 'school_semester_one_percent',
        maxWidth: 100,
        Cell: props =>
          props.value === null ? (
            '--'
          ) : (
            <Chip
              color={colorAttendance(props.value)}
              label={`${props.value}%`}
            />
          ),
        includes: ['semesterly_ada', 'semester_one_ada']
      },
      semester_two_present: {
        Header: 'S2 Days Present',
        accessor: 'school_semester_two_present',
        maxWidth: 100,
        includes: ['semesterly_ada', 'semester_two_ada']
      },
      semester_two_absences: {
        Header: 'S2 Absences',
        accessor: 'school_semester_two_absences',
        maxWidth: 100,
        includes: ['semesterly_ada', 'semester_two_ada']
      },
      semester_two_membership: {
        Header: 'S2 Days in Membership',
        accessor: 'school_semester_two_membership',
        maxWidth: 100,
        includes: ['semesterly_ada', 'semester_two_ada']
      },
      semester_two_percent: {
        Header: 'S2 ADA (%)',
        accessor: 'school_semester_two_percent',
        maxWidth: 100,
        Cell: props =>
          props.value === null ? (
            '--'
          ) : (
            <Chip
              color={colorAttendance(props.value)}
              label={`${props.value}%`}
            />
          ),
        includes: ['semesterly_ada', 'semester_two_ada']
      },
      number_of_attendance_concerns: {
        id: 'number_of_attendance_concerns',
        Header: 'Attendance Concerns (#)',
        accessor: obj => obj,
        maxWidth: 100,
        Cell: props => (
          <Link
            to={`/student/${props.value.id}/wellness?concern_type_ids=2&concern_type_ids=55&concern_type_ids=56&status=open`}
            title={'Click to see open attendance concerns.'}
          >
            {props.value.number_of_attendance_concerns}
          </Link>
        ),
        includes: 'attendance_concerns'
      },
      latest_map_math_percentile: {
        Header: 'MAP Math',
        accessor: 'latest_map_math_percentile',
        Cell: props => (props.value ? `${props.value}%` : '--'),
        includes: 'latest_map'
      },
      latest_map_reading_percentile: {
        Header: 'MAP Read',
        accessor: 'latest_map_reading_percentile',
        Cell: props => (props.value ? `${props.value}%` : '--'),
        includes: 'latest_map'
      },
      map_math_fall_percentile: {
        Header: 'MAP Math Fall Percentile',
        accessor: 'map_math_fall_percentile',
        Cell: props => (props.value ? `${props.value}%` : '--'),
        includes: ['map', 'latest_map']
      },
      map_math_winter_percentile: {
        Header: 'MAP Math Winter Percentile',
        accessor: 'map_math_winter_percentile',
        Cell: props => (props.value ? `${props.value}%` : '--'),
        includes: ['map', 'latest_map']
      },
      map_math_spring_percentile: {
        Header: 'MAP Math Spring Percentile',
        accessor: 'map_math_spring_percentile',
        Cell: props => (props.value ? `${props.value}%` : '--'),
        includes: ['map', 'latest_map']
      },
      met_map_math_fall_winter_growth: {
        Header: 'MAP Math Fall-Winter-Growth',
        accessor: 'met_map_math_fall_winter_growth',
        Cell: props => this.sanitizeBoolean(props.value),
        includes: ['map', 'latest_map']
      },
      met_map_math_fall_spring_growth: {
        Header: 'MAP Math Fall-Spring-Growth',
        accessor: 'met_map_math_fall_spring_growth',
        Cell: props => this.sanitizeBoolean(props.value),
        includes: ['map', 'latest_map']
      },
      map_reading_fall_percentile: {
        Header: 'MAP Reading Fall Percentile',
        accessor: 'map_reading_fall_percentile',
        Cell: props => (props.value ? `${props.value}%` : '--'),
        includes: ['map', 'latest_map']
      },
      map_reading_winter_percentile: {
        Header: 'MAP Reading Winter Percentile',
        accessor: 'map_reading_winter_percentile',
        Cell: props => (props.value ? `${props.value}%` : '--'),
        includes: ['map', 'latest_map']
      },
      map_reading_spring_percentile: {
        Header: 'MAP Reading Spring Percentile',
        accessor: 'map_reading_spring_percentile',
        Cell: props => (props.value ? `${props.value}%` : '--'),
        includes: ['map', 'latest_map']
      },
      met_map_reading_fall_winter_growth: {
        Header: 'MAP Reading Fall-Winter-Growth',
        accessor: 'met_map_reading_fall_winter_growth',
        Cell: props => this.sanitizeBoolean(props.value),
        includes: ['map', 'latest_map']
      },
      met_map_reading_fall_spring_growth: {
        Header: 'MAP Reading Fall-Spring-Growth',
        accessor: 'met_map_reading_fall_spring_growth',
        Cell: props => this.sanitizeBoolean(props.value),
        includes: ['map', 'latest_map']
      },
      projected_ostp_level_based_on_latest_map_reading: {
        Header: 'Projected OSTP Level (Reading)',
        accessor: 'projected_ostp_level_based_on_latest_map_reading',
        Cell: props => (props.value ? `${titleize(props.value)}` : '--'),
        includes: 'latest_map'
      },
      projected_ostp_level_based_on_latest_map_math: {
        Header: 'Projected OSTP Level (Math)',
        accessor: 'projected_ostp_level_based_on_latest_map_math',
        Cell: props => (props.value ? `${titleize(props.value)}` : '--'),
        includes: 'latest_map'
      },
      latest_ostp_ela_band: {
        Header: () => {
          return (
            <Tooltip
              id="Latest OSTP ELA Band"
              placement="top"
              arrow
              title={`This is showing the OSTP ELA band/performance level from the ${
                currentSchoolYear - 1
              } school year.`}
            >
              <span>
                Latest OSTP (ELA) <InfoIcon />
              </span>
            </Tooltip>
          )
        },
        accessor: 'latest_ostp_ela_band',
        Cell: props => (props.value ? `${titleize(props.value)}` : '--'),
        includes: 'latest_ostp'
      },
      previous_ostp_ela_band: {
        Header: () => {
          return (
            <Tooltip
              id="Previous OSTP ELA Band"
              placement="top"
              arrow
              title={`This is showing the OSTP ELA band/performance level from the ${
                currentSchoolYear - 2
              } school year.`}
            >
              <span>
                Previous OSTP (ELA) <InfoIcon />
              </span>
            </Tooltip>
          )
        },
        accessor: 'previous_ostp_ela_band',
        Cell: props => (props.value ? `${titleize(props.value)}` : '--'),
        includes: 'previous_ostp'
      },
      latest_ostp_mathematics_band: {
        Header: () => {
          return (
            <Tooltip
              id="Latest OSTP Math Band"
              placement="top"
              arrow
              title={`This is showing the OSTP Math band/performance level from the ${
                currentSchoolYear - 1
              } school year.`}
            >
              <span>
                Latest OSTP (Math) <InfoIcon />
              </span>
            </Tooltip>
          )
        },
        accessor: 'latest_ostp_mathematics_band',
        Cell: props => (props.value ? `${titleize(props.value)}` : '--'),
        includes: 'latest_ostp'
      },
      previous_ostp_mathematics_band: {
        Header: () => {
          return (
            <Tooltip
              id="Previous OSTP Math Band"
              placement="top"
              arrow
              title={`This is showing the OSTP Math band/performance level from the ${
                currentSchoolYear - 2
              } school year.`}
            >
              <span>
                Previous OSTP (Math) <InfoIcon />
              </span>
            </Tooltip>
          )
        },
        accessor: 'previous_ostp_mathematics_band',
        Cell: props => (props.value ? `${titleize(props.value)}` : '--'),
        includes: 'previous_ostp'
      },
      latest_ostp_science_band: {
        Header: () => {
          return (
            <Tooltip
              id="Latest OSTP Science Band"
              placement="top"
              arrow
              title={`This is showing the OSTP Science band/performance level from the ${
                currentSchoolYear - 1
              } school year.`}
            >
              <span>
                Latest OSTP (Science) <InfoIcon />
              </span>
            </Tooltip>
          )
        },
        accessor: 'latest_ostp_science_band',
        Cell: props => (props.value ? `${titleize(props.value)}` : '--'),
        includes: 'latest_ostp'
      },
      previous_ostp_science_band: {
        Header: () => {
          return (
            <Tooltip
              id="Previous OSTP Science Band"
              placement="top"
              arrow
              title={`This is showing the OSTP Science band/performance level from the ${
                currentSchoolYear - 2
              } school year.`}
            >
              <span>
                Previous OSTP (Science) <InfoIcon />
              </span>
            </Tooltip>
          )
        },
        accessor: 'previous_ostp_science_band',
        Cell: props => (props.value ? `${titleize(props.value)}` : '--'),
        includes: 'previous_ostp'
      },
      discipline_score: {
        id: 'discipline_score',
        Header: 'Behavior',
        accessor: 'discipline_score',
        Cell: props => (
          <Chip
            color={colorDiscipline(props.value)}
            label={`${props.value}%`}
          />
        ),
        maxWidth: 100,
        includes: 'discipline_score'
      },
      num_referrals: {
        id: 'referrals_count',
        Header: '# Ref',
        accessor: 'referrals_count',
        maxWidth: 65,
        includes: 'referrals_count'
      },
      num_suspensions: {
        Header: '# Sus',
        accessor: 'suspensions_count',
        maxWidth: 75,
        includes: 'suspensions_count'
      },
      num_days_suspended: {
        Header: '# Days Sus',
        accessor: 'suspensions_days_count',
        maxWidth: 105,
        includes: 'suspensions_count'
      },
      date_of_last_suspension: {
        id: 'date_of_last_suspension',
        Header: () => (
          <Box>
            <Tooltip
              placement="top"
              arrow
              title={
                <Box>
                  The date of the student&apos;s most recent suspension from
                  this school year.
                </Box>
              }
            >
              <span>Date of Last Suspension</span>
            </Tooltip>
          </Box>
        ),
        includes: 'suspensions_count',
        accessor: obj => obj,
        Cell: props =>
          props.value.date_of_last_suspension &&
          moment(props.value.date_of_last_suspension).format('MM/DD/YYYY')
      },
      suspensions_breakdown: {
        id: 'suspensions_breakdown',
        Header: 'Breakdown',
        accessor: obj => obj,
        sortable: false,
        maxWidth: 130,
        Cell: props => (
          <Button
            onClick={() =>
              this.showStudentSuspensionsBreakdownModal(props.value)
            }
          >
            Breakdown
          </Button>
        )
      },
      address_street: {
        Header: 'Street',
        accessor: 'address_street'
      },
      address_zip: {
        Header: 'Zip',
        accessor: 'address_zip'
      },
      address_city: {
        Header: 'City',
        accessor: 'address_city'
      },
      grad_progress: {
        id: 'grad_progress',
        Header: 'Plan Progress',
        accessor: obj => obj,
        sortable: false,
        minWidth: 140,
        includes: 'grad_progress',
        Cell: props => (
          <Stack
            direction={'row'}
            spacing={1}
            alignItems={'center'}
            component={Link}
            to={`/student/${props.value.id}/graduation-progress`}
          >
            {props.value.primary_grad_plan_completion
              ?.effective_credit_capacity ? (
              <ProgressBar
                stored={
                  (props.value.primary_grad_plan_completion.credits_stored +
                    props.value.primary_grad_plan_completion.tests_passed) /
                  (props.value.primary_grad_plan_completion
                    .effective_credit_capacity +
                    props.value.primary_grad_plan_completion.test_capacity)
                }
                enrolled={
                  props.value.primary_grad_plan_completion.credits_enrolled /
                  props.value.primary_grad_plan_completion
                    .effective_credit_capacity
                }
              />
            ) : (
              <ProgressBar stored={0} />
            )}
          </Stack>
        )
      },
      grad_progress_last_emailed_at: {
        id: 'grad_progress_last_emailed_at',
        Header: 'Last Emailed',
        accessor: obj => obj,
        Cell: props =>
          props.value.grad_progress_last_emailed_at &&
          moment(props.value.grad_progress_last_emailed_at).format('MM/DD/YYYY')
      },
      grad_plan: {
        id: 'graduation_plan',
        Header: 'Grad Plan',
        sortable: false,
        accessor: 'plan',
        minWidth: 500,
        includes: 'graduation',
        Cell: props => {
          const hasError =
            !props.value?.includes('2024') ||
            (props.value?.includes('2024') &&
              !props.value?.includes('Alternate') &&
              !props.value?.includes('College Prep/Work-Ready'))

          if (hasError) {
            if (props.value == '2024+ Core Curriculum') {
              return (
                <Box sx={{ display: 'flex' }}>
                  <Tooltip title="Primary grad plan needs to be changed in PowerSchool unless parent/guardian signature is on file.">
                    <ErrorIcon
                      color="warning"
                      sx={{ verticalAlign: 'middle' }}
                    />
                  </Tooltip>
                  <div style={{ verticalAlign: 'middle' }}>{props.value}</div>
                </Box>
              )
            } else {
              return (
                <Box sx={{ display: 'flex' }}>
                  <Tooltip title="Primary grad plan needs to be changed in PowerSchool.">
                    <ErrorIcon color="error" sx={{ verticalAlign: 'middle' }} />
                  </Tooltip>
                  <div style={{ verticalAlign: 'middle' }}>{props.value}</div>
                </Box>
              )
            }
          } else {
            return props.value
          }
        }
      },
      graduation_status: {
        id: 'graduation_status',
        Header: 'Status',
        sortable: false,
        accessor: 'graduation_status',
        minWidth: 125,
        includes: 'graduation',
        Cell: GraduationStatusChipCell
      },
      cpr: {
        id: 'cpr',
        Header: 'CPR',
        accessor: 'cpr',
        maxWidth: 55,
        Cell: props => checkMark(props.value),
        includes: 'graduation'
      },
      pfl: {
        id: 'financial_literacy',
        Header: 'PFL',
        accessor: 'financial_literacy',
        maxWidth: 55,
        Cell: props => checkMark(props.value),
        includes: 'graduation'
      },
      cohort: {
        Header: 'Cohort',
        accessor: 'cohort',
        includes: 'graduation',
        maxWidth: 60
      },
      gpa: {
        Header: 'GPA',
        accessor: 'gpa',
        includes: 'graduation',
        maxWidth: 60
      },
      ok_promise_gpa: {
        Header: 'OK Promise GPA',
        accessor: 'ok_promise_gpa',
        includes: 'graduation'
      },
      sat_composite: {
        id: 'sat_composite',
        Header: 'SAT',
        accessor: 'sat_composite',
        maxWidth: 60,
        includes: 'graduation'
      },
      graduation_credits: {
        Header: 'Credits',
        accessor: 'graduation_credits',
        includes: 'graduation',
        maxWidth: 60
      },
      failed_core_courses_count: {
        Header: 'Failed Core Courses',
        id: 'failed_core_courses_count',
        sortable: false,
        accessor: obj => obj,
        Cell: props => <RiskStatusPopover student={props.original} />,
        includes: 'graduation'
      },
      student_notes: {
        id: 'student_notes',
        Header: 'Notes',
        accessor: obj => obj,
        Cell: props => <StudentNotesModal student={props.original} />,
        sortable: false
      },
      yearly_major_discipline_count: {
        id: 'major_discipline_count',
        Header: () => (
          <Box>
            <Tooltip
              placement="top"
              arrow
              title={
                <Box>
                  The number of suspensions with a code of 300 or higher from
                  this school year.
                </Box>
              }
            >
              <span>Yearly Major Discipline (#)</span>
            </Tooltip>
          </Box>
        ),
        accessor: 'major_discipline_count',
        Cell: ({ value }) => this.renderChip(value, 5, 2),
        includes: 'behavior_tier_data'
      },
      yearly_in_class_discipline_count: {
        id: 'in_class_discipline_count',
        Header: () => (
          <Box>
            <Tooltip
              arrow
              placement="top"
              title={<Box>The number of referrals from this school year.</Box>}
            >
              <span>Yearly In-Class Discipline (#)</span>
            </Tooltip>
          </Box>
        ),
        accessor: 'in_class_discipline_count',
        Cell: ({ value }) => this.renderChip(value, 15, 5),
        includes: 'behavior_tier_data'
      },
      active_behavior_concerns_with_medium_severity_count: {
        id: 'active_behavior_concerns_with_medium_severity_count',
        Header: () => (
          <Tooltip
            id="Severity (Behavior Only)"
            placement="top"
            arrow
            title={`New or existing behavior concerns are defaulted to a
              severity of Low. As a behavior worsens or is
              reaffirmed by other staff at your site, you can
              elevate the concern's severity to Medium or High so
              that the concern is surfaced to your school's
              Leadership or Student Success Teams.`}
          >
            <span>
              Active Behavior Concerns - Medium Severity (#) <InfoIcon />
            </span>
          </Tooltip>
        ),
        accessor: 'active_behavior_concerns_with_medium_severity_count',
        Cell: ({ value }) => this.renderChip(value, Infinity, 1),
        includes: 'behavior_tier_data'
      },
      active_behavior_concerns_with_high_severity_count: {
        id: 'active_behavior_concerns_with_high_severity_count',
        Header: () => (
          <Tooltip
            id="Severity (Behavior Only)"
            placement="top"
            arrow
            title={`New or existing behavior concerns are defaulted to a
              severity of Low. As a behavior worsens or is
              reaffirmed by other staff at your site, you can
              elevate the concern's severity to Medium or High so
              that the concern is surfaced to your school's
              Leadership or Student Success Teams.`}
          >
            <span>
              Active Behavior Concerns - High Severity (#) <InfoIcon />
            </span>
          </Tooltip>
        ),
        accessor: 'active_behavior_concerns_with_high_severity_count',
        Cell: ({ value }) => this.renderChip(value, 1, Infinity),
        includes: 'behavior_tier_data'
      },
      yearly_in_school_suspension_days_count: {
        id: 'in_school_suspension_days_count',
        Header: () => (
          <Box>
            <Tooltip
              arrow
              placement="top"
              title={
                <Box>
                  The total number of discipline duration days assigned to{' '}
                  <u>referrals</u> and <u>suspensions</u> with a consequence of
                  &quot;Suspension, in-school^&quot; for this school year.
                </Box>
              }
            >
              <span>Yearly In-School Suspension Days (#)</span>
            </Tooltip>
          </Box>
        ),
        accessor: 'in_school_suspension_days_count',
        Cell: ({ value }) => this.renderChip(value, 5, 2),
        includes: 'behavior_tier_data'
      },
      yearly_out_of_school_suspension_days_count: {
        id: 'out_of_school_suspension_days_count',
        Header: () => (
          <Box>
            <Tooltip
              arrow
              key="YearlyOutOfSchoolSuspensionDays"
              placement="top"
              title={
                <Box>
                  The total number of discipline duration days assigned to{' '}
                  <u>suspensions</u> with a consequence other than
                  &quot;Suspension, in-school^&quot; for this school year.
                </Box>
              }
            >
              <span>Yearly Out-of-School Suspension Days (#)</span>
            </Tooltip>
          </Box>
        ),
        accessor: 'out_of_school_suspension_days_count',
        Cell: ({ value }) => this.renderChip(value, 5, 2),
        includes: 'behavior_tier_data'
      },
      advanced_placement: {
        id: 'advanced_placement',
        Header: 'AP',
        accessor: 'advanced_placement',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        includes: 'postsecondary_data'
      },
      international_baccalaureate_program: {
        id: 'international_baccalaureate_program',
        Header: 'IB',
        accessor: 'international_baccalaureate_program',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        includes: 'postsecondary_data'
      },
      college_level: {
        id: 'college_level',
        Header: 'College Level',
        accessor: 'college_level',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        includes: 'postsecondary_data'
      },
      college_ready_map: {
        id: 'college_ready_map',
        Header: 'College Ready (MAP)',
        accessor: 'college_ready_map',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        includes: 'postsecondary_data'
      },
      assessment_requirements_post_sec: {
        id: 'assessment_requirements_post_sec',
        Header: 'Assessment Requirements Met',
        accessor: 'assessment_requirements_post_sec',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        includes: 'postsecondary_data'
      },
      ap_potential_based_on_psat: {
        id: 'ap_potential_based_on_psat',
        Header: 'AP Ready (PSAT)',
        accessor: 'ap_potential_based_on_psat',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        includes: 'postsecondary_data'
      },
      stepmojo_student: {
        id: 'stepmojo_student',
        Header: 'Stepmojo',
        accessor: 'stepmojo_student',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        includes: 'postsecondary_data'
      },
      career_tech_student: {
        id: 'career_tech_student',
        Header: 'Career Tech',
        accessor: 'career_tech_student',
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
        includes: 'postsecondary_data'
      },
      access_expected_growth: {
        Header: 'Access - Expected Growth',
        accessor: 'expected_growth',
        includes: 'latest_access'
      },
      access_growth_made: {
        Header: 'Access - Growth Made',
        accessor: 'growth_made',
        includes: 'latest_access'
      },
      access_growth_goal_met: {
        id: 'access_growth_goal_met',
        Header: 'Access - Growth Goal Met',
        accessor: 'met_growth',
        Cell: ({ value }) => this.sanitizeBoolean(value),
        includes: 'latest_access',
        sortable: false
      },
      us_civics_status: {
        Header: 'U.S. Civics Knowledge Assessment',
        accessor: 'us_civics_status',
        includes: 'us_civics_status'
      },
      current_academic_intervention_platform: {
        id: 'current_academic_intervention_platform',
        Header: 'Academic Intervention Platform',
        accessor: 'current_platform',
        Cell: ({ value }) => titleize(value),
        includes: 'academic_interventions'
      },
      weeks_met_threshold_on_intervention_platform: {
        id: 'weeks_met_threshold_on_intervention_platform',
        Header: 'Weeks Met Threshold',
        accessor: obj => obj,
        Cell: props =>
          props.value.week_count
            ? `${props.value.met_threshold_count} / ${props.value.week_count}`
            : null,
        includes: 'academic_interventions'
      },
      grad_progress_last_event_at: {
        id: 'grad_progress_last_event_at',
        Header: 'Grad Progress Last Mailer Event',
        accessor: 'grad_progress_last_event_at',
        sortable: false,
        includes: 'mailer_event',
        Cell: props =>
          props.value ? moment(props.value).format('MM/DD/YYYY') : null
      },
      last_four_weeks_met_threshold_on_intervention_platform: {
        id: 'last_four_weeks_met_threshold_on_intervention_platform',
        Header: () => (
          <Typography>
            Last 4 Weeks{' '}
            <Tooltip
              title={
                <Typography>
                  Green: Met threshold
                  <br />
                  Yellow: Did not meet threshold
                  <br />
                  Red: Did not use platform
                  <br />
                  Grey: No assigned platform
                </Typography>
              }
              arrow
              placement="top"
            >
              <QuestionMark fontSize={'inherit'} />
            </Tooltip>
          </Typography>
        ),
        accessor: obj => obj,
        Cell: props => {
          const usage = props.value.weekly_usage

          const lastFourWeeks = usage?.slice(Math.max(usage.length - 4, 0))

          const threshold_met_color = (platform, thresholdMet) => {
            // no platform for week
            if (platform === null) {
              return colors.grey[400] //'disabled'
            }

            // no usage for week
            if (thresholdMet === null) {
              return colors.red[600] //'disabled'
            }

            return thresholdMet ? colors.green[600] : colors.amber[600]
          }

          return (
            <Stack direction={'row'} spacing={1}>
              {lastFourWeeks?.map(r => (
                <Avatar
                  key={r.week_number}
                  title={`Week ${r.week_number}`}
                  alt={`Week ${r.week_number}`}
                  sx={{
                    bgcolor: threshold_met_color(r.platform, r.threshold_met),
                    width: 24,
                    height: 24,
                    fontSize: '80%'
                  }}
                >
                  {r.week_number}
                </Avatar>
              ))}
            </Stack>
          )
        },
        includes: 'academic_interventions',
        sortable: false
      },
      student_success_plan_count: {
        id: 'student_success_plan_count',
        Header: 'Student Success Plans (#)',
        accessor: 'ssp_count',
        includes: 'attendance_concerns'
      },
      homeroom_teacher: {
        id: 'homeroom_teacher',
        Header: 'Homeroom Teacher',
        accessor: 'homeroom_teacher_name',
        includes: 'teacher'
      },
      tps_grad_fafsa: {
        id: 'tps_grad_fafsa',
        Header: 'FAFSA',
        accessor: obj => obj,
        includes: 'grad_progress',
        Cell: props => props.value.non_academic_requirements?.tps_grad_fafsa
      },
      tps_grad_flp: {
        id: 'tps_grad_flp',
        Header: 'FLP',
        accessor: obj => obj,
        includes: 'grad_progress',
        Cell: props => props.value.non_academic_requirements?.tps_grad_flp
      },
      tps_grad_cpr: {
        id: 'tps_grad_cpr',
        Header: 'CPR',
        accessor: obj => obj,
        includes: 'grad_progress',
        Cell: props => props.value.non_academic_requirements?.tps_grad_cpr
      },
      tps_grad_uscivics: {
        id: 'tps_grad_uscivics',
        Header: 'US Civics',
        accessor: obj => obj,
        includes: 'grad_progress',
        Cell: props => props.value.non_academic_requirements?.tps_grad_uscivics
      },
      tps_grad_icap9: {
        id: 'tps_grad_icap9',
        Header: 'ICAP 9',
        accessor: obj => obj,
        includes: 'grad_progress',
        Cell: props => props.value.non_academic_requirements?.tps_grad_icap9
      },
      tps_grad_icap10: {
        id: 'tps_grad_icap10',
        Header: 'ICAP 10',
        accessor: obj => obj,
        includes: 'grad_progress',
        Cell: props => props.value.non_academic_requirements?.tps_grad_icap10
      },
      tps_grad_icap11: {
        id: 'tps_grad_icap11',
        Header: 'ICAP 11',
        accessor: obj => obj,
        includes: 'grad_progress',
        Cell: props => props.value.non_academic_requirements?.tps_grad_icap11
      },
      tps_grad_icap12: {
        id: 'tps_grad_icap12',
        Header: 'ICAP 12',
        accessor: obj => obj,
        includes: 'grad_progress',
        Cell: props => props.value.non_academic_requirements?.tps_grad_icap12
      },
      tps_grad_icap_wbl: {
        id: 'tps_grad_icap_wbl',
        Header: 'ICAP WBL',
        accessor: obj => obj,
        includes: 'grad_progress',
        Cell: props => props.value.non_academic_requirements?.tps_grad_icap_wbl
      },
      approximate_fay_status: {
        Header: () => (
          <Box>
            <Tooltip
              placement="top"
              arrow
              title={
                <Box>
                  FAY - Enrolled the first 20 days at the school and no
                  enrollment gap of 10 or more days, corresponds with OSDE
                  number 0.
                  <br />
                  NFAY - Not enrolled the first 20 days at the school and/or an
                  enrollment gap of 10 or more days, corresponds with OSDE
                  numbers 1, 2, or 3.
                </Box>
              }
            >
              <span>
                Approximate FAY Status <InfoIcon />
              </span>
            </Tooltip>
          </Box>
        ),
        accessor: 'approximate_fay_status'
      }
    }

    renderSuccessMessage = () => {
      const { studentName } = this.state
      alert(`Note successfully created for ${studentName}`, 'success')
    }

    isStudentSelected = id => this.state.selectedStudentIds.includes(id)

    toggleStudentSelected = id => {
      let studentIds = [...this.state.selectedStudentIds]
      if (studentIds.includes(id)) {
        studentIds = studentIds.filter(studentId => studentId !== id)
      } else {
        studentIds.push(id)
      }

      this.selectedStudentIdsChanged(studentIds)
    }

    toggleAllSelected = e => {
      let ids = []
      ids = this.state.students.map(student => student.id)
      if (e.target.checked) {
        ids = this.state.students.map(student => student.id)
      }
      this.selectedStudentIdsChanged(ids)
    }

    selectAllOnPage = () => {
      let studentIds = [...this.state.selectedStudentIds]
      let newStudentids = this.state.students.map(student => student.id)
      this.selectedStudentIdsChanged([...studentIds, ...newStudentids])
    }

    selectAllOnAllPages = () => {
      this.selectedStudentIdsChanged(['all'])
    }

    clearSelectAllOnPage = () => {}

    clearAllSelectedStudentIds = () => {
      this.selectedStudentIdsChanged([])
    }

    selectedStudentIdsChanged = selectedStudentIds => {
      this.setState({ selectedStudentIds })
    }

    setStudents = students => this.setState({ students })

    showStudentSuspensionsBreakdownModal = student => {
      const { onRowInteraction } = this.props
      onRowInteraction && onRowInteraction('suspensionBreakdownOpen', student)
    }

    // takes the passed in columns (eg. ['first_name', 'id']) and generates actual
    // table columns with the definitions above (eg. [{ accessor: 'first_name', sortable: true } ...])
    buildColumns = columns => {
      let key, columnDef
      return columns.map(column => {
        if (typeof column === 'string') {
          key = column
          columnDef = this.columnDefinitions[column]
        } else if (column && typeof column.key === 'string') {
          key = column.key
          const definition = this.columnDefinitions[column.key]
          columnDef = { ...definition, ...column } // merge object into def
        } else {
          // a custom column definition or nested columns
          if (column.columns) {
            // nested columns!
            column.columns = this.buildColumns(column.columns)
          }

          return column
        }

        if (!columnDef) {
          throw new Error(
            'Tried to generate students table with ' +
              `unrecognized column label: ${key}. Add the column ` +
              'definition in students-table/table or pass an object that ' +
              'represents the full custom column definition.'
          )
        }

        return columnDef
      })
    }

    render() {
      const { columns, ...props } = this.props
      const { selectedStudentIds, students } = this.state
      const builtColumns = this.buildColumns(this.props.columns)
      const apiIncludes = determineIncludesFromColumns(builtColumns)
      const clearAllSelectedStudentIds = this.clearAllSelectedStudentIds
      const selectAllOnPage = this.selectAllOnPage
      const selectAllOnAllPages = this.selectAllOnAllPages

      return (
        <Box>
          <Component
            columns={this.buildColumns(columns)}
            columnApiIncludes={apiIncludes}
            selectedStudentIdsChanged={this.selectedStudentIdsChanged}
            selectedStudentIds={selectedStudentIds}
            clearAllSelectedStudentIds={clearAllSelectedStudentIds}
            selectAllOnPage={selectAllOnPage}
            selectAllOnAllPages={selectAllOnAllPages}
            setStudents={this.setStudents}
            students={students}
            {...props}
          />
        </Box>
      )
    }
  }
}
