import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { meetingRoles } from 'utils/enum-options'
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack
} from '@mui/material'

const AttendeeForm = ({ filteredAttendeeOptions, addAttendeeFromForm }) => {
  const [selectedAttendeeId, setSelectedAttendeeId] = useState(null)
  const [selectedRole, setSelectedRole] = useState('')
  const getAttendeeNameById = id =>
    filteredAttendeeOptions.find(option => option.id === id).name

  const addAttendee = () => {
    if (selectedRole === '' || selectedAttendeeId === null) {
      return
    }

    const newAttendee = {
      id: null,
      user_id: selectedAttendeeId,
      role: selectedRole,
      name: getAttendeeNameById(selectedAttendeeId)
    }

    addAttendeeFromForm(newAttendee)
  }

  return (
    <Stack>
      <FormControl>
        <InputLabel id="add-meeting-attendee-label">
          Add Meeting Attendees
        </InputLabel>
        <Select
          label="Add Meeting Attendees"
          labelId="add-meeting-attendee-label"
          id="add-meeting-attendee"
          placeholder="Select Attendee"
          onChange={event => setSelectedAttendeeId(event.target.value)}
        >
          {filteredAttendeeOptions.map(option => (
            <MenuItem key={`attendee-${option.id}`} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl>
        <InputLabel id="select-role-label">Select Role</InputLabel>
        <Select
          id="select-role"
          label="Select Role"
          labelId="select-role-label"
          placeholder="Select Role"
          onChange={event => setSelectedRole(event.target.value)}
        >
          {meetingRoles.map(option => (
            <MenuItem key={`attendee-${option.value}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" onClick={addAttendee}>
        Add
      </Button>
    </Stack>
  )
}

AttendeeForm.propTypes = {
  filteredAttendeeOptions: PropTypes.array.isRequired,
  addAttendeeFromForm: PropTypes.func.isRequired
}

export default AttendeeForm
