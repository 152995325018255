import React from 'react'
import PropTypes from 'prop-types'
import StudentsTable from 'components/shared/students-table'
import Tooltip from 'components/shared/tooltip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

const metGoal = (subject, term, met) => {
  const shortTerm = term === 'Winter' ? 'W' : 'S'
  if (met === null) return null

  return (
    <Tooltip
      id={`met-${subject}-fall-${term}-growth`}
      content={`${met ? 'Met' : 'Did not meet'} Fall-${term} growth goal`}
    >
      <span>
        {shortTerm}&nbsp;
        {met ? (
          <CheckCircleIcon sx={{ marginRight: 1 }} color="success" />
        ) : (
          <CancelIcon sx={{ marginRight: 1 }} color="error" />
        )}
      </span>
    </Tooltip>
  )
}

function MapTable({ sectionId, teacher, year }) {
  const columns = [
    {
      Header: 'Student',
      sortable: false,
      columns: ['selector', 'last_name', 'first_name']
    },
    {
      Header: 'Math Percentiles/Growth',
      sortable: false,
      columns: [
        {
          key: 'map_math_fall_percentile',
          Header: 'Fall',
          maxWidth: 60
        },
        {
          key: 'map_math_winter_percentile',
          Header: 'Wint.',
          maxWidth: 65
        },
        {
          key: 'map_math_spring_percentile',
          Header: 'Spring',
          maxWidth: 75
        },
        {
          id: 'math_growth',
          Header: 'Growth',
          accessor: obj => obj,
          sortable: false,
          maxWidth: 90,
          Cell: p => {
            return (
              <span>
                {metGoal(
                  'math',
                  'Winter',
                  p.value.met_map_math_fall_winter_growth
                )}
                {metGoal(
                  'math',
                  'Spring',
                  p.value.met_map_math_fall_spring_growth
                )}
              </span>
            )
          }
        }
      ]
    },
    {
      Header: 'Reading Percentiles/Growth',
      sortable: false,
      columns: [
        {
          key: 'map_reading_fall_percentile',
          Header: 'Fall',
          maxWidth: 60
        },
        {
          key: 'map_reading_winter_percentile',
          Header: 'Wint.',
          maxWidth: 65
        },
        {
          key: 'map_reading_spring_percentile',
          Header: 'Spring',
          maxWidth: 75
        },
        {
          id: 'reading_growth',
          Header: 'Growth',
          accessor: obj => obj,
          sortable: false,
          maxWidth: 90,
          Cell: p => {
            return (
              <span>
                {metGoal(
                  'reading',
                  'Winter',
                  p.value.met_map_reading_fall_winter_growth
                )}
                {metGoal(
                  'reading',
                  'Spring',
                  p.value.met_map_reading_fall_spring_growth
                )}
              </span>
            )
          }
        }
      ]
    }
  ]

  return (
    <div>
      <StudentsTable
        columns={columns}
        filters={{
          section_id: sectionId || '',
          teacher_id: teacher.id,
          school_year: year
        }}
        teacherPage={true}
        teacher={teacher}
      />
    </div>
  )
}

MapTable.propTypes = {
  sectionId: PropTypes.number,
  teacher: PropTypes.object.isRequired,
  year: PropTypes.number
}

export default MapTable
