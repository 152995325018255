import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'utils/ajax'
import StudentsTable from './table'
import StudentsTableFilters from './filters'
import { connect } from 'react-redux'
import { withBuiltColumns } from './with-built-columns'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from 'hooks/useDebounce'
import { Box, Button, Stack, Grid, Popover, Typography } from '@mui/material'
import AttendanceAtRiskLegend from '../../students/attendance-at-risk-legend'
import BehaviorScoreLegend from '../../students/behavior-score-legend'
import GraduationStatusLegend from '../../students/graduation-status-legend'
import StudentsCsvLink from './students-csv-link'
import LetterGeneratorButton from 'components/students/letter_generator_button'
import BulkNoteGeneratorButton from 'components/students/bulk-note-generator-button'

const reduceApiIncludes = columnApiIncludes =>
  columnApiIncludes.reduce((hash, include) => {
    hash[`include_${include}`] = true
    return hash
  }, {})

function StudentsTableIndex({
  filters = {},
  setStudents,
  columns,
  columnApiIncludes,
  selectedStudentIds,
  students,
  refresh,
  teacherPage,
  teacher,
  setActiveColumnSet,
  activeColumnSet,
  includeLegends = false,
  schoolId,
  clearAllSelectedStudentIds,
  selectAllOnPage,
  selectAllOnAllPages
}) {
  const [search, setSearch] = useState('')
  const defaultParams = { page: 1, per_page: 20 }
  const [fetchStudentAttrs, setFetchStudentAttrs] = useState(defaultParams)
  const [fetchStudentParams, setFetchStudentParams] = useState({
    ...filters,
    ...reduceApiIncludes(columnApiIncludes),
    ...defaultParams,
    search
  })

  const [bulkActionAnchorEl, setBulkActionAnchorEl] = useState(null)

  const bulkActionOpenPopover = event => {
    setBulkActionAnchorEl(event.currentTarget)
  }

  const bulkActionClosePopover = () => {
    setBulkActionAnchorEl(null)
  }

  const isBulkActionPopoverOpen = Boolean(bulkActionAnchorEl)
  const bulkActionPopoverId = isBulkActionPopoverOpen
    ? 'bulk-actions-popover'
    : undefined

  const debouncedSearch = useDebounce(search, 300)
  const debouncedFilters = useDebounce(filters, 300)

  const {
    data: { pages, total: totalResults },
    isFetching
  } = useQuery(
    ['fetch-students-table', fetchStudentParams],
    async () => {
      const response = await get('/api/web/students', {
        params: fetchStudentParams
      })
      const students = response.data
      setStudents(students)
      return { students, ...response.meta }
    },
    {
      initialData: {},
      refetchOnWindowFocus: false
    }
  )

  const fetchStudents = attrs => {
    setFetchStudentAttrs(attrs)
    setFetchStudentParams({
      ...filters,
      ...reduceApiIncludes(columnApiIncludes),
      ...attrs,
      search
    })
  }

  useEffect(() => {
    fetchStudents(fetchStudentAttrs)
  }, [debouncedSearch, debouncedFilters, columnApiIncludes, refresh])

  if (!columns.length) {
    return null
  }

  return (
    <Stack spacing={2}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={8}>
          <StudentsTableFilters
            search={search}
            searchChanged={event => setSearch(event.target.value)}
            columns={columns}
            selectedStudentIds={selectedStudentIds}
            teacherPage={teacherPage}
            teacher={teacher}
            setActiveColumnSet={setActiveColumnSet}
            activeColumnSet={activeColumnSet}
          />
        </Grid>

        <Grid item xs={6} md={4}>
          <Stack spacing={1}>
            {selectedStudentIds?.length > 0 && (
              <Stack id="bulk-actions-for-selected-students" spacing={1}>
                <Typography variant="overline">
                  Selected Students:{' '}
                  {selectedStudentIds.includes('all')
                    ? totalResults
                    : selectedStudentIds.length}
                </Typography>

                <Button
                  variant="contained"
                  color="secondary"
                  aria-describedby={bulkActionPopoverId}
                  onClick={bulkActionOpenPopover}
                >
                  Bulk Actions
                </Button>
                <Popover
                  id={bulkActionPopoverId}
                  open={isBulkActionPopoverOpen}
                  anchorEl={bulkActionAnchorEl}
                  onClose={bulkActionClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <Box sx={{ p: 2, width: 200 }}>
                    <Stack>
                      <Typography variant="h6">Bulk Actions</Typography>
                      <Typography>
                        Select an action to apply to multiple students.
                      </Typography>

                      {totalResults > 0 && (
                        <Box>
                          <StudentsCsvLink
                            columns={columns}
                            filters={filters}
                            columnApiIncludes={columnApiIncludes}
                            selectedStudentIds={selectedStudentIds}
                            schoolId={schoolId}
                          />
                        </Box>
                      )}

                      {!!columns.find(c => c.id === 'id') && (
                        <>
                          <Box>
                            <LetterGeneratorButton
                              studentIds={selectedStudentIds}
                              totalResults={totalResults}
                              filters={filters}
                              columnApiIncludes={columnApiIncludes}
                            />
                          </Box>

                          <Box>
                            <BulkNoteGeneratorButton
                              studentIds={selectedStudentIds}
                              totalResults={totalResults}
                              filters={filters}
                              columnApiIncludes={columnApiIncludes}
                            />
                          </Box>
                        </>
                      )}
                    </Stack>
                  </Box>
                </Popover>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Stack direction="row">
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={selectAllOnAllPages}
          >
            Select All On All Pages
          </Button>
        </Box>

        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={selectAllOnPage}
            disabled={selectedStudentIds.includes('all')}
          >
            Select All On Page
          </Button>
        </Box>

        {selectedStudentIds?.length > 0 && (
          <Box>
            <Button
              color="warning"
              variant="outlined"
              onClick={clearAllSelectedStudentIds}
            >
              Clear All
            </Button>
          </Box>
        )}
      </Stack>

      <StudentsTable
        columns={columns}
        data={students}
        totalResults={totalResults}
        totalPages={pages}
        loading={isFetching}
        fetchData={fetchStudents}
        clearAllSelectedStudentIds={clearAllSelectedStudentIds}
        selectedStudentIds={selectedStudentIds}
      />

      {includeLegends && (
        <Stack direction={'row'}>
          {columns.includes('school_attendance_percent') && (
            <AttendanceAtRiskLegend />
          )}
          {columns.includes('discipline_score') && <BehaviorScoreLegend />}
          {columns.includes('graduation_status') && <GraduationStatusLegend />}
        </Stack>
      )}
    </Stack>
  )
}

StudentsTableIndex.propTypes = {
  filters: PropTypes.object,
  // These columns are from the withBuiltColumns higher-order component:
  setStudents: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnApiIncludes: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedStudentIdsChanged: PropTypes.func,
  selectedStudentIds: PropTypes.array,
  students: PropTypes.arrayOf(PropTypes.object).isRequired,
  refresh: PropTypes.any, // change this to force a table refresh (eg. incrementing number)
  teacherPage: PropTypes.bool,
  teacher: PropTypes.object,
  setActiveColumnSet: PropTypes.func,
  activeColumnSet: PropTypes.string,
  includeLegends: PropTypes.bool,
  children: PropTypes.node,
  clearAllSelectedStudentIds: PropTypes.func.isRequired,
  selectAllOnPage: PropTypes.func.isRequired,
  selectAllOnAllPages: PropTypes.func.isRequired
}

const mapStateToProps = (state, ownProps) => ({
  filters: {
    schoolId: state.schools.schoolId,
    ...state.studentFilters.studentFilters,
    ...ownProps.filters
  }
})

export default connect(mapStateToProps)(withBuiltColumns(StudentsTableIndex))
