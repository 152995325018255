import moment from 'moment'

export const DATE_FORMAT = 'M/D/YY'
export const TIME_FORMAT = 'h:mm a'
export const WEEK_OPTION_DATE_FORMAT = 'ddd, MMM D'
export const WEEK_FORMAT = 'GGGG[W]WW'

export { formatTime as timeOfDay }
export function formatTime(date) {
  return moment(date).format(TIME_FORMAT)
}

export function formatDate(date) {
  return moment(date).format(DATE_FORMAT)
}

export function formatDateTime(date) {
  return moment(date).format(`${DATE_FORMAT} ${TIME_FORMAT}`)
}

export function renderDateTimes({ date, start_time, end_time }) {
  return `${formatDate(date)} ${formatTime(start_time)} - ${formatTime(
    end_time
  )}`
}

export function getHours(time) {
  return moment(time).get('hours')
}

export function getMinutes(time) {
  return moment(time).get('minutes')
}

export function weeksBack(from, start) {
  if (from.isBefore(start)) {
    throw new Error('from must not be before start')
  }

  const weeks = []

  while (from.isSameOrAfter(start)) {
    weeks.push(from.format(WEEK_FORMAT))
    from.subtract(1, 'week')
  }

  return weeks
}

export function makeWeekOptions(academic_year) {
  const now = moment()
  const start = moment(academic_year.start_date)
  const end = moment(academic_year.end_date)
  const from = end.isBefore(now) ? end : now

  return weeksBack(from, start).map(week => {
    const x = moment(week)
    return [
      week,
      x.day(1).format(WEEK_OPTION_DATE_FORMAT),
      x.day(7).format(WEEK_OPTION_DATE_FORMAT)
    ]
  })
}
