import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { Route, Switch } from 'react-router-dom'
import Tabs from 'components/shared/tabs'
import TouchpointCard from './touchpoint-card'
import LookForCard from './look-for-card'
import { connect } from 'react-redux'
import AbsoluteSpinner from 'components/shared/spinner/absolute'
import { getTeacherInfo } from 'api/teachers'
import { getTouchpointObservationIndicators } from 'api/touchpoints'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import ObservationDialog from './observation-dialog'
import { groupBy } from 'lodash'

const makeTabs = ({ baseUrl, user }) => [
  { label: 'Latest Observations', to: baseUrl },
  {
    label: 'Look-For Indicators',
    to: `${baseUrl}/look-for-indicators`,
    access: () => user.role !== 'teacher'
  }
]

function ObservationsTab({ teacherId, user }) {
  const baseUrl = `/teachers/${teacherId}/observations`
  const tabs = React.useMemo(
    () => makeTabs({ baseUrl, user }),
    [baseUrl, user.role]
  )
  const include_sections = true
  const include_observations = true

  // State

  const [isCreatingObservation, setIsCreatingObservation] = useState(false)
  const [isViewOnly, setIsViewOnly] = useState(false)
  const [currentObservation, setCurrentObservation] = useState(null)

  // Query
  const queryClient = useQueryClient()

  const teacherInfoQuery = useQuery(
    ['teacher-info', { teacherId, include_sections, include_observations }],
    async () => {
      const response = await getTeacherInfo(
        teacherId,
        {
          include_sections,
          include_observations
        },
        {
          alert: false
        }
      )

      return {
        teacher: response.teacher,
        observations: response.teacher.observations,
        indicators: response.teacher.indicators
      }
    },
    {
      enabled: Boolean(teacherId)
    }
  )

  const indicatorGroupsQuery = useQuery(
    ['teacher-observation-indicators', teacherId],
    async () => {
      const response = await getTouchpointObservationIndicators(teacherId)
      const groups = groupBy(response.data, 'touchpoint_indicator_id')

      return Object.values(groups)
    },
    {
      enabled: Boolean(teacherId)
    }
  )

  // Handlers

  function onClose({ invalidate = false } = {}) {
    if (invalidate) {
      queryClient.invalidateQueries('teacher-info')
      queryClient.invalidateQueries('teacher-observation-indicators')
    }

    setIsCreatingObservation(false)
    setIsViewOnly(false)
    setCurrentObservation(null)
  }

  function onEdit(observation) {
    setIsCreatingObservation(false)
    setIsViewOnly(false)
    setCurrentObservation(observation)
  }

  function onShow(observation) {
    setIsCreatingObservation(false)
    setIsViewOnly(true)
    setCurrentObservation(observation)
  }

  function onCreate() {
    setIsCreatingObservation(true)
    setIsViewOnly(false)
    setCurrentObservation(null)
  }

  // Render

  if (teacherInfoQuery.isError || indicatorGroupsQuery.isError) {
    // TODO: something better with errors
    return 'Error!'
  }

  if (
    teacherInfoQuery.isLoading ||
    !teacherInfoQuery.data ||
    indicatorGroupsQuery.isLoading ||
    !indicatorGroupsQuery.data
  ) {
    return <AbsoluteSpinner size={50} />
  }

  const { teacher, observations } = teacherInfoQuery.data

  return (
    <div
      style={{
        marginTop: '-11px',
        paddingTop: '35px',
        height: '100%'
      }}
    >
      {/* TODO: we should try to make the dialog composable and send the form in as children */}
      <ObservationDialog
        open={
          !isViewOnly && Boolean(currentObservation || isCreatingObservation)
        }
        observation={currentObservation}
        observerId={user.id}
        teacher={teacher}
        onClose={onClose}
        isViewOnly={false}
      />

      {/* TODO: we should try to make the dialog composable and send the view in as children */}
      <ObservationDialog
        open={isViewOnly && Boolean(currentObservation)}
        observation={currentObservation}
        observerId={user.id}
        teacher={teacher}
        onClose={onClose}
        isViewOnly={true}
      />

      <Grid container justifyContent="space-between" alignItems={'center'}>
        <Grid item>
          <Tabs tabs={tabs} />
        </Grid>
        {user.role !== 'teacher' && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={onCreate}>
              New Observation
            </Button>
          </Grid>
        )}
      </Grid>

      <Switch>
        <Route exact path={baseUrl}>
          {observations.map(observation => (
            <TouchpointCard
              key={observation.id}
              primaryFocus="observer"
              observation={observation}
              onEdit={onEdit}
              onShow={onShow}
              user={user}
            />
          ))}
        </Route>

        {user.role !== 'teacher' && (
          <Route exact path={`${baseUrl}/look-for-indicators`}>
            {indicatorGroupsQuery.data.map((indicatorGroup, key) => (
              <LookForCard
                key={key}
                indicatorGroup={indicatorGroup}
                user={user}
              />
            ))}
          </Route>
        )}
      </Switch>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.currentUser.user
})

ObservationsTab.propTypes = {
  teacherId: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(ObservationsTab)
