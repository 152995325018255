import { get, patch } from 'utils/ajax'
import { alert } from 'utils/alert'

export const fetchSchoolLocatorSchoolDetails = schoolId => {
  return get(`/api/web/school_locator_schools/${schoolId}`).catch(err => {
    alert('An error occurred while loading the school details.', err.message)
  })
}

export const updateSchoolLocatorSchoolDetails = (schoolId, params = {}) => {
  return patch(`/api/web/school_locator_schools/${schoolId}`, {
    school_locator_school: params
  }).catch(err => {
    alert('An error occurred while updating the school details.', err.message)
  })
}
