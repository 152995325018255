import { get, patch, post } from 'utils/ajax'
import { alert } from 'utils/alert'

export const fetchSystemStatusTableData = (params = {}) => {
  return get('/api/admin/system_statuses', { params }).catch(_err => {
    alert('An error occurred while fetching the system statuses. ', 'danger')
  })
}

export const updateSystemStatus = params => {
  return patch(`/api/admin/system_statuses/${params.id}`, {
    system_status: {
      active: params.active
    }
  }).catch(_err => {
    alert('An error occurred while updating the system status.', 'danger')
  })
}

export const createSystemStatus = params => {
  return post('/api/admin/system_statuses', {
    system_status: params
  }).catch(_err => {
    alert('An error occurred while creating the system status.', 'danger')
  })
}
