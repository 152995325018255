import { destroy, get, patch, post } from 'utils/ajax'
import { alert } from 'utils/alert'

export const fetchGradPlanNotes = params => {
  return get('/api/web/grad_plan_notes', { params }).catch(() => {
    alert('There was an error fetching the grad plan notes.', 'danger')
  })
}

export const createGradPlanNote = gradPlanNote => {
  return post('/api/web/grad_plan_notes', {
    grad_plan_note: gradPlanNote
  }).catch(() => {
    alert('There was an error creating the grad plan note.', 'danger')
  })
}

export const updateGradPlanNote = (id, gradPlanNote) => {
  return patch(`/api/web/grad_plan_notes/${id}`, {
    grad_plan_note: gradPlanNote
  }).catch(() => {
    alert('There was an error updating the grad plan note.', 'danger')
  })
}

export const destroyGradPlanNote = id => {
  return destroy(`/api/web/grad_plan_notes/${id}`).catch(() => {
    alert('There was an error deleting the grad plan note.', 'danger')
  })
}
