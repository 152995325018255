import AttendancesApi from './api'
import { types } from './types'

export const fetchAttendances = (studentId, params) => {
  return dispatch => {
    return AttendancesApi.getAttendances(studentId, params).then(data => {
      dispatch({
        type: types.SET_ATTENDANCES,
        payload: {
          data,
          studentId
        }
      })
    })
  }
}
