import PropTypes from 'prop-types'

export const studentShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  powerschool_dcid: PropTypes.number.isRequired, // TODO: check if there are any without
  graduation_status: PropTypes.string,
  cohort: PropTypes.number
})

export const groupDetailShape = PropTypes.shape({
  creditsStored: PropTypes.number.isRequired,
  creditsEnrolled: PropTypes.number.isRequired,
  effectiveCreditCapacity: PropTypes.number
})

export const gradPlanNodeShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  children: PropTypes.array.isRequired,
  parentId: PropTypes.number,
  groupDetail: groupDetailShape
})

export const gradPlanShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  dbid: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  planType: PropTypes.number.isRequired,
  versions: PropTypes.array.isRequired
})

export const gradPlanShapeData = PropTypes.shape({
  root: gradPlanNodeShape.isRequired,
  gradPlan: gradPlanShape.isRequired
})
