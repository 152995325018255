import React from 'react'
import PropTypes from 'prop-types'
import { sumBy } from 'lodash'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Title,
  Subtitle,
  PieSeries,
  Tooltip
} from 'react-jsx-highcharts'

const IncidentTypesChart = ({ data, title, subtitle }) => {
  let mappedData = data
  if (data.length > 9) {
    mappedData = [
      ...data.slice(0, 8),
      {
        name: 'Other',
        color: '#bbbbbb',
        total: sumBy(data.slice(8, data.length), 'total')
      }
    ]
  }

  const plotOptions = {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: false
      },
      showInLegend: true
    }
  }

  return (
    <HighchartsChart plotOptions={plotOptions}>
      <Chart type="pie" />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Tooltip
        headerFormat={'<div style="font-size:16px;">{point.key}</div><br/>'}
        pointFormat={
          '{series.name}: <b>{point.y} ({point.percentage:.0f}%)</b>'
        }
        shared={true}
      />
      <PieSeries
        name="Total"
        colorByPoint={true}
        data={mappedData.map(incidentType => ({
          name: incidentType.name,
          y: incidentType.total,
          color: incidentType.color
        }))}
      />
    </HighchartsChart>
  )
}

IncidentTypesChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}

export default withHighcharts(IncidentTypesChart, window.Highcharts)
