import React, { useRef, useState } from 'react'
import {
  createAdminActivity,
  getAdminActivities,
  updateAdminActivity,
  destroyAdminActivity
} from 'api/admin/activities'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/admin-page-container'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
  Button,
  IconButton,
  DialogActions,
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Typography,
  InputAdornment,
  Stack,
  Tooltip
} from '@mui/material'
import Dialog from 'components/shared/dialog'
import ConfirmModalButton from 'components/shared/confirm-modal/button'
import { Cancel, Check, Delete, Edit } from '@mui/icons-material'
import { alert } from 'utils/alert'

const LANGUAGES = {
  en: 'English',
  es: 'Español'
}

function normalizeName(value) {
  return (value || '').trim().toLowerCase().replace(/\s+/, ' ')
}

export function AdminActivities() {
  const [open, setOpen] = useState(false)
  const [editedActivity, setEditedActivity] = useState(null)

  const queryClient = useQueryClient()
  const { data: activities } = useQuery(
    ['admin-activities'],
    () => getAdminActivities().then(res => res.data),
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  const name = useRef({})
  const setName = value => {
    name.current = { ...name.current, ...value }
  }
  const resetName = () => {
    name.current = {}
  }

  const alertErrors = (activityId = null, newName = name.current) => {
    const errors = []

    Object.entries(LANGUAGES).map(([lang, language]) => {
      const normalizedName = normalizeName(newName[lang])
      const normalizedNames = activities
        .filter(a => a.id !== activityId)
        .map(a => normalizeName(a['name'][lang]))
      if (!normalizedName) {
        errors.push(`Language is required: ${language} `)
      } else if (normalizedNames.includes(normalizedName)) {
        errors.push(`“${newName[lang]}” already exists for ${language}`)
      }
    })

    if (errors.length) {
      alert(errors.join('<br />'), 'danger')
      return true
    } else {
      return false
    }
  }

  const handleDestroy = activityId => {
    destroyAdminActivity(activityId).then(() => {
      queryClient.invalidateQueries(['admin-activities'])
    })
  }

  const handleClose = () => {
    setOpen(false)
    handleCancel()
  }

  const handleSubmitNew = () => {
    alertErrors() ||
      createAdminActivity(name.current).then(() => {
        handleClose()
        queryClient.invalidateQueries(['admin-activities'])
      })
  }

  const handleSubmitUpdate = () => {
    const updatedName = { ...editedActivity.name, ...name.current }

    alertErrors(editedActivity.id, updatedName) ||
      updateAdminActivity(editedActivity.id, { name: updatedName }).then(() => {
        handleCancel()
        queryClient.invalidateQueries(['admin-activities'])
      })
  }

  const handleUpdate = activity => {
    resetName()
    setName(activity.name)
    setEditedActivity(activity)
  }

  const handleCancel = () => {
    resetName()
    setEditedActivity(null)
  }

  return (
    <Page name="Admin-Activities" title="Activities">
      <PageContainer>
        <Typography variant="h1">Activities</Typography>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Add New
        </Button>
        <Dialog title={'New Activity'} open={open} onClose={handleClose}>
          <DialogContent>
            <Stack>
              {Object.entries(LANGUAGES).map(([lang, language]) => (
                <TextField
                  key={lang}
                  label={language}
                  variant={'outlined'}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{lang}</InputAdornment>
                    )
                  }}
                  onChange={event => setName({ [lang]: event.target.value })}
                />
              ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitNew}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Table>
          <TableHead>
            <TableRow>
              {Object.entries(LANGUAGES).map(([lang, language]) => (
                <TableCell key={lang}>
                  {language} ({lang})
                </TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities.map(activity => (
              <TableRow key={activity.id}>
                {editedActivity?.id === activity.id
                  ? Object.keys(LANGUAGES).map(lang => (
                      <TableCell key={lang}>
                        <TextField
                          defaultValue={activity.name[lang]}
                          onChange={e => (name.current[lang] = e.target.value)}
                        />
                      </TableCell>
                    ))
                  : Object.keys(LANGUAGES).map(lang => (
                      <TableCell key={lang}>{activity.name[lang]}</TableCell>
                    ))}
                <TableCell>
                  {editedActivity?.id === activity.id && (
                    <Stack direction={'row'}>
                      <Tooltip title="Save" arrow placement="top">
                        <IconButton onClick={handleSubmitUpdate}>
                          <Check color="success" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Cancel" arrow placement="top">
                        <IconButton onClick={handleCancel}>
                          <Cancel color="error" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )}
                  {!editedActivity && (
                    <Stack direction={'row'}>
                      <Tooltip title="Edit" arrow placement="top">
                        <IconButton
                          color="secondary"
                          size="small"
                          onClick={() => handleUpdate(activity)}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                      <ConfirmModalButton
                        confirmTitle={`Delete ${activity.name.en}`}
                        modalMessage={
                          'Are you sure you want to delete this activity? It will also delete all of its corresponding school activity records.'
                        }
                        size="small"
                        color="error"
                        showCancelButton={true}
                        cancelButtonText="No"
                        tooltipText="Delete"
                        onConfirm={() => handleDestroy(activity.id)}
                        startIcon={<Delete />}
                        variant="outlined"
                      >
                        Delete
                      </ConfirmModalButton>
                    </Stack>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PageContainer>
    </Page>
  )
}

export default AdminActivities
