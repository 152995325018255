import { createTheme, colors } from '@mui/material'

export const options = {
  palette: {
    grey: {
      main: colors.grey['800'],
      light: colors.grey['500'],
      dark: colors.grey['300'],
      contrastText: colors.grey['100']
    },
    primary: {
      main: '#0074aa'
    },
    secondary: {
      main: '#ff9900'
    }
  },

  typography: {
    h1: {
      fontSize: '3rem', // 6rem
      fontWeight: 'bold'
    },
    h2: {
      fontSize: '2.5rem', // 3.75rem
      fontWeight: 'bold'
    },
    h3: {
      fontSize: '2rem', // 3rem
      fontWeight: 'bold'
    },
    h4: {
      fontSize: '1.75rem', // 2.125rem
      fontWeight: 'bold'
    },
    h5: {
      fontSize: '1.5rem',
      fontWeight: 'bold'
    },
    h6: {
      fontSize: '1.25rem',
      fontWeight: 'bold'
    },

    // subtitle1: {},
    // subtitle2: {},
    // body1: {},
    // body2: {},
    // button: {},
    // caption: {},
    // overline: {},

    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#0074aa'
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 16
        }
      }
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true
      },
      styleOverrides: {
        root: {
          padding: 16
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 16
        }
      }
    },
    MuiStack: {
      defaultProps: {
        spacing: 3
      }
    }
  }
}

export const theme = createTheme(options)
