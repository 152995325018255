import React from 'react'
import Page from 'components/shared/page'
import PageContainer from 'shared/page-container'
import Content from './index.content.mdx'

export function FormsPage() {
  return (
    <Page name="Forms" title="Forms - Tulsa Public Schools">
      <PageContainer>
        <Content />
      </PageContainer>
    </Page>
  )
}

export default FormsPage
