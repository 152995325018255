import PropTypes from 'prop-types'

export const withRouterPropTypes = {
  match: PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
