import React from 'react'
import PropTypes from 'prop-types'
import { titleize } from 'utils'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Title,
  Subtitle,
  Legend,
  XAxis,
  YAxis,
  ColumnSeries,
  Tooltip
} from 'react-jsx-highcharts'

const DatasetsByTestingWindow = ({ type, data, schoolName }) => {
  if (!data) {
    return (
      <div style={{ padding: '5em' }}>
        <h5 />
      </div>
    )
  }

  const title = `${titleize(type)}`
  const yTitle = `% Met ${titleize(type)}`
  const categories = data[0].data.map(d => d[0])

  return (
    <div id={`js-${type}-bar-chart`}>
      <HighchartsChart>
        <Chart />
        <Title>{title}</Title>
        <Subtitle>{schoolName}</Subtitle>
        <Tooltip
          headerFormat="<span style='font-size:16px;'>{point.x}</span><table>"
          pointFormat={
            '<tr><td style="color:{series.color};padding:0;font-size:14px;">{series.name}: </td>' +
            '<td style="padding:0;font-size:14px;"><b> {point.y:.1f}%</b></td></tr>'
          }
          footerFormat="</table>"
          shared={true}
          crosshairs={true}
          useHTML={true}
        />
        <XAxis categories={categories}>
          <XAxis.Title>Testing Window</XAxis.Title>
        </XAxis>

        <YAxis id="number">
          <YAxis.Title>{yTitle}</YAxis.Title>
        </YAxis>

        <Legend layout="horizontal" align="center" verticalAlign="bottom" />
        {data.map(({ name, data }, index) => (
          <ColumnSeries
            id={`series-${index}`}
            name={name}
            data={data.map(([term, data]) => [
              term,
              data && data[data['property']]
            ])}
            key={index}
            marker={{ enabled: false }}
          />
        ))}
      </HighchartsChart>
    </div>
  )
}

DatasetsByTestingWindow.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.array,
  schoolName: PropTypes.string
}

export default withHighcharts(DatasetsByTestingWindow, window.Highcharts)
