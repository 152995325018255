import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'components/shared/table'
import { round } from 'lodash'

export default class DisciplineTable extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired
  }

  render() {
    const columns = [
      {
        Header: I18n.jsT('table_headers.attendance.month'),
        accessor: 'month_label',
        sortable: false
      },
      {
        Header: I18n.jsT('table_headers.attendance.attendance'),
        id: 'monthly_attendances',
        accessor: obj => obj,
        sortable: false,
        Cell: props =>
          `${round(props.value.monthly_attendance * 100, 1)}% (${round(
            props.value.present,
            1
          )}/${round(props.value.membership, 1)} days)`
      },
      {
        Header: I18n.jsT('table_headers.attendance.ytd'),
        accessor: 'cumulative',
        sortable: false,
        Cell: props => (props.value ? `${round(props.value, 1)}%` : '--')
      },
      {
        Header: I18n.jsT('table_headers.attendance.tardies'),
        accessor: 'tardy_count',
        sortable: false
      }
    ]

    const { data, loading } = this.props

    return (
      <div>
        <Table
          columns={columns}
          data={data}
          loading={loading}
          resizable={false}
          showPagination={false}
        />
      </div>
    )
  }
}
