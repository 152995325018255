import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { titleize } from 'utils'
import { alert } from 'utils/alert'
import { Button, Box, Grid, Typography, TextField } from '@mui/material'
import { updateDistrictDate } from 'api/admin/district_dates'

function DistrictDatesForm(props) {
  const [startDate, setStartDate] = useState(props.startDate || '')
  const [endDate, setEndDate] = useState(props.endDate || '')
  const { category, year } = props

  const handleSubmit = e => {
    e.preventDefault()
    const params = {
      category: category,
      year: year,
      start_date: startDate,
      end_date: endDate
    }
    updateDistrictDate(params)
      .then(() => {
        alert('Successfully updated the district date', 'success')
      })
      .catch(() => {
        alert('There was an error setting the district date.', 'danger')
      })
  }

  return (
    <Box
      sx={{
        p: 3
      }}
    >
      <Typography variant="h4" gutterBottom>
        {titleize(category)}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <TextField
              id={`${category}-start-date`}
              type="date"
              label="Start Date"
              fullWidth
              variant="outlined"
              value={startDate}
              onChange={e => setStartDate(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id={`${category}-end-date`}
              type="date"
              label="End Date"
              fullWidth
              variant="outlined"
              value={endDate}
              onChange={e => setEndDate(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              id={`${category}-save`}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

DistrictDatesForm.propTypes = {
  category: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string
}

export default DistrictDatesForm
