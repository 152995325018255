import { alert } from 'utils/alert'
import {
  createConcern,
  updateConcern,
  getConcernTypes
} from 'api/interventions'
import { useSelector } from 'react-redux'
import { selectors } from 'modules/schools'
import { mapObjectsToOptions } from 'utils/select-option-helpers'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import ConcernTypeHelperText from '../interventions/helper-text'
import moment from 'moment'
import { DatePicker } from '@mui/x-date-pickers'
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Stack,
  FormControlLabel,
  Checkbox
} from '@mui/material'
import StudentSelectContainer from './StudentSelectContainer'
import Dialog from 'components/shared/dialog'
import { useMutation, useQuery } from '@tanstack/react-query'
import ConcernCategory from './ConcernCategory'
import ConcernChildrenDropdown from './ConcernChildrenDropdown'
import InterventionModal from 'components/shared/interventions/modal'

const filterParentOptions = ({ concern, concernTypes }) => {
  const parentOptions = mapObjectsToOptions(concernTypes, 'name', 'id')

  if (!concern) {
    return parentOptions
  }

  const concernIsActive = parentOptions
    .map(o => o.value)
    .includes(concern.concern_type_id || concern.parent_concern_type_id)

  if (concernIsActive) {
    return parentOptions
  }

  return parentOptions.concat([
    {
      label: concern.concern_name,
      value: concern.concern_type_id
    }
  ])
}

const filterChildOptions = ({ concernTypes, concernTypeParentId, school }) => {
  const parentConcernType = concernTypes.find(
    obj => obj.id === concernTypeParentId
  )

  if (parentConcernType) {
    const options = mapObjectsToOptions(
      parentConcernType.children,
      'name',
      'id'
    ).filter(option =>
      school.category === 'high' ? true : option.label !== 'ICAP'
    )
    return options
  }

  return []
}

function ConcernDialog({
  concern,
  onHide,
  show,
  studentId,
  childStudyId,
  hasInterventions
}) {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [needsHelp, setNeedsHelp] = useState(
    concern?.is_requesting_help || false
  )
  const [bulkGenerateIntervention, setBulkGenerateIntervention] =
    useState(false)
  const [concernNotes, setConcernNotes] = useState(concern?.concern_notes || '')
  const [concernTypeParentId, setConcernTypeParentId] = useState('')
  const [concernTypeId, setConcernTypeId] = useState('')
  const [childConcern, setChildConcern] = useState(null)
  const [concernOn, setConcernOn] = useState(
    moment(concern?.concern_on).toDate() || moment().toDate()
  )
  const [selectedStudentOption, setSelectedStudentOption] = useState([])
  const [parentOptions, setParentOptions] = useState([])
  const [childOptions, setChildOptions] = useState([])
  const { userId, school, schoolId } = useSelector(state => ({
    userId: state.currentUser.user.id,
    school: selectors.currentSchool(state),
    schoolId: state.schools.schoolId
  }))
  const [concerns, setConcerns] = useState(null)
  const [interventionModalOpen, setInterventionModalOpen] = useState(false)

  const { data: concernTypes } = useQuery(
    ['concern-types'],
    async () => {
      const res = await getConcernTypes()
      return res.data
    },
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  const closeInterventionModal = () => {
    setInterventionModalOpen(false)
  }

  const closeConcernDialogAndOpenInterventionDialog = data => {
    onHide({ action: 'create', concernId: data[0].id })

    if (bulkGenerateIntervention) {
      setInterventionModalOpen(true)
    }
  }

  const createConcernMutation = useMutation(
    ({ params }) => {
      return createConcern(params)
    },
    {
      onSuccess: data => {
        if (data.length > 1) {
          alert(`Successfully created ${data.length} concerns.`, 'success')
          setConcerns(data)
          closeConcernDialogAndOpenInterventionDialog(data)
        } else {
          alert('Successfully created the concern', 'success')
          onHide({ action: 'create', concernId: data[0].id })
          resetForm()
        }
      }
    }
  )

  const updateConcernMutation = useMutation(
    ({ id, params }) => updateConcern(id, params),
    {
      onSuccess: () => {
        alert('Successfully updated the concern', 'success')
        onHide({ action: 'update' })
      }
    }
  )

  const handleCreate = () => {
    const params = {
      concern_type_id: concernTypeId,
      created_by_user_id: userId,
      school_id: schoolId,
      student_ids: studentId
        ? [studentId]
        : selectedStudentOption.map(o => o.value),
      concern_notes: concernNotes,
      concern_on: concernOn,
      is_requesting_help: needsHelp,
      child_study_id: childStudyId
    }
    createConcernMutation.mutate({ params })
  }

  const handleUpdate = concern => {
    const params = {
      concern_type_id: concernTypeId,
      concern_notes: concernNotes,
      concern_on: concernOn,
      is_requesting_help: needsHelp
    }
    updateConcernMutation.mutate({ id: concern.id, params: params })
  }

  const validForm = () => {
    const isRequiredFilled =
      concernTypeId !== '' &&
      concernTypeParentId !== '' &&
      (selectedStudentOption.length > 0 || studentId)
    return isRequiredFilled
  }

  const handleSubmit = (e, concern) => {
    e.preventDefault()
    setIsSubmitted(true)

    if (!validForm()) {
      return
    }

    if (!concern) {
      handleCreate()
    } else {
      handleUpdate(concern)
    }
  }

  const resetForm = () => {
    setIsSubmitted(false)
    setConcernNotes('')
    setConcernTypeParentId('')
    setConcernTypeId('')
    setChildConcern(null)
    setConcernOn(moment())
  }

  const setFormData = () => {
    if (concern) {
      if (concern.parent_concern_type_id) {
        setConcernTypeParentId(concern.parent_concern_type_id)
        setConcernTypeId(concern.concern_type_id)
      } else {
        setConcernTypeParentId(concern.concern_type_id)
      }
    }
  }

  // On first render, set form data to nothing
  useEffect(() => {
    // NOT STATE!
    setFormData()
  }, [])

  useEffect(() => {
    if (concernTypeParentId && concernTypeId && concernTypes) {
      const parent = concernTypes.find(c => c.id === concernTypeParentId)
      const child = parent?.children.find(c => c.id === concernTypeId)
      setChildConcern(child)
    }
  }, [concernTypeParentId, concernTypeId, setChildConcern])

  useEffect(() => {
    setParentOptions(
      filterParentOptions({
        concern,
        concernTypes
      })
    )
  }, [concernTypes, concern, setParentOptions])

  useEffect(() => {
    setChildOptions(
      filterChildOptions({
        concernTypes,
        concernTypeParentId,
        school
      })
    )
  }, [
    selectedStudentOption.length,
    concernTypes,
    concernTypeParentId,
    setChildOptions,
    school
  ])

  const handleConcernCategoryChange = value => {
    setConcernTypeParentId(value)
    setConcernTypeId('')
  }

  const isHelpTextVisable = concernTypeParentId && concernTypeId && childConcern

  const parentConcernTypeName =
    parentOptions.length > 0 &&
    parentOptions.find(o => o.value === concernTypeParentId)?.label

  return (
    <>
      <Dialog
        title={concern ? 'Edit Concern' : 'Create Concern'}
        open={show}
        onClose={() => onHide({ action: 'cancel' })}
      >
        <DialogContent>
          <Stack component="form">
            <Stack direction={'row'} justifyContent={'space-between'}>
              <StudentSelectContainer
                studentId={studentId}
                setSelectedStudentOption={setSelectedStudentOption}
                selectedStudentOption={selectedStudentOption}
                isSubmitted={isSubmitted}
              />

              <DatePicker
                renderInput={props => <TextField {...props} />}
                disableToolbar
                required
                fullWidth
                inputFormat="MM/DD/YYYY"
                inputVariant="inline"
                id="date-event-on"
                label="Date First Observed"
                value={moment(concernOn).toDate()}
                onChange={val => setConcernOn(moment(val))}
                autoOk={true}
              />
            </Stack>
            <Stack direction={'row'}>
              <ConcernCategory
                value={concernTypeParentId}
                onChange={handleConcernCategoryChange}
                options={parentOptions}
                isSubmitted={isSubmitted}
                hasInterventions={hasInterventions}
              />
              <ConcernChildrenDropdown
                value={concernTypeId}
                onChange={setConcernTypeId}
                options={childOptions}
                isSubmitted={isSubmitted}
                hasInterventions={hasInterventions}
              />
            </Stack>
            <TextField
              id="details"
              label="Details"
              multiline
              fullWidth
              variant="outlined"
              rows={5}
              value={concernNotes}
              onChange={val => setConcernNotes(val.target.value)}
            />
            {isHelpTextVisable && (
              <ConcernTypeHelperText
                description={childConcern.description}
                isHighSecurity={childConcern.is_high_security}
                isEmergencyCrisis={childConcern.is_emergency_crisis}
              />
            )}
          </Stack>
          <Stack direction="row">
            <FormControlLabel
              control={
                <Checkbox
                  checked={needsHelp}
                  onChange={() => setNeedsHelp(!needsHelp)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                />
              }
              label="Requesting Help"
            />
          </Stack>
          {selectedStudentOption.length > 1 &&
            ['Academics', 'Behavior'].includes(parentConcernTypeName) && (
              <Stack direction="row">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bulkGenerateIntervention}
                      onChange={() =>
                        setBulkGenerateIntervention(!bulkGenerateIntervention)
                      }
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                    />
                  }
                  label="Bulk Create Targeted Intervention (Optional)"
                />
              </Stack>
            )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item></Grid>
            <Grid item>
              <Stack direction={'row'}>
                <Button
                  variant={'outline'}
                  onClick={() => onHide({ action: 'cancel' })}
                >
                  Cancel
                </Button>
                <Button
                  variant={'contained'}
                  color="primary"
                  onClick={e => handleSubmit(e, concern)}
                >
                  {concern ? 'Save' : 'Create'}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {interventionModalOpen && (
        <InterventionModal
          concerns={concerns}
          onClose={closeInterventionModal}
          show={interventionModalOpen}
        />
      )}
    </>
  )
}

ConcernDialog.propTypes = {
  concern: PropTypes.object,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  studentId: PropTypes.number,
  childStudyId: PropTypes.number,
  hasInterventions: PropTypes.bool
}

export default ConcernDialog
