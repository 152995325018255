import React from 'react'
import PropTypes from 'prop-types'
import DevelopmentAreaScale from './development-area-scale'
import MasteryLevelTitle from './mastery-level-title'
import { developmentAreaShape } from './prop-types'
import {
  Box,
  FormControl,
  FormGroup,
  Paper,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import DevelopmentAreaPrompt from './development-area-prompt'
import StyledTextField from '../learning-walk/components/styled-text-field'
import { LowInferenceNotes } from './low-inference-notes'

export function InformalObservationObserve({
  form,
  setForm,
  developmentAreaRated,
  developmentAreas,
  invalidate,
  observation
}) {
  const { developmentAreaMasteries } = form
  const masteredDevelopmentAreas = developmentAreas.map(developmentArea => ({
    developmentArea,
    masteryLevelId: developmentAreaMasteries[developmentArea.id]
  }))

  const handleChangeNoteToTeacher = value => {
    setForm(state => ({ ...state, note_to_teacher: value }))
  }

  const handleChangeNoteToSelf = value => {
    setForm(state => ({ ...state, note_to_self: value }))
  }

  return (
    <Stack>
      <Typography variant={'h2'}>Low Inference Notes</Typography>

      <Box id="observation-notes">
        <LowInferenceNotes {...{ form, invalidate, observation }} />
      </Box>

      <Typography variant={'h2'}>Development Areas</Typography>
      {masteredDevelopmentAreas.map(({ developmentArea, masteryLevelId }) => (
        <Paper key={developmentArea.id} elevation={3} sx={{ p: 3 }}>
          <Stack>
            <Box
              sx={{
                display: { sm: 'flex' },
                justifyContent: { sm: 'space-between' }
              }}
            >
              <Typography variant={'h3'} sx={{ mb: 3 }}>
                {developmentArea.name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mb: 3
                }}
              >
                <FormGroup sx={{ mb: 1 }}>
                  <DevelopmentAreaScale
                    {...{
                      developmentArea,
                      developmentAreaRated,
                      masteryLevelId
                    }}
                  />
                </FormGroup>
                <MasteryLevelTitle
                  {...{
                    developmentArea,
                    masteryLevelId
                  }}
                />
              </Box>
            </Box>
            <DevelopmentAreaPrompt {...{ developmentArea, masteryLevelId }} />
          </Stack>
        </Paper>
      ))}

      <Typography variant={'h2'}>Notes</Typography>
      <FormControl fullWidth>
        <TextField
          id={'noteToSelf'}
          name={'noteToSelf'}
          multiline
          label={'Note to Self'}
          helperText={'Visible only to Me'}
          rows={4}
          value={form.note_to_self || ''}
          onChange={event => handleChangeNoteToSelf(event.target.value)}
        />
      </FormControl>

      <FormControl fullWidth>
        <StyledTextField
          id={'teacherNote'}
          name={'teacherNote'}
          multiline
          label={'Note to Teacher'}
          helperText={'Visible to Teacher'}
          rows={4}
          value={form.note_to_teacher || ''}
          onChange={event => handleChangeNoteToTeacher(event.target.value)}
        />
      </FormControl>
    </Stack>
  )
}

InformalObservationObserve.propTypes = {
  form: PropTypes.object.isRequired,
  setForm: PropTypes.func.isRequired,
  developmentAreaRated: PropTypes.func.isRequired,
  developmentAreas: PropTypes.arrayOf(developmentAreaShape).isRequired,
  invalidate: PropTypes.func.isRequired,
  observation: PropTypes.object
}

export default InformalObservationObserve
