import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'components/shared/table'
import IncidentTypesChart from './incident-types-chart'
import { titleize } from 'utils'
import { Dialog } from 'shared/dialog'

const BreakdownModal = ({
  type,
  show,
  onHide,
  data,
  filters,
  seriesName,
  showNumStudents,
  ...props
}) => {
  const pastTenseType = type === 'suspensions' ? 'Suspended' : 'Referred'
  const titleType = titleize(type)
  const title = `${titleType} breakdown`

  const tableColumns = [
    {
      Header: 'Incident',
      accessor: 'name'
    },
    {
      Header: `Total ${titleType}`,
      accessor: 'total'
    }
  ]

  if (type === 'suspensions') {
    tableColumns.push({
      Header: 'Days per Suspension',
      accessor: 'days_per'
    })
  }

  if (showNumStudents) {
    tableColumns.push({
      Header: `Students ${pastTenseType}`,
      accessor: 'students_cited'
    })
  }
  const calculateChartTitle = () => {
    if (filters && filters.incidentType) {
      return `${titleType} – ${filters.incidentType}`
    } else if (filters && filters.incidentTier) {
      return `${titleType} – Tier ${filters.incidentTier}`
    } else {
      return titleType
    }
  }

  return (
    <Dialog open={show} onClose={onHide} {...props} title={title}>
      <IncidentTypesChart
        title={calculateChartTitle()}
        subtitle={seriesName}
        data={data}
      />

      <Table
        columns={tableColumns}
        data={data}
        resizable={false}
        showPagination={false}
      />
    </Dialog>
  )
}

BreakdownModal.propTypes = {
  type: PropTypes.oneOf(['referrals', 'suspensions']).isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      total: PropTypes.number.isRequired
    })
  ).isRequired,
  filters: PropTypes.shape({
    incidentTier: PropTypes.number,
    incidentType: PropTypes.string
  }),
  seriesName: PropTypes.string.isRequired,
  showNumStudents: PropTypes.bool.isRequired
}

BreakdownModal.defaultProps = {
  showNumStudents: true
}

export default BreakdownModal
