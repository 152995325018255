import React from 'react'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import Header from 'components/shared/header'

const NoSchoolsAssigned = () => (
  <Page name="NoSchoolsAssigned" title="No Schools Assigned">
    <PageContainer noSidebar>
      <Header.Contents>
        <Header.Title>No assigned schools</Header.Title>
      </Header.Contents>

      <p>
        It looks like you do not have any schools assigned to your account. If
        you think you might be seeing this in error, use the feedback form at
        the bottom of this page to send us a note.
      </p>
    </PageContainer>
  </Page>
)

export default NoSchoolsAssigned
