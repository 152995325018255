import React from 'react'
import PropTypes from 'prop-types'
import MuiDialog from '@mui/material/Dialog'
import DialogTitle from './title'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material'

export const TopDialog = styled(Dialog)(({ theme: _ }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start'
  }
}))

export function Dialog({ children, title, ...props }) {
  const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'))

  return (
    <MuiDialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={'md'}
      {...props}
    >
      {title && <DialogTitle onClose={props.onClose}>{title}</DialogTitle>}
      {children}
    </MuiDialog>
  )
}

Dialog.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  onClose: PropTypes.func
}

export default Dialog
