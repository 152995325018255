import { get } from 'utils/ajax'
import { alert } from 'utils/alert'

const getJobs = (params = {}) => {
  return get('/api/admin/jobs', {
    params: params
  }).catch(_err => {
    alert('An error occurred while fetching jobs', 'danger')
  })
}

const getJobRoles = () => {
  return get('/api/admin/jobs?roles=true').catch(_err => {
    alert('An error occurred while fetching jobs', 'danger')
  })
}

export default {
  getJobs,
  getJobRoles
}
