import React from 'react'
import PropTypes from 'prop-types'
import { Chip } from '@mui/material'

export default function EmployeeIdCell({ user }) {
  if (user.employee_id) {
    return <Chip label={user.employee_id} size="small" variant="outlined" />
  }

  if (user.external)
    return (
      <Chip label="External" color="primary" size="small" variant="outlined" />
    )

  return <Chip label="NO ID" color="error" size="small" variant="outlined" />
}

EmployeeIdCell.propTypes = {
  user: PropTypes.shape({
    employee_id: PropTypes.number,
    external: PropTypes.bool
  }).isRequired
}
