import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'
import { titleize } from 'utils'
import Dialog from 'shared/dialog'

export default class LinksModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    schoolLinks: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    schoolName: PropTypes.string
  }

  render() {
    const { onHide, show, schoolName, schoolLinks } = this.props

    return (
      <Dialog
        open={show}
        onClose={onHide}
        title={`${titleize(schoolName)} Links`}
      >
        <Box
          sx={{
            p: 4
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Links</TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Comment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schoolLinks &&
                schoolLinks.map((link, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <a href={link.url}>{link.label}</a>
                    </TableCell>
                    <TableCell>{titleize(link.category)}</TableCell>
                    <TableCell>{link.comment}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Dialog>
    )
  }
}
