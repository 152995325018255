import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { isNull } from 'lodash'
import {
  useInformalObservation,
  useInformalObservationMutation
} from 'hooks/tulsa-way/useInformalObservation'
import { useDevelopmentAreas } from 'hooks/tulsa-way/useDevelopmentAreas'
import TulsaWayBadge from '../../../assets/images/tulsa-way/tulsa-way-badge.png'
import { withRouterPropTypes } from 'utils/react-router-prop-types'
import TeacherSectionSelectors from './teacher-section-selectors'
import InformalObservationObserve from './informal-observation/observe'
import ObservationHeader from './observation-header'
import DisableFreshWidget from 'components/shared/disable-fresh-widget'
import ConfirmModalButton from 'components/shared/confirm-modal/button'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import RecentFeedback from './informal-observation/recent-feedback'

const initForm = {
  id: null,
  teacher_id: null,
  section_id: null,
  started_at: null,
  ended_at: null,
  note_to_teacher: '',
  note_to_self: '',
  developmentAreaMasteries: {}
}

const observationToForm = observation => {
  const {
    id,
    observee_id: teacher_id,
    section_id,
    note_to_teacher,
    note_to_self,
    started_at,
    ended_at
  } = {
    observee_id: null,
    ...initForm,
    ...observation
  }

  const reducer = (prev, curr) => {
    const { tulsa_way_development_area_id, tulsa_way_mastery_level_id } = curr
    prev[tulsa_way_development_area_id] = tulsa_way_mastery_level_id
    return prev
  }

  const developmentAreaMasteries = (
    observation?.development_area_masteries || []
  ).reduce(reducer, {})

  return {
    id,
    teacher_id,
    section_id,
    note_to_teacher,
    note_to_self,
    started_at: started_at && new Date(started_at),
    ended_at: ended_at && new Date(ended_at),
    developmentAreaMasteries
  }
}

const formToObservation = form => {
  const { developmentAreaMasteries, ...data } = form

  const mapper = ([
    tulsa_way_development_area_id,
    tulsa_way_mastery_level_id
  ]) => ({ tulsa_way_development_area_id, tulsa_way_mastery_level_id })

  data.development_area_masteries = Object.entries(
    developmentAreaMasteries
  ).map(mapper)

  return data
}

export function TulsaWayInformalObservation({ match, history }) {
  const { params } = match
  const [form, setForm] = React.useState(params.id ? null : initForm)
  const { data: observation, invalidate } = useInformalObservation(params.id, {
    refetchOnWindowFocus: false,
    onSuccess: data => {
      if (!form) {
        setForm(observationToForm(data))
      }
    }
  })
  const observationMutation = useInformalObservationMutation()
  const { data: developmentAreas } = useDevelopmentAreas({
    options: {
      initialData: [],
      refetchOnWindowFocus: false
    }
  })

  const handleSelectorChange = ({ teacher_id, section_id }) => {
    setForm(state => ({ ...state, teacher_id, section_id }))
  }

  const handleCancel = () => {
    history.push('/tulsa-way')
  }

  const handleObserve = () => {
    const started_at = form.started_at || new Date()
    const variables = formToObservation({ ...form, started_at })

    observationMutation.mutate(variables, {
      onSuccess: (data, _variables, _context) => {
        setForm(observationToForm(data))
        history.replace(`${match.url}/${data.id}/observe`)
      }
    })
  }

  const handleDraft = () => {
    const updated_at = new Date()
    const variables = formToObservation({ ...form, updated_at })

    observationMutation.mutate(variables, {
      onSuccess: () => {
        history.push('/tulsa-way')
      }
    })
  }

  const handleComplete = () => {
    const started_at = form.started_at || new Date()
    const ended_at = new Date()
    const variables = formToObservation({ ...form, started_at, ended_at })

    observationMutation.mutate(variables, {
      onSuccess: () => {
        history.push('/tulsa-way')
      }
    })
  }

  const developmentAreaRated = (developmentAreaId, masteryLevelId) => {
    setForm(state => {
      const { developmentAreaMasteries } = state
      if (masteryLevelId) {
        developmentAreaMasteries[developmentAreaId] = masteryLevelId
      } else {
        delete developmentAreaMasteries[developmentAreaId]
      }

      const form = { ...state, developmentAreaMasteries }

      // NOTE: uncomment if we want to save on change
      // const variables = formToObservation(form)
      // observationMutation.mutate(variables)

      return form
    })
  }

  return (
    <Container sx={theme => ({ paddingY: theme.spacing(3) })}>
      <DisableFreshWidget />

      <Stack spacing={5}>
        <Stack
          spacing={4}
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            sx={{
              width: {
                xs: 100,
                md: 200
              },
              height: {
                xs: 100,
                md: 200
              }
            }}
            component="img"
            alt="Tulsa Way Logo"
            src={TulsaWayBadge}
          />

          <Typography variant={'h2'}>Informal Observation</Typography>
        </Stack>

        <Switch>
          <Route path={match.url} exact>
            <React.Fragment>
              <TeacherSectionSelectors
                onChange={handleSelectorChange}
                observationType={'Informal Observation'}
              />
              <Stack direction={'row-reverse'}>
                <Button
                  onClick={handleObserve}
                  variant={'contained'}
                  disabled={observationMutation.isLoading || !form?.section_id}
                >
                  Observe
                </Button>
                <Button
                  onClick={handleCancel}
                  disabled={observationMutation.isLoading}
                >
                  Cancel
                </Button>
              </Stack>
            </React.Fragment>
          </Route>

          <Route path={`${match.url}/observe`}>
            {observation && form && (
              <React.Fragment>
                <ObservationHeader observation={observation} />

                <Stack spacing={4} direction={{ xs: 'column', md: 'row' }}>
                  <InformalObservationObserve
                    {...{
                      form,
                      setForm,
                      developmentAreaRated,
                      developmentAreas,
                      invalidate,
                      observation
                    }}
                  />

                  <Box sx={{ maxWidth: { xs: 1, md: 1 / 4 } }}>
                    <RecentFeedback
                      observations={observation.recent_observations}
                    />
                  </Box>
                </Stack>

                <Stack direction={'row-reverse'}>
                  {isNull(observation.ended_at) ? (
                    <React.Fragment>
                      <ConfirmModalButton
                        modalMessage={
                          'Once submitted, an email will be sent to the observee with a link to the observation. The observee will only be able to see the scored development areas and the "Note to Teacher" sections. All notes entered in the "Note to Self" section will remain private.'
                        }
                        cancelButtonText="No"
                        id={'submit-informal-observation'}
                        onConfirm={handleComplete}
                        disabled={observationMutation.isLoading}
                        confirmTitle="Are you sure you would like to submit this observation?"
                      >
                        Submit
                      </ConfirmModalButton>
                      <Button
                        onClick={handleDraft}
                        disabled={observationMutation.isLoading}
                      >
                        Save as Draft
                      </Button>
                    </React.Fragment>
                  ) : (
                    <Button
                      onClick={handleComplete}
                      variant={'contained'}
                      disabled={observationMutation.isLoading}
                    >
                      Done
                    </Button>
                  )}
                </Stack>
              </React.Fragment>
            )}
          </Route>
        </Switch>
      </Stack>
    </Container>
  )
}

TulsaWayInformalObservation.propTypes = {
  ...withRouterPropTypes
}

export default TulsaWayInformalObservation
