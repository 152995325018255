import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { currentSchoolYear } from 'utils'
import { createUser, resetUserPassword, updateUser } from 'api/admin/users'
import Dialog from 'shared/dialog'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import {
  Box,
  Button,
  Stack,
  FormGroup,
  FormControlLabel,
  FormControl,
  TextField,
  Switch,
  Typography
} from '@mui/material'
import SimpleAutocomplete from '../../shared/simple-autocomplete'
import MultiselectAutocomplete from '../../shared/multiselect-autocomplete'
import { getMuiAutocompleteJobRoles } from 'api/jobs'

function UserModal({ formType, roles, onHide, schools, show, user }) {
  const defaultUser = {
    role: 'unassigned',
    employeeId: '',
    email: '',
    name: '',
    active: false,
    id: undefined,
    expiration_date: null,
    job_title: '',
    beta: false,
    override: false,
    schools: [],
    override_expiration: moment(`${currentSchoolYear}-06-30`)
  }
  const [activeUser, setActiveUser] = useState(user || defaultUser)
  const [selectedSchools, setSelectedSchools] = useState(
    activeUser.schools?.map(school => school.id) || []
  )
  const schoolOptions = Object.values(schools).map(school => ({
    label: school.name,
    value: school.id
  }))

  const submitUser = e => {
    e.preventDefault()

    const params = {
      ...activeUser,
      school_ids: selectedSchools
    }

    if (formType === 'Create') {
      createUser(params).then(() => {
        onHide()
      })
    } else {
      updateUser(params).then(() => {
        onHide()
      })
    }
  }

  const resetPassword = event => {
    event.preventDefault()
    const userId = event.target.value
    resetUserPassword(userId)
    onHide()
  }

  const handleChange = values => {
    setActiveUser({
      ...activeUser,
      ...values
    })
  }

  const resetModal = () => {
    setSelectedSchools(activeUser.schools?.map(school => school.id))
    setActiveUser(user || defaultUser)
    onHide()
  }

  const activeValues = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false }
  ]

  return (
    <Dialog
      open={show}
      onClose={resetModal}
      title={
        !activeUser?.name == ''
          ? `${activeUser?.name} - ${activeUser?.job_title}`
          : ' '
      }
    >
      <Box
        sx={{
          p: 4
        }}
      >
        <Stack spacing={2}>
          <Box>
            {activeUser.id && activeUser.external && (
              <Button
                variant="contained"
                color="warning"
                value={activeUser.id}
                onClick={val => resetPassword(val)}
              >
                Password Reset
              </Button>
            )}
          </Box>
          <Box
            sx={{
              mb: 4
            }}
          >
            <Typography variant="h4">
              {formType == 'Create'
                ? formType + ' External User'
                : formType == 'Edit'
                ? formType + ' User'
                : 'Change User Role'}
            </Typography>
          </Box>
          <Box>
            <form onSubmit={submitUser}>
              <Stack spacing={2}>
                <Box>
                  <Stack direction="row" spacing={2}>
                    <FormControl fullWidth>
                      <TextField
                        id="test-field-name"
                        label="Name"
                        variant="outlined"
                        value={activeUser.name}
                        name="name"
                        onChange={val =>
                          handleChange({ name: val.target.value })
                        }
                        required
                      />
                    </FormControl>

                    <FormControl>
                      <TextField
                        id="test-field-email"
                        label="Email"
                        variant="outlined"
                        value={activeUser.email}
                        name="email"
                        onChange={val =>
                          handleChange({ email: val.target.value })
                        }
                        required
                      />
                    </FormControl>

                    {!activeUser.external && formType != 'Create' && (
                      <FormControl>
                        <TextField
                          id="test-field-employee_id"
                          label="Employee ID"
                          variant="outlined"
                          name="employeeId"
                          value={activeUser.employee_id}
                          onChange={val =>
                            handleChange({ employee_id: val.target.value })
                          }
                        />
                      </FormControl>
                    )}
                  </Stack>
                </Box>

                <Box>
                  <SimpleAutocomplete
                    id="role-select"
                    label={'Role'}
                    options={roles}
                    value={activeUser.role}
                    onChange={val => handleChange({ role: val.value })}
                  />
                </Box>
                <Box>
                  <SimpleAutocomplete
                    id="status-select"
                    value={activeUser.active}
                    options={activeValues}
                    onChange={val => handleChange({ active: val.value })}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value
                    }
                    label="Status"
                  />
                </Box>
                <Box>
                  <MultiselectAutocomplete
                    id="school-select"
                    value={selectedSchools}
                    options={schoolOptions}
                    onChange={val => setSelectedSchools(val)}
                    label="User Schools"
                  />
                </Box>

                {(activeUser.external || formType == 'Create') && (
                  <Box>
                    <FormControl fullWidth>
                      <DatePicker
                        label="Expiration Date (External Users Only)"
                        name="expirationDate"
                        value={
                          activeUser.expiration_date
                            ? moment(activeUser.expiration_date).toDate()
                            : moment().add(1, 'years').toDate()
                        }
                        onChange={val => handleChange({ expiration_date: val })}
                      />
                    </FormControl>
                  </Box>
                )}

                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={activeUser.beta} />}
                    label="Beta User"
                    onChange={event =>
                      handleChange({ beta: event.target.checked })
                    }
                  />
                  <FormControlLabel
                    control={<Switch checked={activeUser.override} />}
                    label="Override"
                    onChange={event => {
                      const checked = event.target.checked
                      const overrideExpiration = checked
                        ? moment(`${currentSchoolYear}-06-30`)
                        : null

                      handleChange({
                        override: checked,
                        override_expiration: overrideExpiration
                      })
                    }}
                  />
                </FormGroup>

                <Button variant="contained" type="submit">
                  Save
                </Button>
              </Stack>
            </form>
          </Box>
        </Stack>
      </Box>
    </Dialog>
  )
}

UserModal.propTypes = {
  fetchData: PropTypes.func,
  fetchUserSchools: PropTypes.func.isRequired,
  formType: PropTypes.string,
  roles: PropTypes.array.isRequired,
  onHide: PropTypes.func.isRequired,
  schools: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userSchools: PropTypes.array
}

const mapStateToProps = state => ({
  roles: getMuiAutocompleteJobRoles(state.jobs.byRole),
  schools: state.schools.byId,
  userSchools: state.users.userSchools
})

export default connect(mapStateToProps)(UserModal)
