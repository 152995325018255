import React from 'react'
import PropTypes from 'prop-types'
import { titleize } from 'utils'
import InfoIcon from '@mui/icons-material/Info'
import { Chip, Typography, Box, Tooltip } from '@mui/material'
import { RemoteTable } from 'components/shared/table'

const AdminJobTable = ({ data, fetchData, loading, totalPages }) => {
  const columns = [
    {
      id: 'name',
      Header: 'Name',
      accessor: obj => obj,
      Cell: props => <div>{titleize(props.value.name)}</div>
    },
    {
      id: 'role',
      Header: 'Role',
      accessor: obj => obj,
      Cell: props => titleize(props.value.role)
    },
    {
      id: 'active',
      Header: () => (
        <Tooltip
          placement="top"
          arrow
          title="Status now displays if an employee is active in Munis or if the user is an active external partner."
        >
          <Box
            component="span"
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Typography>Status</Typography>
            <InfoIcon fontSize="small" />
          </Box>
        </Tooltip>
      ),
      maxWidth: 85,

      accessor: obj => obj,
      Cell: props => (
        <Chip
          color={props.value.active ? 'success' : 'secondary'}
          label={props.value.active ? 'active' : 'inactive'}
          size="small"
        />
      )
    }
  ]

  return (
    <Box id="job-table">
      <RemoteTable
        columns={columns}
        data={data}
        loading={loading}
        onFetchData={params => fetchData(params)}
        pages={totalPages}
      />
    </Box>
  )
}

AdminJobTable.propTypes = {
  data: PropTypes.array,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  totalPages: PropTypes.number,
  value: PropTypes.object
}

export default AdminJobTable
