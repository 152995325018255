import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandsHelping, faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { destroyExternalReferral } from 'api/external-referrals'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import ExternalReferralModal from './modal'
import { theme } from 'components/app/theme'

function TimelineRow({ externalReferral, concern }) {
  const queryClient = useQueryClient()
  const [editedExternalReferral, setEditedExternalReferral] = useState(null)
  const [showExternalReferral, setShowExternalReferral] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setEditedExternalReferral(null)
    setAnchorEl(null)
  }

  const deleteExternalReferralMutation = useMutation(
    ({ externalReferralId }) => destroyExternalReferral(externalReferralId),
    {
      onSuccess: () => {
        handleClose()
        queryClient.invalidateQueries(['concern', concern.id])
      }
    }
  )

  const disabled = concern.closed || concern.is_resolved || concern.is_archived

  const determineBackgroundColor = () => {
    if (disabled) {
      return theme.palette.primary.disabledLight
    } else {
      return theme.palette.primary.contrastText
    }
  }

  const determineColor = () => {
    if (disabled) {
      return theme.palette.primary.disabledDark
    } else {
      return theme.palette.primary.main
    }
  }

  return (
    <Box>
      <TimelineItem style={{ paddingRight: 0 }}>
        {' '}
        <TimelineOppositeContent
          style={{ flex: 0, padding: 0 }}
        ></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
            <FontAwesomeIcon
              icon={faHandsHelping}
              style={{ color: '#fff7eb' }}
            />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{ paddingRight: 0 }}>
          <Typography variant="h4">{externalReferral.agency_name}</Typography>
          <Typography style={{ maxWidth: '35em' }}>
            {externalReferral.note}
          </Typography>
          <Typography>
            <Box>
              <Button
                id={`menu-${externalReferral.id}`}
                aria-controls={`menu-${externalReferral.id}`}
                aria-haspopup="true"
                aria-expanded="true"
                onClick={handleClick}
                style={{
                  backgroundColor: determineBackgroundColor(),
                  color: determineColor()
                }}
                disabled={disabled}
              >
                <FontAwesomeIcon icon={faEllipsisH} />
              </Button>
              <Menu
                color="primary"
                aria-labelledby={`menu-${externalReferral.id}`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                <MenuItem
                  onClick={() => {
                    setEditedExternalReferral(externalReferral)
                    setAnchorEl(null)
                    setShowExternalReferral(true)
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    deleteExternalReferralMutation.mutate({
                      externalReferralId: externalReferral.id
                    })
                  }
                >
                  Delete
                </MenuItem>
              </Menu>
            </Box>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {moment(externalReferral.date).format('MM/DD/YYYY')} by{' '}
            {externalReferral.author_name}
          </Typography>
        </TimelineContent>
      </TimelineItem>
      {editedExternalReferral && (
        <ExternalReferralModal
          show={showExternalReferral}
          setShow={setShowExternalReferral}
          concern={concern}
          referral={editedExternalReferral}
        />
      )}
    </Box>
  )
}

TimelineRow.propTypes = {
  externalReferral: PropTypes.object.isRequired,
  concern: PropTypes.object.isRequired
}

export default TimelineRow
