import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Popover,
  Grid,
  Typography
} from '@mui/material'
import Dialog from 'components/shared/dialog'
import { useMutation } from '@tanstack/react-query'
import { convertKeysToSnake } from 'utils'
import { createIntervention, updateIntervention } from 'api/interventions'
import InterventionForm from './form'
import moment from 'moment'
import { useHistory, useParams } from 'react-router'
import { useConcernType } from 'hooks/useConcernType'
import { isNull } from 'lodash'

export default function InterventionModal({
  concern,
  intervention,
  onAction,
  onClose,
  show,
  concerns
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'helper-text-popover' : undefined

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const history = useHistory()
  const params = useParams()

  const emptyState = (values = {}) => {
    if (concern) {
      return {
        id: values.id || null,
        concern_id: concern.id,
        concern_type_id: concern.concern_type_id,
        targeted_intervention_type_category_id:
          values.targeted_intervention_type_category_id || '',
        targeted_intervention_type_id:
          values.targeted_intervention_type_id || '',
        frequency: values.frequency || '',
        group_size: values.group_size || '',
        goal_description: values.goal_description || '',
        followed_up_at: values.followed_up_at || moment()._d,
        platform_name: values.platform_name || '',
        platform_id: values.platform_id || '',
        assessment_type: values.assessment_type || '',
        baseline: values?.baseline,
        baseline_date: values.baseline_date || moment()._d,
        target: values?.target,
        target_date: values.target_date || moment()._d
      }
    } else if (concerns) {
      // use concern_type_id from first concern in array as they are all the same
      return {
        id: values.id || null,
        concern_ids: concerns.map(c => c.id),
        concern_type_id: concerns[0].concern_type_id,
        targeted_intervention_type_category_id:
          values.targeted_intervention_type_category_id || '',
        targeted_intervention_type_id:
          values.targeted_intervention_type_id || '',
        frequency: values.frequency || '',
        group_size: values.group_size || '',
        goal_description: values.goal_description || '',
        followed_up_at: values.followed_up_at || moment()._d,
        platform_name: values.platform_name || '',
        platform_id: values.platform_id || '',
        assessment_type: values.assessment_type || '',
        baseline: values?.baseline,
        baseline_date: values.baseline_date || null,
        target: values?.target,
        target_date: values.target_date || null
      }
    }
  }

  const [formData, setFormData] = useState(emptyState)

  const { data: concernType } = useConcernType({
    concernTypeId: formData.concern_type_id
  })

  const newInterventionMutation = useMutation(
    params => {
      return createIntervention(params)
    },
    {
      onSuccess: data => {
        if (data.length > 1) {
          onClose()
          window.location.reload()
          // history.push('/wellness')
        } else {
          const url = params.student_id
            ? `/student/${params.student_id}/interventions/${data[0].id}`
            : `/interventions/${data[0].id}`
          onClose()
          history.push(url)
        }
      }
    }
  )

  const updateInterventionMutation = useMutation(
    params => {
      return updateIntervention(intervention.id, params)
    },
    {
      onSuccess: () => {
        onAction()
        onClose()
      }
    }
  )

  const handleSubmit = () => {
    let params = convertKeysToSnake(formData)

    if (intervention) {
      updateInterventionMutation.mutate(params)
    } else {
      newInterventionMutation.mutate(params)
    }
  }

  useEffect(() => {
    // reset modal/form to original state if cancelling an edit and then reopening.
    if (show) {
      setFormData(emptyState(intervention))
    }
  }, [show, intervention])

  const platforms = concernType?.platforms || []
  const bulkCreate = concerns?.length > 1
  const submitDisabled =
    !formData.targeted_intervention_type_category_id ||
    (platforms.length > 0 && !formData.platform_id) ||
    ((platforms.length == 0 || formData.platform_name === 'Other') &&
      !formData.assessment_type) ||
    (!bulkCreate &&
      (isNull(formData.baseline) ||
        !formData.baseline_date ||
        isNull(formData.target) ||
        !formData.target_date)) ||
    (!bulkCreate && formData.baseline == formData.target)

  return (
    <Dialog
      title={
        <Box>
          <Typography>
            {`${intervention ? 'Edit' : 'New'} Targeted Intervention`}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClick}
          >
            See Example
          </Button>
        </Box>
      }
      open={show}
      onClose={onClose}
    >
      <DialogContent>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          sx={{ width: '75%' }}
        >
          <Grid container sx={{ p: 2 }}>
            <Grid item xs={12}>
              <Button
                variant={'contained'}
                size={'small'}
                onClick={() => handleClose()}
              >
                Close
              </Button>
              <Typography variant={'h6'} sx={{ pt: 2 }}>
                Example Concern Details
              </Typography>
              <span>
                Johnny refuses to enter the music room during specials time. He
                stands outside the door with his arms crossed and does not move.
                He does not escalate to physical aggression unless someone gets
                close to him or attempts to touch him. He constantly asks to see
                the counselor, but the counselor is not always available to
                help.
              </span>
              <Typography variant={'h6'} sx={{ pt: 2 }}>
                Example Targeted Intervention
              </Typography>
              <h6>Intervention:</h6>
              <span>
                This is an example of when I would suggest the Tier 2 behavior
                intervention of a check-in / check-out with the counselor so
                that he had a way to see the person he really liked without
                being &quot;in trouble.&quot; Additionally, a break pass to see
                the counselor sometime after specials could be offered as an
                incentive to entering the music room. Then on the targeted
                intervention setup page this guidance when intervention
                category, &quot;Behavior&quot; is selected:
              </span>
              <h6>Progress Monitoring:</h6>
              <span>
                To monitor progress of a tier 2 intervention that includes a
                check-in/check-out with a counselor, consider the following
                setup:
              </span>
              <Grid item sx={{ pl: 4 }}>
                <h6>Platform:</h6>
                <span>N/A</span>
                <h6>Baseline Value:</h6>
                <span>
                  0 (the number of times per day he walks into specials classes
                  on his own)
                </span>
                <h6>Target Value:</h6>
                <span>
                  5 (the number of consecutive days Johnny walks into specials
                  classes on his own)
                </span>
                <h6>Target Date:</h6>
                <span>
                  2 weeks from now - or however much time might be needed to
                  achieve 5 consecutive days
                </span>
                <h6>Goal:</h6>
                <span>
                  Johnny walks into his music class every day on his own for 5
                  consecutive days without needing to see the counselor.
                </span>
                <h6>Frequency:</h6>
                <span>Daily</span>
                <h6>Group Size:</h6>
                <span>Individual</span>
                <h6>Measurements of Improvement:</h6>
                <span>
                  Score updates would include consecutive counts, so if he is
                  successful today - the score update would be 1, then if
                  he&apos;s successful again tomorrow, his score update would be
                  2, etc.
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Popover>
        <InterventionForm
          formData={formData}
          setFormData={setFormData}
          interventionTypes={
            concernType?.targeted_intervention_type_options || []
          }
          platforms={platforms}
          school={(concern || concerns[0]).school}
          bulkCreate={bulkCreate}
        />
      </DialogContent>
      <DialogActions>
        <Button variant={'outlined'} color={'primary'} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={handleSubmit}
          disabled={submitDisabled}
        >
          {intervention ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

InterventionModal.propTypes = {
  concern: PropTypes.object,
  intervention: PropTypes.object,
  onAction: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  concerns: PropTypes.array
}
