import React from 'react'
import PropTypes from 'prop-types'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Title,
  XAxis,
  YAxis,
  ColumnSeries,
  Tooltip
} from 'react-jsx-highcharts'
import { Box } from '@mui/material'
import {
  fetchGroupCategories,
  humanizeCategories
} from 'utils/demographic-types'
import { calculatePercentage } from 'utils'
import { sum } from 'lodash'

const GraduationPlanChart = ({ data, plans, groupBy }) => {
  const plotOptions = {
    series: {
      stacking: 'normal'
    }
  }

  const categories = fetchGroupCategories(groupBy)

  function returnPercent(category, risk) {
    if (data[category]) {
      const part = data[category][risk]
      const whole = sum(Object.values(data[category]))
      return parseFloat(calculatePercentage(part, whole, 1))
    }
    return null
  }

  return (
    <Box
      id="js-graduation-plan-bar-chart"
      className="panel panel-default"
      style={{ minHeight: '400px' }}
    >
      <Box className="panel-body">
        <HighchartsChart plotOptions={plotOptions}>
          <Chart />
          <Title>Graduation Plans</Title>

          <XAxis
            id="x"
            categories={categories.map(c => humanizeCategories(c))}
          />

          <Tooltip
            headerFormat="<span style='font-size:16px;'>{point.x}</span><table>"
            pointFormat={
              '<tr><td style="color:{series.color};padding:0;font-size:14px;">{series.name}:&nbsp;</td>' +
              '<td style="padding:0;font-size:14px;"><b> {point.y}%</b></td></tr>'
            }
            footerFormat="</table>"
            crosshairs={true}
            shared={true}
            useHTML={true}
          />

          <YAxis id="number" max={100} labels={{ format: '{value}%' }}>
            {plans.map((plan, i) => (
              <ColumnSeries
                key={i}
                id={plan}
                name={plan || 'Blank'}
                data={categories.map(c => returnPercent(c, plan))}
              />
            ))}
          </YAxis>
        </HighchartsChart>
      </Box>
    </Box>
  )
}

GraduationPlanChart.propTypes = {
  data: PropTypes.object,
  plans: PropTypes.array,
  groupBy: PropTypes.string
}

export default withHighcharts(GraduationPlanChart, window.Highcharts)
