import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { default as SharedPage } from 'components/shared/page'
import PageContainer from 'shared/page-container'
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  Typography,
  Stack,
  Link as MuiLink
} from '@mui/material'
import { Link, Route, Switch } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import {
  getDyslexiaAddendums,
  getDyslexiaAddendumStats
} from 'api/DyslexiaAddendum'
import moment from 'moment'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { handleRailsRequestJsResponse } from 'utils/handleRailsRequestJsResponse'
import { isArray, isString } from 'lodash'
import { useDebounce } from 'hooks/useDebounce'
import { styled } from '@mui/system'
import { withRouterPropTypes } from 'utils/react-router-prop-types'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import WarningIcon from '@mui/icons-material/Warning'
import SchoolNameHyperlink from 'components/shared/SchoolNameHyperlink'
import StudentNameHyperlink from 'components/shared/StudentNameHyperlink'
import NewWindowButton from 'shared/new-window-button'

export const basePath = '/dyslexia_addendum_slips'

export function Routes() {
  return (
    <Switch>
      <Route exact path={`${basePath}`} component={DyslexiaAddendumIndex} />
    </Switch>
  )
}
Routes.basePath = basePath

function Page({ children, breadcrumbs = [], breadcrumb, ...props }) {
  return (
    <SharedPage {...props}>
      <PageContainer>
        <Breadcrumbs aria-label="breadcrumb" variant={'overline'}>
          <MuiLink component={Link} to="/forms">
            Forms
          </MuiLink>

          {breadcrumbs.map((crumb, index) =>
            isString(crumb) ? (
              <Typography key={index} variant="overline" color={'text.primary'}>
                {crumb}
              </Typography>
            ) : (
              <MuiLink key={index} component={Link} {...crumb} />
            )
          )}

          <Typography variant="overline" color={'text.primary'}>
            {breadcrumb || props.title}
          </Typography>
        </Breadcrumbs>

        <Typography variant={'h1'}>{props.title}</Typography>

        {children}
      </PageContainer>
    </SharedPage>
  )
}

Page.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.oneOf([PropTypes.string, MuiLink.propTypes])
  ),
  breadcrumb: PropTypes.string,
  ...SharedPage.propTypes
}

const StripedTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  }
}))

const DyslexiaAddendumIndex = () => {
  const [tableMutateEvents, _setTableMutateEvents] = useState(0)
  const [search, _setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 300)
  const [page, setPage] = useState(1)
  const handleChangePage = (_, value) => {
    setPage(value)
  }

  const { data: addendums } = useQuery(
    ['DyslexiaAddendums-index', tableMutateEvents, debouncedSearch, page],
    async () => {
      const params = { search: search, page: page }
      const response = await getDyslexiaAddendums(params)
      const json = await handleRailsRequestJsResponse(response)
      return json
    },
    {
      initialData: [],
      refetchOnWindowFocus: true
    }
  )

  const forms = addendums?.data
  const pagination = addendums?.pagination

  const { data: meta } = useQuery(
    ['DyslexiaAddendums-stats', tableMutateEvents, debouncedSearch, page],
    async () => {
      const params = { search: search, page: page }
      const response = await getDyslexiaAddendumStats(params)
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: {},
      refetchOnWindowFocus: true
    }
  )

  return (
    <Page
      name="All Dyslexia Addendums"
      title="SLIP Dyslexia Addendums"
      breadcrumb={'SLIP Dyslexia Addendums'}
    >
      <Stack>
        <Typography variant="body1">
          Use the link below to see which students require a dyslexia addendum
          based on the latest Amira assessment. There you can create a new
          dyslexia addendum.
        </Typography>
        <MuiLink component={Link} to="/academic_assessment_amiras">
          Amira Dyslexia Assessment Results
        </MuiLink>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Total Flagged for At Risk of Dyslexia by Amira Assessment:
            </Typography>
            <Typography variant="body1">
              {meta?.amira_flagged_at_dyslexia_risk}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Dyslexia Addendums Created:
            </Typography>
            <Typography variant="body1">
              {meta?.dyslexia_addendums} of {meta?.k3_requires_slip}
            </Typography>
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table id="slip-index-table">
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>
                  Scanned <br />
                  Document
                </TableCell>
                <TableCell>Signature Pad</TableCell>
                <TableCell>Student Number</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>School Name</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isArray(forms) &&
                forms.map(form => (
                  <StripedTableRow key={form.id}>
                    <TableCell>
                      <NewWindowButton
                        size={'small'}
                        variant={'contained'}
                        href={`/dyslexia_addendums/${form.id}/edit`}
                        text={'View'}
                      />
                    </TableCell>
                    <TableCell>
                      {form?.scanned_document_attachment?.id ? (
                        <CheckBoxIcon color="success" />
                      ) : (
                        <WarningIcon color="warning" />
                      )}
                    </TableCell>
                    <TableCell>
                      {form.has_hand_signatures ? (
                        <CheckBoxIcon color="success" />
                      ) : (
                        <WarningIcon color="warning" />
                      )}
                    </TableCell>
                    <TableCell>{form.student?.number}</TableCell>
                    <TableCell>
                      <StudentNameHyperlink
                        id={form.student?.id}
                        name={`${form.student?.first_name} ${form.student?.last_name}`}
                        tab="slip"
                      />
                    </TableCell>
                    <TableCell>
                      <SchoolNameHyperlink
                        id={form.school?.id}
                        name={form.school?.name}
                        tab="slip"
                      />
                    </TableCell>
                    <TableCell>{form.user?.name}</TableCell>
                    <TableCell>{moment(form.created_at).fromNow()}</TableCell>
                    <TableCell>{moment(form.updated_at).fromNow()}</TableCell>
                  </StripedTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          component="div"
          count={pagination?.total_pages || 100}
          page={page}
          onChange={handleChangePage}
        />
      </Stack>
    </Page>
  )
}

DyslexiaAddendumIndex.propTypes = {
  ...withRouterPropTypes
}

export default DyslexiaAddendumIndex

export { DyslexiaAddendumIndex }
