import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material'
import Dialog from 'components/shared/dialog'
import { DatePicker } from '@mui/x-date-pickers'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import {
  createConcernRootCauseStrategy,
  updateConcernRootCauseStrategy
} from 'api/root-causes'

function RootCauseStrategyDialog({
  show,
  setShow,
  concern,
  rootCause,
  rootCauseStrategy,
  setRootCauseStrategy
}) {
  const queryClient = useQueryClient()
  const [rootCauseStrategyId, setRootCauseStrategyId] = useState(
    rootCauseStrategy?.root_cause_strategy_id || null
  )

  const [date, setDate] = useState(
    moment(rootCauseStrategy?.date).toDate() || moment().toDate()
  )
  const [notes, setNotes] = useState(rootCauseStrategy?.notes || '')

  const handleClose = () => {
    setShow(false)
    setRootCauseStrategy && setRootCauseStrategy(null)
  }

  const resetForm = () => {
    setRootCauseStrategyId(null)
    setDate(moment().toDate())
    setNotes('')
  }

  const newRootCauseStrategyMutation = useMutation(
    params => createConcernRootCauseStrategy(params),
    {
      onSuccess: () => {
        resetForm()
        queryClient.invalidateQueries(['concern', concern.id])
      },
      onSettled: () => {
        handleClose()
      }
    }
  )

  const updateRootCauseStrategyMutation = useMutation(
    params => updateConcernRootCauseStrategy(rootCauseStrategy.id, params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['concern', concern.id])
      },
      onSettled: () => {
        handleClose()
      }
    }
  )

  const handleSubmit = event => {
    event.preventDefault()

    const params = {
      concern_root_cause_id: rootCause.id,
      root_cause_strategy_id: rootCauseStrategyId,
      date,
      notes: notes.trim()
    }
    if (rootCauseStrategy) {
      updateRootCauseStrategyMutation.mutate(params)
    } else {
      newRootCauseStrategyMutation.mutate(params)
    }
  }

  return (
    <Dialog
      title={`${rootCauseStrategy ? 'Edit' : 'New'} Strategy for ${
        rootCause.name
      }`}
      open={show}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack>
            <DatePicker
              renderInput={props => <TextField {...props} />}
              disableToolbar
              required
              fullWidth
              value={date}
              inputFormat="MM/DD/YYYY"
              inputVariant="inline"
              margin="normal"
              id="date-event-on"
              onChange={val => setDate(val)}
              label="Date"
            />

            <FormControl fullWidth variant="outlined">
              <InputLabel id="rootCauseStrategy-label">Strategy</InputLabel>
              <Select
                labelId="rootCauseStrategy-label"
                id="rootCauseStrategy"
                label="Strategy"
                value={rootCauseStrategyId}
                onChange={e => setRootCauseStrategyId(e.target.value)}
                required
              >
                {concern.root_cause_strategies.map(strategy => (
                  <MenuItem key={`option-${strategy.id}`} value={strategy.id}>
                    {strategy.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="rootCauseStrategy-note"
              label="Note (Optional)"
              value={notes}
              onChange={e => setNotes(e.target.value.replace(/^\s+/, ''))}
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              helperText={'How will you implement this strategy?'}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button
            variant="contained"
            color="primary"
            type={'submit'}
            disabled={!rootCauseStrategyId}
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

RootCauseStrategyDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  concern: PropTypes.object.isRequired,
  rootCause: PropTypes.object.isRequired,
  rootCauseStrategy: PropTypes.object,
  setRootCauseStrategy: PropTypes.func
}

export default RootCauseStrategyDialog
