import PropTypes from 'prop-types'
import React from 'react'
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import PaperBox from 'components/shared/PaperBox'
import Spinner from 'components/shared/spinner/spinner'
import { round } from 'lodash'
import Comparison from 'components/shared/comparison'

const formatNumber = (num, decimalPlaces = 0) => {
  return Intl.NumberFormat().format(round(num, decimalPlaces))
}

function DistrictFoundationalMetrics({ metric }) {
  const [comparison, setComparison] = React.useState('week')

  return (
    <PaperBox title="Foundational Metrics">
      {!metric ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table size="small" aria-label="metrics-table">
            <TableHead>
              <TableRow sx={{ fontWeight: 'bold' }}>
                <TableCell sx={{ maxWidth: '33%' }}>
                  Compare to:{' '}
                  <ToggleButtonGroup
                    color="primary"
                    value={comparison}
                    exclusive
                    onChange={val => setComparison(val.target.value)}
                    aria-label="Display as"
                    disabled
                  >
                    <ToggleButton title="1 Week Ago" value="week">
                      W
                    </ToggleButton>
                    <ToggleButton title="1 Month Ago" value="month">
                      M
                    </ToggleButton>
                    <ToggleButton title="1 Year Ago" value="year">
                      Y
                    </ToggleButton>
                  </ToggleButtonGroup>
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>Current</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>Change</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Attendance
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {metric.today.attendance
                    ? formatNumber(metric.today.attendance[0])
                    : '--'}
                  {'%'}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Chronic Absenteeism
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {metric.today.absenteeism
                    ? formatNumber(metric.today.absenteeism[0])
                    : '--'}
                  {'%'}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Referrals
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {metric.today.referrals
                    ? formatNumber(metric.today.referrals[0])
                    : '--'}
                  {'%'}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Suspensions
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {metric.today.suspensions
                    ? formatNumber(metric.today.suspensions[0], 1)
                    : '--'}
                  {'%'}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Blank Grades
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {metric.today.blank_grades
                    ? formatNumber(metric.today.blank_grades)
                    : '--'}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}></TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Enrollment
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  {metric.today.enrollment
                    ? formatNumber(metric.today.enrollment)
                    : '--'}
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <Box
                    title={`${metric[`previous_${comparison}`].enrollment} → ${
                      metric.today.enrollment
                    }`}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Comparison
                      current={metric.today.enrollment}
                      previous={metric[`previous_${comparison}`].enrollment}
                      percentage={false}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </PaperBox>
  )
}

DistrictFoundationalMetrics.propTypes = {
  loading: PropTypes.bool,
  metric: PropTypes.object
}

export default DistrictFoundationalMetrics
