import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'components/shared/table'
import { Box, Button } from '@mui/material'

export default class SectionTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    loading: PropTypes.bool,
    removeAccess: PropTypes.func
  }

  columns = () => [
    {
      Header: 'First Name',
      accessor: 'first_name'
    },
    {
      Header: 'Last Name',
      accessor: 'last_name'
    },
    {
      Header: 'Grade Level',
      accessor: 'section_name'
    },
    {
      Header: 'Actions',
      id: 'user-actions',
      sortable: false,
      accessor: obj => obj.id,
      Cell: props => (
        <Box>
          <Button
            variant="contained"
            color="warning"
            onClick={() => this.props.removeAccess(props.value)}
          >
            Remove Access
          </Button>
        </Box>
      )
    }
  ]

  render() {
    const { data, loading } = this.props

    return (
      <div id="teacher-table">
        <Table columns={this.columns()} data={data} loading={loading} />
      </div>
    )
  }
}
