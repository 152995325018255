import React from 'react'
import GoalsTable from './table'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { currentSchoolYear } from 'utils'
import { includes, isEqual } from 'lodash'
import Page from 'components/shared/page'
import ResourcesModal from './resources-modal'
import { routerPropTypes } from 'utils/prop-types'
import { userPropTypes } from 'modules/current-user'
import { actions as goalActions } from 'redux/modules/goals'
import PageContainer from 'components/shared/page-container'
import { actions as schoolActions, selectors } from 'modules/schools'
import { Box, Button, Stack, Typography } from '@mui/material'
import LinkIcon from '@mui/icons-material/Link'
import SimpleAutocomplete from 'components/shared/simple-autocomplete'

class SchoolGoals extends React.Component {
  static propTypes = {
    ...routerPropTypes,
    ...userPropTypes,
    fetchTableData: PropTypes.func,
    updateGoal: PropTypes.func
  }

  state = {
    openModal: false,
    schoolYear: currentSchoolYear,
    editAccess: false,
    loading: true
  }

  componentDidMount() {
    this.handleFetchGoals()
    this.checkForSchoolInMatchParams()
    this.checkForLocalStorageSchoolYear()
    this.canViewResources()
    this.canEditGoals()
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.schoolId, this.props.schoolId)) {
      this.updateUrlForSchool()
      this.handleFetchGoals()
    }
  }

  handleModalHide = () => {
    this.setState({ openModal: false })
  }

  handleOpenModal = () => {
    this.setState({ openModal: true })
  }

  canViewResources() {
    const { user } = this.props
    return includes(['district', 'principal'], user.role)
  }

  canEditGoals() {
    const { user } = this.props
    this.setState({ editAccess: user.role === 'principal' })
  }

  checkForSchoolInMatchParams() {
    const { match, schools } = this.props
    const id = parseInt(match.params.id)
    if (id) {
      const school = schools.find(s => isEqual(s.value, id))
      this.schoolChanged(school)
    }
  }

  checkForLocalStorageSchoolYear() {
    if (localStorage.selectedSchoolYear) {
      this.setState({ schoolYear: localStorage.selectedSchoolYear.toString() })
    }
  }

  schoolChanged = val => {
    if (val && val.value) {
      this.props.selectSchool(val.value)
    }
  }

  schoolYearChanged = val => {
    if (val) {
      this.setState({ schoolYear: val.toString() })
      localStorage.setItem('selectedSchoolYear', val)
    }
  }

  handleFetchGoals = () => {
    const { schoolId, fetchTableData } = this.props
    const params = {
      id: schoolId
    }
    this.setState({ loading: true })
    fetchTableData(params)
    this.setState({ loading: false })
  }

  updateUrlForSchool() {
    const { schoolId, school, location, history } = this.props
    const newPath = location.pathname.replace(
      /^\/schools\/\d*/,
      `/schools/${schoolId}`
    )
    history.replace(newPath)
    document.title = `${school.name} -- TPS Data`
  }

  render() {
    const { schoolId, school, schools } = this.props
    const { schoolYear, openModal, editAccess, loading } = this.state
    const options = [
      {
        value: currentSchoolYear + 1,
        label: currentSchoolYear + '-' + (currentSchoolYear + 1)
      },
      {
        value: currentSchoolYear,
        label: currentSchoolYear - 1 + '-' + currentSchoolYear
      },
      {
        value: currentSchoolYear - 1,
        label: currentSchoolYear - 2 + '-' + (currentSchoolYear - 1 + '')
      }
    ]

    return (
      <Page name="School Goals" title={`${school.name} - Goals`}>
        <PageContainer>
          <Stack>
            <Box>
              {schools.length > 1 && (
                <Stack direction="row" justifyContent="flex-end">
                  <Box
                    id="test-school-selector"
                    sx={{
                      minWidth: '20rem'
                    }}
                  >
                    <SimpleAutocomplete
                      label="Select School"
                      value={schoolId}
                      options={schools}
                      onChange={this.schoolChanged}
                    />
                  </Box>
                </Stack>
              )}
            </Box>

            <Box
              sx={{
                mb: 6
              }}
            >
              <Typography variant="h1" id="test_header_title">
                Goals
              </Typography>
              <Typography variant="h2" id="test_subheader_title">
                {school.name}
              </Typography>
            </Box>

            <Box>
              <Stack direction="row" spacing={2}>
                {this.canViewResources() && (
                  <Button
                    id="js-admin-view-school-links"
                    variant="contained"
                    onClick={() => this.handleOpenModal()}
                    startIcon={<LinkIcon />}
                  >
                    Resources
                  </Button>
                )}
              </Stack>
            </Box>

            <Box
              sx={{
                my: 4
              }}
            >
              <SimpleAutocomplete
                className="spec-cohort-select"
                label="School Year"
                value={schoolYear}
                options={options}
                onChange={val => this.schoolYearChanged(val && val.value)}
              />
            </Box>

            <Box>
              <GoalsTable
                fetchData={this.handleFetchGoals}
                editAccess={editAccess}
                loading={loading}
                year={schoolYear}
              />
            </Box>

            {this.canViewResources() && (
              <ResourcesModal
                show={openModal}
                onHide={this.handleModalHide}
                schoolName={school.name}
                schoolId={schoolId}
                canViewResources={this.canViewResources}
              />
            )}
          </Stack>
        </PageContainer>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  school: selectors.currentSchool(state),
  schoolId: state.schools.schoolId,
  schools: selectors.schoolOptions(state)
})

const mapDispatchToProps = {
  selectSchool: schoolActions.selectSchool,
  fetchTableData: goalActions.fetchTableData,
  updateGoal: goalActions.updateGoal
}

export default connect(mapStateToProps, mapDispatchToProps)(SchoolGoals)
