import React from 'react'
import { Route, Switch } from 'react-router-dom'
import TulsaWayLanding from './tulsa-way/landing'
import TulsaWayLearningWalk from './tulsa-way/learning-walk'
import TulsaWayLearningWalkReview from './tulsa-way/learning-walk/review'
import TulsaWayWalkToRead from './tulsa-way/walk-to-read'
import TulsaWayWalkToReadReview from './tulsa-way/walk-to-read/review'
import TulsaWayInformalObservation from './tulsa-way/informal-observation'
import TulsaWayInformalObservationReview from './tulsa-way/informal-observation/review'
import ProtectedRoute from 'shared/protected-route'
import { withRouterPropTypes } from 'utils/react-router-prop-types'
import { useSelector } from 'react-redux'

export const permissions = {
  performInformalObservation: user =>
    ['district', 'principal', 'support'].includes(user.role),
  performLearningWalk: user =>
    ['district', 'principal', 'support'].includes(user.role),
  performWalkToRead: user =>
    ['district', 'principal', 'support'].includes(user.role)
}

export function TulsaWay({ match }) {
  const user = useSelector(state => state.currentUser.user)

  return (
    <Switch>
      <Route path={`${match.url}`} exact component={TulsaWayLanding} />

      <Route
        exact
        path={`${match.url}/learning-walk/:id`}
        component={TulsaWayLearningWalkReview}
        redirectUrl={match.url}
      />

      <ProtectedRoute
        path={`${match.url}/learning-walk/:id`}
        component={TulsaWayLearningWalk}
        isPermitted={permissions.performLearningWalk(user)}
        redirectUrl={match.url}
      />

      <ProtectedRoute
        path={`${match.url}/learning-walk`}
        component={TulsaWayLearningWalk}
        isPermitted={permissions.performLearningWalk(user)}
        redirectUrl={match.url}
      />

      <Route
        exact
        path={`${match.url}/walk-to-read/:id`}
        component={TulsaWayWalkToReadReview}
        redirectUrl={match.url}
      />

      <ProtectedRoute
        path={`${match.url}/walk-to-read/:id`}
        component={TulsaWayWalkToRead}
        isPermitted={permissions.performWalkToRead(user)}
        redirectUrl={match.url}
      />

      <ProtectedRoute
        path={`${match.url}/walk-to-read`}
        component={TulsaWayWalkToRead}
        isPermitted={permissions.performWalkToRead(user)}
        redirectUrl={match.url}
      />

      <Route
        exact
        path={`${match.url}/informal-observation/:id`}
        component={TulsaWayInformalObservationReview}
        redirectUrl={match.url}
      />

      <ProtectedRoute
        path={`${match.url}/informal-observation/:id`}
        component={TulsaWayInformalObservation}
        isPermitted={permissions.performInformalObservation(user)}
        redirectUrl={match.url}
      />

      <ProtectedRoute
        path={`${match.url}/informal-observation`}
        component={TulsaWayInformalObservation}
        isPermitted={permissions.performInformalObservation(user)}
        redirectUrl={match.url}
      />
    </Switch>
  )
}

TulsaWay.propTypes = {
  ...withRouterPropTypes
}

export default TulsaWay
