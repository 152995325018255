import React, { useState, useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import AdminJobsTable from './table'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'redux/modules/admins/jobs'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/admin-page-container'
import { convertObjectToReactTableFormat } from 'utils/react-table-helpers'
import {
  Box,
  OutlinedInput,
  InputAdornment,
  Stack,
  Typography,
  FormControl,
  InputLabel
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

function AdminJobs() {
  const dispatch = useDispatch()
  const [search, setSearch] = useState(null)
  const [sorter, setSorter] = useState({ column: 'title', direction: 'asc' })
  const [isLoading, setIsLoading] = useState(true)

  const jobs = useSelector(state => state.jobs.byId)
  const pages = useSelector(state => state.jobs.meta.pages)

  const fetchJobsTableData = useCallback(
    debounce(
      attrs => {
        setIsLoading(true)
        const params = {
          search,
          ...attrs
        }
        dispatch(actions.fetchJobsTableData(params))
          .then(() => {
            setIsLoading(false)
            setSorter({ column: attrs.sort, direction: attrs.sort_direction })
          })
          .catch(() => {
            setIsLoading(false)
          })
        dispatch(actions.fetchJobRoles())
      },
      300,
      { leading: false, trailing: true }
    ),
    [search, dispatch]
  )

  useEffect(() => {
    fetchJobsTableData({})
  }, [fetchJobsTableData])

  const handleSearchChange = e => {
    setSearch(e.target.value)
    fetchJobsTableData({})
  }

  return (
    <Page name="Admin-Jobs" title="All Jobs">
      <PageContainer>
        <Stack direction="column" spacing={3}>
          <Typography variant="h1">Jobs</Typography>
          <form
            onSubmit={e => {
              e.preventDefault()
            }}
          >
            <Box>
              <FormControl fullWidth>
                <InputLabel htmlFor="search">Search</InputLabel>
                <OutlinedInput
                  id="search"
                  value={search}
                  onChange={handleSearchChange}
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </Box>
          </form>
          <AdminJobsTable
            data={convertObjectToReactTableFormat(
              jobs,
              sorter.column,
              sorter.direction
            )}
            totalPages={pages}
            loading={isLoading}
            fetchData={fetchJobsTableData}
          />
        </Stack>
      </PageContainer>
    </Page>
  )
}

AdminJobs.propTypes = {
  fetchJobsTableData: PropTypes.func,
  fetchJobRoles: PropTypes.func,
  jobs: PropTypes.object,
  pages: PropTypes.number
}

export default AdminJobs
