import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Badge as MuiBadge,
  Box,
  ButtonGroup,
  Collapse,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  colors,
  styled,
  Tooltip
} from '@mui/material'
import ProgressBar from './progress-bar'
import {
  studentShape,
  gradPlanNodeShape
} from 'utils/grad-plan-progress-prop-types'
import {
  updateGradPlanRequirementAction,
  destroyGradPlanRequirementAction
} from 'api/grad-plan-requirement-actions'
import { useQueryClient } from '@tanstack/react-query'
import {
  Edit,
  Check,
  Cancel,
  Delete,
  Redo,
  Add,
  Circle,
  CircleOutlined,
  CheckCircleOutlineOutlined,
  CancelOutlined,
  NotInterestedOutlined,
  Pending,
  Info
} from '@mui/icons-material'
import RequirementActionDialog from './requirement-action-dialog'
import RequirementRows, { activeRequirementsCounter } from './requirement-rows'

const Badge = styled(MuiBadge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))

const termMap = {
  '00': 'Y1',
  '01': 'S1',
  '02': 'S2'
}

const requirementActionStatusIconMap = {
  active: CircleOutlined,
  successful: CheckCircleOutlineOutlined,
  unsuccessful: CancelOutlined,
  cancelled: NotInterestedOutlined
}

function RequirementActionStatusIcon({ status }) {
  const Component = requirementActionStatusIconMap[status]

  return <Component />
}
RequirementActionStatusIcon.propTypes = {
  status: PropTypes.string.isRequired
}

const yearTerm = termid => {
  const match = `${termid}`.match(/^(\d{2})(\d{2})$/)
  if (!match) {
    throw new Error(`invalid termid: ${termid}`)
  }
  const [yearid, t] = match.slice(1)
  const year = parseInt(yearid) + 1991
  const term = termMap[t] || ''
  const combined = `${year} ${term}`

  return { year, term, combined }
}

function ActionButton({ student, node, action }) {
  return (
    <RequirementActionDialog {...{ student, node, action }}>
      {({ openDialog }) => (
        <IconButton
          size={'small'}
          onClick={event => {
            event.stopPropagation()
            openDialog()
          }}
          title={'Edit'}
        >
          <Edit fontSize="inherit" />
        </IconButton>
      )}
    </RequirementActionDialog>
  )
}
ActionButton.propTypes = {
  student: studentShape,
  node: gradPlanNodeShape.isRequired,
  action: PropTypes.object
}

function ExpandedDetail({
  student,
  node,
  requirementActions,
  requirementsEarnedAndEnrolled,
  actionable
}) {
  const queryClient = useQueryClient()

  const invalidateQuery = () => {
    queryClient.invalidateQueries({
      queryKey: ['grad-plan-requirement-actions', student.id]
    })
  }

  const handleUpdate = action => {
    updateGradPlanRequirementAction(action.id, action).then(() =>
      invalidateQuery()
    )
  }

  const handleDelete = id => {
    destroyGradPlanRequirementAction(id).then(() => invalidateQuery())
  }

  const earnedOrEnrolled = requirementsEarnedAndEnrolled.find(
    requirement => requirement.name === node.name
  )

  return (
    <>
      {/* Actions */}
      {actionable && (
        <Table sx={{ backgroundColor: colors.grey[100] }}>
          <TableHead sx={{ backgroundColor: colors.grey[300] }}>
            <TableRow>
              <TableCell width={1}>Status</TableCell>
              <TableCell width={1}>Year</TableCell>
              <TableCell width={1}>Term</TableCell>
              <TableCell>Method / Notes</TableCell>
              <TableCell width={1} sx={{ textAlign: 'right' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={5}>
                {/* Add Action */}
                <RequirementActionDialog {...{ student, node }}>
                  {({ openDialog }) => (
                    <Link
                      sx={{ cursor: 'pointer' }}
                      onClick={() => openDialog()}
                    >
                      Add Completion Plan for {node.name}
                    </Link>
                  )}
                </RequirementActionDialog>
              </TableCell>
            </TableRow>
            {requirementActions.map(action => (
              <TableRow key={action.id}>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  <RequirementActionStatusIcon status={action.status} />
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  {action.planned_school_year}
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  {action.planned_term}
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  <Typography>{action.method_of_completion}</Typography>
                  <Box sx={{ whiteSpace: 'pre-wrap' }}>{action.notes}</Box>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top', textAlign: 'right' }}>
                  <ButtonGroup>
                    {action.status === 'active' && (
                      <>
                        <IconButton
                          size={'small'}
                          title="Successful"
                          onClick={() =>
                            handleUpdate({ ...action, status: 'successful' })
                          }
                        >
                          <Check fontSize="inherit" />
                        </IconButton>
                        <IconButton
                          size={'small'}
                          title="Unsuccessful"
                          onClick={() =>
                            handleUpdate({
                              ...action,
                              status: 'unsuccessful'
                            })
                          }
                        >
                          <Cancel fontSize="inherit" />
                        </IconButton>
                        <ActionButton {...{ student, node, action }} />
                      </>
                    )}
                    {action.status !== 'active' && (
                      <IconButton
                        size={'small'}
                        title="Reopen"
                        onClick={() =>
                          handleUpdate({ ...action, status: 'active' })
                        }
                      >
                        <Redo fontSize="inherit" />
                      </IconButton>
                    )}
                    <IconButton
                      size={'small'}
                      title="Delete"
                      onClick={() => handleDelete(action.id)}
                    >
                      <Delete fontSize="inherit" />
                    </IconButton>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {earnedOrEnrolled &&
        earnedOrEnrolled.earned.length + earnedOrEnrolled.enrolled.length >
          0 && (
          <Table sx={{ backgroundColor: colors.grey[100] }}>
            <TableHead sx={{ backgroundColor: colors.grey[300] }}>
              <TableRow>
                <TableCell width={1}>Status</TableCell>
                <TableCell width={1}>Year</TableCell>
                <TableCell width={1}>Term</TableCell>
                <TableCell width={1} sx={{ whiteSpace: 'nowrap' }}>
                  Course Number
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>Course Name</TableCell>
                <TableCell width={1}>Credits</TableCell>
                <TableCell width={1}>Grade</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {earnedOrEnrolled.earned.map(storedGrade => (
                <TableRow key={storedGrade.dcid}>
                  <TableCell title={'Stored'}>
                    <Circle
                      sx={{
                        color:
                          Number(storedGrade.earnedcrhrs) > 0
                            ? colors.green[700]
                            : 'white'
                      }}
                    />
                  </TableCell>
                  <TableCell>{yearTerm(storedGrade.termid).year}</TableCell>
                  <TableCell>{storedGrade.storecode}</TableCell>
                  <TableCell>
                    {storedGrade.course_number}{' '}
                    {storedGrade.course_number < 30000 && (
                      <Tooltip
                        title="HS/CR courses count toward graduation credit requirements but do not count toward number of HS courses required for graduation."
                        arrow
                        placement="top"
                      >
                        <Info />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{storedGrade.course_name}</TableCell>
                  <TableCell>{storedGrade.earnedcrhrs}</TableCell>
                  <TableCell>{storedGrade.grade}</TableCell>
                </TableRow>
              ))}
              {earnedOrEnrolled.enrolled.map(cc => (
                <TableRow key={cc.dcid}>
                  <TableCell title={'Enrolled'}>
                    <Circle sx={{ color: colors.green[300] }} />
                  </TableCell>
                  <TableCell>{yearTerm(cc.termid).year}</TableCell>
                  <TableCell>{yearTerm(cc.termid).term}</TableCell>
                  <TableCell>{cc.course_number}</TableCell>
                  <TableCell>{cc.course_name}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
    </>
  )
}
ExpandedDetail.propTypes = {
  student: studentShape,
  node: gradPlanNodeShape.isRequired,
  requirementActions: PropTypes.array.isRequired,
  requirementsEarnedAndEnrolled: PropTypes.array.isRequired,
  actionable: PropTypes.bool.isRequired
}

function CreditRequirementTableRow({
  student,
  node,
  gradPlanRequirementActions,
  requirementsEarnedAndEnrolled,
  level = 0
}) {
  const { creditsStored, creditsEnrolled, effectiveCreditCapacity } =
    node.groupDetail
  const stored = creditsStored / effectiveCreditCapacity
  const enrolled = creditsEnrolled / effectiveCreditCapacity
  const groupDetails = node.children.map(x => x.groupDetail)
  const completedCount = groupDetails.filter(
    groupDetail =>
      groupDetail.creditsStored / groupDetail.effectiveCreditCapacity === 1
  ).length
  const status = groupDetails.length
    ? `${completedCount} of ${groupDetails.length} Requirements`
    : `${creditsStored} of ${effectiveCreditCapacity} Credits`

  const [open, setOpen] = useState(false)
  const [collapsed, setCollapsed] = useState(true)

  const requirementActions = gradPlanRequirementActions.filter(
    gcp => gcp.ps_gp_node?.id == node.id
  )

  const completed = stored === 1
  const activeRequirementsCount = activeRequirementsCounter({
    node,
    gradPlanRequirementActions
  })
  const actionable = Boolean(activeRequirementsCount) || !completed

  React.useEffect(() => {
    setCollapsed(!open)
  }, [open])

  return (
    <>
      <TableRow
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          open ? setCollapsed(true) : setOpen(true)
        }}
      >
        <TableCell>
          <Link pl={level * 2}>{node.name}</Link>
        </TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>
          <ProgressBar stored={stored} enrolled={enrolled} />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {actionable && (
            <Box sx={{ my: -1 }}>
              <Badge badgeContent={activeRequirementsCount} color={'secondary'}>
                <Pending fontSize="small" />
              </Badge>
            </Box>
          )}
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={4} sx={{ padding: 0 }}>
            <Collapse
              appear={true}
              in={!collapsed}
              onExited={() => {
                setOpen(!collapsed)
              }}
            >
              <ExpandedDetail
                {...{
                  student,
                  node,
                  requirementActions,
                  requirementsEarnedAndEnrolled,
                  actionable
                }}
              />
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
CreditRequirementTableRow.propTypes = {
  student: studentShape,
  node: gradPlanNodeShape.isRequired,
  gradPlanRequirementActions: PropTypes.array,
  requirementsEarnedAndEnrolled: PropTypes.array,
  level: PropTypes.number
}

function CreditGroupTableRow({
  student,
  node,
  gradPlanRequirementActions,
  requirementsEarnedAndEnrolled,
  level = 0
}) {
  const { creditsStored, creditsEnrolled, effectiveCreditCapacity } =
    node.groupDetail
  const stored = creditsStored / effectiveCreditCapacity
  const enrolled = creditsEnrolled / effectiveCreditCapacity
  const groupDetails = node.children.map(x => x.groupDetail)
  const completedCount = groupDetails.filter(
    groupDetail =>
      groupDetail.creditsStored / groupDetail.effectiveCreditCapacity === 1
  ).length
  const status = groupDetails.length
    ? `${completedCount} of ${groupDetails.length} Requirements`
    : `${creditsStored} of ${effectiveCreditCapacity} Credits`
  const [open, setOpen] = useState(false)

  const completed = stored === 1
  const activeRequirementsCount = activeRequirementsCounter({
    node,
    gradPlanRequirementActions
  })
  const actionable = Boolean(activeRequirementsCount) || !completed

  return (
    <>
      <TableRow sx={{ cursor: 'pointer' }} onClick={() => setOpen(!open)}>
        <TableCell>
          <Link pl={level * 2}>{node.name}</Link>
        </TableCell>
        <TableCell>{status}</TableCell>
        <TableCell>
          <ProgressBar stored={stored} enrolled={enrolled} />
        </TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {!open && actionable && (
            <Box sx={{ my: -1 }}>
              <Badge badgeContent={activeRequirementsCount} color={'secondary'}>
                <Pending fontSize="small" />
              </Badge>
            </Box>
          )}
        </TableCell>
      </TableRow>

      {open && (
        <RequirementRows
          {...{
            student,
            node,
            gradPlanRequirementActions,
            requirementsEarnedAndEnrolled,
            level
          }}
        />
      )}
    </>
  )
}
CreditGroupTableRow.propTypes = {
  student: studentShape,
  node: gradPlanNodeShape.isRequired,
  gradPlanRequirementActions: PropTypes.array,
  level: PropTypes.number,
  requirementsEarnedAndEnrolled: PropTypes.array
}

export { CreditGroupTableRow, CreditRequirementTableRow }
export default CreditGroupTableRow
