export { default as actions } from './actions'
export { reducer } from './reducer'
import { currentSchoolYear } from 'utils'

export const emptyDataset = (schoolId, options = {}) => {
  return {
    schoolId,
    ells: [],
    cohorts: [],
    ethnicities: [],
    gradeLevels: [],
    gifted: null,
    sped: null,
    gender: null,
    socioeconomicallyDisadvantaged: null,
    schoolYear: currentSchoolYear,
    grad_status: null,
    failing_multiple: null,
    blank_grades_count: null,
    failed_core: null,
    has_student_success_plan: null,
    approximate_fay_status: null,
    current_platform: null,
    latest_map_reading_percentile_range: [],
    latest_map_math_percentile_range: [],
    latest_projected_reading_ostp_level: null,
    latest_projected_math_ostp_level: null,
    ostp_ela_band: null,
    ostp_mathematics_band: null,
    ostp_science_band: null,
    credit_range: [],
    graduation_plan: [],
    ...options
  }
}
