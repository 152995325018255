import { get } from 'utils/ajax'
import { alert } from 'utils/alert'

export const fetchTeachersForCsvReport = schoolId => {
  const params = {
    school_id: schoolId,
    per_page: 1000000,
    attendance: true,
    referrals: true
  }
  return get('/api/web/teachers', { params }).then(data => data.data)
}

export const fetchTeachers = params => {
  return get('/api/web/teachers', { params }).catch(_err => {
    alert('An error occurred while fetching the teachers', 'danger')
  })
}

export const getActivityFeed = (teacherId, params) => {
  return get(`/api/web/teachers/${teacherId}/feed_items`, { params }).catch(
    _err => {
      alert('An error occurred while fetching the teacher', 'danger')
    }
  )
}

export const getTeacherInfo = (
  teacherId,
  params = {},
  options = { alert: true }
) => {
  const promise = get(`/api/web/teachers/${teacherId}`, { params })

  if (options.alert) {
    return promise.catch(_err => {
      alert('An error occurred while fetching the teacher', 'danger')
    })
  }

  return promise
}

export const fetchAllTeachers = () => {
  const params = {
    school_id: this.props.schoolId,
    per_page: 1000000
  }

  return get('/api/web/teachers', { params }).then(data => data.data)
}
