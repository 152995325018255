import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import Header from 'components/shared/header'
import { getIntervention } from 'api/interventions'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { toInteger } from 'lodash'
import { formatDate } from 'utils/time-helpers'
import InterventionModal from 'components/shared/interventions/modal'
import Scores from './scores'
import Note from 'components/wellness/note/index.jsx'
import NoteTimeline from 'components/wellness/note/timeline'
import { titleize } from 'utils'
import Status from 'components/concerns/show/status'
import { useParams, Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import TrackChangesIcon from '@mui/icons-material/TrackChanges'
import Typography from '@mui/material/Typography'

function displayPlatform(platformName, assessmentType) {
  if (platformName && assessmentType) {
    return `${platformName}: ${assessmentType}`
  } else if (platformName) {
    return platformName
  } else {
    return assessmentType
  }
}

function CategoryHeader({ title }) {
  return <Typography variant={'subtitle1'}>{title.toUpperCase()}</Typography>
}

CategoryHeader.propTypes = {
  title: PropTypes.string.isRequired
}

function CategoryContent(props) {
  return <Typography variant={'body1'} {...props} />
}

CategoryContent.propTypes = {
  children: PropTypes.any
}

function Intervention() {
  const queryClient = useQueryClient()
  const params = useParams()
  const interventionId = toInteger(params.id)
  const [show, setShow] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const { data: intervention } = useQuery(
    ['intervention', interventionId],
    async () => {
      const data = await getIntervention(interventionId)
      return data
    },
    {
      onSuccess: data => {
        document.title = `${data.student_name} - Intervention`
        setIsDisabled(
          data.closed ||
            data.is_resolved ||
            data.concern.closed ||
            data.concern.is_resolved
        )
      }
    }
  )

  const concernUrl =
    intervention && params.student_id
      ? `/student/${params.student_id}/concerns/${intervention?.concern_id}`
      : `/concerns/${intervention?.concern_id}`

  const onClose = () => {
    setShow(false)
  }

  const onAction = _action => {
    queryClient.invalidateQueries(['intervention', interventionId])
    queryClient.invalidateQueries([`interventions/${interventionId}/scores`])
  }

  return (
    <Page name="Show Intervention">
      <PageContainer>
        {intervention && (
          <Stack>
            <Box>
              <Grid
                container
                columnSpacing={2}
                component={Link}
                to={concernUrl}
              >
                <Grid item>
                  <ChevronLeftIcon />
                </Grid>
                <Grid item>
                  <CategoryHeader title={'back to concern'} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item>
                  <TrackChangesIcon />
                </Grid>
                <Grid item>
                  <CategoryHeader title={'targeted intervention'} />
                </Grid>
              </Grid>
            </Box>
            <Header>
              <Header.Contents>
                <Header.Title>
                  <span>{intervention.targeted_intervention_name}</span>
                  {/* Add popover/hover the status component and tell users to click to manage status. */}
                  <Status
                    clickable={true}
                    parent={intervention}
                    queryName="intervention"
                  />
                </Header.Title>
                <Header.Filters>
                  <Grid container item justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      onClick={() => setShow(true)}
                      disabled={isDisabled}
                    >
                      Edit
                    </Button>
                  </Grid>
                </Header.Filters>
              </Header.Contents>
              <Stack>
                <Grid container columnSpacing={2}>
                  <Grid item xs={2}>
                    <CategoryHeader title={'opened'} />
                    <CategoryContent>
                      {formatDate(intervention.followed_up_at)}
                    </CategoryContent>
                  </Grid>
                  <Grid item xs={2}>
                    <CategoryHeader title={'concern'} />
                    <CategoryContent>
                      <Link to={concernUrl}>
                        {intervention.concern.concern_name}
                      </Link>
                    </CategoryContent>
                  </Grid>
                  <Grid item xs={2}>
                    <CategoryHeader title={'student'} />
                    <CategoryContent>
                      <Link
                        to={`/student/${intervention.concern.student_id}/wellness`}
                      >
                        {intervention.student_name}
                      </Link>
                    </CategoryContent>
                  </Grid>
                  <Grid item xs={2}>
                    <CategoryHeader title={'submitter'} />
                    <CategoryContent>{intervention.ownerName}</CategoryContent>
                  </Grid>
                  <Grid item xs={2}>
                    <CategoryHeader title={'frequency'} />
                    <CategoryContent>
                      {titleize(intervention.frequency)}
                    </CategoryContent>
                  </Grid>
                  <Grid item xs={2}>
                    <CategoryHeader title={'group size'} />
                    <CategoryContent>
                      {titleize(intervention.group_size)}
                    </CategoryContent>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container columnSpacing={2}>
                  {(intervention.targeted_intervention_description ||
                    intervention.targeted_intervention_links) && (
                    <Grid item xs={4}>
                      <CategoryHeader title={'about intervention'} />
                      <CategoryContent>
                        {intervention.targeted_intervention_description}
                        <br />
                        {intervention.targeted_intervention_links?.map(
                          (link, i) => (
                            <span key={i}>
                              <a
                                href={link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Resource {i + 1}
                              </a>
                            </span>
                          )
                        )}
                      </CategoryContent>
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <CategoryHeader title={'goal'} />
                    <CategoryContent>
                      {intervention.goal_description}
                    </CategoryContent>
                  </Grid>
                  <Grid item xs={4}>
                    <CategoryHeader title={'platform or assessment type'} />
                    <CategoryContent>
                      {displayPlatform(
                        intervention.platform_name,
                        intervention.assessment_type
                      )}
                    </CategoryContent>
                  </Grid>
                </Grid>
              </Stack>
            </Header>
            <Scores intervention={intervention} isDisabled={isDisabled} />
            <Box>
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <h3>Recent Notes</h3>
                </Grid>
                <Grid item container xs={6} justifyContent="flex-end">
                  <Note parent={intervention} queryName="intervention" />
                </Grid>
              </Grid>
              <NoteTimeline parent={intervention} queryName="intervention" />
            </Box>
            <InterventionModal
              concern={intervention.concern}
              intervention={intervention}
              onAction={onAction}
              onClose={onClose}
              show={show}
            />
          </Stack>
        )}
      </PageContainer>
    </Page>
  )
}

export default Intervention
