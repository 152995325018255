import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PageTitle from 'components/shared/page-title'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/styles'
import Stack from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import { useTeacher } from 'hooks/useTeacher'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { groupBy } from 'lodash'

function Row({ data, absences }) {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell>{`${data['year'] - 1}-${data['year']}`}</TableCell>
        <TableCell align="center">{data['attendance_percent']}</TableCell>
        <TableCell align="center">
          {data['attendance_percent_excluding_unavoidable']}
        </TableCell>
        <TableCell align="center">{data['total_days']}</TableCell>
        <TableCell align="center">{data['days_present']}</TableCell>
        <TableCell align="center">{data['unavoidable_absences']}</TableCell>
        <TableCell align="center">{data['avoidable_absences']}</TableCell>
        <TableCell align="center">
          <IconButton
            aria-label="expand-row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableCell align="center" comopnent="th">
                  Date
                </TableCell>
                <TableCell align="center" comopnent="th">
                  Avoidable
                </TableCell>
                <TableCell align="center" comopnent="th">
                  Reason
                </TableCell>
              </TableHead>
              <TableBody>
                {absences?.map(absence => (
                  <TableRow key={absence.id}>
                    <TableCell align="center">{absence.date}</TableCell>
                    <TableCell align="center">
                      {absence.avoidable == 1 ? 'Yes' : 'No'}
                    </TableCell>
                    <TableCell align="center">{absence.reason}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

Row.propTypes = {
  data: PropTypes.shape({
    year: PropTypes.number.isRequired,
    attendance_percent: PropTypes.number.isRequired,
    attendance_percent_excluding_unavoidable: PropTypes.number.isRequired,
    total_days: PropTypes.number.isRequired,
    days_present: PropTypes.number.isRequired,
    unavoidable_absences: PropTypes.number.isRequired,
    avoidable_absences: PropTypes.number.isRequired
  }),
  absences: PropTypes.arrayOf(
    PropTypes.shape({
      avoidable: PropTypes.number.isRequired,
      date: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      reason: PropTypes.string,
      year: PropTypes.number.isRequired
    })
  )
}

function Attendance({ teacherId }) {
  const theme = useTheme()
  const params = {
    include_attendance_info: true
  }
  const { isSuccess, data } = useTeacher(teacherId, params)

  const teacher = data?.teacher

  const absencesForAllYears = groupBy(teacher?.absences_for_all_years, 'year')

  return (
    <React.Fragment>
      <PageTitle title={'Attendance Data'} />
      <Stack>
        <TableContainer component={Paper} sx={{ marginTop: theme.spacing(4) }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell component="th">School Year</TableCell>
                <TableCell align="center" component="th">
                  Attendance % (Actual)
                </TableCell>
                <TableCell align="center" component="th">
                  Attendance % (excl. Unavoidable)
                </TableCell>
                <TableCell align="center" component="th">
                  Total Days (#)
                </TableCell>
                <TableCell align="center" component="th">
                  Days Present (#)
                </TableCell>
                <TableCell align="center" component="th">
                  Unavoidable Absences (#)
                </TableCell>
                <TableCell align="center" component="th">
                  Avoidable Absences (#)
                </TableCell>
                <TableCell align="center" component="th">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isSuccess &&
                teacher.historical_attendances.map(yearData => (
                  <Row
                    key={yearData['year']}
                    data={yearData}
                    absences={absencesForAllYears[yearData['year']]}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </React.Fragment>
  )
}

Attendance.propTypes = {
  teacherId: PropTypes.number.isRequired
}

export default Attendance
