import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getInterventionTypesForConcern } from 'api/interventions'

const makeOptions = (data, id) =>
  data
    .filter(t => !t.is_archived || t.id === id)
    .map(t => ({ id: t.id, name: t.name }))

const defaultValue = (id, options) =>
  (id && options.find(option => option.id === id)) || null

const useSupportOptionsDefaults = {
  options: {}
}
export function useSupportOptions({
  params: { schoolCategory, concernTypeId, id },
  options
} = useSupportOptionsDefaults) {
  const queryKey = [
    'support-type-options',
    { schoolCategory, concernTypeId, id }
  ]
  const queryFn = async () => {
    const params = { concern_type_id: concernTypeId }
    if (schoolCategory) {
      params.level = ['middle', 'high'].includes(schoolCategory)
        ? 'secondary'
        : 'elementary'
    }

    const res = await getInterventionTypesForConcern(params)

    return makeOptions(res.data, id)
  }
  const query = useQuery(queryKey, queryFn, {
    enabled: Boolean(concernTypeId),
    initialData: [],
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}

export function SupportAutocomplete({
  schoolCategory,
  concernTypeId,
  inputProps = {},
  onChange = () => {},
  onInputChange = () => {},
  value: id,
  inputValue: inputValueProp = '',
  required,
  ...props
}) {
  const [value, setValue] = React.useState(null)
  const [inputValue, setInputValue] = React.useState(inputValueProp)
  const { data: options } = useSupportOptions({
    params: { schoolCategory, concernTypeId, id }
  })

  React.useEffect(() => {
    if (!options.length && (value || inputValue)) {
      setValue(null)
    }
  }, [options])

  return (
    <Autocomplete
      disabled={!options.length}
      value={value || defaultValue(id, options)}
      onChange={(event, newValue) => {
        setValue(newValue)
        onChange(event, newValue)
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
        onInputChange(event, newInputValue)
      }}
      options={options.sort((a, b) => -b.name.localeCompare(a.name))}
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          label={'Support'}
          required={required}
          {...inputProps}
          {...params}
        />
      )}
      blurOnSelect={true}
      {...props}
    />
  )
}

SupportAutocomplete.propTypes = {
  schoolCategory: PropTypes.string,
  concernTypeId: PropTypes.number,
  inputProps: PropTypes.shape(TextField.propTypes),
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  value: PropTypes.number,
  inputValue: PropTypes.string,
  required: PropTypes.bool
}

export default SupportAutocomplete
