import React from 'react'
import PropTypes from 'prop-types'
import {
  styled,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell as MuiTableCell,
  TableRow
} from '@mui/material'
import { groupBy, round, sortBy, sumBy, uniq } from 'lodash'

const calculateProficiency = data => {
  const weightedCredits = sumBy(data, r => r.average_credit * r.responses)
  const responses = sumBy(data, 'responses')

  return round((weightedCredits / responses) * 100, 2)
}

function standardOrParentFullStatement(standard, hmh_oas) {
  return (
    hmh_oas[standard]?.full_statement ||
    hmh_oas[standard.replace(/\.[a-z]+$/, '')]?.full_statement
  )
}

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  verticalAlign: 'top',
  borderBottomColor: theme.palette.grey[200]
}))
const TableHeadCell = styled(TableCell)(() => ({
  fontWeight: 'bold',
  whiteSpace: 'nowrap'
}))

export function StandardsModules({ stats, hmh_oas }) {
  const standards = uniq(stats.map(x => x.standard))
  const module_numbers = sortBy(uniq(stats.map(x => x.module_number)))
  const groupedStats = groupBy(
    stats,
    ({ standard, module_number }) => `${standard} / ${module_number}`
  )

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableHeadCell>Standard</TableHeadCell>
              <TableHeadCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                Standard Description
              </TableHeadCell>
              {module_numbers.map(module_number => (
                <TableHeadCell key={module_number}>
                  Module {module_number}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {standards.map(standard => (
              <TableRow key={standard}>
                <TableCell>{standard}</TableCell>
                <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
                  {standardOrParentFullStatement(standard, hmh_oas)}
                </TableCell>
                {module_numbers
                  .map(module_number => [
                    module_number,
                    groupedStats[`${standard} / ${module_number}`]
                  ])
                  .map(([module_number, data]) => (
                    <TableCell key={module_number}>
                      {data && `${calculateProficiency(data)}%`}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

StandardsModules.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string.isRequired,
      standard: PropTypes.string.isRequired,
      module_number: PropTypes.number.isRequired,
      grade_level: PropTypes.number.isRequired,
      school_id: PropTypes.number.isRequired,
      responses: PropTypes.number.isRequired,
      average_credit: PropTypes.number.isRequired
    })
  ),
  hmh_oas: PropTypes.objectOf(
    PropTypes.shape({
      full_statement: PropTypes.string.isRequired
    })
  )
}

export default StandardsModules
