import React from 'react'
import PropTypes from 'prop-types'
import { ListItemText, ListItemButton, Typography } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import PeopleIcon from '@mui/icons-material/People'

function TeacherListItem({ searchResult, ...props }) {
  return (
    <ListItemButton {...props}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText
        primary={searchResult.searchable.name}
        secondary={
          <React.Fragment>
            <Typography mr={2}>Teacher</Typography>
            <Typography mr={2}>{searchResult.employee_id}</Typography>
            <Typography mr={2}>
              {searchResult.searchable.current_school}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItemButton>
  )
}

TeacherListItem.propTypes = {
  searchResult: PropTypes.object.isRequired
}

export default TeacherListItem
