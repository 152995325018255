import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/styles'

function DangerCell({ percent, isDanger }) {
  const theme = useTheme()
  const color = isDanger
    ? theme.palette.error.main
    : theme.palette.primary.black
  const weight = isDanger
    ? theme.typography.fontWeightBold
    : theme.typography.fontWeightNormal

  return (
    <Typography
      variant="body1"
      style={{
        color: color,
        fontWeight: weight
      }}
    >
      {percent}%
    </Typography>
  )
}

DangerCell.propTypes = {
  percent: PropTypes.number.isRequired,
  isDanger: PropTypes.bool.isRequired
}

export default DangerCell
