import UsersApi from './api'
import { types } from './reducer'

export const fetchUserTableData = params => {
  return dispatch => {
    return UsersApi.getUserTableData(params).then(data => {
      dispatch({ type: types.FETCH_USER_TABLE_DATA, payload: data })
    })
  }
}

export const fetchUserSchools = userId => {
  return dispatch => {
    return UsersApi.getUserSchools(userId).then(data => {
      dispatch({ type: types.FETCH_USER_SCHOOLS, payload: data })
    })
  }
}
