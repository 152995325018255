import React from 'react'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import ChartContainer from 'components/shared/chart-container'
import queryString from 'query-string'
import DatasetsByTestingWindow from '../shared/datasets-by-testing-window'
import { AbsoluteSpinner } from 'components/shared/spinner'
import { convertKeysToSnake } from 'utils'
import { post } from 'utils/ajax'
import StudentFilters from 'components/shared/student-filters'
import withStudentsTable, {
  withStudentsTablePropTypes
} from '../shared/hoc-with-students-table'
import { connect } from 'react-redux'
import { userPropTypes } from 'modules/current-user'
import ChartOptions from './chart-options'
import { optionsPropTypes } from 'utils/contexts/options'
import SummaryTable from './summary-table'
import { selectors } from 'modules/schools'
import RouterLinkButton from '../../shared/router-link-button'
import { Typography } from '@mui/material'

class MapIndex extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['proficiency', 'growth']),
    filters: PropTypes.array.isRequired,
    school: PropTypes.object,
    ...withStudentsTablePropTypes,
    ...optionsPropTypes,
    ...userPropTypes
  }

  state = {
    chartType: 'proficiency',
    chartSubject: 'mathematics',
    chartLanguage: 'english',
    chartData: null,
    loading: false,
    datasets: []
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search)
    if (params.metric) {
      if (['growth', 'proficiency'].includes(params.metric)) {
        this.setState({ chartType: params.metric })
      }
    }

    if (params.subject) {
      if (
        ['mathematics', 'reading'].includes(params.subject) ||
        (params.subject === 'both' && params.metric !== 'growth')
      ) {
        this.setState({ chartSubject: params.subject })
      }
    }

    if (params.language) {
      if (
        ['english', 'spanish'].includes(params.language) ||
        params.language === 'both'
      ) {
        this.setState({ chartLanguage: params.language })
      }
    }
  }

  datasetParams = (datasets = null) => {
    // convert param keys to snake-case for API (eg. schoolYear -> school_year)
    const datasetParams = (datasets || this.state.datasets).map(dataset => {
      return convertKeysToSnake(dataset)
    })

    return {
      datasets: datasetParams
    }
  }

  showChronological = data => {
    const years = data.datasets.map(d => d.school_year)
    const uniqueYears = [...new Set(years)].length
    return uniqueYears > 1 ? true : false
  }

  submit = chartOptions => {
    this.setState({ loading: true })
    const params = {
      ...this.datasetParams(this.props.filters),
      ...chartOptions
    }
    this.props
      .fetchDatasetNames(params)
      .then(() => this.fetchChartData(params))
      .then(() => this.setState({ loading: false }))
      .catch(err => {
        this.setState({ loading: false })
        alert(`An error occurred fetching data: ${err.message}`)
      })
  }

  chartOptionChanged = (key, val) => {
    if (!['chartType', 'chartSubject', 'chartLanguage'].includes(key)) {
      throw new Error(`Invalid chart option: ${key}`)
    }

    this.submit({ [key]: val })
  }

  fetchChartData = datasetParams => {
    this.setState({ loading: true })
    const { chartType, chartSubject, chartLanguage } = this.state
    const yearlyComparison = this.showChronological(datasetParams)
    const params = {
      ...datasetParams,
      chart_options: {
        chart_type: datasetParams.chartType || chartType,
        chart_subject: datasetParams.chartSubject || chartSubject,
        chart_language: datasetParams.chartLanguage || chartLanguage,
        compare_school_year: yearlyComparison
      }
    }

    return post('/api/web/maps/chart_data', params).then(res => {
      this.setState({
        chartData: res.data.data,
        chartType: params.chart_options.chart_type,
        chartSubject: params.chart_options.chart_subject,
        chartLanguage: params.chart_options.chart_language
      })
    })
  }

  render() {
    const { school, user } = this.props

    const { loading, chartData, chartType, chartSubject, chartLanguage } =
      this.state

    return (
      <Page name="MAP" title={`MAP - ${school && school.name}`}>
        <StudentFilters submit={this.submit} />

        <Typography variant="h3" id="test_h3_title">
          MAP Scores
        </Typography>

        {user.role !== 'partner' && (
          <RouterLinkButton to={`/students?school_id=${school.id}&view=MAP`}>
            See Student Data
          </RouterLinkButton>
        )}

        <div>
          <ChartOptions
            type={chartType}
            subject={chartSubject}
            language={chartLanguage}
            onOptionChanged={(key, val) => this.chartOptionChanged(key, val)}
          />
          <div style={styles.container}>
            {loading && <AbsoluteSpinner size={50} />}

            <div style={loading ? styles.faded : null}>
              <ChartContainer>
                <DatasetsByTestingWindow
                  type={chartType}
                  data={chartData}
                  schoolName={school && school.name}
                />
              </ChartContainer>

              {chartData && <SummaryTable type={chartType} data={chartData} />}
            </div>
          </div>
        </div>
      </Page>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    position: 'relative'
  },
  faded: {
    opacity: 0.6
  }
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  filters: state.studentFilters.filters,
  school: selectors.currentSchool(state)
})

export const MapWithStudentsTable = withStudentsTable(MapIndex)
export default connect(mapStateToProps)(MapWithStudentsTable)
