import ChildStudiesApi from './api'
import { types } from './reducer'

export const createChildStudy = (studentId, userId, concernIds, note) => {
  return _ =>
    ChildStudiesApi.createChildStudy(studentId, userId, concernIds, note)
}

export const deleteChildStudy = childStudyId => {
  return dispatch => {
    return ChildStudiesApi.destroyChildStudy(childStudyId).then(data => {
      dispatch({
        type: types.DELETE_CHILD_STUDY,
        payload: {
          childStudyId,
          studentId: data.student_id
        }
      })
    })
  }
}

export const fetchChildStudy = childStudyId => {
  return ChildStudiesApi.getChildStudy(childStudyId)
}

export const fetchEligibleChildStudyStudents = schoolId => {
  return dispatch => {
    return ChildStudiesApi.getEligibleChildStudyStudents(schoolId).then(
      data => {
        dispatch({
          type: types.FETCH_ELIGIBLE_CHILD_STUDY_STUDENTS,
          payload: data
        })
      }
    )
  }
}
