import { get, post, patch } from 'utils/ajax'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'

const QUERY_KEY_BASE = 'tulsa-way:learning-walk'

export function useLearningWalk(id, options = {}) {
  const enabled = Boolean(id)
  const queryKey = [QUERY_KEY_BASE, id]
  const queryFn = () => get(`/api/web/tulsa_way/learning_walks/${id}`)
  const query = useQuery(queryKey, queryFn, { enabled, ...options })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}

export function useLearningWalkMutation() {
  const queryClient = useQueryClient()

  return useMutation(
    variables => {
      if (variables.id) {
        return patch(
          `/api/web/tulsa_way/learning_walks/${variables.id}`,
          variables
        )
      } else {
        return post('/api/web/tulsa_way/learning_walks', variables)
      }
    },
    {
      onMutate: async variables => {
        if (variables.id) {
          const key = [QUERY_KEY_BASE, variables.id]
          await queryClient.cancelQueries(key)
        }
      },
      onError: (_error, _variables, _context) => {},
      onSuccess: async (data, _variables, _context) => {
        const key = [QUERY_KEY_BASE, data.id]
        await queryClient.cancelQueries(key)
        queryClient.setQueryData(key, data)
      }
    }
  )
}
