import React from 'react'
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'

export default function AttendanceRiskTable() {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Risk</TableCell>
          <TableCell>Threshold</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Chip color="success" label="Not At Risk" />
          </TableCell>
          <TableCell>ADA &gt; 95%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Chip color="secondary" label="At Risk" />
          </TableCell>
          <TableCell>95% &ge; ADA &gt; 90%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Chip color="warning" label="Chronic" />
          </TableCell>
          <TableCell>90% &ge; ADA &gt; 80%</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Chip color="error" label="Critically Chronic" />
          </TableCell>
          <TableCell>80% &ge; ADA</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
