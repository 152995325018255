import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import DistrictGoalsModal from './district-goals-modal'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'

export default function DistrictGoals() {
  const [modalOpen, setModalOpen] = useState(false)

  const handleModalHide = () => {
    setModalOpen(false)
  }

  const openModal = () => {
    setModalOpen(true)
  }

  return (
    <Box>
      <DistrictGoalsModal show={modalOpen} onHide={handleModalHide} />

      <Button
        id="js-admin-view-district-goals"
        variant="contained"
        onClick={openModal}
        startIcon={<CrisisAlertIcon />}
      >
        District Goals
      </Button>
    </Box>
  )
}
