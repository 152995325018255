import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from 'shared/dialog'
import {
  createOrUpdateChildStudyTeamMember,
  deleteChildStudyTeamMember,
  fetchChildStudyTeamMembers
} from 'api/child-study-team-members'
import { fetchSchoolDetails } from 'api/schools'
import { titleize } from 'utils'
import { useQuery } from '@tanstack/react-query'
import { Box, Grid, Typography, Stack, Button } from '@mui/material'
import PaperBox from 'components/shared/PaperBox'
import MultiselectAutocomplete from 'components/shared/multiselect-autocomplete'
import { alert, Severity } from 'utils/alert'

async function fetchUsers(schoolId) {
  const schoolDetails = await fetchSchoolDetails(schoolId)
  return schoolDetails.users_with_access.map(u => ({
    label: u.name,
    value: u.id
  }))
}

function ChildStudyTeamMemberModal({ onHide, schoolId, showModal }) {
  const [selectedOptions, setSelectedOptions] = useState({})
  const [initialOptions, setInitialOptions] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)

  const { data: teamInfo } = useQuery(
    ['teamInfo', schoolId],
    () => fetchChildStudyTeamMembers(schoolId),
    { refetchOnWindowFocus: false }
  )

  const { data: schoolUsers } = useQuery(
    ['schoolUsers', schoolId],
    () => fetchUsers(schoolId),
    { refetchOnWindowFocus: false }
  )

  const essDepartmentMembers = teamInfo?.data?.filter(
    member => member.role === 'ess_department'
  )

  const sfssDepartmentMembers = teamInfo?.data?.filter(
    member => member.role === 'sfss_department'
  )

  const filteredSchoolUsers = schoolUsers?.filter(
    userOption =>
      !essDepartmentMembers?.map(m => m.user_id).includes(userOption.value) &&
      !sfssDepartmentMembers?.map(m => m.user_id).includes(userOption.value)
  )

  const handleChange = (role, selectedUserIds) => {
    setSelectedOptions(prev => ({ ...prev, [role]: selectedUserIds }))
    setHasChanges(true)
  }

  const handleSave = async () => {
    setIsSaving(true)

    const savePromises = Object.entries(selectedOptions).map(
      async ([role, selectedUserIds]) => {
        const existingUserIdsWithRole = teamInfo?.data
          ?.filter(m => m.role === role)
          .map(m => m.user_id)

        const newMemberPromises = selectedUserIds
          .filter(id => !existingUserIdsWithRole.includes(id))
          .map(userId =>
            createOrUpdateChildStudyTeamMember(role, userId, schoolId)
          )

        const destroyMemberPromises = existingUserIdsWithRole
          .filter(id => !selectedUserIds.includes(id))
          .map(userId => {
            const member = teamInfo.data.find(m => m.user_id === userId)
            return deleteChildStudyTeamMember(member.id)
          })

        return Promise.all([...newMemberPromises, ...destroyMemberPromises])
      }
    )

    try {
      await Promise.all(savePromises)
      alert('Child Study Team members successfully updated', Severity.SUCCESS)
      setIsSaving(false)
      setHasChanges(false)
      onHide()
    } catch (error) {
      alert(
        'There was an error updating Child Study Team members',
        Severity.ERROR
      )
      setIsSaving(false)
    }
  }

  useEffect(() => {
    const data = teamInfo?.data
    if (data) {
      const groupedOptions = data.reduce((acc, member) => {
        const role = member.role
        if (!acc[role]) acc[role] = []
        acc[role].push(member.user_id)
        return acc
      }, {})
      setSelectedOptions(groupedOptions)
      setInitialOptions(groupedOptions)
    }
  }, [teamInfo?.data])

  return (
    <Dialog
      open={showModal}
      onClose={onHide}
      id="child-study-team-member-modal"
      title="Manage Team"
    >
      <Box
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 2
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={isSaving || !hasChanges}
          >
            Save
          </Button>
        </Box>

        <Stack spacing={3}>
          <PaperBox title="Select Department Members">
            <Grid container spacing={2}>
              {teamInfo?.options.map(option => (
                <Grid item xs={12} sm={6} key={option}>
                  <Typography variant="overline">{titleize(option)}</Typography>
                  <MultiselectAutocomplete
                    id={option}
                    label="Select User(s)"
                    value={selectedOptions[option] || []}
                    options={filteredSchoolUsers}
                    onChange={val => handleChange(option, val)}
                  />
                </Grid>
              ))}
            </Grid>
          </PaperBox>

          <PaperBox title="ESS Department Members">
            {essDepartmentMembers?.map(member => (
              <Typography key={member.user_id} variant="body2">
                {member.name}
              </Typography>
            ))}
          </PaperBox>

          <PaperBox title="SFSS Department Members">
            {sfssDepartmentMembers?.map(member => (
              <Typography key={member.user_id} variant="body2">
                {member.name}
              </Typography>
            ))}
          </PaperBox>
        </Stack>
      </Box>
    </Dialog>
  )
}

ChildStudyTeamMemberModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  schoolId: PropTypes.number.isRequired,
  showModal: PropTypes.bool.isRequired
}

export default ChildStudyTeamMemberModal
