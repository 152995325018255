import React from 'react'
import PropTypes from 'prop-types'
import { Box, CircularProgress } from '@mui/material'

const AbsoluteSpinner = ({ size, style }) => {
  return (
    <Box className="AbsoluteSpinner" style={{ ...styles.container, ...style }}>
      <CircularProgress color="primary" size={size} />
    </Box>
  )
}

const styles = {
  container: {
    marginTop: '5em',
    position: 'absolute',
    display: 'flex',
    left: 0,
    justifyContent: 'center',
    width: '100%',
    zIndex: '10'
  }
}

AbsoluteSpinner.propTypes = {
  size: PropTypes.number,
  style: PropTypes.object
}

AbsoluteSpinner.defaultProps = {
  size: 20,
  style: {}
}

export default AbsoluteSpinner
