import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { titleize } from 'utils'

let sentry_initialized = false

export function sentry_init(config, user = null) {
  if (sentry_initialized || !config?.dsn || !config?.environment) {
    return
  }

  const integrations = [new BrowserTracing()]

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  const tracesSampleRate = 1.0

  Sentry.init({ ...config, integrations, tracesSampleRate })

  sentry_initialized = true

  if (user) {
    set_user(user)
  }
}

export function set_user(user = null) {
  const { id, name, email } = user
  const role = titleize(user.role)

  Sentry.setUser({ id, role, name, email })
}
