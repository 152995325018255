import { alert } from 'utils/alert'
import { destroy, get, patch, post } from 'utils/ajax'

const defaultOptions = {
  alertOnError: false,
  alertOnSuccess: false
}

export const createTeam = params => {
  return post('/api/web/wellness/teams', { team: params }).catch(_error => {
    alert('There was an error creating the team.', 'danger')
  })
}

export const getTeam = id => {
  return get(`/api/web/wellness/teams/${id}`).catch(_error => {
    alert('There was an error fetching the team.', 'danger')
  })
}

export const getTeams = params => {
  return get('/api/web/wellness/teams', { params: params }).catch(_error => {
    alert('There was an error fetching the teams.', 'danger')
  })
}

export const destroyTeam = (id, callback) => {
  return destroy(`/api/web/wellness/teams/${id}`, {})
    .catch(_ => {
      alert('Something went wrong!', 'danger')
    })
    .then(() => {
      alert('Team was deleted!', 'success')
      callback()
    })
}

export const createTeamUser = (params, { alertOnError } = defaultOptions) => {
  return post('/api/web/wellness/team_users', { team_user: params }).catch(
    error => {
      if (alertOnError) {
        alert('There was an error adding the team member.', 'danger')
      }
      throw error
    }
  )
}

export const getTeamUser = (id, { alertOnError } = defaultOptions) => {
  return get(`/api/web/wellness/team_users/${id}`).catch(error => {
    if (alertOnError) {
      alert('There was an error fetching the team member.', 'danger')
    }
    throw error
  })
}

export const updateTeamUser = (
  id,
  params,
  { alertOnError } = defaultOptions
) => {
  return patch(`/api/web/wellness/team_users/${id}`, params).catch(error => {
    if (alertOnError) {
      alert('There was an error updating the team member.', 'danger')
    }
    throw error
  })
}

export const getTeamUsers = (params, { alertOnError } = defaultOptions) => {
  return get('/api/web/wellness/team_users', { params: params }).catch(
    error => {
      if (alertOnError) {
        alert('There was an error fetching the team members.', 'danger')
      }
      throw error
    }
  )
}

export const destroyTeamUser = (
  id,
  { alertOnError, alertOnSuccess } = defaultOptions
) => {
  return destroy(`/api/web/wellness/team_users/${id}`, {})
    .catch(error => {
      if (alertOnError) {
        alert('There was an error removing the team member!', 'danger')
      }
      throw error
    })
    .then(response => {
      if (alertOnSuccess) {
        alert('Team member was removed!', 'success')
      }
      return response
    })
}

export const createNote = params => {
  return post('/api/web/wellness/notes', { note: params }).catch(_error => {
    alert('There was an error creating the note!', 'danger')
  })
}

export const updateNote = (id, params) => {
  return patch(`/api/web/wellness/notes/${id}`, { note: params }).catch(
    _error => {
      alert('There was an error updating the note!', 'danger')
    }
  )
}

export const destroyNote = id => {
  return destroy(`/api/web/wellness/notes/${id}`, {})
    .catch(_ => {
      alert('There was an error deleting the note!', 'danger')
    })
    .then(() => {
      alert('Note was deleted!', 'success')
    })
}

export const createSupport = params => {
  return post('/api/web/supports', { support: params }).catch(_error => {
    alert('There was an error creating the support!', 'danger')
  })
}

export const updateSupport = (id, params) => {
  return patch(`/api/web/supports/${id}`, { support: params }).catch(_error => {
    alert('There was an error updating the support!', 'danger')
  })
}

export const destroySupport = id => {
  return destroy(`/api/web/supports/${id}`, {})
    .catch(_ => {
      alert('There was an error deleting the support!', 'danger')
    })
    .then(() => {
      alert('Support was deleted!', 'success')
    })
}
