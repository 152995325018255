import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import moment from 'moment'
import { Stack } from '@mui/material'
import TulsaWayBadge from '../../../../../assets/images/tulsa-way/tulsa-way-badge.png'
import NewWindowLink from '../../../../components/shared/new-window-link'

export default function LearningWalkHeader({ observation }) {
  return (
    <Stack spacing={4}>
      <Stack
        direction={'row'}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Stack>
          <Typography variant={'h1'}>{observation.observation_type}</Typography>
          <NewWindowLink
            href="https://docs.google.com/document/d/1wSPDzRQuQc1pntF4LLMUndS4qLtHowqcmL4cf8EBs6I"
            text="Tulsa Way Rubric"
          />
        </Stack>

        <img src={TulsaWayBadge} style={{ height: '64px' }} />
      </Stack>

      <Grid container justifyContent={'space-between'}>
        <Grid item>
          <Typography variant={'h4'}>Observer</Typography>
          <Typography variant={'body1'}>
            {observation.observer.user.name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h4'}>Teacher</Typography>
          <Typography variant={'body1'}>
            {observation.observee.user.name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h4'}>Section</Typography>
          <Typography variant={'body1'}>{observation.section.name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h4'}>Started</Typography>
          <Typography variant={'body1'}>
            {moment(observation.started_at).format('MM/DD/YYYY hh:mm A')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant={'h4'}>Ended</Typography>
          <Typography variant={'body1'}>
            {observation.ended_at &&
              moment(observation?.ended_at).format('MM/DD/YYYY hh:mm A')}
          </Typography>
        </Grid>
      </Grid>
    </Stack>
  )
}

LearningWalkHeader.propTypes = {
  observation: PropTypes.object.isRequired
}
