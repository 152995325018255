import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import ScoreChart from './chart'
import ScoreDialog from './dialog'
import ScoreTable from './table'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import { fetchInterventionScores } from 'api/intervention-scores'
import { isNull } from 'lodash'

function Scores({ intervention, isDisabled }) {
  const [show, setShow] = useState(false)
  const [scoreProgressLabel, setScoreProgressLabel] = useState('')
  const [scoreProgressColor, setScoreProgressColor] = useState('default')

  const {
    isSuccess,
    isLoading,
    data: scores
  } = useQuery([`interventions/${intervention.id}/scores`], async () => {
    return fetchInterventionScores(intervention.id)
  })
  const sentiment =
    intervention.target > intervention.baseline ? 'positive' : 'negative'

  useEffect(() => {
    if (scores) {
      const lastScore = parseFloat(scores.slice(-1)[0]?.score)

      if (scores.length == 0) {
        setScoreProgressLabel('Missing baseline/target values')
        setScoreProgressColor('error')
      } else if (scores.length == 1) {
        setScoreProgressLabel('No measurements entered after baseline')
        setScoreProgressColor('default')
      } else if (
        (sentiment == 'positive' && lastScore >= intervention.target) ||
        (sentiment == 'negative' && lastScore <= intervention.target)
      ) {
        setScoreProgressLabel('Target met')
        setScoreProgressColor('success')
      } else if (
        (sentiment == 'positive' && lastScore > intervention.baseline) ||
        (sentiment == 'negative' && lastScore < intervention.baseline)
      ) {
        setScoreProgressLabel('Progress made')
        setScoreProgressColor('info')
      } else {
        setScoreProgressLabel('No progress made')
        setScoreProgressColor('warning')
      }
    }
  }, [intervention, scores])

  return (
    <Stack>
      <Grid container columnSpacing={2}>
        <Grid item xs={10} display={'flex'} alignItems={'center'}>
          <h3>Measurements of Improvement</h3>
          <Chip
            sx={{ ml: 2, mt: 3, fontSize: '1rem' }}
            label={scoreProgressLabel}
            color={scoreProgressColor}
          />
        </Grid>
        <Grid item xs={2}>
          <Grid container justifyContent={'flex-end'}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShow(true)}
              disabled={
                isDisabled ||
                isNull(intervention.baseline) ||
                isNull(intervention.target)
              }
            >
              Add Measurement
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {scores && (
        <ScoreChart
          scores={scores}
          target={intervention.target}
          targetDate={intervention.target_date}
        />
      )}
      <ScoreTable
        interventionId={intervention.id}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isDisabled={isDisabled}
        scores={scores}
      />
      <ScoreDialog
        interventionId={intervention.id}
        show={show}
        handleClose={() => setShow(false)}
        previousScore={null}
      />
    </Stack>
  )
}

Scores.propTypes = {
  intervention: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool.isRequired
}

export default Scores
