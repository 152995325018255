import React, { useState } from 'react'
import PropTypes from 'prop-types'

import StudentsTable from 'components/shared/students-table'
import AssignmentsModal from 'components/shared/students/show/grades/assignments-modal'
import PageTitle from 'components/shared/page-title'
import FilterContainer from 'components/shared/filter-container'
import SectionFilter from 'components/shared/filters/sections-select'
import SchoolFilter from './filters/school-select.jsx'

const studentColumns = [
  'selector',
  'last_name',
  'first_name',
  'grade_level',
  'alerts',
  'section_grade',
  'birthday',
  'ell',
  'idea',
  'school_attendance_percent',
  'latest_map_reading_percentile',
  'latest_map_math_percentile',
  'current_academic_intervention_platform',
  'weeks_met_threshold_on_intervention_platform',
  'last_four_weeks_met_threshold_on_intervention_platform',
  'student_notes'
]

function TeacherSections(props) {
  const {
    sectionId,
    sections,
    sectionOptions,
    schools,
    schoolOptions,
    schoolId,
    sectionChanged,
    schoolChanged,
    teacher
  } = props
  const [selectedStudentId, setSelectedStudentId] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const openModal = student => {
    setSelectedStudentId(student.id)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setSelectedStudentId(null)
  }

  const selectedSection = sections.find(section => sectionId == section.id)

  return (
    <React.Fragment>
      <FilterContainer>
        <SectionFilter
          section={sectionId}
          sectionChanged={sectionChanged}
          sectionOptions={sectionOptions}
        />
        {schools.length > 1 && (
          <SchoolFilter
            schoolId={schoolId}
            schoolOptions={schoolOptions}
            schoolChanged={schoolChanged}
          />
        )}
      </FilterContainer>

      <PageTitle title={'Sections'} />
      {selectedStudentId && (
        <AssignmentsModal
          onHide={closeModal}
          show={showModal}
          section={selectedSection}
          studentId={selectedStudentId}
        />
      )}
      <StudentsTable
        columns={studentColumns}
        filters={{
          section_id: sectionId || '',
          teacher_id: teacher.id,
          ...(schoolId && { school_id: schoolId })
        }}
        getTdProps={(_state, rowInfo, column) => {
          if (
            rowInfo &&
            rowInfo.row &&
            sectionId &&
            column.Header === 'Grade'
          ) {
            return {
              onClick: _e => openModal(rowInfo.original),
              className: 'cell-link'
            }
          } else {
            return {}
          }
        }}
        sectionId={sectionId === 0 ? '' : sectionId}
        sectionOptions={sectionOptions}
        teacherPage={true}
        teacher={teacher}
      />
    </React.Fragment>
  )
}

TeacherSections.propTypes = {
  sectionId: PropTypes.number,
  sections: PropTypes.array.isRequired,
  sectionOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number
    })
  ).isRequired,
  schools: PropTypes.array.isRequired,
  schoolOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number
    })
  ).isRequired,
  schoolId: PropTypes.number,
  sectionChanged: PropTypes.func.isRequired,
  schoolChanged: PropTypes.func.isRequired,
  teacher: PropTypes.object.isRequired
}

export default TeacherSections
