import React, { useState } from 'react'
import PropTypes from 'prop-types'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { NavLink } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Collapse } from '@mui/material'

export function SidebarNavLink({
  title,
  url,
  icon,
  active = false,
  newTab = false,
  children
}) {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }

  // https://dragoshmocrii.com/using-react-router-navlink-with-a-mui-listitembutton-typescript/
  // https://mui.com/material-ui/guides/composition/#caveat-with-inlining
  const NavLinkRef = (props, ref) => (
    <NavLink
      {...props}
      ref={ref}
      to={url}
      activeClassName={'Mui-selected'}
      // TODO: match and location have functionality we can leverage for more
      //       advanced active detection. `isActive` could be a potentional
      //       prop replacement for `active`.
      isActive={(_match, _location) => active}
    />
  )
  const NavLinkMemo = React.useMemo(() => React.forwardRef(NavLinkRef), [url])

  const buttonProps = newTab
    ? {
        component: 'a',
        href: url,
        target: '_blank'
      }
    : {
        component: NavLinkMemo
      }

  return (
    <>
      <ListItem
        disablePadding
        sx={{
          borderRight: theme =>
            active ? `2px solid ${theme.palette.primary.main}` : ''
        }}
      >
        <ListItemButton
          {...buttonProps}
          {...(children && { onClick: handleClick })}
        >
          <ListItemIcon
            sx={{
              '.Mui-selected > &': {
                color: theme => theme.palette.primary.main
              }
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              color: active ? 'primary.main' : 'primary.black'
            }}
          />
          {children && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: '#dfdddd' }}
        >
          {children}
        </List>
      </Collapse>
    </>
  )
}

SidebarNavLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  active: PropTypes.bool,
  newTab: PropTypes.bool,
  children: PropTypes.node
}

export default SidebarNavLink
