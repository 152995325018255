import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Link from '@mui/material/Link'
import ListSubheader from '@mui/material/ListSubheader'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { fetchColumnSets } from 'api/column-sets'
import { FormHelperText } from '@mui/material'
import { useHistory } from 'react-router-dom'

export const useColumnSets = ({ userId }) => {
  const queryClient = useQueryClient()

  const query = useQuery(
    ['column-sets'],
    async () => {
      const res = await fetchColumnSets({ user_id: userId })

      return res.data
    },
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  const invalidate = () => queryClient.invalidateQueries(['column-sets'])

  return { ...query, invalidate }
}

export const columnsFromColumnSet = ({ userId, name }) => {
  const { data: columnSets } = useColumnSets({ userId })
  return columnSets.find(columnSet => columnSet.name === name)?.columns || []
}

export const TableViewDropdown = ({
  userId,
  activeColumnSet,
  setActiveColumnSet,
  customizeTable
}) => {
  const history = useHistory()
  const { data: columnSets } = useColumnSets({ userId })
  const [columnSet, setColumnSet] = React.useState(activeColumnSet || '')

  const viewableToAllActiveColumnSets = columnSets?.filter(
    columnSet => columnSet.is_viewable_to_all && columnSet.user_id !== userId
  )

  const myOtherActiveColumnSets = columnSets?.filter(
    columnSet => columnSet.user_id === userId && columnSet.is_active === true
  )

  const myInactiveColumnSets = columnSets?.filter(
    columnSet => columnSet.user_id === userId && columnSet.is_active === false
  )

  React.useEffect(() => {
    setActiveColumnSet(columnSet)

    const params = new URLSearchParams(history.location.search)
    if (columnSet) {
      params.set('view', columnSet)
    } else {
      params.delete('view')
    }

    const search = params.toString()
    if (search) {
      history.push(`${history.location.pathname}?${search}`)
    } else {
      history.push(`${history.location.pathname}`)
    }
  }, [columnSet])

  React.useEffect(() => {
    setColumnSet(activeColumnSet)
  }, [activeColumnSet])

  return (
    <FormControl fullWidth>
      <InputLabel id={'table-view-select'}>Table View</InputLabel>
      <Select
        size={'small'}
        label={'Table View'}
        labelId={'table-view-select'}
        value={columnSet}
        onChange={val => setColumnSet(val.target.value)}
      >
        {viewableToAllActiveColumnSets.length > 0 && (
          <ListSubheader>Shared With You</ListSubheader>
        )}
        {viewableToAllActiveColumnSets?.map(columnSet => (
          <MenuItem
            key={columnSet.name}
            value={columnSet.name}
            disabled={!columnSet.is_active}
          >
            {columnSet.name}
          </MenuItem>
        ))}
        {myOtherActiveColumnSets.length > 0 && (
          <ListSubheader>My Active Table Views</ListSubheader>
        )}
        {myOtherActiveColumnSets?.map(columnSet => (
          <MenuItem
            key={columnSet.name}
            value={columnSet.name}
            disabled={!columnSet.is_active}
          >
            {columnSet.name}
          </MenuItem>
        ))}
        {myInactiveColumnSets.length > 0 && (
          <ListSubheader>My Inactive Table Views</ListSubheader>
        )}
        {myInactiveColumnSets?.map(columnSet => (
          <MenuItem
            key={columnSet.name}
            value={columnSet.name}
            disabled={!columnSet.is_active}
          >
            {columnSet.name} (Inactive)
          </MenuItem>
        ))}
      </Select>
      {customizeTable && (
        <FormHelperText>
          <Link onClick={() => customizeTable()} sx={{ cursor: 'pointer' }}>
            Customize Table
          </Link>
        </FormHelperText>
      )}
    </FormControl>
  )
}
TableViewDropdown.propTypes = {
  userId: PropTypes.any.isRequired,
  activeColumnSet: PropTypes.any,
  setActiveColumnSet: PropTypes.func.isRequired,
  customizeTable: PropTypes.func
}
