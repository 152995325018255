import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Box, Typography } from '@mui/material'
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { titleize } from 'utils'
import RootCauseDialog from './dialog'
import RootCauseStrategyDialog from './strategy-dialog'

function TimelineRow({ concernRootCause, concern }) {
  const [editedRootCause, setEditedRootCause] = useState(null)
  const [show, setShow] = useState(false)
  const [showStrategy, setShowStrategy] = useState(false)
  const [editedRootCauseStrategy, setEditedRootCauseStrategy] = useState(null)

  useEffect(() => {
    if (editedRootCause) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [editedRootCause])

  useEffect(() => {
    if (editedRootCauseStrategy) {
      setShowStrategy(true)
    } else {
      setShowStrategy(false)
    }
  }, [editedRootCauseStrategy])

  const disabled = concern.closed || concern.is_resolved || concern.is_archived

  return (
    <React.Fragment>
      <TimelineItem style={{ paddingRight: 0 }}>
        <TimelineOppositeContent
          style={{ flex: 0, padding: 0 }}
        ></TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot>
            <FontAwesomeIcon icon={faUser} style={{ color: '#fff7eb' }} />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{ paddingRight: 0 }}>
          <Typography variant="h4">
            Root Cause: {titleize(concernRootCause.name)}
            <Button
              disabled={disabled}
              onClick={() => {
                setEditedRootCause(concernRootCause)
                setShow(true)
              }}
            >
              Edit
            </Button>
            <Button disabled={disabled} onClick={() => setShowStrategy(true)}>
              Add Strategy
            </Button>
          </Typography>
          <Typography style={{ maxWidth: '35em' }}>
            {concernRootCause.notes}
          </Typography>
          <Typography variant="body2" color="textSecondary" className="mr-4">
            {moment(concernRootCause.date).format('MM/DD/YYYY')}
          </Typography>
          {concernRootCause.strategies.length > 0 && (
            <Box>
              <Typography variant={'h6'}>Strategies</Typography>
              {concernRootCause.strategies.map(strategy => (
                <Box key={strategy.id}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {strategy.name}{' '}
                    <Button
                      onClick={() => setEditedRootCauseStrategy(strategy)}
                    >
                      Edit
                    </Button>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className="mr-4"
                  >
                    {moment(strategy.date).format('MM/DD/YYYY')}
                  </Typography>
                  <Typography style={{ maxWidth: '35em' }}>
                    {strategy.notes}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </TimelineContent>
      </TimelineItem>
      {editedRootCause && (
        <RootCauseDialog
          show={show}
          setShow={setShow}
          concern={concern}
          rootCause={editedRootCause}
        />
      )}
      <RootCauseStrategyDialog
        show={showStrategy}
        setShow={setShowStrategy}
        concern={concern}
        rootCause={concernRootCause}
      />
      {editedRootCauseStrategy && showStrategy && (
        <RootCauseStrategyDialog
          show={showStrategy}
          setShow={setShowStrategy}
          concern={concern}
          rootCause={concernRootCause}
          rootCauseStrategy={editedRootCauseStrategy}
          setRootCauseStrategy={setEditedRootCauseStrategy}
        />
      )}
    </React.Fragment>
  )
}

TimelineRow.propTypes = {
  concernRootCause: PropTypes.object.isRequired,
  concern: PropTypes.object.isRequired
}

export default TimelineRow
