import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'

const FeedItemContent = ({ content }) => (
  <Typography
    variant="body1"
    component="div"
    sx={{ p: 4 }}
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

FeedItemContent.propTypes = {
  content: PropTypes.string.isRequired
}

export default FeedItemContent
