import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { AbsoluteSpinner } from 'components/shared/spinner'
import { useHtml } from 'hooks/useHtml'

const styles = {
  loading: {
    opacity: 0.6,
    pointerEvents: 'none',
    transition: 'opacity 200ms'
  }
}

function HtmlLoader({
  url,
  urlParams = {},
  onLoad,
  showLoadingIndicator = true
}) {
  const params = { ...urlParams }

  const { isLoading, data } = useHtml(url, params)
  const style = isLoading ? styles.loading : undefined
  const ref = React.useRef()

  useEffect(() => {
    if (data) {
      const parser = new DOMParser()
      const parsedDoc = parser.parseFromString(data, 'text/html')
      ref.current.innerHTML = parsedDoc.body.innerHTML

      document.dispatchEvent(new Event('content:load'))

      if (typeof onLoad === 'function') {
        onLoad(data)
      }
    } else {
      ref.current.innerHTML = ''
    }
  }, [data])

  return (
    <div>
      {isLoading && showLoadingIndicator && <AbsoluteSpinner />}
      <div ref={ref} style={style} />
    </div>
  )
}

HtmlLoader.propTypes = {
  url: PropTypes.string.isRequired,
  urlParams: PropTypes.object,
  onLoad: PropTypes.func,
  showLoadingIndicator: PropTypes.bool
}

export default HtmlLoader
