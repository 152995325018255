import React from 'react'
import PropTypes from 'prop-types'
import { updateGoal } from 'api/admin/goals'
import { titleize, currentSchoolYear } from 'utils'
import { fetchSchools } from 'api/admin/schools'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { alert, Severity } from 'utils/alert'
import Dialog from 'shared/dialog'
import SimpleAutocomplete from 'shared/simple-autocomplete'

export default class EditGoalModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    goal: PropTypes.object.isRequired,
    goalTypes: PropTypes.array,
    goalCategories: PropTypes.array
  }

  state = {
    category: null,
    goalId: null,
    comment: '',
    goalType: null,
    school: null,
    year: null,
    goalValue: '',
    user_id: null,
    principal: '',
    schoolName: '',
    schools: [],
    principals: []
  }

  componentDidMount() {
    this.handleFetchSchools()
    if (this.props.show) {
      this.resetModal()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.show !== prevProps.show && this.props.show) {
      this.resetModal()
    }
  }

  handleUpdateGoal = event => {
    event.preventDefault()
    const params = {
      id: this.state.goalId,
      category: this.state.category?.value,
      year: this.state.year,
      value: this.state.goalValue,
      goal_type: this.state.goalType?.value,
      comment: this.state.comment
    }
    updateGoal(params)
      .then(() => {
        alert('Goal successfully updated', Severity.SUCCESS)
        this.props.fetchData()
        this.props.onHide()
      })
      .catch(() => {
        alert('Failed to update goal', Severity.ERROR)
      })
  }

  handleFetchSchools = () => {
    const params = { active: true, per_page: 1000 }
    fetchSchools(params)
      .then(res => {
        const schoolOptions = res.data.map(school => ({
          label: titleize(school.name),
          value: school.id,
          principals: school.goal_principals.map(principal => ({
            label: titleize(principal[0]),
            value: principal[1]
          }))
        }))
        this.setState({ schools: schoolOptions })
      })
      .catch(() => alert('Failed to fetch schools'))
  }

  resetModal = () => {
    const { goal, goalTypes, goalCategories } = this.props

    const matchingCategory = goalCategories?.find(
      opt => opt.label.toLowerCase() === goal.category.toLowerCase()
    )

    const matchingType = goalTypes?.find(
      opt => opt.label.toLowerCase() === goal.goal_type.toLowerCase()
    )

    this.setState({
      category: matchingCategory,
      goalId: goal.id,
      comment: goal.comment || '',
      goalType: matchingType,
      school: goal.school_id || null,
      year: goal.year,
      goalValue: goal.value,
      user_id: goal.user_id,
      principal: goal.principal || '',
      schoolName: goal.school_name || ''
    })
  }

  render() {
    const { onHide, show, goalTypes, goalCategories } = this.props
    const years = [
      {
        value: currentSchoolYear + 1,
        label: `${currentSchoolYear}-${currentSchoolYear + 1}`
      },
      {
        value: currentSchoolYear,
        label: `${currentSchoolYear - 1}-${currentSchoolYear}`
      },
      {
        value: currentSchoolYear - 1,
        label: `${currentSchoolYear - 2}-${currentSchoolYear - 1}`
      }
    ]

    const {
      principal,
      schoolName,
      category,
      goalType,
      goalValue,
      comment,
      year
    } = this.state

    return (
      <Dialog open={show} onClose={onHide} title="Update Goal">
        <Box
          sx={{
            p: 4
          }}
        >
          <form onSubmit={this.handleUpdateGoal}>
            <Stack spacing={2}>
              <Box>
                <Typography variant="h4">
                  School: {titleize(schoolName)}
                </Typography>
                <Typography variant="h5">Principal: {principal}</Typography>
              </Box>

              <SimpleAutocomplete
                label="Select Year"
                options={years}
                value={years.find(opt => opt.value === year)}
                onChange={newValue => this.setState({ year: newValue.value })}
              />

              <SimpleAutocomplete
                label="Select Category"
                options={goalCategories}
                value={category}
                onChange={newValue => this.setState({ category: newValue })}
              />

              <SimpleAutocomplete
                label="Select Type"
                options={goalTypes}
                value={goalType}
                onChange={newValue => this.setState({ goalType: newValue })}
              />

              <TextField
                type="number"
                step="0.1"
                min="0.0"
                max="100"
                id="goalValue"
                value={goalValue}
                onChange={val => this.setState({ goalValue: val.target.value })}
                label="Value"
                placeholder="Enter value as a decimal between 0.0 and 100.0"
                fullWidth
              />

              <TextField
                id="comment"
                value={comment}
                onChange={val => this.setState({ comment: val.target.value })}
                label="Comment"
                placeholder="Enter comment"
                multiline
                rows={4}
                fullWidth
              />

              <Button variant="contained" type="submit">
                Update Goal
              </Button>
            </Stack>
          </form>
        </Box>
      </Dialog>
    )
  }
}
