async function handleRailsRequestJsResponse(response) {
  // no longer has a session and needs to sign in again.
  if (response.unauthenticated) {
    window.location = '/signin'
  }

  // rails create action didn't save and has errors
  // is an unprocessable_entity
  if (response.statusCode == 422) {
    const json = await response.json
    // is an unprocessable_entity because unauthenticated
    // Note: this is a json attr and not a response.statusCode
    if (json.detail == 401) {
      window.location = '/signin'
      return
    }
    alert(JSON.stringify(json), 'danger')
    return json
  }

  // rails destroy request was successful
  // 204 status code is used when the server successfully
  // processes the request, but there is no content to return to the client.
  if (response.statusCode == 204) {
    return { success: true }
  }

  // success
  if (response.ok) {
    const json = await response.json
    return json
  } else {
    // server responded with something other than 200, 401, or 422.
    alert(JSON.stringify(await response.json), 'danger')
    return null
  }
}

export { handleRailsRequestJsResponse }
