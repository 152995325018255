import PropTypes from 'prop-types'
import * as actions from './actions'
export { actions }
export * from './reducer'

export const userPropTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
    rights: PropTypes.shape({
      child_study_team_school_ids: PropTypes.array,
      has_admin_access: PropTypes.bool.isRequired
    }),
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    true_user: PropTypes.bool.isRequired,
    has_sections: PropTypes.bool.isRequired
  })
}
