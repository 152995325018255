import { CURRENT_URL } from '.'

export const types = {
  SET_CONFIG: 'reports/set_config'
}

const initialState = {
  env: 'production',
  app_instance: 'production',
  production_url: CURRENT_URL
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CONFIG:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}
