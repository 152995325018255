import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip as MuiTooltip } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

const Tooltip = ({ title, content, placement, children, ...props }) => (
  <MuiTooltip label={title} placement={placement} {...props}>
    {children ? children : <InfoIcon />}

    {content}
  </MuiTooltip>
)

Tooltip.defaultProps = {
  placement: 'top'
}

Tooltip.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any,
  children: PropTypes.node,
  placement: PropTypes.string
}

Tooltip.defaultProps = {
  placement: 'top'
}

export default Tooltip
