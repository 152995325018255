const attendancesDataSelector = ({ attendances }, studentId) =>
  (attendances.byStudentId[studentId] &&
    attendances.byStudentId[studentId].data) ||
  []

const attendancesChartDataSelector = ({ attendances }, studentId) =>
  (attendances.byStudentId[studentId] &&
    attendances.byStudentId[studentId].chart_data) ||
  []

export default {
  attendances: attendancesDataSelector,
  attendancesChartData: attendancesChartDataSelector
}
