import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { RemoteTable } from 'components/shared/table'
import ConcernDialog from 'components/shared/concerns/dialog'
import { getConcerns } from 'api/interventions'
import { useQueries } from '@tanstack/react-query'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import { Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { getConfidentialCrisisForms } from 'api/confidential-crisis-forms'
import { handleRailsRequestJsResponse } from 'utils/handleRailsRequestJsResponse'
import { useConcernTypeOptions } from 'components/shared/concerns/ConcernTypeAutocomplete'
import ConcernChildrenDropdown from 'components/shared/concerns/ConcernChildrenDropdown'
import { debounce, isNull, sortBy } from 'lodash'
import InterventionsTable from '../../../schools/interventions/interventions-table'
import { connect } from 'react-redux'

// This is /student/123/wellness
function Concerns({ student, user }) {
  const history = useHistory()
  const [show, setShow] = useState(false)
  const studentNumber = student.number
  const studentId = student.id
  const urlParams = new URLSearchParams(window.location.search)
  const [params, setParams] = useState({
    student_id: studentId,
    page: 1,
    per_page: 20,
    sort: 'updated_at',
    sort_direction: 'desc'
  })

  const initialFilters = {
    concern_type_id: [],
    status: ''
  }

  const [filters, setFilters] = useState({
    ...initialFilters,
    concern_type_id: urlParams.getAll('concern_type_ids') || [],
    status: urlParams.get('status') || ''
  })

  const [concernsQuery, crisisFormsQuery] = useQueries({
    queries: [
      {
        queryKey: ['concerns', params, filters],
        queryFn: () => getConcerns({ ...params, ...filters }),
        refetchOnWindowFocus: false,
        enabled: !isNull(studentId)
      },
      {
        queryKey: ['search-confidential-crisis-forms', studentNumber],
        queryFn: async () => {
          const params = {
            search: studentNumber
          }
          const response = await getConfidentialCrisisForms(params)
          const json = await handleRailsRequestJsResponse(response)
          return json.data
        },
        initialData: { isLoading: true, concerns: [] },
        refetchOnWindowFocus: false,
        enabled: studentNumber ? true : false
      }
    ]
  })

  const { data: concernTypes } = useConcernTypeOptions({
    includeConcernTypeCategory: true,
    initialData: []
  })

  const { isLoading, data: concerns } = concernsQuery
  const { data: crisis_forms } = crisisFormsQuery

  const onHide = ({ action, concernId }) => {
    setShow(false)
    if (action === 'create') {
      history.push(`/student/${studentId}/concerns/${concernId}`)
    }
  }

  const CreateOrViewCrisisFormButton = () => {
    const label =
      crisis_forms?.length > 0
        ? 'View Confidential Crisis Form'
        : 'Create Confidential Crisis Form'

    const hyperlink =
      crisis_forms?.length > 0
        ? `/confidential_crisis_forms?search=${studentNumber}`
        : `/confidential_crisis_forms/new?student_id=${studentId}`

    return (
      <Button
        component={Link}
        color="secondary"
        variant="contained"
        to={hyperlink}
      >
        {label}
      </Button>
    )
  }

  const handleChange = (key, val) => {
    setFilters({ ...filters, [key]: val })
  }

  const clearFilters = () => {
    setFilters(initialFilters)
  }

  const fetchInterventions = debounce(newParams => {
    setParams({ ...params, ...newParams })
  }, 300)

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Button
          id="create-intervention"
          variant="contained"
          color="primary"
          onClick={() => setShow(true)}
        >
          New Concern
        </Button>
        <Button
          variant="contained"
          color="primary"
          href={
            'https://tulsaschools.sjc1.qualtrics.com/jfe/form/SV_8G1udP9PkeXcgJ0'
          }
          target="_blank"
        >
          Request District Support
        </Button>
        <Button
          variant="contained"
          color="primary"
          href={
            'https://tulsaschools.sjc1.qualtrics.com/jfe/form/SV_9LGh9US9zzWooiV'
          }
          target="_blank"
        >
          Request Intensive Behavior Support
        </Button>
        <CreateOrViewCrisisFormButton />
      </Stack>

      <Stack direction="row" spacing={2}>
        <FormControl fullWidth>
          {/* check onChange for use with multiple */}
          <ConcernChildrenDropdown
            label={'Filter by Concern Type'}
            value={filters.concern_type_id}
            onChange={val => handleChange('concern_type_id', val)}
            options={[{ label: 'All', value: null }].concat(
              sortBy(concernTypes, 'name').map(concernType => ({
                label: concernType.name,
                value: concernType.id
              }))
            )}
            multiple={true}
            required={false}
            isSubmitted={false}
          />
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="status-label" data-testid="status-label">
            Filter by Status
          </InputLabel>
          <Select
            labelId="status-label"
            data-testid="status-select"
            label={'Filter By Status'}
            value={filters.status}
            onChange={val => handleChange('status', val.target.value)}
          >
            <MenuItem value={null}>All</MenuItem>
            <MenuItem value={'open'}>Open</MenuItem>
            <MenuItem value={'closed'}>Closed</MenuItem>
            <MenuItem value={'resolved'}>Resolved</MenuItem>
          </Select>
        </FormControl>

        <Button
          id="create-intervention"
          variant="contained"
          color="warning"
          onClick={() => clearFilters()}
        >
          Clear Filters
        </Button>
      </Stack>

      <InterventionsTable
        interventions={concerns?.data}
        loading={isLoading}
        schoolId={student.school_id}
        meta={concerns?.meta}
        fetchInterventions={fetchInterventions}
        user={user}
        queryKey={['concerns']}
      />
      <ConcernDialog show={show} onHide={onHide} studentId={studentId} />
    </Stack>
  )
}

Concerns.propTypes = {
  student: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.currentUser.user
})

export default connect(mapStateToProps)(Concerns)
