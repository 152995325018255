import React, { useState, useEffect } from 'react'
import { Box, Tooltip } from '@mui/material'
import { alert } from 'utils/alert'
import { fetchAlertTypes } from 'api/alerts'

export default function AlertPopover() {
  const [alertTypes, setAlertTypes] = useState({})

  useEffect(() => {
    fetchAlertTypes()
      .then(res => {
        setAlertTypes(res)
      })
      .catch(err => {
        alert(err)
      })
  }, [])

  const createPopover = () => {
    let table = []
    for (let alert in alertTypes) {
      table.push(
        <tr key={alert}>
          <td>
            <i className={`mr05 ${alertTypes[alert]}`} />
          </td>
          <td>{alert}</td>
        </tr>
      )
    }
    return table
  }

  return (
    <Box>
      <Tooltip title={createPopover()} placement="top" arrow label="Alerts">
        <span>Alerts</span>
      </Tooltip>
    </Box>
  )
}
