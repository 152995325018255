import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { createTeacherNote, updateTeacherNote } from 'api/notes'
import LockIcon from '@mui/icons-material/Lock'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import Dialog from 'components/shared/dialog'
import {
  styled,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { grey } from '@mui/material/colors'

function TeacherNoteDialog({ note, show, onHide, refreshFeed, teacherId }) {
  const [comment, setComment] = useState(note?.comment || '')
  const [isPrivate, setIsPrivate] = useState(note ? note.is_private : true)

  const resetValues = () => {
    setComment('')
    setIsPrivate(true)
  }

  const resetDialogAndRefresh = () => {
    onHide(false)
    resetValues()
    refreshFeed()
  }

  const handleSubmit = () => {
    const params = {
      comment: comment,
      teacher_id: teacherId,
      is_private: isPrivate
    }

    if (note) {
      updateTeacherNote(note.id, params).then(() => resetDialogAndRefresh())
    } else {
      createTeacherNote(params).then(() => resetDialogAndRefresh())
    }
  }

  const StyledToggleButton = styled(ToggleButton)({
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: grey[300]
    }
  })

  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>{note ? 'Update' : 'Create'} Teacher Note</DialogTitle>
      <DialogContent>
        <Stack>
          <FormControl fullWidth variant="outlined">
            <TextField
              required
              multiline
              rows={5}
              label="Note"
              id="note"
              value={comment}
              onChange={val => setComment(val.target.value)}
            />
          </FormControl>
          <ToggleButtonGroup
            exclusive
            value={isPrivate}
            onChange={val => setIsPrivate(val.target.value === 'true')}
          >
            <StyledToggleButton value={true} aria-label="private" id="private">
              Private <LockIcon />
            </StyledToggleButton>
            <StyledToggleButton value={false} aria-label="public" id="public">
              Public <LockOpenIcon />
            </StyledToggleButton>
          </ToggleButtonGroup>
          {isPrivate
            ? 'The note is only visible to you and the teacher.'
            : 'The note is visible to all users with access to the teacher.'}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          id="save-note"
          variant={'contained'}
          color={'primary'}
          onClick={() => handleSubmit()}
          disabled={!comment}
        >
          {note ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

TeacherNoteDialog.propTypes = {
  note: PropTypes.object,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  refreshFeed: PropTypes.func.isRequired,
  teacherId: PropTypes.number.isRequired
}

export default TeacherNoteDialog
