import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Table, TableBody, TableHead, TableRow } from '@mui/material'

import ObservationRow, { TableCell } from './observation-row'

const observationTypeRoutes = {
  'Walk to Read': 'walk-to-read',
  'Informal Observation': 'informal-observation',
  'Learning Walk': 'learning-walk'
}

const determineRoute = ({ observation_type }) => {
  const type = observation_type.match(/Learning Walk/)
    ? 'Learning Walk'
    : observation_type
  return observationTypeRoutes[type]
}

export function ObservationsTable({ observations, onDelete }) {
  const user = useSelector(state => state.currentUser.user)

  return (
    <Table size={'small'}>
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell sx={{ whiteSpace: 'nowrap' }}>Teacher/Section</TableCell>
          <TableCell>Observer</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Feedback</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {observations.map(observation => (
          <ObservationRow
            key={observation.id}
            determineRoute={determineRoute}
            observation={observation}
            onDelete={onDelete}
            user={user}
          />
        ))}
      </TableBody>
    </Table>
  )
}

ObservationsTable.propTypes = {
  observations: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired
}

export default ObservationsTable
