import React from 'react'
import PropTypes from 'prop-types'

const SortableHeader = ({ title }) => (
  <span>
    <span className="SortableHeader__content">{title}</span>
    <i className="fa SortableHeader__icon" />
  </span>
)

SortableHeader.propTypes = {
  title: PropTypes.string.isRequired
}

export default SortableHeader
