import React from 'react'
import PropTypes from 'prop-types'
import { currentSchoolYear } from 'utils'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select
} from '@mui/material'
import GoalSettingForm from './goal_setting_form'
import { fetchGoalSettings } from 'api/admin/goal_settings'
import Dialog from 'shared/dialog'

export default class GoalsModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
  }

  state = {
    goalSettings: [],
    year: currentSchoolYear
  }

  componentDidMount() {
    this.setState({
      goalSettings: this.handleFetchGoalSettings(this.state.year)
    })
  }

  handleChange = event => {
    const year = event.target.value
    try {
      this.setState({ year })
      this.setState({ goalSettings: this.handleFetchGoalSettings(year) })
    } catch (error) {
      console.error('Error in handleChange:', error)
    }
  }

  handleFetchGoalSettings = year => {
    let params = { year: year }
    fetchGoalSettings(params)
      .then(res => {
        this.setState({ goalSettings: res })
      })
      .catch(() => {
        alert('There was an error fetching the goal settings.', 'danger')
      })
  }

  render() {
    const { onHide, show } = this.props
    const { goalSettings, year } = this.state

    const options = [
      {
        value: currentSchoolYear + 1,
        label: `${currentSchoolYear}-${currentSchoolYear + 1}`
      },
      {
        value: currentSchoolYear,
        label: `${currentSchoolYear - 1}-${currentSchoolYear}`
      },
      {
        value: currentSchoolYear - 1,
        label: `${currentSchoolYear - 2}-${currentSchoolYear - 1}`
      }
    ]

    return (
      <Dialog open={show} onClose={onHide} title="Goal Settings Controls">
        <Box
          sx={{
            p: 4
          }}
        >
          <Stack>
            <Box id="year-selector-wrapper mb1">
              <FormControl fullWidth>
                <InputLabel id="year-selector-label">Select Year</InputLabel>
                <Select
                  labelId="year-selector-label"
                  id="year-selector"
                  value={year}
                  label="Select Year"
                  onChange={this.handleChange}
                >
                  {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>Enabled Value</TableCell>
                  <TableCell>Enabled Type</TableCell>
                  <TableCell>Enabled Comment</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goalSettings &&
                  goalSettings.map((goalSetting, i) => (
                    <GoalSettingForm
                      key={i}
                      goalSettingId={goalSetting.id}
                      enabledType={goalSetting.enabled_type}
                      enabledValue={goalSetting.enabled_value}
                      enabledComment={goalSetting.enabled_comment}
                      category={goalSetting.category}
                      fetchGoalSettings={this.handleFetchGoalSettings}
                      year={year}
                    />
                  ))}
              </TableBody>
            </Table>
          </Stack>
        </Box>
      </Dialog>
    )
  }
}
