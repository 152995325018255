import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector, useDispatch } from 'react-redux'
import { actions as schoolActions, selectors } from 'modules/schools'
import Page from 'components/shared/page'
import StudentsTable from '../shared/students-table'
import { studentFiltersChanged } from 'modules/student-filters/actions'
import PageContainer from 'shared/page-container'
import { Box, Stack, Typography } from '@mui/material'
import SchoolAutocomplete from '../shared/school-autocomplete'
import { columnsFromColumnSet } from '../shared/students-table/column-sets'

const splitCommaValuesToArray = (object = {}) => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      const values = value.split(',')
      return [key, values.length > 1 ? values : values[0]]
    })
  )
}

function Students({ history, studentFiltersChanged, userId }) {
  const [schoolId, setSchoolId] = useState(undefined)
  const school = useSelector(selectors.currentSchool)
  const dispatch = useDispatch()
  const changeSchool = id => dispatch(schoolActions.selectSchool(id))

  const urlParams = React.useMemo(() => {
    const params = Object.fromEntries(
      new URLSearchParams(history.location.search).entries()
    )
    const { school_id, view, ...filters } = params
    return { school_id, view, filters }
  }, [history.location.search])
  const view = urlParams.view
  const [activeColumnSet, setActiveColumnSet] = useState(view || 'Default')
  const columns = columnsFromColumnSet({ userId, name: activeColumnSet })

  useEffect(() => {
    studentFiltersChanged({}, splitCommaValuesToArray(urlParams.filters))
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(history.location.search)
    const school_id_param = params.get('school_id')

    if (typeof schoolId === 'undefined') {
      if (school_id_param === null) {
        params.set('school_id', school.id || '')
        setSchoolId(null)
      } else {
        const school_id = parseInt(school_id_param) || null
        changeSchool(school_id)
        setSchoolId(school_id)
      }
    } else {
      params.set('school_id', school.id || '')
      setSchoolId(school.id)
    }

    history.replace(`/students?${params}`)
  }, [schoolId, school.id, history.location.search])

  return (
    <Page name="Students" title={`Students - ${school?.name || 'All Schools'}`}>
      <PageContainer>
        <Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Box
              id="test-school-selector"
              sx={{
                minWidth: '20rem'
              }}
            >
              <SchoolAutocomplete disableClearable includeAllSchoolsOption />
            </Box>
          </Stack>
          <Box
            sx={{
              mb: 6
            }}
          >
            <Typography variant="h1">Students</Typography>
            <Typography variant="h2">
              {school?.name || 'All Schools'}
            </Typography>
          </Box>

          <StudentsTable
            columns={columns}
            filters={{ school_id: school.id }}
            setActiveColumnSet={setActiveColumnSet}
            activeColumnSet={activeColumnSet}
            includeLegends
            schoolId={school.id}
          />
        </Stack>
      </PageContainer>
    </Page>
  )
}

Students.propTypes = {
  studentFiltersChanged: PropTypes.func.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  userId: PropTypes.number.isRequired
}

const mapStateToProps = state => ({
  userId: state.currentUser.user.id
})

const mapDispatchToProps = {
  studentFiltersChanged
}

export default connect(mapStateToProps, mapDispatchToProps)(Students)
