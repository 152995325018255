import React from 'react'
import PropTypes from 'prop-types'
import Chart from './chart'
import { connect } from 'react-redux'
import StudentFilters from 'components/shared/student-filters'
import { AbsoluteSpinner } from 'components/shared/spinner'
import { convertKeysToSnake } from 'utils'
import { post } from 'utils/ajax'
import Page from 'components/shared/page'
import PageTitle from 'components/shared/page-title'
import ChartContainer from 'components/shared/chart-container'
import { selectors } from 'modules/schools'

class SatIndex extends React.Component {
  static propTypes = {
    filters: PropTypes.array.isRequired,
    school: PropTypes.object
  }

  state = {
    benchmarkPercentages: null,
    schoolYear: null
  }

  datasetParams = (datasets = null) => {
    // convert param keys to snake-case for API (eg. schoolYear -> school_year)
    const datasetParams = (datasets || this.props.filters).map(dataset => {
      return convertKeysToSnake(dataset)
    })

    return {
      datasets: datasetParams
    }
  }

  submit = () => {
    this.setState({ loading: true })
    const params = this.datasetParams(this.props.filters)

    this.fetchChartData(params)
      .then(() => this.setState({ loading: false }))
      .catch(err => {
        this.setState({ loading: false })
        alert(`An error occurred fetching data: ${err.message}`)
      })
  }

  fetchChartData = params => {
    return post('/api/web/sats/chart_data', params).then(data => {
      this.setState({
        benchmarkPercentages: data.data,
        schoolYear: data.year // only populated if all same
      })
    })
  }

  render() {
    const { school } = this.props
    const { benchmarkPercentages, schoolYear, loading } = this.state

    return (
      <Page name="SAT" title={`SAT – ${school && school.name}`}>
        <StudentFilters submit={this.submit} />

        <PageTitle title={'SAT Scores'} />
        <ChartContainer>
          <div style={styles.container}>
            {loading && <AbsoluteSpinner size={50} />}

            <div style={loading ? styles.faded : null}>
              <Chart
                data={benchmarkPercentages}
                schoolYear={schoolYear}
                schoolName={school && school.name}
              />
            </div>
          </div>
        </ChartContainer>
      </Page>
    )
  }
}

const styles = {
  container: {
    width: '100%',
    position: 'relative'
  },
  faded: {
    opacity: 0.6
  }
}

const mapStateToProps = state => ({
  school: selectors.currentSchool(state),
  filters: state.studentFilters.filters
})

export default connect(mapStateToProps)(SatIndex)
