import { AbsoluteSpinner } from 'components/shared/spinner'
import { fetchEnrollmentMetrics } from 'api/district.js'
import { round } from 'lodash'
import Page from 'components/shared/page'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import EnrollmentChart from 'components/shared/enrollment/chart'
import Masonry from '@mui/lab/Masonry'

function DistrictEnrollmentTable() {
  const [loaded, setLoaded] = useState(false)
  const [students, setStudents] = useState(null)
  const calculatePercent = val => {
    return round((val / students.students_enrolled) * 100, 1)
  }
  const EnrolledPanel = () => {
    return (
      <Card sx={{ mb: 2 }}>
        <CardHeader title="Current Students Enrolled" align="center" />
        <CardContent>
          <Typography variant="h3" align="center">
            {students.students_enrolled}
          </Typography>
        </CardContent>
      </Card>
    )
  }
  const humanBoolean = val => {
    return val === 'true' ? 'Yes' : 'No'
  }
  const determineLabel = (key, booleanValue) => {
    if (booleanValue) {
      return humanBoolean(key)
    } else if (key === 'Former') {
      return 'Monitored/Exited'
    } else {
      return key
    }
  }
  const PanelContent = metric => {
    const rows = Object.entries(students[`${metric.objectKey}`])
      .filter(([key, _value]) => key !== '')
      .map(([key, value]) => (
        <TableRow key={key}>
          <TableCell>{determineLabel(key, metric.booleanValue)}</TableCell>
          <TableCell>{value}</TableCell>
          <TableCell>{calculatePercent(value)}%</TableCell>
        </TableRow>
      ))

    return (
      <Card>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>{metric.columnTitle}</TableCell>
                <TableCell>Count</TableCell>
                <TableCell>%</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </TableContainer>
      </Card>
    )
  }

  useEffect(() => {
    fetchEnrollmentMetrics().then(res => {
      setStudents(res)
      setLoaded(true)
    })
  }, [])

  if (loaded === false) {
    return <AbsoluteSpinner />
  } else {
    return (
      <Page
        name="District Enrollment Metrics"
        title="District Enrollment Metrics"
      >
        <Box>
          <Masonry columns={4} spacing={4}>
            <EnrolledPanel objectKey="enrollment_breakdown" />
            <PanelContent columnTitle="Grade" objectKey="grade_breakdown" />
            <PanelContent
              columnTitle="Ethnicity"
              objectKey="ethnicity_breakdown"
            />
            <PanelContent
              columnTitle="Socioeconomically Disadvantaged"
              objectKey="socioeconomically_disadvantaged"
              booleanValue="true"
            />
            <PanelContent columnTitle="Gender" objectKey="gender_breakdown" />
            <PanelContent columnTitle="ML Status" objectKey="ell_breakdown" />
            <PanelContent
              columnTitle="SPED"
              objectKey="sped_breakdown"
              booleanValue="true"
            />
            <PanelContent
              columnTitle="Gifted"
              objectKey="gifted_and_talented_breakdown"
              booleanValue="true"
            />
          </Masonry>

          <Paper p={4}>
            <Box sx={{ mb: 2 }}>
              <EnrollmentChart data={students.daily_enrollments} />
            </Box>
          </Paper>
        </Box>
      </Page>
    )
  }
}

DistrictEnrollmentTable.propTypes = {
  getStudents: PropTypes.func,
  students: PropTypes.object
}

export default DistrictEnrollmentTable
