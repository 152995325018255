import { destroy, get, patch, post } from 'utils/ajax'

export const createFilterSet = params => {
  return post('/api/web/filter_sets', { filter_set: params })
}

export const deleteFilterSet = id => {
  return destroy(`/api/web/filter_sets/${id}`, {})
}

export const fetchFilterSets = params => {
  return get('/api/web/filter_sets', { params })
}

export const updateFilterSet = (id, params) => {
  return patch(`/api/web/filter_sets/${id}`, { filter_set: params })
}
