import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import AdminActivities from './activities/index'
import AdminGoals from './goals/index'
import AdminJobs from './jobs/index'
import AdminJob from './jobs/show/index'
import AdminSchools from './schools/index'
import AdminSchool from './schools/show'
import AdminEditSchool from './schools/edit'
import AdminUsers from './users/index'
import DistrictDates from './district_dates/index'
import SystemStatuses from './system_statuses/index'
import { withRouterPropTypes } from 'utils/react-router-prop-types'
import { Box } from '@mui/material'

function Admins({ match }) {
  return (
    <Switch>
      <Route exact path={match.url}>
        <Redirect to={`${match.url}/users`} />
      </Route>
      <Route exact path={`${match.url}/users`} component={AdminUsers} />
      <Route path={`${match.url}/system_statuses`} component={SystemStatuses} />
      <Route path={`${match.url}/district_dates`} component={DistrictDates} />
      <Route exact path={`${match.url}/jobs`} component={AdminJobs} />
      <Route
        exact
        path={`${match.url}/jobs/(id|code)/:id`}
        component={AdminJob}
      />
      <Route path={`${match.url}/goals`} component={AdminGoals} />
      <Route
        exact
        path={`${match.url}/schools/:id/edit`}
        component={AdminEditSchool}
      />
      <Route path={`${match.url}/schools/:id`} component={AdminSchool} />
      <Route path={`${match.url}/schools`} component={AdminSchools} />
      <Route path={`${match.url}/activities`} component={AdminActivities} />
      <Route
        component={() => (
          <Box
            sx={{
              mt: 2
            }}
          >
            <Box>{"Uh oh, that's an ADMIN 404 🌝"}</Box>
          </Box>
        )}
      />
    </Switch>
  )
}

Admins.propTypes = {
  ...withRouterPropTypes
}

export default Admins
