import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'components/shared/table'

const RemoteTable = ({
  columns,
  data,
  pages,
  onFetchData,
  onRowClick,
  ...props
}) => {
  const fetchData = (state, _instance) => {
    let sortParams = {}

    if (state.sorted.length) {
      sortParams = {
        sort: state.sorted[0].id,
        sort_direction: state.sorted[0].desc ? 'desc' : 'asc'
      }
    }

    onFetchData({
      page: state.page + 1,
      per_page: state.pageSize,
      ...sortParams
    })
  }

  return (
    <Table
      manual
      columns={columns}
      data={data}
      pages={pages}
      onFetchData={fetchData}
      getTrProps={onRowClick}
      {...props}
    />
  )
}

RemoteTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  onFetchData: PropTypes.func.isRequired,
  pages: PropTypes.number,
  onRowClick: PropTypes.func
}

export default RemoteTable
