import { get, destroy } from 'utils/ajax'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'

const defaults = { params: {}, options: {} }

export function useObservations({ params, options } = defaults) {
  const queryKey = ['tulsa-way:observations', params]
  const queryFn = () => get('/api/web/tulsa_way/observations', { params })
  const query = useQuery(queryKey, queryFn, options)

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}

export function useDestroyObservationMutation(options = {}) {
  const mutation = useMutation(
    id => destroy(`/api/web/tulsa_way/observations/${id}`),
    options
  )

  return mutation
}
