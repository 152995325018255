import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Tooltip } from '@mui/material'
import PersonSearchIcon from '@mui/icons-material/PersonSearch'
export default function ImpersonateButton({ user }) {
  if (!user.active) {
    return null
  }

  return (
    <Tooltip title="Impersonate User" arrow placement="top">
      <IconButton
        variant="contained"
        color="success"
        id={`impersonate-${user.id}`}
        href={`/admins/users/${user.id}/impersonations`}
      >
        <PersonSearchIcon />
      </IconButton>
    </Tooltip>
  )
}

ImpersonateButton.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired
  }).isRequired
}
