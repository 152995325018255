import Comparison from 'components/shared/comparison'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Spinner from 'components/shared/spinner/spinner'
import { Link } from 'react-router-dom'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import PaperBox from 'components/shared/PaperBox'
import { sum } from 'lodash'

const metricsRowShape = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  inverse: PropTypes.bool.isRequired,
  display: PropTypes.string.isRequired,
  evaluator: PropTypes.func
})

function MetricsTableRow({
  row,
  metric,
  previousWeekMetric,
  previousMonthMetric,
  previousYearMetric,
  percentChangeComparison
}) {
  const evaluator = row.evaluator || (x => x)

  const value = evaluator(metric[row.key])

  const previousMetric = {
    year: previousYearMetric,
    month: previousMonthMetric,
    week: previousWeekMetric
  }[percentChangeComparison]

  const previousValue = previousMetric && evaluator(previousMetric[row.key])

  const inverse = row.inverse
  const display = row.display

  return (
    <TableRow key={row.key}>
      <TableCell component="th" scope="row">
        <Link to={row.url}>{row.label}</Link>
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        {value ? `${display === 'percent' ? `${value}%` : value}` : '--'}
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          {Boolean(value && previousValue) && (
            <Comparison
              current={value}
              previous={previousValue}
              inverse={inverse}
              percentage={display === 'percent'}
            />
          )}
        </Box>
      </TableCell>
    </TableRow>
  )
}

MetricsTableRow.propTypes = {
  row: metricsRowShape.isRequired,
  metric: PropTypes.object.isRequired,
  previousWeekMetric: PropTypes.object,
  previousMonthMetric: PropTypes.object,
  previousYearMetric: PropTypes.object,
  percentChangeComparison: PropTypes.string
}

function SchoolMetrics({
  loading,
  school,
  metric,
  previousWeekMetric,
  previousMonthMetric,
  previousYearMetric
}) {
  const [percentChangeComparison, setPercentChangeComparison] = useState('week')

  const MetricsTable = () => {
    return (
      <TableContainer>
        <Table size="small" aria-label="metrics-table">
          <TableHead>
            <TableRow sx={{ fontWeight: 'bold' }}>
              <TableCell sx={{ maxWidth: '33%' }}>
                Compare to:{' '}
                <ToggleButtonGroup
                  color="primary"
                  value={percentChangeComparison}
                  exclusive
                  onChange={val => setPercentChangeComparison(val.target.value)}
                  aria-label="Display as"
                >
                  <ToggleButton title="1 Week Ago" value="week">
                    W
                  </ToggleButton>
                  <ToggleButton title="1 Month Ago" value="month">
                    M
                  </ToggleButton>
                  <ToggleButton title="1 Year Ago" value="year">
                    Y
                  </ToggleButton>
                </ToggleButtonGroup>
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>Current</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>Change</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schoolMetrics.map(row => (
              <MetricsTableRow
                key={row.key}
                {...{
                  row,
                  metric,
                  previousWeekMetric,
                  previousMonthMetric,
                  previousYearMetric,
                  percentChangeComparison
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const baseUrl = `/schools/${school.id}`

  const metricsForAllSchools = [
    {
      key: 'attendance',
      label: 'Attendance',
      url: `${baseUrl}/attendance`,
      inverse: false,
      display: 'percent'
    },
    {
      key: 'absenteeism',
      label: 'Chronic Absenteeism',
      url: `${baseUrl}/attendance?chart_type=chronic`,
      inverse: true,
      display: 'percent'
    },
    {
      key: 'referrals',
      label: 'Referrals',
      url: `${baseUrl}/referrals`,
      inverse: true,
      display: 'percent'
    },
    {
      key: 'suspensions',
      label: 'Suspensions',
      url: `${baseUrl}/suspensions`,
      inverse: true,
      display: 'percent'
    },
    {
      key: 'blank_grade_counts',
      label: 'Blank Grades',
      url: `/students?school_id=${school.id}&view=Default&blank_grades_count=1&blank_grades_symbol=greater_than_or_equal_to`,
      inverse: true,
      display: 'number',
      evaluator: x => x && sum(Object.values(x))
    },
    {
      key: 'enrollment',
      label: 'Enrollment',
      url: `${baseUrl}/enrollment`,
      inverse: false,
      display: 'number'
    }
  ]

  const highSchoolMetrics = [
    {
      key: 'on_track',
      label: 'On Track (HS Only)',
      url: `${baseUrl}/grad-tracker?tab=graduation-status`,
      inverse: false,
      display: 'percent'
    }
  ]

  const schoolMetrics =
    school.category === 'high'
      ? metricsForAllSchools.concat(highSchoolMetrics)
      : metricsForAllSchools

  return loading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Spinner />
    </Box>
  ) : (
    <PaperBox title="Foundational Metrics" id="foundational_metrics">
      <MetricsTable />
    </PaperBox>
  )
}

SchoolMetrics.propTypes = {
  loading: PropTypes.bool,
  school: PropTypes.object.isRequired,
  metric: PropTypes.object.isRequired,
  previousWeekMetric: PropTypes.object,
  previousMonthMetric: PropTypes.object,
  previousYearMetric: PropTypes.object
}

export default SchoolMetrics
