export const types = {
  CREATE_REPORT: 'reports/create_report',
  DELETE_REPORT: 'reports/delete_report',
  FETCH_REPORTS: 'reports/fetch_reports',
  FETCH_STUDENTS: 'reports/fetch_students',
  SET_STUDENTS_LIST: 'reports/set_students_list'
}

const initialState = {
  reports: [],
  meta: {},
  selectedStudents: [],
  students: []
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_REPORT:
      return {
        ...state
      }
    case types.DELETE_REPORT:
      return {
        ...state
      }
    case types.FETCH_REPORTS:
      return {
        ...state,
        reports: action.payload.data,
        meta: action.payload.meta
      }
    case types.FETCH_STUDENTS:
      return {
        ...state,
        students: action.payload.data
      }
    case types.SET_STUDENTS_LIST:
      return {
        ...state,
        selectedStudents: action.payload
      }
    default:
      return state
  }
}
