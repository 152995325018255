import { destroy, patch, post } from 'utils/ajax'
import { alert } from 'utils/alert'

export const createObservationNote = params => {
  return post('/api/web/tulsa_way/observation_notes', {
    observation_note: params
  }).catch(() => {
    alert('There was an error creating the observation note.', 'danger')
  })
}

export const updateObservationNote = (id, params) => {
  return patch(`/api/web/tulsa_way/observation_notes/${id}`, {
    observation_note: params
  }).catch(() => {
    alert('There was an error updating the observation note.', 'danger')
  })
}

export const deleteObservationNote = id => {
  return destroy(`/api/web/tulsa_way/observation_notes/${id}`).catch(_err => {
    alert('There was an error deleting the note.', 'danger')
  })
}
