import { post, destroy } from 'utils/ajax'
import { alert } from 'utils/alert'

export const destroyChildStudyReferralConcern = id => {
  return destroy(`/api/web/child_study_referral_concerns/${id}`, {}).catch(
    _err => {
      alert(
        'There was an error deleting the link between the referral form and concern.',
        'danger'
      )
    }
  )
}

export const createChildStudyReferralConcern = params => {
  return post('/api/web/child_study_referral_concerns', params).catch(() => {
    alert(
      'There was an error creating the link between the referral form and concern.',
      'danger'
    )
  })
}
