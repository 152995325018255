import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { titleize } from 'utils'
import { Button, TableRow, TableCell } from '@mui/material'
import { updateDistrictGoal } from 'api/admin/district_goals'

function DistrictGoalSettingForm(props) {
  const {
    goal,
    category,
    year,
    fetchDistrictGoalSettings,
    alertMessage,
    showAlert
  } = props

  const [newGoal, setNewGoal] = useState(goal || 0)

  const onSubmit = e => {
    e.preventDefault()
    const params = {
      category: category,
      year: year,
      value: newGoal == '' ? 0 : newGoal
    }
    updateDistrictGoal(params)
      .then(success => {
        alertMessage(
          'District Goal for ' +
            titleize(success.category) +
            ' saved at ' +
            success.value +
            ' for the ' +
            success.year +
            ' school year.'
        )
        showAlert('')
        fetchDistrictGoalSettings()
      })
      .catch(error => {
        alertMessage(
          'There was an error changing the district goal.  ' +
            error.data.message
        )
        showAlert('alert alert-danger')
        fetchDistrictGoalSettings()
      })
  }

  return (
    <TableRow>
      <TableCell>
        <h5>{titleize(category)}</h5>
      </TableCell>
      <TableCell>
        <h5>{year}</h5>
      </TableCell>
      <TableCell>
        <form onSubmit={onSubmit}>
          <input
            className="form-control"
            id={category + '-district-goal'}
            min={0}
            max={100}
            step={0.1}
            value={newGoal}
            type="text"
            onChange={val => setNewGoal(val.target.value)}
          />{' '}
          <Button
            variant="contained"
            color="success"
            type="submit"
            id={`submit-${category}`}
          >
            Update
          </Button>
        </form>
      </TableCell>
    </TableRow>
  )
}

DistrictGoalSettingForm.propTypes = {
  goal: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  fetchDistrictGoalSettings: PropTypes.func.isRequired,
  alertMessage: PropTypes.func,
  showAlert: PropTypes.func
}

export default DistrictGoalSettingForm
