import { connect } from 'react-redux'
import { RequirementPropType } from 'modules/requirements'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { withRouter } from 'react-router'
import { Button, Typography, Stack, Box } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'

class InterventionRequirement extends React.Component {
  static propTypes = {
    fetchRequirements: PropTypes.func.isRequired,
    requirement: RequirementPropType.isRequired,
    schoolId: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired
  }

  state = {
    concernTypes: [],
    show: false
  }

  viewConcernButton() {
    const { requirement } = this.props

    return (
      <Button
        startIcon={<VisibilityIcon />}
        onClick={_ =>
          window.location.assign(`/concerns/${requirement.data_id}`)
        }
      >
        View
      </Button>
    )
  }

  render() {
    const { requirement } = this.props

    return (
      <Stack spacing={2}>
        <Box>
          <Typography variant="overline">Created</Typography>
          <Typography variant="body1">
            {moment(requirement.created_at).format('MM/DD/YYYY')} by{' '}
            {requirement.created_by}
          </Typography>
        </Box>

        <Box>
          <Typography variant="overline">Concern</Typography>
          <Typography variant="body1">{requirement.concern_name}</Typography>
        </Box>

        <Box>
          <Typography variant="overline">Concern details</Typography>
          <Typography variant="body1">{requirement.concern_notes}</Typography>
        </Box>

        <Box>
          <Typography variant="overline">Goal</Typography>
          <Typography variant="body1">{requirement.goal}</Typography>
        </Box>

        {requirement.comments && (
          <Box>
            <Typography variant="overline">Comments</Typography>
            <Typography variant="body1">{requirement.comments}</Typography>
          </Box>
        )}

        <Box>
          <Stack direction="row" justifyContent="center">
            {this.viewConcernButton()}
          </Stack>
        </Box>
      </Stack>
    )
  }
}

const mapStateToProps = state => ({
  user: state.currentUser.user
})

export default connect(mapStateToProps)(withRouter(InterventionRequirement))
