import React from 'react'
import PropTypes from 'prop-types'
import Chip from '@mui/material/Chip'

const STATUS_COLORS = {
  on_track: 'success',
  off_track: 'secondary',
  at_risk: 'warning',
  high_risk: 'error',
  default: 'inherit'
}

function GraduationStatusChip({ label }) {
  const status = label.toLowerCase().replace(/\s+/g, '_')
  const color = STATUS_COLORS[status] || STATUS_COLORS['inherit']

  return <Chip color={color} label={label} />
}

GraduationStatusChip.propTypes = {
  label: PropTypes.string.isRequired
}

export default GraduationStatusChip
