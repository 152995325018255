import React from 'react'
import PropTypes from 'prop-types'
import CircleIcon from '@mui/icons-material/Circle'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

function BulletedListItem({ children }) {
  return (
    <ListItem alignItems={'flex-start'}>
      <ListItemIcon>
        <CircleIcon fontSize={'small'} />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          variant: 'body1',
          sx: { lineHeight: 1.2 }
        }}
      >
        {children}
      </ListItemText>
    </ListItem>
  )
}

BulletedListItem.propTypes = {
  children: PropTypes.any
}

export default BulletedListItem
