import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { Timeline } from '@mui/lab'
import NoteItem from 'components/concerns/show/timeline/note/item'

function NoteTimeline({ parent, queryName }) {
  const [conciseView, setConciseView] = useState(true)

  return (
    <Timeline align="left" style={{ paddingLeft: 0, paddingRight: 0 }}>
      {parent.notes.map(
        (note, index) =>
          (!conciseView || (conciseView && index < 2)) && (
            <NoteItem
              key={note.id}
              parent={parent}
              queryName={queryName}
              note={note}
            />
          )
      )}
      {conciseView && parent.notes.length == 0 && (
        <Typography variant="subtitle1">No notes added</Typography>
      )}
      {conciseView && parent.notes.length > 2 && (
        <Typography
          variant="subtitle1"
          style={{ color: '#28abe2', cursor: 'pointer' }}
          onClick={() => setConciseView(false)}
        >
          See All ({parent.notes.length} Total)
        </Typography>
      )}
    </Timeline>
  )
}

NoteTimeline.propTypes = {
  parent: PropTypes.object.isRequired,
  queryName: PropTypes.string.isRequired
}

export default NoteTimeline
