import * as React from 'react'
import PropTypes from 'prop-types'
import { isNull } from 'lodash'
import ClearButton from './clear-button'
import {
  Box,
  Button,
  Popover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'

export default function MoreOptionsPopover({
  id,
  open,
  anchorEl,
  handleClose,
  radioChanged,
  checkboxChanged,
  gender,
  ells,
  gifted,
  sped,
  socioeconomicallyDisadvantaged
}) {
  const genderSet = !!gender
  const ellsSet = !!(ells && ells.length)
  const giftedSet = !isNull(gifted)
  const spedSet = !isNull(sped)
  const socioeconomicallyDisadvantagedSet = !isNull(
    socioeconomicallyDisadvantaged
  )
  const hasFilter =
    genderSet ||
    ellsSet ||
    giftedSet ||
    spedSet ||
    socioeconomicallyDisadvantagedSet

  const toggleElls = values => {
    let ells = values
    if (ells.length === 3) {
      ells = []
    }

    checkboxChanged('ells', ells)
  }

  const clearAll = e => {
    e.preventDefault()
    radioChanged('gender', null)
    checkboxChanged('ells', [])
    radioChanged('gifted', null)
    radioChanged('sped', null)
    radioChanged('socioeconomicallyDisadvantaged', null)
  }

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      sx={styles.popover}
    >
      <Box
        sx={{
          padding: '1em',
          ...styles.header
        }}
      >
        More Options
        {hasFilter && (
          <Button variant="text" color="primary" onClick={clearAll}>
            Clear all
          </Button>
        )}
      </Box>
      <Box
        sx={{
          padding: 2
        }}
      >
        <Stack spacing={2}>
          <Box sx={styles.optionGroup}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="subtitle2">Gender</Typography>
              <ClearButton
                clearable={genderSet}
                onClick={() => radioChanged('gender', null)}
              />
            </Stack>
            <ToggleButtonGroup
              exclusive
              type="radio"
              name="gender"
              value={gender}
              onChange={(_, val) => radioChanged('gender', val)}
              fullWidth
            >
              <ToggleButton value={'M'}>Male</ToggleButton>
              <ToggleButton value={'F'}>Female</ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box sx={styles.optionGroup}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="subtitle2">ML</Typography>
              <ClearButton
                clearable={ellsSet}
                onClick={() => checkboxChanged('ells', [])}
              />
            </Stack>
            <ToggleButtonGroup
              exclusive
              name="ell"
              value={ells.length ? ells[0] : null}
              onChange={(_, val) => toggleElls([val])}
              fullWidth
            >
              <ToggleButton value={'Yes'}>Yes</ToggleButton>
              <ToggleButton value={'No'}>No</ToggleButton>
              <ToggleButton value={'Former'}>Monitored/Exited</ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box sx={styles.optionGroup}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="subtitle2">Gifted</Typography>
              <ClearButton
                clearable={giftedSet}
                onClick={() => radioChanged('gifted', null)}
              />
            </Stack>
            <ToggleButtonGroup
              exclusive
              type="radio"
              name="gifted"
              value={gifted}
              onChange={(_, val) => radioChanged('gifted', val)}
              fullWidth
            >
              <ToggleButton value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box sx={styles.optionGroup}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="subtitle2">Sped</Typography>
              <ClearButton
                clearable={spedSet}
                onClick={() => radioChanged('sped', null)}
              />
            </Stack>
            <ToggleButtonGroup
              exclusive
              type="radio"
              name="sped"
              value={sped}
              onChange={(_, val) => radioChanged('sped', val)}
              fullWidth
            >
              <ToggleButton value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          </Box>

          <Box sx={styles.optionGroup}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
            >
              <Typography variant="subtitle2">
                Socioeconomically Disadvantaged
              </Typography>
              <ClearButton
                clearable={socioeconomicallyDisadvantagedSet}
                onClick={() =>
                  radioChanged('socioeconomicallyDisadvantaged', null)
                }
              />
            </Stack>
            <ToggleButtonGroup
              exclusive
              type="radio"
              name="socioeconomicallyDisadvantaged"
              value={socioeconomicallyDisadvantaged}
              onChange={(_, val) =>
                radioChanged('socioeconomicallyDisadvantaged', val)
              }
              fullWidth
            >
              <ToggleButton value={true}>Yes</ToggleButton>
              <ToggleButton value={false}>No</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Stack>
      </Box>
    </Popover>
  )
}

MoreOptionsPopover.propTypes = {
  id: PropTypes.any,
  open: PropTypes.bool,
  anchorEl: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  radioChanged: PropTypes.func,
  checkboxChanged: PropTypes.func,
  gender: PropTypes.string,
  ells: PropTypes.array,
  gifted: PropTypes.bool,
  sped: PropTypes.bool,
  socioeconomicallyDisadvantaged: PropTypes.bool,
  clearable: PropTypes.bool,
  onClick: PropTypes.func
}

const styles = {
  popover: {
    maxWidth: '400px',
    width: '100%'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5em 1em',
    borderBottom: '1px solid #ddd'
  },
  optionGroup: {
    marginBottom: '1em'
  }
}
