import createLineGraph from './charts/line-graph'

export function fetchDistrictGraphs() {
  createLineGraph(
    'district-attendance-graph',
    'district-attendance',
    'Percent Attendance',
    100
  )
  createLineGraph(
    'district-absenteeism-graph',
    'district-absenteeism',
    'Percent Chronically Absent'
  )
  createLineGraph(
    'district-suspensions-graph',
    'district-suspensions',
    'Percent Suspensions'
  )
}
