import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'

export default function EditButton({ user, onClick }) {
  return (
    <Tooltip placement="top" arrow title="Edit User">
      <IconButton
        id={`js-admin-view-user-${user.id}`}
        onClick={onClick}
        variant="contained"
        color="secondary"
      >
        <CreateIcon />
      </IconButton>
    </Tooltip>
  )
}

EditButton.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired
}
