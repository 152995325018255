import React from 'react'
import PropTypes from 'prop-types'
import { alert } from 'utils/alert'
import LetterGeneratorModal from './letter_generator_modal'
import Button from '@mui/material/Button'

export default class StudentsLetterGeneratorButton extends React.Component {
  static propTypes = {
    studentIds: PropTypes.array,
    filters: PropTypes.array,
    columnApiIncludes: PropTypes.array,
    totalResults: PropTypes.number
  }

  state = {
    modalOpen: false
  }

  handleModalHide = () => {
    this.setState({ modalOpen: false })
  }

  openModal = () => {
    if (!this.props.studentIds.length) {
      alert('Please select students to generate a PDF', 'warning')
    } else {
      this.setState({
        modalOpen: true
      })
    }
  }

  render() {
    return (
      <div>
        <LetterGeneratorModal
          show={this.state.modalOpen}
          onHide={this.handleModalHide}
          studentIds={this.props.studentIds}
          filters={this.props.filters}
          columnApiIncludes={this.props.columnApiIncludes}
          totalResults={this.props.totalResults}
        />

        <Button variant="contained" onClick={this.openModal}>
          Generate Letter
        </Button>
      </div>
    )
  }
}
