export const mapObjectsToOptions = (
  arrayOfObj,
  labelKey = 'name',
  valKey = 'id'
) => arrayOfObj.map(obj => ({ label: obj[labelKey], value: obj[valKey] }))

export const mapLocalizableObjectsToOptions = (
  arrayOfObj,
  labelKey = 'name',
  valKey = 'id',
  sort = true
) => {
  let localizedList = arrayOfObj.map(obj => {
    let label = obj[labelKey].en || '…'
    let value = obj[valKey]

    return { label, value }
  })

  if (sort) {
    localizedList = localizedList.sort((a, b) => {
      if (a.label < b.label) return -1
      if (a.label > b.label) return +1
      return 0
    })
  }

  return localizedList
}

export const mapToOptions = array =>
  array ? array.map(o => ({ label: o, value: o })) : []

export const findObjectLabelValue = (array, value) =>
  value && {
    label: array.find(obj => obj.id === value).name,
    value: value
  }
