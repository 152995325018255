import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import PreviewHeader from 'components/shared/students/show/graduation_check/emails/header.jsx'
import { studentShape } from 'utils/grad-plan-progress-prop-types'

export function EmailView({ sentEmail, ...props }) {
  const url = `/api/web/students/${sentEmail.source_id}/grad_planner_email_view?sent_email_id=${sentEmail.id}`

  return (
    <Grid container direction={'column'} rowGap={2} {...props}>
      <Grid item component={Paper} sx={{ padding: 2 }}>
        <PreviewHeader
          to={sentEmail.headers['To']}
          from={sentEmail.headers['From']}
          cc={sentEmail.headers['Cc']}
          subject={sentEmail.headers['Subject']}
        />
      </Grid>
      <Grid item flexGrow={1} component={Paper}>
        <Box
          component={'iframe'}
          src={url}
          sx={{
            width: '100%',
            height: '100%',
            border: 0
          }}
        />
      </Grid>
    </Grid>
  )
}
EmailView.propTypes = {
  sentEmail: PropTypes.object.isRequired
}

export function EmailPreview({ student, ...props }) {
  const user = useSelector(state => state.currentUser.user)

  const url = `/api/web/students/${student.id}/grad_planner_email_preview`

  return (
    <Grid container direction={'column'} rowGap={2} {...props}>
      <Grid item component={Paper} sx={{ padding: 2 }}>
        <PreviewHeader
          to={`${student.guardian_first_name} ${student.guardian_last_name} <${student.guardian_email}>`}
          cc={`${student.name} <${student.email}>`}
          bcc={`${user.name} <${user.email}>`}
          from={`${user.name} <${user.email}>`}
          subject={`Your Student’s Grad Progress - ${student.name}`}
        />
      </Grid>
      <Grid item flexGrow={1} component={Paper}>
        <Box
          component={'iframe'}
          src={url}
          sx={{
            width: '100%',
            height: '100%',
            border: 0
          }}
        />
      </Grid>
    </Grid>
  )
}

EmailPreview.propTypes = {
  student: studentShape.isRequired
}

export default EmailPreview
