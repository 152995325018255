import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Page from 'components/shared/page'
import PageContainer from 'shared/page-container'
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Typography,
  TextField,
  Link as MuiLink
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Link, useHistory } from 'react-router-dom'
import { CrisisForm } from './form'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import PrintIcon from '@mui/icons-material/Print'
import EditIcon from '@mui/icons-material/Edit'
import TranslateIcon from '@mui/icons-material/Translate'
import {
  patchConfidentialCrisisForm,
  postConfidentialCrisisForm,
  destroyConfidentialCrisisForm,
  getConfidentialCrisisForm,
  getConfidentialCrisisForms,
  getPrefilledConfidentialCrisisForm
} from 'api/confidential-crisis-forms'
import moment from 'moment'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import StudentSelector from '../shared/search/studentSelector'
import CrisisFormsInstructions from './instructions'
import ManageTeam from './manage-team'
import DocumentsManager from 'components/shared/documents-manager'
import SimpleAutocomplete from 'shared/simple-autocomplete'
import { handleRailsRequestJsResponse } from 'utils/handleRailsRequestJsResponse'
import { isArray } from 'lodash'
import { useDebounce } from 'hooks/useDebounce'

function CrisisFormsIndex({ isAdmin }) {
  const queryString = new URLSearchParams(window.location.search)
  const searchTerm = queryString.has('search') ? queryString.get('search') : ''

  const [tableMutateEvents, setTableMutateEvents] = useState(0)
  const [search, setSearch] = useState(searchTerm)
  const debouncedSearch = useDebounce(search, 300)

  // Filtering for search on backend ensures it will still work once we introduce pagination
  const { data: crisis_forms } = useQuery(
    ['confidential-crisis-forms', tableMutateEvents, debouncedSearch],
    async () => {
      const params = {
        search: search
      }
      const response = await getConfidentialCrisisForms(params)
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  const [schoolName, setSchool] = useState('All')

  const filterCrisisForms = () => {
    if (schoolName !== 'All') {
      return crisis_forms.filter(form => form.school_name == schoolName)
    } else {
      return crisis_forms
    }
  }

  const handleFormDestroy = async id => {
    const response = await destroyConfidentialCrisisForm(id)
    await handleRailsRequestJsResponse(response)
    setTableMutateEvents(tableMutateEvents + 1)
  }

  const schoolOptions = [
    'All',
    ...new Set(
      isArray(crisis_forms) ? crisis_forms.map(form => form.school_name) : []
    )
  ].sort()

  return (
    <Page name="Confidential Crisis Forms" title="Confidential Crisis Forms">
      <PageContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink
            underline="hover"
            color="inherit"
            to="/confidential_crisis_forms"
            component={Link}
          >
            Crisis Forms
          </MuiLink>
          <Typography color="text.primary">All</Typography>
        </Breadcrumbs>
        <Typography variant="h1">Confidential Crisis Forms</Typography>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>{isAdmin && <ManageTeam />}</Grid>
          <Grid item>
            <Button
              startIcon={<EditNoteIcon />}
              component={Link}
              variant="contained"
              color="primary"
              to="/confidential_crisis_forms/new"
            >
              New Confidential Crisis Form
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid item xs={12} sm={6}>
            <TextField
              size={'medium'}
              label={'Search by Name or Student Number'}
              value={search}
              onChange={val => setSearch(val.target.value)}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SimpleAutocomplete
              key={`school-${schoolName}`}
              label={'Filter by School'}
              options={schoolOptions}
              value={schoolName}
              onChange={option => setSchool(option || 'All')}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Actions</TableCell>
                <TableCell>Student Number</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>School Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isArray(crisis_forms) &&
                filterCrisisForms().map(crisis_form => (
                  <TableRow key={crisis_form.id}>
                    <TableCell>
                      <Stack direction="row" spacing={2}>
                        <Button
                          component={Link}
                          variant="contained"
                          color="primary"
                          size="small"
                          to={`/confidential_crisis_forms/${crisis_form.id}`}
                        >
                          Show
                        </Button>
                        <Button
                          component={Link}
                          variant="contained"
                          color="secondary"
                          size="small"
                          to={`/confidential_crisis_forms/${crisis_form.id}/edit`}
                        >
                          Edit
                        </Button>
                        <Button
                          startIcon={<DeleteIcon />}
                          variant="outlined"
                          color="error"
                          size="small"
                          onClick={() => handleFormDestroy(crisis_form.id)}
                        >
                          Destroy
                        </Button>
                      </Stack>
                    </TableCell>
                    <TableCell>{crisis_form.student_number}</TableCell>
                    <TableCell>
                      <Link to={`/student/${crisis_form.student_id}/overview`}>
                        {crisis_form.student_name}
                      </Link>
                    </TableCell>
                    <TableCell>{crisis_form.school_name}</TableCell>
                    <TableCell>{crisis_form.is_draft ? 'Draft' : ''}</TableCell>
                    <TableCell>
                      {moment(crisis_form.created_at).fromNow()}
                    </TableCell>
                    <TableCell>
                      {moment(crisis_form.updated_at).fromNow()}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PageContainer>
    </Page>
  )
}

CrisisFormsIndex.propTypes = {
  isAdmin: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isAdmin: state.currentUser.user.rights.is_crisis_team_admin
})

const CrisisFormsNew = () => {
  const queryString = new URLSearchParams(window.location.search)
  const student_id = queryString.has('student_id')
    ? queryString.get('student_id')
    : null
  const history = useHistory()

  const onSubmitValidated = async crisis_form => {
    // How should we handle errors from the server?
    const response = await postConfidentialCrisisForm(crisis_form)
    const newCrisisForm = await handleRailsRequestJsResponse(response)

    if (newCrisisForm) {
      history.push(`/confidential_crisis_forms/${newCrisisForm.id}`)
    }
  }

  const { data: crisis_form } = useQuery(
    ['new-confidential-crisis-form', student_id],
    async () => {
      const response = await getPrefilledConfidentialCrisisForm({
        student_id
      })
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      enabled: Boolean(student_id),
      initialData: null
    }
  )
  return (
    <Page name="Confidential Crisis Form" title="New Confidential Crisis Form">
      <PageContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink
            underline="hover"
            color="inherit"
            to="/confidential_crisis_forms"
            component={Link}
          >
            Crisis Forms
          </MuiLink>
          <Typography color="text.primary">New</Typography>
        </Breadcrumbs>

        <header>
          <Typography variant="h1" sx={{ pt: 4 }}>
            Confidential Crisis Form
          </Typography>
        </header>
        <Stack spacing={2}>
          <Typography variant="body1">
            A confidential crisis intervention report/referral is required when
            an employee of the district has reason to believe a{' '}
            <strong>student</strong> or <strong>staff member*</strong> is any of
            the following:
          </Typography>

          <Typography component="ul" variant="body1">
            <Box component="li">
              In danger of harming herself/himself (suicidal, self-injurious,
              wish/thought/plan/threat/intent or actions that could harm self,
              etc.){' '}
              <a
                href="http://docs.tulsaschools.org/1_Administration/_board_policies/2111-R.pdf#search=%22COPES%22"
                target="_blank"
                rel="noreferrer"
              >
                Suicide Intervention - TPS Board Policy 2111-R
              </a>
            </Box>
            <Box component="li">
              In danger of harming others (homicidal or
              wish/thought/plan/threat/intent/or actions that could hurt others,
              etc.)
            </Box>
            <Box component="li">
              Experiencing active psychotic features (seeing and/or hearing
              things others do not, etc.).
            </Box>
            <Box component="li">
              *NOTE: Staff related concerns should be reported to the school
              leader immediately. The school leader will consult with COPES and
              provide corresponding documentation to the appropriate Talent
              Management representative.
            </Box>
          </Typography>

          <Box>
            <CrisisFormsInstructions />
          </Box>

          <Box>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <StudentSelector redirectTo="/confidential_crisis_forms/new?student_id=" />
              </Grid>
            </Grid>
          </Box>

          <Paper sx={{ p: 4 }}>
            {crisis_form && (
              <CrisisForm
                prefill={crisis_form}
                onSubmitValidated={onSubmitValidated}
              />
            )}
          </Paper>
        </Stack>
      </PageContainer>
    </Page>
  )
}

const CrisisFormsShow = ({ match }) => {
  const crisis_form_id = match.params.id
  const queryString = new URLSearchParams(window.location.search)
  const locale = queryString.has('locale') ? queryString.get('locale') : 'en'

  const { data: crisis_form } = useQuery(
    ['confidential-crisis-form', crisis_form_id],
    async () => {
      const response = await getConfidentialCrisisForm({
        id: crisis_form_id
      })
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: null
    }
  )

  return (
    <Page name="Confidential Crisis Forms" title="Confidential Crisis Form">
      <PageContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink
            underline="hover"
            color="inherit"
            to="/confidential_crisis_forms"
            component={Link}
          >
            Crisis Forms
          </MuiLink>
          <Typography color="text.primary">{crisis_form_id}</Typography>
        </Breadcrumbs>
        <Typography variant="h1">Confidential Crisis Form</Typography>
        {crisis_form && (
          <>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  startIcon={<EditIcon />}
                  component={Link}
                  variant="contained"
                  color="secondary"
                  to={`/confidential_crisis_forms/${crisis_form.id}/edit`}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  startIcon={<PrintIcon />}
                  variant="contained"
                  color="primary"
                  target="_blank"
                  to={`/confidential_crisis_forms/${crisis_form.id}/print/?locale=${locale}`}
                >
                  Print
                </Button>
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  startIcon={<AttachFileIcon />}
                  variant="contained"
                  color="primary"
                  to={`/confidential_crisis_forms/${crisis_form.id}/documents`}
                >
                  Documents
                </Button>
              </Grid>
              <Grid item>
                <Button
                  component={Link}
                  startIcon={<TranslateIcon />}
                  variant="contained"
                  color="primary"
                  to={locale == 'en' ? '?locale=es' : '?locale=en'}
                >
                  Translate to {locale == 'en' ? 'Spanish' : 'English'}
                </Button>
              </Grid>
            </Grid>
            <CrisisForm disabled={true} prefill={crisis_form} />
          </>
        )}
      </PageContainer>
    </Page>
  )
}

CrisisFormsShow.propTypes = {
  match: PropTypes.object.isRequired
}

const CrisisFormsEdit = ({ match }) => {
  const crisis_form_id = match.params.id
  const history = useHistory()
  const queryClient = useQueryClient()

  const onSubmitValidated = async crisis_form => {
    const response = await patchConfidentialCrisisForm(
      crisis_form_id,
      crisis_form
    )
    const json = await handleRailsRequestJsResponse(response)

    if (json) {
      await queryClient.invalidateQueries({
        queryKey: ['confidential-crisis-form']
      })
      history.push(`/confidential_crisis_forms/${json.id}`)
    }
  }

  const { data: crisis_form } = useQuery(
    ['confidential-crisis-form', crisis_form_id],
    async () => {
      const response = await getConfidentialCrisisForm({
        id: crisis_form_id
      })
      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: null
    }
  )
  return (
    <Page
      name="Confidential Crisis Forms"
      title="Edit Confidential Crisis Form"
    >
      <PageContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink
            underline="hover"
            color="inherit"
            to="/confidential_crisis_forms"
            component={Link}
          >
            Crisis Forms
          </MuiLink>
          <MuiLink
            component={Link}
            underline="hover"
            color="inherit"
            to={`/confidential_crisis_forms/${crisis_form_id}`}
          >
            {crisis_form_id}
          </MuiLink>
          <Typography color="text.primary">Edit</Typography>
        </Breadcrumbs>
        <Typography variant="h1">Edit Confidential Crisis Form</Typography>
        {crisis_form && (
          <CrisisForm
            prefill={crisis_form}
            onSubmitValidated={onSubmitValidated}
          />
        )}
      </PageContainer>
    </Page>
  )
}

CrisisFormsEdit.propTypes = {
  match: PropTypes.object.isRequired
}

const CrisisFormsPrint = ({ match }) => {
  const crisis_form_id = match.params.id

  const { data: crisis_form } = useQuery(
    ['confidential-crisis-form', crisis_form_id],
    async () => {
      const response = await getConfidentialCrisisForm({
        id: crisis_form_id
      })

      const json = await handleRailsRequestJsResponse(response)
      return json.data
    },
    {
      initialData: null
    }
  )

  useLayoutEffect(() => {
    crisis_form && window.print()
  }, [crisis_form])

  return (
    crisis_form && (
      <Stack>
        <Box sx={{ py: '4rem', px: '10rem' }}>
          <Typography variant="h3">
            Tulsa Public Schools Confidential Crisis
          </Typography>
        </Box>
        <Box sx={{ py: '4rem', px: '10rem' }}>
          <CrisisForm disabled={true} prefill={crisis_form} />
        </Box>
      </Stack>
    )
  )
}

CrisisFormsPrint.propTypes = {
  match: PropTypes.object.isRequired
}

const CrisisFormsDocuments = ({ match }) => {
  const crisis_form_id = match.params.id

  return (
    <Page name="Confidential Crisis Forms" title="Confidential Crisis Form">
      <PageContainer>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink
            underline="hover"
            color="inherit"
            to="/confidential_crisis_forms"
            component={Link}
          >
            Crisis Forms
          </MuiLink>
          <MuiLink
            component={Link}
            underline="hover"
            color="inherit"
            to={`/confidential_crisis_forms/${crisis_form_id}`}
          >
            {crisis_form_id}
          </MuiLink>
          <Typography color="text.primary">Documents</Typography>
        </Breadcrumbs>
        {crisis_form_id && (
          <DocumentsManager
            associations={{
              documentable_id: crisis_form_id,
              documentable_type: 'ConfidentialCrisisForm'
            }}
          />
        )}
      </PageContainer>
    </Page>
  )
}

CrisisFormsDocuments.propTypes = {
  match: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(CrisisFormsIndex)

export {
  CrisisFormsNew,
  CrisisFormsEdit,
  CrisisFormsShow,
  CrisisFormsPrint,
  CrisisFormsDocuments
}
