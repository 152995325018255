import { types } from './reducer'
import { omit } from 'lodash'

export const quickFilterSelected = quickFilter => {
  return dispatch => {
    dispatch({
      type: types.QUICK_FILTER_SELECTED,
      payload: quickFilter
    })

    if (quickFilter === 'custom') {
      dispatch({
        type: types.SET_FILTERS_EXPANDED,
        payload: true
      })
    }
  }
}

export const toggleFiltersExpanded = () => {
  return (dispatch, getState) => {
    dispatch({
      type: types.SET_FILTERS_EXPANDED,
      payload: !getState().studentFilters.filtersExpanded
    })
  }
}

export const filtersChanged = (filters, isAdvanced = false) => {
  return dispatch => {
    if (isAdvanced) {
      dispatch({ type: types.QUICK_FILTER_SELECTED, payload: 'custom' })
    }

    dispatch({ type: types.FILTERS_CHANGED, payload: filters })
    return Promise.resolve()
  }
}

export const schoolChanged = schoolId => {
  return (dispatch, getState) => {
    const filters = getState().studentFilters.filters.map(filter => ({
      ...filter,
      schoolId
    }))

    dispatch({ type: types.FILTERS_CHANGED, payload: filters })
  }
}

export const schoolYearChanged = year => {
  if (year) {
    return {
      type: types.SCHOOL_YEAR_CHANGED,
      payload: year
    }
  }
}

export const cohortChanged = cohort => {
  if (cohort) {
    return {
      type: types.COHORT_CHANGED,
      payload: cohort
    }
  }
}

export const studentFiltersChanged = (
  existingFilters,
  newFilters,
  defaultUrlParams
) => {
  return dispatch => {
    if (existingFilters && newFilters) {
      const persistedFilters = omit(existingFilters, Object.keys(newFilters))
      const updatedFilters = { ...persistedFilters, ...newFilters }
      const sanitizedFilters = Object.fromEntries(
        Object.entries(updatedFilters).filter(
          ([_, v]) =>
            typeof v === 'object'
              ? Object.values(v).length // filter out empty objects and arrays: {}, []
              : v !== '' && v !== null // filter out empty string and null
        )
      )

      dispatch({
        type: types.STUDENT_FILTERS_CHANGED,
        payload: sanitizedFilters
      })
      return Promise.resolve()
    }

    if (defaultUrlParams) {
      dispatch({
        type: types.STUDENT_FILTERS_CHANGED,
        payload: defaultUrlParams
      })
      return Promise.resolve()
    }
  }
}

export const clearStudentFilters = () => {
  return dispatch => {
    dispatch({
      type: types.CLEAR_STUDENT_FILTERS,
      payload: {}
    })
    return Promise.resolve()
  }
}

export default {
  quickFilterSelected,
  toggleFiltersExpanded,
  filtersChanged,
  schoolChanged,
  schoolYearChanged,
  cohortChanged,
  studentFiltersChanged,
  clearStudentFilters
}
