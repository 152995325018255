import React from 'react'
import PropTypes from 'prop-types'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Title,
  Subtitle,
  Legend,
  XAxis,
  YAxis,
  LineSeries,
  Tooltip
} from 'react-jsx-highcharts'
import { titleize } from 'utils'
import { connect } from 'react-redux'
import { selectors } from 'modules/shared'
import { chartColors } from 'utils/chart-colors'

const AccessChart = ({ accesses, student, categories, isParentPortal }) => {
  return (
    <div id="access-chart" style={{ minHeight: '300px' }}>
      <HighchartsChart exporting={{ enabled: !isParentPortal }}>
        <Chart />
        <Title>Access Scores By Category</Title>
        <Subtitle>{student.name}</Subtitle>
        <Legend layout="horizontal" align="center" verticalAlign="bottom" />
        <Tooltip shared />
        <XAxis categories={categories.map(c => titleize(c))}>
          <XAxis.Title>Category</XAxis.Title>
        </XAxis>
        <YAxis id="number" tickAmount={8} min={0} max={7}>
          <YAxis.Title>Score</YAxis.Title>
        </YAxis>
        {accesses.map((access, index) => (
          <LineSeries
            id={`access-${index}`}
            name={access.year}
            data={[
              access.composite_proficiency,
              access.listening_proficiency,
              access.writing_proficiency,
              access.comprehension_proficiency,
              access.speaking_proficiency,
              access.reading_proficiency,
              access.literacy_proficiency,
              access.oral_proficiency
            ]}
            key={index}
            marker={{ enabled: true, radius: 8 }}
            lineWidth={0}
            states={{ hover: { lineWidthPlus: 0 } }}
            color={index === 0 ? chartColors.blue : chartColors.grey}
          />
        ))}
      </HighchartsChart>
    </div>
  )
}

AccessChart.propTypes = {
  accesses: PropTypes.array,
  student: PropTypes.object,
  categories: PropTypes.array,
  isParentPortal: PropTypes.bool
}

const mapStateToProps = state => ({
  isParentPortal: selectors.isParentPortal(state)
})

export default connect(mapStateToProps)(
  withHighcharts(AccessChart, window.Highcharts)
)
