import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, uniqWith, intersection } from 'lodash'
import { currentQuarter, currentSemester } from 'utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Spinner from 'shared/spinner/spinner'

function GradesTable({ isLoading, isSuccess, grades, openModal }) {
  const sections = uniqWith(
    grades,
    (a, b) => a.hour === b.hour && a.id === b.id
  )

  const gradeTerms = [
    ...new Set(grades.map(obj => obj.current_grade_term))
  ].sort()
  const elementaryTerms = ['Q1', 'Q2', 'Q3', 'Q4', 'Y1']
  const secondaryTerms = ['S1', 'S2']
  const isQuarters = intersection(gradeTerms, elementaryTerms).length > 0
  const terms = isQuarters ? elementaryTerms : secondaryTerms
  const currentTerm = isQuarters ? currentQuarter : currentSemester

  const findTerm = (sectionId, term) => {
    return grades.find(g => g.id === sectionId && term === g.current_grade_term)
  }

  const determineLabelForCurrentGrade = currentGrade => {
    if (!currentGrade) {
      return ''
    }

    if (currentGrade.letter_grade == '--') {
      return currentGrade.letter_grade
    } else {
      return currentGrade.percent
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell component="th">Hour</TableCell>
            <TableCell component="th">Section</TableCell>
            <TableCell component="th">Teacher</TableCell>
            {terms.map(t => (
              <TableCell key={`term-header-${t}`} component="th">
                {t}
              </TableCell>
            ))}
            <TableCell component="th" align="center">
              Assignments - {currentTerm}
            </TableCell>
            <TableCell component="th" align="center">
              Zeros - {currentTerm}
            </TableCell>
            <TableCell component="th" align="center">
              Ungraded - {currentTerm}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={terms.length + 6}>
                <Spinner centered={true} />
              </TableCell>
            </TableRow>
          )}
          {isSuccess && isEmpty(sections) && (
            <TableRow>
              <TableCell colSpan={terms.length + 6}>
                <Box>No grades found for this student</Box>
              </TableCell>
            </TableRow>
          )}
          {isSuccess &&
            sections.map(s => (
              <TableRow
                key={s.id}
                hover
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  openModal({ id: s.id, name: s.name, hour: s.hour })
                }
              >
                <TableCell>{s.hour}</TableCell>
                <TableCell>{s.name}</TableCell>
                <TableCell>
                  {s?.lead_teacher?.last_name}{' '}
                  <a href={`mailto:${s?.lead_teacher?.email}`}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </a>
                </TableCell>
                {terms.map(t => (
                  <TableCell key={`section-${s.id}-term-${t}`}>
                    {determineLabelForCurrentGrade(findTerm(s.id, t))}
                  </TableCell>
                ))}
                <TableCell align="center">{s?.assignments_count}</TableCell>
                <TableCell align="center">
                  {s?.ungraded_assignments_count}
                </TableCell>
                <TableCell align="center">
                  {s?.zero_graded_assignments_count}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

GradesTable.propTypes = {
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  grades: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      hour: PropTypes.string,
      lead_teacher: PropTypes.shape({
        last_name: PropTypes.string,
        email: PropTypes.string
      }),
      assignments_count: PropTypes.number,
      ungraded_assignments_count: PropTypes.number,
      zero_graded_assignments_count: PropTypes.number
    })
  ).isRequired,
  openModal: PropTypes.func
}

export default GradesTable
