import React from 'react'
import PropTypes from 'prop-types'
import logo from 'images/TPS_WHITE.png'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import AppDropdown from './other-applications-dropdown'
import ProfileDropdown from './profile-dropdown'
import MenuIcon from '@mui/icons-material/Menu'
import { withOptions } from 'utils/contexts/options'
import AppInstanceAlert from './non-production-alert'
import ImpersonationStatus from './impersonation-status'
import UniversalSearch from './search/index'

function TopNavbar({ anonymousMode, mobileOpen, setMobileOpen, appBarRef }) {
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  return (
    <AppBar
      ref={appBarRef}
      position="fixed"
      sx={{
        'width': '100%',
        'zIndex': 1000,
        '& *[role=alert]': {
          borderRadius: 0
        }
      }}
    >
      <AppInstanceAlert />

      <ImpersonationStatus />

      <Toolbar>
        <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
          {/* hamburger */}
          <IconButton
            color="inherit"
            aria-label="open drawer"
            size="large"
            onClick={handleDrawerToggle}
            sx={{ ml: 1, mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Stack direction={'row'} sx={{ alignItems: 'center', flexGrow: 1 }}>
          <Box>
            <a href="/" style={{ padding: '0px' }}>
              <img src={logo} className="logo" style={{ height: '50px' }} />
            </a>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <UniversalSearch />
          </Box>
        </Stack>

        <Box sx={{ display: 'flex' }}>
          <AppDropdown />
          <ProfileDropdown isAnonymousMode={anonymousMode} />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

TopNavbar.propTypes = {
  anonymousMode: PropTypes.bool,
  mobileOpen: PropTypes.bool,
  setMobileOpen: PropTypes.func,
  appBarRef: PropTypes.any
}

export default withOptions(TopNavbar)
