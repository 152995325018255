export function colorDiscipline(score) {
  const scoreValue = parseFloat(score)
  if (scoreValue >= 5) {
    return 'error'
  } else if (scoreValue > 1) {
    return 'warning'
  } else if (scoreValue > 0.01) {
    return 'secondary'
  } else {
    return 'success'
  }
}

export function colorAttendance(score) {
  const scoreValue = parseFloat(score)
  if (scoreValue <= 80) {
    return 'error'
  } else if (scoreValue <= 90) {
    return 'warning'
  } else if (scoreValue <= 95) {
    return 'secondary'
  } else {
    return 'success'
  }
}

export function colorLetterGrade(letter) {
  if (letter == 'A') {
    return 'success'
  } else if (letter == 'B') {
    return 'success'
  } else if (letter == 'C') {
    return 'inherit'
  } else if (letter == 'D') {
    return 'warning'
  } else if (letter == 'F') {
    return 'error'
  }
}
