import { get, destroy, post } from 'utils/ajax'
import { alert } from 'utils/alert'

const createChildStudy = (studentId, userId, concernIds, note) => {
  return post('/api/web/child_studies', {
    student_id: studentId,
    user_id: userId,
    concern_ids: concernIds,
    notes: note
  }).catch(_err => {
    alert('An error occurred creating child study.', 'danger')
  })
}

const destroyChildStudy = childStudyId => {
  return destroy(`/api/web/child_studies/${childStudyId}`, {})
    .then(data => {
      alert('Deleting Child Study')
      return data
    })
    .catch(_err =>
      alert('An error occurred while deleting child studies', 'danger')
    )
}

const getChildStudy = childStudyId => {
  return get(`/api/web/child_studies/${childStudyId}`, {}).catch(_err =>
    alert('An error occurred while fetching the child study', 'danger')
  )
}

const getEligibleChildStudyStudents = schoolId => {
  const params = { school_id: schoolId }
  return get('/api/web/eligible_child_study_students', { params }).catch(
    _err => {
      alert(
        'An error occurred fetching eligible child study students.',
        'danger'
      )
    }
  )
}

export default {
  createChildStudy,
  getChildStudy,
  getEligibleChildStudyStudents,
  destroyChildStudy
}
