import React from 'react'
import ConcernsTable from './concerns-table'
import PropTypes from 'prop-types'
import { Typography, Stack } from '@mui/material'
import { Link } from 'react-router-dom'

function Concerns({ concerns, invalidateQuery, isLoading, student }) {
  return (
    <Stack spacing={2}>
      <Typography variant="h3">Academic & Behavioral Concerns</Typography>
      <ConcernsTable
        concerns={concerns}
        invalidateQuery={invalidateQuery}
        isLoading={isLoading}
      />
      <Typography variant="body1">
        To see all concerns,{' '}
        <Link to={`/student/${student.id}/wellness`}>click here</Link>
      </Typography>
    </Stack>
  )
}

Concerns.propTypes = {
  concerns: PropTypes.array.isRequired,
  invalidateQuery: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  student: PropTypes.object.isRequired
}

export default Concerns
