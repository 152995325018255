import React from 'react'
import AdminJobTable from './table'
import { fetchJobTableData } from 'api/jobs'
import {
  Box,
  InputAdornment,
  Typography,
  Grid,
  OutlinedInput,
  InputLabel,
  FormControl,
  Stack
} from '@mui/material'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/admin-page-container'
import SearchIcon from '@mui/icons-material/Search'

class AdminJob extends React.Component {
  state = {
    pages: null,
    isLoading: true,
    search: '',
    data: [],
    jobTitle: ''
  }

  searchChanged = e => {
    const search = e.target.value
    this.setState({ search }, () => {
      this.handleFetchJobTableData()
    })
  }

  handleFetchJobTableData = attrs => {
    this.setState({ isLoading: true })
    const jobId = this.getJobIdFromWindow()
    const params = {
      search: this.state.search,
      number: jobId,
      ...attrs
    }
    fetchJobTableData(params).then(data => {
      this.setState({
        isLoading: false,
        pages: data.meta.pages,
        data: data.data,
        jobTitle: data.job != undefined && data.job.title
      })
    })
  }

  getJobIdFromWindow = () => {
    return parseInt(window.location.href.split('/id/')[1])
  }

  render() {
    const { isLoading, search, data, pages, jobTitle } = this.state
    return (
      <Page name="Admin-Job" title="Show Job">
        <PageContainer>
          <Stack direction="column" spacing={3}>
            <Typography variant="h1">{jobTitle}</Typography>
            <form>
              <Box>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="search">Search</InputLabel>
                  <OutlinedInput
                    id="search"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    label="Search"
                    value={search}
                    onChange={this.searchChanged}
                  />
                </FormControl>
              </Box>
            </form>
            <Grid container>
              <Grid item xs={12} overflow="auto">
                <AdminJobTable
                  data={data}
                  totalPages={pages}
                  loading={isLoading}
                  fetchData={this.handleFetchJobTableData}
                />
              </Grid>
            </Grid>
          </Stack>
        </PageContainer>
      </Page>
    )
  }
}

export default AdminJob
