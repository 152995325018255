import React from 'react'
import PropTypes from 'prop-types'
import { DirectUpload } from '@rails/activestorage'
import { post } from '@rails/request.js'

const DIRECT_UPLOADS_URL = '/rails/active_storage/direct_uploads'

function upload(file, progress, complete) {
  const directUpload = new DirectUpload(file, DIRECT_UPLOADS_URL, {
    directUploadWillStoreFileWithXHR(request) {
      request.upload.addEventListener('progress', progress)
    }
  })

  directUpload.create(complete)

  return directUpload
}

async function postDocument({ associations, blob }) {
  const signedBlob = {
    filename: blob.filename,
    // concern_id, student_id, user_id,
    ...associations,
    signed_blob_id: blob.signed_id
  }

  const response = await post('/documents', {
    body: signedBlob,
    contentType: 'application/json',
    responseKind: 'json'
  })

  if (!response.ok) {
    return {
      error: new Error(response.statusText)
    }
  }

  return {
    data: await response.json
  }
}

export function Upload({
  file,
  associations,
  onSuccess = () => {},
  onError = () => {},
  children
}) {
  const [error, setError] = React.useState()
  const [documentResponse, setDocumentResponse] = React.useState({
    filename: file.name
  })
  const [loaded, setLoaded] = React.useState(0)

  function progress(event) {
    // Use event.loaded and event.total to update the progress bar
    const percentage = (event.loaded / event.total) * 100
    setLoaded(percentage)
  }

  async function complete(error, blob) {
    if (error) {
      // TODO: Handle the error
      setError(error)
    } else {
      const post = await postDocument({ associations, blob })

      if (post.error) {
        setError(post.error)
      } else {
        setDocumentResponse(post.data)
        onSuccess(post.data)
      }
    }
  }

  React.useEffect(() => {
    upload(file, progress, complete)
  }, [file])

  React.useEffect(() => {
    if (error) {
      onError(error)
    }
  }, [error])

  return children({ doc: documentResponse, loaded, error })
}

Upload.propTypes = {
  file: PropTypes.shape({
    // https://developer.mozilla.org/en-US/docs/Web/API/File
    name: PropTypes.string.isRequired
  }).isRequired,
  associations: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
  children: PropTypes.func.isRequired
}

export default Upload
