import React from 'react'
import {
  Chip,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material'

export default function GraduationStatusRiskTable() {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Status</TableCell>
          <TableCell>Threshold</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <Chip color="success" label="On Track" />
          </TableCell>
          <TableCell>No credit deficiencies</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Chip color="secondary" label="Off Track" />
          </TableCell>
          <TableCell>
            Minimal adjustment to student&#39;s schedule might be needed for
            credit recovery
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Chip color="warning" label="At Risk" />
          </TableCell>
          <TableCell>
            Moderate to significant adjustments to student&#39;s schedule might
            be needed to adapt for credit deficiency
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Chip color="error" label="High Risk" />
          </TableCell>
          <TableCell>
            Significant adjustments to scheduling might be necessary to
            accommodate student&#39;s credit deficiency and may require
            alternative supports or interventions
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
