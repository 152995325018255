import { get, post, patch, destroy } from 'utils/ajax'
import { alert } from 'utils/alert'

export const fetchInterventionScores = interventionId => {
  return get(`/api/web/interventions/${interventionId}/scores`).catch(
    _error => {
      alert('There was an error fetching the intervention scores', 'danger')
    }
  )
}

export const createInterventionScore = ({ params }) => {
  return post(`/api/web/interventions/${params.intervention_id}/scores`, {
    intervention_score: {
      intervention_id: params.intervention_id,
      event_on: params.event_on,
      score: params.score,
      notes: params.notes
    }
  }).catch(_error => {
    alert('There was an error creating the score.', 'danger')
  })
}

export const updateInterventionScore = ({ params }) => {
  return patch(`/api/web/scores/${params.id}`, {
    intervention_score: {
      intervention_id: params.intervention_id,
      event_on: params.event_on,
      score: params.score,
      notes: params.notes
    }
  }).catch(_error => {
    alert('There was an error creating the score.', 'danger')
  })
}

export const destroyInterventionScore = ({ id }) => {
  return destroy(`/api/web/scores/${id}`).catch(_error => {
    alert('There was an error creating the score.', 'danger')
  })
}
