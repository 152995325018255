import { post, patch, destroy } from 'utils/ajax'
import { alert } from 'utils/alert'

export const createNote = (comment, studentId) => {
  return post('/api/web/notes', {
    note: {
      comment: comment,
      student_id: studentId
    }
  }).catch(() => {
    alert('There was an error creating the note.', 'danger')
  })
}

export const createNotes = params => {
  return post('/api/web/notes', {
    ...params
  }).catch(() => {
    alert('There was an error creating the note.', 'danger')
  })
}

export const createTeacherNote = params => {
  return post('/api/web/teacher_notes', {
    teacher_note: params
  }).catch(() => {
    alert('There was an error creating the note.', 'danger')
  })
}

export const updateTeacherNote = (noteId, params) => {
  return patch(`/api/web/teacher_notes/${noteId}`, {
    teacher_note: params
  }).catch(() => {
    alert('There was an error updating the note.', 'danger')
  })
}

export const destroyTeacherNote = noteId => {
  return destroy(`/api/web/teacher_notes/${noteId}`, {}).catch(() => {
    alert('There was an error deleting the note.', 'danger')
  })
}
