import React from 'react'
import PropTypes from 'prop-types'
import Webcam from 'react-webcam'
import { Box, Button } from '@mui/material'
import CameraAltIcon from '@mui/icons-material/CameraAlt'

class Camera extends React.Component {
  static propTypes = {
    onCapture: PropTypes.func.isRequired
  }

  state = {
    image: ''
  }

  setRef = webcam => (this.webcam = webcam)

  savePhoto = () => {
    const image = this.webcam.getScreenshot()
    this.setState({ image }, this.uploadFile)
  }

  erasePhoto = () => {
    this.setState({ image: null })
  }

  uploadFile = () => {
    this.getFile().then(imageFile => {
      this.props.onCapture(imageFile)
    })
  }

  getFile = () =>
    fetch(this.state.image)
      .then(res => res.blob())
      .then(blob => this.blobToFile(blob, 'child_study_image_upload.png'))

  blobToFile = (blob, fileName) => {
    blob.lastModifiedDate = new Date()
    blob.name = fileName
    return blob
  }

  render() {
    const videoConstraints = {
      width: 500,
      height: 1100,
      facingMode: 'user'
    }

    const { image } = this.state

    return (
      <Box>
        <Box>
          {image ? (
            <Box>
              <Box>
                <Button
                  onClick={this.erasePhoto}
                  variant="contained"
                  color="primary"
                >
                  <CameraAltIcon />
                  Retake Photo
                </Button>
              </Box>
              <Box>
                <label>Your Image:</label>
              </Box>
              <img src={image} alt="screenshot preview" />
            </Box>
          ) : (
            <Box>
              <Button
                onClick={this.savePhoto}
                variant="contained"
                color="primary"
              >
                <CameraAltIcon />
                Take Photo
              </Button>
            </Box>
          )}
          <Box>&nbsp;</Box>
          {!image && (
            <Webcam
              audio={false}
              height={350}
              ref={this.setRef}
              screenshotFormat="image/jpeg"
              width={350}
              videoConstraints={videoConstraints}
            />
          )}
        </Box>
      </Box>
    )
  }
}

export default Camera
