import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { maxBy } from 'lodash'
import MapDialog from './dialog'
import MapChart from './chart'
import { fetchStudentMaps } from 'api/maps'
import { titleize } from 'utils'
import ChartContainer from 'components/shared/chart-container'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import InputLabel from '@mui/material/InputLabel'
import MapTable from './table'
import { AbsoluteSpinner } from 'components/shared/spinner'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function Map({ studentId, subject }) {
  const [mapData, setMapData] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [language, setLanguage] = useState('english')
  const [maxLexile, setMaxLexile] = useState(null)
  const [activeMap, setActiveMap] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchMapData = (subject, opts = {}) => {
    const params = {
      subject: subject,
      language: language,
      ...opts
    }

    setLoading(true)

    fetchStudentMaps(studentId, params).then(data => {
      setMapData(data.data)

      const max = maxBy(mapData, 'lexile')
      setMaxLexile(max)
      setLoading(false)
    })
  }

  const handleDialogHide = () => {
    setActiveMap(null)
    setDialogOpen(false)
  }

  useEffect(() => {
    fetchMapData(subject)
  }, [subject, language])

  return (
    <React.Fragment>
      <Typography variant="h3">MAP Growth</Typography>
      <FormControl variant="filled" fullWidth margin="normal">
        <InputLabel>Language</InputLabel>
        <Select
          id="language-selector"
          value={language}
          onChange={val => setLanguage(val.target.value)}
        >
          <MenuItem value="english" key="english" aria-label="english">
            English
          </MenuItem>
          <MenuItem value="spanish" key="spanish" aria-label="spanish">
            Spanish
          </MenuItem>
        </Select>
      </FormControl>
      {activeMap && (
        <MapDialog
          show={dialogOpen}
          onHide={handleDialogHide}
          map={activeMap}
        />
      )}
      {loading ? (
        <AbsoluteSpinner />
      ) : (
        <React.Fragment>
          <ChartContainer>
            {mapData.length > 0 ? (
              <MapChart data={mapData} studentName={''} subject={subject} />
            ) : (
              <div>
                No {titleize(subject)} ({titleize(language)}) MAP data found.
              </div>
            )}
          </ChartContainer>
          <MapTable
            language={language}
            mapData={mapData}
            maxLexile={maxLexile}
            setActiveMap={setActiveMap}
            setDialogOpen={setDialogOpen}
            subject={subject}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

Map.propTypes = {
  studentId: PropTypes.number.isRequired,
  studentName: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired
}

export default Map
