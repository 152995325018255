import { mapKeys, snakeCase, camelCase } from 'lodash'
import moment from 'moment'

export function convertKeysToSnake(obj) {
  return mapKeys(obj, (_v, key) => snakeCase(key))
}

export function convertKeysToCamel(obj) {
  return mapKeys(obj, (_v, key) => camelCase(key))
}

// initializes clazz when page is loaded with the
// matching bodyClass
export function runPage(clazz, bodyClass) {
  $(document).ready(() => {
    if (document.body.classList.contains(bodyClass)) {
      new clazz()
    }
  })
}

export function convertBoolsToYesNo(obj) {
  obj = { ...obj }
  Object.keys(obj).forEach(key => {
    if (obj[key] === true) {
      obj[key] = 'Yes'
    } else if (obj[key] === false) {
      obj[key] = 'No'
    }
  })

  return obj
}

export function calculatePercentage(part, whole, decimal = 2) {
  return whole !== 0 ? ((part / whole) * 100).toFixed(decimal) : 0
}

export function titleize(sentence) {
  if (!sentence || !sentence.split) return sentence

  const _titleizeWord = string =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

  sentence = sentence.replace(/_/g, ' ')
  sentence = sentence.replace('/', ' / ')
  const result = sentence.split(' ').map(word => _titleizeWord(word))

  return result.join(' ')
}

// pluralize(2, 'dog') => 2 dogs     pluralize(2, 'box', 'es') => 2 boxes
export const pluralize = (count, word, suffix = 's') =>
  `${count} ${pluralizeWord(count, word, suffix)}`

// doesn't include the number
export const pluralizeWord = (count, word, suffix = 's') =>
  `${word}${count !== 1 ? suffix : ''}`

const calculateCurrentSchoolYear = () => {
  const today = moment()
  if (today.month() + 1 < 7) {
    // Jan through Jun is current year
    return today.year()
  } else {
    return today.year() + 1
  }
}

// TODO: Set with Termbins as Context from PowerSchool
const calculateCurrentSemester = () => {
  const today = moment()
  if (today.month() + 1 < 7) {
    return 'S2'
  } else {
    return 'S1'
  }
}

const calculateCurrentQuarter = () => {
  const currentMonth = moment().month() + 1
  if (currentMonth > 8 && currentMonth < 11) {
    return 'Q1'
  } else if (currentMonth >= 11 || currentMonth < 2) {
    return 'Q2'
  } else if (currentMonth >= 2 && currentMonth < 4) {
    return 'Q3'
  } else {
    return 'Q4'
  }
}

export const currentSchoolYear = calculateCurrentSchoolYear()
export const currentSemester = calculateCurrentSemester()
export const currentQuarter = calculateCurrentQuarter()
