import React from 'react'
import PropTypes from 'prop-types'
import { Rating, Box } from '@mui/material'
import { Star, StarBorder } from '@mui/icons-material'

export const DEFAULT_SCALE = 5

export const StarRating = ({ rating, scale = DEFAULT_SCALE, ...props }) => {
  return (
    <Box {...props}>
      <Rating
        value={rating}
        max={scale}
        readOnly
        emptyIcon={<StarBorder fontSize="inherit" />}
        icon={<Star fontSize="inherit" />}
      />
    </Box>
  )
}

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
  scale: PropTypes.number
}

export default StarRating
