export const types = {
  ADD_ALERT: 'alerts/add_alert',
  POP_ALERT: 'alerts/pop_alert'
}

const initialState = []

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ALERT:
      return [...state, { added: new Date(), ...action.payload }]
    case types.POP_ALERT:
      return state.slice(1)
    default:
      return state
  }
}
