import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectors } from 'modules/shared'
import {
  withHighcharts,
  HighchartsChart,
  XAxis,
  YAxis,
  LineSeries,
  ColumnSeries,
  Legend,
  Tooltip
} from 'react-jsx-highcharts'
import { chartColors } from 'utils/chart-colors'

const MapChart = ({ data, isParentPortal }) => {
  if (!data || !data.length) {
    return <div style={styles.container} />
  }

  const percentile = data.map(d => d.percentile)
  const testingWindow = data.map(d => d.title)

  const buildLineSeries = data => {
    const series = [
      {
        name: I18n.jsT('labels.student_score'),
        data: data.map(d => d.score),
        color: styles.studentScore.color
      },
      {
        name: I18n.jsT('labels.district_avg'),
        data: data.map(d => d.district_average),
        color: styles.districtAverage.color
      },
      {
        name: I18n.jsT('labels.national_avg'),
        data: data.map(d => d.national_average),
        color: styles.nationalAverage.color
      }
    ]

    return series
  }

  const plotOptions = {
    chart: {
      zoomType: 'xy'
    },
    credits: {
      enabled: false
    }
  }

  const legendOptions = {
    floating: true,
    ...styles.legend
  }

  return (
    <div id="map-chart" style={styles.container}>
      <HighchartsChart
        id="js-attendance-line-chart"
        plotOptions={plotOptions}
        exporting={{ enabled: !isParentPortal }}
      >
        <Legend {...legendOptions} />
        <Tooltip shared />
        <XAxis type="category" categories={testingWindow} crosshair />

        <YAxis
          opposite
          id="number"
          tickAmount={6}
          labels={{ format: '{value}' }}
        >
          <YAxis.Title>{I18n.jsT('labels.score')}</YAxis.Title>
        </YAxis>

        <YAxis
          id="percentile"
          tickAmount={6}
          min={0}
          max={100}
          labels={{
            format: '{value}%',
            style: styles.percentileAxis
          }}
        >
          <YAxis.Title style={styles.percentileAxis}>
            {I18n.jsT('labels.national_percentile')}
          </YAxis.Title>
        </YAxis>

        <ColumnSeries
          id="series-percentile"
          name={I18n.jsT('labels.student_percentile')}
          data={percentile}
          color={styles.studentPercentile.color}
          marker={{ enabled: true }}
          tooltip={{ valueSuffix: '%' }}
          yAxis={1}
        />

        {buildLineSeries(data).map((series, index) => (
          <LineSeries
            id={`series-${index}`}
            key={index}
            name={series.name}
            data={series.data}
            marker={{ enabled: true }}
            color={series.color}
          />
        ))}
      </HighchartsChart>
    </div>
  )
}

MapChart.propTypes = {
  data: PropTypes.array,
  isParentPortal: PropTypes.bool
}

const styles = {
  container: {
    minHeight: '400px'
  },
  percentileAxis: {
    color: chartColors.blue
  },
  studentScore: {
    color: chartColors.blue
  },
  districtAverage: {
    color: chartColors.grey
  },
  nationalAverage: {
    color: chartColors.yellow
  },
  studentPercentile: {
    color: 'rgb(116, 196, 220, 0.5)'
  },
  legend: {
    backgroundColor: '#FFFFFF',
    align: 'left',
    layout: 'vertical',
    x: 100,
    y: 0,
    verticalAlign: 'top',
    borderWidth: 1,
    borderRadius: 3
  }
}

const mapStateToProps = state => ({
  isParentPortal: selectors.isParentPortal(state)
})

export default connect(mapStateToProps)(
  withHighcharts(MapChart, window.Highcharts)
)
