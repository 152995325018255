import StudentsApi from './api'
import { types } from './reducer'

export const fetchStudents = params => {
  return dispatch => {
    return StudentsApi.getStudents(params).then(data => {
      dispatch({ type: types.FETCH_STUDENTS, payload: data })
    })
  }
}

export const setSelectedStudents = students => {
  return dispatch => {
    dispatch({ type: types.SET_STUDENTS_LIST, payload: students })
    return Promise.resolve()
  }
}
