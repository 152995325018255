import React from 'react'
import PropTypes from 'prop-types'

class Page extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.node.isRequired
  }

  componentDidMount() {
    this.fixFeedbackLinks()
    this.updatePageTitle()
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.fixFeedbackLinks()
      this.updatePageTitle()
    }
  }

  fixFeedbackLinks() {
    const { name } = this.props
    const baseUrl = $('#feedback-link').data('baseHref')
    $('#feedback-link').attr(
      'href',
      `${baseUrl}&page=${encodeURIComponent(name)}`
    )
    $('#rating_page').val(name)
  }

  updatePageTitle() {
    const { title } = this.props
    if (title) {
      document.title = `${title} – TPS Data`
    }
  }

  render() {
    return this.props.children
  }
}

export default Page
