import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { titleize } from 'utils'
import { RemoteTable } from 'components/shared/table'
import UserModal from './user-modal'
import NameCell from './table/name-cell'
import EmployeeIdCell from './table/employee-id-cell'
import EditButton from './table/edit-button'
import ImpersonateButton from './table/impersonate-button'
import EmailUserButton from './table/email-user-button'
import { Box, Chip, Tooltip, Stack } from '@mui/material'

const AdminUsersTable = ({ data, fetchData, loading, totalPages }) => {
  const [openModal, setOpenModal] = useState(false)
  const [activeUser, setActiveUser] = useState({})
  const formType = 'Edit'

  const handleModalHide = () => setOpenModal(false)

  const openModalWithUser = user => {
    setActiveUser(user)
    setOpenModal(true)
  }

  const columns = () => {
    return [
      {
        id: 'employee_id',
        Header: 'Employee ID',
        accessor: obj => obj,
        maxWidth: 105,
        Cell: props => <EmployeeIdCell user={props.value} />
      },
      {
        id: 'name',
        Header: 'Name',
        accessor: obj => obj,
        Cell: props => <NameCell user={props.value} />
      },
      {
        id: 'role',
        Header: 'Role',
        maxWidth: 100,
        accessor: obj => titleize(obj.role)
      },
      {
        id: 'job_title',
        Header: 'Job Title',
        sortable: false,
        accessor: obj => obj,
        Cell: props => (
          <Link to={{ pathname: `/admins/jobs/id/${props.value.job_id}` }}>
            {props.value.job_title}
          </Link>
        )
      },
      {
        Header: 'Logins',
        accessor: 'login_events_count',
        maxWidth: 65
      },
      {
        id: 'active',
        Header: () => (
          <Tooltip
            placement="top"
            id="active-status"
            arrow
            title="Status now displays if an employee is active in Munis or if the user is an active external partner."
          >
            Status
          </Tooltip>
        ),
        maxWidth: 80,
        accessor: obj => obj.active,
        Cell: props => (
          <Chip
            color={props.value ? 'success' : 'default'}
            label={props.value ? 'active' : 'inactive'}
            size="small"
          />
        )
      },
      {
        Header: 'Actions',
        id: 'user-actions',
        sortable: false,
        maxWidth: 180,
        accessor: obj => obj,
        Cell: props => (
          <Stack direction="row">
            <EditButton
              user={props.value}
              onClick={() => openModalWithUser(props.value)}
            />
            <ImpersonateButton user={props.value} />
            <EmailUserButton user={props.value} />
          </Stack>
        )
      }
    ]
  }

  return (
    <Box id="user-table">
      <UserModal
        key={activeUser.id}
        show={openModal}
        user={activeUser}
        formType={formType}
        onHide={handleModalHide}
        fetchData={fetchData}
      />
      {data && (
        <RemoteTable
          columns={columns()}
          data={data}
          loading={loading}
          onFetchData={fetchData}
          pages={totalPages}
        />
      )}
    </Box>
  )
}

AdminUsersTable.propTypes = {
  data: PropTypes.array,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  totalPages: PropTypes.number,
  value: PropTypes.object
}

export default AdminUsersTable
