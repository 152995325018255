import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Stack,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Typography
} from '@mui/material'
import { Dialog } from 'shared/dialog'
import { connect } from 'react-redux'
import { fetchRequirements } from 'modules/requirements'
import {
  createChildStudyForm,
  updateChildStudyForm
} from 'api/child-study-forms'
import { childStudyFormCategories } from 'utils/enum-options'
import Camera from 'components/shared/camera'

/**
 * AcceptPropType can be:
 *
 * - undefined (not required)
 * - string
 * - [string, …]
 * - [[string, string], …]
 * - [string, [string, string], …]
 */
const AcceptPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
  )
])

/**
 * Convert accept prop into proper <input accept=""> value.
 *
 * @param {AcceptPropType} accept
 * @returns {undefined|string}
 */
function parseAcceptProp(accept) {
  if (['undefined', 'string'].includes(typeof accept)) {
    return accept
  }

  return accept.flat(1).join(',')
}

function UploadFormModal({
  show,
  onHide,
  childStudyId,
  fetchRequirements,
  formId,
  category,
  name,
  file_type,
  file_url,
  editing,
  accept
}) {
  const [fileCategory, setFileCategory] = useState(category || '')
  const [fileName, setFileName] = useState(name || '')
  const [file, setFile] = useState('')
  const [showFilePicker, setShowFilePicker] = useState(true)
  const [addingNewFile, setAddingNewFile] = useState(!editing)

  const resetState = () => {
    setFileCategory('')
    setFileName('')
    setFile('')
    setShowFilePicker(true)
    setAddingNewFile(true)
  }

  const handleSubmit = event => {
    event.preventDefault()

    setFileCategory(event.target.value)

    const data = getFormData()
    onSubmitAction(data).then(() => {
      resetAndHide()
      fetchRequirements(childStudyId)
    })
  }

  const onSubmitAction = data => {
    if (editing) {
      return updateChildStudyForm(formId, data)
    } else {
      return createChildStudyForm(data)
    }
  }

  const getFormData = () => {
    const determineFileName = fileName || file.name
    const data = new FormData()

    if (addingNewFile) {
      data.append('child_study_form[document]', file, determineFileName)
    }
    data.append('child_study_form[category]', fileCategory)
    data.append('child_study_form[name]', fileName)
    data.append('child_study_form[child_study_id]', childStudyId)

    return data
  }

  const setInitialStateForEdit = () => {
    if (editing) {
      setFileName(name)
      setFileCategory(category)
      setAddingNewFile(false)
    }
  }

  const resetAndHide = () => {
    resetState()
    onHide()
  }

  const renderExistingImage = () => (
    <Box>
      <Button onClick={() => setAddingNewFile(true)}>Upload New File</Button>
      <Box sx={styles.imgContainer}>
        <a
          href={file_url}
          onClick={e => {
            e.preventDefault()
            window.open(file_url, '_blank')
          }}
        >
          {file_type.match(/^image\//) ? (
            <Box
              component="img"
              src={file_url}
              alt="upload preview"
              sx={styles.img}
            />
          ) : (
            <Typography>
              {decodeURI(file_url.split('/').pop().replace(/\?.*$/, ''))}
            </Typography>
          )}
        </a>
      </Box>
    </Box>
  )

  const renderUploadForm = () => (
    <Stack spacing={2}>
      <Box>
        <Button
          onClick={() => {
            setShowFilePicker(true)
            setFile('')
          }}
          variant={showFilePicker ? 'outlined' : 'text'}
          disabled={showFilePicker ? true : false}
        >
          Upload A File
        </Button>
        <Button
          onClick={() => {
            setShowFilePicker(false)
            setFile('')
          }}
          variant={!showFilePicker ? 'outlined' : 'text'}
          disabled={!showFilePicker ? true : false}
          sx={{ ml: 2 }}
        >
          Use Webcam
        </Button>
      </Box>
      {showFilePicker ? (
        <input
          required
          type="file"
          name="upload-form"
          id="upload-form"
          onChange={e => setFile(e.target.files[0])}
          accept={parseAcceptProp(accept)}
        />
      ) : (
        <Camera onCapture={image => setFile(image)} />
      )}
    </Stack>
  )

  const actionType = editing ? 'Update Existing' : 'Upload New'

  const showFilenameInput = file || file_url

  return (
    <Dialog
      open={show}
      onClose={onHide}
      onEntering={setInitialStateForEdit}
      title={`${actionType} Form`}
    >
      <Box sx={{ p: 4 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <FormControl fullWidth>
              <InputLabel id="category-select-label">
                Choose Category
              </InputLabel>
              <Select
                labelId="category-select-label"
                id="category-type"
                value={fileCategory}
                label="Choose Category"
                onChange={e => setFileCategory(e.target.value)}
                required
              >
                {childStudyFormCategories.map(category => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {!addingNewFile && file_url
              ? renderExistingImage()
              : renderUploadForm()}

            {showFilenameInput && (
              <TextField
                id="name"
                label="Add File Name (Optional)"
                value={fileName}
                onChange={e => setFileName(e.target.value)}
                placeholder="File Name"
                fullWidth
              />
            )}

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!file && addingNewFile}
            >
              {`${actionType} Form`}
            </Button>
          </Stack>
        </form>
      </Box>
    </Dialog>
  )
}

const styles = {
  imgContainer: {
    width: '120px',
    margin: 'auto',
    height: 'auto'
  },
  img: {
    width: '100%',
    height: 'auto'
  }
}

UploadFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  childStudyId: PropTypes.number.isRequired,
  fetchRequirements: PropTypes.func.isRequired,
  formId: PropTypes.number,
  category: PropTypes.string,
  name: PropTypes.string,
  file_type: PropTypes.string,
  file_url: PropTypes.string,
  editing: PropTypes.bool,
  accept: AcceptPropType
}

const mapDispatchToProps = {
  fetchRequirements
}

export default connect(null, mapDispatchToProps)(UploadFormModal)
