import React from 'react'
import PropTypes from 'prop-types'
import { RemoteTable } from 'components/shared/table'
import { connect } from 'react-redux'
import moment from 'moment'
import { Box, Link as MuiLink } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import GradeDistribution from 'components/shared/grade-distribution'
import { theme } from '../app/theme'

class SectionsTable extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    data: PropTypes.array,
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    fetchData: PropTypes.func
  }

  fetchData = params => {
    this.props.fetchData(params)
  }

  render() {
    /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
    const { user, data, totalPages, currentPage, fetchData, ...props } =
      this.props
    const columns = [
      {
        id: 'teacher_last_name',
        Header: 'Teacher',
        accessor: obj => obj,
        Cell: props => {
          return user.role == 'support' ? (
            props.value.teacher_last_name
          ) : (
            <MuiLink
              component={RouterLink}
              to={`/teachers/${props.value.teacher_id}/overview`}
              sx={{
                textDecoration: 'none',
                color: theme.palette.primary.main,
                fontWeight: 'bold'
              }}
            >
              {props.value.teacher_last_name}
            </MuiLink>
          )
        }
      },
      {
        Header: 'Hour',
        minWidth: 50,
        width: 50,
        accessor: 'hour'
      },
      {
        Header: 'Class',
        minWidth: 200,
        width: 200,
        accessor: 'name_with_nickname'
      },
      {
        id: 'grade_distribution',
        Header: 'Grades',
        minWidth: 200,
        width: 200,
        accessor: obj => obj,
        sortable: false,
        Cell: props => {
          const gradeDistribution = props.value.grade_distribution
          return <GradeDistribution gradeDistribution={gradeDistribution} />
        }
      },
      {
        Header: 'Students (#)',
        minWidth: 120,
        width: 120,
        accessor: 'student_count'
      },
      {
        Header: 'ML (#)',
        accessor: 'ell_count'
      },
      {
        Header: 'SPED (#)',
        accessor: 'sped_count'
      },
      {
        Header: 'Gifted (#)',
        accessor: 'gifted_count'
      },
      {
        Header: 'Graded Assignments (#)',
        accessor: 'graded_assignments_count'
      },
      {
        id: 'last_grade_entered',
        Header: 'Last Graded',
        accessor: obj =>
          obj.last_grade_entered &&
          moment(obj.last_grade_entered).format('MM/DD/YYYY')
      },
      {
        Header: 'Passing (%)',
        accessor: 'percent_passing',
        Cell: props => {
          return props.value < 0 ? '--' : `${props.value}%`
        }
      }
    ]
    return (
      <Box id="sections-table">
        <RemoteTable
          columns={columns}
          data={data}
          pages={totalPages}
          onFetchData={this.fetchData}
          {...props}
        />
      </Box>
    )
  }
}

const mapStateToProps = state => ({
  user: state.currentUser.user,
  totalPages: state.sections.meta.pages,
  currentPage: state.sections.meta.page
})

export default connect(mapStateToProps)(SectionsTable)
