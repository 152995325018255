import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { isUndefined } from 'lodash'
import { getSchoolInterventions } from 'api/interventions'
import { actions as schoolActions, selectors } from 'modules/schools'
import { userPropTypes } from 'modules/current-user'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import { optionsPropTypes, withOptions } from 'utils/contexts/options'
import InterventionsTable from 'components/schools/interventions/interventions-table'
import InterventionFilters from 'components/schools/interventions/filters'
import WellnessTeamForm from './team/form'
import WellnessTeamDialog from './team/dialog'
import WellnessTeamManageButton from './team/manage-button'
import { fetchSchoolDetails } from 'api/schools'
import { Box, Button, Stack, Typography } from '@mui/material'
import ConcernDialog from 'components/shared/concerns/dialog'
import { useQuery } from '@tanstack/react-query'
import BulkSupportsDialog from 'components/shared/concerns/bulk-supports-dialog'
import { currentSchoolYear } from 'utils'
import urls from 'utils/urls'
import { get } from 'utils/ajax'
import PaperBox from 'components/shared/PaperBox'
import PieChartFiveSlices from 'shared/charts/PieChartFiveSlices'
import SchoolAutocomplete from '../shared/school-autocomplete'
import { useDebounce } from 'hooks/useDebounce'

function Wellness({ schoolId, schools, school, user }) {
  const dispatch = useDispatch()
  const schoolChanged = id => dispatch(schoolActions.selectSchool(id))

  const defaultFilters = {
    status: null,
    concernTypeId: null,
    isRequestingHelp: null,
    createdBy: null,
    severity: null,
    hasInterventions: null,
    hasSupports: null,
    hasExternalReferrals: null,
    hasNotes: null,
    hasDocuments: null,
    isHighSecurity: null
  }

  const [filters, setFilters] = useState(defaultFilters)

  const defaultParams = {
    page: 1,
    per_page: 20,
    sort: 'updated_at',
    sort_direction: 'desc'
  }
  const [params, setParams] = useState(defaultParams)
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  const [wellnessTeamDialogOpen, setWellnessTeamDialogOpen] = useState(false)

  const [concernDialogOpen, setConcernDialogOpen] = useState(false)
  const [bulkSupportsDialogOpen, setBulkSupportsDialogOpen] = useState(false)

  const [behaviorTiers, setBehaviorTiers] = useState(null)

  const filteredSchoolConcernsQuery = useQuery(
    ['filtered-school-concerns', { school, filters, debouncedSearch, params }],
    () =>
      getSchoolInterventions({
        school_id: school?.id,
        student_name_query: debouncedSearch,
        is_requesting_help: filters.isRequestingHelp,
        concern_type_id: filters.concernTypeId,
        status: filters.status,
        created_by: filters.createdBy,
        severity: filters.severity,
        has_interventions: filters.hasInterventions,
        has_supports: filters.hasSupports,
        has_external_referrals: filters.hasExternalReferrals,
        has_notes: filters.hasNotes,
        has_documents: filters.hasDocuments,
        is_high_security: filters.isHighSecurity,
        ...params
      }).then(res => ({
        concerns: res.data,
        concernTypes: res.concern_types,
        createdByNames: res.created_by_names,
        meta: res.meta
      })),
    {
      initialData: {
        concerns: [],
        concernTypes: [],
        createdByNames: [],
        meta: null
      },
      keepPreviousData: false,
      refetchOnWindowFocus: false
    }
  )

  const { concerns, concernTypes, createdByNames, meta } =
    filteredSchoolConcernsQuery.data

  const fetchInterventions = newParams => {
    setParams({ ...params, ...newParams })
  }

  const schoolInfoQuery = useQuery(
    ['wellness-team-users-access', schoolId],
    async () => {
      if (schoolId) {
        const res = await fetchSchoolDetails(schoolId)
        return {
          usersWithAccess: res.users_with_access,
          wellnessTeamId: res.wellness_team_id
        }
      }
    },
    {
      enabled: ['support', 'principal', 'district'].includes(user.role),
      initialData: {
        usersWithAccess: [],
        wellnessTeamId: null
      }
    }
  )
  const { usersWithAccess, wellnessTeamId } = schoolInfoQuery.data

  const toggleWellnessTeamModal = () => {
    setWellnessTeamDialogOpen(!wellnessTeamDialogOpen)
  }

  const resetFilters = () => {
    setFilters(defaultFilters)
  }

  const handleFilterChange = (key, val) => {
    setFilters({ ...filters, [key]: val })
  }

  const handleConcernDialogClose = ({ action }) => {
    setConcernDialogOpen(false)
    if (['create', 'update'].includes(action)) {
      fetchInterventions(defaultParams)
    }
  }

  const handleBulkSupportsDialogClose = ({ action }) => {
    setBulkSupportsDialogOpen(false)
    if (['create', 'update'].includes(action)) {
      fetchInterventions(defaultParams)
    }
  }

  useEffect(() => {
    const params = { school_year: currentSchoolYear }

    if (schoolId) {
      get(urls.api.schoolMetrics(school.id), { params }).then(data => {
        setBehaviorTiers(data.school_metric?.behavior_tiers)
      })
    } else {
      schoolChanged(schools.find(value => value)?.value)
    }
  }, [schoolId])

  return (
    <Page name="Concerns" title={'Student Supports and Concerns'}>
      <PageContainer>
        <Box>
          {schools.length > 1 && (
            <Stack direction="row" justifyContent="flex-end">
              <Box
                id="test-school-selector"
                sx={{
                  minWidth: '20rem'
                }}
              >
                <SchoolAutocomplete disableClearable />
              </Box>
            </Stack>
          )}
        </Box>

        <Box
          sx={{
            mb: 6
          }}
        >
          <Typography variant="h1" id="test_header_title">
            Student Supports and Concerns
          </Typography>
          <Typography variant="h2" id="test_subheader_title">
            {school?.name || 'All Schools'}
          </Typography>
        </Box>

        <Box>
          <Stack id="test-action-buttons">
            <Box>
              <Stack
                direction={'row'}
                spacing={2}
                justifyContent="space-between"
              >
                {['principal', 'district'].includes(user.role) && (
                  <WellnessTeamManageButton
                    onClick={() => toggleWellnessTeamModal()}
                    disabled={isUndefined(school)}
                  />
                )}
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => setConcernDialogOpen(true)}
                  sx={{ lineHeight: 1 }}
                >
                  New Concern
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => setBulkSupportsDialogOpen(true)}
                  sx={{ lineHeight: 1 }}
                >
                  New Support
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  href={
                    'https://tulsaschools.sjc1.qualtrics.com/jfe/form/SV_8G1udP9PkeXcgJ0'
                  }
                  target="_blank"
                  sx={{ lineHeight: 1, textAlign: 'center' }}
                >
                  Request District Support
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  href={
                    'https://tulsaschools.sjc1.qualtrics.com/jfe/form/SV_9LGh9US9zzWooiV'
                  }
                  target="_blank"
                  sx={{ lineHeight: 1, textAlign: 'center' }}
                >
                  Request Intensive Behavior Support
                </Button>
              </Stack>
            </Box>

            <Box>
              <InterventionFilters
                search={search}
                setSearch={setSearch}
                status={filters.status}
                isRequestingHelp={filters.isRequestingHelp}
                createdBy={filters.createdBy}
                severity={filters.severity}
                concernTypeId={filters.concernTypeId}
                concernTypes={concernTypes}
                createdByNames={createdByNames}
                handleChange={handleFilterChange}
                resetState={resetFilters}
                hasInterventions={filters.hasInterventions}
                hasSupports={filters.hasSupports}
                hasExternalReferrals={filters.hasExternalReferrals}
                hasNotes={filters.hasNotes}
                hasDocuments={filters.hasDocuments}
                isHighSecurity={filters.isHighSecurity}
              />
            </Box>

            <Box>
              <InterventionsTable
                interventions={concerns}
                loading={filteredSchoolConcernsQuery.isLoading}
                schoolId={schoolId}
                meta={meta}
                fetchInterventions={fetchInterventions}
                user={user}
                queryKey={['filtered-school-concerns']}
              />
            </Box>
          </Stack>
        </Box>

        <ConcernDialog
          show={concernDialogOpen}
          onHide={handleConcernDialogClose}
        />

        <BulkSupportsDialog
          show={bulkSupportsDialogOpen}
          onHide={handleBulkSupportsDialogClose}
        />

        {wellnessTeamId && (
          <WellnessTeamDialog
            open={wellnessTeamDialogOpen}
            onClose={() => toggleWellnessTeamModal()}
          >
            <WellnessTeamForm
              teamId={wellnessTeamId}
              usersWithAccess={usersWithAccess}
            />
          </WellnessTeamDialog>
        )}

        {behaviorTiers && (
          <PaperBox
            title="Students Needing Behavior Support"
            helperText={{
              'How is "Major Discipline" defined?':
                'The number of suspensions with a code of 300 or higher from this school year.',
              'How is "In-Class Discipline" defined?':
                'The number of referrals from this school year.',
              'How is "In-School Suspension Days" defined?':
                'The total number of discipline duration days assigned to referrals and suspensions with a consequence of "Suspension, in-school^" for this school year.',
              'How is "Out-of-School Suspension Days" defined?':
                'The total number of discipline duration days assigned to suspensions with a consequence other than "Suspension, in-school^" for this school year.',
              'If one or more of the following criteria is met, student needs Tier 2 support:':
                null,
              'Major Discipline': '2 to 4',
              'In-Class Discipline': '5 to 14',
              'Behavior Concerns (Medium Severity)': '1+',
              'In-School Suspension Days': '2 to 4',
              'Out-of-School Suspension Days': '2 to 4',
              'If one or more of the following criteria is met, student needs Tier 3 support:':
                null,
              'Major Discipline ': '5+',
              'In-Class Discipline ': '15+',
              'Behavior Concerns (High Severity)': '1+',
              'In-School Suspension Days ': '5+',
              'Out-of-School Suspension Days ': '5+'
            }}
          >
            <PieChartFiveSlices
              one={{
                title: 'Tier 1',
                value:
                  behaviorTiers.total -
                  behaviorTiers.tier_two_behavior -
                  behaviorTiers.tier_three_behavior,
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Behavior',
                    behavior_tier: 1
                  }
                }
              }}
              two={{
                title: 'Tier 2',
                value: behaviorTiers.tier_two_behavior,
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Behavior',
                    behavior_tier: 2
                  }
                }
              }}
              three={{
                title: 'Tier 3',
                value: behaviorTiers.tier_three_behavior,
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: school.id,
                    view: 'Behavior',
                    behavior_tier: 3
                  }
                }
              }}
            />
          </PaperBox>
        )}
      </PageContainer>
    </Page>
  )
}

Wellness.propTypes = {
  schoolId: PropTypes.number,
  schools: PropTypes.array.isRequired,
  school: PropTypes.object.isRequired,
  ...optionsPropTypes,
  ...userPropTypes
}

const mapStateToProps = state => ({
  schoolId: state.schools.schoolId,
  schools: selectors.schoolOptions(state, { includeAllSchoolsOption: true }),
  school: selectors.currentSchool(state),
  user: state.currentUser.user
})

export default connect(mapStateToProps)(withOptions(Wellness))
