import React from 'react'
import PropTypes from 'prop-types'
import { Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom'

const StudentNameHyperlink = ({ id, name, tab = null }) => {
  const url = tab ? `/student/${id}/${tab}` : `/student/${id}`
  return (
    <MuiLink component={Link} to={url}>
      {name}
    </MuiLink>
  )
}

StudentNameHyperlink.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  tab: PropTypes.string
}

export default StudentNameHyperlink
