import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'utils/ajax'
import { titleize } from 'utils'
import { isEqual } from 'lodash'
import Dialog from 'shared/dialog'
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@mui/material'

export default class ResourcesModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    schoolName: PropTypes.string.isRequired,
    schoolId: PropTypes.number.isRequired
  }

  state = {
    schoolId: this.props.schoolId
  }

  componentDidMount() {
    this.setState({ schoolLinks: this.getSchoolLinks(this.props.schoolId) })
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.schoolName, this.props.schoolName)) {
      this.getSchoolLinks(this.props.schoolId)
    }
  }

  getSchoolLinks = schoolId => {
    get('/api/web/school_links', {
      params: {
        school_id: schoolId
      }
    })
      .then(res => {
        this.setState({ schoolLinks: res })
      })
      .catch(() => {
        alert('There was an error getting the school links.', 'danger')
      })
  }

  render() {
    const { onHide, show, schoolName } = this.props
    const { schoolLinks } = this.state

    return (
      <Dialog
        open={show}
        onClose={onHide}
        title={`${titleize(schoolName)} Links`}
      >
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Links</TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Comment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {schoolLinks &&
                schoolLinks.map((link, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <a href={link.url} target="_blank" rel="noreferrer">
                        {link.label}
                      </a>
                    </TableCell>
                    <TableCell>{titleize(link.category)}</TableCell>
                    <TableCell>{link.comment}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </Dialog>
    )
  }
}
