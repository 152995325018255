import { get, patch } from 'utils/ajax'
import { alert } from 'utils/alert'
import { titleize } from 'utils'

export const fetchJobTableData = (params = {}) => {
  return get('/api/admin/users', { params }).catch(_err => {
    alert(
      `An error occurred while fetching users with job ID ${params.number}`,
      'danger'
    )
  })
}

export const getMuiAutocompleteJobRoles = roles =>
  Object.keys(roles || {}).map(value => ({
    label: titleize(value),
    value: value
  }))

export const updateUserJobRoles = params => {
  return params.ids.map(id => {
    patch(`/api/admin/jobs/${id}`, {
      job: {
        id: id,
        role: params.role,
        code: params.code
      }
    })
      .then(() => {
        alert('Job roles successfully updated', 'success')
      })
      .catch(_err => {
        alert('An error occurred while updating the job roles', 'danger')
      })
  })
}
