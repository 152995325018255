import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import { actions as schoolActions, selectors } from 'modules/schools'
import ChildStudiesTable from './table'
import NewChildStudyButton from './new-child-study-button'
import { compact, debounce, flattenDeep, uniq } from 'lodash'
import ChildStudyTeamMemberModal from './team-member-modal'
import { Box, Button, Link as MuiLink, Stack, Typography } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import StudentsTableFilters from 'components/shared/students-table/filters'
import ChildStudyStatusBadge from 'components/shared/child-study-status-badge'
import moment from 'moment'
import {
  deleteChildStudy,
  fetchEligibleChildStudyStudents
} from 'modules/child-studies'
import { fetchStudents } from 'modules/students'
import ConfirmModalButton from 'components/shared/confirm-modal/button'
import FolderIcon from '@mui/icons-material/Folder'
import DeleteIcon from '@mui/icons-material/Delete'
import GroupsIcon from '@mui/icons-material/Groups'
import urls from 'utils/urls'
import { get } from 'utils/ajax'
import { currentSchoolYear } from 'utils'
import PaperBox from 'shared/PaperBox'
import PieChartFiveSlices from 'components/shared/charts/PieChartFiveSlices'
import SchoolAutocomplete from '../shared/school-autocomplete'
import { theme } from '../app/theme'

const _DeleteAction = ({
  schoolId,
  deleteChildStudy,
  fetchEligibleChildStudyStudents,
  value
}) => (
  <ConfirmModalButton
    modalMessage="Are you sure you want to delete this Child Study and all of its associated data? If so, please type 'DELETE' below."
    confirmWithText="DELETE"
    size="small"
    color="error"
    showCancelButton={false}
    onConfirm={async () => {
      await deleteChildStudy(value)
      if (schoolId) {
        await fetchEligibleChildStudyStudents(schoolId)
      }
    }}
  >
    <DeleteIcon />
  </ConfirmModalButton>
)

_DeleteAction.propTypes = {
  schoolId: PropTypes.number,
  deleteChildStudy: PropTypes.func.isRequired,
  fetchEligibleChildStudyStudents: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired
}

const DeleteAction = connect(
  state => ({
    schoolId: state.schools.schoolId
  }),
  {
    deleteChildStudy,
    fetchEligibleChildStudyStudents
  }
)(_DeleteAction)

const LastNameCell = ({ original }) => (
  <MuiLink
    component={RouterLink}
    to={`/student/${original.id}/child-study-process`}
    sx={{
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    }}
  >
    {original.last_name}
  </MuiLink>
)

LastNameCell.propTypes = {
  original: PropTypes.shape({
    id: PropTypes.number.isRequired,
    last_name: PropTypes.string.isRequired
  }).isRequired
}

const LastActionTakenAtCell = ({ value }) =>
  value ? moment(value).format('MM/DD/YYYY') : '--'

LastActionTakenAtCell.propTypes = {
  value: PropTypes.string.isRequired
}

const StartedOnCell = ({ value }) => moment(value).format('MM/DD/YYYY')

StartedOnCell.propTypes = {
  value: PropTypes.string.isRequired
}

const RecentStatus = ({ value }) => <ChildStudyStatusBadge status={value} />

RecentStatus.propTypes = {
  value: PropTypes.string.isRequired
}

const ActionsCell = ({ value }) => (
  <div>
    <DeleteAction {...{ value }} />
  </div>
)

ActionsCell.propTypes = {
  value: PropTypes.number.isRequired
}

// Functions
// takes the passed in columns (eg. ['first_name', 'id']) and generates actual
// table columns with the definitions above (eg. [{ accessor: 'first_name', sortable: true } ...])
const buildColumns = columns => {
  let key, columnDef
  return columns.map(column => {
    if (typeof column === 'string') {
      key = column
      columnDef = columns[column]
    } else if (column && typeof column.key === 'string') {
      key = column.key
      const definition = columns[column.key]
      columnDef = { ...definition, ...column } // merge object into def
    } else {
      // a custom column definition or nested columns
      if (column.columns) {
        // nested columns!
        column.columns = buildColumns(column.columns)
      }

      return column
    }

    if (!columnDef) {
      throw new Error(
        'Tried to generate students table with ' +
          `unrecognized column label: ${key}. Add the column ` +
          'definition in students-table/table or pass an object that ' +
          'represents the full custom column definition.'
      )
    }

    return columnDef
  })
}

const determineIncludesFromColumns = columns => {
  let includes = columns.map(column => {
    if (column.columns) {
      return [column.includes, determineIncludesFromColumns(column.columns)]
    } else {
      return column.includes
    }
  })

  return uniq(compact(flattenDeep(includes)))
}

const columns = [
  {
    id: 'last_name',
    Header: 'Last Name',
    accessor: row => row,
    minWidth: 150,
    width: 150,
    Cell: LastNameCell,
    sortMethod: (a, b) => {
      return a.last_name > b.last_name ? 1 : -1
    }
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
    minWidth: 150,
    width: 150
  },
  {
    Header: 'Grade Level',
    accessor: 'grade_level'
  },
  {
    Header: 'Last Action',
    accessor: 'last_action_taken_at',
    Cell: LastActionTakenAtCell
  },
  {
    Header: 'Started On',
    accessor: 'started_on',
    sortable: false,
    Cell: StartedOnCell
  },
  {
    Header: 'Meetings',
    accessor: 'meetings_count'
  },
  {
    Header: 'Concerns',
    accessor: 'concerns_count'
  },
  {
    Header: 'Started By',
    accessor: 'started_by',
    minWidth: 150,
    width: 150,
    sortable: false
  },
  {
    Header: 'Referral Category',
    accessor: 'concern_name',
    minWidth: 150,
    width: 150,
    sortable: false
  },
  {
    Header: 'Status',
    accessor: 'recent_status',
    Cell: RecentStatus
  },
  {
    Header: 'Actions',
    id: 'actions',
    sortable: false,
    accessor: obj => obj.child_study_id,
    Cell: ActionsCell
  }
]

const builtColumns = buildColumns(columns)

const columnApiIncludes = determineIncludesFromColumns(builtColumns)

const apiIncludes = columnApiIncludes.reduce((hash, include) => {
  hash[`include_${include}`] = true
  return hash
}, {})

export function ChildStudies({
  fetchStudents,
  filters,
  schoolId,
  schoolOptions,
  user
}) {
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [tierThreeMapChildStudyStatuses, setTierThreeMapChildStudyStatuses] =
    useState(null)

  const debouncedFetchStudents = useCallback(
    debounce(params => {
      setLoading(true)
      fetchStudents(params).then(() => setLoading(false))
    }, 300),
    []
  )

  const handleFetchChildStudies = attrs => {
    const params = {
      school_id: schoolId,
      include_child_study: true,
      has_child_study: true,
      search,
      ...filters,
      ...apiIncludes,
      ...attrs
    }

    debouncedFetchStudents(params)
  }

  useEffect(() => {
    handleFetchChildStudies()

    const params = { school_year: currentSchoolYear }

    if (schoolId) {
      get(urls.api.schoolMetrics(schoolId), { params }).then(data => {
        setTierThreeMapChildStudyStatuses(
          data.school_metric?.tier_three_map_receiving_child_study
        )
      })
    }
  }, [filters, search, schoolId])

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  const searchChanged = event => {
    setSearch(event.target.value)
  }

  const getSchoolLabel = () => {
    const school = schoolOptions.find(school => school.value === schoolId)
    return school ? school.label : null
  }

  const schoolName = getSchoolLabel()

  return (
    <Page name="Child-Studies" title="Child Studies">
      <PageContainer>
        <Stack>
          <Box>
            {schoolOptions.length > 1 && (
              <Stack direction="row" justifyContent="flex-end">
                <Box
                  id="test-school-selector"
                  sx={{
                    minWidth: '20rem'
                  }}
                >
                  <SchoolAutocomplete disableClearable />
                </Box>
              </Stack>
            )}
          </Box>

          <Box
            sx={{
              mb: 6
            }}
          >
            <Typography variant="h1" id="test_header_title">
              Child Studies
            </Typography>
            <Typography variant="h2" id="test_subheader_title">
              {schoolName || 'All Schools'}
            </Typography>
          </Box>

          <Box
            sx={{
              mb: 6
            }}
          >
            <Typography variant="body1">
              Below are students referred to school leadership for child study.
              To view or create concerns, visit the{' '}
              <a href="/wellness">Concerns</a> page. You can attach
              district-approved targeted interventions to academic/behavioral
              concerns.
            </Typography>
          </Box>

          <Stack spacing={2}>
            <NewChildStudyButton fetchChildStudies={handleFetchChildStudies} />

            <Box>
              <Stack direction={'row'} spacing={2}>
                {['principal', 'district'].includes(user.role) && (
                  <Button
                    variant="contained"
                    onClick={toggleModal}
                    startIcon={<GroupsIcon />}
                  >
                    Manage Team
                  </Button>
                )}
                <Button
                  variant="contained"
                  href="https://drive.google.com/drive/folders/1REnLxoEdBGuAwksaUyZjIVLoEnqZyCcQ"
                  target="_blank"
                  startIcon={<FolderIcon />}
                >
                  Forms
                </Button>
              </Stack>
            </Box>

            <Box
              sx={{
                width: '100%'
              }}
            >
              <StudentsTableFilters
                search={search}
                searchChanged={searchChanged}
                columns={columns}
              />
            </Box>
          </Stack>

          <Box>
            <ChildStudiesTable
              columns={columns}
              loading={loading}
              fetchData={handleFetchChildStudies}
            />
          </Box>
        </Stack>

        {tierThreeMapChildStudyStatuses && (
          <PaperBox
            title="Child Study Statuses for Students in Tier 3 MAP"
            helperText={{
              'MAP Update Frequency':
                'Three times per year (Fall/Winter/Spring)',
              'Child Study Status Update Frequency': 'Real-time/Live'
            }}
          >
            <PieChartFiveSlices
              one={{
                title: 'Active',
                value: tierThreeMapChildStudyStatuses.active,
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: schoolId,
                    view: 'MAP',
                    latest_map_reading_percentile_range: [1, 10],
                    child_study_status: 'active'
                  }
                }
              }}
              two={{
                title: 'On-Hold Teacher',
                value: tierThreeMapChildStudyStatuses.on_hold_teacher,
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: schoolId,
                    view: 'MAP',
                    latest_map_reading_percentile_range: [1, 10],
                    child_study_status: 'on_hold_teacher'
                  }
                }
              }}
              three={{
                title: 'Referred',
                value: tierThreeMapChildStudyStatuses.referred,
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: schoolId,
                    view: 'MAP',
                    latest_map_reading_percentile_range: [1, 10],
                    child_study_status: 'referred'
                  }
                }
              }}
              four={{
                title: 'Closed',
                value: tierThreeMapChildStudyStatuses.closed,
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: schoolId,
                    view: 'MAP',
                    latest_map_reading_percentile_range: [1, 10],
                    child_study_status: 'closed'
                  }
                }
              }}
              five={{
                title: 'Not Yet Referred',
                value: tierThreeMapChildStudyStatuses[''],
                options: {
                  pathname: '/students',
                  query_params: {
                    school_id: schoolId,
                    view: 'MAP',
                    latest_map_reading_percentile_range: [1, 10],
                    child_study_status: 'not_yet_referred'
                  }
                }
              }}
            />
          </PaperBox>
        )}

        {['principal', 'district'].includes(user.role) && (
          <ChildStudyTeamMemberModal
            onHide={toggleModal}
            schoolId={schoolId}
            showModal={showModal}
          />
        )}
      </PageContainer>
    </Page>
  )
}

ChildStudies.propTypes = {
  fetchStudents: PropTypes.func.isRequired,
  filters: PropTypes.object,
  schoolChanged: PropTypes.func.isRequired,
  schoolId: PropTypes.number,
  schoolOptions: PropTypes.array,
  user: PropTypes.shape({
    role: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }).isRequired
}

const mapStateToProps = state => ({
  filters: state.studentFilters.studentFilters,
  schoolId: state?.schools?.schoolId,
  schoolOptions: selectors.schoolOptions(state, {
    includeAllSchoolsOption: false
  }),
  user: state.currentUser.user
})

const mapDispatchToProps = {
  fetchStudents,
  schoolChanged: schoolActions.selectSchool
}

export default connect(mapStateToProps, mapDispatchToProps)(ChildStudies)
