import React from 'react'
import PropTypes from 'prop-types'
import { titleize } from 'utils'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Title,
  Subtitle,
  Legend,
  XAxis,
  YAxis,
  LineSeries,
  Tooltip
} from 'react-jsx-highcharts'

const DatasetsByDay = ({ type, data, schoolYear, schoolName }) => {
  if (!data || !data.length) {
    return (
      <div style={{ padding: '5em' }}>
        <h5 />
      </div>
    )
  }

  const pastTenseType = type === 'suspensions' ? 'Suspended' : 'Referred'
  let title, yTitle, tooltipContent

  if (data[0].metric === 'percent') {
    title = `% Unique ${titleize(type)} ${
      schoolYear ? '(' + schoolYear + ')' : ''
    }`
    yTitle = `% Unique Students ${pastTenseType} (cumulative)`
    tooltipContent = '{point.y:.1f}%'
  } else {
    title = `${titleize(type)} ${schoolYear ? '(' + schoolYear + ')' : ''}`
    yTitle = `Total ${titleize(type)} (cumulative)`
    tooltipContent = `{point.y} ${type}`
  }

  return (
    <div id={`js-${type}-line-chart`}>
      <HighchartsChart>
        <Chart />
        <Title>{title}</Title>
        <Subtitle>{schoolName}</Subtitle>
        <Legend layout="horizontal" align="center" verticalAlign="bottom" />
        <Tooltip
          headerFormat="<span style='font-size:16px;'>Day {point.x} of School</span><table>"
          pointFormat={
            '<tr><td style="color:{series.color};padding:0;font-size:14px;">{series.name}:&nbsp;</td>' +
            `<td style="padding:0;font-size:14px;"><b> ${tooltipContent}</b></td></tr>`
          }
          footerFormat="</table>"
          shared={true}
          crosshairs={true}
          useHTML={true}
        />
        <XAxis>
          <XAxis.Title>Day of School Year</XAxis.Title>
        </XAxis>

        <YAxis id="number">
          <YAxis.Title>{yTitle}</YAxis.Title>
        </YAxis>

        {data.map((series, index) => (
          <LineSeries
            id={`series-${index}`}
            name={series.name}
            data={series.data}
            key={index}
            marker={{ enabled: false }}
          />
        ))}
      </HighchartsChart>
    </div>
  )
}

DatasetsByDay.propTypes = {
  type: PropTypes.oneOf(['suspensions', 'referrals']).isRequired,
  data: PropTypes.array,
  schoolYear: PropTypes.number,
  schoolName: PropTypes.string
}

export default withHighcharts(DatasetsByDay, window.Highcharts)
