import { getBaseUrl, get } from 'utils/ajax'
import { alert } from 'utils/alert'

const getHistoricalGrades = (studentId, params = {}) => {
  return get(`${getBaseUrl()}/students/${studentId}/historical_grades`, {
    params
  }).catch(_err => {
    alert('An error occurred while getting historical grades.', 'danger')
  })
}

export default {
  getHistoricalGrades
}
