import { get, destroy, patch, showError } from 'utils/ajax'
import InfiniteActivityFeed from 'components/infinite_activity_feed'
import FeedItemContent from 'components/shared/activity-feed/feed-item-content'
import FeedItemHeader from 'components/shared/activity-feed/feed-item-header'
import moment from 'moment'
import NoteActions from './note-actions'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Box, Grid, Paper } from '@mui/material'
import NoteButton from 'components/students/show/note-button'
import StudentsLetterGeneratorButton from 'components/students/letter_generator_button'
import { titleize } from 'utils'

function sentimentToStatus(status) {
  if (status === 'positive') {
    return 'success'
  } else if (status === 'negative') {
    return 'danger'
  } else {
    return 'info'
  }
}

export default function Feed({ studentId }) {
  const [loading, setLoading] = useState(true)
  const [feedItems, setFeedItems] = useState([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [renderCount, setRenderCount] = useState(0)

  useEffect(() => {
    get(`/api/web/students/${studentId}/feed_items`, {
      params: { page: page, per_page: 10 }
    })
      .then(({ data, meta }) => {
        setFeedItems([...feedItems, ...data])
        setLoading(false)
        const isLastPage = meta.page + 1 >= meta.pages
        setHasMore(!isLastPage)
      })
      .catch(err => {
        setLoading(false)
        showError(
          'Something went wrong when we tried to load the activity feed',
          err
        )
      })
  }, [page, renderCount])

  const fetchNextPage = () => {
    if (!loading) {
      setLoading(true)
      setPage(page + 1)
    }
  }

  const refreshFeed = () => {
    if (!loading) {
      setLoading(true)
      setFeedItems([])
      setRenderCount(renderCount + 1)
    }
  }

  const StudentFeedItems = () => {
    return feedItems.map(feedItem => (
      <Paper key={feedItem.id} sx={{ m: 4 }}>
        <Box>
          <FeedItemHeader
            date={moment(feedItem.date)}
            status={sentimentToStatus(feedItem.sentiment)}
            category={feedItem.category}
            titleIcon={false}
            title={titleize(feedItem.category)}
          />

          <FeedItemContent content={feedItem.description} />

          {feedItem.category === 'note' && (
            <NoteActions
              noteId={feedItem.data_id}
              update={updateNote.mutate}
              destroy={destroyNote.mutate}
              noteOwnerId={feedItem.user_id}
              noteContent={feedItem.description}
              refreshFeed={refreshFeed}
            />
          )}
        </Box>
      </Paper>
    ))
  }

  const updateNote = useMutation(
    ({ noteId, comment }) =>
      patch(`/api/web/notes/${noteId}`, {
        comment
      }),
    {
      onError: (error, _variables, _context) => {
        showError('Something went wrong!', error)
      },
      onSettled: (_data, _variables, _context) => {
        refreshFeed()
      }
    }
  )

  const destroyNote = useMutation(
    noteId => destroy(`/api/web/notes/${noteId}`, {}),
    {
      onError: (error, _variables, _context) => {
        showError('Something went wrong!', error)
      },
      onSettled: (_data, _variables, _context) => {
        refreshFeed()
      }
    }
  )

  return (
    <React.Fragment>
      <Grid container spacing={2} sx={{ pb: 4 }}>
        <Grid item>
          <NoteButton studentId={studentId} refreshFeed={refreshFeed} />
        </Grid>
        <Grid item>
          <StudentsLetterGeneratorButton studentIds={[studentId]} />
        </Grid>
      </Grid>
      <InfiniteActivityFeed
        title="Feed"
        loading={loading}
        loadMore={fetchNextPage}
        hasMore={hasMore}
      >
        <StudentFeedItems />
      </InfiniteActivityFeed>
    </React.Fragment>
  )
}

Feed.propTypes = {
  studentId: PropTypes.number.isRequired
}
