import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import FormHelperText from '@mui/material/FormHelperText'

function ConcernCategory({
  value,
  onChange,
  options,
  isSubmitted,
  hasInterventions = false
}) {
  const isError = isSubmitted && !value

  return (
    <FormControl error={isError} fullWidth required>
      <InputLabel
        data-testid="parent-concern-label"
        id="concern-category-select"
      >
        Concern Category
      </InputLabel>
      <Select
        labelId="concern-category-select"
        label="Concern Category"
        value={value}
        onChange={val => {
          onChange(val.target.value)
        }}
        disabled={hasInterventions}
      >
        {options.map((option, i) => (
          <MenuItem
            key={i}
            data-testid={`parent-concern-${option.value}`}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {isError && <FormHelperText>Concern Category is Required</FormHelperText>}
    </FormControl>
  )
}

ConcernCategory.propTypes = {
  value: PropTypes.oneOfType([PropTypes.oneOf(['']), PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  isSubmitted: PropTypes.bool,
  hasInterventions: PropTypes.bool
}

export default ConcernCategory
