import SectionsApi from './api'
import { types } from './reducer'

export const fetchSections = (schoolId, params) => {
  return dispatch => {
    return SectionsApi.getSections(schoolId, params).then(data => {
      dispatch({ type: types.FETCH_SECTIONS, payload: data })
    })
  }
}

export const setSectionSelections = sections => {
  return dispatch => {
    dispatch({ type: types.SET_SECTIONS_LIST, payload: sections })
    return Promise.resolve()
  }
}
