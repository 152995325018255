import React from 'react'
import PropTypes from 'prop-types'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Legend,
  XAxis,
  YAxis,
  LineSeries,
  Title,
  Tooltip
} from 'react-jsx-highcharts'
import { toNumber } from 'lodash'
import moment from 'moment'

function ScoreChart({ scores, target, targetDate }) {
  return (
    <HighchartsChart id="js-attendance-line-chart">
      <Title>{`Target Date: ${moment(targetDate).format('MM/DD/YYYY')}`}</Title>
      <Chart />
      <Legend
        layout="horizontal"
        align="center"
        verticalAlign="bottom"
        borderWidth={0}
      />
      <Tooltip shared={true} crosshairs={true} useHTML={true} />
      <XAxis
        type="category"
        categories={scores?.map(score =>
          moment(score.event_on).format('MM/DD/YYYY')
        )}
      >
        <XAxis.Title>Date</XAxis.Title>
      </XAxis>
      <YAxis id="number">
        <YAxis.Title>Value</YAxis.Title>
      </YAxis>
      <LineSeries
        id={'score'}
        name={'Value'}
        data={scores?.map(score => toNumber(score.score))}
        marker={{ enabled: true }}
      />
      <LineSeries
        id={'target'}
        name={'Target'}
        data={scores?.map(() => toNumber(target))}
        marker={{ enabled: true }}
      />
    </HighchartsChart>
  )
}

ScoreChart.propTypes = {
  scores: PropTypes.array.isRequired,
  target: PropTypes.number.isRequired,
  targetDate: PropTypes.string.isRequired
}

export default withHighcharts(ScoreChart, window.Highcharts)
