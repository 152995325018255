import { post } from 'utils/ajax'
import { alert } from 'utils/alert'

export const updateChildStudyStatus = (childStudyId, userId, status) => {
  return post('/api/web/child_study_statuses/', {
    child_study_status: {
      child_study_id: childStudyId,
      user_id: userId,
      status: status
    }
  }).catch(_err => {
    alert('An error occurred while updating the child study status', 'danger')
  })
}
