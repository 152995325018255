import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Alert
} from '@mui/material'
import DistrictGoalSettingForm from './district_goal_form'
import { fetchDistrictGoals } from 'api/admin/district_goals'
import Dialog from 'shared/dialog'

function DistrictGoalsModal(props) {
  const [districtGoalSettings, setDistrictGoalSettings] = useState([])
  const [showAlert, setShowAlert] = useState('hidden')
  const [alertMessage, setAlertMessage] = useState('')
  const { show, onHide } = props

  useEffect(() => {
    fetchDistrictGoals()
      .then(res => {
        setDistrictGoalSettings(res)
      })
      .catch(() => {
        alert('There was an error changing the district goal.', 'danger')
      })
  }, [props])

  const handleAlertMessage = message => {
    setAlertMessage(message)
  }

  const showTheAlert = message => {
    setShowAlert(message)
  }

  const resetModal = () => {
    if (showAlert !== 'hidden') {
      setShowAlert('hidden')
    }
  }
  return (
    <Dialog
      open={show}
      onClose={onHide}
      onShow={resetModal}
      title="District Goals"
    >
      <Box>
        <Alert className={showAlert} severity="error">
          {alertMessage}
        </Alert>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Goal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {districtGoalSettings &&
              districtGoalSettings.map((goal, i) => (
                <DistrictGoalSettingForm
                  key={i}
                  category={goal.category}
                  goal={goal.value}
                  year={goal.year}
                  fetchDistrictGoalSettings={fetchDistrictGoals}
                  alertMessage={handleAlertMessage}
                  showAlert={showTheAlert}
                />
              ))}
          </TableBody>
        </Table>
      </Box>
    </Dialog>
  )
}

export default DistrictGoalsModal

DistrictGoalsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired
}
