import JobApi from './api'
import { types } from './reducer'

export const fetchJobsTableData = params => {
  return dispatch => {
    return JobApi.getJobs(params).then(data => {
      dispatch({ type: types.FETCH_JOBS, payload: data })
    })
  }
}

export const fetchJobRoles = () => {
  return dispatch => {
    return JobApi.getJobRoles().then(data => {
      dispatch({ type: types.FETCH_JOB_ROLES, payload: data })
    })
  }
}
