import { get } from 'utils/ajax'

export const fetchDistrictOverview = params => {
  return get('/api/web/district_overview', { params })
}

export const fetchDistrictSchools = params => {
  return get('/api/web/district_overview/schools', { params })
}

export const fetchDistrictFoundationalMetrics = params => {
  return get('/api/web/district_overview/foundational_metrics', { params })
}

export const fetchDistrictAcademicInterventions = params => {
  return get('/api/web/district_overview/academic_interventions', { params })
}

export const fetchDistrictTulsaWayObservations = params => {
  return get('/api/web/district_overview/tulsa_way_observations', { params })
}

export const fetchDistrictMtss = params => {
  return get('/api/web/district_overview/mtss', { params })
}

export const fetchDistrictTeamMeetings = params => {
  return get('/api/web/district_overview/team_meetings', { params })
}
