import React from 'react'
import PropTypes from 'prop-types'
import RequirementTitle from './requirement/title'
import RequirementBody from './requirement/body'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'

const BoxHeightRestricted = styled(Box)(() => ({
  minHeight: '10rem',
  maxHeight: '10rem',
  overflowY: 'auto',
  paddingY: 4,
  paddingX: 2
}))

export function Feed({
  activeId,
  activeModal,
  invalidateChildStudyRequirements,
  requirements,
  setActiveId,
  setActiveModal,
  student
}) {
  return (
    <Stack spacing={2}>
      <Typography variant="h3">Child Study Team Activity</Typography>
      <Box
        id="childStudyActivity"
        sx={{
          p: 4
        }}
      >
        <Stack spacing={2}>
          {requirements.map(requirement => (
            <Paper sx={{ px: 2, py: 4 }} key={requirement.id}>
              <RequirementTitle requirement={requirement} />
              <BoxHeightRestricted
                id={`requirement-panel ${requirement.data_type}-${requirement.data_id}`}
                data-data-type={requirement.data_type}
                data-data-id={requirement.data_id}
              >
                <RequirementBody
                  activeId={activeId}
                  activeModal={activeModal}
                  fetchRequirements={invalidateChildStudyRequirements}
                  requirement={requirement}
                  setActiveId={setActiveId}
                  setActiveModal={setActiveModal}
                  student={student}
                />
              </BoxHeightRestricted>
            </Paper>
          ))}
        </Stack>
      </Box>
    </Stack>
  )
}

Feed.propTypes = {
  activeId: PropTypes.number,
  activeModal: PropTypes.string.isRequired,
  invalidateChildStudyRequirements: PropTypes.func.isRequired,
  requirements: PropTypes.array.isRequired,
  setActiveId: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired
}

export default Feed
