import React from 'react'

function getElement() {
  const elements = document.getElementsByClassName('freshwidget-theme') || []

  return elements[0]
}

export function DisableFreshWidget() {
  React.useEffect(() => {
    const element = getElement()

    if (element) {
      element.style.display = 'none'
    }

    return () => {
      if (element) {
        element.style.display = ''
      }
    }
  }, [])

  return null
}

export default DisableFreshWidget
