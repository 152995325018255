import { getBaseUrl, get } from 'utils/ajax'
import { alert } from 'utils/alert'

export const fetchStudentMaps = (studentId, params = {}) => {
  return get(`${getBaseUrl()}/students/${studentId}/maps`, { params }).catch(
    _err => {
      alert('There was an error loading student data.', 'danger')
    }
  )
}
