import { getChildStudyReferral } from 'api/child-study-referrals'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export function useChildStudyReferral({ childStudyId, studentId, options }) {
  const queryKey = ['child-study-referral', childStudyId, studentId]
  const queryFn = () => getChildStudyReferral(childStudyId, studentId)
  const query = useQuery(queryKey, queryFn, {
    enabled: Boolean(childStudyId) && Boolean(studentId),
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  const data = query.data

  return { data, query, invalidate }
}
