import React from 'react'
import PropTypes from 'prop-types'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Title,
  XAxis,
  YAxis,
  ColumnSeries,
  Tooltip
} from 'react-jsx-highcharts'
import {
  fetchGroupCategories,
  humanizeCategories
} from 'utils/demographic-types'
import { calculatePercentage } from 'utils'
import { sum } from 'lodash'

const StatusChart = ({ data, groupBy }) => {
  const statuses = ['On Track', 'Off Track', 'At Risk', 'High Risk']
  const plotOptions = {
    series: {
      stacking: 'normal'
    }
  }
  const categories = fetchGroupCategories(groupBy)

  function returnPercent(category, risk) {
    if (data[category]) {
      const part = data[category][risk]
      const whole = sum(Object.values(data[category]))
      return parseFloat(calculatePercentage(part, whole, 1))
    }
    return null
  }

  return (
    <div
      id="graduation-status-chart"
      className="panel panel-default"
      style={{ minHeight: '400px' }}
    >
      <div className="panel-body">
        <HighchartsChart plotOptions={plotOptions}>
          <Chart />
          <Title>Graduation Statuses</Title>

          <XAxis
            id="x"
            categories={categories.map(c => humanizeCategories(c))}
          ></XAxis>

          <Tooltip
            headerFormat="<span style='font-size:16px;'>{point.x}</span><table>"
            pointFormat={
              '<tr><td style="color:{series.color};padding:0;font-size:14px;">{series.name}:&nbsp;</td>' +
              '<td style="padding:0;font-size:14px;"><b> {point.y}%</b></td></tr>'
            }
            footerFormat="</table>"
            crosshairs={true}
            shared={true}
            useHTML={true}
          />

          <YAxis id="number" max={100} labels={{ format: '{value}%' }}>
            {statuses.map((status, i) => (
              <ColumnSeries
                key={i}
                id={status}
                name={status}
                data={categories.map(c => returnPercent(c, status))}
              />
            ))}
          </YAxis>
        </HighchartsChart>
      </div>
    </div>
  )
}

StatusChart.propTypes = {
  data: PropTypes.object,
  groupBy: PropTypes.string
}

export default withHighcharts(StatusChart, window.Highcharts)
