import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Menu from '@mui/material/Menu'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Typography from '@mui/material/Typography'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { isNull } from 'lodash'
import { anonymousMode, destroyAnonymousMode } from 'api/devise'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService'
import LogoutIcon from '@mui/icons-material/Logout'
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'
import { withOptions } from 'utils/contexts/options'
import MenuItemLink from './menu-item-link'

function ProfileDropdown({ isAnonymousMode }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const user = useSelector(state => state.currentUser.user)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    if (isNull(anchorEl)) {
      setAnchorEl(event.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="show 4 new mails"
        color="inherit"
        id="js-user-dropdown-link"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        {user.role === 'district' && (
          <MenuItemLink href={'/admins'}>
            <ListItemIcon>
              <HomeRepairServiceIcon />
            </ListItemIcon>
            <ListItemText>
              <Typography>Admin Tools</Typography>
            </ListItemText>
          </MenuItemLink>
        )}
        {user.role === 'district' && isAnonymousMode && (
          <MenuItem onClick={() => destroyAnonymousMode()}>
            <ListItemIcon>
              <VisibilityIcon />
            </ListItemIcon>
            <ListItemText id="js-show-data-link">
              <Typography>Show Sensitive data</Typography>
            </ListItemText>
          </MenuItem>
        )}
        {user.role === 'district' && !isAnonymousMode && (
          <MenuItem onClick={() => anonymousMode()}>
            <ListItemIcon>
              <VisibilityOffIcon />
            </ListItemIcon>
            <ListItemText id="js-mask-data-link">
              <Typography>Mask Sensitive Data</Typography>
            </ListItemText>
          </MenuItem>
        )}
        <MenuItemLink
          target={'_blank'}
          href={
            'https://support.tulsaschools.org/support/solutions/folders/16000021631'
          }
        >
          <ListItemIcon>
            <HelpRoundedIcon />
          </ListItemIcon>
          <ListItemText>Help Center</ListItemText>
        </MenuItemLink>
        <MenuItemLink href={'/signout'}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>Logout</Typography>
          </ListItemText>
        </MenuItemLink>
      </Menu>
    </React.Fragment>
  )
}

ProfileDropdown.propTypes = {
  isAnonymousMode: PropTypes.bool
}

export default withOptions(ProfileDropdown)
