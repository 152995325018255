import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material'

import { TextareaAutosize as TextAreaInput } from '@mui/base/TextareaAutosize'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { MobileDateTimePicker as DateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker'

import { useField } from 'formik'
import { debounce } from 'lodash'
import { useLocation } from 'react-router-dom'

const FormTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)

  const [field, meta, { setValue }] = useField(props, { setValue })
  const [fastFieldValue, setFastFieldValue] = useState(field.value)

  const debouncedSetState = useCallback(
    debounce(newValue => {
      setValue(newValue)
    }, 300),
    []
  )

  const handleInputChange = event => {
    const { value: newValue } = event.target
    setFastFieldValue(newValue)
    debouncedSetState(newValue)
  }

  return (
    <>
      <TextField
        variant="standard"
        value={fastFieldValue}
        {...props}
        label={label}
        onChange={handleInputChange}
        error={meta.touched && meta.error ? true : null}
        helperText={meta.touched && meta.error ? meta.error : null}
      />
      <input type="hidden" {...field} />
    </>
  )
}

const FormHiddenInput = props => {
  const [field, _] = useField(props)
  return <input type="hidden" {...field} />
}

const FormTextAreaInput = ({ label, ...props }) => {
  const [field, meta, { setValue }] = useField(props, { setValue })
  const [fastFieldValue, setFastFieldValue] = useState(field.value)

  const debouncedSetState = useCallback(
    debounce(newValue => {
      setValue(newValue)
    }, 300),
    []
  )

  const handleInputChange = event => {
    const { value: newValue } = event.target
    setFastFieldValue(newValue)
    debouncedSetState(newValue)
  }

  return (
    <FormControl>
      <FormGroup>
        <FormLabel>{label}</FormLabel>
        <TextAreaInput
          value={fastFieldValue}
          minRows={4}
          variant="standard"
          {...props}
          label={label}
          error={meta.touched && meta.error ? true : null}
          onChange={handleInputChange}
        />
        <input type="hidden" {...field} />
      </FormGroup>
      <FormHelperText error>{meta.error ? meta.error : null}</FormHelperText>
    </FormControl>
  )
}

const FormCheckbox = ({ children, ...props }) => {
  // React treats radios and checkbox inputs differently from other input types: select and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: 'checkbox' })
  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox {...field} {...props} value={field.checked} />}
          label={children}
        />
      </FormGroup>
      <FormHelperText error>{meta.error ? meta.error : null}</FormHelperText>
    </FormControl>
  )
}

const FormSelect = ({
  label,
  items,
  chips,
  checkboxes,
  children,
  ...props
}) => {
  const [field, meta] = useField(props)

  const renderChips = selected => (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
      {selected.map(value => (
        <Chip key={value} label={value} />
      ))}
    </Box>
  )

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        {...props}
        {...(chips ? { renderValue: selected => renderChips(selected) } : {})}
        value={field.value || ''}
        input={<OutlinedInput label={label} />}
      >
        {children
          ? children
          : items.map(item =>
              checkboxes ? (
                <MenuItem key={`${label}-${item.value}`} value={item.value}>
                  <Checkbox checked={field.value?.indexOf(item.value) > -1} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ) : (
                <MenuItem key={`${label}-${item.value}`} value={item.value}>
                  {item.label}
                </MenuItem>
              )
            )}
      </Select>
      <FormHelperText error>
        {meta.touched && meta.error ? meta.error : null}
      </FormHelperText>
    </FormControl>
  )
}

const FormDatePicker = ({ label, ...props }) => {
  const [field, meta, { setValue }] = useField(props, { setValue })
  return (
    <FormControl>
      <FormGroup>
        <DatePicker
          slotProps={{
            textField: {
              variant: 'outlined',
              name: props.name,
              error: meta.error ? meta.error : null
            }
          }}
          label={label}
          type="date"
          className="page-break-avoid"
          {...field}
          {...props}
          onChange={val => {
            setValue(val)
          }}
        />
      </FormGroup>
      <FormHelperText error>{meta.error ? meta.error : null}</FormHelperText>
    </FormControl>
  )
}
FormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.any
}

const FormDateTimePicker = ({ label, ...props }) => {
  const [field, meta, { setValue }] = useField(props, { setValue })
  return (
    <FormControl>
      <FormGroup>
        <DateTimePicker
          slotProps={{
            textField: {
              variant: 'outlined',
              name: props.name,
              error: meta.error ? meta.error : null
            }
          }}
          className="page-break-avoid"
          label={label}
          type="datetime-local"
          {...field}
          {...props}
          onChange={val => {
            setValue(val)
          }}
        />
      </FormGroup>
      <FormHelperText error>{meta.error ? meta.error : null}</FormHelperText>
    </FormControl>
  )
}
FormDateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.any
}

const setLocale = () => {
  const location = useLocation()
  const queryString = new URLSearchParams(location.search)
  I18n.locale = queryString.has('locale') ? queryString.get('locale') : 'en'
}

FormTextInput.propTypes = {
  label: PropTypes.string
}

FormTextAreaInput.propTypes = {
  label: PropTypes.string
}

FormCheckbox.propTypes = {
  children: PropTypes.node
}

FormSelect.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  chips: PropTypes.bool,
  checkboxes: PropTypes.bool,
  children: PropTypes.node
}

FormDatePicker.propTypes = {
  label: PropTypes.string
}

FormDateTimePicker.propTypes = {
  label: PropTypes.string
}

export {
  FormTextInput,
  FormTextAreaInput,
  FormSelect,
  FormCheckbox,
  FormDatePicker,
  FormDateTimePicker,
  FormHiddenInput,
  setLocale
}
