import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

const ClearButton = React.memo(({ clearable, onClick }) => {
  if (!clearable) {
    return null
  }

  const handleClick = e => {
    e.preventDefault()
    onClick()
  }

  return (
    <Button
      onClick={handleClick}
      variant="text" // or "outlined" if you prefer
      color="primary"
      size="small"
      sx={{ ml: 1 }} // margin-left
    >
      Clear
    </Button>
  )
})

ClearButton.propTypes = {
  clearable: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default ClearButton
