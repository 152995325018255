import React from 'react'
import PropTypes from 'prop-types'
import { Box, colors } from '@mui/material'

function ProgressBar({ stored = 0, enrolled = 0 }) {
  return (
    <Box
      sx={{
        height: '20px',
        backgroundColor: colors.grey[300],
        width: '100%',
        display: 'flex'
      }}
    >
      {stored > 0 && (
        <Box
          sx={{
            height: '100%',
            backgroundColor: colors.green[700],
            width: `${stored * 100}%`
          }}
        />
      )}
      {enrolled > 0 && (
        <Box
          sx={{
            height: '100%',
            backgroundColor: colors.green[300],
            width: `${enrolled * 100}%`
          }}
        />
      )}
    </Box>
  )
}
ProgressBar.propTypes = {
  stored: PropTypes.number,
  enrolled: PropTypes.number
}

export default ProgressBar
