import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import StudentsTable from 'components/shared/students-table/table' // different from importing from index

export function ChildStudiesTable({
  students,
  studentsMeta,
  columns,
  fetchData
}) {
  return (
    <StudentsTable
      data={students}
      columns={columns}
      totalPages={studentsMeta?.pages}
      fetchData={fetchData}
    />
  )
}

ChildStudiesTable.propTypes = {
  students: PropTypes.array.isRequired,
  studentsMeta: PropTypes.object,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchData: PropTypes.func
}
const mapStateToProps = state => ({
  students: state.students.students,
  studentsMeta: state.students.meta
})

export default connect(mapStateToProps)(ChildStudiesTable)
