import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from 'components/shared/dialog'
import DialogContent from '@mui/material/DialogContent'

export function WellnessTeamDialog({
  children,
  open = false,
  onClose = () => {}
}) {
  const [isOpen, setIsOpen] = useState(false)

  function handleClose() {
    setIsOpen(false)
    onClose()
  }

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  return (
    <Dialog
      title={'Manage Concern Visibility'}
      onClose={handleClose}
      aria-labelledby="manage-concern-visibility"
      open={isOpen}
      maxWidth={'lg'}
    >
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )
}

WellnessTeamDialog.propTypes = {
  children: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
}

export default WellnessTeamDialog
