import React from 'react'
import { titleize } from 'utils'
import PropTypes from 'prop-types'
import EditGoalModal from './edit-goal-modal'
import { RemoteTable } from 'components/shared/table'
import { IconButton, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'

export default class AdminGoalsTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    fetchData: PropTypes.func,
    loading: PropTypes.bool,
    totalPages: PropTypes.number,
    goalTypes: PropTypes.array,
    goalCategories: PropTypes.array
  }

  state = {
    openModal: false
  }

  handleModalHide = () => {
    this.setState({ openModal: false })
  }

  openModalWithGoal = goal => {
    this.setState({
      activeGoal: goal,
      openModal: true
    })
  }

  columns = () => {
    return [
      {
        id: 'school_name',
        Header: 'School',
        sortable: false,
        accessor: obj => obj,
        Cell: props => (
          <a
            id="js-admin-view-goal"
            href={`/admins/schools/${props.value.school_id}`}
          >
            {titleize(props.value.school_name)}
          </a>
        )
      },
      {
        id: 'year',
        Header: 'Year',
        accessor: obj => obj.year
      },
      {
        id: 'category',
        Header: 'Category',
        accessor: obj => titleize(obj.category)
      },
      {
        id: 'type',
        Header: 'Type',
        sortable: false,
        accessor: obj => (obj.goal_type ? titleize(obj.goal_type) : 'None')
      },
      {
        id: 'value',
        Header: 'Value',
        accessor: obj => obj.value,
        Cell: props => (props.value ? props.value : 'None')
      },
      {
        id: 'comment',
        Header: 'Comment',
        accessor: obj => obj.comment
      },
      {
        Header: 'Actions',
        id: 'actions',
        sortable: false,
        accessor: obj => obj,
        Cell: props => (
          <Tooltip placement="top" arrow title="Edit">
            <IconButton
              onClick={() => this.openModalWithGoal(props.value)}
              color="secondary"
              id="js-edit-goal-modal"
              aria-label="create"
            >
              <CreateIcon />
            </IconButton>
          </Tooltip>
        )
      }
    ]
  }

  render() {
    const {
      data,
      loading,
      fetchData,
      totalPages,
      goalTypes,
      goalCategories,
      ...props
    } = this.props
    const { activeGoal, openModal } = this.state
    return (
      <div id="goal-table">
        {activeGoal && (
          <EditGoalModal
            key={activeGoal.id}
            show={openModal}
            goal={activeGoal}
            onHide={this.handleModalHide}
            fetchData={fetchData}
            goalTypes={goalTypes}
            goalCategories={goalCategories}
          />
        )}
        <RemoteTable
          columns={this.columns()}
          data={data}
          loading={loading}
          onFetchData={params => fetchData(params)}
          pages={totalPages}
          {...props}
        />
      </div>
    )
  }
}
