import { get, post } from 'utils/ajax'
import { alert } from 'utils/alert'

export const sendStudentEmail = async (id, showAlert = true) => {
  try {
    return await post(`/api/web/students/${id}/grad_planner_emails`)
  } catch (error) {
    if (showAlert) {
      alert('There was an error sending the email', 'danger')
    }

    throw error
  }
}

export const getPrimaryGradPlanOptions = () => {
  return get('/api/web/ps/grad_plans/options.json')
}
