import { titleize } from 'utils'
import { orderBy } from 'lodash'

export const convertObjectToReactTableFormat = (
  object,
  sortBy,
  sortDirection
) => {
  return Object.keys(object).length === 0
    ? []
    : orderBy(Object.values(object), sortBy, sortDirection)
}

export const getReactSelectFormat = (object, _key = null, _value = null) => {
  let reactOptions
  if (Array.isArray(object)) {
    reactOptions = Object.keys(object).map(object => ({
      [titleize(object[_key])]: object[_value]
    }))
  } else {
    reactOptions = Object.keys(object).map((_key, _value) => ({
      label: titleize(_key),
      value: _value
    }))
  }
  reactOptions.sort((a, b) => a.label.localeCompare(b.label))
  return reactOptions
}
