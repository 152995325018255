import React from 'react'
import PropTypes from 'prop-types'
import BehaviorRiskTable from 'components/students/behavior-risk-table'
import Tooltip from 'components/shared/tooltip'
import { Chip, Typography } from '@mui/material'

const RiskScoreHeader = ({ isParentPortal, riskScore }) => {
  const renderRiskScoreBadge = riskScore => {
    if (riskScore >= 5.0) {
      return <Chip color="error" label={I18n.jsT('badges.critical')} />
    } else if (riskScore >= 1.0 && riskScore < 5.0) {
      return <Chip color="warning" label={I18n.jsT('badges.high_risk')} />
    } else if (riskScore > 0 && riskScore < 1.0) {
      return <Chip color="info" label={I18n.jsT('badges.at_risk')} />
    } else if (riskScore === 0.0) {
      return <Chip color="success" label={I18n.jsT('badges.low_risk')} />
    } else null
  }

  return (
    <Typography variant="h4">
      {I18n.jsT('titles.current_risk_score')}: {riskScore}
      {renderRiskScoreBadge(riskScore)}
      {!isParentPortal && (
        <Tooltip
          id="risk-score"
          title="Behavior Risk Score Thresholds"
          content={<BehaviorRiskTable />}
          placement="right"
        />
      )}
    </Typography>
  )
}

RiskScoreHeader.propTypes = {
  isParentPortal: PropTypes.bool,
  riskScore: PropTypes.number
}

export default RiskScoreHeader
