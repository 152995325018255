import React from 'react'
import PropTypes from 'prop-types'
import ButtonGroup from '@mui/material/ButtonGroup'
import Button from '@mui/material/Button'
import { developmentAreaShape } from './prop-types'
import { groupBy } from 'lodash'
import DescriptionIcon from '@mui/icons-material/Description'
import Avatar from '@mui/material/Avatar'

const DevelopmentAreaScale = ({
  developmentArea,
  developmentAreaRated,
  masteryLevelId = null
}) => {
  const { indicators } = developmentArea
  const groupedMasteryLevels = groupBy(indicators, 'mastery_level.id')
  const masteryLevels = Object.values(groupedMasteryLevels).map(
    ([{ mastery_level }]) => mastery_level
  )

  const determineColor = id => (id === masteryLevelId ? 'primary' : 'inherit')

  const handleChange = masteryLevel => {
    developmentAreaRated &&
      developmentAreaRated(developmentArea.id, masteryLevel?.id)
  }

  if (!developmentAreaRated) {
    const masteryLevel = masteryLevels.find(({ id }) => id === masteryLevelId)
    return <Avatar key={masteryLevel.id}>{masteryLevel.weight}</Avatar>
  }

  return (
    <ButtonGroup variant="contained" fullWidth>
      <Button onClick={() => handleChange(null)} color={determineColor(null)}>
        <DescriptionIcon />
      </Button>
      {masteryLevels.map(masteryLevel => (
        <Button
          key={masteryLevel.id}
          onClick={() => handleChange(masteryLevel)}
          color={determineColor(masteryLevel.id)}
        >
          {masteryLevel.weight}
        </Button>
      ))}
    </ButtonGroup>
  )
}

DevelopmentAreaScale.propTypes = {
  developmentArea: developmentAreaShape.isRequired,
  developmentAreaRated: PropTypes.func,
  masteryLevelId: PropTypes.number
}

export default DevelopmentAreaScale
