import { post, patch, destroy } from 'utils/ajax'
import { alert } from 'utils/alert'

export const destroyChildStudyForm = (id, callback) => {
  return destroy(`/api/web/child_study_forms/${id}`, {})
    .catch(_err => {
      alert('There was an error destroying the upload.', 'danger')
    })
    .then(() => callback())
}

export const createChildStudyForm = data => {
  return post('/api/web/child_study_forms', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 30000
  }).catch(() => {
    alert('There was an error uploading the form.', 'danger')
  })
}

export const updateChildStudyForm = (id, data) => {
  return patch(`/api/web/child_study_forms/${id}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    timeout: 30000
  }).catch(() => {
    alert('There was an error uploading the form.', 'danger')
  })
}
