import React from 'react'
import PropTypes from 'prop-types'
import { withOptions } from 'utils/contexts/options'
import { alert } from 'utils/alert'

export function FlashMessages({ flashMessages }) {
  React.useEffect(() => {
    flashMessages?.map(({ text, type }) => {
      alert(text, type)
    })
  }, [])

  return null
}

FlashMessages.propTypes = {
  flashMessages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.any.isRequired,
      type: PropTypes.string
    })
  )
}

export default withOptions(FlashMessages)
