import { destroyChildStudyForm } from 'api/child-study-forms'
import { RequirementPropType } from 'modules/requirements'
import AccessChecker from 'utils/child-study-team-user-access-checker'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import UploadFormModal from '../action-modals/upload-form'
import ConfirmModalButton from 'components/shared/confirm-modal/button'
import {
  Box,
  Button,
  Grid,
  Stack,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Tooltip,
  Typography
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const UploadFormRequirement = ({
  activeId,
  activeModal,
  fetchRequirements,
  handleCloseAndRefreshFeed,
  setActiveId,
  setActiveModal,
  requirement,
  student,
  user
}) => {
  const [previewOpen, setPreviewOpen] = useState(false)

  const categoryTable = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan="2">Form Questions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(requirement.contents).map((question, index) => (
              <TableRow key={index}>
                <TableCell>{question}</TableCell>
                <TableCell>{requirement.contents[question]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  )

  const fileLink = () => {
    return (
      requirement.file_url && (
        <Button
          href={requirement.file_url}
          onClick={e => {
            e.preventDefault()
            window.open(requirement.file_url, '_blank')
          }}
          title={'Click to download'}
        >
          Download
        </Button>
      )
    )
  }

  const imagePreview = () => {
    return (
      requirement.file_url &&
      requirement.file_type &&
      requirement.file_type.match(/^image\//) && (
        <Tooltip id="overlay-trigger-preview" placement="top" title="Preview">
          <img
            src={requirement.file_url}
            alt="upload preview"
            className={previewOpen ? 'img' : 'img mini'}
            onClick={() => setPreviewOpen(!previewOpen)}
          />
        </Tooltip>
      )
    )
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          {requirement.file_url && (
            <Box>
              <Typography variant="overline">File: </Typography>
              {fileLink()}
            </Box>
          )}
          <Box>
            <Typography variant="overline">Category: </Typography>
            {requirement.text}
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {imagePreview()}
        </Grid>
      </Grid>

      {requirement.contents && categoryTable()}

      <Stack direction="row" justifyContent="center">
        {AccessChecker.checkUserEditAccess(requirement, user, student) && (
          <Button
            startIcon={<EditIcon />}
            onClick={() =>
              setActiveModal('uploadForm') & setActiveId(requirement.data_id)
            }
            color="secondary"
          >
            Edit
          </Button>
        )}
        {AccessChecker.checkUserDestroyAccess(requirement, user, student) && (
          <ConfirmModalButton
            modalMessage="Are you sure you want to delete this file?"
            showCancelButton={false}
            onConfirm={() =>
              destroyChildStudyForm(requirement.data_id, fetchRequirements)
            }
            startIcon={<DeleteIcon />}
            color="error"
            variant="outlined"
          >
            Delete
          </ConfirmModalButton>
        )}
      </Stack>

      <UploadFormModal
        show={activeModal == 'uploadForm' && activeId == requirement.data_id}
        onHide={handleCloseAndRefreshFeed}
        formId={requirement.data_id}
        childStudyId={requirement.child_study_id}
        category={requirement.category}
        name={requirement.filename}
        file_type={requirement.file_type}
        file_url={requirement.file_url}
        editing
        accept={[
          ['.doc', 'application/msword'],
          [
            '.docx',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          ],
          ['.jpg', '.jpeg', '.jpe', 'image/jpeg'],
          ['.pdf', 'application/pdf'],
          ['.png', 'image/png']
        ]}
      />
    </React.Fragment>
  )
}

UploadFormRequirement.propTypes = {
  activeId: PropTypes.number,
  activeModal: PropTypes.string.isRequired,
  fetchRequirements: PropTypes.func.isRequired,
  requirement: RequirementPropType.isRequired,
  setActiveId: PropTypes.func.isRequired,
  setActiveModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  handleCloseAndRefreshFeed: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired
}

export default UploadFormRequirement
