import React from 'react'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'
import { pick } from 'lodash'
import { titleize } from 'utils'
import { showError } from 'utils/errors'
import { fetchTeachersForCsvReport } from 'api/teachers'
import { Box, Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import DownloadingIcon from '@mui/icons-material/Downloading'

export default class TeachersCsvLink extends React.Component {
  static propTypes = {
    schoolId: PropTypes.number
  }

  csvLink = React.createRef()
  state = { teachers: [], loading: false }

  handleFetchTeachersForCsvReport = () => {
    this.setState({ loading: true })
    fetchTeachersForCsvReport(this.props.schoolId)
      .then(teachers => {
        this.setState(
          { teachers: this.mapTeachers(teachers), loading: false },
          () => {
            this.csvLink.current.link.click()
          }
        )
      })
      .catch(err => {
        showError('An error occurred when loading teachers', err)
        this.setState({ loading: false })
      })
  }

  mapTeachers(teachers) {
    return teachers.map(t => {
      const teacher = pick(t, [
        'school_id',
        'employee_id',
        'last_name',
        'first_name',
        'attendance_percent',
        'avoidable_absences',
        'attendance_percent_excluding_unavoidable',
        'referrals_count'
      ])

      return teacher
    })
  }

  headers(teachers) {
    if (!teachers || !teachers.length) return []
    const headers = Object.keys(teachers[0]).map(key => ({
      label: titleize(key),
      key
    }))

    return headers
  }

  render() {
    const { loading, teachers } = this.state
    const headers = this.headers(teachers)

    return (
      <Box>
        <Button
          size="large"
          variant="contained"
          onClick={this.handleFetchTeachersForCsvReport}
          disabled={loading}
          startIcon={loading ? <DownloadingIcon /> : <DownloadIcon />}
        >
          CSV
        </Button>

        <CSVLink
          data={teachers}
          headers={headers}
          filename="teachers.csv"
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
      </Box>
    )
  }
}
