import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import ExternalReferralModal from './modal'

function ExternalReferral({ concern }) {
  const [showExternalReferral, setShowExternalReferral] = useState(false)

  const disabled = concern.closed || concern.is_resolved || concern.is_archived

  return (
    <React.Fragment>
      <Button
        onClick={() => setShowExternalReferral(true)}
        variant="contained"
        color="primary"
        size="medium"
        disabled={disabled}
        style={{ marginBottom: '10px' }}
      >
        Add External Referral
      </Button>
      <ExternalReferralModal
        concern={concern}
        setShow={setShowExternalReferral}
        show={showExternalReferral}
      />
    </React.Fragment>
  )
}

ExternalReferral.propTypes = {
  concern: PropTypes.object
}

export default ExternalReferral
