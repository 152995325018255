import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Box from '@mui/material/Box'

export function ObservationHeader({ observation }) {
  const { section, observee, observer } = observation
  const teacher = observee.user
  const administrator = observer.user

  return (
    <Box>
      <Box>School: {observation.section.school.name}</Box>
      {teacher && (
        <Box>
          Teacher: {teacher.first_name} {teacher.last_name}
        </Box>
      )}
      {administrator && (
        <Box>
          Observer: {administrator.first_name} {administrator.last_name}
        </Box>
      )}
      {section && (
        <Box>
          Section: {section.hour} {section.name}
        </Box>
      )}
      {observation.started_at && (
        <Box>Date: {moment(observation.started_at).format('MMM D, YYYY')}</Box>
      )}
    </Box>
  )
}

ObservationHeader.propTypes = {
  observation: PropTypes.object.isRequired
}

export default ObservationHeader
