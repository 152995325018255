import { get } from 'utils/ajax'
import { alert } from 'utils/alert'
import { titleize } from 'utils'

export const fetchSchoolDetails = (schoolId, params = {}) => {
  return get(`/api/web/schools/${schoolId}`, { params }).catch(err => {
    alert('An error occurred while loading the school details.', err.message)
  })
}

export const getMuiAutocompleteSchools = schools => {
  const schoolOptions = schools.map(school => ({
    label: titleize(school.name),
    id: school.id
  }))
  schoolOptions.sort((a, b) => a.label.localeCompare(b.label))
  return schoolOptions
}

export const getSchools = params => {
  return get('/api/web/schools', {
    params
  })
}
