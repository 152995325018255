import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CSVDownload } from 'react-csv'
import {
  pick,
  pickBy,
  sortBy,
  map as lodashMap,
  isString,
  isNumber,
  toInteger
} from 'lodash'
import { titleize, convertBoolsToYesNo } from 'utils'
import { showError } from 'utils/errors'
import { Alert, Button } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import DownloadingIcon from '@mui/icons-material/Downloading'
import { get } from 'utils/ajax'

// Recursive function to flatten nested columns
function flattenColumns(columns) {
  return columns.flatMap(column =>
    column.columns ? flattenColumns(column.columns) : column
  )
}

function convertByWeekThresholdHashToArray(val) {
  if (typeof val === 'undefined') {
    return {}
  }

  if (val === null) {
    return {
      last_four_weeks_threshold_met: []
    }
  }

  const convertedHashToArrayOfHashesWithNamedKeys = Object.entries(val).map(
    ([weekNumber, thresholdMet]) => ({
      week_number: toInteger(weekNumber),
      threshold_met: thresholdMet
    })
  )

  const sortedArrayByWeek = sortBy(
    convertedHashToArrayOfHashesWithNamedKeys,
    'week_number'
  ).reverse()
  const lastFourWeeks = sortedArrayByWeek.filter((_r, index) => index <= 3)

  const sanitized = lastFourWeeks.reverse()

  const output = lodashMap(sanitized, 'threshold_met')

  return {
    last_four_weeks_threshold_met: output
  }
}

function mapStudentData(res, columns) {
  const flatColumns = flattenColumns(columns)
  const pickColumns = flatColumns.map(
    col => (col.accessor && isString(col.accessor) && col.accessor) || col.id
  )

  return res.map(s => {
    const { grade_distribution, alerts, by_week_threshold_met, ...data } = pick(
      s,
      pickColumns
    )

    const data_columns = convertBoolsToYesNo(data)

    const alert_columns = alerts && {
      alert_count: alerts.length,
      alerts: alerts.map(a => a.content).join('; ')
    }

    const grade_count_columns =
      grade_distribution &&
      pickBy(grade_distribution, (_v, k) => k.endsWith('_count'))

    const by_week_threshold_met_columns = convertByWeekThresholdHashToArray(
      by_week_threshold_met
    )

    return {
      ...data_columns,
      ...alert_columns,
      ...grade_count_columns,
      ...by_week_threshold_met_columns
    }
  })
}

function headers(students) {
  if (!students || !students.length) return []

  const customHeaders = {
    cpr: 'CPR',
    financial_literacy: 'PFL',
    ok_promise_gpa: 'OK Promise GPA',
    ell: 'ML'
  }

  const headers = Object.keys(students[0]).map(key => ({
    label: customHeaders[key] || titleize(key),
    key
  }))

  return headers
}

const reduceApiIncludes = columnApiIncludes =>
  columnApiIncludes.reduce((hash, include) => {
    hash[`include_${include}`] = true
    return hash
  }, {})

function StudentsCsvLink({
  columns,
  filters,
  columnApiIncludes,
  selectedStudentIds,
  schoolId
}) {
  const [data, setStudents] = useState([])
  const [loading, setLoading] = useState(false)
  const [downloadReady, setDownloadReady] = useState(false)

  const fetchStudents = async () => {
    const params = {
      ...(selectedStudentIds.length > 0 && { student_ids: selectedStudentIds }),
      ...filters,
      ...reduceApiIncludes(columnApiIncludes),
      per_page: 1000000
    }

    const response = await get('/api/web/students', { params })
    return response.data
  }

  const fetchCsvData = () =>
    fetchStudents().then(res => mapStudentData(res, columns))

  useEffect(() => {
    if (loading) {
      fetchCsvData()
        .then(setStudents)
        .catch(err =>
          showError('An error occurred when loading student CSV data', err)
        )
        .finally(() => setLoading(false))
    }
  }, [loading])

  useEffect(() => {
    if (data.length > 0) {
      setDownloadReady(true)
    }
  }, [data])

  const sectionId = filters?.section_id
  const isTeacherSectionTable =
    isString(sectionId) || isNumber(sectionId) ? true : false
  const disableCSVButton = loading || (!isTeacherSectionTable && !schoolId)

  return (
    <React.Fragment>
      {schoolId || isTeacherSectionTable ? (
        <Button
          variant="contained"
          onClick={() => setLoading(true)}
          disabled={disableCSVButton}
          startIcon={loading ? <DownloadingIcon /> : <DownloadIcon />}
        >
          CSV
        </Button>
      ) : (
        <Alert severity="info">
          You must select a school from the dropdown list to generate a CSV.
        </Alert>
      )}

      {!loading && downloadReady && (
        <CSVDownload
          data={data}
          headers={headers(data)}
          filename="students.csv"
          target="_blank"
        />
      )}
    </React.Fragment>
  )
}

StudentsCsvLink.propTypes = {
  fetchStudents: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  schoolId: PropTypes.number
}

export default StudentsCsvLink
