import React from 'react'
import moment from 'moment'
import {
  Alert,
  Tooltip,
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Paper
} from '@mui/material'
import PropTypes from 'prop-types'
import Status from './status/index'
import LockIcon from '@mui/icons-material/Lock'
import { Link } from 'react-router-dom'
import Severity from './severity'
import InfoOutlinedIcon from '@mui/icons-material/Info'

function ConcernHeader({ concern, setShowEditModal }) {
  // 11 is the id of the parent concern type "Behavior"
  const isBehavior = [
    concern.concern_type_id,
    concern.parent_concern_type_id
  ].includes(11)

  return (
    <React.Fragment>
      {concern.confidential_crisis_form_id && (
        <Alert severity="warning">
          Concern associated with this{' '}
          <Link
            to={`/confidential_crisis_forms/${concern.confidential_crisis_form_id}`}
          >
            Confidential Crisis Form
          </Link>
        </Alert>
      )}
      {concern.child_study_id && (
        <Alert severity="warning">
          Concern associated with this{' '}
          <Link
            aria-label={'go-to-child-study'}
            to={`/student/${concern.student_id}/child-study-process`}
          >
            Child Study
          </Link>
        </Alert>
      )}
      {concern.is_requesting_help && (
        <Alert severity="warning">
          Requesting help from the Leadership Team
        </Alert>
      )}
      <Paper sx={{ px: 4, py: 2, mt: 6 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography variant="h3" component="div">
                {concern.concern_name}
              </Typography>

              {concern.is_high_security && (
                <Tooltip
                  sx={{ fontSize: '1.25rem' }}
                  title="Only principals, owners, and Leadership Team members can
                      view this concern"
                  placement="top"
                  arrow
                >
                  <Box
                    sx={{
                      ml: 1
                    }}
                  >
                    <LockIcon style={{ color: '#e84f25' }} />
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" gutterBottom>
              OPENED
            </Typography>
            <Typography variant="body2" gutterBottom>
              {moment(concern.concern_on).format('MM/DD/YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" gutterBottom>
              STUDENT
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Link to={`/student/${concern.student_id}/wellness`}>
                {concern.student_name}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" gutterBottom>
              SUBMITTER
            </Typography>
            <Typography variant="body2" gutterBottom>
              {concern.created_by}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" gutterBottom>
              BACKGROUND
            </Typography>
            <Typography variant="body2" gutterBottom>
              <span>{concern.concern_notes}</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="subtitle2" gutterBottom>
              SEVERITY
              {isBehavior && (
                <Tooltip
                  id="Severity (Behavior Only)"
                  placement="top"
                  arrow
                  title={`New or existing behavior concerns are defaulted to a
                    severity of Low. As a behavior worsens or is
                    reaffirmed by other staff at your site, you can
                    elevate the concern's severity to Medium or High so
                    that the concern is surfaced to your school's
                    Leadership or Student Success Teams.`}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              )}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Severity concern={concern} disabled={concern.closed} />
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row">
              <Status clickable={true} parent={concern} queryName="concern" />
              {!concern.closed && (
                <Button
                  id={`edit-concern-${concern.id}`}
                  variant="outlined"
                  color="primary"
                  onClick={() => setShowEditModal(true)}
                  disabled={!concern.editable_by}
                >
                  Edit
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  )
}

ConcernHeader.propTypes = {
  concern: PropTypes.object.isRequired,
  setShowEditModal: PropTypes.func.isRequired
}

export default ConcernHeader
