import { destroy, post, patch } from 'utils/ajax'
import { alert } from 'utils/alert'

export const deleteChildStudyNote = (id, callback) => {
  return destroy(`/api/web/child_study_notes/${id}`, {})
    .catch(err => {
      alert('Something went wrong!', err)
    })
    .then(() => {
      alert('Child Study Note successfully deleted.', 'success')
      callback()
    })
}

export const createChildStudyNote = (childStudyId, content) => {
  return post('/api/web/child_study_notes', {
    child_study_note: {
      child_study_id: childStudyId,
      content
    }
  }).catch(() => {
    alert('There was an error submitting the note.', 'error')
  })
}

export const editChildStudyNote = (noteId, content) => {
  return patch(`/api/web/child_study_notes/${noteId}`, {
    child_study_note: {
      content
    }
  }).catch(() => {
    alert('There was an error updating the note.', 'error')
  })
}
