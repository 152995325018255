import { get, patch } from 'utils/ajax'

export const fetchSchools = params => {
  return get('/api/admin/schools', { params })
}

export const fetchSchool = (schoolId, params = {}) => {
  return get(`/api/admin/schools/${schoolId}`, {
    params
  })
}

export const editSchool = school => {
  const params = {
    school
  }
  return patch(`/api/admin/schools/${school.id}`, params)
}
