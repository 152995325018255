import { types } from './types'

const initialState = {
  byStudentId: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_HISTORICAL_GRADES:
      return {
        ...state,
        byStudentId: {
          ...state.byStudentId,
          [action.payload.studentId]: {
            ...state.byStudentId[action.payload.studentId],
            historicalGrades: action.payload.data
          }
        }
      }
    default:
      return state
  }
}
