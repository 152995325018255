import { destroy, get, post } from 'utils/ajax'

export const createOrUpdateChildStudyTeamMember = (role, userId, schoolId) => {
  return post('/api/web/child_study_team_members', {
    child_study_team_member: {
      role: role,
      user_id: userId,
      school_id: schoolId
    }
  }).catch(err => {
    alert(
      'An error occurred while updating the child study team member.',
      err.message
    )
  })
}

export const deleteChildStudyTeamMember = id => {
  return destroy(`/api/web/child_study_team_members/${id}`).catch(err => {
    alert(
      'An error occurred while loading the child study team members.',
      err.message
    )
  })
}

export const fetchChildStudyTeamMembers = schoolId => {
  return get('/api/web/child_study_team_members', {
    params: {
      school_id: schoolId
    }
  }).catch(err => {
    alert(
      'An error occurred while loading the child study team members.',
      err.message
    )
  })
}
