import { getSearch } from 'api/searches'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export function useSearch({ params, options }) {
  const queryKey = ['search', params]
  const queryFn = () => getSearch({ params })
  const query = useQuery(queryKey, queryFn, {
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}
