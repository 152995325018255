import { useQuery, useQueryClient } from '@tanstack/react-query'
import { get } from 'utils/ajax'

export function useHtml(url, params = {}, options = {}) {
  const queryKey = ['html', { url, params }]
  const queryFn = () => get(url, { params })
  const query = useQuery(queryKey, queryFn, options)

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}
