import { get } from 'utils/ajax'
import { useQuery, useQueryClient } from '@tanstack/react-query'

export function useTeacher(teacherId, params = {}, options = {}) {
  const queryKey = ['teacher', teacherId, params]
  const queryFn = () => get(`/api/web/teachers/${teacherId}`, { params })
  const query = useQuery(queryKey, queryFn, {
    enabled: Boolean(teacherId),
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}
