import { get } from 'utils/ajax'

import { useQuery, useQueryClient } from '@tanstack/react-query'

export function useStudent({ id, params, options }) {
  const queryKey = ['student', id, params]
  const queryFn = () => get(`/api/web/students/${id}`, { params })
  const query = useQuery(queryKey, queryFn, {
    refetchOnWindowFocus: false,
    ...options
  })

  const queryClient = useQueryClient()
  const invalidate = (key = queryKey) => queryClient.invalidateQueries(key)

  return { ...query, invalidate }
}
