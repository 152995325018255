import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardHeader,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material'
import SimpleAutocomplete from 'shared/simple-autocomplete'

export default function ChartOptions({
  subject,
  setSubject,
  gradeLevel,
  setGradeLevel,
  gradeLevelOptions
}) {
  return (
    <Box
      sx={{
        mt: 2
      }}
    >
      <Card>
        <CardHeader
          title={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <Typography variant="subtitle1">Chart Options</Typography>
              <Box
                sx={{
                  ml: 2
                }}
              >
                <ToggleButtonGroup
                  value={subject}
                  onChange={val => setSubject(val.target.value)}
                  exclusive
                  aria-label="subject"
                >
                  <ToggleButton size="medium" value="ela" aria-label="ela">
                    ELA
                  </ToggleButton>
                  <ToggleButton
                    size="medium"
                    value="mathematics"
                    aria-label="mathematics"
                  >
                    Mathematics
                  </ToggleButton>
                  <ToggleButton
                    size="medium"
                    value="science"
                    aria-label="science"
                  >
                    Science
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box
                sx={{
                  width: 200,
                  maxWidth: '25%',
                  ml: 2
                }}
              >
                <SimpleAutocomplete
                  key={'ostp-grade-level-select'}
                  label={'Filter by Grade Level'}
                  options={gradeLevelOptions}
                  value={gradeLevel}
                  onChange={option => setGradeLevel(option.value)}
                />
              </Box>
            </Box>
          }
        />
      </Card>
    </Box>
  )
}

ChartOptions.propTypes = {
  subject: PropTypes.string,
  setSubject: PropTypes.func,
  gradeLevel: PropTypes.string,
  setGradeLevel: PropTypes.func,
  gradeLevelOptions: PropTypes.array
}
