import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SupportModal from './modal'
import { Button } from '@mui/material'

function Support({ concern }) {
  const [showSupport, setShowSupport] = useState(false)
  const disabled = concern.closed || concern.is_resolved || concern.is_archived
  return (
    <React.Fragment>
      <Button
        onClick={() => setShowSupport(!showSupport)}
        variant="contained"
        color="primary"
        size="medium"
        disabled={disabled}
        style={{ marginBottom: '10px' }}
      >
        Add Support
      </Button>

      <SupportModal
        concern={concern}
        setShow={setShowSupport}
        show={showSupport}
      />
    </React.Fragment>
  )
}

Support.propTypes = {
  concern: PropTypes.object.isRequired
}

export default Support
