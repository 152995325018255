import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function GroupingSelect({ grouping, setGrouping }) {
  const groupingOptions = [
    { value: '', label: 'None Selected' },
    { value: 'sped', label: 'SPED Status' },
    { value: 'ell', label: 'ML Status' },
    { value: 'ethnicity', label: 'Ethnicity' },
    { value: 'gender', label: 'Gender' }
  ]

  return (
    <FormControl variant="outlined" sx={{ m: 1, minWidth: 180 }}>
      <InputLabel id="group-by-select-label">Group By</InputLabel>
      <Select
        labelId="group-by-select-label"
        id="group-by-select"
        value={grouping}
        label="Group By"
        onChange={e => setGrouping(e.target.value)}
      >
        {groupingOptions.map(g => (
          <MenuItem key={`grouping-option-${g.value}`} value={g.value}>
            {g.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

GroupingSelect.propTypes = {
  grouping: PropTypes.string.isRequired,
  setGrouping: PropTypes.func.isRequired
}

export default GroupingSelect
