import React, { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { capitalize } from 'lodash'
import { useReports } from 'hooks/useReports'
import Pagination from './pagination'
import DestroyButton from './destroy-button'
import Spinner from 'shared/spinner/spinner'
import { Box, Button, Stack } from '@mui/material'
import Link from '@mui/material/Link'
import { Download } from '@mui/icons-material'

function ExportsTable() {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const params = {
    page: page,
    per_page: perPage
  }
  const options = {}
  const {
    isSuccess,
    isLoading,
    data: reports
  } = useReports({
    params,
    options
  })

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Report Name</TableCell>
              <TableCell align="right">Report Type</TableCell>
              <TableCell align="right">Date Generated</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell colSpan={5} component="th" scope="row">
                  <Spinner centered={true} />
                </TableCell>
              </TableRow>
            )}

            {isSuccess && reports.data.length === 0 && (
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell colSpan={5} component="th" scope="row">
                  No Reports Found
                </TableCell>
              </TableRow>
            )}

            {isSuccess &&
              reports.data.length > 0 &&
              reports.data.map(row => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.report_type}</TableCell>
                  <TableCell align="right">{row.created_at}</TableCell>
                  <TableCell align="right">{capitalize(row.status)}</TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      display: 'flex',
                      textAlign: 'center',
                      verticalAlign: 'center'
                    }}
                  >
                    <Stack direction="row" spacing={2}>
                      <DestroyButton id={row.id} />

                      <Button
                        variant="contained"
                        disabled={!row.url}
                        component={Link}
                        href={row.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        startIcon={<Download />}
                      >
                        Download
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isSuccess && (
        <Pagination
          count={reports.meta.total}
          perPage={reports.meta.per_page}
          page={reports.meta.page}
          setPage={setPage}
          setPerPage={setPerPage}
          pages={reports.meta.pages}
        />
      )}
    </Box>
  )
}

export default ExportsTable
