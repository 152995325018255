import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { RemoteTable } from 'components/shared/table'
import { debounce } from 'lodash'
import moment from 'moment'
import { useTeachers } from 'hooks/useTeachers'
import { IconButton, Box, Link as MuiLink, Tooltip } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import GradeDistribution from 'components/shared/grade-distribution'
import MarkunreadRoundedIcon from '@mui/icons-material/MarkunreadRounded'
import { theme } from '../app/theme'

const initPopovers = () => {
  $('.js-progress-bar').popover('destroy')
  $('.js-progress-bar').popover({
    trigger: 'hover',
    placement: 'auto'
  })
}

const LastNameCell = ({ value }) => (
  <Box>
    <MuiLink
      component={RouterLink}
      to={{
        pathname: `/teachers/${value.id}/overview`
      }}
      sx={{
        textDecoration: 'none',
        color: theme.palette.primary.main,
        fontWeight: 'bold'
      }}
    >
      {value.last_name}
    </MuiLink>
  </Box>
)
LastNameCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.number.isRequired,
    last_name: PropTypes.string.isRequired
  }).isRequired
}

const LastObservedCell = ({ value }) => (
  <Box>
    {value.last_observed && moment(value.last_observed).format('MM/DD/YYYY')}
  </Box>
)
LastObservedCell.propTypes = {
  value: PropTypes.shape({
    last_observed: PropTypes.any // TODO: any …
  }).isRequired
}

const ActionsCell = ({ value }) => (
  <Box>
    <Tooltip title="Email Teacher" arrow placement="top">
      <IconButton
        color="primary"
        target="_blank"
        href={`https://outlook.office.com/?path=/mail/action/compose&to=${value.email}`}
      >
        <MarkunreadRoundedIcon />
      </IconButton>
    </Tooltip>
  </Box>
)
ActionsCell.propTypes = {
  value: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired
}

const GradeDistributionCell = ({ value }) => {
  const gradeDistribution = value.grade_distribution
  return <GradeDistribution gradeDistribution={gradeDistribution} />
}

const columns = [
  {
    id: 'last_name',
    Header: 'Last Name',
    accessor: obj => obj,
    Cell: LastNameCell
  },
  {
    Header: 'First Name',
    accessor: 'first_name'
  },
  {
    id: 'grade_distribution',
    Header: 'Grade Distribution',
    width: 200,
    sortable: false,
    accessor: obj => obj,
    Cell: GradeDistributionCell
  },
  {
    id: 'attendance_percent',
    Header: () => (
      <Box>
        Attendance %
        <br />
        (Actual)
      </Box>
    ),
    accessor: 'attendance_percent'
  },
  {
    id: 'avoidable_absences',
    Header: () => (
      <Box>
        Avoidable
        <br />
        Absences
      </Box>
    ),
    accessor: 'avoidable_absences'
  },
  {
    id: 'attendance_percent_excluding_unavoidable',
    Header: () => (
      <Box>
        Attendance %
        <br />
        (excl. Unavoidable)
      </Box>
    ),
    minWidth: 120,
    accessor: 'attendance_percent_excluding_unavoidable'
  },
  {
    id: 'referrals_count',
    Header: 'Referrals',
    accessor: 'referrals_count'
  },
  {
    id: 'last_observed',
    Header: 'Last Observed',
    accessor: obj => obj,
    Cell: LastObservedCell
  },
  {
    id: 'actions',
    Header: 'Actions',
    sortable: false,
    accessor: obj => obj,
    Cell: ActionsCell
  }
]

const debounced = debounce((f, v) => f(v), 300)

function TeachersTable({ search, schoolId }) {
  const defaultParams = {
    school_id: schoolId,
    search: search,
    attendance: true,
    include_grade_distribution: true,
    referrals: true,
    observations: true
  }

  const [queryParams, setQueryParams] = useState(defaultParams)

  const { isLoading, data: teachers } = useTeachers({
    params: queryParams,
    options: {
      onSuccess: _data => {
        initPopovers()
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false
    }
  })

  const tableData = teachers?.data || []
  const pages = teachers?.meta?.pages || 0
  const updateParams = params => {
    const newData = { ...defaultParams, ...params }
    debounced(setQueryParams, newData)
  }

  useEffect(() => {
    debounced(setQueryParams, params => ({ ...params, search }))
  }, [search])

  useEffect(() => {
    setQueryParams({ ...queryParams, school_id: schoolId })
  }, [schoolId])

  return (
    <Box id="teacher-table">
      <RemoteTable
        columns={columns}
        data={tableData}
        loading={isLoading}
        pages={pages}
        onFetchData={updateParams}
      />
    </Box>
  )
}

TeachersTable.propTypes = {
  search: PropTypes.string.isRequired,
  schoolId: PropTypes.number
}

GradeDistributionCell.propTypes = {
  value: PropTypes.shape({
    grade_distribution: PropTypes.object.isRequired
  }).isRequired
}

export default TeachersTable
