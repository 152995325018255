import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

function TermsSelect({ termOptions, term, setTerm }) {
  return (
    <FormControl
      variant="outlined"
      sx={{
        m: 1,
        minWidth: 100
      }}
    >
      <InputLabel id="term-select-label">Term</InputLabel>
      <Select
        labelId="term-select-label"
        id="term-select"
        value={term}
        label="Term"
        onChange={e => setTerm(e.target.value)}
      >
        {termOptions.map(t => (
          <MenuItem key={`term-option-${t.value}`} value={t.value}>
            {t.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

TermsSelect.propTypes = {
  termOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  term: PropTypes.string.isRequired,
  setTerm: PropTypes.func.isRequired
}

export default TermsSelect
