import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'orange'
    },
    '&:hover fieldset': {
      borderColor: 'orange'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'orange'
    }
  }
}))

export default StyledTextField
