import React from 'react'
import PropTypes from 'prop-types'
import { Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom'

const SchoolNameHyperlink = ({ id, name, tab = null }) => {
  const url = tab ? `/schools/${id}/${tab}` : `/schools/${id}`
  return (
    <MuiLink component={Link} to={url}>
      {name}
    </MuiLink>
  )
}

SchoolNameHyperlink.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  tab: PropTypes.string
}

export default SchoolNameHyperlink
