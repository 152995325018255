import React from 'react'
import PropTypes from 'prop-types'
// import TranscriptContents from './transcript'
import GradPlanner from './grad-planner'
import Page from 'components/shared/page'

function Transcript({ student }) {
  return (
    <Page name="Graduation Progress" title={student.name}>
      <GradPlanner student={student} />
      {/* <TranscriptContents student={student} showComments={false} /> */}
    </Page>
  )
}

Transcript.propTypes = {
  student: PropTypes.object.isRequired
}

export default Transcript
