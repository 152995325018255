import { actions as userActions } from 'modules/current-user'
import { actions as schoolActions } from 'modules/schools'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { includes, isEmpty } from 'lodash'
import { OptionsContext, optionsPropTypes } from 'utils/contexts/options'
import { Provider } from 'react-redux'
import { setConfig } from 'modules/config'
import { theme } from './theme'
import { CssBaseline, ThemeProvider } from '@mui/material'
import PowerBI from '../power-bi'
import Calendar from '../calendar'
import ChildStudies from '../child_studies/index'
import District from '../district'
import Exports from '../exports'
import Concern from '../concerns/show'
import Intervention from '../interventions/show'
import NoSchoolsAssigned from '../schools/no-schools-assigned'
import NotFound from '../../pages/not-found'
import ForbiddenPage from '../../pages/forbidden'
import PropTypes from 'prop-types'
import ProtectedRoute from '../shared/protected-route'
import React from 'react'
import Schools from '../schools'
import Sections from '../sections'
import store, { RESET_STATE } from 'redux/store'
import Student from '../students/show'
import Students from '../students'
import Teacher from '../teachers/show/index.jsx'
import Teachers from '../teachers/index'
import Wellness from '../wellness/index'
import AppQueryClient from './app-query-client'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import TulsaWay from '../../pages/tulsa-way'
import { sentry_init } from 'utils/sentry'
import ScrollToTop from 'components/shared/scroll-to-top'
import CrisisFormsIndex, {
  CrisisFormsNew,
  CrisisFormsShow,
  CrisisFormsEdit,
  CrisisFormsPrint,
  CrisisFormsDocuments
} from 'components/crisis_forms'
import StudentDocuments from '../students/documents'
import { Routes as MentalWellnessReferralRoutes } from 'components/mental_wellness_referrals'
import { Routes as SlipFormRoutes } from 'components/slip_forms'
import { Routes as PastThirdGradeSlipRoutes } from 'components/past_third_grade_slips'
import { Routes as DyslexiaAddendumSlipRoutes } from 'components/dyslexia_addendum_slips'
import { Routes as AcademicAssessmentAmiraRoutes } from 'components/academic_assessment_amiras'

import { Theme } from '../../pages/theme.jsx'

import Admins from '../admins/index.jsx'
import FlashMessages from '../shared/flash-messages.jsx'
import FormsPage from '../../pages/forms/index.jsx'
import { MdxComponentProvider } from 'components/shared/typography.jsx'

function Providers({ children, options }) {
  return (
    <AppQueryClient>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <OptionsContext.Provider value={options}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MdxComponentProvider>
                <FlashMessages />
                {children}
              </MdxComponentProvider>
            </LocalizationProvider>
          </OptionsContext.Provider>
        </ThemeProvider>
      </Provider>
    </AppQueryClient>
  )
}

Providers.propTypes = {
  children: PropTypes.any,
  options: PropTypes.object
}

function App({ currentUser, schools, config, day_of_school, ...options }) {
  sentry_init(config.sentry, currentUser)

  store.dispatch({ type: RESET_STATE })
  store.dispatch(setConfig(config))
  store.dispatch(userActions.setCurrentUser(currentUser))
  store.dispatch(schoolActions.setSchoolsList(schools))
  store.dispatch(schoolActions.determineCurrentSchool())

  // NOTE: this isn’t great, but teacher is explicitly excluded from the
  //       `SchoolPolicy#show?` via `SchoolPolicy#has_access_to_school?`.
  if (currentUser.role !== 'teacher') {
    const schoolId = store.getState().schools.schoolId
    store.dispatch(schoolActions.selectSchool(schoolId))
  }

  const isOnChildStudy =
    !isEmpty(currentUser.rights.child_study_team_school_ids) ||
    matchesRole(['district', 'principal'])

  const StudentsComponent = Students

  function matchesRole(roles) {
    return includes(roles, currentUser.role)
  }

  function checkNoSchoolsAccess() {
    return (
      schools.length === 0 &&
      !matchesRole(['district', 'partner']) && <Redirect to="/no-schools" />
    )
  }

  const isPermittedToSchools =
    matchesRole(['district', 'principal', 'support', 'partner']) ||
    currentUser.rights.is_rsa_coordinator ||
    currentUser.rights.is_wellness_team_member_at_schools.length > 0

  return (
    <Providers options={options}>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Route path="/theme" component={Theme} />

          <Route path="/no-schools" component={NoSchoolsAssigned} />
          {checkNoSchoolsAccess()}
          <ProtectedRoute
            path="/district"
            dayOfSchool={day_of_school}
            component={District}
            isPermitted={matchesRole(['district', 'partner'])}
            redirectUrl="/"
          />
          <ProtectedRoute
            path="/touchpoints"
            component={<Redirect to="/tulsa-way" />}
            isPermitted={matchesRole(['district', 'principal'])}
            redirectUrl="/"
          />
          <ProtectedRoute
            path="/schools"
            component={Schools}
            isPermitted={isPermittedToSchools}
            redirectUrl="/"
          />
          <ProtectedRoute
            exact={true}
            path="/sections"
            component={Sections}
            isPermitted={matchesRole(['district', 'principal', 'support'])}
            redirectUrl="/"
          />
          <ProtectedRoute
            exact={true}
            path="/teachers"
            component={Teachers}
            isPermitted={matchesRole(['district', 'principal', 'support'])}
            redirectUrl="/"
          />
          <Route path="/teachers/:id" component={Teacher} />
          <ProtectedRoute
            path="/students"
            component={StudentsComponent}
            isPermitted={
              matchesRole(['district', 'principal', 'support']) ||
              currentUser.rights.is_wellness_team_member_at_schools.length > 0
            }
            redirectUrl="/"
          />
          <Route path="/student/:student_id/concerns/:id" component={Concern} />
          <Route
            path="/student/:student_id/interventions/:id"
            component={Intervention}
          />
          <Route path="/student/:id/documents" component={StudentDocuments} />
          <Route path="/student/:id" component={Student} />

          <Route
            exact
            path="/confidential_crisis_forms"
            component={CrisisFormsIndex}
          />
          <Route
            exact
            path="/confidential_crisis_forms/new"
            component={CrisisFormsNew}
          />
          <Route
            exact
            path="/confidential_crisis_forms/:id/documents"
            component={CrisisFormsDocuments}
          />
          <Route
            exact
            path="/confidential_crisis_forms/:id/print"
            component={CrisisFormsPrint}
          />
          <Route
            path="/confidential_crisis_forms/:id/edit"
            component={CrisisFormsEdit}
          />
          <Route
            path="/confidential_crisis_forms/:id"
            component={CrisisFormsShow}
          />
          <Route exact path="/forms" component={FormsPage} />

          <ProtectedRoute
            exact
            path="/wellness"
            component={Wellness}
            isPermitted={matchesRole([
              'teacher',
              'support',
              'principal',
              'district'
            ])}
            redirectUrl="/"
          />

          <ProtectedRoute
            exact={true}
            path="/child_studies"
            component={ChildStudies}
            isPermitted={isOnChildStudy}
            redirectUrl="/"
          />
          <Route path="/exports" component={Exports} />
          <Route path="/concerns/:id" component={Concern} />
          <Route path="/interventions/:id" component={Intervention} />
          <Route path="/calendar" component={Calendar} />
          <ProtectedRoute
            path="/power-bi"
            component={PowerBI}
            isPermitted={matchesRole(['district', 'principal'])}
            redirectUrl="/"
          />

          <ProtectedRoute
            path="/tulsa-way"
            redirectUrl="/"
            component={TulsaWay}
            isPermitted={matchesRole([
              'district',
              'support',
              'principal',
              'teacher'
            ])}
          />

          <Route
            path={MentalWellnessReferralRoutes.basePath}
            component={MentalWellnessReferralRoutes}
          />

          <Route path={SlipFormRoutes.basePath} component={SlipFormRoutes} />

          <Route
            path={PastThirdGradeSlipRoutes.basePath}
            component={PastThirdGradeSlipRoutes}
          />

          <Route
            path={DyslexiaAddendumSlipRoutes.basePath}
            component={DyslexiaAddendumSlipRoutes}
          />

          <Route
            path={AcademicAssessmentAmiraRoutes.basePath}
            component={AcademicAssessmentAmiraRoutes}
          />

          <ProtectedRoute
            path="/admins"
            redirectUrl="/"
            component={Admins}
            isPermitted={currentUser?.rights?.has_admin_access}
          />

          <Route path="/forbidden" component={ForbiddenPage} />
          {/* Don't put routes below NotFound */}
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </Providers>
  )
}

App.propTypes = {
  config: PropTypes.shape({
    env: PropTypes.string.isRequired,
    app_instance: PropTypes.string.isRequired,
    production_url: PropTypes.string.isRequired,
    sentry: PropTypes.shape({
      dsn: PropTypes.string,
      environment: PropTypes.string.isRequired
    })
  }),
  currentUser: PropTypes.object.isRequired,
  ...optionsPropTypes
}

export { store }
export default App
