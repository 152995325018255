import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import CurrentGrades from './current-grades'
import HistoricalGrades from './historical-grades'
import PageTitle from 'components/shared/page-title'
import { Switch, Route } from 'react-router-dom'
import Tabs from 'components/shared/tabs'

const makeTabs = ({ baseUrl }) => [
  { label: 'Current Grades', to: `${baseUrl}` },
  { label: 'Historical Grades', to: `${baseUrl}/historical-grades` }
]

function Grades({ student }) {
  const baseUrl = `/student/${student.id}/grades`
  const tabs = makeTabs({ baseUrl })

  return (
    <React.Fragment>
      <PageTitle title={'Grades'} />
      <Box sx={{ width: '100%' }}>
        <Tabs tabs={tabs} />

        <Switch>
          <Route path={`${baseUrl}`} exact={true}>
            <CurrentGrades student={student} />
          </Route>

          <Route path={`${baseUrl}/historical-grades`} exact={true}>
            <HistoricalGrades studentId={student.id} />
          </Route>
        </Switch>
      </Box>
    </React.Fragment>
  )
}

Grades.propTypes = {
  student: PropTypes.object.isRequired
}

export default Grades
