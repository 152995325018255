import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/shared/page'
import PageContainer from 'components/shared/page-container'
import { currentSchoolYear, titleize } from 'utils'
import { useSelector, useDispatch } from 'react-redux'
import { actions as schoolActions, selectors } from 'modules/schools'
import { withRouterPropTypes } from 'utils/react-router-prop-types'
import { permissions } from '../tulsa-way'
import {
  useObservations,
  useDestroyObservationMutation
} from 'hooks/tulsa-way/useObservations'
import TulsaWayBadge from '../../../assets/images/tulsa-way/tulsa-way-badge.png'
import ObservationsTable from './table'
import NewWindowLink from '../../components/shared/new-window-link'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import { uniqBy } from 'lodash'

const statuses = ['completed', 'incomplete', 'cancelled']

const filterObservations = (observations, filterParams) =>
  observations.filter(
    observation =>
      (!filterParams.observation_type ||
        filterParams.observation_type === observation.observation_type) &&
      (!filterParams.status || filterParams.status === observation.status) &&
      (!filterParams.observee ||
        filterParams.observee ===
          `${observation.observee.user.first_name} ${observation.observee.user.last_name}`) &&
      (!filterParams.observer ||
        filterParams.observer ===
          `${observation.observer.user.first_name} ${observation.observer.user.last_name}`)
  )

const userNamesSet = (observations, role) =>
  [
    ...observations.reduce((prev, curr) => {
      prev.add(`${curr[role].user.first_name} ${curr[role].user.last_name}`)
      return prev
    }, new Set())
  ].sort()

const filterParamsFromUrl = location => {
  const searchParams = new URLSearchParams(location.search)
  return Object.fromEntries(searchParams.entries())
}

const defaultParams = {
  observation_type: '',
  status: '',
  observee: '',
  observer: ''
}

export function TulsaWayLanding({ history, location }) {
  const user = useSelector(state => state.currentUser.user)
  const dispatch = useDispatch()
  const school = useSelector(selectors.currentSchool)
  const schoolOptions = useSelector(state => selectors.schoolOptions(state))
  const schoolChanged = id => dispatch(schoolActions.selectSchool(id))

  const filterParams = React.useMemo(
    () => ({
      school_year: currentSchoolYear,
      school_id: school.id,
      ...defaultParams,
      ...filterParamsFromUrl(location)
    }),
    [location, school]
  )

  const { data: observations, invalidate: invalidateObservations } =
    useObservations({
      params: {
        school_id: filterParams.school_id,
        school_year: filterParams.school_year
      },
      options: {
        initialData: [],
        refetchOnWindowFocus: false
      }
    })

  const observationTypes = uniqBy(observations, 'observation_type').map(
    o => o.observation_type
  )

  const filteredObservations = React.useMemo(
    () => filterObservations(observations, filterParams),
    [observations, filterParams]
  )
  const observees = React.useMemo(
    () => userNamesSet(observations, 'observee'),
    [observations]
  )
  const observers = React.useMemo(
    () => userNamesSet(observations, 'observer'),
    [observations]
  )
  const [deleteObservation, setDeleteObservation] = useState(null)

  const destroyObservationMutation = useDestroyObservationMutation({
    onSuccess: () => {
      invalidateObservations()
    }
  })

  const updateUrl = params => {
    const filteredEntries = Object.entries(params).filter(([_k, v]) =>
      Boolean(v)
    )
    const newParams = Object.fromEntries(filteredEntries)
    const newSearchParams = new URLSearchParams(newParams)
    const curSearchParams = new URLSearchParams(location.search)
    if (newSearchParams.toString() !== curSearchParams.toString()) {
      history.push('?' + newSearchParams)
    }
  }

  const handleChange = (key, value) => {
    updateUrl({ ...filterParams, [key]: value })
  }

  const handleClear = React.useCallback(
    () =>
      updateUrl({
        ...filterParams,
        ...defaultParams
      }),
    [filterParams]
  )

  const handleDelete = observation => _event => {
    setDeleteObservation(observation)
  }

  const handleDeleteAction = action => {
    if (action == 'delete') {
      destroyObservationMutation.mutate(deleteObservation.id)
    }
    setDeleteObservation(null)
  }

  useEffect(() => {
    if (school.id != filterParams.school_id) {
      schoolChanged(filterParams.school_id)
    }
  }, [school.id, filterParams.school_id])

  useEffect(() => {
    // if the school year or school is changed, we should reset all other filters to original state
    updateUrl({
      ...filterParams,
      ...defaultParams
    })
  }, [observations])

  return (
    <Page name={'Tulsa Way'} title={'Tulsa Way'}>
      <PageContainer>
        <Stack spacing={4}>
          <Box
            id="test-header"
            sx={{
              mb: 6
            }}
          >
            <Stack spacing={4} direction="row" alignItems="center">
              <Box
                component="img"
                alt="Tulsa Way Logo"
                src={TulsaWayBadge}
                sx={{
                  width: 100,
                  height: 100
                }}
              />

              <Typography variant={'h1'} id="test_header_title">
                Tulsa Way Observations
              </Typography>
            </Stack>
          </Box>

          <Box>
            <Stack spacing={4}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Box>
                  {permissions.performInformalObservation(user) && (
                    <Button
                      variant={'contained'}
                      size="large"
                      key={'start-informal-observation'}
                      onClick={() =>
                        history.push('/tulsa-way/informal-observation')
                      }
                      sx={{ m: 2 }}
                    >
                      Start Informal Observation
                    </Button>
                  )}
                  {permissions.performWalkToRead(user) &&
                    school.category === 'elementary' && (
                      <Button
                        variant={'contained'}
                        size="large"
                        key={'start-walk-to-read'}
                        onClick={() => history.push('/tulsa-way/walk-to-read')}
                        sx={{ m: 2 }}
                      >
                        Start Walk to Read
                      </Button>
                    )}
                </Box>
                {schoolOptions.length > 0 && (
                  <Grid item xs={4} md={2}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="school-label">Select School</InputLabel>
                      <Select
                        label="Select School"
                        labelId="school-label"
                        value={filterParams.school_id}
                        onChange={event =>
                          handleChange('school_id', event.target.value)
                        }
                      >
                        {schoolOptions.map((schoolOption, i) => (
                          <MenuItem key={i} value={schoolOption.value}>
                            {schoolOption.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Stack>

              <Typography variant="body1">
                Informal observations are a tool to improve instructional
                practice through coaching around the development areas of the
                Tulsa Way Rubric. Provide direct feedback to individual
                teachers. Informs site-based professional learning and informs
                arc of learning for leaders and coaches.{' '}
                <NewWindowLink
                  href="https://docs.google.com/document/d/1wSPDzRQuQc1pntF4LLMUndS4qLtHowqcmL4cf8EBs6I"
                  text="Tulsa Way Rubric"
                />
              </Typography>

              <Stack direction={{ xs: 'column', md: 'row' }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel id="school-year-label">
                    Filter by School Year
                  </InputLabel>
                  <Select
                    labelId="school-year-label"
                    value={filterParams.school_year}
                    onChange={event =>
                      handleChange('school_year', event.target.value)
                    }
                  >
                    <MenuItem key={'2025-school-year'} value={2025}>
                      2024-2025
                    </MenuItem>
                    <MenuItem key={'2024-school-year'} value={2024}>
                      2023-2024
                    </MenuItem>
                    <MenuItem key={'2023-school-year'} value={2023}>
                      2022-2023
                    </MenuItem>
                  </Select>
                </FormControl>

                {observations.length > 0 && (
                  <>
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="observation-type-label">
                        Filter by Observation Type
                      </InputLabel>
                      <Select
                        labelId="observation-type-label"
                        value={filterParams.observation_type}
                        onChange={event =>
                          handleChange('observation_type', event.target.value)
                        }
                      >
                        {observationTypes.map((observationType, i) => (
                          <MenuItem key={i} value={observationType}>
                            {observationType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth variant="standard">
                      <InputLabel id="status-label">
                        Filter by Status
                      </InputLabel>
                      <Select
                        labelId="status-label"
                        value={filterParams.status}
                        onChange={event =>
                          handleChange('status', event.target.value)
                        }
                      >
                        {statuses.map((status, i) => (
                          <MenuItem key={i} value={status}>
                            {titleize(status)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}

                {observees.length > 1 && (
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="observee-label">
                      Filter by Teacher
                    </InputLabel>
                    <Select
                      labelId="observee-label"
                      value={filterParams.observee}
                      onChange={event =>
                        handleChange('observee', event.target.value)
                      }
                    >
                      {observees.map((observee, i) => (
                        <MenuItem key={i} value={observee}>
                          {observee}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {observers.length > 1 && (
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="observer-label">
                      Filter by Observer
                    </InputLabel>
                    <Select
                      labelId="observer-label"
                      value={filterParams.observer}
                      onChange={event =>
                        handleChange('observer', event.target.value)
                      }
                    >
                      {observers.map((observer, i) => (
                        <MenuItem key={i} value={observer}>
                          {observer}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {Object.values(filterParams).some(val => val !== '') && (
                  <Button
                    size="large"
                    color={'warning'}
                    variant={'contained'}
                    onClick={handleClear}
                  >
                    Clear
                  </Button>
                )}

                {Boolean(observations.length) && (
                  <Button
                    variant={'contained'}
                    href={`/api/web/tulsa_way/observations/export.csv?${new URLSearchParams(
                      filterParamsFromUrl(location)
                    )}`}
                  >
                    CSV
                  </Button>
                )}
              </Stack>

              <ObservationsTable
                observations={filteredObservations}
                onDelete={handleDelete}
              />
            </Stack>
          </Box>
        </Stack>
      </PageContainer>
      {deleteObservation && (
        <DeleteObservationDialog
          observation={deleteObservation}
          onAction={handleDeleteAction}
        />
      )}
    </Page>
  )
}

TulsaWayLanding.propTypes = {
  ...withRouterPropTypes
}

function DeleteObservationDialog({ observation, onAction }) {
  const handleClose = () => onAction('cancel')
  const handleDelete = () => onAction('delete')

  return (
    <Dialog open={Boolean(observation)} onClose={handleClose}>
      {observation && (
        <React.Fragment>
          <DialogTitle onClose={handleClose}>
            Delete {observation.observation_type}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this{' '}
              {observation.observation_type}?
            </DialogContentText>
          </DialogContent>
        </React.Fragment>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteObservationDialog.propTypes = {
  observation: PropTypes.object,
  onAction: PropTypes.func.isRequired
}

export default TulsaWayLanding
