import React from 'react'
import PropTypes from 'prop-types'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import Typography from '@mui/material/Typography'
import { Alert } from '@mui/material'
import { Link } from 'react-router-dom'

const AccordionInstructions = ({ children }) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ArrowDownwardIcon />}>
        <Typography variant={'h4'}>
          Instructions, Guidance, Resources
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

AccordionInstructions.propTypes = {
  children: PropTypes.any
}

const Instructions = () => {
  return (
    <>
      <Alert severity="warning">
        This form is for routine referral. If the student is currently
        experiencing a mental health crisis, call COPES (918-744-4800) and
        complete the{' '}
        <Link to={'/confidential_crisis_forms/new'}>
          Confidential Crisis Form
        </Link>
        .
      </Alert>

      <ul>
        <li>
          Before completing this form, contact the family. Tell the family the
          referral is being made and provide the name of the agency.
        </li>
        <li>
          After submitting, the information is provided to the referred agency.
        </li>
        <li>
          The family receives an email notification of the referral. (The
          notification does not state the name of the referred agency.)
        </li>
      </ul>
    </>
  )
}

const MentalWellnessReferralInstructions = () => (
  <AccordionInstructions>
    <Instructions />
  </AccordionInstructions>
)

export default MentalWellnessReferralInstructions
