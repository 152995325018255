import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { RemoteTable } from 'components/shared/table'
import LinksModal from './links-modal'
import { Button, Tooltip } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
import FolderIcon from '@mui/icons-material/Folder'

export default class AdminSchoolsTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    fetchData: PropTypes.func,
    loading: PropTypes.bool,
    totalPages: PropTypes.number
  }

  state = {
    openModal: false,
    activeLinks: '',
    activeName: ''
  }

  handleModalHide = () => {
    this.setState({
      openModal: false
    })
  }

  openModalWithLinks = (links, name) => {
    this.setState({
      activeLinks: links,
      activeName: name,
      openModal: true
    })
  }

  columns = () => {
    return [
      {
        Header: 'Schools',
        sortable: false,
        columns: [
          {
            id: 'name',
            Header: 'Name',
            accessor: 'name'
          },
          {
            id: 'id',
            Header: 'Code',
            accessor: obj => obj.id
          }
        ]
      },
      {
        Header: 'Principal Logins',
        sortable: false,
        columns: [
          {
            id: 'principal_logins_avg',
            Header: 'All-Time Avg Per',
            accessor: obj => obj.principal_logins_avg
          }
        ]
      },
      {
        Header: 'Teacher Logins',
        sortable: false,
        columns: [
          {
            id: 'percent_teacher_logins',
            Header: 'All-Time %',
            accessor: obj => obj.percent_teacher_logins,
            Cell: props => parseFloat(props.value * 100).toFixed(1) + '%'
          },
          {
            id: 'teacher_logins_avg',
            Header: 'All-Time Avg Per',
            accessor: obj => obj.teacher_logins_avg
          }
        ]
      },
      {
        Header: 'Actions',
        sortable: false,
        columns: [
          {
            id: 'actions',
            Header: 'Actions',
            accessor: obj => obj,
            Cell: props => (
              <>
                <Tooltip arrow placement="top" title="School Links/Resources">
                  <Button
                    id="js-admin-view-school-links"
                    variant="contained"
                    onClick={() =>
                      this.openModalWithLinks(
                        props.value.school_links,
                        props.value.name
                      )
                    }
                  >
                    <FolderIcon />
                  </Button>
                </Tooltip>
                <Tooltip arrow placement="top" title="Edit School Info">
                  <Button
                    id={`admin-edit-school-${props.value.id}`}
                    variant="contained"
                    color="secondary"
                    component={Link}
                    sx={{ ml: 2 }}
                    to={{
                      pathname: `/admins/schools/${props.value.id}/edit`
                    }}
                  >
                    <CreateIcon />
                  </Button>
                </Tooltip>
              </>
            )
          }
        ]
      }
    ]
  }

  render() {
    const { data, loading, fetchData, totalPages, ...props } = this.props
    return (
      <div id="user-table">
        <LinksModal
          show={this.state.openModal}
          schoolLinks={this.state.activeLinks}
          schoolName={this.state.activeName}
          onHide={this.handleModalHide}
        />
        <RemoteTable
          columns={this.columns()}
          className="table-bordered"
          data={data}
          loading={loading}
          onFetchData={params => fetchData(params)}
          pages={totalPages}
          {...props}
        />
      </div>
    )
  }
}
