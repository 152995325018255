import { groupBy } from 'lodash'

export const types = {
  SET_GOAL: 'goals/fetch_goal',
  FETCH_GOALS: 'goals/fetch_goals',
  UPDATE_GOAL: 'goals/update_goals'
}

const initialState = {
  byYear: {},
  activeGoal: {},
  meta: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_GOALS:
      return {
        ...state,
        byYear: groupBy(action.payload.data, 'year'),
        schoolId: action.payload.data[0].school_id,
        meta: action.payload.meta
      }
    case types.UPDATE_GOAL:
      return {
        ...state,
        activeGoal: action.payload
      }
    case types.SET_GOAL:
      return {
        ...state,
        activeGoal: action.payload
      }
    default:
      return state
  }
}
