import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Feed from './feed'
import Actions from './actions'
import ReferralForm from './referral/form'
import Concerns from './concerns'
import Button from '@mui/material/Button'
import RequirementsApi from 'modules/requirements/api'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getConcerns } from 'api/interventions'
import { Box, Paper, Stack } from '@mui/material'

function ChildStudyProcess({ student, schoolId }) {
  const childStudyId = student.child_study_id
  const [showReferralForm, setShowReferralForm] = useState(false)
  const queryClient = useQueryClient()
  const [activeModal, setActiveModal] = useState('')
  const [activeId, setActiveId] = useState(null)
  useState(null)

  const { isLoading, data: concerns } = useQuery(
    ['child-study-concerns', student.id],
    async () => {
      const res = await getConcerns({
        student_id: student.id,
        cst_page: true
      })
      return res.data
    },
    {
      initialData: [],
      refetchOnWindowFocus: false
    }
  )

  const { data: requirements } = useQuery(
    ['child-study-requirements', student.id],
    async () => {
      if (childStudyId) {
        return RequirementsApi.getRequirements(childStudyId).then(
          res => res.data
        )
      }
    },
    {
      initialData: []
    }
  )

  const invalidateConcernsQuery = () => {
    queryClient.invalidateQueries(['child-study-concerns', student.id])
  }

  const invalidateChildStudyRequirements = () => {
    queryClient.invalidateQueries(['child-study-requirements', student.id])
  }

  return (
    <Box>
      <Stack spacing={2}>
        {student.can_view_child_study_tab && !childStudyId && (
          <Box>
            {!showReferralForm && (
              <Button
                id="refer-to-child-study-team"
                variant="contained"
                onClick={() => setShowReferralForm(true)}
              >
                Refer to Child Study Team
              </Button>
            )}
            {showReferralForm && (
              <ReferralForm schoolId={schoolId} studentId={student.id} />
            )}
          </Box>
        )}

        {childStudyId && (
          <Stack spacing={2}>
            <Box>
              <Actions
                activeModal={activeModal}
                childStudyId={childStudyId}
                invalidateChildStudyRequirements={
                  invalidateChildStudyRequirements
                }
                invalidateConcernsQuery={invalidateConcernsQuery}
                setActiveModal={setActiveModal}
                studentId={student.id}
                studentName={student.name}
                schoolId={schoolId}
              />
            </Box>

            {student.can_use_child_study_actions && (
              <Box>
                <Stack spacing={2}>
                  <Paper sx={{ p: 4 }}>
                    <Concerns
                      concerns={concerns}
                      invalidateQuery={invalidateConcernsQuery}
                      isLoading={isLoading}
                      student={student}
                    />
                  </Paper>

                  <Paper sx={{ p: 4 }}>
                    <Feed
                      activeId={activeId}
                      activeModal={activeModal}
                      invalidateChildStudyRequirements={
                        invalidateChildStudyRequirements
                      }
                      requirements={requirements}
                      setActiveId={setActiveId}
                      setActiveModal={setActiveModal}
                      student={student}
                    />
                  </Paper>
                </Stack>
              </Box>
            )}
          </Stack>
        )}
      </Stack>
    </Box>
  )
}

ChildStudyProcess.propTypes = {
  student: PropTypes.object.isRequired,
  schoolId: PropTypes.number.isRequired
}

export default ChildStudyProcess
