import React from 'react'
import PropTypes from 'prop-types'
import {
  withHighcharts,
  HighchartsChart,
  Chart,
  Title,
  XAxis,
  YAxis,
  LineSeries,
  Tooltip
} from 'react-jsx-highcharts'

function EnrollmentChart({ data }) {
  const format = ({ date, student_count }) => [
    new Date(date).getTime(),
    student_count
  ]

  const toDataSeries = data => data.map(dt => format(dt))

  const xAxisTitle = 'Date'
  const yAxisTitle = 'Enrollment'
  const chartData = toDataSeries(data)
  const dateLabelFormat = { day: '%b %e' }

  return (
    <div id="daily-enrollment-line-chart-container">
      <HighchartsChart id="daily-enrollment-line-chart">
        <Chart type="spline" />
        <Title>Daily Enrollment</Title>
        <Tooltip padding={10} hideDelay={250} />
        <XAxis type="datetime" dateTimeLabelFormats={dateLabelFormat}>
          <XAxis.Title>{xAxisTitle}</XAxis.Title>
        </XAxis>
        <YAxis id="number">
          <YAxis.Title>{yAxisTitle}</YAxis.Title>
        </YAxis>
        <LineSeries data={chartData} name={yAxisTitle} />
      </HighchartsChart>
    </div>
  )
}

EnrollmentChart.propTypes = {
  data: PropTypes.array,
  isParentPortal: PropTypes.bool
}

export default withHighcharts(EnrollmentChart, window.Highcharts)
