import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@mui/styles'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'

const useStyles = makeStyles({
  root: {
    minWidth: 345,
    maxWidth: 900,
    marginTop: 20,
    padding: 5
  },
  icon: {
    marginRight: 5,
    verticalAlign: 'middle'
  }
})

const CustomSlider = withStyles({
  root: {
    color: '#0096D7',
    height: 4
  },
  thumb: {
    display: 'none'
  },
  active: {},
  track: {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#0096D7'
  },
  rail: {
    height: 8,
    borderRadius: 4,
    backgroundColor: '#0096D7'
  }
})(Slider)

export default function LookForCard({ indicatorGroup, user }) {
  const classes = useStyles()

  const indicatorScores = indicatorGroup
    .map(answer => answer.score)
    .filter(score => score !== null)
  const indicatorScoreSum = indicatorScores.reduce((acc, cur) => acc + cur, 0)

  return (
    <Card
      key={indicatorGroup[0].touchpoint_indicator_id}
      className={classes.root}
    >
      <CardContent>
        <Grid container>
          <Grid item>
            <MenuBookIcon className={classes.icon} />
            {indicatorGroup[0].indicator.title}
          </Grid>
        </Grid>
        <Divider style={{ marginTop: '15px' }} />
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          {indicatorGroup[0].indicator.description}
        </Typography>
        {Boolean(indicatorScores.length) && user.role !== 'teacher' && (
          <Grid container item xs={4}>
            <Typography
              variant="body2"
              style={{ marginTop: '25px', color: '#8a8a8a' }}
            >
              Success {indicatorScoreSum}/{indicatorScores.length} observations
            </Typography>
            <CustomSlider
              value={indicatorScoreSum}
              color="primary"
              min={0}
              max={indicatorScores.length}
              disabled
            />
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}

LookForCard.propTypes = {
  indicatorGroup: PropTypes.any,
  user: PropTypes.object.isRequired
}
