import { get } from 'utils/ajax'
import { alert } from 'utils/alert'

export const fetchStats = ({ schoolId, groupBy }) => {
  const params = { school_id: schoolId, group_by: groupBy }
  return get('/api/web/graduation/stats', { params })
    .then(res => {
      return res.data
    })
    .catch(() => {
      alert('There was an error fetching graduation stats', 'danger')
    })
}

export const fetchQualtricsGraducationCheckConversationStart = studentId => {
  const params = { student_id: studentId }
  return get('/api/qualtrics/graduation_check_conversations/start', {
    params
  }).catch(_ => {
    alert('There was an error starting the qualtrics survey', 'danger')
  })
}

export const getGraduationCheckStats = schoolId => {
  const params = { school_id: schoolId }

  return get('/api/web/graduation_check_stats', { params }).catch(() => {
    alert('There was an error fetching graduation check stats', 'danger')
  })
}
