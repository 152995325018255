import { currentSchoolYear } from 'utils'

export const types = {
  RESET: 'school_filters/reset',
  FILTERS_CHANGED: 'school_filters/filters_changed',
  SCHOOL_YEAR_CHANGED: 'school_filters/school_year_changed',
  COHORT_CHANGED: 'school_filters/cohort_changed',
  QUICK_FILTER_SELECTED: 'school_filters/quick_filter_selected',
  SET_FILTERS_EXPANDED: 'school_filters/toggle_filters_expanded',
  STUDENT_FILTERS_CHANGED: 'student_filters/student_filters_changed',
  CLEAR_STUDENT_FILTERS: 'student_filters/clear_student_filters'
}

const initialState = {
  filters: [],
  selectedQuickFilter: 'school_years',
  filtersExpanded: false,
  schoolYearFilter: currentSchoolYear,
  cohortFilter: currentSchoolYear,
  studentFilters: {}
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET:
      return { ...initialState }
    case types.FILTERS_CHANGED:
      return { ...state, filters: action.payload }
    case types.QUICK_FILTER_SELECTED:
      return { ...state, selectedQuickFilter: action.payload }
    case types.SET_FILTERS_EXPANDED:
      return { ...state, filtersExpanded: action.payload }
    case types.SCHOOL_YEAR_CHANGED:
      return { ...state, schoolYearFilter: action.payload }
    case types.COHORT_CHANGED:
      return { ...state, cohortFilter: action.payload }
    case types.STUDENT_FILTERS_CHANGED:
      return { ...state, studentFilters: action.payload }
    case types.CLEAR_STUDENT_FILTERS:
      return {
        ...state,
        studentFilters: {}
      }
    default:
      return state
  }
}
