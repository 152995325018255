import React from 'react'
import PropTypes from 'prop-types'
import { AbsoluteSpinner } from 'components/shared/spinner'
import { Redirect, Switch, Route } from 'react-router-dom'
import AccessTab from './access/index'
import AttendanceTab from 'components/shared/students/show/attendance'
import ChildStudyProcessTab from './child-study-process'
import DisciplineTab from 'components/shared/students/show/discipline'
import GradesTab from 'components/shared/students/show/grades'
import ConcernsTab from './concerns/index'
import OverviewTab from './overview'
import SatTab from './sat/index'
import { useStudent } from 'hooks/useStudents'
import GraduationProgressTab from 'components/shared/students/show/graduation_progress'
// import GraduationCheckTab from 'components/shared/students/show/graduation_check/tab'
import PageContainer from 'components/shared/page-container'
import StudentSlipCompliance from '../slip/compliance'
import StudentAcademicInterventions from '../academic-interventions/StudentAcademicInterventions'
import StudentAcademicAssessments from '../academic-assessments/StudentAcademicAssessments'
import { Box, Typography } from '@mui/material'
import { Tabs as SharedTabs } from 'components/shared/tabs'
import ProtectedRoute from '../../shared/protected-route'

const makeTabs = ({ student, baseUrl, isHighSchool }) => {
  return [
    { label: 'Overview', to: `${baseUrl}/overview` },
    { label: 'Grades', to: `${baseUrl}/grades` },
    { label: 'Attendance', to: `${baseUrl}/attendance` },
    { label: 'Discipline', to: `${baseUrl}/discipline` },
    { label: 'Supports & Concerns', to: `${baseUrl}/wellness` },
    {
      label: 'Graduation Progress',
      to: `${baseUrl}/graduation-progress`,
      access: () => isHighSchool
    },
    // {
    //   label: 'Graduation Check',
    //   to: `${baseUrl}/graduation-check`,
    //   access: () => isHighSchoolWithCohort
    // },
    { label: 'Child Study Process', to: `${baseUrl}/child-study-process` },
    {
      label: 'Academic Interventions',
      to: `${baseUrl}/academic-interventions`
    },
    { label: 'Academic Assessments', to: `${baseUrl}/academic-assessments` },
    {
      label: 'SLIP',
      to: `${baseUrl}/slip`,
      access: () =>
        ['k', '1st', '2nd', '3rd', '4th', '5th'].includes(student?.grade_level)
    }
  ].filter(tab => !tab.access || tab.access())
}

function Tabs({ student, baseUrl, isHighSchool }) {
  const tabs = React.useMemo(
    () =>
      student &&
      makeTabs({
        student,
        baseUrl,
        isHighSchool
      }),
    [student, baseUrl, isHighSchool]
  )

  return <SharedTabs tabs={tabs} />
}

Tabs.propTypes = {
  student: PropTypes.object.isRequired,
  baseUrl: PropTypes.string.isRequired,
  isHighSchool: PropTypes.bool.isRequired
}

export function Student() {
  const studentId = parseInt(window.location.href.split('/')[4])
  const params = {
    studentId: studentId,
    include_school: true
  }

  const { isLoading, data } = useStudent({
    id: studentId,
    params
  })

  const student = data?.student
  const baseUrl = `/student/${studentId}`

  const isHighSchool = student?.school?.category === 'high'

  return (
    <Box id="student-page">
      <PageContainer>
        {isLoading && (
          <AbsoluteSpinner
            style={{
              marginTop: '3em'
            }}
          />
        )}

        <Box
          sx={{
            mb: 6
          }}
        >
          <Typography variant="h1" id="test_header_title">
            Student
          </Typography>
          <Typography variant="h2" id="test_subheader_title">
            {student?.name}
          </Typography>
        </Box>

        {student?.id && (
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                {...{
                  student,
                  baseUrl,
                  isHighSchool
                }}
              />
            </Box>

            <Box
              sx={{
                mt: 6
              }}
            >
              <Switch>
                <Redirect
                  from={baseUrl}
                  exact={true}
                  to={`${baseUrl}/overview`}
                />
                <Route path={`${baseUrl}/overview`}>
                  <OverviewTab student={student} />
                </Route>

                <Route path={`${baseUrl}/grades`}>
                  <GradesTab student={student} />
                </Route>

                <Route path={`${baseUrl}/attendance`}>
                  <AttendanceTab studentId={studentId} />
                </Route>

                <Route path={`${baseUrl}/discipline`}>
                  <DisciplineTab studentId={studentId} />
                </Route>

                <Route path={`${baseUrl}/access`}>
                  <AccessTab studentId={studentId} />
                </Route>

                <Route path={`${baseUrl}/wellness`}>
                  <ConcernsTab student={student} />
                </Route>

                <Route path={`${baseUrl}/sat`}>
                  <SatTab studentId={studentId} />
                </Route>

                <Route path={`${baseUrl}/graduation-progress`}>
                  <GraduationProgressTab student={student} />
                </Route>

                <Redirect
                  exact={true}
                  from={`${baseUrl}/graduation-check`}
                  to={`${baseUrl}/graduation-progress`}
                />
                {/* <Route path={`${baseUrl}/graduation-check`}>
                  <GraduationCheckTab
                    student={student}
                    basePath={`${baseUrl}/graduation-check`}
                  />
                </Route> */}

                <ProtectedRoute
                  path={`${baseUrl}/child-study-process`}
                  redirectUrl={`${baseUrl}/overview`}
                  isPermitted={student?.can_view_child_study_tab}
                  component={
                    <ChildStudyProcessTab
                      student={student}
                      childStudyId={student?.child_study_id}
                      schoolId={student?.school.id}
                    />
                  }
                />

                <Route path={`${baseUrl}/slip`}>
                  <StudentSlipCompliance student={student} />
                </Route>

                <Route path={`${baseUrl}/academic-interventions`}>
                  <StudentAcademicInterventions student={student} />
                </Route>

                <Route path={`${baseUrl}/academic-assessments`}>
                  <StudentAcademicAssessments student={student} />
                </Route>
              </Switch>
            </Box>
          </Box>
        )}
      </PageContainer>
    </Box>
  )
}

export default Student
