import React, { useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import { DatePicker } from '@mui/x-date-pickers'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import moment from 'moment'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import PropTypes from 'prop-types'
import Select from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import {
  createChildStudyReferral,
  updateChildStudyReferral
} from 'api/child-study-referrals'
import { useQueryClient } from '@tanstack/react-query'
import { useStudentConcerns } from 'hooks/useStudentConcerns'
import { createChildStudyReferralForm } from 'api/child-study-referral-forms'
import {
  createChildStudyReferralConcern,
  destroyChildStudyReferralConcern
} from 'api/child-study-referral-concerns'

function DownloadLink({ file, children }) {
  const href = typeof file === 'string' ? file : URL.createObjectURL(file)

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      download={file.name}
    >
      {children}
    </a>
  )
}
DownloadLink.propTypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.any
}

function ReferralForm({ childStudyReferral, onClose, schoolId, studentId }) {
  const { query: studentConcernsQuery } = useStudentConcerns({
    studentId: studentId,
    options: {
      refetchOnWindowFocus: false
    }
  })
  const { data: studentConcerns } = studentConcernsQuery
  const queryClient = useQueryClient()
  const [behaviorConcerns, setBehaviorConcerns] = useState([])
  const [academicConcerns, setAcademicConcerns] = useState([])
  const [attendanceConcerns, setAttendanceConcerns] = useState([])
  const [socialHealthConcerns, setSocialHealthConcerns] = useState([])
  const classworkSummaryRef = useRef(null)
  const studentObservationSummaryRef = useRef(null)
  const classAssessmentsSummaryRef = useRef(null)
  const districtAssessmentsSummaryRef = useRef(null)
  const behaviorInformationSummaryRef = useRef(null)
  const healthInformationSummaryRef = useRef(null)
  const outsideAgencyReportsSummaryRef = useRef(null)

  const [formData, setFormData] = useState({
    academicConcernSummary: childStudyReferral?.academic_concern_summary || '',
    attendanceConcernSummary:
      childStudyReferral?.attendance_concern_summary || '',
    behaviorConcernSummary: childStudyReferral?.behavior_concern_summary || '',
    behaviorInformationDocuments: [],
    behaviorInformationSummary:
      childStudyReferral?.behavior_information_summary || '',
    classAssessmentsDocuments: [],
    classAssessmentsSummary:
      childStudyReferral?.class_assessments_summary || '',
    classworkDocuments: [],
    classworkSummary: childStudyReferral?.classwork_summary || '',
    conferenceWithParentDate:
      moment(childStudyReferral?.conference_with_parent_date)._d || null,
    conferenceWithParentSummary:
      childStudyReferral?.conference_with_parent_summary || '',
    conferenceWithStudentDate:
      moment(childStudyReferral?.conference_with_student_date)._d || null,
    conferenceWithStudentSummary:
      childStudyReferral?.conference_with_student_summary || '',
    districtAssessmentsDocuments: [],
    districtAssessmentsSummary:
      childStudyReferral?.district_assessments_summary || '',
    hasAcademicConcern: childStudyReferral?.has_academic_concern || false,
    hasAttendanceConcern: childStudyReferral?.has_attendance_concern || false,
    hasBehaviorConcern: childStudyReferral?.has_behavior_concern || false,
    hasSocialHealthConcern:
      childStudyReferral?.has_social_health_concern || false,
    healthInformationDocuments: [],
    healthInformationSummary:
      childStudyReferral?.health_information_summary || '',
    outsideAgencyReportsDocuments: [],
    outsideAgencyReportsSummary:
      childStudyReferral?.outside_agency_reports_summary || '',
    referralDate: moment(childStudyReferral?.referral_date) || moment()._d,
    referrerTitle: childStudyReferral?.referrer_title || '',
    selectedAcademicConcerns: [],
    selectedAttendanceConcerns: [],
    selectedBehaviorConcerns: [],
    selectedSocialHealthConcerns: [],
    socialHealthConcernSummary:
      childStudyReferral?.social_health_concern_summary || '',
    strategiesSummary: childStudyReferral?.strategies_summary || '',
    studentObservationDocuments: [],
    studentObservationSummary:
      childStudyReferral?.student_observation_summary || '',
    summary: childStudyReferral?.summary || ''
  })

  const constructFormDataQueriesAndCreate = async referralId => {
    const documentTypes = [
      { key: 'behaviorInformationDocuments', value: 'behavior_information' },
      { key: 'classAssessmentsDocuments', value: 'class_assessments' },
      { key: 'classworkDocuments', value: 'classwork' },
      { key: 'districtAssessmentsDocuments', value: 'district_assessments' },
      { key: 'healthInformationDocuments', value: 'health_information' },
      { key: 'outsideAgencyReportsDocuments', value: 'outside_agency_reports' },
      { key: 'studentObservationDocuments', value: 'student_observation' }
    ]

    const queryParamsCollection = []

    documentTypes.map(documentType => {
      const files = formData[documentType.key]

      if (files) {
        files.map(file => {
          const query = new FormData()
          query.append('child_study_referral_form[document]', file, file.name)
          query.append(
            'child_study_referral_form[category]',
            documentType.value
          )
          query.append(
            'child_study_referral_form[child_study_referral_id]',
            referralId
          )
          queryParamsCollection.push(query)
        })
      }
    })

    const queryPromises = queryParamsCollection.map(async queryParams => {
      await createChildStudyReferralForm(queryParams)
    })

    return Promise.all(queryPromises)

    // TODO: Allow users to remove previously uploaded forms
  }

  const constructConcernQueries = async referralId => {
    const concernTypes = [
      { key: 'selectedAcademicConcerns', value: 'academic' },
      { key: 'selectedAttendanceConcerns', value: 'attendance' },
      { key: 'selectedBehaviorConcerns', value: 'behavior' },
      { key: 'selectedSocialHealthConcerns', value: 'social_health' }
    ]
    const newConcernParamsCollection = []
    const existingConcerns = childStudyReferral
      ? childStudyReferral.referral_concerns.map(referralConcern => ({
          id: referralConcern.id,
          child_study_referral_concern_id:
            referralConcern.child_study_referral_concern_id
        }))
      : []

    // Create unique ChildStudyReferralConcern linkages
    concernTypes.map(concernType => {
      const sanitizedConcerns = formData[concernType.key].filter(
        concern => !existingConcerns.map(obj => obj.id).includes(concern.id)
      )

      if (sanitizedConcerns.length > 0) {
        sanitizedConcerns.map(concern => {
          const params = {
            child_study_referral_id: referralId,
            concern_id: concern.id,
            category: concernType.value
          }
          newConcernParamsCollection.push(params)
        })
      }
    })

    const newConcernPromises = newConcernParamsCollection.map(async params => {
      await createChildStudyReferralConcern(params)
    })

    await Promise.all(newConcernPromises)

    // Delete removed ChildStudyReferralConcern linkages
    if (existingConcerns) {
      let destroyReferralConcernCollection = existingConcerns

      concernTypes.map(concernType => {
        const selectedConcernIds = formData[concernType.key].map(
          concern => concern.id
        )
        destroyReferralConcernCollection =
          destroyReferralConcernCollection.filter(
            concern => !selectedConcernIds.includes(concern.id)
          )
      })

      const destroyConcernPromises = destroyReferralConcernCollection.map(
        async referralConcern => {
          await destroyChildStudyReferralConcern(
            referralConcern.child_study_referral_concern_id
          )
        }
      )

      await Promise.all(destroyConcernPromises)
    }
    return
  }

  const submitForm = e => {
    e.preventDefault()

    const params = {
      academic_concern_summary: formData['academicConcernSummary'],
      attendance_concern_summary: formData['attendanceConcernSummary'],
      behavior_concern_summary: formData['behaviorConcernSummary'],
      behavior_information_summary: formData['behaviorInformationSummary'],
      class_assessments_summary: formData['classAssessmentsSummary'],
      classwork_summary: formData['classworkSummary'],
      conference_with_parent_date: formData['conferenceWithParentDate'],
      conference_with_parent_summary: formData['conferenceWithParentSummary'],
      conference_with_student_date: formData['conferenceWithStudentDate'],
      conference_with_student_summary: formData['conferenceWithStudentSummary'],
      district_assessments_summary: formData['districtAssessmentsSummary'],
      has_academic_concern: formData['hasAcademicConcern'],
      has_attendance_concern: formData['hasAttendanceConcern'],
      has_behavior_concern: formData['hasBehaviorConcern'],
      has_social_health_concern: formData['hasSocialHealthConcern'],
      health_information_summary: formData['healthInformationSummary'],
      outside_agency_reports_summary: formData['outsideAgencyReportsSummary'],
      referral_date: formData['referralDate'],
      referrer_title: formData['referrerTitle'],
      school_id: schoolId,
      social_health_concern_summary: formData['socialHealthConcernSummary'],
      strategies_summary: formData['strategiesSummary'],
      student_id: studentId,
      student_observation_summary: formData['studentObservationSummary'],
      summary: formData['summary']
    }

    if (childStudyReferral) {
      updateChildStudyReferral(childStudyReferral.id, params).then(async () => {
        await constructFormDataQueriesAndCreate(childStudyReferral.id)
        await constructConcernQueries(childStudyReferral.id)
        queryClient.invalidateQueries([
          'child-study-referral',
          childStudyReferral.child_study_id,
          childStudyReferral.student_id
        ])
        onClose()
      })
    } else {
      createChildStudyReferral(params).then(async res => {
        await constructFormDataQueriesAndCreate(res.id)
        await constructConcernQueries(res.id)
        window.location.reload()
        // history.push(`/student/${studentId}/child-study-process`)
      })
    }
  }

  const handleChange = (attr, val) => {
    setFormData({
      ...formData,
      [attr]: val
    })
  }

  const handleUpload = (fileType, files) => {
    const attr = `${fileType}Documents`
    const array = formData[attr]
    const newArray = Object.values(files)
    const manipulatedArray = array.concat(newArray)

    setFormData({
      ...formData,
      [attr]: manipulatedArray
    })
  }

  const handleDeleteUpload = (fileType, file) => {
    const attr = `${fileType}Documents`
    const array = formData[attr]
    const manipulatedArray = array.filter(f => f.name !== file.name)

    setFormData({
      ...formData,
      [attr]: manipulatedArray
    })
  }

  useEffect(() => {
    if (studentConcerns) {
      setAcademicConcerns(
        studentConcerns.filter(
          concern => concern.parent_concern_type_name === 'Academics'
        )
      )
      setAttendanceConcerns(
        studentConcerns.filter(concern =>
          ['Attendance', 'Enrollment'].includes(
            concern.parent_concern_type_name
          )
        )
      )
      setBehaviorConcerns(
        studentConcerns.filter(
          concern => concern.parent_concern_type_name === 'Behavior'
        )
      )
      setSocialHealthConcerns(
        studentConcerns.filter(concern =>
          ['Basic Needs', 'General Health', 'Mental Health'].includes(
            concern.parent_concern_type_name
          )
        )
      )

      const selectedAcademicConcerns = studentConcerns.filter(concern =>
        childStudyReferral?.referral_concerns
          .filter(referralConcern => referralConcern.category === 'academic')
          .map(referralConcern => referralConcern.id)
          .includes(concern.id)
      )

      const selectedAttendanceConcerns = studentConcerns.filter(concern =>
        childStudyReferral?.referral_concerns
          .filter(referralConcern => referralConcern.category === 'attendance')
          .map(referralConcern => referralConcern.id)
          .includes(concern.id)
      )

      const selectedBehaviorConcerns = studentConcerns.filter(concern =>
        childStudyReferral?.referral_concerns
          .filter(referralConcern => referralConcern.category === 'behavior')
          .map(referralConcern => referralConcern.id)
          .includes(concern.id)
      )

      const selectedSocialHealthConcerns = studentConcerns.filter(concern =>
        childStudyReferral?.referral_concerns
          .filter(
            referralConcern => referralConcern.category === 'social_health'
          )
          .map(referralConcern => referralConcern.id)
          .includes(concern.id)
      )

      setFormData({
        ...formData,
        selectedAcademicConcerns: selectedAcademicConcerns,
        selectedAttendanceConcerns: selectedAttendanceConcerns,
        selectedBehaviorConcerns: selectedBehaviorConcerns,
        selectedSocialHealthConcerns: selectedSocialHealthConcerns
      })
    }
  }, [studentConcerns])

  return (
    <form onSubmit={e => submitForm(e)}>
      <Stack>
        <Stack>
          <Typography variant={'h4'}>Referrer Information</Typography>
          <div id="referrer-info">
            {childStudyReferral && <h6>{childStudyReferral.referrer_name}</h6>}
            <FormControl fullWidth>
              <TextField
                required
                label="Enter your job title/role here"
                color="primary"
                defaultValue={formData['referrerTitle']}
                onChange={val =>
                  handleChange('referrerTitle', val.target.value)
                }
                id="referrer-title-field"
                variant={'outlined'}
              />
            </FormControl>
          </div>
        </Stack>
        <Stack>
          <Typography variant={'h4'}>
            Problem/Concern (Check all that apply)
          </Typography>
          <Grid container id="behavior" columnSpacing={2}>
            <Grid item xs={2}>
              <FormControlLabel
                key={1}
                control={
                  <Checkbox
                    id="behavior-checkbox"
                    color="primary"
                    checked={formData['hasBehaviorConcern']}
                    onChange={() =>
                      handleChange(
                        'hasBehaviorConcern',
                        !formData['hasBehaviorConcern']
                      )
                    }
                  />
                }
                label={'Behavior'}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <TextField
                  disabled={!formData['hasBehaviorConcern']}
                  required={formData['hasBehaviorConcern']}
                  label="Enter behavior details here"
                  color="primary"
                  defaultValue={formData['behaviorConcernSummary']}
                  onChange={val =>
                    handleChange('behaviorConcernSummary', val.target.value)
                  }
                  id="behavior-concern-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              {behaviorConcerns.length > 0 && (
                <FormControl fullWidth>
                  <InputLabel id={'behavior-concern-select-label'}>
                    Select Behavior Concern(s) (Optional)
                  </InputLabel>
                  <Select
                    fullWidth
                    multiple
                    disabled={!formData['hasBehaviorConcern']}
                    labelId={'behavior-concern-select-label'}
                    id={'behavior-concern-select'}
                    label={'Select Behavior Concern(s) (Optional)'}
                    value={formData['selectedBehaviorConcerns']}
                    onChange={e =>
                      handleChange('selectedBehaviorConcerns', e.target.value)
                    }
                    renderValue={selected => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selected.map(o => (
                          <Chip key={o.id} label={o.concern_name} />
                        ))}
                      </Box>
                    )}
                  >
                    {behaviorConcerns.map(concern => (
                      <MenuItem key={concern.id} value={concern}>
                        {concern.concern_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid container id="academic" columnSpacing={2}>
            <Grid item xs={2}>
              <FormControlLabel
                key={1}
                control={
                  <Checkbox
                    id="academic-checkbox"
                    color="primary"
                    checked={formData['hasAcademicConcern']}
                    onChange={() =>
                      handleChange(
                        'hasAcademicConcern',
                        !formData['hasAcademicConcern']
                      )
                    }
                  />
                }
                label={'Academic'}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <TextField
                  disabled={!formData['hasAcademicConcern']}
                  required={formData['hasAcademicConcern']}
                  label="Enter academic details here"
                  color="primary"
                  defaultValue={formData['academicConcernSummary']}
                  onChange={val =>
                    handleChange('academicConcernSummary', val.target.value)
                  }
                  id="academic-concern-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              {academicConcerns.length > 0 && (
                <FormControl fullWidth>
                  <InputLabel id={'academic-concern-select-label'}>
                    Select Academic Concern(s) (Optional)
                  </InputLabel>
                  <Select
                    fullWidth
                    multiple
                    disabled={!formData['hasAcademicConcern']}
                    labelId={'academic-concern-select-label'}
                    id={'academic-concern-select'}
                    label={'Select Academic Concern(s) (Optional)'}
                    value={formData['selectedAcademicConcerns']}
                    onChange={e =>
                      handleChange('selectedAcademicConcerns', e.target.value)
                    }
                    renderValue={selected => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selected.map(o => (
                          <Chip key={o.id} label={o.concern_name} />
                        ))}
                      </Box>
                    )}
                  >
                    {academicConcerns.map(concern => (
                      <MenuItem key={concern.id} value={concern}>
                        {concern.concern_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid container id="attendance" columnSpacing={2}>
            <Grid item xs={2}>
              <FormControlLabel
                key={1}
                control={
                  <Checkbox
                    id="attendance-checkbox"
                    color="primary"
                    checked={formData['hasAttendanceConcern']}
                    onChange={() =>
                      handleChange(
                        'hasAttendanceConcern',
                        !formData['hasAttendanceConcern']
                      )
                    }
                  />
                }
                label={'Attendance'}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <TextField
                  disabled={!formData['hasAttendanceConcern']}
                  required={formData['hasAttendanceConcern']}
                  label="Enter attendance details here"
                  color="primary"
                  defaultValue={formData['attendanceConcernSummary']}
                  onChange={val =>
                    handleChange('attendanceConcernSummary', val.target.value)
                  }
                  id="attendance-concern-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              {attendanceConcerns.length > 0 && (
                <FormControl fullWidth>
                  <InputLabel id={'attendance-concern-select'}>
                    Select Attendance Concern(s) (Optional)
                  </InputLabel>
                  <Select
                    fullWidth
                    multiple
                    disabled={!formData['hasAttendanceConcern']}
                    labelId={'attendance-concern-select'}
                    label={'Select Attendance Concern(s) (Optional)'}
                    value={formData['selectedAttendanceConcerns']}
                    onChange={e =>
                      handleChange('selectedAttendanceConcerns', e.target.value)
                    }
                    renderValue={selected => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selected.map(o => (
                          <Chip key={o.id} label={o.concern_name} />
                        ))}
                      </Box>
                    )}
                  >
                    {attendanceConcerns.map(concern => (
                      <MenuItem key={concern.id} value={concern}>
                        {concern.concern_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid container id="social-health" columnSpacing={2}>
            <Grid item xs={2}>
              <FormControlLabel
                key={1}
                control={
                  <Checkbox
                    id="social-health-checkbox"
                    color="primary"
                    checked={formData['hasSocialHealthConcern']}
                    onChange={() =>
                      handleChange(
                        'hasSocialHealthConcern',
                        !formData['hasSocialHealthConcern']
                      )
                    }
                  />
                }
                label={'Social Health'}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControl fullWidth>
                <TextField
                  disabled={!formData['hasSocialHealthConcern']}
                  required={formData['hasSocialHealthConcern']}
                  label="Enter social health details here"
                  color="primary"
                  defaultValue={formData['socialHealthConcernSummary']}
                  onChange={val =>
                    handleChange('socialHealthConcernSummary', val.target.value)
                  }
                  id="social-health-concern-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={5}>
              {socialHealthConcerns.length > 0 && (
                <FormControl fullWidth>
                  <InputLabel id={'social-health-concern-select'}>
                    Select Social Health Concern(s) (Optional)
                  </InputLabel>
                  <Select
                    fullWidth
                    multiple
                    disabled={!formData['hasSocialHealthConcern']}
                    labelId={'social-health-concern-select'}
                    label={'Select Social Health Concern(s) (Optional)'}
                    value={formData['selectedSocialHealthConcerns']}
                    onChange={e =>
                      handleChange(
                        'selectedSocialHealthConcerns',
                        e.target.value
                      )
                    }
                    renderValue={selected => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selected.map(o => (
                          <Chip key={o.id} label={o.concern_name} />
                        ))}
                      </Box>
                    )}
                  >
                    {socialHealthConcerns.map(concern => (
                      <MenuItem key={concern.id} value={concern}>
                        {concern.concern_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </Stack>
        <Stack>
          <Typography variant={'h4'}>Summary of the Problem/Concern</Typography>
          <Typography variant={'subtitle1'}>
            Objectively describe the specific behaviors, incidents and/or
            academic needs with included documentation.
          </Typography>
          <FormControl fullWidth>
            <TextField
              required
              multiline
              label="Enter summary here"
              rows={4}
              color="primary"
              defaultValue={formData['summary']}
              onChange={val => handleChange('summary', val.target.value)}
              id="summary-field"
              variant={'outlined'}
            />
          </FormControl>
        </Stack>
        <Stack>
          <Typography variant={'h4'}>
            Strategies Tried Prior to Referral - Mandatory
          </Typography>
          <Typography>
            Briefly describe your results and provide dates.
          </Typography>
          <Grid container id="conference-with-student">
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  color="primary"
                  required
                  defaultValue={formData['conferenceWithStudentSummary']}
                  label="Enter student conference details here"
                  onChange={val =>
                    handleChange(
                      'conferenceWithStudentSummary',
                      val.target.value
                    )
                  }
                  id="conference-with-student-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: 2 }}>
              <DatePicker
                label={'Date of Conference'}
                renderInput={props => <TextField {...props} required />}
                disableToolbar
                fullWidth
                inputFormat="MM/DD/YYYY"
                required
                id="conference-with-student-date"
                value={formData['conferenceWithStudentDate']}
                onChange={val => handleChange('conferenceWithStudentDate', val)}
                autoOk={true}
              />
            </Grid>
          </Grid>
          <Grid container id="conference-with-parent">
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  color="primary"
                  required
                  defaultValue={formData['conferenceWithParentSummary']}
                  label="Enter parent conference details here"
                  onChange={val =>
                    handleChange(
                      'conferenceWithParentSummary',
                      val.target.value
                    )
                  }
                  id="conference-with-parent-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ paddingLeft: 2 }}>
              <DatePicker
                label={'Date of Conference'}
                renderInput={props => <TextField {...props} required />}
                disableToolbar
                fullWidth
                inputFormat="MM/DD/YYYY"
                required
                id="phone-call-conference-with-parent-date"
                value={formData['conferenceWithParentDate']}
                onChange={val => handleChange('conferenceWithParentDate', val)}
                autoOk={true}
              />
            </Grid>
          </Grid>
          <FormControl fullWidth>
            <TextField
              required
              multiline
              color="primary"
              rows={4}
              defaultValue={formData['strategiesSummary']}
              onChange={val =>
                handleChange('strategiesSummary', val.target.value)
              }
              label="Enter strategy details here"
              id="strategies-summary-field"
              variant={'outlined'}
            />
          </FormControl>
        </Stack>
        <Stack>
          <Typography variant={'h4'}>Documentation</Typography>
          <Typography variant={'subtitle1'}>
            Please document any issues the child is having in these areas. If
            possible, provide concrete examples of student work and submit
            copies of your examples with this form.
          </Typography>
          <Grid container id="classwork" columnSpacing={2}>
            <Grid item xs={4}>
              <h6>
                Classwork
                <IconButton
                  aria-label={'classwork-documentation'}
                  onClick={() => classworkSummaryRef.current.click()}
                >
                  <NoteAddIcon fontSize="large" />
                </IconButton>
                <input
                  type="file"
                  ref={classworkSummaryRef}
                  onChange={e => handleUpload('classwork', e.target.files)}
                  style={{ visibility: 'hidden' }}
                  multiple
                />
              </h6>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  color="primary"
                  defaultValue={formData['classworkSummary']}
                  onChange={val =>
                    handleChange('classworkSummary', val.target.value)
                  }
                  label="Enter classwork details here"
                  id="classwork-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {childStudyReferral?.forms
                .filter(form => form.category === 'classwork')
                .map((form, index) => (
                  <DownloadLink key={index} file={form.file_url}>
                    Link {index + 1}
                  </DownloadLink>
                ))}
            </Grid>
            <Grid item xs={12}>
              {formData['classworkDocuments'].map((file, i) => (
                <React.Fragment key={`classworkDocuments-${file.name}-${i}`}>
                  <DownloadLink file={file}>{file.name}</DownloadLink>
                  <Button
                    key={`${file.name}-${i}-classworkDocuments-button`}
                    onClick={() => handleDeleteUpload('classwork', file)}
                  >
                    X
                  </Button>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Grid container id="student-observation" columnSpacing={2}>
            <Grid item xs={4}>
              <h6>
                Student Observation
                <IconButton
                  aria-label={'student-observation-documentation'}
                  onClick={() => studentObservationSummaryRef.current.click()}
                >
                  <NoteAddIcon fontSize="large" />
                </IconButton>
                <input
                  type="file"
                  ref={studentObservationSummaryRef}
                  onChange={e =>
                    handleUpload('studentObservation', e.target.files)
                  }
                  style={{ visibility: 'hidden' }}
                  multiple
                />
              </h6>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  color="primary"
                  defaultValue={formData['studentObservationSummary']}
                  onChange={val =>
                    handleChange('studentObservationSummary', val.target.value)
                  }
                  label="Enter student observation details here"
                  id="student-observation-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {childStudyReferral?.forms
                .filter(form => form.category === 'student_observation')
                .map((form, index) => (
                  <DownloadLink key={index} file={form.file_url}>
                    Link {index + 1}
                  </DownloadLink>
                ))}
            </Grid>
            <Grid item xs={12}>
              {formData['studentObservationDocuments'].map((file, i) => (
                <React.Fragment
                  key={`studentObservationDocuments-${file.name}-${i}`}
                >
                  <DownloadLink file={file}>{file.name}</DownloadLink>
                  <Button
                    key={`${file.name}-${i}-studentObservationDocuments-button`}
                    onClick={() =>
                      handleDeleteUpload('studentObservation', file)
                    }
                  >
                    X
                  </Button>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Grid container id="class-assessments" columnSpacing={2}>
            <Grid item xs={4}>
              <h6>
                Class Assessments
                <IconButton
                  aria-label={'class-assessments-documentation'}
                  onClick={() => classAssessmentsSummaryRef.current.click()}
                >
                  <NoteAddIcon fontSize="large" />
                </IconButton>
                <input
                  type="file"
                  ref={classAssessmentsSummaryRef}
                  onChange={e =>
                    handleUpload('classAssessments', e.target.files)
                  }
                  style={{ visibility: 'hidden' }}
                  multiple
                />
              </h6>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  color="primary"
                  defaultValue={formData['classAssessmentsSummary']}
                  onChange={val =>
                    handleChange('classAssessmentsSummary', val.target.value)
                  }
                  label="Enter class assessment details here"
                  id="class-assessments-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {childStudyReferral?.forms
                .filter(form => form.category === 'class_assessments')
                .map((form, index) => (
                  <DownloadLink key={index} file={form.file_url}>
                    Link {index + 1}
                  </DownloadLink>
                ))}
            </Grid>
            <Grid item xs={12}>
              {formData['classAssessmentsDocuments'].map((file, i) => (
                <React.Fragment
                  key={`classAssessmentsDocuments-${file.name}-${i}`}
                >
                  <DownloadLink file={file}>{file.name}</DownloadLink>
                  <Button
                    key={`${file.name}-${i}-classAssessmentsDocuments-button`}
                    onClick={() => handleDeleteUpload('classAssessments', file)}
                  >
                    X
                  </Button>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Grid container id="district-assessments" columnSpacing={2}>
            <Grid item xs={4}>
              <h6>
                District Assessments/Benchmarks
                <IconButton
                  aria-label={'district-assessments-documentation'}
                  onClick={() => districtAssessmentsSummaryRef.current.click()}
                >
                  <NoteAddIcon fontSize="large" />
                </IconButton>
                <input
                  type="file"
                  ref={districtAssessmentsSummaryRef}
                  onChange={e =>
                    handleUpload('districtAssessments', e.target.files)
                  }
                  style={{ visibility: 'hidden' }}
                  multiple
                />
              </h6>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  color="primary"
                  defaultValue={formData['districtAssessmentsSummary']}
                  onChange={val =>
                    handleChange('districtAssessmentsSummary', val.target.value)
                  }
                  label="Enter district assessment details here"
                  id="district-assessments-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {childStudyReferral?.forms
                .filter(form => form.category === 'district_assessments')
                .map((form, index) => (
                  <DownloadLink key={index} file={form.file_url}>
                    Link {index + 1}
                  </DownloadLink>
                ))}
            </Grid>
            <Grid item xs={12}>
              {formData['districtAssessmentsDocuments'].map((file, i) => (
                <React.Fragment
                  key={`districtAssessmentsDocuments-${file.name}-${i}`}
                >
                  <DownloadLink file={file}>{file.name}</DownloadLink>
                  <Button
                    key={`${file.name}-${i}-districtAssessmentsDocuments-button`}
                    onClick={() =>
                      handleDeleteUpload('districtAssessments', file)
                    }
                  >
                    X
                  </Button>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Grid container id="behavior-information" columnSpacing={2}>
            <Grid item xs={4}>
              <h6>
                Behavior Information
                <IconButton
                  aria-label={'behavior-information-documentation'}
                  onClick={() => behaviorInformationSummaryRef.current.click()}
                >
                  <NoteAddIcon fontSize="large" />
                </IconButton>
                <input
                  type="file"
                  ref={behaviorInformationSummaryRef}
                  onChange={e =>
                    handleUpload('behaviorInformation', e.target.files)
                  }
                  style={{ visibility: 'hidden' }}
                  multiple
                />
              </h6>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  color="primary"
                  defaultValue={formData['behaviorInformationSummary']}
                  onChange={val =>
                    handleChange('behaviorInformationSummary', val.target.value)
                  }
                  label="Enter behavior information details here"
                  id="behavior-information-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {childStudyReferral?.forms
                .filter(form => form.category === 'behavior_information')
                .map((form, index) => (
                  <DownloadLink key={index} file={form.file_url}>
                    Link {index + 1}
                  </DownloadLink>
                ))}
            </Grid>
            <Grid item xs={12}>
              {formData['behaviorInformationDocuments'].map((file, i) => (
                <React.Fragment
                  key={`behaviorInformationDocuments-${file.name}-${i}`}
                >
                  <DownloadLink file={file}>{file.name}</DownloadLink>
                  <Button
                    key={`${file.name}-${i}-behaviorInformationDocuments-button`}
                    onClick={() =>
                      handleDeleteUpload('behaviorInformation', file)
                    }
                  >
                    X
                  </Button>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Grid container id="health-information" columnSpacing={2}>
            <Grid item xs={4}>
              <h6>
                Health Information
                <IconButton
                  aria-label={'health-information-documentation'}
                  onClick={() => healthInformationSummaryRef.current.click()}
                >
                  <NoteAddIcon fontSize="large" />
                </IconButton>
                <input
                  type="file"
                  ref={healthInformationSummaryRef}
                  onChange={e =>
                    handleUpload('healthInformation', e.target.files)
                  }
                  style={{ visibility: 'hidden' }}
                  multiple
                />
              </h6>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  color="primary"
                  defaultValue={formData['healthInformationSummary']}
                  onChange={val =>
                    handleChange('healthInformationSummary', val.target.value)
                  }
                  label="Enter health information details here"
                  id="health-information-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {childStudyReferral?.forms
                .filter(form => form.category === 'health_information')
                .map((form, index) => (
                  <DownloadLink key={index} file={form.file_url}>
                    Link {index + 1}
                  </DownloadLink>
                ))}
            </Grid>
            <Grid item xs={12}>
              {formData['healthInformationDocuments'].map((file, i) => (
                <React.Fragment
                  key={`healthInformationDocuments-${file.name}-${i}`}
                >
                  <DownloadLink file={file}>{file.name}</DownloadLink>
                  <Button
                    key={`${file.name}-${i}-healthInformationDocuments-button`}
                    onClick={() =>
                      handleDeleteUpload('healthInformation', file)
                    }
                  >
                    X
                  </Button>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          <Grid container id="outside-agency-reports" columnSpacing={2}>
            <Grid item xs={4}>
              <h6>
                Outside Agency Reports
                <IconButton
                  aria-label={'outside-agency-reports-documentation'}
                  onClick={() => outsideAgencyReportsSummaryRef.current.click()}
                >
                  <NoteAddIcon fontSize="large" />
                </IconButton>
                <input
                  type="file"
                  ref={outsideAgencyReportsSummaryRef}
                  onChange={e =>
                    handleUpload('outsideAgencyReports', e.target.files)
                  }
                  style={{ visibility: 'hidden' }}
                  multiple
                />
              </h6>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <TextField
                  color="primary"
                  defaultValue={formData['outsideAgencyReportsSummary']}
                  onChange={val =>
                    handleChange(
                      'outsideAgencyReportsSummary',
                      val.target.value
                    )
                  }
                  label="Enter outside agency report details here"
                  id="outside-agency-reports-summary-field"
                  variant={'outlined'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {childStudyReferral?.forms
                .filter(form => form.category === 'outside_agency_reports')
                .map((form, index) => (
                  <DownloadLink key={index} file={form.file_url}>
                    Link {index + 1}
                  </DownloadLink>
                ))}
            </Grid>
            <Grid item xs={12}>
              {formData['outsideAgencyReportsDocuments'].map((file, i) => (
                <React.Fragment
                  key={`outsideAgencyReportsDocuments-${file.name}-${i}`}
                >
                  <DownloadLink file={file}>{file.name}</DownloadLink>
                  <Button
                    key={`${file.name}-${i}-outsideAgencyReportsDocuments-button`}
                    onClick={() =>
                      handleDeleteUpload('outsideAgencyReports', file)
                    }
                  >
                    X
                  </Button>
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        </Stack>
        <Stack>
          {/* <Button onClick={() => onClose()} style={{ marginRight: '0.5em' }}>
            Cancel
          </Button> */}
          <Button variant="contained" color="primary" type="submit" id="submit">
            {childStudyReferral ? 'Save' : 'Submit'}
          </Button>
        </Stack>
      </Stack>
    </form>
  )
}

ReferralForm.propTypes = {
  childStudyReferral: PropTypes.object,
  onClose: PropTypes.func,
  schoolId: PropTypes.number.isRequired,
  studentId: PropTypes.number.isRequired
}

export default ReferralForm
