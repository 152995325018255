import React from 'react'
import moment from 'moment'
import { startCase } from 'lodash'
import { Stack, Typography } from '@mui/material'
import { RequirementPropType } from 'modules/requirements'

const RequirementTitle = ({ requirement }) => {
  const headerText = () => {
    if (requirement.data_type === 'ChildStudyForm') {
      const description = requirement.filename || requirement.name
      return `${startCase(requirement.data_type)} - ${description}`
    } else if (requirement.data_type === 'Concern') {
      return requirement.child_study_id
        ? 'Concern Created by Child Study Team'
        : 'Concern'
    } else {
      return requirement.name
    }
  }

  const createdAt = `Created ${moment(
    requirement.created_at,
    'YYYY-MM-DDTHH:mm'
  ).fromNow()}`

  return (
    <Stack direction="row" justifyContent={'space-between'}>
      <Typography variant="h4">{headerText()}</Typography>
      <Typography variant="overline">{createdAt}</Typography>
    </Stack>
  )
}

RequirementTitle.propTypes = {
  requirement: RequirementPropType.isRequired
}

export default RequirementTitle
